import React from 'react';
import useStyles from './styles';
import { FaFaceSmile } from 'react-icons/fa6';
import LeadershipList from 'pages/PageContent/components/leadershipList';
import SubSectionList from 'pages/PageContent/components/subSectionList';
import { hrTabs } from 'pages/PageContent/data';

const HrPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          Meet
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T103048/5I9ACEe4yjiBHPGImPGC9IJ1rfH5Qs1OKs5IEQTu.png'
            }
          />
          The HR Team
        </h1>
      </div>
      <div className={classes.leaderContainer}>
        <LeadershipList
          leaders={[
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-05T113856/B2Htx5rXY1VkCjJ21DzNswTWUyPymBfDZptafuR7.png',
              title: 'HR Director',
              name: 'Nina Vidler',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-05T151747/KtCrgYtwopVJhuRxFBQ2QY2qNmgybLHypoVcGah5.png',
              title: 'Junior HR Business Partner',
              name: 'Anika Glogowski',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T101044/Qr2egQgsJSz2JuSW48cnRscap5U241q8moS01OBn.png',
              title: 'Operations and Events Coordinator',
              name: 'Sen Kahveci',
            },
          ]}
        />
      </div>
      <div className={classes.content}>
        <SubSectionList cards={hrTabs} />
      </div>
      <br />
    </div>
  );
};

export default HrPage;
