import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../../contexts/theme';

const useCreateCategoryStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    padding: theme.spacing(3),
    background: theme.design.colors.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  loader: {
    '&.MuiCircularProgress-colorPrimary': {
      color: theme.design.colors.white,
    },
  },
  loading: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
  },
  title: {
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.design.colors.grey100,
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    paddingBottom: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  selectBox: {
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  btn: {
    width: '100%',
    '&.MuiButton-root': {
      padding: theme.spacing(1.5, 3),
    },
  },
  createTextfield: {
    margin: '0 !important',
    color: theme.design.colors.greyDark,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
    },
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
      background: theme.design.colors.grey150,
    },
  },
  formActions: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
  },
  categorySubs: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
}));

export default useCreateCategoryStyles;
