import { FC } from 'react';
import useParameterCardStyles from './styles';
import { BsArrowUpShort } from 'react-icons/bs';
import { ParameterCardProps } from './types';
import clx from 'classnames';

export const ParameterCard: FC<ParameterCardProps> = ({
  index,
  title,
  value,
  impression,
}) => {
  const classes = useParameterCardStyles();

  const determineBackground = () => {
    switch (index) {
      case 0:
        return classes.revenueCard;
      case 1:
        return classes.impressionCard;
      case 2:
        return classes.uniqueViewCard;
      case 3:
        return classes.ecpmCard;
      case 4:
        return classes.totalViewsCard;
      case 5:
        return classes.subscriberCard;
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <p className={clx(classes.title, determineBackground())}>{title}</p>
      <div className={classes.valueParent}>
        <p className={classes.value}>{value}</p>
        {/* we dont have data for this section */}
        {/* <div className={classes.impressionContainer}>
          <p className={classes.impression}>{impression}</p>
          <BsArrowUpShort />
        </div> */}
      </div>
    </div>
  );
};
