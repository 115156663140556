import { Icon } from '@iconify/react';
import { formatDistanceToNow } from 'date-fns';
import { Campaign } from 'generated/jcmat/graphql';
import { Link } from 'react-router-dom';

type SavingsPromoCardProps = {
  name: Campaign['name'];
  commentsCount: Campaign['commentsCount'];
  postsCount: Campaign['postsCount'];
  createdAt: Campaign['createdAt'];
};

export default ({
  name,
  commentsCount,
  createdAt,
  postsCount,
}: SavingsPromoCardProps) => {
  const timeAgoCreatedAt = formatDistanceToNow(new Date(createdAt), {
    addSuffix: true,
  });

  return (
    <div
      style={{ border: '2px solid #EAECF0' }}
      className="w-full max-h-full shadow-md rounded-[12px]"
    >
      <div
        style={{ borderBottom: '2px solid #EAECF0' }}
        className="p-5 flex justify-between lg:items-center gap-2 flex-col lg:flex-row"
      >
        <p className="text-lg font-[500]">{name}</p>

        {/* @ts-ignore */}
        <Link to="#">
          <div className="flex items-center space-x-2">
            {/* <Icon icon="uil:edit" color="#257CFF" /> */}

            {/* <p className="text-[#257CFF] text-sm">Edit campaign</p> */}
          </div>
        </Link>
      </div>

      <div className="xl:h-[calc(100%-110px)] p-5 grid grid-cols-2 content-between gap-y-14 justify-items-stretch">
        <div>
          <p className="text-[#257CFF] text-lg">
            {postsCount.toLocaleString('en-GB')}
          </p>

          <p className="text-[#5E646D]">Number of Posts</p>
        </div>

        <div>
          <p className="text-[#257CFF] text-lg">
            {commentsCount.toLocaleString('en-GB')}
          </p>

          <p className="text-[#5E646D]">Total Comments</p>
        </div>

        <div>
          <p className="text-[#257CFF] text-lg">Instagram</p>

          <p className="text-[#5E646D]">Most tracked channel</p>
        </div>

        <div>
          <p className="text-[#257CFF] text-lg">{timeAgoCreatedAt}</p>

          <p className="text-[#5E646D]">Date Created</p>
        </div>
      </div>
    </div>
  );
};
