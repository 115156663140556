import { useEffect, FC, useMemo, useState } from 'react';
import { Tag } from '@wazobia-tech/wazcom/dist/core';
import { CircularProgress } from '@mui/material';
import { CheckBoxInput, SelectInput } from '@wazobia-tech/wazcom/dist/io';
import { useQuery } from '@apollo/client';
import {
  getCategoryOptions,
  handleCategoryDelete,
  handleSubCategorySelection,
  isSubCategorySelected,
  updateSlug,
} from './helpers';
import { CategoryListProps, TCategory } from './types';
import { useCategoryStyles } from './styles';
import { get } from 'lodash';
import { useGetPreSlugURL } from 'components/Blog/helpers';

export const CategoryList: FC<CategoryListProps> = ({
  formData,
  setFormData,
  isArticle,
  getCategoryConfig: { GET_CATEGORY, client, variables, dataKey },
}) => {
  const classes = useCategoryStyles();
  const getPreSlugUrl = useGetPreSlugURL();
  const [blogCategory, setBlogCategory] = useState<{ [key: string]: any }[]>(
    []
  );

  const { data: categoriesData, loading } = useQuery(GET_CATEGORY, {
    fetchPolicy: 'network-only',
    client,
    variables: {
      ...variables,
      first: 50,
    },
  });

  useEffect(() => {
    if (categoriesData) {
      setBlogCategory(get(categoriesData, dataKey?.join('.')));
    }
  }, [categoriesData, loading]);

  const subCategories = useMemo(() => {
    if (!formData?.category_uuid) return [];

    const category = blogCategory.find(
      (category: any) => category?.uuid === formData?.category_uuid
    );
    return category?.subCategories ?? [];
  }, [formData?.category_uuid, blogCategory]);

  const handlePrimaryCategoryChange = ({ value }: { value: string }) => {
    setFormData({ ...formData, category: value });
    const category_uuids = (formData?.categories as TCategory[]).map(
      ({ uuid, subCategories }) =>
        subCategories && subCategories.length > 0
          ? subCategories[0]?.uuid
          : uuid
    );
    const categoryIndex = category_uuids.findIndex((uuid) => uuid === value);
    if (categoryIndex >= 0) {
      const categories = formData ? [...formData.categories] : [];
      const primaryCategory = categories.splice(categoryIndex, 1);
      const newCategories = [...primaryCategory, ...categories];
      setFormData({ ...formData, categories: newCategories });
      !formData?.published_at &&
        updateSlug(
          formData,
          setFormData,
          getPreSlugUrl(),
          isArticle,
          newCategories
        );
    }
  };

  useEffect(() => {
    if (!formData?.category_uuid || !blogCategory) return;
    const idx = (formData?.categories ?? []).findIndex(
      (category: { uuid: string }) => category?.uuid === formData?.category_uuid
    );

    if (idx >= 0) return;

    const category = blogCategory.find(
      (category: any) => category?.uuid === formData?.category_uuid
    );

    const newCategory = {
      name: category?.name,
      uuid: category?.uuid,
      slug: category?.slug,
      subCategories: [],
    };
    const updatedCategories = [...(formData?.categories ?? []), newCategory];
    setFormData({
      ...formData,
      categories: updatedCategories.filter((item) => Boolean(item.name)),
    });
  }, [formData?.category_uuid, blogCategory]);

  useEffect(() => {
    if (!formData?.published_at) {
      updateSlug(formData, setFormData, getPreSlugUrl(), isArticle);
    }

    if (!formData?.categories || formData?.categories?.length < 2) return;

    if (!formData?.category) {
      const category = formData?.categories[0];
      const uuid =
        category?.subCategories && category?.subCategories?.length > 0
          ? category?.subCategories[0]?.uuid
          : category?.uuid;
      setFormData({ ...formData, category: uuid });
      return;
    }

    const category_uuids = (formData?.categories as TCategory[]).map(
      ({ uuid, subCategories }) =>
        subCategories && subCategories.length > 0
          ? subCategories[0]?.uuid
          : uuid
    );

    if (category_uuids.includes(formData?.category)) return;
    setFormData({ ...formData, name: 'category', value: category_uuids[0] });
  }, [formData?.categories]);

  const renderCategories = () =>
    formData?.categories?.map(
      (
        {
          name,
          uuid,
          subCategories,
        }: {
          name: string;
          uuid: string;
          subCategories: { name: string; uuid: string }[];
        },
        index: number
      ) => (
        <div key={index} className={classes.category}>
          <Tag
            name={name}
            handleRemove={() => {
              handleCategoryDelete(formData, uuid, setFormData);
            }}
          />
          {subCategories && subCategories?.length > 0 && (
            <div className={classes.categoryFormSubs}>
              <div className={classes.categoryIndicator} />
              {subCategories.map(({ name }, index) => (
                <Tag
                  classname={classes.categorySubTag}
                  key={index}
                  name={name}
                />
              ))}
            </div>
          )}
        </div>
      )
    );

  const renderSubCategories = () =>
    subCategories?.map(({ name, uuid, slug }: any, index: number) => (
      <div key={index} className={classes.categorySub}>
        <CheckBoxInput
          onChange={({ value: checked }) => {
            handleSubCategorySelection(
              formData,
              { name: name ?? '', uuid, slug },
              checked,
              setFormData,
              getPreSlugUrl(),
              isArticle
            );
          }}
          classNames={{ classes: { root: classes.categoryCheckBox } }}
          value={isSubCategorySelected(formData, { name: name ?? '', uuid })}
        />
        <p>
          {(name ?? '').charAt(0)?.toUpperCase() +
            (name ?? '')?.slice(1)?.toLowerCase()}
        </p>
      </div>
    ));

  if (/*blogCategory.length < 0 || */ loading)
    return <CircularProgress size={14} color="success" />;

  return (
    <div className={classes.root}>
      {renderCategories()}
      {subCategories?.length > 0 && (
        <div className={classes.categorySubsParent}>
          <p className={classes.categorySubHeading}>
            SELECT A SUBCATEGORY (OPTIONAL)
          </p>
          <div className={classes.categorySubs}>{renderSubCategories()}</div>
        </div>
      )}
      {formData?.categories && formData?.categories.length > 1 && (
        <div className={classes.primaryCategoryContainer}>
          <SelectInput
            options={getCategoryOptions(formData)}
            value={formData?.category?.value}
            className={classes.primaryCategory}
            label="SELECT PRIMARY CATEGORY"
            onChange={handlePrimaryCategoryChange}
          />
        </div>
      )}
    </div>
  );
};
