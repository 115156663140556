import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FETCHCONFIG, UPDATECONFIG } from './graphQL';
import Section from './Section/Section';
import { Accordion } from '../common/Accordion/Accordion';
import { FournineSectionProps } from './types';
import { useFournineRevolutionStyles } from './styles';
import { set, isEqual } from 'lodash';
import { defaultCard } from './data';
import Spinner from '../common/Spinner/Spinner';
import { Button } from '../common/Button/Button';
import { parseSectionsIntoProps, parseSectionsIntoJson } from './utilities';

import clx from 'classnames';
import { notifySuccess } from '../../utilities/notify';

const FournineRevolution = () => {
  const { data, loading } = useQuery(FETCHCONFIG, {
    variables: { route: 'newyearsrevolution', site: 'fournine' },
    fetchPolicy: 'network-only',
  });
  const [updateConfigMutation, { loading: updateLoading }] =
    useMutation(UPDATECONFIG);
  const [initialFournineSections, setInitialFournineSections] = useState<
    FournineSectionProps[]
  >([]);
  const [fournineSections, setFournineSections] = useState<
    FournineSectionProps[]
  >([]);
  const [activeAccId, setActiveAccId] = useState<number | null>(0);
  const classes = useFournineRevolutionStyles();

  useEffect(() => {
    if (data) {
      const { getConfig: pageContent } = data;
      const {
        config: {
          newyearsrevolution: { content: sections },
        },
      } = JSON.parse(pageContent);
      const parsedSections = parseSectionsIntoProps(JSON.parse(sections));
      setInitialFournineSections(JSON.parse(JSON.stringify(parsedSections)));
      setFournineSections(parsedSections);
    }
  }, [data]);

  // Method to handle the user setting and updating the different fields
  const handleTextChange = useCallback(
    (keys: string[], value: string | number | boolean) => {
      const newFournineSections = [...fournineSections];
      if (keys.includes('max')) {
        newFournineSections[Number(keys[0])].section.max = Number(value);
      } else {
        const newItem = {
          ...newFournineSections[Number(keys[0])].section.items[
            Number(keys[1])
          ],
        };
        set(newItem, keys.slice(2).join('.'), value);
        newFournineSections[Number(keys[0])].section.items[Number(keys[1])] =
          newItem;
      }
      setFournineSections(newFournineSections);
    },
    [fournineSections]
  );

  const handleAddCard = useCallback(
    (index) => {
      const newFournineSections = [...fournineSections];
      newFournineSections[index].section.items.unshift(defaultCard);
      setFournineSections(newFournineSections);
    },
    [fournineSections]
  );

  const handleDeleteCard = useCallback(
    (sectionIndex, cardIndex) => {
      const prompt = window.confirm('Are you sure you want to delete ?');
      if (!prompt) return;
      const newFournineSections = [...fournineSections];
      newFournineSections[sectionIndex].section.items = newFournineSections[
        sectionIndex
      ].section.items.filter(
        (_, index) => index.toString() !== cardIndex.toString()
      );
      setFournineSections(newFournineSections);
    },
    [fournineSections]
  );

  const updateConfig = useCallback(async () => {
    if (updateLoading) return;
    const content = parseSectionsIntoJson(fournineSections);
    try {
      const variables = {
        route: 'newyearsrevolution',
        site: 'fournine',
        content,
      };
      await updateConfigMutation({ variables });
      notifySuccess('Updated successfully.');
      const parsedSections = parseSectionsIntoProps(JSON.parse(content));
      setInitialFournineSections(JSON.parse(JSON.stringify(parsedSections)));
    } catch (error) {
      //
    }
  }, [fournineSections]);

  const displayFournineSections = useCallback(() => {
    return fournineSections.map(({ name, section }, index) => {
      const formattedName =
        name.charAt(0).toUpperCase() +
        name.slice(1).replace(/_/g, '  ').toLowerCase();
      return (
        <Accordion
          key={index}
          title={formattedName}
          expanded={activeAccId === index}
          handleClick={() => {
            activeAccId === index
              ? setActiveAccId(null)
              : setActiveAccId(index);
          }}
        >
          <p
            className={classes.add}
            onClick={() => {
              handleAddCard(index);
            }}
          >
            Add New Card
          </p>
          <Section
            {...section}
            index={index}
            handleChange={handleTextChange}
            handleDeleteCard={handleDeleteCard}
          />
        </Accordion>
      );
    });
  }, [fournineSections, activeAccId]);

  return (
    <>
      {!loading && (
        <div className={classes.root}>
          <form>{displayFournineSections()}</form>
          <div className={classes.publishBtn}>
            <Button
              title="Publish"
              loading={updateLoading}
              onClick={() => {
                updateConfig();
              }}
            />
            <div
              className={clx(
                classes.overlay,
                isEqual(initialFournineSections, fournineSections) &&
                  classes.overlayActive
              )}
            />
          </div>
        </div>
      )}
      <Spinner display={loading} />
    </>
  );
};

export default FournineRevolution;
