import { SetStateAction, useState } from 'react';
import { Icon } from '@iconify/react';
import TabButton from '../common/TabButton';
import TablePagination from '../common/TablePagination';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import CampaignInsightCard from './CommentInsightCard';

interface Props {
  comments: {
    author: string;
    text: string;
    createdAt: Date;
    id: string;
  }[];
  searchQuery: string;
  setSearchQuery:  React.Dispatch<React.SetStateAction<string>>
}

export default function CommentsInsightsTabe({ comments, searchQuery, setSearchQuery }: Props) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <div className="overflow-x-scroll">
      <div className="min-w-[70rem]">
        <div style={{ border: '2px solid #EAECF0' }} className="rounded-[12px]">
          <div className="p-5 flex justify-between items-center">
            <div className="space-y-2">
              <p className="text-[#101828] font-[550] text-xl">
                Comments - Level Insights
              </p>

              <p className="text-[#8E8E93]">
                An overview of all comments on posts
              </p>
            </div>

            <TextInput
              height="100%"
              borderRadius="999px"
              placeholder="Search"
              className="w-[20rem] mr-5 text-[#6C747A] placeholder:text-[#6C747A]"
              leftIcon={
                <div style={{ marginLeft: 2, marginRight: 5 }}>
                  <Icon width={20} color="#D8D8D8" icon="iconamoon:search" />
                </div>
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.value)}
            />
          </div>

          <div className="px-5 flex pb-5 items-center space-x-4">
            {['Good Comments', 'Neutral Comments', 'Bad Comments'].map(
              (button, key) => (
                <TabButton
                  key={key}
                  btnText={button}
                  active={activeIndex === key}
                  onClick={() => setActiveIndex(key)}
                />
              )
            )}
          </div>

          <TableTitle />

          {comments?.length > 0 ? (
            comments?.map((comment) => (
              <CampaignInsightCard key={comment.id} comment={comment} />
            ))
          ) : (
            <p className="flex justify-center italic text-lg text-gray-500 my-12">
              No available comments
            </p>
          )}

          {comments?.length > 0 && <TablePagination />}
        </div>
      </div>
    </div>
  );
}

function TableTitle() {
  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-5 p-4 bg-[#EAECF0] gap-4"
    >
      <div>
        <p>Author</p>
      </div>

      <div>
        <p>Comments</p>
      </div>

      <div>
        <p>Channel</p>
      </div>

      <div>
        <p>Post URL</p>
      </div>

      <div>
        <p>Date Added</p>
      </div>
    </div>
  );
}
