import {
  createBlogPage,
  updateBlogPage,
  useGetPostInformation,
} from 'components/Blog/helpers';
import { useEffect, useRef, useState } from 'react';
import { generateTimestamp } from './components/VersionPrompt/helpers';
import { useNavigate } from 'react-router-dom';
import { ImageCropperValue } from '@wazobia-tech/wazcom/dist/gql/GqlImageCropper/types';
import { createImageSrcset } from './alt_helpers';
import { compareObjects } from 'utilities/helpers';

export const validatePostInformation = (form: any, status: boolean) => {
  const fields = status
    ? [
        'title',
        'content',
        'slug',
        'categories',
        'featured_image',
        'tags',
        'seo_description',
        'seo_url',
        'seo_title',
        'seo_image',
      ]
    : ['title', 'content', 'slug'];
  const invalidFields: string[] = [];

  fields.forEach((field) => {
    const fieldMapping: Record<string, string> = {
      featured_image: 'Featured Image',
      tags: 'Tags',
    };

    const mappedFieldName = fieldMapping[field] || field;

    if (field === 'categories') {
      status &&
        (!form?.categories || form?.categories?.length === 0) &&
        invalidFields.push(field);
    } else if (
      [
        'seo_title',
        'seo_description',
        'seo_url',
        'seo_image',
        'title',
        'content',
        'slug',
        'featured_image',
      ].includes(field)
    ) {
      if (!form[field] || form[field].trim().length === 0) {
        invalidFields.push(mappedFieldName);
      }
    } else if (!form['tags']?.length) {
      invalidFields.push(mappedFieldName);
    } else if (!form[field]) invalidFields.push(mappedFieldName);
  });

  return invalidFields.map(
    (field) => field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()
  );
};

export const decodeHtml = (html: string = '') => {
  var txt = document.createElement('div');
  txt.innerHTML = html;

  return txt.textContent || txt.innerText;
};

export const getVideoUrl = (url: string) => {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/);
  if (match && match[2].length === 11) {
    return 'https' + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
  }
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    // eslint-disable-line no-cond-assign
    return (
      (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
    );
  }
  return url;
};

export const useAutoSave = (
  initialData: any,
  other: { [key: string]: any },
  saveInterval = 30000
): [
  {
    [key: string]: any;
  },
  React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >
] => {
  const navigate = useNavigate();
  const localData = localStorage.getItem('formData');
  const storedData = localData ? JSON.parse(localData) : initialData;
  const getPostInformation = useGetPostInformation();
  const { blog_uuid } = other;

  const [formData, setFormData] = useState<{ [key: string]: any }>(storedData);

  const lastSavedDataRef = useRef(storedData);

  const handleSubmit = async (version: string, status: boolean) => {
    const variables = getPostInformation(formData, blog_uuid, status);

    if (!formData?.created_at) {
      await createBlogPage(blog_uuid, {
        ...variables,
        version,
        uuid: initialData.generated_uuid,
      })
        .then((res) => {
          setFormData((form) => ({
            ...form,
            post_uuid: res?.createBlogPost.uuid,
            created_at: res?.createBlogPost.created_at,
          }));
          navigate(`/cms/posts/${res?.createBlogPost.uuid}`, {
            replace: false,
          });
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      await updateBlogPage({
        ...variables,
        uuid: formData?.post_uuid as string,
        version,
        identifier: formData?.post_uuid as string,
      })
        .then((res: any) => {})
        .catch((error) => {});
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        showRightSidebar(formData) &&
        !compareObjects(formData, lastSavedDataRef.current)
      ) {
        const invalidFields = validatePostInformation(
          formData,
          formData?.status
        );

        if (invalidFields.length == 0) {
          await handleSubmit(generateTimestamp(), formData?.status);
        }

        localStorage.setItem('formData', JSON.stringify(formData));
        lastSavedDataRef.current = formData;
      }
    }, saveInterval);

    return () => clearInterval(interval);
  }, [formData, saveInterval]);

  return [formData, setFormData];
};

export function parseRecursiveHelper(data: string) {
  if (typeof data !== 'string' || data === '') return data;

  try {
    const parsedData = JSON.parse(data);
    if (typeof parsedData === 'string') {
      return parseRecursiveHelper(parsedData);
    }
    return parsedData;
  } catch (error) {
    return { src: data };
  }
}

export const parseRecursive = (data: string) => {
  let parsedData = parseRecursiveHelper(data);
  try {
    const temp = JSON.parse(parsedData?.src);
    return temp;
  } catch (e) {
    return parsedData;
  }
};

export const imageSizes =
  '(min-width: 1024px) 1100px, (min-width: 500px) 750px, 450px';

export const calculateCropCoordinates = (
  imageWidth: number,
  coordinates?: { desktop: Record<'left' | 'top' | 'right' | 'bottom', number> }
) => {
  if (!coordinates) return '';
  const left = Math.round(imageWidth * Number(coordinates.desktop['left']));
  const top = Math.round(imageWidth * Number(coordinates.desktop['top']));
  const right = Math.round(imageWidth * Number(coordinates.desktop['right']));
  const bottom = Math.round(imageWidth * Number(coordinates.desktop['bottom']));

  return `,cr-${left}.${top}.${right}.${bottom}`;
};

export const generateModifiedSrc = (
  src: string,
  width: number,
  coordinates: { desktop: Record<'left' | 'top' | 'right' | 'bottom', number> }
) => {
  let imageUrl = src;

  if (src.includes('unsplash')) {
    const indexOfQuestionMark = src.indexOf('?');
    if (indexOfQuestionMark !== -1) {
      imageUrl = src.substring(0, indexOfQuestionMark);
    }
  }

  const dolosUrl =
    hasImageServiceUrl(imageUrl) || src.includes('unsplash')
      ? ''
      : 'https://images.twistedfood.co.uk/';

  const validWidth = width === 0 ? 1024 : width;
  const queryParams = `tr=w-${Math.min(
    validWidth,
    1024
  )}${calculateCropCoordinates(Math.min(validWidth, 1024), coordinates)}`;

  let finalUrl = `${dolosUrl}${imageUrl}`;
  if (!imageUrl.includes('tr=w-')) {
    finalUrl += imageUrl.includes('?') ? '&' : '?';
    finalUrl += queryParams;
  }

  return finalUrl;
};

export const updateEditorImage = (
  image: HTMLImageElement,
  value: ImageCropperValue
) => {
  if (!value?.src) return;

  if (value?.alternative_text)
    image.setAttribute('alt', value?.alternative_text);
  else image.removeAttribute('alt');

  image.setAttribute('srcset', createImageSrcset(value));

  const encodedConfig = getEncodedConfig(value);

  let updatedSrc: string;
  const srcSplits = value.src.split('?');
  const url = new URL(value.src);
  const params = new URLSearchParams(url.search);

  if (srcSplits.length === 1) {
    params.set('config', encodedConfig);
    updatedSrc = srcSplits[0] + '?' + params.toString();
  } else {
    const trParams = params.get('tr');

    if (trParams) {
      params.delete('tr');
      params.set('tr', trParams);
    }
    if (params.get('config')) params.delete('config');
    params.set('config', encodedConfig);
    updatedSrc = srcSplits[0] + '?' + params.toString();
  }

  image.setAttribute('src', updatedSrc);
};

export const getEncodedConfig = (value: ImageCropperValue) => {
  const config: Record<string, any> = {};

  const params = [
    'name',
    'description',
    'alternative_text',
    'caption',
    'coordinates',
    'width',
    'height',
    'uuid',
  ];
  const jsonifiedValue = JSON.parse(JSON.stringify(value));

  params.forEach((param) => {
    if (jsonifiedValue[param]) config[param] = jsonifiedValue[param];
  });

  return btoa(encodeURIComponent(JSON.stringify(config)));
};

export const hasImageServiceUrl = (src: string = '') => {
  if (
    src.includes('img.wazobia.tech') ||
    src.includes('images.twistedfood.co.uk')
  ) {
    return true;
  }
  return false;
};

export const showRightSidebar = (formData: { [key: string]: any }) => {
  if (window.location.pathname.includes('/new')) {
    return true;
  }
  return Boolean(formData?.post_uuid);
};

export const addPrefixToObjectKeys = (
  obj: { [key: string]: any },
  prefix: string
) => {
  const newObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[prefix + key] = obj[key];
    }
  }

  return newObj;
};
