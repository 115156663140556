import Post from './Post';
import { Icon } from '@iconify/react';
import TablePagination from '../common/TablePagination';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { GET_POSTS_BY_CAMPAIGN_UUID } from 'graphql/jcmat/posts';
import { useQuery } from '@apollo/client';
import { caClient as client } from 'pages/Ca/client';
import { Post as GqlPost } from 'generated/jcmat/graphql';
import { useState } from 'react';
import { useModal } from '../common/ModalManager';
import AddPostToCampaign from '../campaign/forms/AddPostToCampaignForm';

interface Props {
  campaignUuid: string;
}

export default function PostsTable({ campaignUuid }: Props) {
  const take = 10;
  const [page, setPage] = useState(1);
  const { showModal } = useModal();
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading, error, refetch } = useQuery(
    GET_POSTS_BY_CAMPAIGN_UUID,
    {
      variables: { take, skip: (page - 1) * take, campaignUuid },
      client,
    }
  );
  const posts = data?.postsByCampaign?.posts;

  const filteredPosts = posts?.filter((post: GqlPost) =>
    post?.text?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="overflow-x-scroll">
      <div
        style={{ border: '2px solid #EAECF0' }}
        className="rounded-[12px] min-w-[70rem]"
      >
        <div className="p-5 flex justify-between items-center">
          <div className="space-y-2">
            <p className="text-[#101828] font-[550] text-xl">
              Social Media Posts
            </p>

            <p className="text-[#8E8E93]">
              Track and analyze sentiment across your recent social media posts
              search
            </p>
          </div>

          <div className="flex h-[60px] w-full max-w-[30rem] items-center space-x-10">
            <TextInput
              height="100%"
              borderRadius="999px"
              placeholder="Search"
              className="w-full text-[#6C747A] placeholder:text-[#6C747A]"
              leftIcon={
                <div style={{ marginLeft: 2, marginRight: 5 }}>
                  <Icon width={20} color="#D8D8D8" icon="iconamoon:search" />
                </div>
              }
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.value)}
            />

            <div>
              <button
                onClick={() =>
                  showModal(<AddPostToCampaign campaignUuid={campaignUuid} />)
                }
                className="bg-black px-5 text-white flex items-center space-x-2 rounded-full py-2 h-[43px]"
              >
                <Icon width={16} color="white" icon="line-md:plus" />

                <span className="mb-[2px] whitespace-nowrap">
                  Add a new post
                </span>
              </button>
            </div>
          </div>
        </div>

        <TableTitle />

        {filteredPosts.length > 0 ? (
          filteredPosts.map((post: GqlPost, index: number) => (
            <Post key={index} {...post} />
          ))
        ) : (
          <p className="flex justify-center italic text-lg text-gray-500 my-12">
            No available posts
          </p>
        )}

        {filteredPosts.length > 0 && (
          <TablePagination
            refetch={refetch}
            campaignUuid={campaignUuid}
            take={take}
            postPage={page}
            setPostPage={setPage}
          />
        )}
      </div>
    </div>
  );
}

function TableTitle() {
  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-[repeat(13,_minmax(0,_1fr))] p-4 bg-[#EAECF0] gap-4"
    >
      <div className="col-span-1">
        <p>Posts</p>
      </div>

      <div className="col-span-2">
        <p>Caption</p>
      </div>

      <div className="col-span-2">
        <p>Post URL</p>
      </div>

      <div className="col-span-2">
        <p>Channel</p>
      </div>

      <div className="col-span-2">
        <p>Post Engagement</p>
      </div>

      <div className="col-span-2">
        <p>Sentiment Analysis</p>
      </div>

      <div className="col-span-2">
        <p>Date Added</p>
      </div>
    </div>
  );
}
