import { gql } from '@apollo/client';

export const EDITSURVEY = gql`
  mutation updateSurvey(
    $id: ID!
    $name: String
    $url: String
    $recipients: [ID!]
    $all: Boolean
  ) {
    updateSurvey(
      id: $id
      name: $name
      url: $url
      recipients: $recipients
      all: $all
    ) {
      message
    }
  }
`;

export const FETCHSURVEY = gql`
  query getSurveyByID($id: ID!) {
    survey(id: $id) {
      name
      url
      recipients
    }
  }
`;
