import { FC, useMemo } from 'react';
import { CheckBoxInput } from '@wazobia-tech/wazcom/dist/io';
import { usePostStyles } from './styles';

export type PostsProps = {
  uuid: string;
  title: string;
  published_at: string;
  formData: { [key: string]: any };
  setFormData: any;
};

export const Post: FC<PostsProps & { isDialog?: boolean }> = ({
  uuid,
  title,
  published_at,
  formData,
  setFormData,
  isDialog,
}) => {
  const classes = usePostStyles({ isDialog: isDialog ?? false });

  const isPostSelected = () =>
    useMemo(
      () =>
        Boolean(
          formData?.related_posts_config
            ?.map((post: any) => post?.uuid)
            ?.includes(uuid)
        ),
      [formData?.related_posts_config]
    );

  const handlePostSelect = ({
    value: checked,
  }: {
    name: string;
    value: boolean;
  }) => {
    let newRelatedPostsConfig;
    if (!checked)
      newRelatedPostsConfig = (formData?.related_posts_config ?? []).filter(
        (post: any) => post?.uuid !== uuid
      );
    else {
      const initialPostsConfig =
        (formData?.related_posts_config ?? []).length === 3
          ? (formData?.related_posts_config ?? []).slice(0, 2)
          : formData?.related_posts_config ?? [];

      const postsConfig = [
        ...initialPostsConfig,
        { title, uuid, published_at },
      ];
      let postsConfigUuids = postsConfig?.map(
        ({ uuid }: { uuid: string }) => uuid
      );
      postsConfigUuids = [...new Set([...(postsConfigUuids ?? []), uuid])];

      newRelatedPostsConfig = postsConfigUuids?.map((uuid: string) =>
        postsConfig?.find((post: any) => post?.uuid === uuid)
      );
    }
    setFormData({
      ...formData,
      ['related_posts_config']: newRelatedPostsConfig,
    });
  };

  return (
    <div className={classes.root}>
      <CheckBoxInput
        classNames={{ classes: { root: classes.postCheckBox } }}
        onChange={handlePostSelect}
        value={isPostSelected()}
      />
      <div className={classes.postContent}>
        <p className={classes.postTitle}>{title}</p>
        <p className={classes.postPublishedAt}>
          Published{' '}
          <span>{published_at?.replace(/-/g, '/')?.replace(/ /g, ' - ')}</span>
        </p>
      </div>
    </div>
  );
};
