import Channel from './Channel';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { Divider } from '@mui/material';
import PopoverItem from '../common/PopoverItem';
import Popover from '@mui/material/Popover';
import PostEngagement from './PostEngagement';
import SentimentResult from '../common/SentimentResult';
import imagePlaceholder from '../../../../assets/imgs/jp_CA.jpeg';
import { Post as GqlPost } from 'generated/jcmat/graphql';
import { formatDate } from '../helper';

export default function Post({
  text,
  url,
  commentsCount,
  sentiment,
  createdAt,
  imageUrl,
}: GqlPost) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formattedDate, formattedTime } = formatDate(createdAt);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-[repeat(13,_minmax(0,_1fr))] p-4 gap-4"
    >
      <div className="col-span-1">
        <div className="max-w-full">
          <img
            className="w-24 h-20 rounded"
            alt="post image"
            src={imageUrl ?? imagePlaceholder}
          />
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full space-y-2">
          <p className="truncate text-[#101828] font-[500]">{text}</p>
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full">
          <p className="truncate text-[#475467]">{url}</p>
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full">
          <Channel
            postChannel={url.includes('facebook') ? 'facebook' : 'instagram'}
          />
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full space-y-2">
          <PostEngagement engagementCount={0} engagementType="mentions" />
          <PostEngagement
            engagementCount={commentsCount}
            engagementType="comments"
          />
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full w-full">
          <SentimentResult
            result={{
              sad: sentiment.negative,
              happy: sentiment.positive,
              neutral: sentiment.neutral,
            }}
          />
        </div>
      </div>

      <div className="col-span-2 h-full flex items-center">
        <div className="max-w-full flex items-center justify-between w-full space-x-3">
          <div className="space-y-2">
            <p className="truncate text-[#344054] font-[500]">
              {formattedDate}
            </p>

            <p className="truncate text-[#A9A9A9]">{formattedTime}</p>
          </div>

          <div>
            {/* <button
              aria-describedby="simple-popover"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Icon
                color="#98A2B3"
                icon="ph:dots-three-outline-vertical-fill"
              />
            </button> */}

            <Popover
              open={open}
              id={popoverId}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className="w-[13.5rem]">
                <PopoverItem btnText="Post details" icon="carbon:view" />

                <Divider style={{ borderColor: '#dfe3e9' }} />

                <PopoverItem btnText="View comments" icon="uil:comments" />

                <Divider style={{ borderColor: '#dfe3e9' }} />

                <PopoverItem
                  btnText="Remove post"
                  icon="solar:trash-bin-2-outline"
                />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}
