import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { FETCHSURVEYS, DELETESURVEY } from './graphQL';
import { useQuery, useMutation } from '@apollo/client';
import useStyles from '../ViewRedirect/styles';
import { Select } from '../common/Select/Select';
import { Button } from '../common/Button/Button';
import { CircularProgress } from '@mui/material';
import TextField from '../common/TextField/TextField';
import { SurveyType } from '../../types/SurveyType';
import Table from '../common/Table/Table';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../utilities/notify';

const Surveys = () => {
  const [paginator, setPaginator] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const { data, error, loading } = useQuery(FETCHSURVEYS, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const { toggleSpinner } = useContext(AuthContext);
  const [deleteSurveyMutation] = useMutation(DELETESURVEY);
  const [surveys, setSurveys] = useState<SurveyType[]>([]);
  const [displayedSurveys, setDisplayedSurveys] = useState<SurveyType[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      let surveys: SurveyType[] = data.surveys.data;
      surveys = surveys.map(({ id, name, creator, url }) => {
        return { id, name, creator, url };
      });
      setSurveys(surveys);
      setDisplayedSurveys(surveys);
      const paginatorInfo = data?.surveys?.paginatorInfo;
      setPaginator(paginatorInfo);
    }

    if (error) {
      notifyError('An Error Occured');
    }
  }, [data, error]);

  const actionsHandler = (
    event: React.ChangeEvent<{ name?: string; value: string }>,
    id: number
  ) => {
    const value = event.target.value;
    if (value === 'Delete') {
      deleteSurvey(id);
    }

    if (value === 'Edit') {
      navigate(`/admin/surveys/edit/${id}`);
    }
  };

  const updateRedirects = useCallback(
    (id: number) => {
      let idx = surveys.findIndex(
        (survey) => survey.id.toString() === id.toString()
      );
      const newSurveys = [...surveys];
      newSurveys.splice(idx, 1);
      setSurveys(newSurveys);
      idx = displayedSurveys.findIndex(
        (survey) => survey.id.toString() === id.toString()
      );
      const newDisplayedSurveys = [...displayedSurveys];
      newDisplayedSurveys.splice(idx, 1);
      setDisplayedSurveys(newDisplayedSurveys);
    },
    [surveys, displayedSurveys]
  );

  const deleteSurvey = async (id: number) => {
    const proceed = window.confirm('are you sure you want to delete ?');
    if (!proceed) {
      return;
    }
    const variables = { id };
    toggleSpinner(true);
    try {
      await deleteSurveyMutation({ variables });
      notifySuccess('Survey Deleted Successfully');
      updateRedirects(variables.id);
    } catch (error) {
      notifyError('An Error Occured');
    } finally {
      toggleSpinner(false);
    }
  };

  const searchSurveys = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      setSearchText(event.target.value);
      const filteredSurveys = surveys.filter((survey) =>
        survey.name.toLowerCase().includes(event.target.value)
      );
      setDisplayedSurveys(filteredSurveys);
    },
    // eslint-disable-next-line
    [displayedSurveys, surveys]
  );

  const schema: SchemaProps[] = [
    {
      label: 'TITLE',
      func: ({ item }) => get(item, 'name'),
    },
    {
      label: 'SURVEY URL',
      func: ({ item }) => get(item, 'url'),
    },
    {
      label: 'CREATOR',
      func: ({ item }) => get(item, 'creator'),
    },
    {
      label: 'ACTIONS',
      func: ({ item }) => (
        <div style={{ width: '110px' }}>
          <Select
            value="Actions"
            options={['Actions', 'Edit', 'Delete']}
            onChange={(e) => {
              actionsHandler(e, get(item, 'id'));
            }}
            noStyle={true}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Surveys</p>
        <div className={classes.searchBox}>
          <Button
            title="Create Survey"
            onClick={() => {
              navigate('/admin/surveys/create');
            }}
          />
        </div>
      </div>

      <div className={classes.main}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <>
            <Table
              schema={schema}
              collection={displayedSurveys}
              pagination={true}
              paginatorInfo={paginator}
              rowsPerPage={rows}
              changePage={(e) => setPage(e + 1)}
              changeRow={(e) => setRows(e + 1)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Surveys;
