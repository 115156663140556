import axios from 'axios';
import { addScriptTag } from 'utilities/helpers';

export function extractTikTokTwitterUsernameVideoId(url: string) {
  const tiktokRegex = /@([^/]+)\/video\/(\d+)/;
  const twitterRegex = /(?:twitter|x)\.com\/([^/]+)\/status\/(\d+)/;
  const match = url.match(tiktokRegex) || url.match(twitterRegex);

  if (match && match[1] && match[2]) {
    return {
      username: match[1],
      videoId: match[2],
    };
  } else {
    return null;
  }
}

export function extractInstagramFacebookPostId(inputString: string) {
  const instagramRegex = /instagram\.com\/p\/([^/]+)/;
  const facebookRegex = /fbid=(\d+)/;
  const match =
    inputString.match(instagramRegex) || inputString.match(facebookRegex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

export const addDimensionToFacebookIframe = (htmlString: string = '') => {
  const tempElement: HTMLDivElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const iframeElement: HTMLIFrameElement = tempElement.querySelector('iframe')!;
  const spanElement: HTMLSpanElement | null = tempElement.querySelector('span');

  const width: string = spanElement?.style.width.replace('px', '') ?? '560';
  const height: string = spanElement?.style.height.replace('px', '') ?? '600';

  iframeElement.setAttribute('width', width);
  iframeElement.setAttribute('height', height);

  const srcUrl: string = iframeElement.getAttribute('src')!;
  const updatedSrcUrl: URL = new URL(srcUrl);
  updatedSrcUrl.searchParams.append('width', width);
  updatedSrcUrl.searchParams.append('height', height);
  iframeElement.setAttribute('src', updatedSrcUrl.toString());

  return iframeElement.outerHTML;
};

export const addDimensionToTikTokIframe = (htmlString: string = '') => {
  const tempElement: HTMLDivElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const iframeElement: HTMLIFrameElement = tempElement.querySelector('iframe')!;

  const width: string = iframeElement.style.width.replace('px', '');
  const height: string = iframeElement.style.height.replace('px', '');

  const srcUrl: string = iframeElement.getAttribute('src')!;
  const updatedSrcUrl: URL = new URL(srcUrl);
  updatedSrcUrl.searchParams.append('width', width);
  updatedSrcUrl.searchParams.append('height', height);
  iframeElement.setAttribute('src', updatedSrcUrl.toString());

  return iframeElement.outerHTML;
};

export const addDimensionToInstagramIframe = (htmlString: string = '') => {
  const tempElement: HTMLDivElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const iframeElement: HTMLIFrameElement = tempElement.querySelector('iframe')!;

  const height = iframeElement?.getAttribute('height')?.replace('px', '') ?? '';

  const srcUrl: string = iframeElement?.getAttribute('src')!;
  const updatedSrcUrl: URL = new URL(srcUrl);
  updatedSrcUrl.searchParams.append('height', height);
  iframeElement?.setAttribute('src', updatedSrcUrl.toString());

  return iframeElement.outerHTML;
};

export const addDimensionToRedditIframe = (htmlString: string = '') => {
  const tempElement: HTMLDivElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const iframeElement: HTMLIFrameElement = tempElement.querySelector('iframe')!;

  const height = iframeElement?.getAttribute('height')?.replace('px', '') ?? '';
  const width = iframeElement?.getAttribute('width')?.replace('px', '') ?? '';

  const srcUrl: string = iframeElement?.getAttribute('src')!;
  const updatedSrcUrl: URL = new URL(srcUrl);
  updatedSrcUrl.searchParams.append('height', height);
  updatedSrcUrl.searchParams.append('width', width);
  iframeElement?.setAttribute('src', updatedSrcUrl.toString());

  return iframeElement.outerHTML;
};

export const loadEmbedScripts = async () => {
  await addScriptTag({ src: `https://www.instagram.com/embed.js` });
  await addScriptTag({ src: `https://platform.twitter.com/widgets.js` });
  await addScriptTag({
    src: `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0`,
    tag: 'body',
  });
  await addScriptTag({ src: '//embed.redditmedia.com/widgets/platform.js' });
};
