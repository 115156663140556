import { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GETALLCHANNELSTORIES, GETCHANNELINFO } from '../graphQl/graphQl';
import { cpasClient } from '../../../utilities/apolloClient';

export interface MonthMapping {
  [key: string]: number;
}

const useSelectChange = () => {
  const previousMonthStart = moment().subtract(1, 'month').startOf('month');
  const formattedFirstDayOfPreviousMonth =
    previousMonthStart.format('YYYY-MM-DD');
  const lastDateOfPreviousMonth = moment().startOf('month').subtract(1, 'day');
  const formattedLastDateOfPreviousMonth =
    lastDateOfPreviousMonth.format('YYYY-MM-DD');
  const [value, setValue] = useState<string>(
    moment().subtract(1, 'months').format('MMMM')
  );
  const [showCustomRange, setShowCustomRange] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(
    formattedFirstDayOfPreviousMonth
  );
  const [selectedEndDate, setSelectedEndDate] = useState<string>(
    formattedLastDateOfPreviousMonth
  );

  const handleSelectChange = (
    e: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const selectedOption = e.target.value.toLowerCase();
    if (selectedOption === 'custom' || /-/.test(selectedOption)) {
      setShowCustomRange(true);
      setValue(e.target.value);
    } else {
      setShowCustomRange(false);

      const currentYear = moment().year();
      const monthMapping: MonthMapping = {
        january: 0,
        february: 1,
        march: 2,
        april: 3,
        may: 4,
        june: 5,
        july: 6,
        august: 7,
        september: 8,
        october: 9,
        november: 10,
        december: 11,
      };
      const selectedMonthIndex = monthMapping[selectedOption];
      const startDate = moment([currentYear, selectedMonthIndex, 1]).format(
        'YYYY-MM-DD'
      );
      setSelectedEndDate(
        moment([currentYear, selectedMonthIndex, 1])
          .endOf('month')
          .format('YYYY-MM-DD')
      );
      setSelectedStartDate(startDate);
      setValue(e.target.value);
    }
  };

  return {
    value,
    showCustomRange,
    selectedStartDate,
    selectedEndDate,
    handleSelectChange,
    setShowCustomRange,
    setSelectedStartDate,
    setSelectedEndDate,
  };
};

export default useSelectChange;

export const useChannelInfo = (
  businessProfileId: string | undefined,
  selectedStartDate: string,
  selectedEndDate: string,
  cpasClient: any,
  allChannel: boolean,
  brand: string
) => {
  const formattedBrand = brand.includes('(Brand)')
    ? brand.replace('(Brand)', '')
    : brand;
  const {
    data: channelInfoData,
    error: channelInfoError,
    loading: loadingChannel,
  } = useQuery(GETCHANNELINFO, {
    variables: {
      businessProfileId,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      all_channels: allChannel,
      brand: formattedBrand,
    },
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  return { channelInfoData, channelInfoError, loadingChannel };
};
