import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../../contexts/theme';

const useSocialStyles = makeStyles((theme: BaseTheme) => ({
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content__input: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  content__title: {
    fontSize: theme.typography?.fontSize + 6,
    color: theme.design.colors.grey100,
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
  },
  content__form: {
    background: theme.design.colors.white,
    padding: '16px',
    borderRadius: '4px',
  },
  primary: {
    background: `${theme.design.colors.white} !important`,
    color: `${theme.design.colors.black} !important`,
  },
  formInput: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  content__form_image: {
    height: '350px',
    width: '100%',
    background: 'rgba(0,0,0,0.02)',
    marginBottom: '2rem',
  },
  content__form_section: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    paddingBottom: theme.spacing(3),
  },
  switchOptionsParent: {
    paddingBottom: theme.spacing(3),
  },
  content__form_label: {
    fontSize: theme.typography?.fontSize,
    marginBottom: theme.spacing(0.5),
    color: theme.design.colors.darkCharcoal,
  },
  tabContainer: {
    width: 118,
    paddingTop: theme.spacing(3),
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
      background: theme.design.colors.grey150,
    },
  },
  content__form_error: {
    marginTop: theme.spacing(2),
    fontSize: '14px',
    color: '#EA3C53',
  },
  content__form_actions: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  tab: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1, 0),
    width: 'calc(100% - 16px)',
    display: 'flex',
    position: 'relative',
    left: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      background: theme.design.colors.black,
      color: theme.design.colors.white,
      borderTopLeftRadius: '25px',
      borderBottomLeftRadius: '25px',
    },
  },
  activeTab: {
    background: theme.design.colors.black,
    color: theme.design.colors.white,
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
  },
  tabs: {
    background: theme.design.colors.white,
    position: 'fixed',
    zIndex: 20,
    height: 'calc(100vh - 60px)',
    right: 0,
    bottom: 0,
    border: `1px solid ${theme.design.colors.greyPitch}`,
    display: 'flex',
    flexDirection: 'column',
  },
  tabName: {
    fontSize: theme.typography.fontSize,
    textAlign: 'center',
  },
  controls: {
    position: 'fixed',
    zIndex: 20,
    right: 118,
    height: 'calc(100% - 108px)',
    top: 60,
    overflowY: 'auto',
    width: '25%',
    background: theme.design.colors.white,
    padding: theme.spacing(3, 3, 5, 3),
    borderRight: '1px solid black',
  },
  title: {
    fontSize: theme.typography.fontSize + 2,
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  schemaTitle: {
    marginBottom: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2),
  },
  headerTitle: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  active: {
    color: theme.design.colors.white,
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
  },
  fileField: {
    width: '50%',
  },
  fullFileField: {
    width: 340,
    height: 120,
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    paddingBottom: theme.spacing(1.5),
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subText: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.greyDark,
    paddingBottom: theme.spacing(2),
  },
  categoryLabel: {
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(1),
  },
  form_top_content: {
    width: '49%',
  },
  twitterSubHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  publishOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  radioBtn: {
    fontSize: theme.typography.fontSize,
  },
  switchParent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchLabel: {
    fontSize: theme.typography.fontSize,
  },
  same: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.jpGrey300,
    fontWeight: theme.typography.fontWeightMedium,
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'white',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  excerptText: {
    height: 84,
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize,
  },
  selectContainer: {
    position: 'relative',
    height: '36px',
    background: ' rgba(221, 221, 221, 1)',
  },
  selectBox: {
    marginBottom: theme.spacing(2),
  },
  contentLabel: {
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.fontSize,
  },
  tags: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    gap: theme.spacing(3),
    display: 'flex',
  },
  formDescription: {
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(1.5),
  },
  bottomContentDesc: {
    fontSize: theme.typography?.fontSize + 2,
    fontWeight: theme.typography?.fontWeightMedium,
  },
  topSpace: {
    marginTop: theme.spacing(2),
  },
  noSpace: {
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  adjustInput: {
    width: 'calc(100% - 24px)',
  },
  seoAnalysisTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.4),
    fontSize: theme.typography.fontSize,
  },
  seoAnalysis: {
    display: 'grid',
    alignItems: 'start',
    gap: theme.spacing(0.8),
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(1, 0),
    gridTemplateColumns: '5% 94%',
    justifyContent: 'space-between',
  },
  seoRemark: {
    paddingLeft: theme.spacing(0.5),
  },
  postStatus: {
    padding: theme.spacing(0.3, 1.2),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.design.colors.grey100}`,
  },
  postStatusPublished: {
    border: `1px solid ${theme.design.colors.waz_successColor200}`,
    backgroundColor: theme.design.colors.waz_successColor200,

    '& > p': {
      color: theme.design.colors.white,
    },
  },
  postStatusText: {
    fontSize: theme.typography.fontSize - 2,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.design.colors.grey100,
  },
}));

export default useSocialStyles;
