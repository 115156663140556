import { gql } from '@apollo/client';

export const CREATEPURCHASEORDER = gql`
  mutation createPurchaseOrder(
    $vendor_name: String!
    $vendor_contact: String!
    $slt_approved: Boolean!
    $current_budget: Boolean!
    $requirement: String!
    $total: Float!
    $items: [String]!
    $purchase_date: Date!
    $requestor_id: ID!
  ) {
    createPurchaseOrder(
      vendor_name: $vendor_name
      vendor_contact: $vendor_contact
      slt_approved: $slt_approved
      current_budget: $current_budget
      requirement: $requirement
      total: $total
      items: $items
      purchase_date: $purchase_date
      requestor_id: $requestor_id
    ) {
      id
    }
  }
`;

export const GET_PURCHASE_ORDER = gql`
  query GqlGetPurchaseOrder($uuid: ID!) {
    getPurchaseOrderByUuid(uuid: $uuid) {
      id
      uuid
      vendor_name
      vendor_contact
      slt_approved
      acc_approved
      approved_at
      current_budget
      requirement
      total
      items
      purchase_date
      created_at
      updated_at
      po_number
      status
      requestor {
        name
        department
        role
        position
        email
        id
      }
    }
  }
`;

export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder(
    $id: ID!
    $vendor_name: String!
    $vendor_contact: String!
    $slt_approved: Boolean!
    $current_budget: Boolean!
    $requirement: String!
    $total: Float!
    $items: [String]!
    $purchase_date: Date!
    $requestor_id: ID!
    $status: PurchaseOrderStatus
  ) {
    updatePurchaseOrder(
      id: $id
      vendor_name: $vendor_name
      vendor_contact: $vendor_contact
      slt_approved: $slt_approved
      current_budget: $current_budget
      requirement: $requirement
      total: $total
      items: $items
      purchase_date: $purchase_date
      requestor_id: $requestor_id
      status: $status
    ) {
      id
    }
  }
`;
