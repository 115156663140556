import { useState, useEffect } from 'react';
import clx from 'classnames';
import { PAGECONTENT } from './graphQL';
import { useLazyQuery } from '@apollo/client';
import useStyles from './styles';
import { notifyError } from '../../utilities/notify';
import {
  welcomeTabs,
  cultureTabs,
  hrTabs,
  officeTabs,
  financeTabs,
  getContent,
  faqContent,
} from './data';
import { SectionCard } from './components/SectionCard';
import { BreadCrumb } from './components/BreadCrumb';
import { useLocation } from 'react-router-dom';
import { getContentFuntion, getRoute } from './helper';
import Spinner from 'components/common/Spinner/Spinner';
import { DetermineTabsProps, ItemProps, PageProps } from './pageContents/types';
import { faqTabs } from 'pages/Content/data';
import { Accordion } from '@wazobia-tech/wazcom/dist/core';

const SubPageContent = () => {
  const [page, setPage] = useState<PageProps | null>(null);
  const [modal, setModal] = useState(0);
  const [openAccordion, setOpenAccordion] = useState(0);

  const { pathname } = useLocation();
  const slug = pathname.split('/').slice(-1)[0];
  const specificPathname = pathname.split('/').slice(1)[0];
  const determineTabs = (): DetermineTabsProps => {
    if (specificPathname.includes('welcome')) return welcomeTabs;
    else if (specificPathname.includes('culture')) return cultureTabs;
    else if (specificPathname.includes('hr')) return hrTabs;
    else if (specificPathname.includes('office')) return officeTabs;
    else if (specificPathname.includes('finance')) return financeTabs;
    else if (specificPathname.includes('faq')) return faqTabs;
    else return [];
  };
  const [fetchPage, { data, loading, error }] = useLazyQuery(PAGECONTENT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const singleSubSection = location?.pathname?.split('/')?.[2];
  const hasSubSection = determineTabs().filter(
    (tab) => tab.route === singleSubSection
  )[0];
  const [subSection, setSubSection] = useState({
    name:
      hasSubSection?.subPages?.[0]?.classifications?.[0].name ??
      hasSubSection?.subPages?.[0]?.name,
    route:
      hasSubSection?.subPages?.[0]?.classifications?.[0].route ??
      hasSubSection?.subPages?.[0]?.route,
  });

  const classes = useStyles();

  useEffect(() => {
    if (getRoute({ array: hrTabs, route: slug })) {
      fetchPage({ variables: { slug: subSection?.route } });
      return;
    }
    pathname.split('/').slice(-2)[0] !== 'faq' &&
      fetchPage({ variables: { slug } });
  }, [subSection]);

  useEffect(() => {
    if (data && data.content) {
      setPage(data.content);
    }

    if (data && !data.content) {
      notifyError('Page Does Not Exist');
      setPage(null);
    }

    if (error) {
      notifyError('An Error Occured');
    }
  }, [data, error]);

  const imgs = [
    'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-05T114703/LoarOsXnQ21OiYlqOUpWkV87ocna8HBLt3welvHt.png',
    'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-05T113602/D5PeSFNqz7kefOOutFJMrXIS8bAjcaL3mQvBBfcr.png',
  ];

  const isFaq = location.pathname.includes('faq');

  const filteredData = faqContent.filter((data) => data?.[slug])?.[0]?.[slug];

  return (
    <section className={classes.root}>
      <BreadCrumb />
      <SectionCard titles={determineTabs()} />
      <div
        className={clx(classes.container, !hasSubSection?.subPages && 'single')}
      >
        {imgs.map((img, index) => (
          <img
            key={index}
            className={clx(
              index === 1 ? classes.imageTop : classes.imageBottom
            )}
            src={img}
          />
        ))}
        {hasSubSection && (
          <aside className={classes.aside}>
            {hasSubSection?.subPages?.map((item: ItemProps, index: number) => (
              <>
                <div
                  key={index}
                  className={clx(
                    classes.asideContainer,
                    subSection?.route === item.route && classes.activeTab,
                    classes.links
                  )}
                  onClick={() =>
                    !item?.classifications?.length
                      ? setSubSection({ name: item?.name, route: item?.route })
                      : setModal(index === modal ? -1 : index)
                  }
                >
                  {item.name}
                </div>
                {item?.classifications?.length && modal === index && (
                  <div className={classes.classification}>
                    {item?.classifications?.map(
                      (classification, index: number) => (
                        <div
                          key={index}
                          className={clx(
                            classes.link,
                            subSection?.route === classification.route &&
                              classes.activeTab
                          )}
                          onClick={() =>
                            setSubSection({
                              name: classification?.name,
                              route: classification?.route,
                            })
                          }
                        >
                          <p>{classification?.name}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            ))}
          </aside>
        )}
        {isFaq ? (
          <div
            className={clx(classes.content, {
              [classes.content__loading]: loading,
            })}
          >
            {filteredData.map(
              (data: { question: string; answer: string }, index: number) => {
                return (
                  <Accordion
                    title={data.question}
                    isExpanded={openAccordion === index}
                    className={classes.accordion}
                    key={index}
                  >
                    <p>{data?.answer}</p>
                  </Accordion>
                );
              }
            )}
          </div>
        ) : (
          <article>
            {!loading && page ? (
              <div
                className={clx(classes.content, {
                  [classes.content__loading]: loading,
                })}
              >
                <p className={classes.content__title}>{page?.title}</p>
                <div
                  className={classes.content__details}
                  dangerouslySetInnerHTML={{ __html: page?.content }}
                />
              </div>
            ) : (
              <div className={clx(classes.content__loader)}>
                <Spinner display={true} variant="large" />
              </div>
            )}
          </article>
        )}
      </div>
    </section>
  );
};

export default SubPageContent;
