// this section holds the sample data used for the formbuilder to build forms, it is here to provide an idea of how to create fields in the form builder
export const data = {
  heroCarousel: {
    required: true,
    sections: {
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'recipeUrl',
            },
          },
        ],
        data: [
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/twisted-green/sweet-potato-and-charred-corn-california-salad',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/twisted-green/vegan-miso-butter-crab-linguine',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/twisted-green/summer-roll-salad',
          },
        ],
      },
    },
    enabled: true,
  },
  latestRecipes: {
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'heading',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionText',
                    defaultValue: 'see all recipes',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionLink',
                    defaultValue: '/recipes',
                  },
                },
              ],
            },
          },
        ],
        data: {
          heading: 'Latest recipes',
          actionText: 'See all recipes',
          actionLink: '/recipes',
        },
      },
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'recipeUrl',
              value:
                'https://twistedfood.co.uk/recipes/twisted-green/vegan-miso-butter-crab-linguine',
            },
          },
        ],
        data: [
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/twisted-green/moroccan-style-spaghetti-and-aubergine-meatballs-twisted-green',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/chicken/fried-chicken-parmesan-parcels',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/vegetarian/korean-buffalo-mushroom-wings',
          },
        ],
      },
    },
    enabled: true,
  },
  featuredRecipes: {
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'heading',
              defaultValue: 'Featuring',
            },
          },
        ],
        data: {
          heading: 'Featuring',
        },
      },
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'url',
            },
          },
        ],
        data: [
          {
            url: 'https://twistedfood.co.uk/recipes/spicy/korean-style-short-rib-croquettes',
          },
          {
            url: 'https://twistedfood.co.uk/recipes/chicken/fried-chicken-parmesan-parcels',
          },
          {
            url: 'https://twistedfood.co.uk/recipes/vegetarian/korean-buffalo-mushroom-wings',
          },
        ],
      },
    },
    enabled: true,
  },
  lifestyle: {
    required: true,
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'heading',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionText',
                    defaultValue: 'see all recipes',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionLink',
                    defaultValue: '/recipes',
                  },
                },
              ],
            },
          },
        ],
        data: {
          heading: 'Lifestyle',
          actionText: 'see all articles',
          actionLink: '/articles',
        },
      },
      articles: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'articleLink',
            },
          },
        ],
        data: [
          {
            articleLink:
              'https://twistedfood.co.uk/articles/interviews/notting-hill-carnival-best-food-stalls-caribbean-festival-food-street',
          },
          {
            articleLink:
              'https://twistedfood.co.uk/articles/features/hoppers-founder-karan-gokani-south-asian-heritage-month-sri-lankan-food-indian',
          },
          {
            articleLink:
              'https://twistedfood.co.uk/articles/features/best-burgers-in-london-places-burger-chicken-vegan',
          },
        ],
      },
      features: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'featureArticleLink',
              defaultValue:
                'https://twistedfood.co.uk/articles/news/candy-funhouse-chief-officer-six-figures-apply',
            },
          },
        ],
        data: [
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/features/best-rooftop-restaurants-london-central-aviary-garden',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/full-english-breakfast-pizza-how-make-instagram',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/heinz-launches-hash-browns-filled-with-baked-beans',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/lotus-biscoff-cream-bar-coop-chocolate-uk-where-buy',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/british-food-opinions-twitter-debate-american',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/features/music-festival-food-stall-overheard-working-funny',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/salt-bae-london-restaurant-7m-four-months-profits-reviews',
          },
        ],
      },
    },
    enabled: true,
  },
  motivation: {
    sections: {
      backgroundImage: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'desktopImage',
                  },
                },
                {
                  type: 'file',
                  properties: {
                    name: 'mobileImage',
                  },
                },
              ],
            },
          },
        ],
        data: {
          desktopImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/g1cxCtV3qgc5jmMvOCpdDJj719YdJi4FeA1DsMLY.webp',
          mobileImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/gEEpYZJJDY7OSF4DBFfIDDOXOrjqAAyJtRWmKHeR.png',
        },
      },
      quote: {
        required: true,
        enabled: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'textarea',
                  properties: {
                    name: 'paragraph',
                    value: 'keith floys',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'author',
                  },
                },
              ],
            },
          },
        ],
        data: {
          paragraph:
            'To hell with fashion, trends and gastronomic one-upmanship. Cooking and eating should be fun.',
          author: 'Keith Floyd',
        },
      },
    },
    enabled: true,
  },
  shows: {
    required: true,
    sections: {
      heading: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
        ],
        data: {
          title: 'Watch our shows',
        },
      },
      featuredEpisodes: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'url',
            },
          },
        ],
        data: [
          {
            url: 'https://twistedfood.co.uk/shows/bake-in/pao-de-queijo-breakfast-waffles',
          },
          {
            url: 'https://twistedfood.co.uk/shows/shelf-life/menemen-fried-rice',
          },
          {
            url: 'https://twistedfood.co.uk/shows/shelf-life/road-trip-spaghetti-meatballs',
          },
        ],
      },
      showsSlider: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showURL',
              value: 'https://twistedfood.co.uk/shows/show-slug',
            },
          },
        ],
        data: [
          {
            showURL: 'https://twistedfood.co.uk/shows/hangin-with',
          },
          {
            showURL: 'https://twistedfood.co.uk/shows/bake-in',
          },
          {
            showURL: 'https://twistedfood.co.uk/shows/shelf-life',
          },
        ],
      },
    },
    enabled: true,
  },
  aboutUs: {
    sections: {
      backgroundImage: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'desktopImage',
                  },
                },
                {
                  type: 'file',
                  properties: {
                    name: 'mobileImage',
                  },
                },
              ],
            },
          },
        ],
        data: {
          desktopImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/ELgycj0wUpNEyOxO7zuzWsa8jTdjTmsgcuGYTJS5.webp',
          mobileImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/UOFDDL3FFZxP5iKPQV4lnnIZESZhINz9G06QZbf9.webp',
        },
      },
      text: {
        fields: [
          {
            type: 'text',
            properties: {
              value: 'Lets get this bread',
              name: 'paragraph',
            },
          },
        ],
        data: {
          paragraph:
            'Twisted exists to demonstrate that unserious food tastes seriously good.',
        },
      },
      button: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  properties: {
                    value: 'about us',
                    name: 'buttonText',
                  },
                  type: 'text',
                },
                {
                  type: 'text',
                  properties: {
                    value: '/about-us',
                    name: 'url',
                  },
                },
              ],
            },
          },
        ],
        data: {
          buttonText: 'about us',
          url: '/about-us',
        },
      },
    },
    enabled: true,
  },
};

export const cocktailconfig = {
  heroSection: {
    required: true,
    sections: {
      header: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
          {
            type: 'textarea',
            properties: {
              name: 'description',
            },
          },
        ],
        data: {
          title: 'Breakfast Recipes',
          description:
            "Breakfast is often left unfairly on the sidelines - a meal neglected and degraded by nasty grey cereal. We at Twisted have come to the rescue with some stunning ideas to help liven your breakfast recipes up a bit. Breakfast doesn't have to be complicated to be good - lots of our breakfast recipe ideas are easy, healthy and quick. That's not to say we haven't got a few decadent and delicious ones living for the weekend. We've got Turkish eggs, chorizo tacos and many, many more. Twisted - giving you probably the only convincing reason to get out of bed in the morning.",
        },
      },
    },
    enabled: true,
  },
  headerCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/breakfast/birds-eye-meat-free-sausage-breakfast-tortilla-wrap',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/breakfast/pao-de-queijo-breakfast-waffles',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/breakfast/cheesy-chorizo-breakfast-tacos',
          },
        ],
      },
    },
    enabled: true,
  },
  recipeFeeds: {
    required: true,
    canHaveMultiple: true,
    sections: {
      recipeCategories: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            url: 'https://twistedfood.co.uk/recipes/breakfast/breakfast-fried-rice-menemen-inspired-with-tom',
            featuredImage: '',
          },
        ],
      },
    },
    enabled: true,
  },
  bottomCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/news/police-weetabix-baked-beans-crime',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/news/heinz-launches-hash-browns-filled-with-baked-beans',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/anya-hindmarch-ice-cream-project-london-review',
          },
        ],
      },
    },
    enabled: true,
  },
};

export const recipeCategories = {
  Breakfast: 'breakfast',
  Sandwiches: 'sandwiches',
  Chicken: 'chicken',
  Vegetarian: 'vegetarian',
  TwistedGreen: 'twisted-green',
  Spicy: 'spicy',
  Pasta: 'pasta',
  Dessert: 'dessert',
  Cocktails: 'cocktails',
};

export const lifestyleCategories = {
  Trending: 'trending',
  Features: 'features',
  Opinion: 'opinion',
  Interview: 'interview',
  News: 'news',
};

export const showsCategories = {
  BakeIn: 'bake-in',
  ShelfLife: 'shelf-life',
  HanginWith: 'hangin-with',
  FoodPalace: 'food-palace',
};

export const homepageData = {
  heroCarousel: {
    required: true,
    sections: {
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'recipeUrl',
            },
          },
        ],
        data: [
          {
            recipeUrl: 'https://twistedfood.co.uk/recipes/chorizo-caesar-salad',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/salted-chocolate-babka-by-alex-szrok',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/fried-pork-chop-burrata-sandwich',
          },
        ],
      },
    },
    enabled: true,
  },
  latestRecipes: {
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'heading',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionText',
                    defaultValue: 'see all recipes',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionLink',
                    defaultValue: '/recipes',
                  },
                },
              ],
            },
          },
        ],
        data: {
          heading: 'Latest recipes',
          actionText: 'See all recipes',
          actionLink: '/recipes',
        },
      },
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'recipeUrl',
              value:
                'https://twistedfood.co.uk/recipes/twisted-green/vegan-miso-butter-crab-linguine',
            },
          },
        ],
        data: [
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/chilli-con-carne-meatball-sub',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/all-the-cheese-focaccia',
          },
          {
            recipeUrl: 'https://twistedfood.co.uk/recipes/fondant-potato-stars',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/stuffed-everything-bagel-flatbreads',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/whole-chicken-tikka-biryani',
          },
          {
            recipeUrl: 'https://twistedfood.co.uk/recipes/breakfast-carbonara',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/spicy-chorizo-carbonara',
          },
          {
            recipeUrl:
              'https://twistedfood.co.uk/recipes/twisted-green/puttanesca-pan-con-tomate',
          },
          {
            recipeUrl: '',
          },
        ],
      },
    },
    enabled: true,
  },
  featuredRecipes: {
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'heading',
              defaultValue: 'Featuring',
            },
          },
        ],
        data: {
          heading: 'Featuring',
        },
      },
      recipes: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'url',
            },
          },
        ],
        data: [
          {
            url: 'https://twistedfood.co.uk/recipes/steak-eggs-by-alex-szrok',
          },
          {
            url: 'https://twistedfood.co.uk/recipes/lamb-barley-by-alex-szrok',
          },
          {
            url: 'https://twistedfood.co.uk/recipes/chicken-and-tarragon-steamed-pudding-by-alex-szrok',
          },
          {
            url: 'https://twistedfood.co.uk/recipes/poor-knights-of-windsor-by-alex-szrok',
          },
        ],
      },
    },
    enabled: true,
  },
  lifestyle: {
    required: true,
    sections: {
      heading: {
        required: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'heading',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionText',
                    defaultValue: 'see all recipes',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'actionLink',
                    defaultValue: '/recipes',
                  },
                },
              ],
            },
          },
        ],
        data: {
          heading: 'Lifestyle',
          actionText: 'see all articles',
          actionLink: '/articles',
        },
      },
      articles: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'articleLink',
            },
          },
        ],
        data: [
          {
            articleLink:
              'https://twistedfood.co.uk/articles/features/glastonbury-festival-food-at-stalls-best',
          },
          {
            articleLink:
              'https://twistedfood.co.uk/articles/features/glastonbury-2022-drinking-game-alcohol-hunger-stage-festival',
          },
          {
            articleLink:
              'https://twistedfood.co.uk/articles/features/food-to-take-to-glastonbury-festival-pack-tent',
          },
        ],
      },
      features: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'featureArticleLink',
              defaultValue:
                'https://twistedfood.co.uk/articles/news/candy-funhouse-chief-officer-six-figures-apply',
            },
          },
        ],
        data: [
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/heinz-condiment-machine-sauce-dispenser-association-show',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/costa-popcorn-frappe-coffee-club-member-secret',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/lidl-insect-burgers-mealworm-larvae-ireland-buy',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/news/kitkat-churro-flavour-limited-edition-us-buy',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/costco-lemon-meringue-cheesecake-us-buy',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/ritz-crackers-scalloped-edges-shape-cut-cheese',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/bug-strawberries-salt-water-hack-can-you-eat-fruit',
          },
          {
            featureArticleLink:
              'https://twistedfood.co.uk/articles/trending/office-worker-padlock-milk-shared-fridge',
          },
        ],
      },
    },
    enabled: true,
  },
  motivation: {
    sections: {
      backgroundImage: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'desktopImage',
                  },
                },
                {
                  type: 'file',
                  properties: {
                    name: 'mobileImage',
                  },
                },
              ],
            },
          },
        ],
        data: {
          desktopImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/g1cxCtV3qgc5jmMvOCpdDJj719YdJi4FeA1DsMLY.webp',
          mobileImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/gEEpYZJJDY7OSF4DBFfIDDOXOrjqAAyJtRWmKHeR.png',
        },
      },
      quote: {
        required: true,
        enabled: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'textarea',
                  properties: {
                    name: 'paragraph',
                    value: 'keith floys',
                  },
                },
                {
                  type: 'text',
                  properties: {
                    name: 'author',
                  },
                },
              ],
            },
          },
        ],
        data: {
          paragraph:
            'To hell with fashion, trends and gastronomic one-upmanship. Cooking and eating should be fun.',
          author: 'Keith Floyd',
        },
      },
    },
    enabled: true,
  },
  shows: {
    required: true,
    sections: {
      heading: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
        ],
        data: {
          title: 'Watch our shows',
        },
      },
      featuredEpisodes: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'url',
            },
          },
        ],
        data: [
          {
            url: 'https://twistedfood.co.uk/shows/bake-in/pao-de-queijo-breakfast-waffles',
          },
          {
            url: 'https://twistedfood.co.uk/shows/shelf-life/menemen-fried-rice',
          },
          {
            url: 'https://twistedfood.co.uk/shows/shelf-life/road-trip-spaghetti-meatballs',
          },
        ],
      },
      showsSlider: {
        canHaveMultiple: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showURL',
              value: 'https://twistedfood.co.uk/shows/show-slug',
            },
          },
        ],
        data: [
          {
            showURL: 'https://twistedfood.co.uk/shows/hangin-with',
          },
          {
            showURL: 'https://twistedfood.co.uk/shows/bake-in',
          },
          {
            showURL: 'https://twistedfood.co.uk/shows/shelf-life',
          },
        ],
      },
    },
    enabled: true,
  },
  aboutUs: {
    sections: {
      backgroundImage: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'desktopImage',
                  },
                },
                {
                  type: 'file',
                  properties: {
                    name: 'mobileImage',
                  },
                },
              ],
            },
          },
        ],
        data: {
          desktopImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/ELgycj0wUpNEyOxO7zuzWsa8jTdjTmsgcuGYTJS5.webp',
          mobileImage:
            'https://portal-spa.s3.us-west-1.amazonaws.com/fournine/UOFDDL3FFZxP5iKPQV4lnnIZESZhINz9G06QZbf9.webp',
        },
      },
      text: {
        fields: [
          {
            type: 'text',
            properties: {
              value: 'Lets get this bread',
              name: 'paragraph',
            },
          },
        ],
        data: {
          paragraph:
            'Twisted exists to demonstrate that unserious food tastes seriously good.',
        },
      },
      button: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  properties: {
                    value: 'about us',
                    name: 'buttonText',
                  },
                  type: 'text',
                },
                {
                  type: 'text',
                  properties: {
                    value: '/about-us',
                    name: 'url',
                  },
                },
              ],
            },
          },
        ],
        data: {
          buttonText: 'about us',
          url: '/about-us',
        },
      },
    },
    enabled: true,
  },
  SEO: {
    sections: {
      pageTitle: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'pageTitle',
            },
          },
        ],
        data: {
          pageTitle: 'Home',
        },
      },
      metaTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {
          title: 'Twisted | Unserious Food Tastes Seriously Good',
          description:
            'Welcome to Twisted, the home of unserious food. Recipes, shows, interviews news and features. Twisted, unserious food tastes seriously good',
        },
      },
      FacebookTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      TwitterTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      Shows: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showsHeading',
            },
          },
        ],
        data: {
          showsHeading: 'Are you eating comfortably?',
        },
      },
    },
    enabled: true,
  },
};

export const recepiesData = {
  heroSection: {
    required: true,
    sections: {
      header: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
          {
            type: 'textarea',
            properties: {
              name: 'description',
            },
          },
        ],
        data: {
          title: 'Twisted Recipes',
          description:
            'Need some recipe inspiration? Browse our complete list of Twisted recipes, delicious meals and dinner ideas from our kitchen to yours, to fit every palate, dietary requirement and taste.',
        },
      },
    },
    enabled: true,
  },
  headerCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/twisted-green/moroccan-style-spaghetti-and-aubergine-meatballs-twisted-green',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/green-pici-with-spicy-nuts',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/caramelised-white-chocolate-brownies',
          },
        ],
      },
    },
    enabled: true,
  },
  categories: {
    required: true,
    canHaveMultiple: true,
    sections: {
      recipeCategories: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              type: 'horizontal',
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'categoryUrl',
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            categoryUrl: '/recipes/dessert',
          },
          {
            categoryUrl: '/recipes/vegetarian',
          },
          {
            categoryUrl: '/recipes/pasta',
          },
          {
            categoryUrl: '/recipes/chicken',
          },
          {
            categoryUrl: '/recipes/spicy',
          },
        ],
      },
    },
    enabled: true,
  },
  featuredCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: ' https://twistedfood.co.uk/articles/trending/m-s-magic-coffee-ristretto-australia-marks-spencer',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/interview/what-is-vegan-meat-made-of-cheese-how-to',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/interview/tiktok-rosie-grant-grave-stone-recipes-ghostly-archives',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/guinness-good-health-benefits-drink',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/what-mcdonalds-characters-names-mascots',
          },
        ],
      },
    },
    enabled: true,
  },
  episodeCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/pina-colada-doughnuts',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/chicken-curry-cheesy-chips',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/sichuan-spiced-hot-chicken-sandwich',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/steak-frites-sandwich',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/cheats-smoked-chipotle-wings-avocado-crema',
          },
        ],
      },
    },
    enabled: true,
  },
  SEO: {
    sections: {
      pageTitle: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'pageTitle',
            },
          },
        ],
      },
      metaTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {
          title: 'Twisted Recipes, Delicious Meals & Recipe Ideas | Twisted ',
          description:
            'Feeling hungry, looking for dinner ideas? Check out all the recipes from Twisted, our expert chefs provide unique & inspiring recipes from our kitchen for yours',
        },
      },
      FacebookTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      TwitterTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      Shows: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showsHeading',
            },
          },
        ],
        data: {},
      },
    },
    enabled: true,
  },
};

export const lifestylePage = {
  heroSection: {
    required: true,
    sections: {
      header: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
          {
            type: 'textarea',
            properties: {
              name: 'description',
            },
          },
        ],
        data: {
          title: 'All Articles',
          description: '',
        },
      },
    },
    enabled: true,
  },
  headerCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-sunday-roast-london-cheap-vegetarian-pub',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/gymbox-ring-of-fire-chilli-spicy-workout-london-review',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/who-invented-fish-chips-traditional-where-originate-how-to-make',
          },
        ],
      },
    },
    enabled: true,
  },
  categories: {
    required: true,
    canHaveMultiple: true,
    sections: {
      recipeCategories: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              type: 'horizontal',
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'categoryUrl',
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            categoryUrl: '/lifestyle/trending',
          },
          {
            categoryUrl: '/lifestyle/interview',
          },
          {
            categoryUrl: '/lifestyle/features',
          },
          {
            categoryUrl: '/lifestyle/news',
          },
        ],
      },
    },
    enabled: true,
  },
  featuredCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-sunday-roast-london-cheap-vegetarian-pub',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-brunch-london-places-spots-bottomless-guide',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-burgers-in-london-places-burger-chicken-vegan',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-sandwiches-london-near-me-uk-shop',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/features/best-pizza-in-london-delivery-places-restaurants',
          },
        ],
      },
    },
    enabled: true,
  },
  bottomSlider: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/chicken-curry-cheesy-chips',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/pina-colada-doughnuts',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/sichuan-spiced-hot-chicken-sandwich',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/duck-confit-lasagne',
          },
        ],
      },
    },
    enabled: true,
  },
  SEO: {
    sections: {
      pageTitle: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'pageTitle',
            },
          },
        ],
      },
      metaTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {
          title: 'Twisted News | Food News, Reviews, Interviews & Opinion',
          description:
            'Get the latest in food news, reviews and opinion from our expert food writers. Viral food news from across the web alongside interviews, reviews and opinion. ',
        },
      },
      FacebookTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      TwitterTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      Shows: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showsHeading',
            },
          },
        ],
        data: {},
      },
    },
    enabled: true,
  },
};

export const guidesHomepage = {
  heroSection: {
    required: true,
    sections: {
      header: {
        required: true,
        fields: [
          {
            type: 'text',
            properties: {
              name: 'title',
            },
          },
          {
            type: 'textarea',
            properties: {
              name: 'description',
            },
          },
        ],
        data: {
          title: 'Twisted Recipes',
          description:
            'Need some recipe inspiration? Browse our complete list of Twisted recipes, delicious meals and dinner ideas from our kitchen to yours, to fit every palate, dietary requirement and taste.',
        },
      },
    },
    enabled: true,
  },
  headerCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/twisted-green/moroccan-style-spaghetti-and-aubergine-meatballs-twisted-green',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/green-pici-with-spicy-nuts',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/recipes/caramelised-white-chocolate-brownies',
          },
        ],
      },
    },
    enabled: true,
  },
  categories: {
    required: true,
    canHaveMultiple: true,
    sections: {
      recipeCategories: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              type: 'horizontal',
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'categoryUrl',
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            categoryUrl: '/recipes/dessert',
          },
          {
            categoryUrl: '/recipes/vegetarian',
          },
          {
            categoryUrl: '/recipes/pasta',
          },
          {
            categoryUrl: '/recipes/chicken',
          },
          {
            categoryUrl: '/recipes/spicy',
          },
        ],
      },
    },
    enabled: true,
  },
  featuredCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: ' https://twistedfood.co.uk/articles/trending/m-s-magic-coffee-ristretto-australia-marks-spencer',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/interview/what-is-vegan-meat-made-of-cheese-how-to',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/interview/tiktok-rosie-grant-grave-stone-recipes-ghostly-archives',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/guinness-good-health-benefits-drink',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/articles/what-mcdonalds-characters-names-mascots',
          },
        ],
      },
    },
    enabled: true,
  },
  episodeCarousel: {
    required: true,
    sections: {
      carousel: {
        required: true,
        canHaveMultiple: true,
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'file',
                  properties: {
                    name: 'featuredImage',
                  },
                },
                {
                  type: 'layout',
                  properties: {
                    type: 'vertical',
                    fields: [
                      {
                        type: 'text',
                        properties: {
                          name: 'url',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
        data: [
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/pina-colada-doughnuts',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/chicken-curry-cheesy-chips',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/shelf-life/sichuan-spiced-hot-chicken-sandwich',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/steak-frites-sandwich',
          },
          {
            featuredImage: '',
            url: 'https://twistedfood.co.uk/shows/food-palace/cheats-smoked-chipotle-wings-avocado-crema',
          },
        ],
      },
    },
    enabled: true,
  },
  SEO: {
    sections: {
      pageTitle: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'pageTitle',
            },
          },
        ],
        data: {
          pageTitle: 'Recepies',
        },
      },
      metaTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {
          title: 'Twisted Recipes, Delicious Meals & Recipe Ideas | Twisted ',
          description:
            'Feeling hungry, looking for dinner ideas? Check out all the recipes from Twisted, our expert chefs provide unique & inspiring recipes from our kitchen for yours',
        },
      },
      FacebookTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      TwitterTags: {
        fields: [
          {
            type: 'layout',
            properties: {
              fields: [
                {
                  type: 'text',
                  properties: {
                    name: 'title',
                  },
                },
                {
                  type: 'textarea',
                  properties: {
                    name: 'description',
                  },
                },
              ],
            },
          },
          {
            type: 'file',
            properties: {
              name: 'image',
            },
          },
        ],
        data: {},
      },
      Shows: {
        fields: [
          {
            type: 'text',
            properties: {
              name: 'showsHeading',
            },
          },
        ],
        data: {},
      },
    },
    enabled: true,
  },
};
