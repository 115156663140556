import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import moment from 'moment';
import { useNavigate } from 'react-router';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            setRefreshToken(false);
            navigate(`/admin/pages/edit/${row?.slug}`, { state: { row } });
          },
        },
        {
          name: 'Delete',
          func: (row: { id: string }) => {
            setPostId(row.id);
            setRefreshToken(false);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Slug', key: 'slug', type: 'string' },
    { name: 'Date', key: 'updated_at', type: 'date' },
  ];
};

export const useGetTableRows = () => {
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];
    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = (
        <p style={{ width: '400px' }}>{decodeHtml(row['title'])} </p>
      );
      parsedRow['slug'] = <p>{decodeHtml(row['slug'])} </p>;
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] = (
        <p style={{ width: '100px' }}>
          {updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase()}
        </p>
      );
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Pages', condition: (posts: any) => posts },
];
