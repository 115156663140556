import { ApolloError, useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../utilities/notify';
import { plugins, toolbar, uploadImage } from '../../utilities/tiny';
import { isRequired, ValidationProps } from '../../utilities/validation';
import { Button } from '../common/Button/Button';
import TextField from '../common/TextField/TextField';
import useStyles from '../Blog/components/CreateContent/styles';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import { CREATEPAGE } from './graphQL';
import { Editor } from '@tinymce/tinymce-react';
import clx from 'classnames';
import { WysiwygInput } from '@wazobia-tech/wazcom/dist/io';

const CreatePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [createPageMutation, { loading }] = useMutation(CREATEPAGE);
  const [fullLoading, setFullLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const [embed, setEmbed] = useState<{ type: string; value: any }>();
  const [embedAction, setEmbedAction] = useState<string>('');

  const handleFieldBlur = useCallback(
    ({ target: { name, value } }: React.FocusEvent<HTMLInputElement>) => {
      const validation: ValidationProps =
        name === 'tags'
          ? {
              status: Boolean(formData.tags),
              errorText: 'the tags field is required',
            }
          : isRequired({ name, value });

      if (validation.status) {
        const newErrors = { ...formErrors };
        delete newErrors[name];
        setFormErrors(newErrors);
      } else {
        setFormErrors({
          ...formErrors,
          [name]: validation.errorText,
        });
      }
    },
    [formErrors, formData]
  );

  const handleText = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      let formattedValue = value;
      if (name === 'url') {
        formattedValue = value.toLowerCase().replace(/ /g, '-');
      }
      setFormData({ ...formData, [name]: formattedValue });
    },
    [formData]
  );

  const handleEditorChange = useCallback(
    (content) => {
      setFormData({ ...formData, content });
    },
    [formData]
  );

  const tinyImageCallback = useCallback(
    (callback) => {
      uploadImage(callback, setFullLoading, 'pages');
    },
    [setFullLoading]
  );

  const handleChange = (data: { name: string; value: string }) => {
    setFormData({
      ...formData,
      [data?.name]: data?.value,
    });
  };

  const createPage = async () => {
    if (Object.keys(formData).length < 3) {
      notifyError('All fields are required');
      return;
    }
    const variables: { [key: string]: string } = {
      ...formData,
      slug: formData.url,
    };
    delete variables.url;
    await createPageMutation({ variables })
      .then(() => {
        notifySuccess('page created successfully');
        navigate(-1);
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.map(({ extensions, message }) => {
          if ('title' in extensions?.validation) {
            return notifyError(extensions?.validation.title[0]);
          } else {
            return notifyError(message);
          }
        });
      });
  };

  return (
    <>
      <div className={classes.content}>
        <FullScreenLoader display={fullLoading} />
        <p className={classes.content__title}>Create Page</p>
        <form className={classes.content__form}>
          <p className={classes.formDescription}>
            Enter the page Title and Url
          </p>
          <div className={classes.formInput}>
            <div className={classes.form_top_content}>
              <label className={classes.content__form_label}>TITLE</label>
              <TextField
                onChange={handleText}
                onBlur={handleFieldBlur}
                type="text"
                value={formData?.title ?? ''}
                name="title"
                className={classes.textField}
              />
              {formErrors?.title && (
                <p className={classes.content__form_error}>
                  {formErrors?.title}
                </p>
              )}
            </div>
            <div className={classes.form_top_content}>
              <label className={classes.content__form_label}>URL</label>
              <TextField
                onChange={handleText}
                onBlur={handleFieldBlur}
                type="text"
                value={formData?.url ?? ''}
                name="url"
                className={classes.textField}
              />
              {formErrors?.url && (
                <p className={classes.content__form_error}>{formErrors?.url}</p>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className={clx(classes.content__form, classes.topSpace)}>
        <p className={classes.bottomContentDesc}>Page Content</p>
        <div className={clx(classes.content__form_section, classes.fullWidth)}>
          {/* @ts-ignore*/}
          <WysiwygInput
            value={{ title: formData?.title, content: formData?.content }}
            onChange={handleChange}
            embed={embed}
            initiateEmbed={(embedAction: string) => {
              setEmbedAction(embedAction);
            }}
            hasTitle={false}
          />
          {formErrors?.content && (
            <p className={classes.content__form_error}>{formErrors?.content}</p>
          )}
        </div>
        <div className={clx(classes.content__form_actions, classes.noSpace)}>
          <Button
            disabled={loading}
            onClick={() => {
              createPage();
            }}
            title="Create"
          />
        </div>
      </div>
    </>
  );
};

export default CreatePage;
