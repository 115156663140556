import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useCustomPlayerStyles = makeStyles((theme: BaseTheme) => ({
  snapchat: {
    width: '100%',
    marginTop: theme.spacing(1.5),
    height: '100%',
    borderRadius: '8px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.design.colors.black,
    overflow: 'hidden',

    '& video': {
      // width: '95%',
      height: '100%',
    },

    '& .play-button': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.design.colors.dimGrey,
      borderRadius: ' 50%',
      zIndex: 9999,
      cursor: 'pointer',
      transition: ' opacity 0.3s',
      display: 'flex',
      justifyContent: 'center',
    },

    '& .play-button img': {
      width: '40px',
      height: '40px',
    },

    '& .play-button.hidden': {
      opacity: 0,
    },
  },
  snapInfo: {
    position: 'absolute',
    top: '5%',
  },
  snapDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 1.5),
  },
  snapTitle: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.white,
    fontWeight: theme.typography.fontWeightBold,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  snapIcon: {
    width: 48,
    height: 48,
    minWidth: 48,
    borderRadius: '50%',
    background: theme.design.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useCustomPlayerStyles;
