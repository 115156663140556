import { createContext, useState, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'universal-cookie';
import createApolloClient from '../utilities/apolloClient';
import UserType from '../types/UserType';
import { getActiveSiteOrDefault } from 'utilities/helpers';

const initial = {
  user: {
    id: 0,
    name: '',
    email: '',
    avatar: '',
    uuid: '',
    role: '',
    bio: '',
    social_handle: '',
    location: '',
    position: '',
    department: '',
  },
  changeUser: (user: UserType) => {
    initial.user = user;
  },
  brand: '',
  site: 'vt',
  channel: 'Family Matters',
  changeChannel: (channel: string, data: any) => {
    initial.channel = channel;
    data;
  },
  siteDetails: {
    id: '',
    uuid: '',
    blog_uuid: '',
    library_uuid: '',
    url: '',
  },
  channelDetails: {
    businessProfileId: '5c9c44eb-019a-4e85-b675-6a40ce4f94a9',
    _id: '',
  },
  all: false,
  changeSite: (site: string, data: Record<string, any>[]) => {
    initial.site = site;
    data;
  },
  form: {},
  updateForm: (form: { [key: string]: unknown }) => {
    initial.form = form;
  },
};

export const AppStateContext = createContext(initial);

function AppStateProvider({ children }: { children: ReactNode }) {
  // app state
  const activeSite = getActiveSiteOrDefault();
  const cookies = new Cookies();
  const userFromCookie = cookies.get('user') ?? {};
  const [user, setUser] = useState(userFromCookie);
  const [site, setSite] = useState<string>(activeSite.site);
  const [all, setAll] = useState(false);
  const [channel, setChannel] = useState<string>(activeSite.channel);
  const [brand, setBrand] = useState('');
  const [channelDetails, setChannelDetails] = useState(
    activeSite.channelDetails
  );
  const [siteDetails, setSiteDetails] = useState<{
    id: string;
    uuid: string;
    blog_uuid: string;
    library_uuid?: string;
    url: string;
  }>(activeSite.siteDetails);
  const [form, setForm] = useState<{ [key: string]: any }>({});
  const client = createApolloClient(
    import.meta.env.VITE_GRAPH_QL_URL as string
  );
  const theBrands = [
    'VT',
    'Lovimals',
    'Futur3ology',
    'Four Nine',
    'Level Fitness',
    'Craft Factory(Brand)',
    'Twisted(Brand)',
  ];
  if (!activeSite.status) {
    localStorage.setItem(
      'active_site',
      JSON.stringify({
        siteDetails,
        site,
      })
    );
  }

  return (
    <AppStateContext.Provider
      value={{
        user,
        changeUser: (user) => {
          setUser(user);
        },
        channel,
        all,
        brand,
        channelDetails,
        changeChannel: (channel: string, data: { [key: string]: any }[]) => {
          const channelInfo = data.find(
            (value) =>
              value.mutablePublisherName.toLowerCase() === channel.toLowerCase()
          );
          setChannel(channel);
          if (theBrands.some((item) => item === channel)) {
            setBrand(channel);
          } else {
            setBrand('');
          }
          if (channel === 'All Channels') {
            setAll(true);
            setChannelDetails({
              businessProfileId: '',
              _id: channelInfo?._id,
            });
          } else {
            setAll(false);
            setChannelDetails({
              businessProfileId: channelInfo?.businessProfileId,
              _id: channelInfo?._id,
            });
          }
        },
        site,
        siteDetails,
        changeSite: (site: string, data: { [key: string]: any }[]) => {
          const siteInfo = data?.find(
            (value) => value.site.name.toLowerCase() == site.toLowerCase()
          );

          setSite(site);
          setSiteDetails({
            id: siteInfo?.site.id,
            uuid: siteInfo?.site.uuid,
            blog_uuid: siteInfo?.blog_uuid,
            library_uuid: siteInfo?.library_uuid,
            url: siteInfo?.site.url,
          });
          localStorage.setItem(
            'active_site',
            JSON.stringify({
              siteDetails: {
                id: siteInfo?.site.id,
                uuid: siteInfo?.site.uuid,
                blog_uuid: siteInfo?.blog_uuid,
                library_uuid: siteInfo?.library_uuid,
              },
              site,
            })
          );
        },
        form,
        updateForm: (form: { [key: string]: any }) => {
          setForm(form);
        },
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AppStateContext.Provider>
  );
}

export default AppStateProvider;