// LineChart.tsx
import useChartConfig from './useChartConfig';
import React, { FC } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import useLineChartStyles from './styles';
import clx from 'classnames';

type LineChartProps = {
  className?: string;
  data: any[];
};

export const LineChart: FC<LineChartProps> = ({ className, data }) => {
  const classes = useLineChartStyles();

  if (!data || data.length === 0) {
    return (
      <div className={clx(classes.emptyState, className)}>
        <img
          src="https://sytbuildr.s3.eu-west-2.amazonaws.com/blog+illustration.svg"
          alt="Empty Table"
        />
        <p> No data available</p>
      </div>
    );
  }

  const { Options } = useChartConfig({
    series: 15,
    dataType: 'time',
    data,
  });

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary as unknown as Date,
      elementType: 'line',
    }),
    [data]
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: 'line',
      },
    ],
    [data]
  );

  return (
    <>
      <div className={clx(className)}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
          }}
          style={{ fontFamily: 'Montserrat, sans-serif' }}
        />
      </div>
    </>
  );
};
