import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import CreateAnnouncement from 'components/Announcement/CreateAnnouncement/CreateAnnouncement';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import useStyles from '../ViewRedirect/styles';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { ShowAnnouncementDialog } from './ViewAnnouncements';
import { useState } from 'react';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            setRefreshToken(false);
            navigate(`/admin/announcements/${row.id}/edit`, { state: { row } });
          },
        },
        {
          name: 'Delete',
          func: (row: { id: string }) => {
            setPostId(row.id);
            setRefreshToken(false);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Description', key: 'description', type: 'string' },
    { name: 'Author', key: 'author', type: 'string' },
    { name: 'Last modified', key: 'updated_at', type: 'date' },
  ];
};

export const useGetTableRows = () => {
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];
    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = (
        <p style={{ width: '400px' }}>{decodeHtml(row['title'])} </p>
      );
      parsedRow['description'] = <p>{decodeHtml(row['description'])} </p>;
      parsedRow['author'] = (
        <p style={{ width: '200px' }}>
          {decodeHtml(row['author']) ? decodeHtml(row['author']) : 'none'}{' '}
        </p>
      );
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] = (
        <p style={{ width: '200px' }}>
          {updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase()}
        </p>
      );
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Announcements', condition: (posts: any) => posts },
];
