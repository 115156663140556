import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { FiLayers } from 'react-icons/fi';
import { FETCHNEWSLETTERS } from './graphQL';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Typography, CircularProgress } from '@mui/material';
import WidgetNav from '../common/WidgetNav/Widget';

import NewsletterType from '../../types/NewsletterType';
import { Button } from 'components/common/Button/Button';
import { WidgetButton } from 'components/SurveyPolls/SurveyPolls';
import { AiFillFire } from 'react-icons/ai';
import { parseRecursiveHelper } from 'components/Blog/components/CreateContent/helpers';
import { isDateStringLessThanCurrent } from 'utilities/date';
import { PiFolderOpen } from 'react-icons/pi';

const NewsLetterWidget = () => {
  const { data, error, loading } = useQuery(FETCHNEWSLETTERS, {
    fetchPolicy: 'network-only',
  });
  const [newsletters, setNewsletters] = useState<NewsletterType[]>([]);
  const [scrollIndex, setScrollIndex] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      const newsletters = data.newsletters.data;
      setNewsletters(newsletters);
    }
  }, [data]);

  const displayNewsletters = () =>
    newsletters.map(({ image, url }, index) => (
      <div key={index} className={classes.container__newsletter}>
        <img
          src={parseRecursiveHelper(image)?.src ?? image}
          alt=""
          style={{ height: '165px' }}
        />

        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={classes.btncontainer}
        >
          <WidgetButton
            color={'#fff'}
            title={'Read Now'}
            icon={<AiFillFire color={'#FF656C'} size={20} />}
          />
        </a>
      </div>
    ));

  const scroll = (index: number) => {
    if (index < 0 || index === newsletters.length) {
      return;
    }

    const scrollParent = document.getElementById('newsletters');

    if (scrollParent) {
      scrollParent.scrollLeft = index * scrollParent.clientWidth;
      setScrollIndex(index);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerHeading}>
        <img
          src="https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-22T155043/qlmgpEveQfArZ5Zrjy2LoLeTh6RALeTZ8A35PE7s.png"
          alt="newsletterIcon"
        />
        <div className={classes.newsletterheader}>
          <p className={classes.headerTitle}>Latest Newsletters</p>
          {newsletters.length > 0 &&
            !isDateStringLessThanCurrent(newsletters[0]?.date, true) && (
              <p className={classes.date}>
                {format(new Date(newsletters[0]?.date), 'do MMMM, yyyy')}
              </p>
            )}
        </div>
      </div>
      <div id="newsletters" className={classes.containerNewsletters}>
        {!isDateStringLessThanCurrent(newsletters[0]?.date, true) ? (
          displayNewsletters()
        ) : (
          <div className={classes.empty}>
            <PiFolderOpen size={25} />
            <span>No newsletters</span>
          </div>
        )}
      </div>
      {/* <div className={classes.container__nav}>
        <WidgetNav
          maxIndex={newsletters.length - 1}
          activeIndex={scrollIndex}
          onClickLeft={() => {
            scroll(scrollIndex - 1);
          }}
          onClickRight={() => {
            scroll(scrollIndex + 1);
          }}
        />
      </div> */}
      <div
        className={`loader ${
          loading || newsletters.length === 0 ? 'active' : null
        }`}
      >
        {!error && loading && <CircularProgress color="inherit" size="25px" />}
        {!error && !loading && newsletters.length === 0 && (
          <p>No newsletters to display</p>
        )}
      </div>
    </div>
  );
};

export default NewsLetterWidget;
