import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from '@apollo/client/utilities';
import Sidebar from '../../components/Sidebar/Sidebar';
import { AppStateContext } from '../../contexts/provider';
import { tabs as initialTabs, routes as initialRoutes } from './data';
import { getActiveSiteOrDefault, usePrevious } from 'utilities/helpers';
import { PiCakeLight } from 'react-icons/pi';
import { BiSolidMapPin } from 'react-icons/bi';

const Cms = () => {
  const { site } = useContext(AppStateContext);
  const navigate = useNavigate();

  const parseTabs = (tabs: { [key: string]: any }[]) => {
    const parsedTabs: { [key: string]: any }[] = [];
    const formattedTabs = cloneDeep(tabs);
    formattedTabs.forEach((tab) => {
      if (!tab.children) {
        if (!tab.site || tab.site === site) {
          parsedTabs.push(tab);
        }
        return;
      }
      tab.children = tab.children.filter(
        (tab: { [key: string]: string }) => !tab.site || tab.site === site
      );
      if (tab.children.length > 0) parsedTabs.push(tab);
    });
    return parsedTabs;
  };

  const parseRoutes = (
    routes: { route: string; component: JSX.Element; site?: string }[]
  ) => {
    const parsedRoutes: {
      route: string;
      component: JSX.Element;
      site?: string;
    }[] = [];
    routes.forEach((route) => {
      if (!route.site || route.site === site) parsedRoutes.push(route);
    });
    return parsedRoutes;
  };

  const prevSite = usePrevious(site) ?? getActiveSiteOrDefault().site;

  useEffect(() => {
    const tabs = parseTabs(initialTabs);
    const routes = parseRoutes(initialRoutes);
    setTabs(tabs);
    setRoutes(routes);
    if (routes.length > 0 && prevSite != site) navigate(routes[0].route);
  }, [site]);

  const [tabs, setTabs] = useState(parseTabs(initialTabs));
  const [, setRoutes] = useState(parseRoutes(initialRoutes));
  useEffect(() => {
    if (site === 'twistedfood') {
      setTabs((prevTabs) => {
        const newTabs = [...prevTabs];
        newTabs.splice(
          3,
          0,
          {
            name: 'Recipes',
            icon: <PiCakeLight size={20} />,
            key: 'recipe',
            children: [
              {
                name: 'All recipes ',
                route: '/cms/recipes',
                key: '/cms/recipes',
              },
              {
                name: 'Categories ',
                route: '/cms/recipes-category',
                key: '/cms/recipes-category',
              },
            ],
          },
          {
            name: 'Guides',
            icon: <BiSolidMapPin size={20} />,
            key: 'guides',
            children: [
              {
                name: 'All guides ',
                route: '/cms/guides',
                key: '/cms/guides',
              },
              {
                name: 'Categories ',
                route: '/cms/guides-category',
                key: '/cms/guides-category',
              },
            ],
          }
        );
        return newTabs;
      });
    }
  }, [site]);

  return <Sidebar tabs={tabs} />;
};

export default Cms;
