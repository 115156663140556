import { ReactNode, SetStateAction, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, ClassNameMap } from '@mui/material';
import { BiMailSend } from 'react-icons/bi';
import { GET_DIRECTORY } from './graphQL';
import useDirectoryStyles from './styles';
import WidgetNav from 'components/common/WidgetNav/WidgetNav';

export const formatDirectories = (
  data: { [key: string]: any },
  classes: ClassNameMap,
  type: string,
  dataLimit: number,
  currentPage: number,
  setCurrentPage: React.Dispatch<SetStateAction<number>>
): ReactNode => {
  const dataLength = data?.[type].length;
  const goToNextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const startIndex = currentPage * dataLimit - dataLimit;
  const endIndex = startIndex + dataLimit;

  const directoryParent = (
    <div className={classes.directory} key="directories-parent">
      {[...data?.[type]]
        .slice(startIndex, endIndex)
        .map((data: any, index: number) => (
          <div
            key={`${data.id}_${index}`}
            style={{ flexShrink: 0, width: '100%', paddingBottom: '8px' }}
          >
            <div className={classes.list_container}>
              <img
                className={classes.list_image}
                src={
                  data?.avatar?.image
                    ? `data:image/${data?.avatar?.type.toLowerCase()};base64, ${
                        data?.avatar?.image
                      }`
                    : 'https://jungle-portal.s4-us-west-1.amazonaws.com/Neutral2.png'
                }
                alt="Jungle Creations Logo"
                width="100%"
              />
              <div className={classes.list_details}>
                <div>
                  <p
                    style={{ fontSize: '12px', color: '#444444' }}
                    className={classes.name}
                  >
                    {data.name}
                  </p>
                  <p>
                    {data.role}
                    {data.role?.length > 28 ? (
                      <span className={'tooltiptext'}>{data.role}</span>
                    ) : null}
                  </p>
                </div>

                <div className={classes.list_icon}>
                  <a href={`mailto:${data.email}`}>
                    <BiMailSend
                      size="16px"
                      color="#444444"
                      style={{ marginRight: '10px' }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      {type === 'getBirthdays' && (
        <WidgetNav
          onClickLeft={goToPreviousPage}
          onClickRight={goToNextPage}
          startingPoint={startIndex !== 0 && dataLength > 0}
          endingPoint={endIndex < dataLength && dataLength > 0}
        />
      )}
    </div>
  );

  return directoryParent;
};

export default function DirectoryWidget() {
  const classes = useDirectoryStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(GET_DIRECTORY);

  return (
    <>
      {loading ? (
        <div className={classes.loader_container}>
          <CircularProgress className={classes.loader} size="24px" />
        </div>
      ) : (
        <div id="scroll-directories" className={classes.scroll}>
          {formatDirectories(
            data,
            classes,
            'getCezanneDirectory',
            5,
            currentPage,
            setCurrentPage
          )}
        </div>
      )}
    </>
  );
}
