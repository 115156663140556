import React, { useState } from 'react';
import { Select } from 'components/common/Select/Select';
import { DateRange } from 'react-date-range';
import clx from 'classnames';
import useCpasStyles from 'pages/Cpas/styles';
import { getOptions } from '../../../../pages/Cpas/data';
import { formatDate } from '../../helpers';

interface FilterSelectProps {
  value: string;
  showCustomRange: boolean;
  selectedStartDate: string;
  selectedEndDate: string;
  handleSelectChange: (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => void;
  setShowCustomRange: (value: boolean) => void;
  setSelectedStartDate: (value: string) => void;
  setSelectedEndDate: (value: string) => void;
  hasMarginBottom?: boolean;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  value,
  showCustomRange,
  handleSelectChange,
  setShowCustomRange,
  setSelectedStartDate,
  setSelectedEndDate,
  hasMarginBottom,
}) => {
  const classes = useCpasStyles();

  const handleClickAway = () => setShowCustomRange(false);
  const handleApplyClick = () => {
    setShowCustomRange(false);
  };

  const monthMapping = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const [dateState, setDateState] = useState([
    { startDate: new Date(), endDate: null, key: 'selection' },
  ]);

  const isCustomData = formatDate(dateState?.[0]?.endDate ?? '');

  const getCustomData = (isCustomData: string) => {
    if (!isCustomData) {
      return 'Custom';
    }

    if (monthMapping.includes(value.toLowerCase())) {
      return 'Custom';
    }

    return `(${formatDate(dateState?.[0]?.startDate ?? '')})-(${formatDate(
      dateState?.[0]?.endDate ?? ''
    )})`;
  };

  const handleSubmitDate = () => {
    setSelectedStartDate(formatDate(dateState?.[0]?.startDate ?? ''));
    setSelectedEndDate(formatDate(dateState?.[0]?.endDate ?? ''));
    setShowCustomRange(false);
  };

  const valueData =
    value === 'Custom' || /-/.test(value) ? getCustomData(isCustomData) : value;

  return (
    <div className={clx(classes.filterSelectRoot, hasMarginBottom && 'table')}>
      <div className={classes.selectField}>
        <p className={classes.filterText}>Filter By:</p>
        <Select
          options={getOptions(getCustomData(isCustomData), true)}
          onChange={handleSelectChange}
          value={valueData}
          className={clx(
            classes.select,
            /-/.test(value) || value === 'Custom' ? 'expand' : ''
          )}
        />
      </div>
      {showCustomRange && (
        <>
          <div className={clx(classes.filterContainer)}>
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => setDateState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateState}
            />
            <div className={classes.filterBox}>
              <button onClick={handleClickAway}>Cancel</button>
              <button onClick={handleSubmitDate} className="secondary">
                Apply
              </button>
            </div>
          </div>
          <div
            className={classes.overlay}
            onClick={() => setShowCustomRange(false)}
          />
        </>
      )}
    </div>
  );
};

export default FilterSelect;
