import { useCallback, useContext, useEffect, useState } from 'react';
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client';
import { Modal, Box } from '@mui/material';
import PollType from '../../types/PollType';
import { Button } from '../common/Button/Button';
import Poll from '../Poll/Poll';
import { DELETE_POLL, VIEWPOLLS } from './graphQL';
import useStyles from './styles';
import CreatePoll from '../CreatePoll/CreatePoll';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helper';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';
import { blogClient } from 'utilities/apolloClient';
import AuthContext from 'contexts/auth';
import { notifySuccess } from 'utilities/notify';

const ViewPolls = () => {
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [deletePoll] = useMutation(DELETE_POLL);
  const { toggleSpinner } = useContext(AuthContext);
  const [activePoll, setActivePoll] = useState<PollType | null>(null);
  const [getId, setGetId] = useState<undefined | string>('');
  const [polls, setPolls] = useState<PollType[]>([]);
  const [refreshToken, setRefreshToken] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState<{
    open: boolean;
    data: PollType | undefined;
  }>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const { data, error, loading, refetch } = useQuery(VIEWPOLLS, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const getTableRows = useGetTableRows();
  const tableColumns = useGetTableColumns(
    setGetId,
    setOpenDeleteModal,
    setRefreshToken,
    setOpenViewModal,
    setOpenCreateModal
  );

  useEffect(() => {
    if (data) {
      const polls = [...data.polls.data];
      setPolls(polls);
      const poll = [...polls][0];
      setActivePoll(poll);
    }
  }, [data, error, loading]);

  const updatePolls = useCallback(
    (id, poll) => {
      const index = Number(
        polls?.findIndex((poll) => poll.id.toString() === id.toString())
      );
      if (polls) {
        const newPolls = [...polls];
        newPolls[index] = poll;
        setPolls(newPolls);
        setActivePoll(poll);
      }
    },
    // eslint-disable-next-line
    [polls, activePoll]
  );

  const handleClose = () =>
    setOpenCreateModal({ open: false, data: undefined });

  const handleDeletePoll = async () => {
    if (!getId) return;

    const variables = { id: getId };
    toggleSpinner(true);
    try {
      setOpenDeleteModal(false);
      await deletePoll({ variables });
      setRefreshToken(true);
      notifySuccess('Poll Deleted Successfully');
    } finally {
      setRefreshToken(false);
      setOpenDeleteModal(false);
      toggleSpinner(false);
    }
  };

  const classes = useStyles();

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.polls}>
            <>
              <div className={classes.title_parent}>
                <p className={classes.polls__text}>Polls</p>
                <Button
                  title="Create Poll"
                  onClick={() => {
                    setOpenCreateModal({ open: true, data: undefined });
                  }}
                />
              </div>
              <div className={classes.polls__content}>
                <TableNew
                  columns={tableColumns}
                  getRows={getTableRows}
                  query={{
                    name: VIEWPOLLS,
                    client,
                    variables: {
                      refreshToken,
                    },
                    dataKey: 'polls',
                  }}
                  conditionFilters={tableConditionFilters}
                  showFilters={false}
                />
              </div>
            </>
          </div>
          <Modal
            open={openCreateModal?.open ?? false}
            onClose={handleClose}
            onBackdropClick={(e) => e.stopPropagation()}
          >
            <Box className={classes.box}>
              <CreatePoll
                handleClose={() =>
                  setOpenCreateModal({ open: false, data: undefined })
                }
                handleRefresh={() => setRefreshToken(true)}
                editData={openCreateModal?.data}
              />
            </Box>
          </Modal>
          <Modal
            open={openViewModal}
            onClose={() => setOpenViewModal(false)}
            onBackdropClick={(e) => e.stopPropagation()}
          >
            <Box className={classes.box}>
              {activePoll && (
                <div className={classes.poll}>
                  <Poll update={updatePolls} {...activePoll}></Poll>
                </div>
              )}
            </Box>
          </Modal>
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            deletePostCategory={handleDeletePoll}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default ViewPolls;
