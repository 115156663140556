import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { FiLink } from 'react-icons/fi';
import { useSlugControlStyles } from './styles';
import { removeSpecialCharacters } from './helpers';
import { SlugProps } from './types';
import clx from 'classnames';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { Tooltip } from '@wazobia-tech/wazcom/dist/core';
import { RECIPE_PAGE_TYPE } from 'layouts';

export const Slug: FC<SlugProps> = ({
  name = 'slug',
  label = 'PERMALINK',
  ignoreTitleChange = false,
  isDisabled,
  className,
  formData,
  setFormData,
  type,
}) => {
  const classes = useSlugControlStyles();
  const { uuid } = useParams();
  const getPreSlugURL = useGetPreSlugURL();
  const [copied, setCopied] = useState(false);

  const getCategorySlug = () => {
    if (!formData?.categories || formData?.categories?.length === 0) return '';

    const slug =
      formData?.categories[0]?.subCategories &&
      formData?.categories[0]?.subCategories?.length > 0
        ? formData?.categories[0]?.subCategories[0]?.slug
        : formData?.categories[0]?.slug;

    return slug?.startsWith('/') ? slug : '/' + slug;
  };

  useEffect(() => {
    if (formData.ignoreTitleChange) {
      const categorySlug = getCategorySlug();
      const currentUrlParts = formData[name]?.split('/');
      const currentTitlePart = currentUrlParts?.pop() ?? '';
      const url =
        type !== RECIPE_PAGE_TYPE
          ? `${categorySlug}/${currentTitlePart}`
          : `/${currentTitlePart} `;
      setFormData({ ...formData, [name]: url });
    } else {
      const categorySlug = getCategorySlug();
      const titlePart = removeSpecialCharacters(
        (formData?.title?.trim()?.replace(/ /g, '-') ?? '')?.toLowerCase()
      );
      const url =
        type !== RECIPE_PAGE_TYPE
          ? `${categorySlug}${titlePart?.trim() ?? ''}`
          : `${titlePart?.trim() ?? ''}`;
      setFormData({ ...formData, [name]: url });
    }
  }, [formData?.title, JSON.stringify(formData?.categories)]);

  const handleChange = ({ value }: { name: string; value: string }) => {
    setFormData({
      ...formData,
      [name]: value,
      permalink: name === 'slug' ? value?.trim() ?? 's' : formData.permalink,
      ignoreTitleChange: true,
    });
  };

  return (
    <TextInput
      name={name}
      label={label}
      leftIcon={
        <div className={classes.slugTextAndIcon}>
          <Tooltip
            title="copy"
            position="top"
            content={`${copied ? 'Copied' : 'Copy link'}`}
          >
            <FiLink
              width={16}
              className={classes.slugControlIcon}
              onClick={() => {
                navigator.clipboard
                  .writeText(getPreSlugURL() + formData[name])
                  .then(() => {
                    setCopied(true);
                  });
              }}
            />
          </Tooltip>
          <span>{getPreSlugURL()}</span>
        </div>
      }
      disabled={
        !formData.ignoreTitleChange || isDisabled !== undefined
          ? isDisabled
          : false
      }
      placeholder={'/'}
      value={formData[name]?.trim() ?? ''}
      className={classes.slugControl}
      classNames={{ input: clx(classes.slugControlTextInput, className) }}
      onChange={handleChange}
    />
  );
};
