import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { REQUESTFEATURE } from './graphQL';
import { useMutation } from '@apollo/client';
import useStyles from '../Blog/components/CreateContent/styles';
import TextField from '../common/TextField/TextField';
import { formHandleText, formHandleFieldBlur } from '../../utilities/form';
import { Button } from '../common/Button/Button';
import Loader from '../common/Loader/Loader';
import { notifyError, notifySuccess } from '../../utilities/notify';

const RequestFeature = () => {
  const classes = useStyles();
  const [requestFeatureMutation, { loading }] = useMutation(REQUESTFEATURE);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const navigate = useNavigate();

  const handleFieldBlur = useCallback(
    (event) => {
      formHandleFieldBlur(event, formData, formErrors, setFormErrors);
    },
    [formErrors, formData]
  );

  const requestFeature = async () => {
    if (Object.keys(formData).length < 3) {
      return notifyError('All Fields Are Required');
    }

    const variables = { ...formData };
    requestFeatureMutation({ variables })
      .then(() => {
        navigate('/admin/new-features');
        notifySuccess('Request Made Successfully');
      })
      .catch(() => {
        notifyError('An Error Occured');
      });
  };

  return (
    <div className={classes.content}>
      <p className={classes.content__title}>Request Feature</p>
      <form className={classes.content__form}>
        <div className={classes.content__form_section}>
          <label className={classes.content__form_label}>
            Summary of Issue
          </label>
          <TextField
            onChange={(e) => {
              formHandleText(e, setFormData, formData);
            }}
            onBlur={handleFieldBlur}
            type="text"
            value={formData?.name ?? ''}
            name="name"
          />
          {formErrors?.name && (
            <p className={classes.content__form_error}>{formErrors?.name}</p>
          )}
        </div>
        <div className={classes.content__form_section}>
          <label className={classes.content__form_label}>
            Describe the issue or request in more detail if possible. If the
            issue is a bug include steps to replicate the bug. If the issue is a
            task or story include what is required for you accept the new
            feature as completed
          </label>
          <TextField
            onChange={(e) => {
              formHandleText(e, setFormData, formData);
            }}
            multiline
            rows={7}
            onBlur={handleFieldBlur}
            type="text"
            value={formData?.description ?? ''}
            name="description"
          />
          {formErrors?.description && (
            <p className={classes.content__form_error}>
              {formErrors?.description}
            </p>
          )}
        </div>
        <div className={classes.content__form_section}></div>
        <div
          className={classes.content__form_actions}
          style={{ justifyContent: 'flex-end', width: '100%' }}
        >
          <div style={{ position: 'relative' }}>
            <Button onClick={requestFeature} title="Make Request" />
            <Loader display={loading} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default RequestFeature;
