const selectFilter = (
  data: { [key: string]: string | number }[],
  field: string,
  filterValue: string
) => {
  if (filterValue === 'all') {
    return data;
  }
  return data.filter((value) => value[field] === filterValue);
};

const searchFilter = (
  data: { [key: string]: string }[],
  field: string,
  searchValue: string
) => {
  return data.filter((value) =>
    value[field].toLowerCase().includes(searchValue)
  );
};

export { selectFilter, searchFilter };
