import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useModalStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    right: '50%',
    top: '50%',
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    zIndex: 61,
    transform: 'translateX(35%)',
    '&.TopRight': {
      right: '5%',
      left: 'unset',
      animation: '$slideRight 0.5s ease',
      justifyContent: 'flex-end',
    },
    '&.BottomRight': {
      right: '5%',
      left: 'unset',
      top: 'unset',
      bottom: '5%',
      animation: '$slideRight 0.5s ease',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    '&.BottomLeft': {
      right: 'unset',
      left: '5%',
      top: 'unset',
      bottom: '5%',
      animation: '$slideLeft 0.5s ease',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
  },
  modalContent: {
    borderRadius: '4px',
    boxShadow: `0px 12px 16px ${theme.design.colors.shadow200}`,
    width: 'fit-content',
    height: 'fit-content',
    zIndex: 61,
    [theme.breakpoints.down('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
  closeButton: (props) => ({
    border: 'none',
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    position: 'absolute',
    right: '-24px',
    top: '-16px',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      right: '-15px',
      top: '-13px',
      padding: theme.spacing(0.5, 0.7),
    },
  }),
  '@keyframes slideLeft': {
    from: {
      left: '-50%',
    },
    to: {
      left: '5%',
    },
  },
  '@keyframes slideRight': {
    from: {
      right: '-50%',
      overflow: 'hidden',
    },
    to: {
      right: '5%',
      overflow: 'hidden',
    },
  },
  modalOverlay: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: theme.design.colors.overlayBackground,
    zIndex: 60,
    overflow: 'hidden',
  },
}));
