import React from 'react';
import { ProgressBarProps } from './types';

const ProgressBar: React.FC<ProgressBarProps> = ({
  progressValue,
  highest,
  lowest,
}) => {
  return (
    <progress
      id="file"
      max="100"
      value={(progressValue / highest) * 100}
      style={{
        width: '100%',
        accentColor:
          progressValue > lowest && progressValue <= highest - 1
            ? 'green'
            : progressValue > highest - 1
            ? 'red'
            : '#fec228',
      }}
    />
  );
};

export default ProgressBar;
