import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { RightSideBarControls } from 'components/common/RightSideBar/types';
import { DocumentNode } from 'graphql';

export type CreateContentLayoutProps = {
  getContentConfig: {
    GET_CONTENT: DocumentNode;
    variables: { [key: string]: any };
    client: ApolloClient<NormalizedCacheObject>;
    dataKey: string[];
  };
  onGoBack: () => void;
  isEdit: Boolean;
  isPublished: Boolean;
  showRightSidebar: Boolean;
  updateStateData: (data: { [key: string]: any }) => void;
  type: PageType;
  sidebarControls: RightSideBarControls;
  saving?: boolean;
};

export type PageType = 'recipes' | 'guides' | 'posts';

export const RECIPE_PAGE_TYPE = 'recipes';

export const GUIDE_PAGE_TYPE = 'guides';

export const POST_PAGE_TYPE = 'posts';
