import { useState } from 'react';
import { useAdminDashboardStyles } from './styles';
import WelcomeWidget from '../../WelcomeWidget/WelcomeWidget';
import DirectoryWidget from '../../DirectoryWidget/Old_DirectoryWidget';
import Calendar from '../../common/Calendar/Calendar';
import { FaUserGroup } from 'react-icons/fa6';
import { AnnouncementWidget } from 'components/Announcement/AnnouncementWidget';
import { EventWidget } from 'components/Events/EventsWidget';
import { WidgetWrapper } from './WidgetWrapper';
import { LatestArticlesWidget } from 'components/LatestArticles/LatestArticlesWidget';
import AdminBirthdayWidget from 'components/BirthdayWidget/AdminBirthdayWidget';
import AdminEmployee from 'components/EmployeeWidget/AdminEmployee';

export const AdminDashboard = () => {
  const classes = useAdminDashboardStyles();
  const [date] = useState<Date>(new Date());

  const widgetData = [
    {
      title: 'Welcome',
      subtitle: (
        <p className={classes.day}>
          <em>Today</em>
        </p>
      ),
      children: <WelcomeWidget />,
    },
    {
      title: 'Announcements',
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-volume-low.png_HcvcUA'
          }
        />
      ),
      children: <AnnouncementWidget />,
    },
    {
      title: 'Events',
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-note.png_G5QSzO'
          }
        />
      ),
      children: <EventWidget />,
    },
    {
      title: 'Top Articles',
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-task-square.png_CCkwsJ'
          }
        />
      ),
      children: <LatestArticlesWidget />,
    },
    {
      title: "Company's Directory",
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-clipboard-tick.png_IzU6pO'
          }
        />
      ),
      children: <DirectoryWidget />,
    },
    {
      title: 'New Staffs',
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-profile-2user.png_AYRxcB'
          }
        />
      ),
      children: <AdminEmployee />,
    },
    {
      title: 'Birthday',
      subtitle: (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-music.png_iQTTU5'
          }
        />
      ),
      children: <AdminBirthdayWidget />,
    },
  ];

  return (
    <>
      <div className={classes.home}>
        <div className={classes.left_section}>
          <div className={classes.topSection}>
            {widgetData.slice(0, 4).map((data, index) => (
              <WidgetWrapper
                key={index}
                title={data.title}
                subtitle={data.subtitle}
              >
                {data.children}
              </WidgetWrapper>
            ))}
          </div>
          <div className={classes.topSection}>
            {widgetData.slice(4, 7).map((data, index) => (
              <WidgetWrapper
                key={index}
                title={data.title}
                subtitle={data.subtitle}
              >
                {data.children}
              </WidgetWrapper>
            ))}
            <div className={classes.right_section}>
              <Calendar date={date} className={classes.calendar} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
