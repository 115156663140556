import React, { FC } from 'react';
import usePreviewStyles from './styles';
import { PreviewProps } from './types';

const Preview: FC<PreviewProps> = ({ title, link, description, label }) => {
  const classes = usePreviewStyles();
  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <div className={classes.container}>
        <p className={classes.title}>{title}</p>
        {/* <p className={classes.link}>{link}</p> */}
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

export default Preview;
