import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { FETCHFEATURES, UPDATEFEATURE } from './graphQL';
import { useQuery, useMutation } from '@apollo/client';
import useStyles from '../ViewRedirect/styles';
import { Select } from '../common/Select/Select';
import { Button } from '../common/Button/Button';
import { CircularProgress } from '@mui/material';
import TextField from '../common/TextField/TextField';
import { FeatureType } from '../../types/FeatureType';
import Table from '../common/Table/Table';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../utilities/notify';

const Features = () => {
  const [paginator, setPaginator] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const { data, error, loading } = useQuery(FETCHFEATURES, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const { toggleSpinner } = useContext(AuthContext);
  const [updateFeatureMutation] = useMutation(UPDATEFEATURE);
  const [features, setFeatures] = useState<FeatureType[]>([]);
  const [displayedFeatures, setDisplayedFeatures] = useState<FeatureType[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      let features: FeatureType[] = data.features.data;
      features = features.map(
        ({ id, name, reason, user, description, status }) => {
          return { id, name, reason, description, user, status };
        }
      );
      setFeatures(features);
      setDisplayedFeatures(features);
      const paginatorInfo = data?.features?.paginatorInfo;
      setPaginator(paginatorInfo);
    }

    if (error) {
      notifyError('An Error Occured');
    }
  }, [data, error]);

  const actionsHandler = (
    event: React.ChangeEvent<{ name?: string; value: string }>,
    id: number
  ) => {
    const value = event.target.value === 'Approve' ? 1 : 2;
    updateFeature(id, value);
  };

  const updateFeature = useCallback(
    (id: number, status: number) => {
      const variables = { id, status };
      toggleSpinner(true);
      updateFeatureMutation({ variables })
        .then(() => {
          notifySuccess('Request Updated Successfully');
          const newFeatures = [...features];
          const newDisplayedFeatures = [...displayedFeatures];
          let idx = features.findIndex(
            (feature) => feature.id.toString() === id.toString()
          );
          newFeatures[idx].status = status;
          setFeatures(newFeatures);
          idx = displayedFeatures.findIndex(
            (feature) => feature.id.toString() === id.toString()
          );
          newDisplayedFeatures[idx].status = status;
          setDisplayedFeatures(newDisplayedFeatures);
        })
        .catch(() => {
          notifyError('An Error Occured');
        })
        .finally(() => {
          toggleSpinner(false);
        });
      // eslint-disable-next-line
    },
    [features, displayedFeatures]
  );

  const searchFeatures = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      setSearchText(event.target.value);
      const filteredFeatures = features.filter((feature) =>
        feature.name.toLowerCase().includes(event.target.value)
      );
      setDisplayedFeatures(filteredFeatures);
    },
    // eslint-disable-next-line
    [displayedFeatures, features]
  );

  const schema: SchemaProps[] = [
    {
      label: 'Feature',
      func: ({ item }) => get(item, 'name'),
    },
    {
      label: 'User',
      func: ({ item }) => get(item, 'user'),
    },
    {
      label: 'Reason',
      func: ({ item }) => get(item, 'reason'),
    },
    {
      label: 'Description',
      func: ({ item }) => get(item, 'description'),
    },
    {
      label: 'Status',
      func: ({ item }) => {
        if (item.status === 0) {
          return 'Pending';
        }

        if (item.status === 1) {
          return 'Approved';
        }

        if (item.status === 2) {
          return 'Declined';
        }
      },
    },
    {
      label: 'Actions',
      func: ({ item }) => {
        let options = ['Actions', 'Approve', 'Decline'];
        if (item.status === 1) {
          options = ['Actions', 'Decline'];
        }

        if (item.status === 2) {
          options = ['Actions', 'Approve'];
        }
        return (
          <div style={{ width: '110px' }}>
            <Select
              value="Actions"
              options={options}
              onChange={(e) => {
                actionsHandler(e, get(item, 'id'));
              }}
              noStyle={true}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Feature Requests</p>
        <div className={classes.searchBox}>
          <div className={classes.search}>
            <TextField
              name="redirect"
              type="text"
              value={searchText}
              onChange={searchFeatures}
              placeholder="Search Requests"
            />
            <img
              src="https://jungle-static.s3-us-west-1.amazonaws.com/jungleportal/search_icon.svg"
              className={classes.searchIcon}
              alt="search icon"
            />
          </div>
          <Button
            title="Make Request"
            onClick={() => {
              navigate('/admin/features/request');
            }}
          />
        </div>
      </div>

      <div className={classes.main}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <>
            <Table
              schema={schema}
              collection={displayedFeatures}
              pagination={true}
              paginatorInfo={paginator}
              rowsPerPage={rows}
              changePage={(e) => setPage(e + 1)}
              changeRow={(e) => setRows(e + 1)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Features;
