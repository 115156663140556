import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useSidebarStyles = makeStyles((theme: BaseTheme) => ({
  drawer: {
    width: 240,
    height: '100vh',
    flexShrink: 0,
    position: 'fixed',
    zIndex: 60,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
    transition: 'width ease-in 0.2s',

    '&::-webkit-scrollbar': {
      height: '0px',
    },
  },
  drawer_hidden: {
    width: '50px !important',
  },
  gorillaIcon: {
    margin: theme.spacing(3, 0, 4, 0),
    width: '60%',
    cursor: 'pointer',
  },
  drawerPaper: {
    '&.MuiDrawer-paper': {
      position: 'relative',
      borderRight: 'transparent',
      background: theme.design.colors.black,
      overflow: 'hidden',
      fontFamily: theme.design.font.universal,
      width: '240px',
      alignItems: 'center',
    },
  },
  icon: {
    transition: 'margin ease-in 0.2s',
    '& .chevron': {
      color: theme.design.colors.black,
    },
    width: 'fit-content',
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
    bottom: 0,
    // transform: "translateX(50%)",
    background: '#fff',
    padding: theme.spacing(0.5, 0.5, 0.1, 0.5),
    borderRadius: '50%',
  },
  icon_closed: {
    margin: 0,
  },
  tab: {
    width: '100%',
    display: 'flex',
    fontFamily: theme.design.font.universal,
    alignItems: 'center',
    padding: theme.spacing(1.5, 0, 1.5, 2.5),
    color: 'rgba(221, 221, 221, 1)',
    transition: 'all ease-in 0.2s',
    cursor: 'pointer',
    boxSizing: 'border-box',
    gap: '12px',

    '&:hover': {
      '& .text': {
        color: theme.design.colors.white,
      },
      '& .icon': {
        color: theme.design.colors.white,
      },
    },

    '& .icon': {
      marginRight: theme.spacing(1.5),
      flexShrink: 0,
      color: 'rgba(221, 221, 221, 1)',
    },

    '& .text': {
      fontSize: 12,
      flexShrink: 0,
      color: 'rgba(221, 221, 221, 1)',
      fontFamily: theme.design.font.universal,
    },
  },
  tabParent: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    marginBottom: 0,
  },
  tab_active: {
    background: theme.design.colors.darkCharcoal,
    borderTopLeftRadius: '25px',
    borderBottomLeftRadius: '25px',
    color: theme.design.colors.white,
    '& .text': {
      color: theme.design.colors.white,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .icon': {
      color: theme.design.colors.white,
    },
  },
  cell: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0, 1.5, 2.5),
    marginBottom: theme.spacing(1),
    color: theme.design.colors.white,
    transition: 'all ease-in 0.2s',
    cursor: 'pointer',
    boxSizing: 'border-box',

    '& .icon': {
      marginRight: theme.spacing(1.5),
      flexShrink: 0,
    },
  },
  text: {
    '&.MuiTypography-root': {
      fontSize: theme.typography.fontSize,
      flexShrink: 0,
      color: 'rgba(221, 221, 221, 1)',
      '&:hover': {
        color: theme.design.colors.white,
      },
    },
  },
  text_active: {
    '&.MuiTypography-root': {
      color: theme.design.colors.white,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabCollapse: {
    width: '100%',
    marginLeft: theme.spacing(3),
    background: '#0d0d0d',
  },
  content: {
    paddingTop: theme.spacing(11),
    position: 'relative',
    left: 270,
    width: 'calc(100% - 300px)',
    height: '100%',
    transition: 'all ease-in 0.2s',
  },
  fullView: {
    paddingTop: 70,
    width: 'calc(100% - 280px)',
  },
  content_extended: {
    left: 80,
    width: 'calc(100% - 130px)',
  },
  resizeIcon: {
    filter: 'brightness(0) invert(1)',
    width: 26,
    height: 26,
  },
}));
