import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    background: '#F4EFED 0% 0% no-repeat padding-box',
    minHeight: 'calc(100vh - 70px)',
    paddingBottom: theme.spacing(5),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    font: 'normal normal bold 48px/72px universal',
    letterSpacing: 0,
    color: '#000000',
    opacity: 1,
    marginTop: theme.spacing(2.5),
    '& img': {
      padding: theme.spacing(0, 2),
    },
  },
  content: {
    marginTop: theme.spacing(5),
  },
  leaderContainer: {
    width: '90vw',
    margin: '0 auto',

    '& > div > div': {
      width: '16vw',
    },
  },
}));

export default useStyles;
