import { Icon } from '@iconify/react';

interface Props {
  // postChannel: 'instagram' | 'facebook' | 'linkedin';
  postChannel: 'instagram' | 'facebook' ;
}

export default function Channel({ postChannel }: Props) {
  const icon = {
    facebook: 'logos:facebook',
    // linkedin: 'skill-icons:linkedin',
    instagram: 'skill-icons:instagram',
  }[postChannel];

  const socialChannel = {
    instagram: 'Instagram',
    facebook: 'Facebook',
    // linkedin: 'Linkedin',
  }[postChannel];

  return (
    <div
      style={{ border: '2px solid #E5E7EC' }}
      className="rounded-full px-3 py-2 w-fit"
    >
      <div className="flex space-x-2 items-center justify-center">
        <div>
          <Icon width={14} icon={icon} color="#6D6D6D" />
        </div>

        <div>
          <p className="text-[#6D6D6D] text-xs">{socialChannel}</p>
        </div>
      </div>
    </div>
  );
}
