import { gql } from '@apollo/client';

export const CREATENEWSLETTER = gql`
  mutation createNewsletter(
    $title: String!
    $url: String!
    $image: JSON!
    $date: String!
  ) {
    createNewsletter(title: $title, url: $url, image: $image, date: $date) {
      message
      errorId
    }
  }
`;

export const UPDATENEWSLETTER = gql`
  mutation updateNewsletter(
    $title: String!
    $url: String!
    $image: JSON!
    $date: String!
    $id: ID!
  ) {
    updateNewsletter(
      title: $title
      url: $url
      image: $image
      date: $date
      id: $id
    ) {
      message
      errorId
    }
  }
`;
