import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  buttonPlay: {
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '22px 22px 22px 28px',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative', // Add this line to handle the absolute positioning of ::before

    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
      padding: '16px 16px 16px 22px',
    },

    '&::before': {
      //   ...theme.transitions.create('background', {
      //     easing: theme.transitions.easing.easeFast,
      //   }),
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      // background: theme.palette.tertiary.main,
      borderRadius: '50%',
      zIndex: '1',
    },

    '&:hover': {
      '&::before': {
        // background: theme.palette.info.main,
      },

      '.button-play__icon': {
        '&::before, &::after': {
          background: theme.palette.common.white,
        },

        '.icon__element': {
          '&[stroke]': {
            stroke: theme.palette.common.white,
          },
        },
      },
    },
  },
  buttonPlayChange: {
    '.button-play__icon': {
      '&::before, &::after': {
        //   ...theme.transforms.none(),
      },

      '.icon__element': {
        //   ...theme.transforms.translateY('-100px'),
      },
    },
  },
  buttonPlayIcon: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: '2',

    '&::before, &::after': {
      // ...theme.transitions.create(['transform', 'background'], {
      //   easing: theme.transitions.easing.easeFast,
      // }),
      // ...theme.transforms.translateY('100px'),
      content: '""',
      width: '8px',
      height: 'calc(100% - 16px)',
      position: 'absolute',
      top: '8px',
      // background: theme.palette.danger.main,
    },

    '&::before': {
      right: 'calc(50% + 8px)',
    },

    '&::after': {
      left: 'calc(50% + 4px)',
    },

    '.icon__element': {
      // ...theme.transitions.create(['transform', 'stroke'], {
      //   easing: theme.transitions.easing.easeFast,
      // }),

      '&[stroke]': {
        // stroke: theme.palette.danger.main,
        strokeWidth: '14px',
      },
    },
  },
  iconPlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: '12px',
    background: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor: theme.design.colors.dimGrey,
    ' & svg': {
      fill: 'white',
    },

    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
      padding: '16px 16px 16px 22px',
    },

    '&__active': {
      width: '44px',
      height: '44px',
    },
  },
  iconElement: {
    width: '100%',
    height: '100%',
    float: 'left',
    objectFit: 'contain',
  },
}));
