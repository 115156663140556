import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from '../Blog/components/CreateContent/styles';
import { CREATENEWSLETTER, UPDATENEWSLETTER } from './graphQL';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import TextField from '../common/TextField/TextField';
import { Button } from '../common/Button/Button';
import Loader from '../common/Loader/Loader';
import { notifySuccess, notifyError } from '../../utilities/notify';
import {
  formHandleText,
  formHandleImage,
  formHandleFieldBlur,
} from '../../utilities/form';
import { parseDate } from '../../utilities/date';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import { GETNEWSLETTERS } from 'components/ViewNewsletters/graphQL';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import clx from 'classnames';
import { MediaDisplay } from 'components/common/MediaDisplay';

const CreateNewsletter = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isNewsletterId = window.location.pathname.split('/').includes('edit');
  const id = window.location.pathname.split('/').slice(-2)[0];
  const {
    data: NewslettersData,
    error: NewslettersError,
    loading: loadingNewsletters,
  } = useQuery(GETNEWSLETTERS, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const [embedAction, setEmbedAction] = useState('');
  const [date, setDate] = useState(parseDate(new Date()));
  const [createNewsletterMutation] = useMutation(CREATENEWSLETTER);
  const [updateNewsletterMutation] = useMutation(UPDATENEWSLETTER);

  const [loading, setLoading] = useState<boolean>(false);

  const handleFieldBlur = useCallback(
    (event) => {
      formHandleFieldBlur(event, formData, formErrors, setFormErrors);
    },
    [formErrors, formData]
  );

  useEffect(() => {
    setFormData({
      image: parseRecursive(NewslettersData?.newsletter?.image),
      url: NewslettersData?.newsletter?.url,
      title: NewslettersData?.newsletter?.title,
    });
  }, [NewslettersData]);

  const createContent = async () => {
    if (Object.keys(formData).length < 3) {
      notifyError('All Fields are Required');
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    if (!isNewsletterId) {
      createNewsletterMutation({
        variables: { ...formData, date, image: JSON.stringify(formData.image) },
      })
        .then((response) => {
          const {
            data: { createNewsletter },
          } = response;
          if (createNewsletter.errorId === 'NewsletterExistsAlready') {
            return notifyError('Newsletter name is taken');
          }
          notifySuccess('Newsletter Created Successfully');
          navigate('/admin/newsletters');
        })
        .catch((error: ApolloError) => {
          if (error.networkError) {
            notifyError('Failed to connect to API');
            return;
          }
          error.graphQLErrors.map(({ message }) => {
            notifyError(message);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateNewsletterMutation({
        variables: {
          ...formData,
          date,
          image: JSON.stringify(formData.image),
          id,
        },
      })
        .then((response) => {
          const {
            data: { updateNewsletter },
          } = response;
          // if (updateNewsletter.errorId === 'NewsletterExistsAlready') {
          //   return notifyError('Newsletter name is taken');
          // }
          notifySuccess('Newsletter Update Successfully');
          navigate('/admin/newsletters');
        })
        .catch((error: ApolloError) => {
          if (error.networkError) {
            notifyError('Failed to connect to API');
            return;
          }
          error.graphQLErrors.map(({ message }) => {
            notifyError(message);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    setFormData({ ...formData, [name]: JSON.stringify(url) });

    handleClose();
  };

  return (
    <>
      <div className={clx(classes.content)}>
        <p className={classes.content__title}>
          {isNewsletterId ? 'Edit Newsletter' : 'Create Newsletter'}
        </p>
        <form className={classes.content__form}>
          <div
            className={classes.content__form_section}
            style={{ width: '100%' }}
          >
            <label className={classes.content__form_label}>
              Newsletter Title:
            </label>
            <TextField
              onChange={(e: React.ChangeEvent<any>) => {
                formHandleText(e, setFormData, formData);
              }}
              onBlur={handleFieldBlur}
              type="text"
              value={formData?.title ?? ''}
              name="title"
            />
            {formErrors?.title && (
              <p className={classes.content__form_error}>{formErrors?.title}</p>
            )}
          </div>
          <div
            className={classes.content__form_section}
            style={{ width: '100%' }}
          >
            <label className={classes.content__form_label}>URL:</label>
            <TextField
              onChange={(e: React.ChangeEvent<any>) => {
                formHandleText(e, setFormData, formData);
              }}
              onBlur={handleFieldBlur}
              type="text"
              value={formData?.url ?? ''}
              name="url"
            />
            {formErrors?.url && (
              <p className={classes.content__form_error}>{formErrors?.url}</p>
            )}
          </div>
          <div
            className={classes.content__form_section}
            style={{ width: '100%' }}
          >
            <label className={classes.content__form_label}>Upload Image</label>
            <div
              style={{
                width: '30%',
                height: ((image) => {
                  if (image) {
                    return '100%';
                  }
                  return '120px';
                })(formData.image),
              }}
            >
              <MediaDisplay
                name={'image'}
                formData={formData}
                setFormData={setFormData}
                setEmbedAction={() => setEmbedAction('image')}
              />
            </div>
            <EmbedImage
              open={embedAction === 'image'}
              handleClose={handleClose}
              handleEmbed={handleImageEmbed}
              name={embedAction}
              formData={formData}
              setFormData={setFormData}
            />
            {formErrors?.image && (
              <p className={classes.content__form_error}>image is required</p>
            )}
          </div>
          <div
            className={classes.content__form_section}
            style={{ width: '100%' }}
          ></div>
          <div className={classes.content__form_actions}>
            <div style={{ position: 'relative' }}>
              <Button
                onClick={() => {
                  createContent();
                }}
                title={
                  isNewsletterId ? 'Update Newsletter' : 'Create Newsletter'
                }
              />
              <Loader display={loading} />
            </div>
          </div>
        </form>
      </div>
      <div className={classes.contentOverlay}>
        <Loader display={loadingNewsletters} />
      </div>
    </>
  );
};

export default CreateNewsletter;
