import React, { useState, useEffect, useCallback, FC } from 'react';
import useStyles from './styles';
import { useQuery, useMutation } from '@apollo/client';
import {
  CREATE_ANNOUNCEMENT,
  GETUSERS,
  FETCHANNOUNCEMENT,
  UPDATE_ANNOUCEMENT,
} from './graphQL';
import Taggables from '../../common/Taggables/Taggables';
import CustomTextField from '../../common/TextField/TextField';
import { Button } from '../../common/Button/Button';
import { formHandleText, formHandleFieldBlur } from '../../../utilities/form';
import Loader from '../../common/Loader/Loader';
import { notifyError, notifySuccess } from '../../../utilities/notify';
import { useNavigate } from 'react-router';
import Checkbox from '../../common/Checkbox/Checkbox';
import { Close } from '@mui/icons-material';
import { AnnoucementProps } from './types';
import { CircularProgress } from '@mui/material';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import { MediaDisplay } from 'components/common/MediaDisplay';
import FileField from 'components/common/FileField/FileField';

const CreateAnnouncement: FC<AnnoucementProps> = ({
  handleClose,
  id,
  setRefreshToken,
}) => {
  const { data, error } = useQuery(GETUSERS, {
    fetchPolicy: 'network-only',
  });
  const {
    data: announcementData,
    error: announcementError,
    loading: loadingAnnoucement,
  } = useQuery(FETCHANNOUNCEMENT, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const [createAnnouncement, { loading }] = useMutation(CREATE_ANNOUNCEMENT);
  const [updateAnnouncement, { loading: annoucementLoading }] =
    useMutation(UPDATE_ANNOUCEMENT);
  const [embedAction, setEmbedAction] = useState('');
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const classes = useStyles();
  const navigate = useNavigate();
  const [all, setAll] = useState<boolean>(false);

  const [userData, setUserData] = useState<{ [key: string]: string }[]>([]);

  useEffect(() => {
    data && setUserData(data.users.data);
  }, [data, error]);

  useEffect(() => {
    if (announcementData) {
      const announcement = announcementData.announcement;
      setFormData(announcement);
      setAll(announcement.all);
    }
  }, [announcementData, announcementError]);

  const handleFieldBlur = useCallback(
    (event) => {
      formHandleFieldBlur(event, formData, formErrors, setFormErrors);
    },
    [formErrors, formData]
  );

  const parseAndSubmitData = () => {
    const newFormData = { ...formData };
    const variables: { [key: string]: boolean | string | string[] } = {
      ...newFormData,
      all,
    };
    if (Object.keys(variables).length < 2) {
      notifyError('Fields Are Required');
      return;
    }
    if (id) {
      return handleSubmit({ ...variables, id });
    }
    handleSubmit(variables);
  };

  const handleSubmit = (variables: {
    [key: string]: boolean | string | string[];
  }) => {
    if (id) {
      updateAnnouncement({ variables })
        .then(() => {
          notifySuccess('Annoucement Updated');
          navigate('/admin/announcements');
          setRefreshToken(true);
        })
        .catch(() => {
          notifyError('An Error Occurred');
        })
        .finally(() => {
          handleClose();
        });
    } else {
      createAnnouncement({ variables })
        .then(() => {
          notifySuccess('Announcement Created');
          navigate('/admin/announcements');
          setRefreshToken(true);
        })
        .catch(() => {
          notifyError('An Error Occurred');
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  const handleTags = useCallback(
    (tags: string[]) => {
      const invitees = userData
        .filter((user) => tags.includes(user.name))
        .map((user) => user.id);
      if (invitees.length === 0) {
        const newFormData = { ...formData };
        delete newFormData.tags;
        delete newFormData.invitees;
        setFormData(newFormData);
        return;
      }
      setFormData({ ...formData, recipients: invitees, tags });
    },
    [formData, userData]
  );

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    setFormData({ ...formData, [name]: JSON.stringify(url) });
    setEmbedAction('');
  };

  return (
    <div className={classes.event}>
      <div className={classes.eventTitleParent}>
        <p className={classes.event__title}>
          {id ? 'Edit Annoucement' : 'Create Announcement'}
        </p>
        <Close className={classes.closeIcon} onClick={handleClose} />
      </div>
      {id && loadingAnnoucement ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" size="30px" />
        </div>
      ) : (
        <>
          <form className={classes.event__form}>
            <div style={{ width: '60%' }}>
              <div className={classes.event__form_section}>
                <label className={classes.label}>Title</label>
                <CustomTextField
                  onChange={(e: React.ChangeEvent<any>) => {
                    formHandleText(e, setFormData, formData);
                  }}
                  onBlur={handleFieldBlur}
                  type="text"
                  value={formData?.title ?? ''}
                  name="title"
                  className={classes.createTextfield}
                />
                {formErrors?.title && (
                  <p className={classes.event__form_error}>
                    {formErrors?.title}
                  </p>
                )}
              </div>

              <div className={classes.event__form_section}>
                <label className={classes.label}>Description</label>
                <CustomTextField
                  onChange={(e: React.ChangeEvent<any>) => {
                    formHandleText(e, setFormData, formData);
                  }}
                  onBlur={handleFieldBlur}
                  type="text"
                  value={formData?.description ?? ''}
                  name="description"
                  multiline={true}
                  rows={5}
                  className={classes.createTextfield}
                />
                {formErrors?.description && (
                  <p className={classes.event__form_error}>
                    {formErrors?.description}
                  </p>
                )}
              </div>

              <div className={classes.event__form_section}>
                <label className={classes.label}>Cover Image</label>
                <div
                  style={{
                    width: '100%',
                    height: ((image) => {
                      if (image) {
                        return '100%';
                      }
                      return '150px';
                    })(formData.image),
                  }}
                >
                  <MediaDisplay
                    name={'image'}
                    formData={formData}
                    setFormData={setFormData}
                    setEmbedAction={() => setEmbedAction('image')}
                  />
                </div>
                <EmbedImage
                  open={embedAction === 'image'}
                  handleClose={() => {
                    setEmbedAction('');
                  }}
                  handleEmbed={handleImageEmbed}
                  name={embedAction}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>

              <div className={classes.event__form_section}>
                <label className={classes.label}>Video</label>
                <FileField
                  handleChange={(url: string, name: string) => {
                    formHandleText(
                      { target: { name, value: url } },
                      setFormData,
                      formData
                    );
                  }}
                  name="video"
                  url={formData.video}
                  setImageUrl={() => {}}
                />
                {formErrors?.description && (
                  <p className={classes.event__form_error}>
                    {formErrors?.description}
                  </p>
                )}
              </div>
            </div>
            <div
              className={classes.event__form_section}
              style={{ marginBottom: 0, width: '40%' }}
            >
              <label className={classes.label}>Recipients</label>
              <Taggables
                className={classes.taggable}
                options={userData.map(
                  (user: { [key: string]: string }) => user.name
                )}
                invitees={true}
                onBlur={handleFieldBlur}
                onChange={(e, tags) => {
                  handleTags(tags);
                }}
                tags={formData.tags ?? []}
              />
              <div className={classes.event__check_parent}>
                <Checkbox
                  checked={all}
                  handleChange={(e) => {
                    setAll(Boolean(e?.target.checked));
                  }}
                />
                <p className={classes.senderToOptions}>To Employees</p>
              </div>
            </div>
          </form>
          <div className={classes.event__form_actions}>
            <div style={{ position: 'relative' }}>
              <Button
                className={classes.btn}
                onClick={parseAndSubmitData}
                title={id ? 'Edit Annoucement' : 'Create Announcement'}
              />
              <Loader display={loading || annoucementLoading} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateAnnouncement;
