import React, { useContext, useEffect, useState, useCallback } from 'react';
import { GET_ARTICLES, SEND_PUSH_NOTIFICATION } from './graphQL';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useStyles } from '../InstantArticles/styles';
import { AppStateContext } from '../../contexts/provider';
import { schema } from './schema';
import Table from '../common/Table/Table';
import TextField from '../common/TextField/TextField';
import { CircularProgress } from '@mui/material';
import Spinner from '../common/Spinner/Spinner';
import { searchFilter } from '../../utilities/filter';
import AuthContext from '../../contexts/auth';
import { notifyError, notifySuccess } from '../../utilities/notify';

const PushNotifications = () => {
  const classes = useStyles();
  const { toggleSpinner } = useContext(AuthContext);
  const { site } = useContext(AppStateContext);
  const [articleText, setArticleText] = useState<string>('');
  const [fetchArticles, { data, loading }] = useLazyQuery(GET_ARTICLES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [sendPushNotification] = useMutation(SEND_PUSH_NOTIFICATION);
  const [articles, setArticles] = useState<{ [key: string]: any }[]>([]);
  const [displayedArticles, setDisplayedArticles] = useState<
    { [key: string]: any }[]
  >([]);

  useEffect(() => {
    if (!['vt', 'twistedfood'].includes(site)) {
      setArticles([]);
      setDisplayedArticles([]);
      return;
    }
    fetchArticles({ variables: { site } });
  }, [site]);

  useEffect(() => {
    if (data) {
      const articlesData: { [key: string]: any }[] = data?.getPlatformArticles;
      const articles = articlesData.map(
        ({ id, title, description, url, site, instant_article_status }) => {
          return {
            id,
            title,
            description,
            url,
            site,
            instant_article_status,
          };
        }
      );
      setArticles(articles);
      setDisplayedArticles(articles);
    }
  }, [data]);

  const filterArticles = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      const newDisplayedArticles = searchFilter(
        articles,
        'title',
        event.target.value
      );
      setDisplayedArticles(newDisplayedArticles);
      setArticleText(event.target.value);
    },
    [articles]
  );

  const sendNotification = useCallback(
    async (url: string, title: string, body: string) => {
      toggleSpinner(true);
      await sendPushNotification({
        variables: {
          site,
          title,
          body,
          url,
        },
      })
        .then((data) => {
          if (data.data.sendPushNotification.includes('error')) {
            notifyError('Incorrectly Formatted Article');
            return;
          }
          notifySuccess('Success!');
          fetchArticles({ variables: { site } });
        })
        .catch(() => {
          //
        })
        .finally(() => {
          toggleSpinner(false);
        });
    },
    [articles, displayedArticles, site]
  );

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Push Notifications</p>
        <div className={classes.searchBox} style={{ width: '30%' }}>
          <div className={classes.search}>
            <img
              src="https://jungle-static.s3-us-west-1.amazonaws.com/jungleportal/search_icon.svg"
              className={classes.searchIcon}
              alt="search icon"
              style={{ left: 25 }}
            />
            <TextField
              name="article"
              type="text"
              value={articleText}
              onChange={filterArticles}
            />
          </div>
        </div>
      </div>

      <div className={classes.main}>
        {loading && !data ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <Table
              schema={schema(classes, sendNotification)}
              collection={displayedArticles}
              pagination={false}
            />
          </>
        )}
        <Spinner display={data && loading} />
      </div>
    </>
  );
};

export default PushNotifications;
