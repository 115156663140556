import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query fetchPlatformArticles($site: String!) {
    getPlatformArticles(site: $site) {
      title
      description
      url
      site
      id
      instant_article_status {
        status
        error
      }
    }
  }
`;

export const POST_ARTICLES = gql`
  mutation sendIA($slug: String!, $site: String!) {
    sendIa(slug: $slug, site: $site) {
      id
    }
  }
`;
