/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import clx from 'classnames';
import { TwistedSectionProps } from './types';
import { useTwistedfoodHomeStyles } from './styles';
import { Button } from '../../common/Button/Button';
import { Select } from '../../common/Select/Select';
import { AppStateContext } from 'contexts/provider';
import SideTabs from '../../common/SideTabs/SideTabs';
import { useMutation, useQuery } from '@apollo/client';
import { TwistedSectionsValidity, ValidationContext, VTSectionsValidity } from 'contexts/validation';
import { CircularProgress, Switch } from '@mui/material';
import { notifySuccess } from '../../../utilities/notify';
import { useNavigate, useLocation } from 'react-router-dom';
import { PURGECACHE } from 'components/ClearCache/graphQL';
import FormGroup from '../../FormBuilder/FormGroup/FormGroup';
import { FETCHCONFIG } from '../../../utilities/graphql/queries';
import { UPDATECONFIG } from '../../../utilities/graphql/mutations';
import { lifestyleCategories, recipeCategories, showsCategories } from './data';
import { camelCaseToTitleCase, getFormDataFromConfig, parseSectionsIntoJson, parseSectionsIntoProps } from '../../../utilities/helpers';

const TwistedfoodPage: React.FC = () => {
  const classes = useTwistedfoodHomeStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { siteDetails } = useContext(AppStateContext);
  const splitPath = location.pathname.split('/');
  const currentPath = splitPath[splitPath.length - 1];
  const previousPath = splitPath[splitPath.length - 2];
  const { isFormValid } = useContext(ValidationContext)

  // states
  const [, setInitialSections] = useState<TwistedSectionProps[]>([]);
  const [sections, setSections] = useState<TwistedSectionProps[]>([]);
  const [activeTabId, setActiveTabId] = useState<number>(0);
  const [form, setForm] = useState<{ [key: string]: any }>({});
  const [category, setCategory] = useState<string>(currentPath);

  const getPageRoute = () => {
    switch (currentPath) {
      case 'home':
        return 'index';
      default:
        return `${previousPath}/${currentPath}`;
    }
  };

  const getOptions = () => {
    switch (previousPath) {
      case 'recipes':
        return recipeCategories;
      case 'lifestyle':
        return lifestyleCategories;
      case 'shows':
        return showsCategories;
      default: {
        return {};
      }
    }
  };

  const optionValue = Object.values(getOptions());

  const getFERoute = (route: string) => {
    let result = route.replace(/(?:\/?(?:index|overview))\/?$/, '');
    result = '/' + result.replace(/^\/+|\/+$/g, '');

    return result;
  };

  // data fetching
  const { data, loading } = useQuery(FETCHCONFIG, {
    variables: { route: getPageRoute(), site: 'twistedfood' },
    fetchPolicy: 'network-only',
  });

  const [updateConfigMutation, { loading: updateLoading }] =  useMutation(UPDATECONFIG);
  const [purgeCache, { loading: purgeLoading }] = useMutation(PURGECACHE);

  useEffect(() => {
    if (data) {
      const route = getPageRoute();
      const { getConfig: pageContent } = data;

      const {
        config: {
          [route]: { content: sections },
        },
      } = JSON.parse(pageContent);
      const parsedSections = parseSectionsIntoProps(JSON.parse(sections));
      setForm(getFormDataFromConfig(parsedSections));
      setInitialSections(JSON.parse(JSON.stringify(parsedSections)));
      setSections(parsedSections);
    }
  }, [data]);

  const updateConfig = useCallback(async () => {
    if (updateLoading) return;
    const content = parseSectionsIntoJson(sections, form);

    try {
      const variables = {
        route: `${getPageRoute()}`,
        site: 'twistedfood',
        content,
      };
      await updateConfigMutation({ variables });
      await purgeCache({
        variables: { url: siteDetails.url + getFERoute(variables.route), type:'articles' },
      }).catch(console.log);
      notifySuccess('Updated successfully.');
      const parsedSections = parseSectionsIntoProps(JSON.parse(content));
      setInitialSections(JSON.parse(JSON.stringify(parsedSections)));
    } catch (error) {
      //
    }
  }, [sections, form]);

  const displayTwistedSections = () => {
    return sections.map(({ name }, index) => {
      const formattedName = camelCaseToTitleCase(name);

      return (
        <SideTabs
          key={index}
          title={formattedName}
          isActive={activeTabId === index}
          handleClick={() => {
            setActiveTabId(index);
          }}
        />
      );
    });
  };

  const displayTabContent = useCallback(() => {
    const fieldSections = Object.keys(sections?.[activeTabId]?.sections ?? {});

    return (
      <>
        <div className={classes.enabled}>
          <p>Enabled</p>
          <Switch
            inputProps={{ 'aria-label': 'demo' }}
            defaultChecked
            name={sections?.[activeTabId]?.name}
            className={classes.switch}
            onChange={() => {
              setForm((prevState: { [key: string]: any }) => {
                return {
                  ...prevState,
                  [sections?.[activeTabId]?.name]: {
                    ...prevState[sections?.[activeTabId]?.name],
                    enabled: !prevState[sections?.[activeTabId]?.name]?.enabled,
                  },
                };
              });
            }}
            checked={Boolean(form[sections?.[activeTabId]?.name]?.enabled)}
          />
        </div>
        {fieldSections.map((value: string, idx) => (
          <FormGroup
            key={idx}
            {...sections?.[activeTabId]?.sections[value]}
            name={value}
            sectionName={sections ? sections[activeTabId]?.name : ''}
            form={form}
            setForm={setForm}
          />
        ))}
      </>
    );
  }, [sections, activeTabId, form]);

  const handleCategoryChange = (
    e: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    const newCategory = e.target.value;
    const newURL = `${previousPath}/${newCategory}`;
    setCategory(newCategory);
    navigate(newURL);
  };

  return (
    <>
      <div className={classes.titleWidget}>
        <div className={classes.categoryContainer}>
          <p className={classes.title}>
            {splitPath.length > 3 ? `${previousPath}` : `${currentPath}`}
          </p>
          {optionValue.includes(currentPath) && (
            <div className={classes.categoryDropdown}>
              <Select
                value={category}
                options={getOptions()}
                onChange={handleCategoryChange}
              />
            </div>
          )}
        </div>
        <div className={classes.publishBtn}>
          <Button
            title="Publish"
            disabled={!sections || !isFormValid(sections[activeTabId]?.name as keyof (VTSectionsValidity | TwistedSectionsValidity))}
            loading={updateLoading || purgeLoading}
            onClick={() => updateConfig()}
          />
          <div className={clx(classes.overlay)} />
        </div>
      </div>
      <div className={classes.root}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <div className={classes.sectionsContainer}>
            <div className={classes.sectionsTab}>
              {displayTwistedSections()}
            </div>
            <form className={classes.form}>{displayTabContent()}</form>
          </div>
        )}
      </div>
    </>
  );
};

export default TwistedfoodPage;
