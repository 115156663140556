import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useButtonStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    '&.MuiButton-root': {
      height: 'fit-content',
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      borderRadius: '3px',
      fontSize: theme.typography.fontSize + 2,
      fontFamily: theme.design.font.universal,
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.design.colors.white,
      cursor: 'pointer',
      background: theme.design.colors.black,
      '&:hover': {
        color: theme.design.colors.white,
        backgroundColor: theme.design.colors.black,
      },
      '&:disabled': {
        color: theme.design.colors.white,
        backgroundColor: theme.design.colors.black,
        opacity: '55%',
      },
    },
  },
  icon: {
    color: theme.design.colors.white,
  },
}));
