import Sidebar from 'components/Sidebar/Sidebar';
import { TabsProps } from 'components/PortalDashboard/data';
import { FiClipboard } from 'react-icons/fi';

export const tabs: TabsProps[] = [
  {
    key: '1',
    name: 'Home',
    route: '/ca',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(3).svg',
  },
  {
    key: '2',
    name: 'Comment Analysis',
    route: '/ca',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(3).svg',
  },
  {
    key: '3',
    name: 'Settings',
    route: '/ca',
    icon: <FiClipboard className="icon" />,
  },
  {
    key: '4',
    name: 'Account',
    route: '/ca',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(3).svg',
  }
];

export function CommentAnalysisLayout() {
  return <Sidebar tabs={tabs} />;
}
