import {
  TableColumn,
  TableConditionFilter,
  Tag,
} from '@wazobia-tech/wazcom/dist/core';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';
import useBlogStyles from '../Blog/styles';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { notifyStatus } from 'simple-notify/dist/types';
import { blogClient, recipeClient } from 'utilities/apolloClient';
import { CREATE_GUIDE, UPDATE_GUIDE } from '../Blog/graphQL';
import useStyles from '../Blog/styles';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import { useGetPreSlugURL } from 'components/Blog/helpers';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  const classes = useStyles();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            navigate(`/cms/guides/${row.uuid}/edit`, { state: { row } });
          },
        },
        {
          name: 'Delete',
          func: (row: { uuid: string }) => {
            setPostId(row?.uuid);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Author', key: 'author.name', type: 'string' },
    { name: 'Categories', key: 'categories', type: 'string' },
    { name: 'Tags', key: 'tags', type: 'string' },
    { name: 'Last Modified', key: 'updated_at', type: 'date' },
  ];
};

export const getGuidesURL = (current_site_url: string) => {
  const portal_host = window.location.host;
  if (portal_host == 'portal.junglecreations.com') {
    return current_site_url.includes('twistedfood')
      ? current_site_url + '/guides'
      : current_site_url;
  } else {
    let convertedUrl = current_site_url
      ? current_site_url.replace('https://', 'https://dev.')
      : '';
    return current_site_url.includes('twistedfood')
      ? convertedUrl + '/guides'
      : convertedUrl;
  }
};

export const useGetTableRows = () => {
  const classes = useBlogStyles();
  const getPreSlugURL = useGetPreSlugURL();

  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = (
        <a
          href={getPreSlugURL(false, 'guides') + row?.slug}
          target="_blank"
          className={classes.blogTitleSlug}
        >
          {decodeHtml(row['title'])}{' '}
          {row?.status ? <FaCheckCircle className={classes.postCheck} /> : ''}
        </a>
      );
      parsedRow['author.name'] = row?.author?.name as string;
      parsedRow['categories'] =
        row?.categories && row?.categories?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.categories
              ?.slice(0, 3)
              ?.map(({ name }: any, index: number) => (
                <Tag name={name as string} key={index} />
              ))}
            {row?.categories?.length > 3 && (
              <span className={classes.postTagDifference}>
                {row?.categories?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      parsedRow['tags'] =
        row?.tags?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.tags?.slice(0, 3)?.map(({ name }: any, index: number) => (
              <Tag name={name} key={index} />
            ))}
            {row?.tags?.length > 3 && (
              <span className={classes.postTagDifference}>
                +{row?.tags?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] =
        updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Guides', condition: (posts: any) => posts },
  {
    name: 'Published',
    condition: (posts: any) => posts.filter((post: any) => post.status),
  },
  {
    name: 'Drafted',
    condition: (posts: any) => posts.filter((post: any) => !post.status),
  },
];

export const notify = (status: notifyStatus, title: string, text?: string) => {
  return new Notify({
    status: status,
    title: title,
    text: text,
    effect: 'slide',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 3500,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'top right',
  });
};

export const createGuide = async (variables: { [key: string]: any }) => {
  const result = await blogClient.mutate({
    mutation: CREATE_GUIDE,
    variables,
  });
  return result.data;
};

export const updateGuide = async (variables: { [key: string]: any }) => {
  const result = await blogClient.mutate({
    mutation: UPDATE_GUIDE,
    variables,
  });
  return result.data;
};
