import { gql } from '@apollo/client';

export const LATESTPOLLS = gql`
  query getPoll {
    latestPolls {
      data {
        id
        title
        status
        vote
        expires_at
        votes_count
        is_public
        author {
          name
        }
        options {
          id
          title
          votes_count
        }
      }
    }
  }
`;

export const MYSURVEYS = gql`
  query getSurvey {
    mySurveys {
      name
      url
    }
  }
`;
