export const videoData = [
  {
    name: 'The Add',
    src: 'https://sytbuildr.s3.eu-west-2.amazonaws.com/JUNGLE+THE+ADD_MN_V9.mp4',
  },
  // {
  //   name: 'VTC vid: Chloe Dillon 18 UGC/N/L',
  //   src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/8e4a32ac-6dfb-4665-81ea-62e0311df160.mp4',
  // },
  // {
  //   name: 'UGC - Sushi Pizza - N - M',
  //   src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/84186ee2-be6a-4a54-9304-a48f7eb16016.mov',
  // },
  // {
  //   name: 'UGC - Rainbow Cake - N - M',
  //   src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/bd38ba52-2561-43bc-9146-d03bfc3b3ab1.mov',
  // },
  // {
  //   name: 'Chloe Dillon Trying Sour Snacks - COMP/O/M',
  //   src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/0fd5b38f-0f4f-48be-86af-1991c0d84630.mp4',
  // },
  // {
  //   name: 'Bakes CN - Tara Rainbow Smiley Cookie Repost',
  //   src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/1ac12e24-23b5-4268-a5d7-40036cdc2569.mp4',
  // },
];
