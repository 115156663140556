import { useState, useContext } from 'react';
import { GqlMediaLibrary } from '@wazobia-tech/wazcom/dist/gql';
import {
  GET_IMAGES,
  UPLOAD_IMAGE,
  UPDATE_IMAGE,
  DELETE_IMAGE,
} from './graphql/queries';
import createApolloClient from 'utilities/apolloClient';
import { useMediaLibraryStyles } from './styles';
import { AddMedia } from '@wazobia-tech/wazcom/dist/gql/GqlMediaLibrary/components/AddMedia/AddMedia';
import { useFileSelect } from '@wazobia-tech/wazcom/dist/gql/GqlMediaLibrary/hooks';
import { Helmet } from 'react-helmet';
import { AppStateContext } from '../../contexts/provider';
import { commonMediaLibraryProps } from 'components/common/MediaDisplay/commonMediaProps';
import clx from 'classnames';

const MediaLibrary = () => {
  const classes = useMediaLibraryStyles();
  const handleFileSelect = useFileSelect();
  const [addMedia, setAddMedia] = useState<any>({});
  const [open, setOpen] = useState(false);
  const { siteDetails } = useContext(AppStateContext);

  const showAddMedia = (media: any) => {
    setAddMedia({ ...addMedia, ...media });
  };

  const handleFileSelection = () => {
    handleFileSelect(addMedia.fileRef);
    setOpen(false);
  };

  const renderHeader = () => (
    <div className={classes.pageTitleContainer}>
      <div>
        <h3 className={classes.pageTitle}>Media</h3>
        <p className={classes.desc}>
          Manage all images from your personal gallery, Unsplash, Instagram and
          Pexels.
        </p>
      </div>
      {!addMedia?.isSelector && addMedia.mediaTab === 0 && (
        <AddMedia
          handleFilesSelection={handleFileSelection}
          handleOpenMedia={open}
          buttonText="Add image"
          handleCloseMedia={setOpen}
          handleMediaAction={addMedia?.handleMediaAction}
          className={clx(addMedia?.addBtn, classes.mediaButton)}
        />
      )}
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>Media Library</title>
      </Helmet>

      <GqlMediaLibrary
        config={{
          ...commonMediaLibraryProps,
          client: createApolloClient(
            import.meta.env.VITE_DOLOS_API_URL as string
          ) as any,
          query: {
            name: GET_IMAGES,
            variables: {
              page: 1,
              count: 30,
              library_uuid: siteDetails?.library_uuid,
            },
          },
          upload: { name: UPLOAD_IMAGE, variables: {} },
          update: { name: UPDATE_IMAGE },
          remove: { name: DELETE_IMAGE },
        }}
        showAddMedia={showAddMedia}
        library_uuid={siteDetails?.library_uuid}
        header={renderHeader()}
      />
    </div>
  );
};

export default MediaLibrary;
