import { useLazyQuery, useMutation } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import {
  GET_SOCIAL_INTEGRATION,
  GET_FACEBOOK_PAGE_ACCESS_TOKEN,
  DELETE_CONNECTION,
} from './graphQL';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/auth';
import { schema, connectToFacebook } from './schema';
import Spinner from '../common/Spinner/Spinner';
import { AppStateContext } from '../../contexts/provider';
import Table from '../common/Table/Table';
import { useStyles } from '../InstantArticles/styles';
import { notifySuccess } from '../../utilities/notify';
import { Button } from '../common/Button/Button';

const Facebook = () => {
  const classes = useStyles();
  const { site } = useContext(AppStateContext);
  const { toggleSpinner } = useContext(AuthContext);
  const [fetchIntegrations, { data, loading }] = useLazyQuery(
    GET_SOCIAL_INTEGRATION,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  );

  const [
    fetchPages,
    { data: facebookPageData, loading: pagesLoading, error: pageError },
  ] = useMutation(GET_FACEBOOK_PAGE_ACCESS_TOKEN);
  const [deleteConnectionMutation] = useMutation(DELETE_CONNECTION);

  const [, setPages] = useState<{ [key: string]: any }[]>([]);
  const [displayedPages, setDisplayedPages] = useState<
    { [key: string]: any }[]
  >([]);

  useEffect(() => {
    fetchIntegrations({ variables: { site, platform: 'facebook' } });
  }, [site]);

  const deleteConnection = async (id: number) => {
    const proceed = window.confirm('are you sure you want to delete ?');
    if (!proceed) {
      return;
    }
    const variables = { id };
    toggleSpinner(true);
    try {
      await deleteConnectionMutation({ variables });
      await fetchIntegrations({ variables: { site, platform: 'facebook' } });
      notifySuccess('Connection Deleted Successfully');
    } finally {
      toggleSpinner(false);
    }
  };

  useEffect(() => {
    if (data) {
      const pagesData: { [key: string]: any }[] = data?.connectionByPlatform;
      const pages = pagesData.map(
        ({ id, name, token, expires_at, platform_id }) => {
          return {
            id,
            name,
            token,
            expires_at,
            platform_id,
          };
        }
      );
      setPages(pages);
      setDisplayedPages(pages);
    }
  }, [data]);

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Facebook</p>
        <Button
          title="Add New Page"
          onClick={() => {
            connectToFacebook(fetchPages, site, facebookPageData, pageError);
          }}
        />
      </div>
      <div className={classes.main}>
        {loading && !data ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <Table
              schema={schema(
                fetchPages,
                site,
                facebookPageData,
                pagesLoading,
                deleteConnection,
                pageError
              )}
              collection={displayedPages}
              pagination={false}
            />
          </>
        )}
        <Spinner display={data && loading} />
      </div>
    </>
  );
};
export default Facebook;
