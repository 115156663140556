import React from 'react';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { SubSectionListProps } from './types';

const SubSectionList: React.FC<SubSectionListProps> = ({ cards }) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.sections}>
        {cards.map((section, index) => (
          <Link to={section.route} className={classes.sectionLink}>
            <div key={index} className={classes.section}>
              <p className={classes.sectionName}>{section.name}</p>
              <div className={classes.sectionButton}>
                <span>Learn more</span>{' '}
                <IoIosArrowRoundForward className="icon" size={18} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SubSectionList;
