import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  event: {
    width: '100%',
    padding: theme.spacing(3),
    background: theme.design.colors.white,
  },
  event_title_parent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  event__title: {
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: theme.design.colors.grey100,
  },
  event__form: {
    background: theme.design.colors.white,
  },
  event__form_section: {
    marginBottom: theme.spacing(2.5),
  },
  event__form_label: {
    fontSize: '1.125rem',
    marginBottom: theme.spacing(2.25),
  },
  event__form_error: {
    marginTop: theme.spacing(2),
    fontSize: '14px',
    color: theme.design.colors.Red01,
  },
  event__check_parent: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },

  event__form_actions: {
    width: '100%',
    margin: theme.spacing(2.5, 0),
  },
  event__date_time_picker: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  reporter: {
    marginBottom: 0,
  },
  createTextfield: {
    margin: '0 !important',
    color: theme.design.colors.greyDark,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
    },
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  btn: {
    width: '100%',
  },
  taggable: {
    marginBottom: theme.spacing(2.5),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  loading: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
  },
  taskParent: {
    display: 'flex',
    gap: '32px',
    marginBottom: '24px',
  },
  formParent: {
    width: '48%',
  },
}));

export default useStyles;
