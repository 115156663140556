import React, { useCallback } from 'react';
import { CardProps } from './types';
import TextField from '../../common/TextField/TextField';
import FileField from '../../common/FileField/FileField';
import Checkbox from '../../common/Checkbox/Checkbox';
import { useCardStyles } from './styles';
import { getInputs } from './utilities';

const Card: React.FC<CardProps> = ({
  index,
  image,
  video,
  text,
  enabled,
  handleChange,
  handleDelete,
}) => {
  const classes = useCardStyles();
  const inputs = getInputs({
    index: index as number,
    image,
    video,
    text,
    enabled,
  });

  const displayInputs = useCallback(() => {
    return inputs.map(({ name, value, type, keys }, index) => {
      let input;
      switch (type) {
        case 'text':
          input = (
            <TextField
              name={name}
              label={name}
              value={value}
              onChange={(e) => {
                handleChange?.(keys, e.target.value);
              }}
            />
          );
          break;
        case 'file':
          input = (
            <FileField
              name="file"
              label={name}
              handleChange={(url: string) => {
                handleChange?.(keys, url);
              }}
              url={value as string}
            />
          );
          break;
        case 'checkbox':
          input = (
            <Checkbox
              label={name}
              checked={Boolean(value)}
              handleChange={(event) => {
                handleChange?.(keys, Boolean(event?.target.checked));
              }}
            />
          );
          break;
        default:
          input = <div key={index} />;
      }
      return (
        <div key={index} className={classes.inputParent}>
          {input}
        </div>
      );
    });
  }, [inputs]);

  return (
    <div className={classes.root}>
      {displayInputs()}
      <p
        className={classes.deleteBtn}
        onClick={() => {
          handleDelete?.(index as number);
        }}
      >
        Delete
      </p>
    </div>
  );
};

export default Card;
