import React from 'react';
import { useLocation } from 'react-router-dom';
import { TabProps } from './types';
import { Typography } from '@mui/material';
import clx from 'classnames';

const Tab: React.FC<TabProps> = ({ name, identifier, navigate, classes }) => {
  const location = useLocation();
  const locationSplitArray = location.pathname.split('/');
  const activePath = [
    locationSplitArray[locationSplitArray.length - 2],
    locationSplitArray[locationSplitArray.length - 1],
  ].join('/');

  const key =
    typeof identifier == 'object'
      ? identifier.find((value: string) => value == activePath)
      : identifier;

  return (
    <div
      onClick={navigate}
      key={name}
      className={`${classes.cell} ${
        location.pathname.includes(key) && classes.cell_active
      }`}
    >
      <Typography
        variant="inherit"
        className={clx(
          classes.text,
          location.pathname.includes(key) && classes.text_active
        )}
      >
        {name}
      </Typography>
    </div>
  );
};

export default Tab;
