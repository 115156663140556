import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    marginBottom: theme.spacing(2),
    height: '100%',
  },
  input_parent: {
    position: 'relative',
    height: '500px',
    width: '100%',
    borderRadius: '4px',
    border: '1px dashed rgba(0, 0, 0, 0.23)',
    background: theme.design.colors.grey150,
    '& p': {
      color: theme.design.colors.grey500,
      fontSize: '0.9rem',
    },
  },
  input_field: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: '4px',
    zIndex: 1,
    opacity: 0,
    cursor: 'pointer',
    background: theme.design.colors.grey400,
  },
  upload_btn: {
    background: `${theme.design.colors.white} !important`,
    color: `${theme.design.colors.black} !important`,
    boxShadow: 'none',
    border: `solid 1px #CCCCCD`,
    fontWeight: '400 !important',
    fontSize: theme.typography.fontSize,
  },
  uploadBtnMd: {
    top: '50%',
    border: `solid 1px $333`,
    borderRadius: '4px',
    padding: `${theme.spacing(0.5, 1.5)} !important`,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontWeight: 400,
    color: theme.design.colors.black,
  },
  loader: {
    position: 'absolute',
    top: '43%',
    left: '48%',
  },
  input_button: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    height: 'calc(100% - 10px)',
    width: '14.4%',
    background: theme.design.colors.widgetGrey,
    border: `1px solid ${theme.design.colors.titleIcon}`,
    cursor: 'pointer',

    '&$focused': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  uploaded_img: {
    position: 'relative',
    width: '100%',
    objectFit: 'cover',
    mixBlendMode: 'darken',
  },
  icons_section: {
    position: 'absolute',
    top: 8,
    right: 12,
    display: 'flex',
    gap: '16px',
  },
  change_image: {
    width: '25px',
    zIndex: 2,
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
  },
  icon_bg: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: theme.design.colors.grey700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  open_modal: {
    position: 'absolute',
    top: '5px',
    right: '83px',
    height: 'calc(100% - 10px)',
    width: '14.4%',
    background: theme.design.colors.widgetGrey,
    border: `1px solid ${theme.design.colors.titleIcon}`,
    cursor: 'pointer',

    '&$focused': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.black,
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    display: 'none',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  buttonLoader: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    height: 'calc(100% - 10px)',
    width: '17.4%',
    background: theme.design.colors.widgetGrey,
    border: `1px solid ${theme.design.colors.titleIcon}`,
    zIndex: -9999,
    opacity: 0,
    transition: 'opacity 0.5s ease-in',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
  },
  buttonLoaderActive: {
    opacity: 1,
    zIndex: 5,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  modalCard: {
    backgroundColor: theme.design.colors.white,
    width: '65%',
    height: '65%',
    borderRadius: '4px',
  },
  modalTitle: {
    borderBottom: `solid 1px ${theme.design.colors.offWhite}`,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(2),
    height: 'calc(100% - 90px)',
  },
  cropImg: {
    objectFit: 'contain',
    width: '100%',
    height: '95%',
  },
  cropBtnSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cropBtn: {
    background: theme.design.colors.widgetGrey,
    border: `1px solid ${theme.design.colors.titleIcon}`,
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.fontSize + 2,
  },
  fileFieidMd: {
    height: '100%',
    position: 'relative',
  },
}));

export default useStyles;
