import React from 'react';
import { ThemeProvider } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
  Outlet,
  Navigate,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cookies from 'universal-cookie';
import './App.css';
import theme from './contexts/theme';
import Dashboard from './pages/Dashboard/Dashboard';
import { Login } from './pages/Login/Login';
import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PortalLandingDashboard from 'components/LandingDashboard/PortalLandingDashboard';
import Cpas from 'pages/Cpas/Cpas';
import {
  adminRoutes,
  caRoutes,
  cmsRoutes,
  contentRoutes,
} from 'pages/Dashboard/routes';
import { RouteType } from 'pages/Dashboard/types';

const isAuthenticated = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};

const origins: string[] = [
  import.meta.env.VITE_APP_GRAPH_QL_URL ?? '',
  import.meta.env.VITE_APP_BLOG_API_URL ?? '',
  import.meta.env.VITE_APP_DNS_API_URL ?? '',
  import.meta.env.VITE_APP_AMBROSIA_API_URL ?? '',
  import.meta.env.VITE_APP_ACM_API_URL ?? '',
].filter((item) => item != '');



Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
  ],
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

function App() {
  const ProtectedRoutes = () => {
    return isAuthenticated() ? <Dashboard /> : <Login />;
  };

  const renderRoutes = (routes: RouteType[]): RouteObject[] => {
    return routes.map((route) => ({
      path: route.path,
      element: route.element,
      index: route.index,
      ...(route.routes
        ? {
            children: renderRoutes(route.routes),
          }
        : {}),
    }));
  };

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      element: <ProtectedRoutes />,
      children: renderRoutes([
        ...[
          { index: true, element: <PortalLandingDashboard /> },
          { path: 'cpas', element: <Cpas />, routes: [] },
          ...contentRoutes,
        ],
        ...caRoutes,
        ...cmsRoutes,
        ...adminRoutes,
      ]),
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <RouterProvider router={router}></RouterProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
