export const schemaFields = [
  {
    name: 'url',
    label: 'url',
  },
  {
    name: 'logo_url',
    label: 'logo url',
  },
  {
    name: 'street_address',
    label: 'street address',
  },
  {
    name: 'postal_address',
    label: 'postal address',
  },
];
