import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  event: {
    width: '100%',
    background: theme.design.colors.white,
    padding: theme.spacing(3),
    fontFamily: theme.design.font.universal,
    borderRadius: '4px',
    maxHeight: '80vh',
    overflow: 'scroll',
  },
  loading: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
  },
  event__title: {
    fontSize: theme.typography.fontSize + 4,
    lineHeight: '22px',
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightBold,
  },
  event__form: {
    background: theme.design.colors.white,
    display: 'flex',
    gap: theme.spacing(3),
  },
  content__form_label: {
    fontSize: theme.typography?.fontSize,
    marginBottom: theme.spacing(0.5),
    color: theme.design.colors.darkCharcoal,
  },
  event__form_section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2.5),

    '& .all_day': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  event__form_label: {
    fontSize: '1.125rem',
    marginBottom: theme.spacing(2.25),
  },
  event__form_error: {
    marginTop: theme.spacing(2),
    fontSize: '14px',
    color: theme.design.colors.Red01,
  },
  event__check_parent: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },

  event__form_actions: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
  },
  event__date_time_picker: {
    width: '100%',
  },
  eventTitleParent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  createTextfield: {
    margin: '0 !important',
    color: theme.design.colors.greyDark,
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 1.5),
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  senderToOptions: {
    fontSize: theme.typography.fontSize,
  },
  taggable: {
    background: theme.design.colors.grey150,
  },
  btn: {
    width: '100%',
    '&.MuiButton-root': {
      padding: theme.spacing(1.5, 3),
    },
  },
  imageContainer: {
    width: '100%',
    marginTop: theme.spacing(1.25),
  },
}));

export default useStyles;
