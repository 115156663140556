import { makeStyles } from '@mui/styles';
import { LayoutStyleProps } from './types';
import { Theme } from '@mui/material';

export const useLayoutStyles = makeStyles<Theme, LayoutStyleProps>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    verticalVariant: {
      flexDirection: 'column',
    },
    layoutElement: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: ({ width }) => width,
      },
    },
  })
);
