import React from 'react';
import { TabParentProps } from './types';
import { Typography, Collapse } from '@mui/material';
import clx from 'classnames';
import { useDisplayTabs } from '../../../utilities/page';

const TabParent: React.FC<TabParentProps> = ({
  name,
  icon,
  tabs,
  handleClick,
  active,
  navigate,
  classes,
}) => {
  const displayTabs = useDisplayTabs();
  const [navs, setNavs] = React.useState<{ [key: string]: boolean }>({
    content: false,
    forms: false,
  });

  return (
    <div key={name} className={clx(classes.tab, classes.tabParent)}>
      <div
        onClick={handleClick}
        className={clx(classes.tab, active && classes.tab_active)}
      >
        {typeof icon === 'string' ? <img src={icon} /> : icon}
        <Typography variant="inherit" className="text">
          {name}
        </Typography>
      </div>
      <Collapse
        in={active}
        className={String(classes.tabCollapse)}
        timeout="auto"
        unmountOnExit
      >
        {displayTabs(tabs, classes, navs, setNavs, navigate)}
      </Collapse>
    </div>
  );
};

export default TabParent;
