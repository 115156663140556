import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  issue: {
    position: 'absolute',
    top: '5px',
    backgroundColor: 'white',
    width: '35vw',
    zIndex: 1000,
    padding: '10px',
    borderRadius: '10px',
    border: '!px solid',
    boxShadow: '0px 6px 10px -5px rgba(0, 0, 0, 0.1)',
  },
  action: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  loading: {
    position: 'relative',
    top: 'calc((30vh - 40px)/2)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
  },
  main: {
    minHeight: '30vh',
    background: '#fff',
    borderRadius: '10px',
  },
  mainTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 0 25px 0',
    fontWeight: 'bold',
  },
  mainTopRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  mainTopLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5.3rem',

    '& p': {
      margin: '0 1.25rem 0 0',
      color: 'rgba(0,0,0,0.8)',
      width: '-webkit-fill-available',
    },
  },
  search: {
    width: '100%',
    height: '49px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  searchBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '50%',
  },
  searchIcon: {
    position: 'absolute',
    left: '6%',
    top: 20,
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: '2.5rem',
    marginTop: '30px',
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  icon: {
    fontSize: 20,
    position: 'relative',
    top: 6,
  },
  actionsParent: {
    display: 'flex',
  },
  actionsIcon: {
    fontSize: 18,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}));
