import { gql } from '@apollo/client';

export const GET_TWITTER_EMBED = gql`
  query GqlGetTwitterEmbed($url: String!) {
    getTwitterEmbed(url: $url) {
      data
      message
    }
  }
`;
