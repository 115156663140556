import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useDirectoryStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.lidGrey}`,
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
    borderRadius: '10px',
    width: '100%',
    height: '295px',
  },
  inputRoot: {
    color: 'inherit',
    marginBottom: theme.spacing(2),
    width: '90%',
    margin: '0 5% 0 5%',
  },
  inputInput: {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: '100%',
    '&.MuiOutlinedInput-input': {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up('sm')]: {
      width: '50px',
      '&:focus': {
        width: '100px',
      },
    },
  },
  directory: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    border: `0.5px solid ${theme.design.colors.grey900}`,
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2, 0),
    color: theme.design.colors.black,
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `1px solid ${theme.design.colors.whisper}`,
    justifyContent: 'space-between',
  },
  title_icon: {
    color: theme.design.colors.titleColor,
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },
  list_container: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  list_image: {
    marginTop: '4px',
    width: 24,
    height: 24,
    objectFit: 'cover',
    borderRadius: '50px',
  },
  list_details: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '& p': {
      color: theme.design.colors.titleColor,
      fontSize: 10,
    },
  },
  list_icon: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    '& p': {
      width: '70%',
      lineHeight: '1.3em',
      fontSize: theme.typography.fontSize - 2,

      '& .tooltiptext': {
        visibility: 'hidden',
        backgroundColor: theme.design.colors.white,
        padding: theme.spacing(1),
        border: `1px solid ${theme.design.colors.widgetBorder}`,
        borderRadius: '10px',
        color: theme.design.colors.titleColor,
        position: 'absolute',
        right: 0,
        top: '50%',
        zIndex: 1000,
      },
      '&:hover .tooltiptext': {
        visibility: 'visible',
      },
    },
  },
  name: {
    fontSize: '18px',
  },
  navigation: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& p': {
      textDecoration: 'underline',
      fontSize: '18px',
    },
  },
  scroll: {
    height: '90%',
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    display: 'flex',

    '&::-webkit-scrollbar': {
      height: '0px',
    },
  },
  loader: {
    margin: theme.spacing(6),
    color: theme.design.colors.greyDark,
  },
  loader_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 8,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography?.fontWeightMedium,
  },
}));

export default useDirectoryStyles;
