import React from 'react';
import useStyles from './styles';
import { CircularProgress } from '@mui/material';

interface FullScreenProps {
  display: boolean;
}

const FullScreenLoader: React.FC<FullScreenProps> = ({ display }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.fullScreen} ${
        display && classes.fullScreen_active
      }`}
    >
      <CircularProgress color="inherit" />
    </div>
  );
};

export default FullScreenLoader;
