import React, { FC, memo } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { get } from 'lodash';
import Checkbox from '../Checkbox/Checkbox';
import { TableProps } from './types';
import useStyles from './styles';
import { TablePaginationActions } from './TablePaginationActions';

const Table: FC<TableProps> = memo(
  ({
    schema,
    collection,
    identifier,
    pagination,
    hasCheckBox,
    rowsPerPage,
    changePage,
    changeRow,
    paginatorInfo,
    onClick,
  }) => {
    const currentPage = paginatorInfo?.currentPage
      ? paginatorInfo?.currentPage - 1
      : 0;
    const rows = paginatorInfo?.perPage ?? rowsPerPage;

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      changePage?.(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      changeRow?.(parseInt(event.target.value, 10) - 1);
      changePage?.(0);
    };

    const classes = useStyles();
    return (
      <div className={classes.list}>
        <TableContainer component="div">
          <MuiTable className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                {hasCheckBox ? (
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                ) : null}
                {schema.map(({ label }, index) => (
                  <TableCell key={index} className={classes.tableHeadCell}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ borderBottom: 'none' }}>
              {collection.map((item, rowIndex: number) => {
                const uniqueId = identifier ? get(item, identifier) : rowIndex;

                return (
                  <TableRow
                    onClick={() => {
                      if (onClick) {
                        const index = currentPage + rowIndex;
                        onClick(index);
                      }
                    }}
                    key={uniqueId}
                    style={{ cursor: onClick ? 'pointer' : 'null' }}
                  >
                    {hasCheckBox ? (
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                    ) : null}

                    {schema.map((SchemaItem, cellIndex) => {
                      return 'component' in SchemaItem ? (
                        <TableCell
                          key={cellIndex}
                          className={classes.tableBodyCell}
                        >
                          {SchemaItem.component({
                            item,
                            uniqueId,
                          })}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={cellIndex}
                          className={classes.tableBodyCell}
                        >
                          {SchemaItem.func({ item, uniqueId })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {pagination ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 40, 80]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    count={paginatorInfo?.total || 0}
                    rowsPerPage={Number(rows)}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    component="div"
                  />
                </TableRow>
              </TableFooter>
            ) : null}
          </MuiTable>
        </TableContainer>
      </div>
    );
  }
);

export default Table;
