import { convertData } from 'pages/Cpas/helpers';
import React from 'react';
import { options } from './data';
import {
  DataType,
  ElementType,
  PrimaryAxisType,
  SecondaryAxisType,
  PrimaryAxisPosition,
  SecondaryAxisPosition,
  TooltipAnchor,
  TooltipAlign,
  InteractionMode,
  TooltipGroupingMode,
} from './types';

const optionKeys = Object.keys(options) as (keyof typeof options)[];

export default function useChartConfig({
  series,
  datums = 10,
  useR,
  show = [],
  count = 1,
  canRandomize = true,
  dataType = 'time',
  elementType = 'line',
  primaryAxisType = 'time',
  secondaryAxisType = 'linear',
  primaryAxisPosition = 'bottom',
  secondaryAxisPosition = 'left',
  primaryAxisStack = false,
  secondaryAxisStack = true,
  primaryAxisShow = true,
  secondaryAxisShow = true,
  tooltipAnchor = 'closest',
  tooltipAlign = 'auto',
  interactionMode = 'primary',
  tooltipGroupingMode = 'primary',
  snapCursor = true,
  data = [], // Add a parameter for the data
  legend = true, // Add a parameter for the legend
}: {
  series: number;
  datums?: number;
  useR?: boolean;
  show?: (keyof typeof options)[];
  count?: number;
  resizable?: boolean;
  canRandomize?: boolean;
  dataType?: DataType;
  elementType?: ElementType;
  primaryAxisType?: PrimaryAxisType;
  secondaryAxisType?: SecondaryAxisType;
  primaryAxisPosition?: PrimaryAxisPosition;
  secondaryAxisPosition?: SecondaryAxisPosition;
  primaryAxisStack?: boolean;
  secondaryAxisStack?: boolean;
  primaryAxisShow?: boolean;
  secondaryAxisShow?: boolean;
  tooltipAnchor?: TooltipAnchor;
  tooltipAlign?: TooltipAlign;
  interactionMode?: InteractionMode;
  tooltipGroupingMode?: TooltipGroupingMode;
  snapCursor?: boolean;
  data?: any[];
  legend?: boolean;
}) {
  const [state, setState] = React.useState({
    count,
    canRandomize,
    dataType,
    elementType,
    primaryAxisType,
    secondaryAxisType,
    primaryAxisPosition,
    secondaryAxisPosition,
    primaryAxisStack,
    secondaryAxisStack,
    primaryAxisShow,
    secondaryAxisShow,
    tooltipAnchor,
    tooltipAlign,
    interactionMode,
    tooltipGroupingMode,
    snapCursor,
    datums,
    data,
    legend, // Add the legend parameter to the state
  });

  React.useEffect(() => {
    setState((old) => ({
      ...old,
      data,
    }));
  }, [data]);

  function handleOptionChange(
    option: keyof typeof options,
    value: string | boolean,
    setState: React.Dispatch<React.SetStateAction<any>>
  ) {
    setState((old: any) => ({
      ...old,
      [option]:
        typeof options[option][0] === 'boolean' ? value === 'true' : value,
    }));
  }

  const Options = optionKeys
    .filter((option) => show.indexOf(option) > -1)
    .map((option) => (
      <div key={option}>
        {option}: &nbsp;
        <select
          value={state[option] as string}
          onChange={({ target: { value } }) =>
            handleOptionChange(option, value, setState)
          }
        >
          {options[option].map((data: any) => (
            <option value={data as string} key={data.toString()}>
              {data.toString()}
            </option>
          ))}
        </select>
        <br />
      </div>
    ));

  return {
    ...state,
    Options,
  };
}
