import { Icon } from '@iconify/react';
import { Link, useParams } from 'react-router-dom';
import Container from './components/layout/Container';
import { SentimentAnalysis } from './components/analysis/SentimentAnalysis';
import { PostSources } from './components/analysis/PostSources';
import CommentsInsightsTabe from './components/analysis/CommentsInsightsTabe';
import { GET_CAMPAIGN } from 'graphql/jcmat/campaigns';
import { useQuery } from '@apollo/client';
import { caClient as client } from './client';
import { useCommentAnalysis } from 'contexts/ca';
import { useState } from 'react';

export default function SentimentReport() {
  const { campaignId } = useParams();
  const { isCommentsLoading, downloadCommentsHandler } = useCommentAnalysis();
  const [searchQuery, setSearchQuery] = useState('');

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      uuid: campaignId,
    },
    client,
  });
  const commentsCount = data?.campaign?.commentsCount;

  const filteredComments = data?.campaign?.comments?.filter((comment: any) =>
    comment?.text?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <Container>
      <div>
        <div className="space-y-8 max-w-full">
          <div className="flex lg:items-center lg:flex-row flex-col gap-1 mt-[4px]">
            {/* @ts-ignore */}
            <Link
              className="flex items-center space-x-1"
              to={`/ca/campaign/${campaignId as string}`}
            >
              <div className="mt-[5px]">
                <Icon icon="ic:round-arrow-back-ios" color="#292D32" />
              </div>

              <p className="text-[#6C747A] whitespace-nowrap">
                Campaign Overview / Campaign Details
              </p>
            </Link>

            <p className="whitespace-nowrap">/ Detailed Sentiment Report</p>
          </div>

          <div className="space-y-3">
            <div className="flex justify-between lg:items-center gap-2 flex-col lg:flex-row">
              <p className="text-lg font-[500]">
                Sentiment Report for {data?.campaign?.name}
              </p>

              <div>
                <button
                  style={{ border: '2px solid #EAECF0' }}
                  onClick={() => downloadCommentsHandler(campaignId as string)}
                  disabled={!commentsCount}
                  className={` px-4 py-3 space-x-2 flex items-center rounded-[8px] border-2 ${
                    commentsCount
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed opacity-50'
                  }`}
                >
                  <Icon icon="octicon:download-16" color="#242424" />

                  <span className="text-[#242424] text-xs whitespace-nowrap">
                    {isCommentsLoading
                      ? 'Downloading...'
                      : 'Export Sentiment Report'}
                  </span>
                </button>
              </div>
            </div>

            <div
              style={{ border: '2px solid #EAECF0' }}
              className="p-5 h-28 shadow-md rounded-[12px] py-10 lg:py-0"
            >
              <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 h-full content-center">
                <div className="text-center space-y-1">
                  <p className="text-[#101828] text-3xl font-[500]">
                    {data?.campaign?.postsCount}
                  </p>

                  <p className="text-[#101828]">Post Counts</p>
                </div>

                <div className="text-center space-y-1">
                  <p className="text-[#101828] text-3xl font-[500]">
                    {commentsCount}
                  </p>

                  <p className="text-[#101828]">Comments Counts</p>
                </div>

                <div className="text-center space-y-1">
                  <p className="text-[#101828] text-3xl font-[500]">0</p>

                  <p className="text-[#101828]">Mentions Counts</p>
                </div>

                <div className="text-center space-y-1">
                  <p className="text-[#101828] text-3xl font-[500]">0</p>

                  <p className="text-[#101828]">Likes Counts</p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            <SentimentAnalysis
              sentiment={data?.campaign?.sentiment}
              totalComments={commentsCount}
            />

            <PostSources />
          </div>

          <CommentsInsightsTabe
            comments={filteredComments}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>
    </Container>
  );
}
