/* eslint-disable object-shorthand */
// import ReactECharts from 'echarts-for-react';

export function PostSources() {
  const options = {
    tooltip: {
      trigger: 'item',
      formatter: function (params: {
        color: string;
        name: string;
        percent: string;
        value: string;
      }) {
        return `
          <p style="color: ${params.color}; font-size: 14px; font-weight: 400;">${params.name}</p>
          <p style="color: #757575; font-size: 12px;">Percentage: ${params.percent}%</p>
          <p style="color: #757575; font-size: 12px;">Posts: ${params.value}</span></p>
        `;
      },
    },
    legend: {
      bottom: '10%',
      left: 'center',
      itemHeight: 10,
      itemWidth: 10,
      itemGap: 30,
      icon: 'circle',
      formatter: (name: string) => {
        switch (name) {
          case 'LinkedIn':
            return 'LinkedIn - 0%';
          case 'TikTok':
            return 'TikTok - 0%';
          case 'YouTube':
            return 'YouTube - 0%';
          case 'Medium':
            return 'Medium - 0%';
          case 'Twitter':
            return 'Twitter - 0%';
          case 'Facebook':
            return 'Facebook - 0%';
          case 'Instagram':
            return 'Instagram - 0%';
          default:
            return name;
        }
      },
    },
    color: [
      '#3CC3DF', // LinkedIn
      '#FF928A', // TikTok
      '#1F93FF', // YouTube
      '#988AFC', // Medium
      '#07DBFA', // Twitter
      '#7086FD', // Facebook
      '#6FD195', // Instagram
    ],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '65%'],
        center: ['50%', '40%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 0, name: 'LinkedIn' },
          { value: 0, name: 'TikTok' },
          { value: 0, name: 'YouTube' },
          { value: 0, name: 'Medium' },
          { value: 0, name: 'Twitter' },
          { value: 0, name: 'Facebook' },
          { value: 0, name: 'Instagram' },
        ],
      },
    ],
  };

  return (
    <div
      style={{ border: '2px solid #EAECF0' }}
      className="p-5 max-h-full flex flex-col justify-between shadow-md rounded-[12px]"
    >
      <div>
        <p className="text-[#101828] font-[550] text-lg">Post Sources</p>

        <div className="w-full max-h-full h-[20rem] flex items-center">
          {/* @ts-ignore */}
          {/* <ReactECharts
            option={{
              ...options,
              grid: {
                left: '0%',
                right: '0%',
                top: '5px',
                bottom: '0%',
                containLabel: true, // Ensure labels are contained within the grid
              },
            }}
            style={{ height: '100%', width: '100%' }}
          /> */}
        </div>
      </div>

      <div
        style={{ border: '2px solid #EAECF0' }}
        className="w-full rounded-[12px]"
      >
        <div className="text-[#5E646D] font-semibold border-b p-4 bg-[#F2F2F280]">
          Report
        </div>

        <div className="p-4 space-y-1">
          <p className="text-[#5B5B5B]">
            Posts in this campaign are generated mostly from Instagram
          </p>
        </div>
      </div>
    </div>
  );
}
