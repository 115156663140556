import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  list: {
    '& .MuiTableCell-root': {
      fontFamily: theme.design.font.universal,
      borderSpacing: '0 4px',
    },
  },
  table: {
    width: '100%',
    background: theme.design.colors.white,
    borderRadius: '10px',
  },
  tableHead: {
    background: theme.design.colors.white,
    padding: '1.375rem 0',

    '& th': {
      fontSize: theme.typography?.fontSize,
      fontFamily: theme.design.font.universal,
      color: theme.design.colors.jpGrey300,
    },
  },
  tableRow: {
    '& tr': {
      borderSpacing: theme.spacing(0, 4),
    },
  },
  tableHeadCell: {
    fontFamily: theme.design.font.universal,
  },
  tableBodyCell: {
    paddingLeft: '20px',
    paddingRight: '20px',
    fontSize: theme.typography.fontSize,
    fontFamily: theme.design.font.universal,
  },
}));

export default useStyles;
