import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useTextAreaStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  textArea: {
    padding: theme.spacing(1.3, 1.5),
    border: `1px solid ${theme.design.colors.nueGrey}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: theme.typography.fontSize,
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.black,
    marginBottom: theme.spacing(1),
  },
}));
