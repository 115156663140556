import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    fontFamily: 'Universal',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  title: {
    color: '#386A2A',
    fontFamily: 'Georgia',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  layout: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: theme.spacing(3),
  },
  headerBox: {
    display: 'flex',
    width: '100%',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '6px',
    border: '1px solid #DED9D8',
    background: '#FFF',
    marginTop: theme.spacing(2),
  },
  headerBoxTitle: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '16px',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  gridItem: {},
  itemName: {
    color: '#868382',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },
  itemValue: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '21px',
  },
  poDetails: {
    borderRadius: '6px',
    border: '1px solid #DED9D8',
    background: '#FFF',
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    marginTop: theme.spacing(2),
  },
  poDetailsTitle: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  poDetailsData: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(2),
  },
  totalPrice: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(0, 0),
    background: 'white',
  },
  totalPriceTitle: {
    color: '#868382',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },
  totalPriceAmount: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  items: {
    display: 'flex',
    padding: theme.spacing(2, 2),
    alignItems: 'center',
    gap: '18px',
    borderRadius: '2px',
    border: '1px solid #DED9D8',
    background: '#F3FFF0',
    width: '100%',
  },
  item: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  itemTitle: {
    display: 'flex',
    width: '42px',
    height: '14px',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  itemDetails: {
    display: 'flex',
    width: '211px',
    height: '13px',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },
  businessRequirements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
    width: '100%',
  },
  businessRequirementsContent: {
    display: 'flex',
    width: '100%',
    padding: '10px',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '4px',
    border: '1px solid #DED9D8',
  },
  businessRequirementsTitle: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '21px',
  },
  businessRequirementsText: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
  },
  hr: {
    width: '100%',
    height: '1px',
    border: 'none',
    backgroundColor: '#DED9D8',
    margin: theme.spacing(2, 0),
  },
  sidebar: {},
  progress: {
    display: 'flex',
    width: '381px',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '6px',
    border: '1px solid var(--Foundation-jungle-Grey-jungle-grey-600, #DED9D8)',
    background: 'var(--Foundation-Dark-D80, #FFF)',
  },
  progressTitle: {
    color: 'var(--jungle-black-text, #333)',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '21px',
  },
  tracker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  step: {
    width: '25px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  line: {
    width: '2px',
    height: '50px', // Adjust height based on the number of steps
    backgroundColor: '#ccc',
    marginLeft: '10px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
  progressTracker: {
    width: '100%',
  },
  summary: {
    fontSize: '12px',
    marginTop: '5px',
  },
  progressDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  noPadding: {
    padding: '0 !important',
  },
  vendorDetails: {
    display: 'flex',
    width: '783px',
    height: '140px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '6px',
    border: '1px solid #DED9D8',
    background: '#FFF',
  },
  vendorDetailsTitle: {
    color: '#333',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
}));

export default useStyles;
