import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      margin: 0,
    },

    '& .Mui-checked': {
      color: `${theme.design.colors.black} !important`,
    },
  },
}));

export default useStyles;
