export function getRoute({
  array,
  route,
}: {
  array: { name: string; route?: string; title?: string; content?: string }[];
  route: string;
}) {
  for (const item of array) {
    if (item?.route === route) {
      return item;
    }
    if (item?.title === route) {
      return item;
    }
  }
  return null;
}

export function getContentFuntion({
  array,
  title,
}: {
  array: { title: string; content: string }[];
  title: string;
}) {
  for (const item of array) {
    if (item?.title === title) {
      return item;
    }
  }
  return null;
}
