import { isRequired, ValidationProps } from './validation';
import { validateFile } from './file';
import { IndexHtmlTransformHook } from 'vite';

interface FormData {
  [key: string]: string | string[] | File;
}

export const formHandleText = (
  { target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement>,
  setFormData: (formData: FormData) => void,
  formData: FormData
) => {
  if (value === '') {
    const newFormData: FormData = { ...formData };
    delete newFormData[name];
    setFormData(newFormData);
    return;
  }

  let formattedValue: string | File = value;
  if (name === 'slug') {
    formattedValue = value.toLowerCase().replace(/ /g, '-');
  }

  // if (name === 'excerpt') {
  //   setFormData({
  //     ...formData,
  //     excerpt: formattedValue,
  //     social_description: formattedValue,
  //     seo_description: formattedValue,
  //     twitter_description: formattedValue,
  //   });
  // }
  // console.log(formData);

  setFormData({ ...formData, [name]: formattedValue });
};

export const formHandleSite = (
  event: React.ChangeEvent<{ name?: string; value: string }>,
  setFormData: (formData: { [key: string]: string | string[] | File }) => void,
  formData: { [key: string]: string | string[] | File }
) => {
  const {
    target: { name, value },
  } = event;
  setFormData({
    ...formData,
    [String(name)]: value,
  });
};

export const formHandleSiteInBoolean = (
  event: React.ChangeEvent<{ name?: string; value: string }>,
  setFormData: (formData: { [key: string]: string | string[] | File }) => void,
  formData: { [key: string]: string | string[] | File }
) => {
  const {
    target: { name, value },
  } = event;
  setFormData({
    ...formData,
    [String(name)]: value,
  });
};

export const formHandleImage = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFormData: (formData: { [key: string]: undefined | File }) => void,
  formData: { [key: string]: string | string[] | File | undefined }
) => {
  event.preventDefault();
  const file = event.target?.files?.[0];
  if (!file || !validateFile(file)) {
    return;
  }
  setFormData({ ...formData, image: file });
};

export const formHandleTags = (
  event: React.ChangeEvent<string | unknown>,
  tags: string[],
  setFormData: (formData: { [key: string]: string | string[] | File }) => void,
  formData: { [key: string]: string | string[] | File }
) => {
  event.preventDefault();
  if (Array.isArray(tags)) {
    const newFormData = { ...formData };
    delete newFormData.tags;
    setFormData(newFormData);
    return;
  }
  setFormData({ ...formData, tags });
};

export const formHandleFieldBlur = (
  { target: { name, value } }: React.FocusEvent<HTMLInputElement>,
  formData: { [key: string]: string | string[] | File },
  formErrors: { [key: string]: string },
  setFormErrors: (formErrors: { [key: string]: string }) => void
) => {
  const validation: ValidationProps =
    name === 'tags'
      ? {
          status: !formData.tags
            ? false
            : (formData.tags as string[]).length > 0,
          errorText: 'the tags field is required',
        }
      : isRequired({ name, value });

  if (validation.status) {
    const newErrors = { ...formErrors };
    delete newErrors[name];
    setFormErrors(newErrors);
  } else {
    setFormErrors({
      ...formErrors,
      [name]: String(validation.errorText),
    });
  }
};

export const formHandleSchedule = (
  data: any,
  setFormData: (formData: { [key: string]: string | string[] | File }) => void,
  formData: { [key: string]: string | string[] | File }
) => {
  setFormData({ ...formData, schedule: data?.value[0] });
};
