import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    background: '#F4EFED 0% 0% no-repeat padding-box',
    minHeight: 'calc(100vh - 70px)',
  },
  header: {
    background:
      "transparent url('https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-04T175421/B3cpFluk99NkPe92WxiGlKFHuGNXML0Kl4WVNxWR.png') 0% 0% no-repeat padding-box",
    width: '100%',
    height: '18.6vw',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  heading: {
    letterSpacing: 0,
    font: 'normal normal 700 48px/72px universal',
    opacity: 1,
    marginTop: theme.spacing(4),
  },
  subheading: {
    font: 'normal normal normal 28px/42px universal',
    letterSpacing: 0,
    maxWidth: 596,
    textAlign: 'center',
  },
  body: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translateY(-20%)',
    gap: theme.spacing(3),
  },
  leaderContainer: {
    background: ' #A1AAFF 0% 0% no-repeat padding-box',
    borderRadius: theme.spacing(4),
    opacity: 1,
    width: '74vw',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  leader: {
    '& > p': {
      font: 'normal normal 500 16px/24px Universal',
      letterSpacing: 0,
      color: '#212121',
      marginLeft: theme.spacing(1),

      '&:last-child': {
        marginTop: theme.spacing(0.4),
      },
    },
  },
  leaderImage: {
    width: '100%',
    height: 'auto',
  },
  sections: {
    width: '75%',
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    gap: theme.spacing(2),

    '& > h4': {
      fontSize: '1.6vw',
      lineHeight: 1.4,
      margin: theme.spacing(3, 0),
    },
    '&>p': {
      lineHeight: 1.4,
      margin: theme.spacing(2, 0),
    },
  },
  section: {
    width: '100%',
    background: '#FFDCD2 0% 0% no-repeat padding-box',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3),
    opacity: 1,
  },
  sectionName: {
    font: 'normal normal bold 24px/36px universal',
    letterSpacing: 0,
    color: '#010E05',
    opacity: 1,
  },
  sectionButton: {
    font: 'normal normal normal 12px/18px universal',
    letterSpacing: 0,
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    textDecoration: 'none',

    '& > span': {
      display: 'block',
    },
  },
}));

export default useStyles;
