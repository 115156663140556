import React, { useState } from 'react';
import SurveyPolls from '../SurveyPolls/SurveyPolls';
import BirthdayWidget from '../BirthdayWidget/BirthdayWidget';
import EmployeeWidget from '../EmployeeWidget/EmployeeWidget';
import NewsletterWidget from '../NewsLetterWidget/NewsLetterWidget';
import OldSocialMediaWidget from 'components/SocialMediaWidget/oldSocialMediaWidgets';
import usePortalLandingStyles from './styles';
import Update from 'components/Update/Update';
import Events from '../Events/Events';

export default function PortalLandingDashboard() {
  const classes = usePortalLandingStyles();
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);

  return (
    <div className={classes.home}>
      <div className={classes.leftSection}>
        <SurveyPolls
          openViewModal={openViewModal}
          setOpenViewModal={setOpenViewModal}
        />
        <NewsletterWidget />
        <BirthdayWidget />
      </div>
      <div className={classes.social}>
        {/* <OffDayWidget /> */}
        <div className="social">
          <OldSocialMediaWidget />
        </div>
        <Update />
      </div>
      <div className={classes.right_section}>
        <Events />
        <EmployeeWidget />
      </div>
    </div>
  );
}
