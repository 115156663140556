import { FC, useContext, useState } from 'react';
import { ProfileProps } from './type';
import { Button } from 'components/common/Button/Button';
import { useStyles } from './styles';
import { AppStateContext } from 'contexts/provider';
import { MediaDisplay } from 'components/common/MediaDisplay';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import { SelectInput, TextArea, TextInput } from '@wazobia-tech/wazcom/dist/io';
import { gqlUpdateUser, profileFormData } from './helper';
import { ApolloError } from '@apollo/client';
import { errorHandler, notifySuccess } from 'utilities/notify';
import Cookies from 'universal-cookie';
import { getOptions } from 'components/Recipes/CreateRecipe/helper';

export const Profile: FC<ProfileProps> = ({}) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const { user, changeUser } = useContext(AppStateContext);

  const [embedAction, setEmbedAction] = useState('');
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    profile_image: user.avatar,
    fullname: user.name,
    email: user.email,
    uuid: user.uuid,
    role: user?.role,
    bio: user?.bio,
    social_handle: user?.social_handle,
    location: user?.location,
    position: user?.position,
    department: user?.department,
  });
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    setFormData({ ...formData, [name]: JSON.stringify(url) });
    setEmbedAction('');
    handleClose();
  };

  const handleTextChange = ({
    value,
    name,
  }: {
    name: string;
    value: string;
  }) => {
    setFormData((form: any) => {
      return {
        ...form,
        [name]: value,
      };
    });
  };

  const onSubmit = async () => {
    const variables = {
      avatar: JSON.stringify(parseRecursive(formData.profile_image)),
      uuid: formData.uuid,
      role: formData.role,
      bio: formData.bio,
      social_handle: formData.social_handle,
      location: formData.location,
      position: formData.position,
      department: formData.department,
    };
    setSubmitting(true);

    await gqlUpdateUser(variables)
      .then((res) => {
        const new_user = res.data.updateUser;
        cookies.remove('user', { path: '/' });
        cookies.set('user', JSON.stringify({ ...user, ...new_user }), {
          path: '/',
        });
        changeUser({ ...user, ...new_user });
        notifySuccess('Profile updated successfully');
      })
      .catch((error) => {
        if (error instanceof ApolloError) {
          errorHandler(error as ApolloError);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <section>
      <h2 className={classes.profileHeader}>
        Profile Information
        <span>
          <Button title={'Update'} loading={submitting} onClick={onSubmit} />
        </span>
      </h2>
      <article className={classes.profileBody}>
        <div className={classes.profileImage}>
          <MediaDisplay
            name={'profile_image'}
            formData={formData}
            setFormData={setFormData}
            setEmbedAction={setEmbedAction}
            label="Profile image"
          />
        </div>
        <div>
          <MediaDisplay
            name={'cover_image'}
            formData={formData}
            setFormData={setFormData}
            setEmbedAction={setEmbedAction}
            label="Cover image"
          />
        </div>
        <EmbedImage
          open={
            embedAction === 'profile_image' || embedAction === 'cover_image'
          }
          handleClose={handleClose}
          handleEmbed={handleImageEmbed}
          name={embedAction}
          formData={formData}
          setFormData={setFormData}
        />
        <form className={classes.profileForm}>
          <div className={classes.profileFirstInput}>
            {profileFormData.slice(0, 3).map((item, index) => (
              <TextInput
                key={index}
                label={item.label}
                name={item.name}
                classNames={{ input: classes.embedTextInput }}
                value={formData[item.name]}
                onChange={handleTextChange}
                disabled={item.disabled}
              />
            ))}
          </div>
          <div className={classes.profileFirstInput}>
            {profileFormData.slice(3, 6).map((item, index) => {
              if (item.type == 'select') {
                return (
                  <SelectInput
                    options={getOptions([
                      'The Wild - Partnerships',
                      'The Wild - Brand Direct',
                      'Operations',
                      'Marcomms',
                      'Finance',
                      'HR',
                      'Technology',
                      'SLT',
                    ])}
                    value={formData.department}
                    name={item.name}
                    className={classes.embedTextInput}
                    label={item.label}
                    onChange={({ name, value }) => {
                      handleTextChange({ name, value });
                    }}
                  />
                );
              }

              return (
                <TextInput
                  key={index}
                  label={item.label}
                  name={item.name}
                  classNames={{ input: classes.embedTextInput }}
                  value={formData[item.name]}
                  onChange={handleTextChange}
                  disabled={item.disabled}
                />
              );
            })}
          </div>
          <TextArea
            onChange={handleTextChange}
            value={formData?.bio}
            name="bio"
            label="BIO"
            className={classes.excerptText}
          />
        </form>
      </article>
    </section>
  );
};
