import { Icon } from '@iconify/react';

interface Props {
  result:
    | string
    | {
        sad: number;
        happy: number;
        neutral: number;
      };
}

export default function SentimentResult({ result }: Props) {
  console.log(result);

  return (
    <div className="h-full flex items-center w-full">
      {typeof result !== 'string' && (
        <div className="w-full">
          <Reaction type="happy" percentage={result.happy} />
          <Reaction type="neutral" percentage={result.neutral} />
          <Reaction type="sad" percentage={result.sad} />
        </div>
      )}

      {typeof result === 'string' && <p className="text-[#344054]">{result}</p>}
    </div>
  );
}

interface ReactionProps {
  percentage: number;
  type: 'happy' | 'neutral' | 'sad';
}

function Reaction({ type, percentage }: ReactionProps) {
  const color = {
    sad: '#FF2915',
    happy: '#AAE78E',
    neutral: '#DEA20D',
  }[type];

  const icon = {
    happy: 'ph:smiley-bold',
    sad: 'gravity-ui:face-sad',
    neutral: 'gravity-ui:face-neutral',
  }[type];

  return (
    <div className="flex items-center mb-1">
      <div>
        <Icon color={color} icon={icon} width={18} height={18} />
      </div>

      <div className="ml-2 rounded-full h-[5px] w-full max-w-[100px] bg-[#EAECF0]">
        <div
          className="h-full rounded-full"
          style={{ width: `${percentage}%`, backgroundColor: color }}
        />
      </div>

      <div className="ml-2">
        <p className="text-[#8A8A8A] text-[12px]">{percentage}%</p>
      </div>
    </div>
  );
}
