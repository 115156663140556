import { FC, useContext } from 'react';
import { RecipesCategoryProps } from './types';
import { AppStateContext } from 'contexts/provider';
import Category from './Category/Category';
import {
  CREATE_RECIPE_CATEGORY,
  DELETE_RECIPE_CATEGORY,
  GET_RECIPE_CATEGORIES,
  UPDATE_RECIPE_CATEGORY,
} from 'components/Blog/graphQL';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';

export const RecipesCategory: FC<RecipesCategoryProps> = ({}) => {
  const {
    siteDetails: { uuid: site_uuid },
  } = useContext(AppStateContext);
  const path = '/cms/recipes-category';
  const dataKey = 'getCategoriesBySiteUuid';
  return (
    <Category
      site_uuid={site_uuid}
      path={path}
      name="category"
      datakey={dataKey}
      DELETE_CATEGORY={DELETE_RECIPE_CATEGORY}
      GET_CATEGORIES={GET_RECIPE_CATEGORIES}
      UPDATE_CATEGORY={UPDATE_RECIPE_CATEGORY}
      CREATE_CATEGORY={CREATE_RECIPE_CATEGORY}
      tableConditionFilters={tableConditionFilters}
      useGetTableColumns={useGetTableColumns}
      useGetTableRows={useGetTableRows}
    />
  );
};
