import {
  TableColumn,
  TableConditionFilter,
  Tag,
} from '@wazobia-tech/wazcom/dist/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';
import useBlogStyles from './styles';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { notifyStatus } from 'simple-notify/dist/types';
import { blogClient } from 'utilities/apolloClient';
import useStyles from './styles';
import { AppStateContext } from 'contexts/provider';
import { useContext } from 'react';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import useOrderStyles from './styles';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useContext(AppStateContext);

  return [
    {
      name: 'PO Number',
      key: 'uuid',
      type: 'string',
      actions: [
        {
          name: 'Delete',
          func: (row: { uuid: string }) => {
            setPostId(row?.uuid);
            setOpenDeleteModal(true);
          },
        },
        ...([3, 10, 203, 133, 42, 201, 205].includes(user.id)
          ? [
              {
                name: 'Review',
                func: (row: any) => {
                  navigate(`/admin/purchase-orders/${row.uuid}/review`, {
                    state: { row },
                  });
                },
              },
            ]
          : []),
      ],
    },
    { name: 'Name', key: 'name', type: 'string' },
    { name: 'Job Title', key: 'jobTitle', type: 'string' },
    { name: 'Total', key: 'total', type: 'string' },
    { name: 'Submitted Date', key: 'created_at', type: 'date' },
    { name: 'Approved Date', key: 'approved_at', type: 'date' },
    { name: 'Status', key: 'status', type: 'string' },
  ];
};

export enum Status {
  approved = 'approved',
  received = 'received',
  processing = 'processing',
  cancelled = 'cancelled',
}

export const useGetStatusClass = () => {
  const classes = useOrderStyles();

  return (status: Status) => {
    switch (status) {
      case Status.approved:
        return classes.orderApproved;
      case Status.received:
        return classes.orderReceived;
      case Status.processing:
        return classes.orderProcessing;
      default:
        return classes.orderCancelled;
    }
  };
};

export const useGetTableRows = () => {
  const classes = useBlogStyles();
  const getStatusClass = useGetStatusClass();
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow.uuid = <p>&#x23;{row.po_number}</p>;
      parsedRow['name'] = row?.requestor.name as string;
      parsedRow['jobTitle'] = row?.requestor.position as string;
      parsedRow['total'] = row?.total as string;
      parsedRow['status'] = (
        <Tag
          name={
            row.status
              ? row.status.charAt(0).toUpperCase() +
                row.status.slice(1).toLowerCase()
              : ''
          }
          classname={getStatusClass(row.status)}
        />
      );
      parsedRow.created_at = moment(row?.created_at).format('DD/MM/YYYY HH:mm');
      parsedRow.approved_at = row?.approved_at
        ? moment(row?.approved_at).format('DD/MM/YYYY HH:mm')
        : '';
      parsedRow.identifier = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Posts', condition: (posts: any) => posts },
  {
    name: 'Published',
    condition: (posts: any) => posts.filter((post: any) => post.status),
    filter: [
      {
        column: 'status',
        operator: '=',
        value: '1',
      },
    ],
  },
  {
    name: 'Drafted',
    condition: (posts: any) => posts.filter((post: any) => !post.status),
    filter: [
      {
        column: 'status',
        operator: '=',
        value: '0',
      },
    ],
  },
];
