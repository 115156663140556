import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/common/Button/Button';
import Cookies from 'universal-cookie';
import { FcGoogle } from 'react-icons/fc';
import { AppStateContext } from '../../contexts/provider';
import { notifyError } from '../../utilities/notify';
import { Theme } from '@mui/material';
import jungle_hand from './jungle__hand.svg';
import jungle_smile from './jungle__smile.png';

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { changeUser } = useContext(AppStateContext);
  const classes = useStyles();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const cookies = new Cookies();
  const url = import.meta.env.VITE_REST_URL;

  useEffect(() => {
    if (code !== null) {
      setLoading(true);
      axios
        .get(url + 'api/auth/callback?code=' + code)
        .then(({ data }) => {
          const { access_token, expires_at, user } = data;
          const expiry_object = new Date(expires_at);
          const expiryTimestamp = expiry_object.getSeconds();
          expiry_object.setSeconds(expiryTimestamp);
          cookies.set('token', access_token, {
            path: '/',
            expires: expiry_object,
          });

          cookies.set('user', JSON.stringify(user), {
            path: '/',
            expires: expiry_object,
          });

          cookies.set('token_expiry', expiry_object.getTime(), {
            path: '/',
            expires: expiry_object,
          });
          changeUser(user);
          window.location.href = window.location.origin;
        })
        .catch((reason) => {
          notifyError(reason);
        });
    }
    // eslint-disable-next-line
  }, [code]);

  const login = () => {
    axios.get(url + 'api/auth/redirect').then(({ data }) => {
      window.location.href = data.redirect_url;

      return null;
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <div className={classes.info}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="114.179"
            height="84.577"
            viewBox="0 0 114.179 84.577"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_794"
                  data-name="Rectangle 794"
                  width="114.179"
                  height="84.577"
                  transform="translate(0 0.314)"
                />
              </clipPath>
            </defs>
            <g
              id="Group_2595"
              data-name="Group 2595"
              transform="translate(0 -0.314)"
              clip-path="url(#clip-path)"
            >
              <path
                id="Path_772"
                data-name="Path 772"
                d="M80.2,18.9H68.927c-2.119,0-3.337-.871-3.337-2.39,0-.908.458-2.433,3.519-2.433l15.812,0c8.537,0,13.673-1.53,13.673-5.942,0-4.158-5.047-7.823-14.4-7.823-14.582,0-19.53,14.281-27.1,14.281S44.572.314,29.991.314c-9.358,0-14.4,3.665-14.4,7.823,0,4.411,5.136,5.942,13.673,5.942l15.812,0c3.06,0,3.519,1.525,3.519,2.433,0,1.519-1.219,2.39-3.337,2.39H33.979C2.451,18.9,0,33.123,0,37.481A10.4,10.4,0,0,0,10.193,47.563c5.43,0,8.278-3.031,11.664-9.535,3.553-6.824,11.9-14.68,19.916-14.68,1.614,0,2.83.813,2.83,2.171,0,3.327-6.512,1.918-12.569,7.412-4.452,4.038-9.081,10.984-9.081,18.948,0,5.47,4.011,10.037,8.991,10.037,7.8,0,11.346-6.98,11.346-18.033,0-8.955,2.42-14.364,6.57-14.364a3.312,3.312,0,0,1,3.286,3.526c0,2.745-2.742,9.557-4.851,17.587-1.828,6.96-3.6,14.708-3.6,21.214,0,6.147,4.274,13.044,12.394,13.044s12.394-6.9,12.394-13.044c0-6.506-1.773-14.254-3.6-21.214-2.109-8.031-4.851-14.842-4.851-17.587a3.313,3.313,0,0,1,3.286-3.526c4.15,0,6.57,5.409,6.57,14.364,0,11.053,3.545,18.033,11.346,18.033,4.979,0,8.991-4.568,8.991-10.037,0-7.964-4.629-14.909-9.081-18.948-6.057-5.494-12.569-4.085-12.569-7.412,0-1.358,1.216-2.171,2.83-2.171,8.015,0,16.363,7.856,19.916,14.68,3.386,6.5,6.234,9.535,11.664,9.535a10.4,10.4,0,0,0,10.193-10.082C114.179,33.123,111.729,18.9,80.2,18.9Z"
                transform="translate(0 0)"
              />
            </g>
          </svg>
          <p className={classes.title}>
            Welcome to <span className={classes.title__second}>the Portal</span>
          </p>
          <p className={classes.info}>
            Sign In to Jungle Creation Intranet Portal using the button below.
          </p>
        </div>
        <Button
          // color="#6BCE2C"
          onClick={login}
          loading={loading}
          endIcon={<FcGoogle />}
          className={classes.loginButton}
        >
          Continue With Google
        </Button>
      </div>
      <img src={jungle_hand} className={classes.jungle_hand} />
      <img src={jungle_smile} className={classes.jungle_smile} />
      <img
        className={classes.jungle_right}
        src={
          'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-05T114703/LoarOsXnQ21OiYlqOUpWkV87ocna8HBLt3welvHt.png'
        }
      />
      <img
        className={classes.jungle_left}
        src={
          'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-05T114703/LoarOsXnQ21OiYlqOUpWkV87ocna8HBLt3welvHt.png'
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundSize: 'cover',
    backgroundColor: theme.design.colors.grey950,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  jungle_hand: {
    position: 'absolute',
    top: '120px',
    left: '120px',
  },
  jungle_smile: {
    position: 'absolute',
    right: '120px',
    bottom: '80px',
  },
  jungle_right: {
    position: 'absolute',
    left: '40px',
    bottom: '40px',
  },
  jungle_left: {
    position: 'absolute',
    right: '40px',
    top: '40px',
  },
  title__second: {
    color: theme.design.colors.black,
    display: 'block',
    fontWeight: 800,
  },
  loginButton: {
    justifyContent: 'space-between !important',
    width: '256px',
    borderRadius: '20px !important',
    margin: '32px auto 0 !important',
  },
  info: {
    fontFamily: 'Universal',
    fontSize: theme.spacing(2.3),
    marginBottom: '10px',
    color: theme.design.colors.black,
  },
  title: {
    fontSize: '137px',
    marginBottom: '10px',
    fontFamily: theme.design.font.feature,
    color: theme.design.colors.black,
    fontWeight: 'normal',
  },
  image: {
    height: '100vh',
    width: '100vw',
    backgroundSize: 'cover',
  },
  section: {
    position: 'absolute',
    flexDirection: 'column',
    padding: '20px',
    color: 'white',
  },
}));
