import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useCpasStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    paddingTop: theme.spacing(11),
  },
  cpasParent: {
    padding: theme.spacing(0, 6),
  },
  heading: {
    fontFamily: 'Universal',
    fontWeught: theme.typography.fontWeightBold,
  },
  dataParameterContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '10px',
    minHeight: 300,
    position: 'relative',
  },
  exportFilter: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    '&.creative': {
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1.5),
    },
  },
  exportBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    gap: theme.spacing(1.5),
    border: `1px solid ${theme.design.colors.black}`,
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    textDecoration: 'none',
    cursor: 'pointer',
    '& span': {
      color: theme.design.colors.black,
    },
    '&.loading': {
      cursor: 'progress',
    },
  },
  subHeadingParent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subHeading: {
    fontSize: theme.typography.fontSize + 8,
    color: theme.design.colors.jpGrey300,
    fontWeight: theme.typography.fontWeightBold,
  },
  select: {
    width: 'fit-content',
    border: 'none',
    '&.expand': {
      width: '100%',
    },
  },
  revenueCard: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  parameterParent: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  storiesRoot: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    gap: theme.spacing(2),
  },
  topStoriesParent: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '4px',
    background: theme.design.colors.white,
    width: '30%',
    minHeight: 500,
  },
  creativeParent: {
    width: '70%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '4px',
    background: theme.design.colors.white,
    position: 'relative',
  },
  selectField: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    padding: theme.spacing(0, 0.5, 0, 2),
    borderRadius: '3px',
  },
  chartHeading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  snapchat: {
    width: '100%',
    marginTop: theme.spacing(2),
    height: 640,
    borderRadius: '8px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
  },
  img: {
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
  filterSelect: {
    position: 'absolute',
    right: 20,
    top: 55,
  },
  tableParent: {
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '4px',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    position: 'relative',
  },
  tableContainer: {
    marginTop: theme.spacing(1.5),
  },
  box: {
    width: '80%',
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.design.colors.grey150,
    padding: theme.spacing(2.5, 3),
    height: '80vh',
    overflow: 'auto',
  },
  chart: {
    width: `100%`,
    height: `85%`,
    marginTop: theme.spacing(5),
  },
  filterText: {
    textWrap: 'noWrap',
    fontSize: theme.typography.fontSize,
  },
  loader: {
    margin: theme.spacing(6),
    color: theme.design.colors.greyDark,
  },
  loader_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    transform: 'translate(0, -50%)',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  filterContainer: {
    height: 'auto',
    left: '50%',
    top: '50%',
    position: 'fixed',
    borderRadius: '5px',
    transform: 'translate(-50%,-50%)',
    zIndex: 11,
    background: 'white',
    padding: theme.spacing(1.5),
  },
  dateRangeParent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 11,
  },
  filterSelectRoot: {
    background: theme.design.colors.white,
    position: 'relative',
    '&.table': {
      marginTop: theme.spacing(3),
    },
  },
  filterBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    '& button': {
      padding: theme.spacing(0.8, 1.5),
      background: 'transparent',
      border: `1px solid ${theme.design.colors.black}`,
      borderRadius: '4px',
    },
    '& .secondary': {
      background: theme.design.colors.black,
      color: theme.design.colors.white,
    },
  },
  overlay: {
    height: '100%',
    width: '100%',
    right: 0,
    top: 0,
    left: 0,
    bottom: 0,
    background: theme.design.colors.darkBackground,
    borderRadius: '4px',
    padding: theme.spacing(1),
    position: 'fixed',
    boxShadow: '0px 3px 6px #00000029',
    zIndex: 10,
    opacity: 1,
  },
  snapIcon: {
    top: 16,
    left: 30,
  },
}));

export default useCpasStyles;
