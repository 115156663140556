import { gql } from '@apollo/client';

export const GETCHANNELS = gql`
  query getChannels {
    getChannels {
      _id
      mutablePublisherName
      channel_id
      hostUsername
      businessProfileId
      metrics
    }
  }
`;

export const GETCHANNELINFO = gql`
  query getChannel(
    $businessProfileId: String
    $startDate: String!
    $endDate: String!
    $all_channels: Boolean
    $brand: String
  ) {
    getChannel(
      input: {
        businessProfileId: $businessProfileId
        start_date: $startDate
        end_date: $endDate
        all_channels: $all_channels
        brand: $brand
      }
    ) {
      metrics
      hostUsername
      formalName
    }
  }
`;

export const GETCHANNELSTORIES = gql`
  query getChannelStories(
    $businessProfileId: String
    $page: Int
    $first: Int
    $end_date: String!
    $start_date: String!
    $search: String
    $export: Boolean
    $all_channels: Boolean
    $brand: String
    $sort: [MetricSortInput!]
  ) {
    getChannelStories(
      input: {
        businessProfileId: $businessProfileId
        first: $first
        page: $page
        end_date: $end_date
        start_date: $start_date
        search: $search
        export: $export
        all_channels: $all_channels
        brand: $brand
        sort: $sort
      }
    ) {
      data {
        name
        story_id
        metrics {
          avg_time_viewed
          ecpm
          edition_subscribe_count
          revenue
          sold_impressions
          start_date
          unique_dau
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const GETALLCHANNELSTORIES = gql`
  query getChannelStories($all_channels: Boolean) {
    getChannelStories(input: { all_channels: $all_channels }) {
      data {
        metrics {
          avg_time_viewed
          ecpm
          edition_subscribe_count
          revenue
          sold_impressions
          start_date
          unique_dau
        }
      }
    }
  }
`;

export const GETCHANNELMETRICS = gql`
  query getAggregatedChannelStoryMetrics(
    $businessProfileId: String!
    $start_date: String
    $end_date: String
    $brand: String
    $all_channels: Boolean
  ) {
    getAggregatedChannelStoryMetrics(
      input: {
        businessProfileId: $businessProfileId
        start_date: $start_date
        end_date: $end_date
        brand: $brand
        all_channels: $all_channels
      }
    ) {
      day
      metrics
    }
  }
`;

export const GETTOPSTORYSNAPS = gql`
  query getTopStorySnaps(
    $businessProfileId: String
    $page: Int
    $first: Int
    $start_date: String!
    $end_date: String!
    $search: String
    $all_channels: Boolean
    $brand: String
  ) {
    getTopStorySnaps(
      input: {
        businessProfileId: $businessProfileId
        first: $first
        page: $page
        start_date: $start_date
        end_date: $end_date
        search: $search
        all_channels: $all_channels
        brand: $brand
      }
    ) {
      name
      snap_url
    }
  }
`;
