import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCsv = (name: string, data: { [key: string]: string }[]) => {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
  const excelBuffer: BlobPart = XLSX.write(workBook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const downloadData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(downloadData, name + fileExtension);
};

export default exportToCsv;
