import React from 'react';
import { useStyles } from './styles';
import clsx from 'classnames';
import IconPlay from './icon-play';

const ButtonPlay: React.FC<any> = (props) => {
  const classes = useStyles();

  return true ? (
    <div
      className={classes.iconPlay}
      onClick={() => {
        props.click();
      }}
    >
      <img
        src={
          'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-23T100841/ZkpijjPGphdZIDcvvUjm9M2dpF7N3STK6qrfXBuU.png'
        }
      />
    </div>
  ) : (
    <button
      onClick={props.click}
      className={clsx(classes.buttonPlay, {
        [classes.buttonPlayChange]: props.changeIcon,
      })}
      aria-label="Play button"
    >
      <span className={classes.buttonPlayIcon}>
        <IconPlay classes={classes.iconElement} />
      </span>
    </button>
  );
};

export default ButtonPlay;
