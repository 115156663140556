import { ApolloConsumer } from '@apollo/client';
import React, { useContext, useState } from 'react';
import useFormStyles from './styles';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import { GET_FORMS } from './graphql';
import { AppStateContext } from 'contexts/provider';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';

const ViewForms = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const classes = useFormStyles();
  const {
    site,
    siteDetails: { uuid },
  } = useContext(AppStateContext);
  const [refreshToken, setRefreshToken] = useState(
    String(Math.random() * 1000)
  );

  const handleDialogOpen = (uuid: string) => {
    setOpenDeleteModal(true);
  };
  const tableColumns = useGetTableColumns(handleDialogOpen);
  const getTableRows = useGetTableRows();

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Forms</p>
          </div>

          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: GET_FORMS,
                client,
                variables: {
                  site_uuid: uuid,
                  refreshToken,
                },
                dataKey: 'getFormsBySiteUuid',
              }}
              conditionFilters={tableConditionFilters}
              showFilters={false}
            />
          </div>
          {/* <DialogDelete
          openDeleteModal={openDeleteModal}
          handleClose={handleClose}
          deletePostCategory={handleDeleteArticle}
          setOpenDeleteModal={setOpenDeleteModal}
        /> */}
        </>
      )}
    </ApolloConsumer>
  );
};

export default ViewForms;
