import { FC, useContext, useState } from 'react';
import { EmbedImageProps } from './types';
import { Modal } from '@wazobia-tech/wazcom/dist/core';
import { useEmbedImageStyles } from './styles';
import { GqlImageCropper } from '@wazobia-tech/wazcom/dist/gql';
import { GqlMediaLibrary } from '@wazobia-tech/wazcom/dist/gql';
import createApolloClient from 'utilities/apolloClient';
import {
  GET_IMAGES,
  UPLOAD_IMAGE,
  UPDATE_IMAGE,
  DELETE_IMAGE,
} from 'components/Media/graphql/queries';
import { AppStateContext } from 'contexts/provider';
import { ImageCropperValue } from '@wazobia-tech/wazcom/dist/gql/GqlImageCropper/types';
import { commonMediaLibraryProps } from 'components/common/MediaDisplay/commonMediaProps';
import { Fade } from '@mui/material';
import { addImagesSubdomain } from 'utilities/image';

export const EmbedImage: FC<EmbedImageProps> = ({
  open,
  handleClose,
  handleEmbed,
  name,
  formData,
  setFormData,
}) => {
  const classes = useEmbedImageStyles();
  const [imageData, setImageData] = useState<
    ImageCropperValue & { uuid?: string }
  >({
    src: '',
    description: '',
  });
  const [showCropper, setShowCropper] = useState(false);
  const [addMedia, setAddMedia] = useState<any>({});

  const handleChange = ({ name, value }: { name: string; value: any }) => {
    setImageData({
      ...value,
      src: value.url,
    });
    return;
  };
  const { siteDetails } = useContext(AppStateContext);

  const handleGenerateImage = (name: string, value: any) => {
    handleEmbed?.(name, value);
    setShowCropper(false);
    handleClose();
  };
  const handleCloseCropper = () => {
    setShowCropper(false);
    handleClose();
  };

  const showAddMedia = (media: any) => {
    setAddMedia({ ...addMedia, ...media });
  };

  return (
    <Modal open={open} handleClose={handleClose} className={classes.modal}>
      <Fade in={open}>
        <div className={classes.wrapper}>
          <GqlMediaLibrary
            config={{
              ...commonMediaLibraryProps,
              client: createApolloClient(
                import.meta.env.VITE_DOLOS_API_URL as string
              ) as any,
              query: {
                name: GET_IMAGES,
                variables: {
                  page: 1,
                  count: 30,
                  library_uuid: siteDetails?.library_uuid,
                },
              },
              upload: { name: UPLOAD_IMAGE, variables: {} },
              update: { name: UPDATE_IMAGE },
              remove: { name: DELETE_IMAGE },
            }}
            showAddMedia={showAddMedia}
            onChange={({ name, value }: { name: string; value: any }) => {
              handleGenerateImage(name, value);
            }}
            library_uuid={siteDetails?.library_uuid}
            isSelector
            onSelect={(data) => {
              handleChange(data);
              setShowCropper(true);
            }}
          />
          {imageData.src && showCropper && (
            <GqlImageCropper
              name={name}
              open={true}
              value={imageData}
              handleClose={handleCloseCropper}
              onChange={({ name, value }) => {
                handleGenerateImage(name, value);
              }}
              config={{
                ...commonMediaLibraryProps,
                client: createApolloClient(
                  import.meta.env.VITE_DOLOS_API_URL as string
                ) as any,
                query: {
                  name: GET_IMAGES,
                  variables: {
                    page: 1,
                    count: 30,
                    library_uuid: siteDetails?.library_uuid,
                  },
                },
                upload: { name: UPLOAD_IMAGE, variables: {} },
                update: { name: UPDATE_IMAGE },
                remove: { name: DELETE_IMAGE },
              }}
              imageServiceDomain={addImagesSubdomain(siteDetails.url)}
            />
          )}
        </div>
      </Fade>
    </Modal>
  );
};
