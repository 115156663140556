import { useMemo, FC, useEffect } from 'react';
import { Tag as WazTag } from '@wazobia-tech/wazcom/dist/core';
import { useTagListStyles } from './styles';
import keyword_extractor from 'keyword-extractor';
import { getTagSuggestions } from './helpers';

export type TagsProps = {
  formData: { [key: string]: any };
  setFormData: any;
};

const TagList: FC<TagsProps> = ({ formData, setFormData }) => {
  const classes = useTagListStyles();

  const extraction_result = keyword_extractor.extract(formData.description, {
    language: 'english',
    remove_digits: true,
    return_changed_case: true,
    remove_duplicates: false,
    return_chained_words: true,
  });

  const initialSuggestions = useMemo(
    () =>
      extraction_result && extraction_result.length > 0
        ? extraction_result.slice(0, 10)
        : getTagSuggestions(),
    []
  );

  const suggestions = useMemo(
    () =>
      initialSuggestions.filter(
        (suggestion: any) =>
          !formData?.tags
            ?.map((item: any) => {
              if (typeof item == 'string') {
                return item;
              } else if (item && item?.name) {
                return item.name;
              }
              return '';
            })
            .filter(Boolean)
            .includes(suggestion)
      ),
    [initialSuggestions]
  );

  const handleAddSuggestion = (suggestion: string) => {
    const newTags = [...(formData?.tags ?? []), suggestion];
    setFormData({ ...formData, tags: newTags });
  };

  const handleRemoveTag = (index: number) => {
    const newTags = [...(formData?.tags ?? [])];
    newTags.splice(index, 1);
    setFormData({ ...formData, tags: newTags });
  };

  const renderSuggestions = () =>
    suggestions.map((suggestion: any, index: number) => (
      <div
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleAddSuggestion(suggestion);
        }}
      >
        <WazTag name={String(suggestion)} classname={classes.tagSuggestion} />
      </div>
    ));

  const renderSelections = () =>
    formData?.tags &&
    formData?.tags?.map(
      (tag: { name: string; uuid: string }, index: number) => (
        <WazTag
          key={index}
          name={String(tag?.name ?? tag)}
          handleRemove={() => {
            handleRemoveTag(index);
          }}
        />
      )
    );

  useEffect(() => {
    if (formData.tag_uuid) {
      setFormData((form_data: { [key: string]: any }) => {
        return {
          ...form_data,
          tags: [
            ...new Set([
              ...(form_data.tags ?? []),
              form_data.allTags.find(
                (item: any) => item.uuid == form_data.tag_uuid
              )?.name,
            ]),
          ],
        };
      });
    }
  }, [formData.tag_uuid]);

  return (
    <div className={classes.root}>
      {suggestions?.length > 0 && (
        <div className={classes.tagSuggestions}>
          <p className={classes.tagHeading}>SUGGESTIONS</p>
          <div className={classes.tagContent}>{renderSuggestions()}</div>
        </div>
      )}
      {formData?.tags?.length > 0 && (
        <div className={classes.tagSelections}>
          <p className={classes.tagHeading}>SELECTED</p>
          <div className={classes.tagContent}>{renderSelections()}</div>
        </div>
      )}
    </div>
  );
};

export default TagList;
