import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  poll: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    background: theme.design.colors.white,
  },
  poll__title: {
    fontSize: theme.typography.fontSize + 4,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  poll__form: {
    background: '#fff',
    borderRadius: '10px',
  },
  poll__form_section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
  poll__form_label: {
    fontSize: '1.125rem',
  },
  poll__form_error: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  poll__option: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 1.25rem 0',
  },
  poll__option_circle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: '2px solid #666666',
    margin: '0 0.75rem 0 0',
  },
  poll__option_remove: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '& p': {
      fontSize: theme.typography?.fontSize,
    },
  },
  poll__option_add: {
    padding: '0 0 1px 0',
    fontSize: theme.typography?.fontSize,
    color: theme.design.colors.pitchGrey,
    width: 'fit-content',
    cursor: 'pointer',
  },
  poll__duration_parent: {
    width: '100%',
    position: 'relative',

    '& .select': {
      height: '100%',
      position: 'absolute',
      top: 0,
      background: theme.design.colors.white,
      colors: theme.design.colors.white,
      right: 0,
      width: '50%',
      zIndex: 10,
      paddingLeft: '20px',
    },
  },
  poll__duration_input: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
    },
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  poll__submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  title_parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  textfieldContainer: {
    width: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      border: 'none',
      padding: theme.spacing(1, 1.5),
    },
  },
  btn: {
    width: '100%',
  },
  flexField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
}));

export default useStyles;
