import { ReactNode } from 'react';
import TailwindWrapper from 'components/common/Tailwind/TailwindWrapper';

interface Props {
  children: ReactNode;
}

export default function Container({ children }: Props) {
  return (
    <TailwindWrapper>
      <div className="pb-20 w-full">{children}</div>
    </TailwindWrapper>
  );
}
