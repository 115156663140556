import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useAccordionStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    width: '100%',
    '&.MuiAccordion-root': {
      boxShadow: 'none',
      border: `solid 1px ${theme.design.colors.borderGrey}`,
      borderRadius: '4px',
    },
  },
  accordion: {
    backgroundColor: theme.design.colors.grey10,
    boxShadow: '0',
    borderRadius: 4,
    border: `1px solid ${theme.design.colors.greyPitch}`,
  },
  icon: {
    color: '#343538',
    fontSize: '20px',
  },
  summary: {
    backgroundColor: theme.design.colors.red50,
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    '&.MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `solid 1px ${theme.design.colors.borderGrey}`,
    },
    '& .Mui-expanded': {
      margin: theme.spacing(1.5, 0),
    },
  },
  headSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.typography.fontSize,
  },
  deleteButton: {
    color: theme.design.colors.danger,
    padding: theme.spacing(0.5, 1.5),
    fontSize: '14px',
    fontWeight: 600,
  },
  '.MuiAccordion-root:before': {
    opacity: 0,
  },
}));
