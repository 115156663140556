import { FC, useContext } from 'react';
import { RecipeTagProps } from './types';
import { AppStateContext } from 'contexts/provider';
import Category from 'components/Category/Category';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_BLOG_CATEGORIES,
} from 'components/Blog/graphQL';
import { UPDATE_CATEGORY } from 'components/Category/components/CreateCategory/graphQL';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';

export const RecipeTags: FC<RecipeTagProps> = ({}) => {
  const {
    siteDetails: { blog_uuid, uuid },
  } = useContext(AppStateContext);
  const path = '/cms/recipes';
  const dataKey = 'getBlogCategoriesByBlogUuid';
  return (
    <Category
      blog_uuid={blog_uuid}
      name="tags"
      path={path}
      datakey={dataKey}
      DELETE_CATEGORY={DELETE_CATEGORY}
      GET_BLOG_CATEGORIES={GET_BLOG_CATEGORIES}
      UPDATE_CATEGORY={UPDATE_CATEGORY}
      CREATE_CATEGORY={CREATE_CATEGORY}
      tableConditionFilters={tableConditionFilters}
      useGetTableColumns={useGetTableColumns}
      useGetTableRows={useGetTableRows}
    />
  );
};
