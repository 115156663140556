import { FC, useContext } from 'react';
import { MediaDisplayProps } from './type';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import { GqlMediaLibrarySelector } from '@wazobia-tech/wazcom/dist/gql';
import { commonMediaLibraryProps } from './commonMediaProps';
import createApolloClient from 'utilities/apolloClient';
import {
  DELETE_IMAGE,
  GET_IMAGES,
  UPDATE_IMAGE,
  UPLOAD_IMAGE,
} from 'components/Media/graphql/queries';
import { AppStateContext } from 'contexts/provider';
import { addImagesSubdomain } from 'utilities/image';

export const MediaDisplay: FC<MediaDisplayProps> = ({
  name,
  formData,
  setFormData,
  setEmbedAction,
  label,
}) => {
  const {
    siteDetails: { library_uuid, url },
  } = useContext(AppStateContext);

  const imageUrl = parseRecursive(formData?.[name]);

  return (
    <GqlMediaLibrarySelector
      name={name}
      config={{
        ...commonMediaLibraryProps,
        client: createApolloClient(
          import.meta.env.VITE_DOLOS_API_URL as string
        ) as any,
        query: {
          name: GET_IMAGES,
          variables: {
            page: 1,
            count: 30,
            library_uuid,
          },
        },
        upload: {
          name: UPLOAD_IMAGE,
          variables: {},
        },
        update: { name: UPDATE_IMAGE },
        remove: { name: DELETE_IMAGE },
      }}
      library_uuid={library_uuid}
      value={imageUrl}
      onChange={({ name, value }) => {
        setFormData((form) => ({ ...form, [name]: JSON.stringify(value) }));
      }}
      variant="square"
      size="full"
      iconType="button"
      isSelector={false}
      label={label}
      imageServiceDomain={addImagesSubdomain(url)}
    />
  );
};
