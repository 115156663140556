import { ApolloProvider } from '@apollo/client';
import { Modal } from '@wazobia-tech/wazcom/dist/core';
import { caClient } from 'pages/Ca/client';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

interface ModalContextType {
  showModal: (content: ReactNode) => void;
  hideModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function ModalProvider({ children }: { children: ReactNode }) {
  const [content, setContent] = useState<ReactNode>(null);
  const [isOpen, setIsOpen] = useState(false);

  const showModal = useCallback((content: ReactNode) => {
    console.log(content);

    setContent(content);
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsOpen(false);
    setContent(null);
  }, []);

  return (
    <ApolloProvider client={caClient}>
      <ModalContext.Provider value={{ showModal, hideModal }}>
        {children}
        <Modal open={isOpen} isOpen={isOpen} onClose={hideModal}>
          <div
            style={{
              top: '50%',
              left: '50%',
              padding: '20px',
              outline: 'none',
              borderRadius: '8px',
              position: 'absolute',
              backgroundColor: 'white',
              width: 'min(90%, 600px)',
              transform: 'translate(-50%, -50%)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            {content}
          </div>
        </Modal>
      </ModalContext.Provider>
    </ApolloProvider>
  );
}

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
