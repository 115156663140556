import { TagFormProps } from './types';
import useTagStyles from './styles';
import { TagList } from '../tagList';
import { GqlComboBox } from '@wazobia-tech/wazcom/dist/gql';

const TagForm: React.FC<TagFormProps> = ({
  formData,
  setFormData,
  getTagsConfig: { variables, ...getTagsConfig },
  addOption,
}) => {
  const classes = useTagStyles();
  const postTagsFetchHook = (tagParams: { label: string; value: string }[]) => {
    const tags = tagParams.map(({ label, value }) => ({
      name: label,
      uuid: value,
    }));
    setFormData((form_data) => ({
      ...form_data,
      allTags: tags,
    }));
  };

  return (
    <div className={classes.content__form_section} style={{ width: '100%' }}>
      <p className={classes.title}>Tags</p>
      <div className={classes.adjustInput}>
        {/* @ts-ignore */}
        <GqlComboBox
          config={getTagsConfig}
          onChange={({ name, value }: any) => {
            setFormData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }}
          name="tag_uuid"
          variables={variables}
          placeholder="e.g entertainment"
          label="ADD TAGS TO DESCRIBE THE POST"
          postFetchHook={postTagsFetchHook}
          addOption={addOption}
          sx={{
            '& input': {
              minHeight: 0,
              width: '94%',
            },
          }}
        />
      </div>
      <TagList formData={formData} setFormData={setFormData} />
    </div>
  );
};

export default TagForm;
