import React from 'react';
import { OutlinedInput } from '@mui/material';
import useStyles from './styles';
import { Select } from '../Select/Select';
interface SiteFieldProps {
  options?: { [key: string]: string };
  onChange: ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: ({
    target: { name, value },
  }: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: ({
    target: { name, value },
  }: React.FocusEvent<HTMLInputElement>) => void;
  onSelect: (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => void;
  value: string;
  selectName?: string;
  selectValue?: string;
  name: string;
  disabled?: boolean;
  edit?: boolean;
  content?: boolean;
}

export const sites: { [key: string]: string } = {
  'https://vt.co/': 'vt',
  'https://twistedfood.co.uk/': 'twistedfood',
  'https://fournine.net/': 'fournine',
  'https://craftfactory.com/': 'craftfactory',
  'https://thewild.agency/': 'thewild',
};

const SiteField: React.FC<SiteFieldProps> = ({
  onChange,
  onBlur,
  value,
  onSelect,
  onFocus,
  name,
  disabled,
  selectName,
  selectValue,
  edit,
  options = sites,
  content,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.input_parent}>
      <div className={classes.select}>
        <Select
          name={selectName ?? ''}
          options={options}
          onChange={onSelect}
          value={selectValue}
          edit={edit}
        />
      </div>
      {content ? (
        <OutlinedInput
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={'text'}
          value={value}
          disabled={disabled}
          className={classes.input}
        />
      ) : (
        <OutlinedInput
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          type={'text'}
          defaultValue={value}
          disabled={disabled}
          className={classes.input}
        />
      )}
    </div>
  );
};

export default SiteField;
