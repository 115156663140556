export const determineSiteImage = (site?: { name: string }) => {
  if (!site) {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/Jungle.svg';
  }

  const name = site.name.toLowerCase().replace(/ /g, '');

  if (name === 'twisted' || name === 'twistedfood') {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/Twisted.svg';
  }

  if (name === 'vt') {
    return 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vt.svg';
  }

  if (name === 'fournine') {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/fournine.jpeg';
  }

  if (name === 'craftfactory') {
    return 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/craftfactory.jpeg';
  }

  if (name === 'lovimals') {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/lovimals.png';
  }

  return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/Jungle.svg';
};

export const determinePlatformImage = (platform: string) => {
  if (!platform) {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/facebook-icon.svg';
  }

  const formattedPlatform = platform.toLowerCase();

  if (formattedPlatform === 'instagram') {
    return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/instagram-icon.svg';
  }

  return 'https://jungle-portal.s3-us-west-1.amazonaws.com/icons/facebook-icon.svg';
};

export const addImagesSubdomain = (url: string): string => {
  const parsedUrl = new URL(url);
  const domain = parsedUrl.hostname;
  const parts = domain.split('.');

  parts.unshift('images');

  return parts.join('.');
};
