import { Icon } from '@iconify/react';
import { Link, useParams } from 'react-router-dom';
import { memo } from 'react';
import Container from './components/layout/Container';
import PostsTable from './components/posts/PostsTable';
import SavingsPromoCard from './components/campaign/SavingsPromoCard';
import SentimentReportCard from './components/campaign/SentimentReportCard';
import { useQuery } from '@apollo/client';
import { caClient as client } from './client';
import { GET_CAMPAIGN } from 'graphql/jcmat/campaigns';
import { Campaign } from 'generated/jcmat/graphql';
import { ModalProvider } from './components/common/ModalManager';
import { useCommentAnalysis } from 'contexts/ca';

export type PostModalStep = 1 | 2 | 3;

const pickCampaignStats = ({
  name,
  commentsCount,
  createdAt,
  postsCount,
}: Campaign) => ({ name, commentsCount, createdAt, postsCount });

export const CampaignDetails = memo(() => {
  const route = useParams();
  const { downloadCommentsHandler } = useCommentAnalysis();

  const { data, loading, error } = useQuery(GET_CAMPAIGN, {
    variables: { uuid: route.campaignUuid },
    client,
  });

  if (loading) return <p>Loading...</p>; // Wait until data is available
  if (error) return <p>Error: {error.message}</p>;

  if (data.campaign === undefined) {
    return <div>Something wrong! Please contact the administrator.</div>;
  }

  return (
    <Container>
      <ModalProvider>
        <div className="space-y-8">
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 justify-between lg:gap-8 max-w-full">
            <div className="flex items-center space-x-1 mt-[4px]">
              {/* @ts-ignore */}
              <Link className="flex items-center space-x-1" to="/ca">
                <Icon icon="ic:round-arrow-back-ios" color="#292D32" />

                <p className="text-[#6C747A] whitespace-nowrap">
                  Campaign Overview
                </p>
              </Link>

              <p className="whitespace-nowrap">/ Campaign Details</p>
            </div>

            <div>
              <button
                style={{ border: '2px solid #EAECF0' }}
                onClick={() => downloadCommentsHandler(data.campaign.uuid)}
                disabled={!data.campaign.commentsCount}
                className={` px-4 py-3 space-x-2 flex items-center rounded-[8px] border-2 ${
                  data.campaign.commentsCount
                    ? 'cursor-pointer'
                    : 'opacity-50 cursor-not-allowed'
                }`}
              >
                <Icon icon="octicon:download-16" color="#242424" />

                <span className="text-[#242424] text-xs whitespace-nowrap">
                  Export Sentiment Report
                </span>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-full">
            <SavingsPromoCard {...pickCampaignStats(data.campaign)} />
            <SentimentReportCard {...data.campaign.sentiment} />
          </div>

          <div className="max-w-full">
            <PostsTable campaignUuid={data.campaign.uuid} />
          </div>
        </div>
      </ModalProvider>
    </Container>
  );
});
