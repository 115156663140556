import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { notifyStatus } from 'simple-notify/dist/types';
import useStyles from '../Blog/styles';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  const classes = useStyles();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            navigate(`/admin/newsletters/${row.id}/edit`, { state: { row } });
            setRefreshToken(false);
          },
        },
        {
          name: 'Delete',
          func: (row: { id: string }) => {
            setPostId(row?.id);
            setOpenDeleteModal(true);
            setRefreshToken(false);
          },
        },
      ],
    },
    { name: 'Url', key: 'url', type: 'string' },
    { name: 'Date', key: 'updated_at', type: 'date' },
  ];
};

export const useGetTableRows = () => {
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];
    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = <p>{decodeHtml(row['title'])} </p>;
      parsedRow['url'] = <p>{decodeHtml(row['url'])} </p>;
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] =
        updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Newsletters', condition: (posts: any) => posts },
];

export const notify = (status: notifyStatus, title: string, text?: string) => {
  return new Notify({
    status: status,
    title: title,
    text: text,
    effect: 'slide',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 3500,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'top right',
  });
};
