import { gql } from '@apollo/client';

export const GETUSERS = gql`
  query getUsers {
    users {
      data {
        id
        name
      }
    }
  }
`;

export const GET_TASK = gql`
  query task($id: ID) {
    task(id: $id) {
      assignee_id
      created_at
      deadline
      description
      id
      name
      reporter_id
      status
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask(
    $name: String!
    $description: String!
    $deadline: DateTime!
    $reporter_id: ID
    $assignee_id: ID
  ) {
    createTask(
      name: $name
      description: $description
      deadline: $deadline
      reporter_id: $reporter_id
      assignee_id: $assignee_id
    ) {
      name
      description
      deadline
      reporter_id
      assignee_id
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation updateTask(
    $name: String!
    $description: String!
    $deadline: DateTime!
    $reporter_id: ID
    $assignee_id: ID
    $id: ID!
  ) {
    updateTask(
      name: $name
      description: $description
      deadline: $deadline
      reporter_id: $reporter_id
      assignee_id: $assignee_id
      id: $id
    ) {
      name
      description
      deadline
      reporter_id
      assignee_id
      id
    }
  }
`;
