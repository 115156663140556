import { Dispatch } from 'react';
import { TCategory } from './types';
import { removeSpecialCharacters } from 'components/Blog/components/helpers';
import { RECIPE_PAGE_TYPE } from 'layouts';

export const handleSubCategorySelection = (
  formData: any,
  subCategory: { name: string; uuid: string; slug: string },
  checked: boolean,
  setFormData: any,
  preSlugUrl: string,
  isArticle: boolean
) => {
  const { categories, category_uuid } = formData;
  const idx = categories?.findIndex(
    (category: { uuid: string }) => category?.uuid === category_uuid
  );

  if (idx < 0) return;

  let category = categories[idx];

  category = {
    ...category,
    subCategories: checked
      ? [subCategory]
      : category?.subCategories?.filter(
          ({ uuid }: { uuid: string }) => uuid !== subCategory?.uuid
        ),
  };
  categories[idx] = category;
  setFormData({ ...formData, name: 'categories', value: categories });
  updateSlug(formData, setFormData, preSlugUrl, isArticle);
};

export const isSubCategorySelected = (
  form: any,
  subCategory: { name: string; uuid: string }
) => {
  const { categories, category_uuid } = form;

  const idx = (categories ?? [])?.findIndex(
    (category: { uuid: string }) => category?.uuid === category_uuid
  );

  if (idx < 0) return false;

  const category = categories[idx];

  return Boolean(
    category?.subCategories?.find(
      ({ uuid }: { name: string; uuid: string }) => uuid === subCategory?.uuid
    )
  );
};

export const handleCategoryDelete = (
  formData: any,
  category_uuid: string,
  setFormData: any
) => {
  const { categories, category_uuid: form_category_uuid } = formData;
  const updatedCategories = categories?.filter(
    (category: { uuid: string }) => category?.uuid !== category_uuid
  );
  const updatedCategoryUuid =
    category_uuid === form_category_uuid
      ? updatedCategories.length > 0
        ? updatedCategories[0]?.uuid
        : undefined
      : form_category_uuid;

  setFormData({
    ...formData,
    categories: updatedCategories,
    category_uuid: updatedCategoryUuid,
  });
};

export const getCategoryOptions = (form: any) => {
  if (!form?.categories) return [];

  const { categories } = form;
  const parsedCategories: { label: string; value: string }[] = [];

  (categories as TCategory[]).forEach(({ name, uuid, subCategories }) => {
    if (subCategories && subCategories.length > 0) {
      parsedCategories.push({
        label: subCategories[0]?.name,
        value: subCategories[0]?.uuid,
      });
    } else {
      parsedCategories.push({ label: name, value: uuid });
    }
  });

  return parsedCategories;
};

export const updateSlug = (
  formData: any,
  setFormData: any,
  preSlugUrl: string,
  isArticle: boolean,
  categories?: TCategory[]
) => {
  const usedCategories = categories ?? formData?.categories;
  if (!usedCategories || usedCategories?.length === 0) return;

  let categorySlug =
    usedCategories[0]?.subCategories &&
    usedCategories[0]?.subCategories?.length > 0
      ? usedCategories[0]?.subCategories[0]?.slug
      : usedCategories[0]?.slug;

  categorySlug = categorySlug?.startsWith('/')
    ? categorySlug?.toLowerCase()
    : '/' + categorySlug?.toLowerCase();

  const slug = isArticle
    ? categorySlug +
      removeSpecialCharacters(
        formData?.slug ? formData?.slug.split('/').slice(-1)[0] : ''
      )
    : removeSpecialCharacters(
        formData?.slug ? formData?.slug.split('/').slice(-1)[0] : ''
      );
  const permalink = removeSpecialCharacters(
    formData?.slug ? formData?.slug.split('/').slice(-1)[0] : ''
  );
  const seoSlug = formData.seo_url
    ? formData.seo_url.split(preSlugUrl).slice(-1)[0]
    : '';

  setFormData({
    ...formData,
    slug,
    permalink,
    seo_url: isArticle
      ? preSlugUrl +
        categorySlug +
        (seoSlug?.startsWith('/') ? seoSlug : '/' + seoSlug)
      : preSlugUrl + (seoSlug?.startsWith('/') ? seoSlug : '/' + seoSlug),
  });
};
