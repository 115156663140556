import { getPostConfig } from 'components/Blog/helpers';
import { useEffect, useRef, useState } from 'react';
import { createRecipe, updateRecipe } from '../helpers';
import { generateTimestamp } from 'components/Blog/components/CreateContent/components/VersionPrompt/helpers';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import _ from 'lodash';
import { compareObjects } from 'utilities/helpers';
import { useNavigate } from 'react-router-dom';

export const getOptions = (form: any) => {
  if (!form) return [];

  const parsedOption: { label: string; value: string }[] = [];

  form.forEach((name: string) => {
    parsedOption.push({ label: name, value: name });
  });

  return parsedOption;
};

export const quantities = [
  'Gram (g)',
  'Kilogram (kg)',
  'Ounce (oz)',
  'Litre (l)',
];

export const serveOptions = ['', '1', '2', '3', '4', '5', '6-8', '8-10'];

export const validateRecipeInformation = (form: any, status: boolean) => {
  const fields = status
    ? [
        'title',
        'description',
        'slug',
        'excerpt',
        'serves',
        'featured_image',
        'categories',
        'tags',
        'seo_description',
        'seo_url',
        'seo_title',
        'seo_image',
      ]
    : ['title', 'description', 'slug', 'excerpt'];

  const invalidFields: string[] = [];

  fields.forEach((field) => {
    const fieldMapping: Record<string, string> = {
      excerpt: 'Short Description',
      description: 'Full Description',
      serves: 'Serving Size',
      featured_image: 'Featured Image',
      categories: 'Category',
      tags: 'Tag',
      seo_description: 'SEO description',
      seo_title: 'SEO title',
      seo_url: 'SEO url',
      seo_image: 'SEO image',
    };

    const mappedFieldName = fieldMapping[field] || field;

    if (field === 'categories') {
      status &&
        (!form?.categories || form?.categories?.length === 0) &&
        invalidFields.push(field);
    } else {
      if (
        [
          'seo_title',
          'seo_description',
          'seo_url',
          'seo_image',
          'title',
          'description',
          'slug',
          'excerpt',
          'serves',
          'featured_image',
        ].includes(field)
      ) {
        if (!form[field] || form[field].trim().length === 0) {
          invalidFields.push(mappedFieldName);
        }
      } else if (!form['excerpt'] || !form['tags']?.length) {
        invalidFields.push(mappedFieldName);
      } else if (field === 'excerpt' || field === 'description') {
        if (
          !form['excerpt'] ||
          !form['description'] ||
          form[field].length < 12
        ) {
          invalidFields.push(mappedFieldName);
        }
      } else {
        if (!form[field]) invalidFields.push(mappedFieldName);
      }
    }
  });

  return invalidFields.map(
    (field) => field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()
  );
};

export const getRecipeInformation = (form: any, siteUuid: string) => {
  const { categories } = form;
  const category_uuids: string[] = [];

  if (categories) {
    categories.forEach(
      ({
        uuid,
        subCategories,
      }: {
        name: string;
        uuid: string;
        subCategories?: { name: string; uuid: string }[];
      }) => {
        const category_uuid =
          subCategories && subCategories.length > 0
            ? subCategories[0].uuid
            : uuid;
        category_uuids.push(category_uuid);
      }
    );
  }

  const config = getPostConfig(form);
  let related_recipes_config = config.related_posts_config;
  let is_feature_recipe = config.is_feature_article;
  delete config.related_posts_config;
  delete config.is_feature_article;

  return {
    title: form?.title,
    description: form?.description,
    excerpt: form?.excerpt,
    slug: form?.slug,
    permalink: form?.permalink,
    ingredient_lists: JSON.stringify(form.ingredients_list),
    status: form?.status ?? false,
    category_uuids,
    category: category_uuids.length > 0 ? category_uuids[0] : null,
    method: form.method,
    cover_image: JSON.stringify(parseRecursive(form.featured_image)),
    measurement: form.measurement,
    serves: String(form.serves),
    cooking_time: formatCookingTime(form),
    cooking_time_extra: formatExtraCookingTime(form),
    author_uuid: form?.author_uuid,
    tags: form?.tags
      ? form.tags
          .map((item: any) => {
            if (typeof item == 'string') {
              return item;
            } else if (item && item?.name) {
              return item.name;
            }
            return '';
          })
          .filter(Boolean)
      : [],
    config: {
      ...config,
      related_recipes_config,
      is_feature_recipe,
    },
    site_uuid: siteUuid,
    published_at: form.published_at,
    video_link: form.video_link,
  };
};

export const formatCookingTime = (form: { [key: string]: any }) => {
  let result = '';

  if (form.serving_time) {
    result += form.serving_time ?? '0';
  }

  if (form.serving_time_unit) {
    result += ' // ' + form.serving_time_unit;
  }

  return result;
};

export const formatExtraCookingTime = (form: { [key: string]: any }) => {
  let result = '';

  if (form.serving_extra_time) {
    result += form.serving_extra_time ?? '0';
  }

  if (form.serving_extra_time_unit) {
    result += ' // ' + form.serving_extra_time_unit;
  }

  return result;
};

export const getCookingTimeDetails = (cooking_details: string) => {
  if (cooking_details) {
    const [time, measurement] = cooking_details.split('//');

    return {
      time: time ? time.trim() : '',
      measurement: measurement ? measurement.trim() : '',
    };
  }

  return {
    time: '',
    measurement: '',
  };
};

export const useRecipeAutosave = (
  initialData: any,
  other: { [key: string]: any },
  saveInterval = 10000
): [
  {
    [key: string]: any;
  },
  React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >
] => {
  const navigate = useNavigate();
  const localData = localStorage.getItem('recipeFormData');
  const storedData = localData ? JSON.parse(localData) : initialData;
  const { site_uuid } = other;

  const [formData, setFormData] = useState<{ [key: string]: any }>(storedData);

  const lastSavedDataRef = useRef(storedData);

  const handleSubmit = async (version: string, status: boolean) => {
    const variables = getRecipeInformation(formData, site_uuid);
    if (!formData?.created_at) {
      await createRecipe(variables)
        .then((res) => {
          setFormData((form) => ({
            ...form,
            recipe_uuid: res.createRecipe.uuid,
            created_at: res.createRecipe.created_at,
          }));
          navigate(`/cms/recipes/${res.createRecipe.uuid}`, {
            replace: false,
          });
        })
        .catch((error) => {});
    } else {
      await updateRecipe({ ...variables, uuid: formData.recipe_uuid })
        .then((res) => {})
        .catch((error) => {});
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        showRightSidebar(formData) &&
        !compareObjects(formData, lastSavedDataRef.current)
      ) {
        const invalidFields = validateRecipeInformation(
          formData,
          formData['status']
        );
        if (invalidFields.length == 0) {
          await handleSubmit(generateTimestamp(), formData['status']);
        }

        localStorage.setItem('recipeFormData', JSON.stringify(formData));
        lastSavedDataRef.current = formData;
      }
    }, saveInterval);

    return () => clearInterval(interval);
  }, [formData, saveInterval]);

  return [formData, setFormData];
};

export const getSeoData = (config: { [key: string]: any }) => {
  const properties = ['image', 'description', 'title', 'url'];

  return properties.reduce((seoData, property) => {
    let value =
      config?.seo_cards?.[`seo:${property}`] ??
      config.seo_cards?.[`seo_${property}`] ??
      config.open_graph?.[`og:${property}`];

    if (property === 'image') {
      value = JSON.stringify(parseRecursive(value));
    }

    return { ...seoData, [`seo_${property}`]: value };
  }, {});
};

export const getSocialData = (config: { [key: string]: any }) => {
  return {
    social_title: config.open_graph['og:title'],
    social_description: config.open_graph['og:description'],
    social_image: JSON.stringify(parseRecursive(config.open_graph['og:image'])),
  };
};

export const getTwitterData = (config: { [key: string]: any }) => {
  return {
    twitter_image: JSON.stringify(
      parseRecursive(config.twitter_cards?.['twitter:image'])
    ),
    twitter_description: config.twitter_cards?.['twitter:description'],
    twitter_title: config.twitter_cards?.['twitter:title'],
  };
};

export const showRightSidebar = (formData: { [key: string]: any }) => {
  if (window.location.pathname.includes('/new')) {
    return true;
  }
  return Boolean(formData?.recipe_uuid);
};
