import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -9999,
    opacity: 0,
    color: theme.design.colors.black,
    background: theme.design.colors.fullScreenBackground,
  },
  fullScreen_active: {
    zIndex: 9999,
    opacity: 1,
  },
}));

export default useStyles;
