import { gql } from '@apollo/client';

export const CORE_FORM_RESPONSE_FIELDS = gql`
  fragment CoreFormResponseFields on FormResponse {
    uuid
    response
    created_at
    updated_at
  }
`;

export const GET_RESPONSES_BY_FORM_UUID = gql`
  ${CORE_FORM_RESPONSE_FIELDS}
  query GetResponsesByFormUuid(
    $form_uuid: ID!
    $all: Boolean
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getResponsesByFormUuid(
      input: {
        form_uuid: $form_uuid
        all: $all
        first: $first
        page: $page
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        ...CoreFormResponseFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;
