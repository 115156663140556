import { gql } from '@apollo/client';

export const GETUSERS = gql`
  query getUser {
    users {
      data {
        id
        name
      }
    }
  }
`;

export const CREATESURVEY = gql`
  mutation createSurvey(
    $name: String!
    $url: String!
    $recipients: [ID!]
    $all: Boolean
  ) {
    createSurvey(name: $name, url: $url, recipients: $recipients, all: $all) {
      message
    }
  }
`;
