import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      name
      email
      phone
      department
      position
      avatar
      dob
      uuid
      role
      bio
      social_handle
      location
    }
  }
`;
