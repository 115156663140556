import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../../contexts/theme';

const usePreviewStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    marginBottom: theme.spacing(2.5),
  },
  container: {
    height: 94,
    borderRadius: 4,
    background: theme.design.colors.grey150,
    padding: theme.spacing(1, 1.5),
  },
  title: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.design.colors.grey100,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  link: {
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.blue200,
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    paddingBottom: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  description: {
    color: theme.design.colors.grey200,
    fontSize: theme.typography.fontSize,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
}));

export default usePreviewStyles;
