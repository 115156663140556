import { Icon } from '@iconify/react';
import Loading from '../common/Loading';
import assetBlue from '../../../../assets/svgs/campaign-blue.svg';
import assetGreen from '../../../../assets/svgs/campaign-green.svg';

interface Props {
  assetColor: 'blue' | 'green';
  stock: 'incline' | 'decline';
  total: number;
  setFilter: React.Dispatch<React.SetStateAction<string>>
}

export default function CampaignStatCard({ assetColor, stock, total, setFilter }: Props) {
  const icon = assetColor === 'blue' ? assetBlue : assetGreen;

  const color = {
    incline: '#027A48',
    decline: '#FF2915',
  }[stock];

  return (
    <div
      style={{ border: '2px solid #EAECF0' }}
      className="w-full shadow-md h-[10rem] p-5 rounded-[12px]"
    >
      <div className="flex h-full flex-col justify-between space-y-5">
        <div className="flex justify-between space-x-4 items-center">
          <div className="space-y-1">
            <img width={38} src={icon} height={38} alt="display icon" />

            <p className="text-[#101828]">
              Total Number of{' '}
              {assetColor === 'blue' ? 'Campaigns Created' : 'Posts'}
            </p>
          </div>

          {/* <div>
            <select
              style={{ border: '2px solid #DBDEE1' }}
              className="px-3 rounded-[6px] text-sm text-[#6C747A] outline-[#6caa7d] py-1"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value='all'>All Time</option>

              <option value='lastWeek'>Last Week</option>

              <option value='lastMonth'>Last Month</option>
            </select>
          </div> */}
        </div>

        <div className="flex items-center space-x-2">
          <p className="text-[#101828] text-3xl font-[550]">{total}</p>

          <div className="flex items-center space-x-1">
            {/* <Icon
              width={20}
              color={color}
              icon={
                stock === 'decline'
                  ? 'ion:arrow-down-outline'
                  : 'ion:arrow-up-outline'
              }
            /> */}

            {/* <p style={{ color }}>40%</p> */}
          </div>

          <p className="text-sm text-[#475467]">vs last month</p>
        </div>
      </div>

      {/* <div className='h-full flex items-center justify-center'>
        <Loading />
      </div> */}
    </div>
  );
}
