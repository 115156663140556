import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import useCpasStyles from '../../styles';
import { ParameterCard } from 'components/ParameterCard/ParameterCard';
import { CircularProgress } from '@mui/material';
import { DataParametersProps } from './types';
import useSelectChange, { useChannelInfo } from '../../hooks/hooks';
import FilterSelect from '../FilterSelect/FilterSelect';
import { cpasClient } from 'utilities/apolloClient';
import { AppStateContext } from 'contexts/provider';
import { PiExport } from 'react-icons/pi';
import { CSVLink } from 'react-csv';
import clx from 'classnames';

const DataParameter: FC<DataParametersProps> = ({ channelDetails }) => {
  const { all, brand, channel } = useContext(AppStateContext);
  const classes = useCpasStyles();
  const {
    value,
    setShowCustomRange,
    setSelectedStartDate,
    selectedStartDate,
    selectedEndDate,
    handleSelectChange,
    setSelectedEndDate,
    showCustomRange,
  } = useSelectChange();

  const { channelInfoData, loadingChannel } = useChannelInfo(
    channelDetails?.businessProfileId,
    selectedStartDate,
    selectedEndDate,
    cpasClient,
    all,
    brand
  );

  const renamedDataArray = useCallback(() => {
    let timeViewedSecs = channelInfoData?.getChannel?.metrics.timeViewedSecs;
    if (timeViewedSecs && !isNaN(parseFloat(timeViewedSecs))) {
      timeViewedSecs = Number(timeViewedSecs).toLocaleString();
    }

    let totalUniqueSubscribers =
      channelInfoData?.getChannel?.metrics.mauSubscribers;
    if (totalUniqueSubscribers && !isNaN(parseFloat(totalUniqueSubscribers))) {
      totalUniqueSubscribers = Number(totalUniqueSubscribers).toLocaleString();
    }

    let DauSubscribers = channelInfoData?.getChannel?.metrics.dauSubscribers;
    if (DauSubscribers && !isNaN(parseFloat(DauSubscribers))) {
      DauSubscribers = Number(DauSubscribers).toLocaleString();
    }

    let totalUniqueViewers = channelInfoData?.getChannel?.metrics.mau;
    if (totalUniqueViewers && !isNaN(parseFloat(totalUniqueViewers))) {
      totalUniqueViewers = Number(totalUniqueViewers).toLocaleString();
    }

    let paidImpressions = channelInfoData?.getChannel?.metrics.sold_impressions;
    if (paidImpressions && !isNaN(parseFloat(paidImpressions))) {
      paidImpressions = Number(paidImpressions).toLocaleString();
    }

    let totalGrossRevenue = channelInfoData?.getChannel?.metrics.revenue;
    if (totalGrossRevenue && !isNaN(parseFloat(totalGrossRevenue))) {
      totalGrossRevenue = Number(totalGrossRevenue).toLocaleString();
    }

    let ecpm = channelInfoData?.getChannel?.metrics.ecpm;
    if (ecpm && !isNaN(parseFloat(ecpm))) {
      ecpm = Number(ecpm).toLocaleString();
    }
    return [
      {
        metrics: 'Average Watch Time',
        value: `${timeViewedSecs}(secs)` ?? '-',
      },
      {
        metrics: 'Gross Revenue',
        value: totalGrossRevenue ? `$${totalGrossRevenue}` : '-',
      },
      {
        metrics: 'ecpm',
        value: ecpm ? `$${ecpm}` : '-',
      },
      {
        metrics: 'Total Unique Subscribers',
        value: totalUniqueSubscribers ?? '-',
      },
      {
        metrics: 'Paid Impressions',
        value: paidImpressions ?? '-',
      },
      {
        metrics: 'Total Unique Viewers',
        value: totalUniqueViewers ?? '-',
      },
    ];
  }, [channelDetails, channelInfoData]);

  const displayParameterCard = () =>
    renamedDataArray().map((revenue, index) => (
      <div key={index} className={classes.revenueCard}>
        <ParameterCard
          index={index}
          title={revenue?.metrics ?? ''}
          value={String(revenue?.value) ?? ''}
        />
      </div>
    ));

  return (
    <div className={classes.dataParameterContainer}>
      <div className={classes.subHeadingParent}>
        <p className={classes.subHeading}>Data Parameters</p>
        <div className={classes.exportFilter}>
          {channel !== 'All Channels' && (
            <CSVLink
              className={clx(classes.exportBtn, !loadingChannel && 'loading')}
              data={renamedDataArray()}
              filename={`${channel}-Metrics-${selectedStartDate}-${selectedEndDate}.csv`}
            >
              <span>
                <PiExport />
              </span>
              {!loadingChannel ? (
                <span>Export</span>
              ) : (
                <CircularProgress size="20px" />
              )}
            </CSVLink>
          )}
          <FilterSelect
            value={value}
            showCustomRange={showCustomRange}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleSelectChange={handleSelectChange}
            setShowCustomRange={setShowCustomRange}
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
          />
        </div>
      </div>
      <div>
        {loadingChannel ? (
          <div className={classes.loader_container}>
            <CircularProgress
              className={classes.loader}
              size="24px"
              color="inherit"
            />
          </div>
        ) : (
          <div className={classes.parameterParent}>
            {displayParameterCard()}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataParameter;
