import React from 'react';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import { FiMinusCircle } from 'react-icons/fi';
import { FcCheckmark } from 'react-icons/fc';
import { ClassNameMap } from '@mui/material';

export const schema = (
  classes: ClassNameMap<string>,
  publish: (slug: string) => void
): SchemaProps[] => {
  return [
    {
      label: 'Id',
      func: ({ item }) => get(item, 'id'),
    },
    {
      label: 'Title',
      func: ({ item }) => get(item, 'title'),
    },
    {
      label: 'Published',
      func: ({ item }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center' }}>
              {get(item, 'apple_news_status') ? (
                <FcCheckmark style={{ fontSize: '18px' }} />
              ) : (
                <FiMinusCircle color="grey" style={{ fontSize: '18px' }} />
              )}
            </div>
          </div>
        );
      },
    },
    {
      label: 'Actions',
      func: ({ item }) => {
        return (
          <p
            className={classes.action}
            onClick={() => {
              publish(get(item, 'url'));
            }}
          >
            Publish
          </p>
        );
      },
    },
  ];
};
