import { FC, useEffect, useState } from 'react';
import { FAQProps } from './type';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import { EmbedVideo } from 'components/Blog/components/CreateContent/components/EmbedVideo';
import { EmbedLink } from 'components/Blog/components/CreateContent/components/EmbedLink';
import {
  getEncodedConfig,
  getVideoUrl,
} from 'components/Blog/components/CreateContent/helpers';
import { WysiwygInput } from '@wazobia-tech/wazcom/dist/io';

export const FAQ: FC<FAQProps> = ({ faq: initialFormData, setFAQ }) => {
  const [embedAction, setEmbedAction] = useState<string>('');
  const [embed, setEmbed] = useState<{ type: string; value: any }>();
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    ...initialFormData,
  });
  const initiateEmbed = (embedAction: string) => {
    setEmbedAction(embedAction);
  };
  const handleImageEmbed = (name: string, value: any) => {
    value['src'] =
      value['src'] +
      (value['src']?.includes('?') ? '&' : '?') +
      `config=${getEncodedConfig(value)}`;

    name === 'image'
      ? setEmbed({ type: 'image', value })
      : setFormData({ ...formData, [name]: JSON.stringify(value) });

    handleClose();
  };

  const handleVideoEmbed = (videoUrl: string) => {
    setEmbed({ type: 'video', value: getVideoUrl(videoUrl) });
    handleClose();
  };

  const handleChange = (data: { name: string; value: string }) => {
    setFormData({
      ...formData,
      [data?.name]: data?.value,
    });
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleLinkEmbed = (link: string) => {
    setEmbed({ type: 'link', value: link });
    handleClose();
  };

  useEffect(() => {
    if (!formData) return;
    setFAQ((prev) => ({ ...prev, subtopics: [formData] }));
  }, [formData]);

  return (
    <section className={'guideEditor'}>
      <WysiwygInput
        value={{ title: formData?.title, content: formData?.content }}
        onChange={handleChange}
        embed={embed}
        initiateEmbed={initiateEmbed}
      />
      <EmbedImage
        open={embedAction === 'image'}
        handleClose={handleClose}
        handleEmbed={handleImageEmbed}
        name={embedAction}
        formData={formData ?? {}}
        setFormData={setFormData}
      />
      <EmbedVideo
        open={embedAction === 'video'}
        handleClose={handleClose}
        handleEmbed={handleVideoEmbed}
      />
      <EmbedLink
        open={embedAction === 'link'}
        handleClose={handleClose}
        handleEmbed={handleLinkEmbed}
        handleVideoEmbed={handleVideoEmbed}
      />
    </section>
  );
};
