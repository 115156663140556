import { formatDate } from '../helper';
import Channel from '../posts/Channel';

interface Props {
  comment: {
    author: string;
    text: string;
    createdAt: Date;
    id: string;
  };
}

export default function CampaignInsightCard({ comment }: Props) {
  const { formattedDate, formattedTime } = formatDate(comment.createdAt);

  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-5 p-4 gap-4"
    >
      <div className="max-w-full h-full flex items-center">
        <p className="text-[#101828] truncate !font-light">{comment?.author ?? 'unknown'}</p>
      </div>

      <div className="max-w-full h-full flex items-center">
        <p className="text-[#101828] truncate !font-light">
          {comment.text}
        </p>
      </div>

      <div className="max-w-full h-full flex items-center">
        <div className="max-w-full">
          <Channel postChannel="instagram" />
        </div>
      </div>

      <div className="max-w-full h-full flex items-center">
        <div className="max-w-full">
          <p className="truncate text-[#475467]">www.hapouhsnoy.com</p>
        </div>
      </div>

      <div className="max-w-full h-full flex items-center">
        <div className="space-y-2">
          <p className="text-[#344054] truncate !font-light">{formattedDate}</p>

          <p className="text-[#A9A9A9] text-sm truncate !font-light">{formattedTime}</p>
        </div>
      </div>
    </div>
  );
}
