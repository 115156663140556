// Helper method to transform the JSON config returned from the

import { FournineSectionProps } from './types';

// API into the FournineSectionProps[] type
export const parseSectionsIntoProps = (sections: { [key: string]: any }) => {
  const keys = Object.keys(sections);
  const values = Object.values(sections);
  const parsedSections: any[] = [];
  keys.forEach((key, index) => {
    parsedSections.push({ name: key, section: values[index] });
  });
  return parsedSections;
};

// Helper method to transform the state props from the component
// into JSON that will be returned to the API
export const parseSectionsIntoJson = (sections: FournineSectionProps[]) => {
  const content: { [key: string]: any } = {};
  sections.forEach(({ name, section: { max, items } }) => {
    content[name] = { max, items };
  });
  return JSON.stringify(content);
};
