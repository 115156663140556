import {
  TableColumn,
  TableConditionFilter,
  Tag,
} from '@wazobia-tech/wazcom/dist/core';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';
import useBlogStyles from '../Blog/styles';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { notifyStatus } from 'simple-notify/dist/types';
import {
  CorePostFields,
  GqlCreateBlogPageVariables,
  GqlCreatePostVariables,
  GqlUpdateBlogPageVariables,
  GqlUpdatePostVariables,
} from '../Blog/components/CreateContent/type';
import { blogClient, recipeClient } from 'utilities/apolloClient';
import {
  CREATE_POST,
  CREATE_RECIPE,
  UPDATE_POST,
  UPDATE_RECIPE,
} from '../Blog/graphQL';
import useStyles from '../Blog/styles';
import { AiOutlineEye, AiOutlineLineChart } from 'react-icons/ai';
import { HiOutlineChartBar } from 'react-icons/hi';
import { BsBook } from 'react-icons/bs';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import { useContext } from 'react';
import { AppStateContext } from 'contexts/provider';
import { useGetPreSlugURL } from 'components/Blog/helpers';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  const classes = useStyles();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            navigate(`/cms/recipes/${row.uuid}`, { state: { row } });
          },
        },
        {
          name: 'Delete',
          func: (row: { uuid: string }) => {
            setPostId(row?.uuid);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Author', key: 'author.name', type: 'string' },
    { name: 'Categories', key: 'categories', type: 'string' },
    { name: 'Tags', key: 'tags', type: 'string' },
    { name: 'Last Modified', key: 'updated_at', type: 'date' },
  ];
};

export const getRecipeURL = (current_site_url: string) => {
  const portal_host = window.location.host;
  if (portal_host == 'portal.junglecreations.com') {
    return current_site_url.includes('twistedfood')
      ? current_site_url + '/recipes'
      : current_site_url;
  } else {
    let convertedUrl = current_site_url
      ? current_site_url.replace('https://', 'https://dev.')
      : '';
    return current_site_url.includes('twistedfood')
      ? convertedUrl + '/recipes'
      : convertedUrl;
  }
};

export const useGetTableRows = () => {
  const classes = useBlogStyles();
  const getPreSlugURL = useGetPreSlugURL();

  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = (
        <a
          href={getPreSlugURL() + row?.slug}
          target="_blank"
          className={classes.blogTitleSlug}
        >
          {decodeHtml(row['title'])}{' '}
          {row?.status ? <FaCheckCircle className={classes.postCheck} /> : ''}
        </a>
      );
      parsedRow['author.name'] = row?.author?.name as string;
      parsedRow['categories'] =
        row?.categories && row?.categories?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.categories
              ?.slice(0, 3)
              ?.map(({ name }: any, index: number) => (
                <Tag name={name as string} key={index} />
              ))}
            {row?.categories?.length > 3 && (
              <span className={classes.postTagDifference}>
                {row?.categories?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      parsedRow['tags'] =
        row?.tags?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.tags?.slice(0, 3)?.map(({ name }: any, index: number) => (
              <Tag name={name} key={index} />
            ))}
            {row?.tags?.length > 3 && (
              <span className={classes.postTagDifference}>
                +{row?.tags?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] =
        updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Recipes', condition: (posts: any) => posts },
  {
    name: 'Published',
    condition: (posts: any) => posts.filter((post: any) => post.status),
    filter: [
      {
        column: 'status',
        operator: '$eq',
        value: '1',
      },
    ],
  },
  {
    name: 'Drafted',
    condition: (posts: any) => posts.filter((post: any) => !post.status),
    filter: [
      {
        column: 'status',
        operator: '$eq',
        value: '0',
      },
    ],
  },
];

export const validatePostInformation = (form: any) => {
  const fields = ['title', 'content', 'slug', 'categories'];
  const invalidFields: string[] = [];

  fields.forEach((field) => {
    if (field === 'categories') {
      (!form?.categories || form?.categories?.length === 0) &&
        invalidFields.push(field);
    } else if (!form[field]) invalidFields.push(field);
  });

  return invalidFields.map(
    (field) => field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()
  );
};

export const notify = (status: notifyStatus, title: string, text?: string) => {
  return new Notify({
    status: status,
    title: title,
    text: text,
    effect: 'slide',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 3500,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'top right',
  });
};

export const useGetPostInformation = () => {
  return (form: any, blogUuid: string) => {
    const { categories } = form;
    const category_uuids: string[] = [];

    categories.forEach(
      ({
        uuid,
        subCategories,
      }: {
        name: string;
        uuid: string;
        subCategories?: { name: string; uuid: string }[];
      }) => {
        const category_uuid =
          subCategories && subCategories.length > 0
            ? subCategories[0].uuid
            : uuid;
        category_uuids.push(category_uuid);
      }
    );

    const config = getPostConfig(form);
    const metadata: any = { ...config };
    delete metadata?.related_posts_config;

    return {
      title: form?.title,
      content: form?.content,
      status: form?.status,
      category_uuids,
      author_uuid: form?.author_uuid,
      tags: form?.tags,
      featured_image: form.featured_image,
      excerpt: form?.excerpt,
      route: form?.slug,
      visibilty: Boolean(form?.visibilty),
      blog_uuid: blogUuid as string,
      config,
      metadata: JSON.stringify(metadata),
    };
  };
};

const getPostConfig = (form: any) => {
  const social_preview_config: { [key: string]: string } = {};
  const socialFields = [
    'seo_title',
    'seo_description',
    'og:image',
    'og:title',
    'og:description',
    'twitter:image',
    'twitter:title',
    'twitter:description',
  ];

  socialFields.forEach((field) => {
    if (form[field] && field.startsWith('seo_')) {
      social_preview_config[field.split('seo_')[1]] = form[field];
      return;
    }

    if (form[field]) social_preview_config[field] = form[field];
  });

  const schema: { [key: string]: string } = {};
  const schemaKeys = Object.keys(form).filter((key: string) =>
    key.startsWith('schema_')
  );

  schemaKeys.forEach((key) => {
    if (form[key]) {
      const newKey = key.split('schema_')[1];
      schema[newKey] = form[key];
    }
  });

  return {
    is_comments_enabled: form?.is_comments_enabled,
    is_in_sitemap: form?.is_in_sitemap,
    is_feature_article: form?.is_feature_article,
    related_posts_config: form?.auto_related_posts
      ? null
      : form?.related_posts_config && form?.related_posts_config?.length > 0
      ? typeof form?.related_posts_config[0] === 'string'
        ? undefined
        : JSON.stringify(
            form?.related_posts_config?.map(
              ({ uuid }: { uuid: string }) => uuid
            )
          )
      : null,
    social_preview_config: JSON.stringify(social_preview_config),
    schema: JSON.stringify(schema),
  };
};

export const createBlogPage = async (
  blogUuid: string,
  variables: GqlCreateBlogPageVariables
) => {
  const blog_uuid = blogUuid;
  if (blog_uuid) {
    const postInput = (({
      route,
      title,
      status,
      category_uuids,
      content,
      tags,
      description,
      featured_image,
      author_uuid,
      published_at,
      config,
      excerpt,
      version,
      visibility,
    }) => ({
      slug: route,
      title,
      status,
      category_uuids,
      content,
      description,
      blog_uuid,
      tags,
      featured_image,
      author_uuid,
      published_at,
      config,
      excerpt,
      version,
      visibility,
    }))(variables);

    const { data }: { data?: { createPost: CorePostFields } } =
      await gqlCreatePost({
        ...postInput,
      });

    if (data) {
      return 'success';
    }
  }
};

export const updateBlogPage = async (variables: GqlUpdateBlogPageVariables) => {
  const postInput = (({
    uuid,
    route,
    title,
    status,
    category_uuids,
    content,
    tags,
    description,
    featured_image,
    published_at,
    config,
    excerpt,
    version,
    visibility,
  }) => ({
    uuid,
    slug: route,
    title,
    status,
    category_uuids,
    content,
    description,
    tags,
    featured_image,
    published_at,
    config,
    excerpt,
    version,
    visibility,
  }))(variables);

  const { data }: { data?: { updatePost: CorePostFields } } =
    await gqlUpdatePost({
      ...postInput,
    });

  if (data) {
    return 'success';
  }
};

export const gqlCreatePost = async (variables: GqlCreatePostVariables) =>
  await blogClient.mutate({
    mutation: CREATE_POST,
    variables,
  });

export const gqlUpdatePost = async (variables: GqlUpdatePostVariables) =>
  await blogClient.mutate({
    mutation: UPDATE_POST,
    variables,
  });

export const createRecipe = async (variables: { [key: string]: any }) => {
  const result = await recipeClient.mutate({
    mutation: CREATE_RECIPE,
    variables,
  });
  return result.data;
};

export const updateRecipe = async (variables: { [key: string]: any }) => {
  const result = await recipeClient.mutate({
    mutation: UPDATE_RECIPE,
    variables,
  });
  return result.data;
};
