import { gql } from '@apollo/client';

export const GET_REDIRECTS = gql`
  query getRedirects($first: Int, $page: Int) {
    redirects(first: $first, page: $page) {
      data {
        id
        site
        target
        source
        type
        site
        user {
          name
        }
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETE_REDIRECT = gql`
  mutation deleteRedirect($id: ID!) {
    deleteRedirect(id: $id) {
      id
    }
  }
`;
