/* eslint-disable object-shorthand */
// import ReactECharts from 'echarts-for-react';

interface Props {
  sentiment: {
    positive: number;
    neutral: number;
    negative: number;
  };
  totalComments: number;
}

export function SentimentAnalysis({ sentiment, totalComments }: Props) {
  const options = {
    tooltip: {
      trigger: 'item',
      formatter: function (params: {
        color: string;
        name: string;
        percent: string;
        value: string;
      }) {
        return `
          <p style="color: ${params.color}; font-size: 14px; font-weight: 400;">${params.name}</p>
          <p style="color: #757575; font-size: 12px;">Percentage: ${params.percent}%</p>
          <p style="color: #757575; font-size: 12px;">Posts: ${params.value}</span></p>
        `;
      },
    },
    legend: {
      bottom: '10%',
      left: 'center',
      itemHeight: 10,
      itemWidth: 10,
      itemGap: 30,
      icon: 'circle',
      formatter: (name: string) => {
        switch (name) {
          case 'Good':
            return `Good - ${sentiment?.positive}%`;
          case 'Neutral':
            return `Neutral - ${sentiment?.neutral}%`;
          case 'Bad':
            return `Bad - ${sentiment?.negative}%`;
          default:
            return name;
        }
      },
    },
    color: ['#6FD195', '#FFAE4C', '#FF928A'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '65%'],
        center: ['50%', '40%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: sentiment?.positive, name: 'Good' },
          { value: sentiment?.neutral, name: 'Neutral' },
          { value: sentiment?.negative, name: 'Bad' },
        ],
      },
    ],
  };

  const sentiments = {
    neutral: sentiment?.neutral || 0,
    positive: sentiment?.positive || 0,
    negative: sentiment?.negative || 0,
  };

  const highestSentiment = (Object.keys(sentiments) as Array<keyof typeof sentiments>).reduce(
    (a, b) => (sentiments[a] > sentiments[b] ? a : b)
  );

  return (
    <div
      style={{ border: '2px solid #EAECF0' }}
      className="max-h-full flex flex-col justify-between p-5 shadow-md rounded-[12px]"
    >
      <div>
        <p className="text-[#101828] font-[550] text-lg">Sentiment Analysis</p>

        <div className="w-full max-h-full h-[20rem] flex items-center">
          {/* @ts-ignore */}
          {/* <ReactECharts
            option={{
              ...options,
              grid: {
                left: '0%',
                right: '0%',
                top: '5px',
                bottom: '0%',
                containLabel: true, // Ensure labels are contained within the grid
              },
            }}
            style={{ height: '100%', width: '100%' }}
          /> */}
        </div>
      </div>

      <div
        style={{ border: '2px solid #EAECF0' }}
        className="w-full rounded-[12px]"
      >
        <div className="text-[#5E646D] font-semibold border-b p-4 bg-[#F2F2F280]">
          Report
        </div>

        <div className="p-4 space-y-1">
          <p className="text-[#070707] font-[400] !mb-2">
            Majority of the comments in this campaign are {highestSentiment}
          </p>

          <p className="text-[#5B5B5B]">{Math.round(sentiment?.neutral / 100 * totalComments)} with neutral sentiments</p>

          <p className="text-[#5B5B5B]">{Math.round(sentiment?.positive / 100 * totalComments)} with positive sentiments</p>

          <p className="text-[#5B5B5B]">{Math.round(sentiment?.negative / 100 * totalComments)} with bad sentiments</p>
        </div>
      </div>
    </div>
  );
}
