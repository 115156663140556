import { FormControl, InputLabel, TextareaAutosize } from '@mui/material';

import React from 'react';
import { useTextAreaStyles } from './styles';
import { TextareaProps } from './types';

const TextArea: React.FC<TextareaProps> = ({
  label,
  name,
  value,
  onChange,
  disabled,
}) => {
  const classes = useTextAreaStyles();
  return (
    <FormControl className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <TextareaAutosize
        minRows={6}
        maxRows={6}
        name={name}
        placeholder="Minimum 3 rows"
        style={{ width: '100%' }}
        value={value}
        className={classes.textArea}
        onChange={onChange}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default TextArea;
