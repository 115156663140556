import deleteIcon from '../common/assets/delete.svg';
import { Button } from 'components/common/Button/Button';
import { useModal } from '../common/ModalManager';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DELETE_CAMPAIGN } from 'graphql/jcmat/deleteCampaign';
import { useCommentAnalysis } from 'contexts/ca';

const DeleteCampaign = ({ campaignUuid }: { campaignUuid: string }) => {
  const { hideModal } = useModal();
  const { fetchCampaigns } = useCommentAnalysis();
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);

  const handleDeleteCampaign = async () => {
    await deleteCampaign({
      variables: {
        uuid: campaignUuid,
      },
    })
      .then(() => {
        hideModal();
        fetchCampaigns();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center my-4">
      <img src={deleteIcon} className="mt-4" />
      <h1 className="text-xl font-medium my-4">Delete Campaign</h1>
      <p className="text-lg font-light text-center mb-12 text-[#979797] w-[70%]">
        Deleting this campaign will permanently remove all the posts associated
        to it.Are you sure you want to delete as this cannot be undone?
      </p>
      <div className="flex items-center justify-between w-full">
        <Button
          onClick={hideModal}
          className="!rounded-full !bg-transparent !border-2 !border-black !text-black  !w-[48%] !h-[56px]"
        >
          No, Don’t Delete
        </Button>
        <Button
          onClick={handleDeleteCampaign}
          className="!rounded-full !w-[48%] !h-[56px]"
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteCampaign;
