import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: theme.spacing(0, 6, 6, 6),
    background: theme.design.colors.white,
    '& p': {
      lineHeight: '25px',
      textAlign: 'justify',
    },

    '& li': {
      marginBottom: '20px',
      lineHeight: '25px',
      textAlign: 'justify',
    },
  },
  content__loading: {
    height: 'calc(100vh - 200px)',
    overflow: 'hidden',
  },
  content__title: {
    fontSize: '24px',
    lineHeight: '54px',
    margin: '50px 0 35px 0',
    fontFamily: theme.design.font.universal,
  },
  content__info: {
    display: 'flex',
    marginBottom: '1.875rem',
    fontSize: '1.125rem',
  },
  content__details: {
    lineHeight: '1.05rem',

    '& iframe': {
      width: '100%',
    },
  },
  content__loader: {
    height: 'calc(120vh - 100px)',
    width: '100%',
    position: 'absolute',
    top: '25px',
    backgroundColor: theme.design.colors.widgetGrey,
    opacity: 0,
    zIndex: -9999,
    transition: 'opacity 0.3s ease-in',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  show__contentLoader: {
    opacity: 100,
    zIndex: 10,
  },
  tabs: {
    display: 'flex',
    gap: theme.spacing(2.5),
    margin: theme.spacing(3, 0),
  },
  link: {
    textDecoration: 'none',
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.grey800,
    paddingBottom: theme.spacing(1),
  },
  linkActive: {
    color: theme.design.colors.grey100,
    borderBottom: `5px solid ${theme.design.colors.black}`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  neutral: {
    width: '80%',
  },
  landingTabs: {
    borderBottom: `1px solid ${theme.design.colors.lidGrey}`,
    paddingLeft: '9%',
  },
}));

export default useStyles;
