import { FC, useCallback } from 'react';
import { useMeasurementStyles } from './styles';
import { useStylesRecipe } from '../../../CreateRecipe/styles';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import DraggableCard from 'components/common/DraggableCard';
import update from 'immutability-helper';
import { MeasurementSubList } from '../MeasurementSubList';

export const CreateMeasurement: FC<any> = ({
  index: listindex,
  form,
  setFormData,
}) => {
  const classes = { ...useMeasurementStyles(), ...useStylesRecipe() };

  const handleInputIncrement = () => {
    const new_ingredients_list = [...form.ingredients_list];
    new_ingredients_list[listindex].list = [
      ...new_ingredients_list[listindex].list,
      {
        amount: '',
        ingredient: '',
      },
    ];
    setFormData({
      ...form,
      ingredients_list: new_ingredients_list,
    });
  };

  const handleChange = (e: any, listindex: number, number: number) => {
    setFormData((form: any) => {
      const newIngredientsList = form.ingredients_list.map(
        (
          list: {
            list_heading: String;
            list: [{ ingredient: String; amount: String }];
          },
          i: Number
        ) => {
          if (i === listindex) {
            const newList = list.list.map((item, j) => {
              if (j === number) {
                return {
                  ...item,
                  [e.name]: e.value,
                };
              }
              return item;
            });

            return {
              ...list,
              list: newList,
            };
          }
          return list;
        }
      );

      return {
        ...form,
        ingredients_list: newIngredientsList,
      };
    });
  };

  const handleSearchChange = ({
    value: search,
  }: {
    name: string;
    value: string;
  }) => {
    setFormData((form: any) => {
      const new_ing_list = form.ingredients_list;
      new_ing_list[listindex].list_heading = search;

      return {
        ...form,
        ingredients_list: new_ing_list,
      };
    });
  };

  const handleInputDecrement = (index: number) => {
    const new_ingredients_list = [...form.ingredients_list];
    new_ingredients_list[listindex].list.splice(index, 1);

    setFormData({
      ...form,
      ingredients_list: new_ingredients_list,
    });
  };

  const handleRemoveList = () => {
    setFormData((oldFormData: { [key: string]: any }) => {
      oldFormData.ingredients_list.splice(listindex, 1);
      return {
        ...oldFormData,
      };
    });
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const new_ingredients_list = update(form.ingredients_list, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, form.ingredients_list[dragIndex]],
        ],
      });

      setFormData({
        ...form,
        ingredients_list: new_ingredients_list,
      });
    },
    [form]
  );

  return (
    <DraggableCard id="parent1" index={listindex} level={0} moveCard={moveCard}>
      <section className={classes.root}>
        <aside
          className={classes.aside}
          onMouseEnter={(e) => {
            e.currentTarget.style.cursor = 'move';
          }}
        >
          <p>{listindex + 1}</p>
        </aside>
        <main className={classes.listHeading}>
          <div className={classes.listHeadingRow}>
            <TextInput
              label="LIST HEADING"
              name="list_heading"
              placeholder="E.g Sauce Ingredients"
              classNames={{ input: classes.embedTextInput }}
              value={form.ingredients_list[listindex].list_heading}
              onChange={handleSearchChange}
            />
            {listindex !== 0 && (
              <div className={classes.removeList} onClick={handleRemoveList}>
                <AiOutlineMinus />
              </div>
            )}
          </div>
          <article className={classes.tableContainer}>
            <label className={classes.label}>Details</label>
            <table className={classes.ingredientTable}>
              <tbody>
                <tr className={classes.displayGrid}>
                  <th></th>
                  <th>
                    <p className={classes.tableHeaderText}>AMOUNT</p>
                  </th>
                  <th>
                    <p className={classes.tableHeaderText}>INGREDIENT</p>
                  </th>
                  <th className={classes.incrementIcon}>
                    <AiOutlinePlus onClick={handleInputIncrement} />
                  </th>
                </tr>
                <MeasurementSubList
                  form={form}
                  listindex={listindex}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handleInputDecrement={handleInputDecrement}
                />
              </tbody>
            </table>
          </article>
        </main>
      </section>
    </DraggableCard>
  );
};
