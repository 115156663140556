import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useParameterCardStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    borderRadius: '4px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
  },
  title: {
    padding: theme.spacing(2),
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.black,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  valueParent: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.design.colors.white,
  },
  value: {
    fontSize: theme.typography.fontSize * 2,
    fontWeight: theme.typography.fontWeightBold,
  },
  impression: {
    fontSize: theme.typography.fontSize - 2,
  },
  revenueCard: {
    background: theme.design.colors.mintGreen,
  },
  impressionCard: {
    background: theme.design.colors.cyan,
  },
  uniqueViewCard: {
    background: theme.design.colors.yellow,
  },
  ecpmCard: {
    background: theme.design.colors.skyBlue,
  },
  totalViewsCard: {
    background: theme.design.colors.pink,
  },
  subscriberCard: {
    background: theme.design.colors.black,
    color: theme.design.colors.white,
  },
  impressionContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
}));

export default useParameterCardStyles;
