import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export interface StyleProps {
  background?: string;
  padding?: string;
}

const useStyles = makeStyles((theme: BaseTheme) => ({
  poll: {
    display: 'flex',
    flexDirection: 'column',
    background: ({ background }: StyleProps) =>
      background ?? theme.design.colors.white,
    padding: ({ padding }: StyleProps) => padding ?? '48px 53px',
    maxHeight: '406px',
    width: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '10px',
    flexShrink: 0,

    '&::-webkit-scrollbar': {
      width: ({ background }) => (background ? '1px' : '3px'),
      height: 0,
      background: 'rgba(0,0,0,0.05)',
    },

    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0,0,0,0.25)',
    },

    '&::-webkit-scrollbar-track': {
      background: 'rgba(0,0,0,0.01)',
    },
  },
  poll__title: {
    fontWeight: 'bold',
    color: '#333',
    fontSize: ({ background }) => (background ? '0.9rem' : '1.125rem'),
    margin: ({ background }) => (background ? '0 0 12px 0' : '0 0 2.5rem 0'),
  },
  poll__options: {
    display: 'flex',
    flexDirection: 'column',
    margin: ({ background }) => (background ? '0' : '0 0 2.938rem 0'),
  },
  poll__option: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ({ background }) => (background ? '7px' : '10px'),
    margin: '0 0 14px 0',
    borderRadius: '10px',
    border: '1px solid #BDBDBD',
    fontSize: ({ background }) => (background ? '0.8em' : '1rem'),
    lineHeight: '1.172rem',
    color: '#666666',
    cursor: 'pointer',

    '& .poll-option-loader': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: -9999,
      opacity: 0,
      borderRadius: '10px',
      background: ({ background }) => background ?? theme.design.colors.white,
      transition: 'width 1s ease-in',
    },

    '& .poll-option-loader-active': {
      zIndex: 20,
      opacity: 1,
    },

    '& p': {
      zIndex: 10,
    },
  },
  poll__option_vote: {
    position: 'absolute',
    top: '0px',
    left: 0,
    height: '100%',
    background: '#E2E2E2',
    borderRadius: '10px',
    transition: 'width 1s ease-in',
  },
  poll__option_percent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '0 10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 1s ease-in',

    '& p': {
      color: '#333333',
      fontWeight: 'bold',
      fontSize: '11px',
    },

    '& img': {
      position: 'absolute',
      right: '27px',
    },
  },
  poll__author: {
    fontSize: '0.75rem',
    color: '#8C8C8C',
    margin: ({ background }) => (background ? '0 0 12px 0' : '0'),
  },
}));

export default useStyles;
