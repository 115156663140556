import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  dashboard: {
    background: theme.design.colors.jpGrey400,
    minHeight: '100vh',
    width: '100vw',
  },
  dashboard__content: {
    position: 'relative',
    height: 'fit-content',

    '@media(max-height:800px)': {
      paddingBottom: '20px',
    },
  },
  dashboard__content_fill: {
    width: '100%',
    maxWidth: '100vw',
  },
  dashboard__breadcrumb: {
    margin: '2.5rem 0 3.375rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  dashboard__breadcrumb_separator: {
    margin: '0 10px',
  },
  dashboard__link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export default useStyles;
