import { useState, FC } from 'react';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import { blogClient, recipeClient } from 'utilities/apolloClient';
import { Modal, Box } from '@mui/material';
import CreateCategory from './components/CreateCategory/CreateCategory';
import useCategoryStyles from './styles';
import { useMutation, ApolloConsumer } from '@apollo/client';
import { notifySuccess } from 'utilities/notify';
import { useNavigate } from 'react-router-dom';
import { CategoryProps } from './types';
import { Button } from 'components/common/Button/Button';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';

const Category: FC<CategoryProps> = ({
  name,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  site_uuid,
  path,
  datakey,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
}) => {
  const classes = useCategoryStyles();
  const [deleteCategory, { loading }] = useMutation(DELETE_CATEGORY, {
    client: recipeClient,
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [category, setCategory] = useState<{ [key: string]: string } | null>();
  const navigate = useNavigate();
  const tableColumns = useGetTableColumns(
    setCategory,
    setOpenDeleteModal,
    setOpenCreateModal
  );
  const [refreshToken, setRefreshToken] = useState(false);

  const getTableRows = useGetTableRows();

  const handleClose = () => {
    setOpenCreateModal(false);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deletePostCategory = async () => {
    const variables = { uuid: category?.uuid };
    try {
      await deleteCategory({ variables });
      notifySuccess('Category Deleted Successfully');
      setRefreshToken((prevRefresh) => !prevRefresh);
      navigate(path);
      setOpenDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.titleWidget}>
            <p className={classes.title}>
              {name === 'category' ? 'Categories' : 'Tags'}
            </p>
            <Button
              title={name === 'category' ? 'Create Category' : 'Create Tags'}
              onClick={() => {
                setCategory(null);
                setOpenCreateModal(true);
              }}
              className={classes.createBtn}
            />
          </div>
          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: GET_CATEGORIES,
                client: recipeClient,
                variables: { site_uuid, refreshToken },
                dataKey: datakey,
              }}
              conditionFilters={tableConditionFilters}
              showFilters={false}
            />
          </div>
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleCloseDeleteModal}
            deletePostCategory={deletePostCategory}
            loading={loading}
            setOpenDeleteModal={setOpenDeleteModal}
          />
          <Modal
            open={openCreateModal}
            onClose={handleClose}
            onBackdropClick={(e) => e.stopPropagation()}
          >
            <Box className={classes.box}>
              <CreateCategory
                handleClose={() => setOpenCreateModal(false)}
                category={category}
                setRefreshToken={setRefreshToken}
                UPDATE_CATEGORY={UPDATE_CATEGORY}
                CREATE_CATEGORY={CREATE_CATEGORY}
                name={name}
              />
            </Box>
          </Modal>
        </>
      )}
    </ApolloConsumer>
  );
};

export default Category;
