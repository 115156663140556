import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useLineChartStyles = makeStyles((theme: BaseTheme) => ({
  emptyState: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default useLineChartStyles;
