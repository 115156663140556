import clx from 'classnames';
import { useStyles } from './styles';
import { FC } from 'react';
import { BreadCrumbProps } from './types';
import { TbMathGreater } from 'react-icons/tb';

export const BreadCrumb = () => {
  const classes = useStyles();

  interface SectionMapping {
    [key: string]: { href: string; text: string };
  }

  const getSection = () => {
    const pathMappings: SectionMapping = {
      welcome: { href: '/welcome', text: 'Welcome' },
      culture: { href: '/culture', text: 'Culture' },
      hr: { href: '/hr', text: 'HR' },
      office: { href: '/office', text: 'Office' },
      finance: { href: '/finance', text: 'Finance' },
      faq: { href: '/faq', text: 'Faq' },
    };

    const path: string | undefined = location?.pathname
      .split('/')[1]
      ?.toLowerCase();
    const matchingPath = Object.keys(pathMappings).find((key) =>
      path?.includes(key)
    );
    return matchingPath ? pathMappings[matchingPath] : null;
  };

  const breadCrumbs = [
    <a href="/" key={1} className={classes.link}>
      Home <TbMathGreater color={'black'} />
    </a>,
    <a href={getSection()?.href} key={2} className={classes.link}>
      {getSection()?.text} <TbMathGreater color={'black'} />
    </a>,
    <p className={clx(classes.link, 'active')} key={3}>
      {location.pathname.split('/')[2].replaceAll('-', ' ')}
    </p>,
  ];
  return (
    <section className={classes.root}>
      {breadCrumbs.map((breadCrumb) => {
        return breadCrumb;
      })}
    </section>
  );
};
