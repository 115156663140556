export const formatDate = (dateString: Date) => {
  const date = new Date(dateString);

  const formattedDate = `${date.getDate().toString().padStart(2, '0')} - ${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')} - ${date.getFullYear()}`;

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedTime = `${hours % 12 || 12}:${minutes}${ampm}`;

  return { formattedDate, formattedTime };
};

export const isValidSocialUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const allowedDomains = ['facebook.com', 'instagram.com', 'linkedin.com'];

    const hostname = parsedUrl.hostname.replace('www.', '');

    return allowedDomains.includes(hostname);
  } catch (error) {
    return false;
  }
};

export const downloadCSV = (csv: any) => {

  // Create a Blob from the CSV data
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  
  // Trigger download
  link.href = URL.createObjectURL(blob);
  link.download = 'comments_report.csv';
  link.click();
};


