import { ModalProps } from './types';
import { FC } from 'react';
import { useModalStyles } from './styles';
import clx from 'classnames';

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  position,
}) => {
  const classes = useModalStyles();
  const closeModal = () => {
    onClose();
  };

  return isOpen ? (
    <>
      <div className={clx(classes.modal, position)}>{children}</div>
      <div className={classes.modalOverlay} onClick={closeModal} />
    </>
  ) : null;
};
