import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Modal, Box } from '@mui/material';
import { get } from 'lodash';
import TaskType from '../../types/TaskType';
import { notifyError, notifySuccess } from '../../utilities/notify';
import { Button } from '../common/Button/Button';
import Table from '../common/Table/Table';
import { SchemaProps } from '../common/Table/types';
import useStyles from '../ViewRedirect/styles';
import { DELETETASK, FETCHTASKS } from './graphQL';
import CreateTask from '../Task/CreateTask/CreateTask';
import clx from 'classnames';
import AuthContext from 'contexts/auth';

const ViewTasks = () => {
  const [paginator, setPaginator] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showActions, setShowActions] = useState<number | string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { data, error, loading } = useQuery(FETCHTASKS, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const [tasks, setTasks] = useState<TaskType[]>([]);

  const [displayedTasks, setDisplayedTasks] = useState<TaskType[]>([]);
  const [deleteTaskMutation] = useMutation(DELETETASK);
  const { toggleSpinner } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      let tasks: TaskType[] = data.tasks.data;

      tasks = tasks.map(
        ({ id, name, description, assignee_id, deadline, status }) => {
          return { id, name, deadline, description, assignee_id, status };
        }
      );
      const paginatorInfo = data?.tasks?.paginatorInfo;
      setTasks(tasks);
      setPaginator(paginatorInfo);
      setDisplayedTasks(tasks);
    }

    if (error) {
      notifyError('An Error Occured');
    }
  }, [data, error]);

  const deleteTask = async (id: number) => {
    const proceed = window.confirm('are you sure you want to delete ?');
    if (!proceed) {
      return;
    }
    const variables = { id };
    toggleSpinner(true);
    try {
      await deleteTaskMutation({ variables });
      notifySuccess('Task Deleted Successfully');
      updateTasks(variables.id);
    } catch (error) {
      console.log(error);
    } finally {
      toggleSpinner(false);
    }
  };

  const updateTasks = useCallback(
    (id: number) => {
      let idx = tasks.findIndex((task) => task.id.toString() === id.toString());
      const newTasks = [...tasks];
      newTasks.splice(idx, 1);
      setTasks(newTasks);
      idx = displayedTasks.findIndex(
        (task) => task.id.toString() === id.toString()
      );
      const newDisplayedTasks = [...displayedTasks];
      newDisplayedTasks.splice(idx, 1);
      setDisplayedTasks(newDisplayedTasks);
    },
    [tasks, displayedTasks]
  );

  const schema: SchemaProps[] = [
    {
      label: 'Title',
      func: ({ item, uniqueId }) => {
        const title = get(item, 'name');
        return (
          <div
            className={classes.titleParent}
            onMouseEnter={() => setShowActions(uniqueId)}
          >
            <p className={classes.itemTitle}>{title}</p>
            <div
              className={clx(
                classes.actions,
                showActions === uniqueId && classes.actionActive
              )}
            >
              <p
                className={classes.edit}
                onClick={() => {
                  const id = get(item, 'id');
                  setSelectedId(id);
                  setOpenCreateModal(true);
                }}
              >
                Edit
              </p>
              <p
                className={clx(classes.edit, classes.editBorder)}
                onClick={() => {
                  const id = get(item, 'id');
                  deleteTask(id);
                }}
              >
                Delete
              </p>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Description',
      func: ({ item }) => get(item, 'description'),
    },
    {
      label: 'Assignee',
      func: ({ item }) => get(item, 'assignee_id'),
    },
    {
      label: 'Status',
      func: ({ item }) => (
        <p
          style={{
            background: '#f2f2f2',
            padding: '5px',
            borderRadius: '10px',
            width: 'fit-content',
          }}
        >
          {get(item, 'status') ? 'Completed' : 'Uncompleted'}
        </p>
      ),
    },
    {
      label: 'Deadline',
      func: ({ item }) => get(item, 'deadline').split(' ')[0],
    },
  ];

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Tasks</p>
        <div className={classes.searchBox}>
          <Button
            title="Create Task"
            onClick={() => {
              setSelectedId(null);
              setOpenCreateModal(true);
            }}
          />
        </div>
      </div>

      <div className={classes.main}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <Table
            schema={schema}
            collection={displayedTasks}
            pagination={displayedTasks.length > 0}
            paginatorInfo={paginator}
            rowsPerPage={rows}
            changePage={(e) => setPage(e + 1)}
            changeRow={(e) => setRows(e + 1)}
          />
        )}
      </div>
      <Modal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onBackdropClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.box}>
          <CreateTask
            handleClose={() => setOpenCreateModal(false)}
            id={selectedId}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ViewTasks;
