import axios from 'axios';

export const parseSlug = (site: string, slug: string) => {
  let parsedSlug = '';
  switch (site.toLowerCase()) {
    case 'vt':
      parsedSlug = slug.split('https://vt.co')[1];
      break;
    default:
      parsedSlug = slug.split('https://vt.co')[1];
  }
  return parsedSlug;
};

export const postToAppleNews = async (site: string, slug: string) => {
  const apiUrl = import.meta.env.VITE_REST_URL as string;
  const url = `${apiUrl}api/apple-news/${site}?slug=${parseSlug(site, slug)}`;
  return axios.get(url);
};
