import { gql } from '@apollo/client';

export const VIEWPOLLS = gql`
  query getPolls($first: Int, $page: Int, $search: String) {
    polls(first: $first, page: $page, search: $search) {
      data {
        id
        title
        status
        vote
        votes_count
        expires_at
        is_public
        author {
          name
        }
        options {
          id
          title
          votes_count
        }
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
        lastPage
      }
    }
  }
`;

export const DELETE_POLL = gql`
  mutation deletePoll($id: ID!) {
    deletePoll(id: $id) {
      message
      poll {
        title
        is_public
      }
    }
  }
`;
