import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useVTPageStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    position: 'relative',
    minHeight: 'calc(45vh)',
  },
  publishBtn: {
    position: 'relative',
    '& button': {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.design.font.universal,
    },
  },
  add: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: 24,
  },
  form: {
    border: `solid 1px ${theme.design.colors.borderGrey}`,
    borderLeft: 'none',
    fontFamily: theme.design.font.universal,
    background: theme.design.colors.white,
    maxHeight: '65vh',
    width: '90%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(3),
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  title: {
    fontSize: theme.typography.fontSize + 4,
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  loading: {
    position: 'relative',
    top: 'calc((30vh - 40px)/2)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
  },
  enabled: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: theme.typography.fontSize,
    fontFamily: theme.design.font.universal,
  },
  accordion: {
    boxShadow: 'none !important',
    border: `1px solid ${theme.design.colors.dimGrey}`,
    borderRadius: '4px !important',
    overflow: 'hidden',

    marginBottom: theme.spacing(2),
    '& > .MuiAccordionSummary-root': {
      backgroundColor: `${theme.design.colors.widgetGrey} !important`,

      '& > div > p': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  categoryDropdown: {
    width: '200px',
    marginBottom: theme.spacing(2),
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'white',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  sectionsContainer: {
    display: 'flex',
    width: '100%',
  },
  sectionsTab: {
    width: '20%',
    fontFamily: theme.design.font.universal,
  },
}));
