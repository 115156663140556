import {
  CulturePageContent,
  FaqPageContent,
  FinancePageContent,
  HrPageContent,
  OfficePageContent,
  SubPageContent,
  WelcomePageContent,
} from 'pages/PageContent';
import PortalDashboard from '../../components/PortalDashboard/PortalDashboard';
import { AdminDashboard } from '../../components/PortalDashboard/Dashboard/AdminDashboard';
import ViewTasks from '../../components/ViewTasks/ViewTasks';
import ViewRedirect from '../../components/ViewRedirect/ViewRedirect';
import ViewPolls from '../../components/ViewPolls/ViewPolls';
import ViewNewsletters from '../../components/ViewNewsletters/ViewNewsletters';
import ViewPages from '../../components/ViewPages/ViewPages';
import ViewSurveys from '../../components/ViewSurveys/ViewSurveys';
import ClearCache from '../../components/ClearCache/ClearCache';
import Acl from '../../components/Acl/Acl';
import ViewAnnouncements from '../../components/ViewAnnouncements/ViewAnnouncements';
import ViewSocialIntegrations from '../../components/ViewSocialIntegrations/ViewSocialIntegrations';
import RequestFeature from '../../components/RequestFeature/RequestFeature';
import Features from '../../components/Features/Features';
import Content from '../Content/Content';
import VTPage from '../../components/VTPages/Pages';
import TwistedfoodPage from '../../components/TwistedfoodPages/Pages/Pages';
import Blog from '../../components/Blog/Blog';
import VtTacoBells from '../../components/VtTacoBells/VtTacoBells';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import FournineRevolution from '../../components/FournineRevolution/FournineRevolution';
import MediaLibrary from '../../components/Media/MedialLibrary';
import Mrss from '../../components/Mrss/Mrss';
import AppleNews from '../../components/AppleNews/AppleNews';
import PushNotifications from '../../components/PushNotifications/PushNotifications';
import Facebook from '../../components/Facebook/Facebook';
import InstantArticles from '../../components/InstantArticles/InstantArticles';
import CreatePage from 'components/CreatePage/CreatePage';
import CreateNewsletter from 'components/CreateNewsletter/CreateNewsletter';
import CreateContent from 'components/Blog/components/CreateContent/CreateContent';
import EditPage from 'components/EditPage/EditPage';
import Recipes from 'components/Recipes/Recipes';
import { CreateRecipe } from 'components/Recipes/CreateRecipe/CreateRecipe';
import { BlogCategory } from 'components/BlogCategory/Index';
import { RecipesCategory } from 'components/RecipesCategory/Index';
import { RecipeTags } from 'components/RecipeTags';
import Guides from 'components/Guides/Guides';
import { GuidesCategory } from 'components/GuidesCategory/Index';
import { CreateGuide } from 'components/Guides/CreateGuides/CreateGuides';
import { Profile } from 'components/Profile';
import { RouteType } from './types';
import { ViewForms, ViewResponses } from 'components/Forms';
import {
  CreatePurchaseOrder,
  ReviewPurchaseOrder,
  ViewPurchaseOrders,
} from 'components/PurchaseOrders';
import Cms from 'pages/Cms/Cms';
import { CommentAnalysisLayout } from 'pages/Ca/CommentAnalysisLayout';
import { CommentAnalysis } from 'pages/Ca/CommentAnalysis';
import { CampaignDetails } from 'pages/Ca/CampaignDetails';
import SentimentReport from 'pages/Ca/SentimentReport';
import ContactPage from 'components/ContactPage';

export const contentRoutes = [
  {
    path: '/faq',
    routes: [
      {
        path: '/faq/hr',
        element: <SubPageContent />,
      },
      {
        path: '/faq/payroll',
        element: <SubPageContent />,
      },
      {
        path: '/faq/office',
        element: <SubPageContent />,
      },
      {
        path: '/faq/d&b',
        element: <SubPageContent />,
      },
      {
        path: '/faq/tech',
        element: <SubPageContent />,
      },
      {
        path: '/faq/finance',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <FaqPageContent />,
      },
    ],
  },
  {
    path: '/welcome',
    routes: [
      {
        path: '/welcome/your-first-day',
        element: <SubPageContent />,
      },
      {
        path: '/welcome/emails-and-slack',
        element: <SubPageContent />,
      },
      {
        path: '/welcome/email-signature',
        element: <SubPageContent />,
      },
      {
        path: '/welcome/guess-who',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <WelcomePageContent />,
      },
    ],
  },
  {
    path: '/culture',
    routes: [
      {
        path: '/culture/mission-statements',
        element: <SubPageContent />,
      },
      {
        path: '/culture/values',
        element: <SubPageContent />,
      },
      {
        path: '/culture/all-hands',
        element: <SubPageContent />,
      },
      {
        path: '/culture/diversity-forum',
        element: <SubPageContent />,
      },
      {
        path: '/culture/ergs-clubs-committees',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <CulturePageContent />,
      },
    ],
  },
  {
    path: '/hr',
    routes: [
      {
        path: '/hr/useful-information',
        element: <SubPageContent />,
      },
      {
        path: '/hr/onboarding',
        element: <SubPageContent />,
      },
      {
        path: '/hr/life-at-jungle',
        element: <SubPageContent />,
      },
      {
        path: '/hr/wellbeing-&-benefits',
        element: <SubPageContent />,
      },
      {
        path: '/hr/management',
        element: <SubPageContent />,
      },
      {
        path: '/hr/hiring-at-jungle',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <HrPageContent />,
      },
    ],
  },
  {
    path: '/office',
    routes: [
      {
        path: '/office/the-office',
        element: <SubPageContent />,
      },
      {
        path: '/office/reporting-problems',
        element: <SubPageContent />,
      },
      {
        path: '/office/first-aid-&-fire-safety',
        element: <SubPageContent />,
      },
      {
        path: '/office/phones-&-printers',
        element: <SubPageContent />,
      },
      {
        path: '/office/meeting-rooms',
        element: <SubPageContent />,
      },
      {
        path: '/office/keeping-it-green',
        element: <SubPageContent />,
      },
      {
        path: '/office/health-precautions',
        element: <SubPageContent />,
      },
      {
        path: '/office/coronavirus-policy',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <OfficePageContent />,
      },
    ],
  },
  {
    path: '/finance',
    routes: [
      {
        path: '/finance/purchase-orders',
        element: <SubPageContent />,
      },
      {
        path: '/finance/accounts-payable',
        element: <SubPageContent />,
      },
      {
        path: '/finance/pleo',
        element: <SubPageContent />,
      },
      {
        path: '/finance/dext',
        element: <SubPageContent />,
      },
      {
        path: '/finance/billing',
        element: <SubPageContent />,
      },
      {
        path: '/finance/company-information',
        element: <SubPageContent />,
      },
      {
        index: true,
        element: <FinancePageContent />,
      },
    ],
  },
];

export const cmsRoutes = [
  {
    path: '/cms/*',
    element: <Cms />,
    routes: [
      {
        path: 'vt',
        element: <VTPage />,
        routes: [{ path: '*', element: <VTPage /> }],
      },
      {
        path: 'twisted',
        element: <TwistedfoodPage />,
        routes: [{ path: '*', element: <TwistedfoodPage /> }],
      },
      { path: 'taco-bell', element: <VtTacoBells /> },
      { path: 'contact-details', element: <ContactDetails /> },
      { path: 'fournine-revolution', element: <FournineRevolution /> },
      { path: 'create-mrss', element: <Mrss /> },
      { path: 'media-library', element: <MediaLibrary /> },
      { path: 'push-notifications', element: <PushNotifications /> },
      { path: 'facebook', element: <Facebook /> },
      { path: 'apple-news', element: <AppleNews /> },
      { path: 'category', element: <BlogCategory /> },
      { path: 'recipes-category', element: <RecipesCategory /> },
      { path: 'guides-category', element: <GuidesCategory /> },
      { path: 'recipes-tags', element: <RecipeTags /> },
      { path: 'instant-articles', element: <InstantArticles /> },
      { path: 'posts/:uuid/new', element: <CreateContent /> },
      { path: 'posts/:uuid', element: <CreateContent /> },
      { path: 'posts', element: <Blog /> },
      { path: 'posts/:mode', element: <CreateContent /> },
      { path: 'recipes', element: <Recipes /> },
      { path: 'recipes/:uuid/new', element: <CreateRecipe /> },
      { path: 'recipes/:uuid', element: <CreateRecipe /> },
      { path: 'guides', element: <Guides /> },
      { path: 'guides/create', element: <CreateGuide /> },
      { path: 'guides/:uuid/edit', element: <CreateGuide /> },
      { path: 'redirects', element: <ViewRedirect /> },
      { path: 'clear-cache', element: <ClearCache /> },
      { path: 'forms/:uuid/responses', element: <ViewResponses /> },
      { path: 'forms', element: <ViewForms /> },
    ],
  },
];

export const caRoutes = [
  {
    path: '/ca',
    element: <CommentAnalysisLayout />,
    routes: [
      { index: true, element: <CommentAnalysis /> },
      { path: '/ca/campaign/:campaignUuid', element: <CampaignDetails /> },
      {
        path: '/ca/campaign/:campaignId/sentiment-report',
        element: <SentimentReport />,
      },
    ],
  },
];

export const adminRoutes = [
  {
    path: '/admin',
    element: <PortalDashboard />,
    routes: [
      { element: <AdminDashboard />, index: true },
      { path: 'announcements', element: <ViewAnnouncements /> },
      { path: 'announcements/:id/edit', element: <ViewAnnouncements /> },
      {
        path: 'productivity',
        routes: [
          { path: 'task', element: <ViewTasks /> },
          { path: 'polls', element: <ViewPolls /> },
          { path: 'surveys', element: <ViewSurveys /> },
        ],
      },
      {
        path: 'purchase-orders',
        routes: [
          { element: <ViewPurchaseOrders />, index: true },
          { path: 'request-forms', element: <ViewTasks /> },
          { path: 'settings', element: <ViewPolls /> },
          { path: 'create', element: <CreatePurchaseOrder /> },
          { path: ':uuid/edit', element: <CreatePurchaseOrder /> },
          { path: ':uuid/review', element: <ReviewPurchaseOrder /> },
        ],
      },
      { path: 'newsletters', element: <ViewNewsletters /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'newsletters/:id/edit', element: <CreateNewsletter /> },
      { path: 'newsletters/create', element: <CreateNewsletter /> },
      { path: 'pages', element: <ViewPages /> },
      { path: 'pages/create', element: <CreatePage /> },
      { path: 'pages/edit/:id', element: <EditPage /> },
      { path: 'profile', element: <Profile /> },
      { path: 'features', element: <Features /> },
      { path: 'acl', element: <Acl /> },
      { path: 'features/request', element: <RequestFeature /> },
      { path: 'social/integrations', element: <ViewSocialIntegrations /> },
      {
        path: 'welcome',
        element: <Content />,
      },
      {
        path: 'culture',
        element: <Content />,
      },
      {
        path: 'hr',
        element: <Content />,
      },
      {
        path: 'office',
        element: <Content />,
      },
      {
        path: 'faq',
        element: <Content />,
      },
      {
        path: 'finance',
        element: <Content />,
      },
    ],
  },
];
