import { gql } from '@apollo/client';

export const CREATEREDIRECT = gql`
  mutation createRedirect(
    $site: String!
    $source: String!
    $target: String!
    $type: Boolean!
    $time: String
  ) {
    createRedirect(
      input: {
        site: $site
        source: $source
        target: $target
        type: $type
        time: $time
      }
    ) {
      message
      errorId
      redirect {
        source
        target
        site
        type
        expires_at
      }
    }
  }
`;
