import React from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useTextFieldStyles } from './styles';
import { TextFieldProps } from './types';
import InputAdornment from '@mui/material/InputAdornment';
import clx from 'classnames';

const TextField: React.FC<TextFieldProps> = ({
  onChange,
  onBlur,
  multiline,
  type,
  value,
  label,
  name,
  rows,
  disabled,
  placeholder,
  className,
  onKeyDown,
  icon,
}) => {
  const classes = useTextFieldStyles();

  return (
    <FormControl className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <OutlinedInput
        id={name}
        name={name}
        rows={rows}
        multiline={multiline}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type={type}
        value={value}
        disabled={disabled}
        className={clx(classes.textField, className)}
        placeholder={placeholder}
        startAdornment={
          icon && <InputAdornment position="start">{icon}</InputAdornment>
        }
      />
    </FormControl>
  );
};

export default TextField;
