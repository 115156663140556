const tagSuggestions = [
  'Music',
  'Dance',
  'Comedy',
  'Politics',
  'Thoughts',
  'News',
  'Shows',
  'Entertainment',
  'Hiring',
  'Outsourcing',
  'Agile',
  'Scrum',
  'Development',
];

export const getTagSuggestions = () => {
  const suggestions = [];

  for (let i = 0; i < 6; i++) {
    const index = Math.round(Math.random() * (tagSuggestions.length - 1));
    suggestions.push(tagSuggestions[index]);
  }

  return [...new Set(suggestions)];
};
