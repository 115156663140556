import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useOrderStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    display: 'flex',
    background: theme.design.colors.white,
    padding: theme.spacing(4),

    '& img': {
      width: 100,
      height: 100,
      objectFit: 'cover',
      borderRadius: '50%',
    },
  },
  postTags: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  postCheck: {
    marginLeft: theme.spacing(1.5),
    opacity: 0.5,
  },
  postTagDifference: {
    marginTop: theme.spacing(0.5),
    color: theme.design.colors.grey200,
    fontSize: theme.typography.fontSize - 2,
    opacity: 0.85,
  },
  blogTitleSlug: {
    fontWeight: 'bold',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontFamily: theme.design.font.universal,
    cursor: 'pointer',
    color: '#2271B1',
  },
  orderApproved: {
    background: theme.design.colors.green100,
    fontWeight: theme.typography.fontWeightMedium,
  },
  orderProcessing: {
    background: theme.design.colors.yellow,
    fontWeight: theme.typography.fontWeightMedium,
    border: 'none',
  },
  orderReceived: {
    background: theme.design.colors.primary700,
    fontWeight: theme.typography.fontWeightMedium,
  },
  orderCancelled: {
    background: theme.design.colors.pink,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export default useOrderStyles;
