import { useState, useEffect, useCallback, useContext } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import {
  DELETE_RECIPE_BY_UUID,
  GET_POSTS_BY_BLOG_UUID,
  GET_RECIPES_BY_SITE_UUID,
} from '../Blog/graphQL';
import { useMutation, ApolloConsumer } from '@apollo/client';
import { Button } from '../common/Button/Button';
import useStyles from '../ViewRedirect/styles';
import { notifySuccess } from '../../utilities/notify';
import { SEND_NOTIFICATION } from '../ArticlePlatforms/graphql';
import { AppStateContext } from '../../contexts/provider';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';
import { recipeClient } from 'utilities/apolloClient';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';
import { v4 as uuidv4 } from 'uuid';

const Recipes = () => {
  const [, setPaginator] = useState<{ [key: string]: any }>({});
  const [, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [post_uuid, setPostUuid] = useState<undefined | string>();
  const [refreshToken, setRefreshToken] = useState(false);
  const {
    site,
    siteDetails: { blog_uuid, uuid },
    user,
  } = useContext(AppStateContext);
  //   const [fetchArticles, { data, loading: pageLoading, error }] = useLazyQuery(
  //     GETARTICLES,
  //     {
  //       fetchPolicy: 'network-only',
  //     }
  //   );

  const [deleteRecipe] = useMutation(DELETE_RECIPE_BY_UUID, {
    client: recipeClient,
  });
  const [articles, setArticles] = useState<{ [key: string]: string }[]>([]);
  const { toggleSpinner } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [sendNotification] = useMutation(SEND_NOTIFICATION);
  const tableColumns = useGetTableColumns(setPostUuid, setOpenDeleteModal);
  const getTableRows = useGetTableRows();

  //   useEffect(() => {
  //     const page = 1;
  //     setPage(page);
  //     fetchArticles({ variables: { page, first: rows, site } });
  //   }, [site]);

  //   useEffect(() => {
  //     if (data) {
  //       const articles = data.pages.data;
  //       setArticles(articles);
  //       const paginatorInfo = data?.pages?.paginatorInfo;
  //       setPaginator(paginatorInfo);
  //     }

  //     if (error) {
  //       notifyError('an error occured');
  //     }
  //   }, [data, error]);

  //   useEffect(() => {
  //     setRows(rows);
  //     setPage(1);
  //     fetchArticles({ variables: { page: 1, first: rows, site } });
  //   }, [rows]);

  //   const fetchPages = useCallback(
  //     (page: number) => {
  //       setPage(page);
  //       fetchArticles({ variables: { page, first: rows, site } });
  //     },
  //     [site, rows]
  //   );

  const handleDeleteArticle = async () => {
    if (!post_uuid) return;

    const variables = { uuid: post_uuid };
    toggleSpinner(true);
    try {
      handleClose();
      await deleteRecipe({ variables });
      setRefreshToken(true);
      notifySuccess('Recipe Deleted Successfully');
    } finally {
      setRefreshToken(false);
      handleClose();
      toggleSpinner(false);
    }
  };

  const sendPushNotification = async (
    site: string,
    url: string,
    title: string,
    body: string
  ) => {
    toggleSpinner(true);
    await sendNotification({ variables: { site, title, body, url } })
      .then(() => {
        notifySuccess('Success!');
      })
      .catch(() => {
        //
      })
      .finally(() => {
        toggleSpinner(false);
      });
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <header className={classes.titleWidget}>
            <p className={classes.title}>Recipes</p>
            <div className={classes.rightSide}>
              <Button
                title="Create Recipes"
                onClick={() => {
                  navigate(`/cms/recipes/${uuidv4()}/new`);
                }}
                className={classes.createBtn}
              />
            </div>
          </header>

          {/* {/* <div className={classes.main}>
            {pageLoading ? (
              <div className={classes.loading}>
                <CircularProgress color="inherit" size="30px" />
              </div>
            ) : ( */}
          <TableNew
            columns={tableColumns}
            getRows={getTableRows}
            query={{
              name: GET_RECIPES_BY_SITE_UUID,
              client,
              variables: {
                site_uuid: uuid,
                refreshToken,
                include_drafts: true,
              },
              dataKey: 'getRecipesBySiteUuid',
            }}
            conditionFilters={tableConditionFilters}
            showFilters={false}
          />
          {/* )} */}
          {/* </div> */}
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            deletePostCategory={handleDeleteArticle}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default Recipes;
