export const subLinks: { [key: string]: string[] } = {
  welcome: [
    'Your First Day',
    'Emails and Slack',
    'Email Signature',
    'Guess Who',
  ],
  culture: [
    'Mission Statements',
    'Values',
    'All Hands',
    'Diversity Forum',
    'ERGs',
  ],
  hr: [
    'Sick Days',
    'Working Day',
    'Performance Reviews',
    'Career Bandings',
    'Wellbeing',
    'Hiring',
    'Hybrid Working',
    'Payroll',
  ],
  faq: [
    'Sick Days',
    'Working Day',
    'Performance Reviews',
    'Career Bandings',
    'Wellbeing',
    'Hiring',
    'Hybrid Working',
    'Payroll',
  ],
  office: [
    'HR',
    'Payroll',
    'Office',
    'D&B',
    'Tech',
    'Finance',
    'Health Precautions',
    'Coronavirus Policy',
  ],
  dashboard: [
    'Redirects',
    'Polls',
    'Surveys',
    'Features',
    'Clear Cache',
    'Instant Articles',
    'Articles',
    'Create Pages',
  ],
  finance: [
    'Purchase Orders',
    'Accounts Payable',
    'Pleo',
    'Dext',
    'Billing',
    'Company Information',
  ],
};

export const sections = [
  { name: 'Admin', route: '/admin' },
  { name: 'CMS', route: '/cms' },
  { name: 'CPAS', route: '/cpas' },
  { name: 'CA', route: '/ca' },
];
