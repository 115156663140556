import { gql } from '@apollo/client';

export const FETCHNEWSLETTER = gql`
  query getNewsletterById($id: ID!) {
    newsletter(id: $id) {
      title
      url
      date
    }
  }
`;

export const UPDATENEWSLETTER = gql`
  mutation updateNewsletter(
    $id: ID!
    $title: String!
    $url: String!
    $date: String!
    $image: Upload
  ) {
    updateNewsletter(
      id: $id
      title: $title
      url: $url
      date: $date
      image: $image
    ) {
      message
      errorId
    }
  }
`;
