import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useAdminDashboardStyles = makeStyles((theme: BaseTheme) => ({
  home: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'row',
    zIndex: -10,

    '& .action_button': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: `${theme.spacing(3)}px`,
      padding: theme.spacing(1.5),
      borderRadius: '10px',
      background: theme.design.colors.black,
      color: theme.design.colors.white,
      width: '254px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      fontSize: 14,

      '& .arrow_parent': {
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.design.colors.white,
        borderRadius: '3px',

        '& .icon': {
          color: theme.design.colors.black,
        },
      },
    },
  },
  directoryWidgetParent: {
    width: '100%',
  },
  right_section: {
    backgroundColor: theme.design.colors.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
    alignItems: 'center',
    minHeight: '295px',
    position: 'relative',
    overflow: 'hidden',
  },
  left_section: {
    backgroundColor: theme.design.colors.widgetGrey,
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(2),

    '& .initial-widgets': {
      display: 'flex',

      '& .welcome-events': {
        display: 'flex',
        flexDirection: 'column',
        width: '320px',
        marginRight: `${theme.spacing(3)}px`,
      },

      '& .social': {
        marginTop: `${theme.spacing(2)}px`,
        width: '100%',
        height: '790px',
        zIndex: 0,
      },
    },

    '& .final-widgets': {
      display: 'flex',
      justifyContent: 'space-between',

      '& .widget-parent': {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc((100% - 25px)/2)',
      },
    },

    '& .events': {
      marginBottom: theme.spacing(2.5),
    },
  },
  left_section_right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(2.5),
  },
  topSection: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  divider: {
    border: `0.5px solid${theme.design.colors.grey900}`,
    width: '100%',
    margin: 0,
  },
  title: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  weatherTitleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    margin: theme.spacing(0, 'auto'),
    // padding: theme.spacing(2, 0),
    height: 50,
    alignItems: 'center',
  },
  day: {
    fontSize: theme.typography.fontSize - 2,
    padding: theme.spacing(0.3, 1),
    color: theme.design.colors.grey700,
    background: theme.design.colors.grey900,
    borderRadius: '12px',
  },
  shortcutParent: {
    width: '90%',
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2, 0, 1, 0),
  },
  shortcut: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize,
  },
  plusIcon: {
    width: 31,
    height: 29,
    borderRadius: '50%',
    background: theme.design.colors.grey900,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  social: {
    marginTop: theme.spacing(2),
  },
  calendarParent: {
    padding: theme.spacing(3, 0, 1.5, 0),
  },
  calendar: {
    '&.MuiDateCalendar-root': {
      minWidth: '240px !important',
      minHeight: '295px',
      maxHeight: '300px',
      width: '90%',
    },
  },
}));
