import { GqlMediaLibrarySelectorProps } from '@wazobia-tech/wazcom/dist/gql';
import {
  CONNECT_INSTAGRAM_ACCOUNT,
  DELETE_IMAGE,
  DOWNLOAD_IMAGE,
  INTEGRATE_INSTAGRAM_ACCOUNT,
  UPDATE_IMAGE,
} from 'components/Media/graphql/queries';
import {
  GET_INSTAGRAM_IMAGES,
  GET_PEXELS_IMAGES,
  GET_UNSPLASH_IMAGES,
} from 'components/Media/graphql/queries';

export const commonMediaLibraryProps: Pick<
  GqlMediaLibrarySelectorProps['config'],
  'update' | 'remove' | 'unsplash' | 'instagram' | 'pexels' | 'download'
> = {
  update: {
    name: UPDATE_IMAGE,
  },
  remove: {
    name: DELETE_IMAGE,
  },
  unsplash: {
    name: GET_UNSPLASH_IMAGES,
    variables: {},
  },
  instagram: {
    name: GET_INSTAGRAM_IMAGES,
    connect: CONNECT_INSTAGRAM_ACCOUNT,
    integrate: INTEGRATE_INSTAGRAM_ACCOUNT,
    variables: {},
  },
  pexels: {
    name: GET_PEXELS_IMAGES,
    variables: {},
  },
  download: {
    name: DOWNLOAD_IMAGE,
  },
};
