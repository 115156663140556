export const defaultCard = {
  image: {
    src: {
      desktop: '',
      mobile: '',
    },
    alt: '',
  },
  video: {
    src: '',
  },
  text: '',
  enabled: true,
};
