import { gql } from '@apollo/client';

export const FETCHCONFIG = gql`
  query GetConfig($route: String!, $site: String!) {
    getConfig(route: $route, site: $site)
  }
`;

export const FETCHSITE = gql`
  query Sites {
    sites {
      id
      name
      uuid
    }
  }
`;

export const FETCH_SITES = gql`
  query GetSiteByName {
    getBlogSites {
      site {
        id
        name
        uuid
        url
      }
      blog_uuid
      library_uuid
    }
  }
`;

export const GETSITEFEEDS = gql`
  query GetSiteFeeds($site_uuid: ID!, $first: Int!) {
    getSiteFeeds(site_uuid: $site_uuid, first: $first) {
      data {
        title
        uuid
        site_id
        managingEditor
        description
        mrssItems {
          title
          description
          link
          pubDate
          uuid
        }
        image
        lastBuildDate
        link
        pubDate
      }
    }
  }
`;

export const GETFEEDINFOBYUUID = gql`
  query GetFeedInfoByUuid($uuid: ID!) {
    getFeedInfoByUuid(uuid: $uuid) {
      description
      image
      lastBuildDate
      link
      managingEditor
      pubDate
      site_id
      title
      uuid
      mrssItems {
        description
        link
        pubDate
        title
        uuid
      }
    }
  }
`;

export const GETFEEDCONTENTSBYUUID = gql`
  query GetFeedContentsByUuid($feed_uuid: ID!, $first: Int!) {
    getFeedContentsByUuid(feed_uuid: $feed_uuid, first: $first) {
      data {
        description
        link
        pubDate
        title
        uuid
        mrssMedia {
          author
          category
          copyright_url
          description
          thumbnail
          title
          url
          uuid
        }
      }
    }
  }
`;

export const GET_RECIPE_BY_SITEUUID_AND_SLUG = gql`
  query GetRecipeBySiteUuidAndSlug($site_uuid: ID!, $slug: String!) {
    getRecipeBySiteUuidAndSlug(site_uuid: $site_uuid, slug: $slug) {
      title
      status
      description
      author_uuid
    }
  }
`;

export const GET_BLOG_POST_BY_UUID_AND_SLUG = gql`
  query MyQuery($blogUuid: ID!, $slug: String!) {
    getBlogPostByBlogUuidAndSlug(input: { blog_uuid: $blogUuid, slug: $slug }) {
      author_uuid
      title
      status
      content
    }
  }
`;
