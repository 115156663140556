import { FC } from 'react';
import clx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import { CREATEPOLL, UPDATE_POLL } from './graphQL';
import { useMutation } from '@apollo/client';
import { CreatePoll as CreatePollSchema } from '../../schemas/poll';
import { notifyError, notifySuccess } from '../../utilities/notify';
import useStyles from './styles';
import { OutlinedInput, Switch } from '@mui/material';
import { Button } from '../common/Button/Button';
import { Select } from '../common/Select/Select';
import TextField from '../common/TextField/TextField';
import { Close } from '@mui/icons-material';
import PollProps from 'types/PollType';

interface pollsProps {
  handleClose: () => void;
  handleRefresh: () => void;
  editData: PollProps | undefined;
}

const CreatePoll: FC<pollsProps> = ({
  handleClose,
  handleRefresh,
  editData,
}) => {
  const classes = useStyles();
  const [CREATEPOLLMUTATION, { loading }] = useMutation(CREATEPOLL);
  const [editPoll] = useMutation(UPDATE_POLL);
  const navigate = useNavigate();

  const createPoll = async (
    {
      title,
      options,
      time,
      is_public,
    }: {
      [key: string]: string | string[];
    },
    _resetForm: () => void
  ) => {
    try {
      await CREATEPOLLMUTATION({
        variables: { title, options, time, is_public },
      });
      notifySuccess('Poll Created Successfully');
      handleRefresh();
      handleClose();
      navigate('/admin/productivity/polls');
    } catch (error) {
      notifyError('An Error Occured');
    }
  };

  const updatePoll = async (
    variables: {
      [key: string]: string | string[];
    },
    _resetForm: () => void
  ) => {
    try {
      await editPoll({ variables });
      notifySuccess('Poll Updated Successfully');
      handleRefresh();
      handleClose();
      navigate('/admin/productivity/polls');
    } catch (error) {
      notifyError('An Error Occured');
    }
  };

  const timeOptions = {
    Minutes: 'm',
    Hour: 'h',
    Day: 'd',
    Month: 'n',
    Year: 'y',
  };

  const dateObject = new Date(editData?.expires_at ?? '');
  const currentDateTime = new Date();
  const timeDifference = dateObject.getTime() - currentDateTime.getTime();
  const remainingMinutes = Math.ceil(timeDifference / (1000 * 60));

  return (
    <div className={classes.poll}>
      <div className={classes.title_parent}>
        <p className={classes.poll__title}>
          {editData?.title ? 'Edit Poll' : 'Create Poll'}
        </p>
        <Close className={classes.closeIcon} onClick={handleClose} />
      </div>
      <Formik
        initialValues={{
          title: editData?.title ?? '',
          options: [
            editData?.options?.[0]?.title ?? '',
            editData?.options?.[1]?.title ?? '',
          ],
          time: '',
          time_number: editData?.expires_at ? `${remainingMinutes}` : '',
          time_period: 'm',
          is_public: editData?.is_public ?? 1,
        }}
        validationSchema={CreatePollSchema}
        validateOnChange={false}
        onSubmit={(values, { resetForm }) => {
          const time = values.time_number + values.time_period;
          values.time = time;
          if (editData.id) {
            updatePoll({ ...values, id: editData.id }, resetForm);
          } else {
            createPoll(values, resetForm);
          }
        }}
        render={({ handleChange, values, errors }) => (
          <Form className={classes.poll__form}>
            <div
              className={classes.poll__form_section}
              style={{ width: '100%' }}
            >
              <label className={classes.label}>Question</label>
              <TextField
                name="title"
                type="text"
                onChange={handleChange}
                value={values.title}
                multiline={true}
                rows={3}
              />
              {errors.title && (
                <p className={classes.poll__form_error}>{errors.title}</p>
              )}
            </div>
            <FieldArray
              name="options"
              render={(arrayHelpers) => (
                <div style={{ marginBottom: '16px' }}>
                  <p className={classes.label}>Options</p>
                  {values.options.map((friend, index) => (
                    <div key={index}>
                      <div className={classes.poll__option}>
                        <div className={classes.poll__option_circle}></div>
                        <div className={classes.textfieldContainer}>
                          <TextField
                            name={`options.${index}`}
                            type="text"
                            value={values.options[index]}
                            onChange={handleChange}
                            placeholder={`Option ${index + 1}`}
                            className={classes.textField}
                          />
                        </div>
                        {values.options.length > 2 && (
                          <div
                            onClick={() => {
                              if (values.options.length > 2) {
                                arrayHelpers.remove(index);
                              }
                            }}
                            className={classes.poll__option_remove}
                          >
                            <img
                              src="https://jungle-static.s3-us-west-1.amazonaws.com/jungleportal/minus.svg"
                              alt="minus symbol"
                            />
                            <p>remove</p>
                          </div>
                        )}
                      </div>
                      {errors.options && (
                        <p
                          className={classes.poll__form_error}
                          style={{
                            position: 'relative',
                            left: '32px',
                            margin: '0 0 1rem 0',
                          }}
                        >
                          {errors.options[index]}
                        </p>
                      )}
                    </div>
                  ))}
                  <p
                    onClick={() => {
                      arrayHelpers.insert(values.options.length, '');
                    }}
                    className={classes.poll__option_add}
                  >
                    Add Option
                  </p>
                </div>
              )}
            />
            <div className={classes.poll__form_section}>
              <label className={classes.label}>Poll Duration</label>
              <div className={classes.poll__duration_parent}>
                <div style={{ position: 'relative' }}>
                  <div className="select">
                    <Select
                      name="time_period"
                      options={timeOptions}
                      onChange={handleChange}
                      append={true}
                    />
                  </div>
                  <OutlinedInput
                    name=""
                    onChange={handleChange('time_number')}
                    type="number"
                    className={classes.poll__duration_input}
                    value={Number(values.time_number)}
                  />
                </div>
                {errors.time_number && (
                  <p className={classes.poll__form_error}>
                    {errors.time_number}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className={clx(classes.poll__form_section, classes.flexField)}>
              <label className={classes.label}>
                Share results with respondents
              </label>
              <Switch
                checked={Boolean(values.is_public)}
                defaultChecked
                onChange={handleChange('is_public')}
                name="is_public"
              />
            </div>
            <div className={classes.poll__submit}>
              <Button
                disabled={loading}
                type="submit"
                title={editData?.title ? 'Update' : 'Create'}
                className={classes.btn}
              />
            </div>
          </Form>
        )}
      ></Formik>
    </div>
  );
};

export default CreatePoll;
