import { useCallback, useContext } from 'react';
import useCategoryStyles from './styles';
import { CategoryFormProps } from './types';
import { useMutation } from '@apollo/client';
import { CategoryList } from '../categoryList';
import { GqlComboBox } from '@wazobia-tech/wazcom/dist/gql';
import { notifyError } from 'utilities/notify';
import { AppStateContext } from 'contexts/provider';

const CategoryForm: React.FC<CategoryFormProps> = ({
  formData,
  setFormData,
  isArticle,
  getCategoriesConfig: { variables, ...getCategoriesConfig },
  createCategoryConfig: { client, CREATE_CATEGORY },
}) => {
  const classes = useCategoryStyles();

  const [createCategory, { data }] = useMutation(CREATE_CATEGORY, {
    client,
  });

  const {
    siteDetails: { url: siteUrl },
  } = useContext(AppStateContext);

  const postCategoryFetchHook = (
    params: { label: string; value: string }[]
  ) => {
    const categories = params.map(({ label, value }) => ({
      name: label,
      uuid: value,
    }));
    setFormData((formData) => ({ ...formData, allCategories: categories }));
  };

  const authorizedToCreateCategory = ['aff8a482-9492-4b2b-923e-4d20cb2a34ff'];

  const checkUserAccess = () => {
    if (
      !siteUrl.includes('twistedfood') ||
      (siteUrl.includes('twistedfood') &&
        authorizedToCreateCategory.includes(formData.author_uuid))
    ) {
      return true;
    }
    return false;
  };

  const CategoriesInput = useCallback(() => {
    return (
      // @ts-ignore
      <GqlComboBox
        name="category_uuid"
        config={getCategoriesConfig}
        onChange={({ name, value }: any) => {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }}
        postFetchHook={postCategoryFetchHook}
        placeholder="e.g entertainment"
        variables={variables}
        label="ASSIGN CATEGORIES TO THIS POST"
        addOption={(value: string) => {
          const hasAccess = checkUserAccess();
          if (!hasAccess) {
            notifyError("You don't have access to create this category");
          } else {
            createCategory({
              variables: {
                ...variables,
                name: value,
                slug: `/${value.toLowerCase().replace(/ /g, '-')}`,
              },
            });
          }
        }}
        sx={{
          '& input': {
            minHeight: 0,
            width: '90%',
          },
        }}
      />
    );
  }, [data]);

  return (
    <div className={classes.content__form_section} style={{ width: '100%' }}>
      <p className={classes.title}>Category</p>
      <div className={classes.adjustInput}>
        <CategoriesInput />
      </div>
      <CategoryList
        formData={formData}
        setFormData={setFormData}
        isArticle={isArticle ?? false}
        getCategoryConfig={{
          client: getCategoriesConfig.client,
          dataKey: getCategoriesConfig.key,
          GET_CATEGORY: getCategoriesConfig.name,
          variables,
        }}
      />
    </div>
  );
};

export default CategoryForm;
