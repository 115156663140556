import { useAutoFill } from 'layouts/createContent/helpers';
import useSeoStyles from './styles';
import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import TextField from 'components/common/TextField/TextField';
import { formHandleText } from 'utilities/form';
import TextArea from 'components/common/TextArea/TextArea';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Preview from 'components/Blog/components/Preview/Preview';
import { MediaDisplay } from 'components/common/MediaDisplay';
import { SeoFormProps } from './types';
import ProgressBar from 'components/common/ProgressBar';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { Switch } from '@mui/material';

const SeoForm: React.FC<SeoFormProps> = ({
  formData,
  setEmbedAction,
  setFormData,
}) => {
  const classes = useSeoStyles();
  const { handleAutoFilledFields } = useAutoFill(formData, setFormData, 'seo');
  const getPreSlugURL = useGetPreSlugURL();

  const seoAnalysis = [
    {
      mark:
        formData?.seo_title?.length > 41 && formData?.seo_title?.length < 70,
      not_Good: formData?.seo_title?.length < 41,
      title: 'Title Counter:',
      remark:
        formData?.seo_title?.length > 41 && formData?.seo_title?.length < 70
          ? 'Good job!'
          : 'Not Good enough!',
    },
    {
      mark:
        formData?.seo_description?.length > 105 &&
        formData?.seo_description?.length < 143,
      not_Good: formData?.seo_description?.length < 105,
      title: 'Description Counter:',
      remark:
        formData?.seo_description?.length > 105 &&
        formData?.seo_description?.length < 143
          ? 'Good job!'
          : 'Not Good enough!',
    },
    {
      mark:
        formData?.seo_description?.length > 105 &&
        formData?.seo_description?.length < 143,
      not_Good: formData?.seo_description?.length < 105,
      title: 'Keyphrase Density Analyser:',
      remark:
        'The focus keyword was found 23 times. That’s more the recommended maximum for a text of that length.',
    },
  ];
  let progressTitle = formData?.seo_title?.length;
  let progressDescription = formData?.seo_description?.length;

  return (
    <div>
      <p className={classes.title}>SEO</p>
      <div className={classes.switchWrapper}>
        <p className={classes.autoText}>
          Do you want to use the auto-filled suggestion?
        </p>
        <Switch
          inputProps={{ 'aria-label': 'demo' }}
          name="is_autofilled_suggestion"
          className={classes.switch}
          onChange={(e) => {
            setFormData({
              ...formData,
              is_autofilled_suggestion: e.target.checked,
            });
          }}
          checked={formData?.is_autofilled_suggestion}
          value={formData.is_autofilled_suggestion}
        />
      </div>
      <div className={classes.selectBox}>
        <MediaDisplay
          name={'seo_image'}
          formData={formData}
          setFormData={setFormData}
          setEmbedAction={setEmbedAction}
        />
      </div>
      <Preview
        title={decodeHtml(formData?.seo_title)}
        description={decodeHtml(formData?.seo_description)}
        // link={formData?.permalink}
        label="SEARCH RESULT PREVIEW"
      />
      <div className={classes.selectBox}>
        <p className={classes.label}>
          Seo Title <span>{formData?.seo_title?.length ?? 0}</span>
        </p>
        <TextField
          onChange={(e: React.ChangeEvent<any>) => {
            handleAutoFilledFields('title', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          type="text"
          value={decodeHtml(formData?.seo_title ?? '')}
          name={'seo_title'}
          className={classes.textField}
        />
        <ProgressBar progressValue={progressTitle} highest={70} lowest={40} />
      </div>

      <div className={classes.selectBox}>
        <p className={classes.label}>SEO URL</p>
        <TextField
          onChange={(e: React.ChangeEvent<any>) => {
            handleAutoFilledFields('url', e.target.value);
            formHandleText(e, setFormData, formData);
            setFormData((form) => {
              const escapedBaseUrl = getPreSlugURL().replace(
                /[.*+?^${}()|[\]\\]/g,
                '\\$&'
              );
              const matches = e.target.value.match(
                new RegExp(`${escapedBaseUrl}(.*)$`)
              );
              return {
                ...form,
                slug: matches?.[1] ?? form.slug,
                permalink: matches?.[1] ?? form.permalink,
                ignoreTitleChange: true,
              };
            });
          }}
          type="text"
          value={formData?.seo_url}
          name={'seo_url'}
          className={classes.textField}
        />
      </div>
      <div className={classes.selectBox}>
        <p className={classes.label}>
          Description <span>{formData?.seo_description?.length ?? 0}</span>
        </p>
        <TextArea
          onChange={(e) => {
            handleAutoFilledFields('description', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          value={decodeHtml(formData?.seo_description ?? '')}
          name="seo_description"
          className={classes.excerptText}
        />
        <ProgressBar
          progressValue={progressDescription}
          highest={143}
          lowest={105}
        />
      </div>
      <div>
        <p className={classes.seoAnalysisTitle}>
          SEO Analysis <HiQuestionMarkCircle color={'#999F9B'} size={20} />
        </p>
        {seoAnalysis?.map(({ mark, title, remark, not_Good }, index) => (
          <div key={index} className={classes.seoAnalysis}>
            <IoIosCheckmarkCircle
              color={not_Good ? '#fec228' : mark ? 'green' : 'red'}
              size={20}
            />
            <p>
              <span className={classes.bottomContentDesc}>{title}</span>
              <span className={classes.seoRemark}>{remark}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeoForm;
