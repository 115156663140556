import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query getPlatformArticles($site: String!) {
    getPlatformArticles(site: $site) {
      title
      description
      url
      site
      id
      push_notification_status
    }
  }
`;

export const SEND_PUSH_NOTIFICATION = gql`
  mutation sendPushNotifications(
    $title: String!
    $body: String!
    $site: String!
    $url: String
  ) {
    sendPushNotification(title: $title, site: $site, body: $body, url: $url) {
      canonical_ids
      failure
      multicast_id
      success
    }
  }
`;
