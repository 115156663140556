import { gql } from '@apollo/client';

export const FETCHPAGES = gql`
  query getContents($first: Int, $page: Int, $search: String) {
    contents(first: $first, page: $page, search: $search) {
      data {
        id
        title
        slug
        updated_at
      }
      paginatorInfo {
        count
        total
        currentPage
        lastPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const CONTACTUSPAGES = gql`
  query getResponsesByFormUuid(
    $form_uuid: ID!
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getResponsesByFormUuid(
      input: {
        form_uuid: $form_uuid
        first: $first
        page: $page
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        response
        updated_at
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const DELETEPAGE = gql`
  mutation deleteContent($id: ID!) {
    deleteContent(id: $id) {
      id
    }
  }
`;
