import clx from 'classnames';
import useDetailStyles from './styles';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { Switch } from '@mui/material';
import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import { MediaDisplay } from 'components/common/MediaDisplay';
import { Slug } from 'components/Blog/components/Slug';
import { Author } from 'components/Blog/components/Author';
import { formHandleText } from 'utilities/form';
import TextField from 'components/common/TextField/TextField';
import TextArea from 'components/common/TextArea/TextArea';
import { DetailsFormProps } from './types';
import { POST_PAGE_TYPE, RECIPE_PAGE_TYPE } from 'layouts/createContent/types';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';

const DetailsForm: React.FC<DetailsFormProps> = ({
  setEmbedAction,
  setFormData,
  formData,
  type,
  switchData,
}) => {
  const classes = useDetailStyles();
  const getPreSlugURL = useGetPreSlugURL();

  const displaySwitchOptions = () =>
    switchData.map((data) => (
      <div className={classes.switchParent} key={data.name}>
        <p className={classes.switchLabel}>{data.label}</p>
        <Switch
          inputProps={{ 'aria-label': 'demo' }}
          name={data.name}
          className={classes.switch}
          onChange={(e) => {
            setFormData({
              ...formData,
              [data.name]: e.target.checked,
            });
          }}
          checked={formData?.[data.name]}
          value={formData[data.name]}
        />
      </div>
    ));

  return (
    <>
      <p className={classes.title}>Details</p>
      <div className={classes.selectBox}>
        <p className={classes.label}>Featured image</p>
        <MediaDisplay
          name={'featured_image'}
          formData={formData}
          setFormData={setFormData}
          setEmbedAction={setEmbedAction}
        />
      </div>
      <Slug formData={formData} setFormData={setFormData} type={type} />
      <div className={classes.selectBox}>
        <p className={classes.label}>Preview link</p>
        <TextField
          type="text"
          value={`${getPreSlugURL(true)}${
            type !== RECIPE_PAGE_TYPE ? formData.slug : formData.permalink
          }`}
          name={'preview_link'}
          className={classes.textField}
          disabled={true}
        />
      </div>
      <Author formData={formData} setFormData={setFormData} />
      {type !== RECIPE_PAGE_TYPE && (
        <div className={classes.selectBox}>
          <p className={classes.label}>Excerpt</p>
          <TextArea
            onChange={(e) => {
              formHandleText(e, setFormData, formData);
            }}
            value={decodeHtml(formData?.excerpt ?? '')}
            name="excerpt"
            className={classes.excerptText}
          />
        </div>
      )}
      {type == POST_PAGE_TYPE && formData.published_at && (
        <div className={classes.selectBox}>
          <p className={classes.label}>Published on</p>
          <TextInput
            type="datetime-local"
            value={formData.published_at}
            name={'published_at'}
            className={clx(classes.input, classes.textField)}
            classNames={{
              inputField: classes.block,
            }}
            onChange={(e) => {
              setFormData({
                ...formData,
                [e.name]: e.value,
              });
            }}
          />
        </div>
      )}
      <div className={classes.switchOptionsParent}>
        {displaySwitchOptions()}
      </div>
    </>
  );
};

export default DetailsForm;
