import { gql } from '@apollo/client';

export const FETCHEVENTS = gql`
  query getEvent($date: String!) {
    events(date: $date) {
      name
      color
      description
      all_day
      start_time
      end_time
    }
  }
`;
