import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useCalendarStyles } from './styles';
import clx from 'classnames';

type CalendarProps = {
  date: Date;
  className?: string;
};

const CalendarComponent: React.FC<CalendarProps> = ({ date, className }) => {
  const classes = useCalendarStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={dayjs(date)}
        className={clx(classes.root, className)}
      />
    </LocalizationProvider>
  );
};

export default React.memo(CalendarComponent);
