import { gql } from '@apollo/client';

export const GET_BIRTHDAYS = gql`
  query getCezanneBirthdays {
    getBirthdays {
      name
      dob
      role
      avatar {
        type
        image
      }
    }
  }
`;
