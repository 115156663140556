import { CardProps } from './types';

export const getInputs = ({
  index,
  image,
  video,
  text,
  enabled,
}: CardProps) => {
  return [
    {
      name: 'Text',
      value: text,
      type: 'text',
      keys: [String(index), 'text'],
    },
    {
      name: 'Desktop Image',
      value: image.src.desktop,
      type: 'file',
      keys: [String(index), 'image', 'src', 'desktop'],
    },
    {
      name: 'Mobile Image',
      value: image.src.mobile,
      type: 'file',
      keys: [String(index), 'image', 'src', 'mobile'],
    },
    {
      name: 'Image Alternative Text',
      value: image.alt,
      type: 'text',
      keys: [String(index), 'image', 'alt'],
    },
    {
      name: 'Tiktok Post Id',
      value: video.src,
      type: 'text',
      keys: [String(index), 'video', 'src'],
    },
    {
      name: 'Enabled',
      value: enabled,
      type: 'checkbox',
      keys: [String(index), 'enabled'],
    },
  ];
};
