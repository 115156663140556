import {
  createTheme,
  Theme as MuiTheme,
  ThemeOptions,
} from '@mui/material/styles';
import { theme as wazTheme } from '@wazobia-tech/wazcom/dist/theme';

type ExtendedThemeOptions = {
  include: { [key: string]: any };
  design: {
    font: { [key: string]: any };
    colors: { [key: string]: string };
    background: { [key: string]: string };
    border: { [key: string]: string };
  };
};

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends ExtendedThemeOptions {}

  // allow configuration using `createMuiTheme`
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends ExtendedThemeOptions {}
}

export type BaseTheme = MuiTheme & ExtendedThemeOptions;

const colors = {
  white: '#fff',
  black: '#000',
};

export const theme: any = {
  direction: 'rtl',
  typography: {
    fontFamily: ['Lato, Diurnal-Light'].join(','),
    fontWeightMedium: 600,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    overline: {},
    pxToRem: () => '',
    htmlFontSize: 12,
    fontSize: 12,
    button: {},
    caption: {},
    h1: {},
    subtitle1: {},
    subtitle2: {},
    h2: {
      fontSize: '3rem',
      fontWeight: 500,
    },
    h3: {},
    h4: {},
    h5: {
      fontSize: '1.4rem',
    },
    h6: {},
    body1: {
      fontSize: '14px',
      fontFamily: 'Diurnal-Light',
      fontWeightMedium: 600,
    },
    body2: {},
  },
  include: {
    '@keyframes gradient': {
      from: { width: 0 },
      to: { width: '100%' },
    },
    ...wazTheme.include,
  },
  design: {
    font: {
      title: 'Diurnal-Light',
      workSans: 'Work Sans, sans-serif',
      montserrat: 'Montserrat, sans-serif',
      diurnal: 'Diurnal-Bold, sans-serif',
      feature: 'Feature, sans-serif',
      universal: 'Universal, sans-serif',
      ...wazTheme.design.font,
    },
    colors: {
      width: '1366px',
      Red01: 'EA3C53',
      blue: '#0889F7',
      lightGrey: '#666666',
      orange: '#E8505B',
      darkBlue: '#274C77',
      red: '#FF0000',
      lightRed: '#FF4C4C',
      danger: '#E71A1F',
      sunRed: '#FF4C4C',
      honey: '#F7FFF7',
      grey: '#3A3335',
      borderGrey: '#E6E6E6',
      chineseWhite: '#E0E0E0',
      fauxGrey: '#5C5C5C',
      maroon: '#A33F50',
      charcoal: '#2A2D34',
      harvestGold: '#F2DBB5',
      ghost: '#B7B7B8',
      jungleNavy: '#0D183D',
      white: '#FFFFFF',
      offWhite: '#D6D6D6',
      fauxBlack: '#121212',
      magicMint: '#FEDAA2',
      whisper: '#EEEEEE',
      darkCharcoal: '#333333',
      black: '#000000',
      widgetGrey: '#F7F7F9',
      lightestGrey: 'rgba(0,0,0,0.1)',
      deepGrey: '#DDDDDD',
      greyLight: '#666666',
      greyDark: '#333333',
      greyPitch: '#F3F3F3',
      titleIcon: '#BDBDBD',
      titleColor: '#828282',
      widgetBorder: '#eeeeee',
      dimGrey: '#ccc',
      scrollBarBackground: 'rgba(0,0,0,0.05)',
      overlayBackground: 'rgba(0,0,0,0.5)',
      scrollBarThumbBackground: 'rgba(0,0,0,0.25)',
      scrollBarTrackBackground: 'rgba(0,0,0,0.01)',
      fullScreenBackground: 'rgba(0,0,0,0.2)',
      faintBlack: 'rgba(0,0,0,0.1)',
      newblack: 'rgba(221, 221, 221, 1)',
      grey50: '#F261F',
      grey100: '#010E05',
      grey150: '#FAFAFA',
      grey250: '#F2F2F2',
      grey200: '#343E37',
      jpGrey300: '#212121',
      jpGrey400: '#F9F9F9',
      grey400: '#FBFBFB',
      grey500: '#4F4F4F',
      grey600: '#1A1B1F',
      grey650: '#676E69',
      grey700: '#343538',
      grey800: '#4D5650',
      grey900: '#F2F2F2',
      grey950: '#F4EFED',
      grey1000: '#808782',
      blue100: '#1551C31A',
      blue200: '#1551C3',
      blue300: '#6C63FF',
      pitchGrey: '#999F9B',
      lidGrey: '#E8E8E8',
      mintGreen: '#A9D3B8',
      green100: '#86FC65',
      pink: '#FCBDC0',
      palePink: '#F4E7E0',
      pink100: '#FF656C',
      pink200: '#FFDCD2',
      skyBlue: '#A1AAFF',
      yellow: '#F6FF4A',
      cyan: '#00FAE8',

      ...wazTheme.design.colors,
      nueGrey: '#BDBDBD',
      shadow100: '#0000000D',
      shadow200: '#000000029',
      purple: '#A1AAFF',
    },
    background: {},
    border: {},
  },
};

export default createTheme(theme);
