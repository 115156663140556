import { useLazyQuery } from '@apollo/client';
import { FETCHEVENTS } from './graphQL';
import { useEffect, useState } from 'react';
import WidgetDataFormat, {
  AnnouncementContainer,
  ArticleList,
} from 'components/Announcement/WidgetDataFormat';
import { parseDate } from 'utilities/date';

export const EventWidget = () => {
  const [fetchEventsQuery, { data, loading, error }] = useLazyQuery(
    FETCHEVENTS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [date] = useState(new Date());
  const [pages, setPages] = useState<number>(1);
  const [events, setEvents] = useState<{ [key: string]: any }[]>([]);

  const dataLimit = 2;

  useEffect(() => {
    const variables = { date: parseDate(date) };
    fetchEventsQuery({ variables });
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (data) {
      const events: { [key: string]: any }[] = data?.events;
      const newEvents: { [key: string]: any }[] = [];
      events.forEach(({ start_time, description, name }) => {
        newEvents.push({ start_time, description, name });
      });
      setEvents(newEvents);
      setPages(Math.ceil(data?.events?.length / dataLimit));
    }
  }, [data]);

  const displayContent = (startIndex: number, endIndex: number) => {
    return events
      ?.slice(startIndex, endIndex)
      ?.map((data: any, index: number) => {
        const newDate = new Date(data.start_time);
        const dateString = newDate.toLocaleString('en-GB');
        return (
          <AnnouncementContainer
            key={index}
            date={dateString.replaceAll('/', '-')}
            title={data.name}
            description={data.description}
          />
        );
      });
  };

  return (
    <>
      <p style={{ fontSize: '10px', padding: '16px 0', width: '90%' }}>
        UPCOMING EVENTS
      </p>
      <WidgetDataFormat
        pages={pages}
        displayContent={displayContent}
        loading={loading}
        dataLimit={dataLimit}
        dataLength={events?.length}
      />
    </>
  );
};
