import { gql } from '@apollo/client';

export const FETCHPAGE = gql`
  query getContentForPages($slug: String!) {
    content(slug: $slug) {
      id
      title
      slug
      content
    }
  }
`;

export const UPDATEPAGE = gql`
  mutation updateContent(
    $id: ID!
    $slug: String
    $title: String
    $content: String
  ) {
    updateContent(id: $id, slug: $slug, title: $title, content: $content) {
      id
    }
  }
`;
