import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  actionButton: {
    justifyContent: 'flex-end',
    margin: theme.spacing(4, 0),
    gap: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      cursor: 'pointer',
    },
  },
  primary: {
    background: `transparent !important`,
    color: `${theme.design.colors.black} !important`,
    border: `1px solid ${theme.design.colors.black} !important`,
  },
  iconShadow: {
    borderRadius: '50%',
    padding: theme.spacing(0.8),
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: 'center',
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.lidGrey}`,
  },
}));
