import React, { FC, useContext, useState, useEffect } from 'react';
import useCreateCategoryStyles from './styles';
import CustomTextField from '../../../common/TextField/TextField';
import { Close } from '@mui/icons-material';
import { formHandleText } from 'utilities/form';
import { Button } from 'components/common/Button/Button';
import Loader from 'components/common/Loader/Loader';
import { Tag } from '@wazobia-tech/wazcom/dist/core';
import { useMutation } from '@apollo/client';
import { notifyError, notifySuccess } from 'utilities/notify';
import { AppStateContext } from 'contexts/provider';
import { blogClient } from 'utilities/apolloClient';
import { CreateCategoryProps } from './type';

const CreateCategory: FC<CreateCategoryProps> = ({
  name,
  handleClose,
  category,
  setRefreshToken,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
}) => {
  const { siteDetails } = useContext(AppStateContext);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [createBlogCategory, { loading }] = useMutation(CREATE_CATEGORY, {
    client: blogClient,
  });
  const [updateBlogCategory, { loading: updatingCategory }] = useMutation(
    UPDATE_CATEGORY,
    {
      client: blogClient,
    }
  );
  const [text, setText] = useState('');
  const classes = useCreateCategoryStyles();
  const blog_uuid = siteDetails?.blog_uuid;

  useEffect(() => {
    if (category) {
      const subCategoriesWithoutTypename = category.subCategories.map(
        (subCategory: { [x: string]: any; __typename: any }) => {
          const { __typename, ...restOfSubCategory } = subCategory;
          return restOfSubCategory;
        }
      );
      setFormData({
        name: category?.name,
        description: category?.description,
        subCategories: subCategoriesWithoutTypename,
      });
    }
  }, [category]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    setText(event.target.value);
  };

  const handleAdd = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.code || event.key;

    if (keyCode === 'Enter') {
      const slug = formData?.name
        ? `/${formData?.name?.toLowerCase()}/${text.toLowerCase()}`
        : `/${text.toLowerCase()}`;
      const subCategories = [
        ...(formData?.subCategories ?? []),
        { name: text, slug },
      ];
      setFormData({ ...formData, subCategories });
      setText('');
    }
  };

  const handleRemove = (subCategoryIndex: number) => {
    const newSubCategories = (formData?.subCategories ?? []).filter(
      (_: { name: string; slug: string; uuid?: string }, index: number) =>
        index !== subCategoryIndex
    );
    setFormData({ ...formData, subCategories: newSubCategories });
  };

  const renderSubCategories = () =>
    formData?.subCategories?.map(
      ({ name }: { name: string; slug: string }, index: number) => (
        <Tag
          key={index}
          name={name}
          handleRemove={() => {
            handleRemove(index);
          }}
        />
      )
    );

  const authorizedToCreateCategory = ['aff8a482-9492-4b2b-923e-4d20cb2a34ff'];

  const checkUserAccess = () => {
    if (
      !siteDetails.url.includes('twistedfood') ||
      (siteDetails.url.includes('twistedfood') &&
        authorizedToCreateCategory.includes(formData.author_uuid))
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!checkUserAccess()) {
      return notifyError("You don't have access to create this category");
    }
    if (!formData['name'])
      return notifyError(
        name === 'category'
          ? `Category Name is Required`
          : `Tag Name is Required`
      );
    if (category) {
      updateBlogCategory({
        variables: {
          ...formData,
          uuid: category?.uuid,
          slug: `/${formData.name}`,
        },
      })
        .then(() => {
          setRefreshToken(true);
          notifySuccess(
            name === 'category' ? 'Category Updated' : 'Tag Updated'
          );
          handleClose();
        })
        .catch(() => {
          notifyError('An Error Occurred');
        });
    } else {
      createBlogCategory({
        variables: {
          ...formData,
          slug: `/${formData.name}`,
          blog_uuid,
        },
      })
        .then(() => {
          setRefreshToken(true);
          notifySuccess(
            name === 'category' ? 'Category Created' : 'Tag Created'
          );
          handleClose();
        })
        .catch(() => {
          notifyError('An Error Occurred');
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <p className={classes.title}>
          {name === 'category'
            ? category
              ? 'Edit Category'
              : 'Create Category'
            : category
            ? 'Edit Tag'
            : 'Create Tag'}
        </p>
        <Close className={classes.closeIcon} onClick={handleClose} />
      </div>
      <>
        <div className={classes.selectBox}>
          <p className={classes.label}>Category Name</p>
          <CustomTextField
            onChange={(e: React.ChangeEvent<any>) => {
              formHandleText(e, setFormData, formData);
            }}
            type="text"
            value={formData?.name ?? ''}
            name="name"
            className={classes.textField}
          />
        </div>
        {name === 'category' && (
          <div className={classes.selectBox}>
            <p className={classes.label}>Add Sub category </p>
            <CustomTextField
              onKeyDown={handleAdd}
              onChange={handleChange}
              type="text"
              value={text}
              name="subCategories"
              className={classes.textField}
            />
            {formData?.subCategories?.length > 0 && (
              <div className={classes.categorySubs}>
                {renderSubCategories()}
              </div>
            )}
          </div>
        )}
        <div className={classes.selectBox}>
          <p className={classes.label}>Description</p>
          <CustomTextField
            onChange={(e: React.ChangeEvent<any>) => {
              formHandleText(e, setFormData, formData);
            }}
            type="text"
            value={formData?.description ?? ''}
            name="description"
            multiline={true}
            rows={10}
            className={classes.createTextfield}
          />
        </div>
        <div className={classes.formActions}>
          <div style={{ position: 'relative' }}>
            <Button
              className={classes.btn}
              title={
                name === 'category'
                  ? category
                    ? 'Update Category'
                    : 'Create Category'
                  : category
                  ? 'Update Tag'
                  : 'Create Tag'
              }
              onClick={handleSubmit}
              loading={loading}
            />
            <Loader
              display={loading || updatingCategory}
              className={classes.loader}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default CreateCategory;
