import {
  TableColumn,
  TableConditionFilter,
  Tag,
} from '@wazobia-tech/wazcom/dist/core';
import useCategoryStyles from 'components/Category/styles';

export const useGetTableColumns = (
  setCategory: React.Dispatch<
    React.SetStateAction<
      | {
          [key: string]: string;
        }
      | null
      | undefined
    >
  >,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenDetailsModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  return [
    {
      name: 'Main Category',
      key: 'name',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (category) => {
            setCategory(category);
            setOpenDetailsModal(true);
          },
        },
        {
          name: 'Delete',
          func: (category) => {
            setCategory(category);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Posts', key: 'posts', type: 'string' },
    { name: 'Description', key: 'description', type: 'string' },
  ];
};

export const useGetTableRows = () => {
  const classes = useCategoryStyles();

  return (data: any[]) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['name'] = (
        <p
          style={{
            fontWeight: 'bold',
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
          }}
        >
          {row['name']}
        </p>
      );
      parsedRow['posts'] = row?.post_count as number;
      parsedRow['description'] = row?.description ? row['description'] : 'None';
      parsedRow['identifier'] = row as any;
      parsedRows.push(parsedRow);
    }
    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Tags', condition: (posts: any) => posts },
  {
    name: 'Published',
    condition: (posts: any) => posts.filter((post: any) => post.status),
  },
  {
    name: 'Drafted',
    condition: (posts: any) => posts.filter((post: any) => !post.status),
  },
];
