import { TableColumn } from '@wazobia-tech/wazcom/dist/core';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { cpasClient } from 'utilities/apolloClient';
import { GETALLCHANNELSTORIES, GETCHANNELS } from './graphQl/graphQl';

export const useGetTableColumns = (
  setStoryId: React.Dispatch<React.SetStateAction<string>>,
  setOpenDetailsModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  return [
    {
      name: 'Story Title',
      key: 'name',
      type: 'string',
      actions: [
        {
          name: 'View Details',
          func: (story_id) => {
            setStoryId(story_id);
            setOpenDetailsModal(true);
          },
        },
      ],
    },
    {
      name: 'Paid Impressions',
      key: 'metrics.sold_impressions',
      type: 'string',
    },
    {
      name: 'Gross Revenue',
      key: 'metrics.revenue',
      type: 'number',
    },
    {
      name: 'eCPM',
      key: 'metrics.ecpm',
      type: 'number',
    },
    // {
    //   name: 'Average Watch Time (secs)',
    //   key: 'metrics.avg_time_viewed',
    //   type: 'string',
    // },
    {
      name: 'Unique Viewers',
      key: 'metrics.unique_dau',
      type: 'number',
    },
    {
      name: 'Unique Subscribers',
      key: 'metrics.edition_subscribe_count',
      type: 'number',
    },
  ];
};

export const useGetTableRows = () => {
  return (data: any[]) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow.name = row?.name ? row.name : 'None';
      parsedRow['metrics.sold_impressions'] =
        row.metrics.sold_impressions.toLocaleString();
      parsedRow['metrics.unique_dau'] = row.metrics.unique_dau.toLocaleString();
      // parsedRow['metrics.avg_time_viewed'] = row['metrics'][
      //   'avg_time_viewed'
      // ].toLocaleString(undefined, {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // });
      parsedRow['metrics.edition_subscribe_count'] =
        row.metrics.edition_subscribe_count.toLocaleString();
      parsedRow['metrics.ecpm'] = `$${row.metrics.ecpm.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`;
      parsedRow['metrics.revenue'] = `$${row.metrics.revenue.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`;
      parsedRow.identifier = row.story_id;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const useFetchChannelsData = () => {
  const [channelsData, setchannelsData] = useState<{ [key: string]: any }[]>(
    []
  );
  const {
    data: data,
    error: error,
    loading: loading,
  } = useQuery(GETCHANNELS, {
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  useEffect(() => {
    if (data && data.getChannels) {
      setchannelsData(data.getChannels);
    }
  }, [data]);

  return { channelsData, isLoading: loading, error };
};

export const convertData = (data: any[]): any[] => {
  const convertedData: {
    [key: string]: {
      label: string;
      data: { primary: string; secondary: number; radius?: undefined }[];
    };
  } = {};

  data.forEach(({ day, metrics }) => {
    const { __typename, ...labels } = metrics;

    Object.entries(labels).forEach(([label, value]) => {
      // Rename the labels here
      if (label === 'revenue') {
        label = 'Gross Revenue';
      } else if (label === 'sold_impressions') {
        label = 'Paid Impressions';
      } else if (label === 'avg_time_viewed') {
        label = 'Average Watch Time';
      }

      if (!convertedData[label]) {
        convertedData[label] = { label, data: [] };
      }

      const primary: string = day;
      const secondary: number = value as number;
      const radius = undefined;

      convertedData[label].data.push({ primary, secondary, radius });
    });
  });

  return Object.values(convertedData);
};

export const convertEcpmAndRevenueData = (data: any[]): any[] => {
  const convertedData: {
    [key: string]: {
      label: string;
      data: { primary: string; secondary: number; radius?: undefined }[];
    };
  } = {};

  data.forEach(({ day, metrics }) => {
    const ecpmValue = metrics.ecpm;
    const revenueValue = metrics.revenue;

    if (ecpmValue !== undefined) {
      if (!convertedData.eCPM) {
        convertedData.eCPM = { label: 'eCPM', data: [] };
      }

      const primary: string = day;
      const secondary: number = ecpmValue;
      const radius = undefined;

      convertedData.eCPM.data.push({ primary, secondary, radius });
    }

    if (revenueValue !== undefined) {
      if (!convertedData['Gross Revenue']) {
        convertedData['Gross Revenue'] = { label: 'Gross Revenue', data: [] };
      }

      const primary: string = day;
      const secondary: number = revenueValue;
      const radius = undefined;

      convertedData['Gross Revenue'].data.push({ primary, secondary, radius });
    }
  });

  return Object.values(convertedData);
};

export const renameProperty = (key: string) => {
  switch (key) {
    case 'avg_time_viewed':
      return 'Average Time Viewed';
    case 'ecpm':
      return 'Total eCPM';
    case 'dauSubscribers':
      return 'Total Subscribers';
    case 'revenue':
      return 'Total Revenue';
    case 'sold_impressions':
      return 'total paid impression';
    case 'start_date':
      return 'Start Date';
    case 'unique_dau':
      return 'Unique DAU';
    default:
      return key;
  }
};

export function formatDate(inputDate: any) {
  if (inputDate === '') {
    return '';
  }
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
