import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { AppStateContext } from '../../contexts/provider';
import {
  ADDMRSSITEM,
  CREATEFEED,
  DELETEMRSSITEM,
  UPDATEFEED,
  UPDATEMRSSITEM,
} from '../../utilities/graphql/mutations';
import {
  GETFEEDCONTENTSBYUUID,
  GETFEEDINFOBYUUID,
  GETSITEFEEDS,
} from '../../utilities/graphql/queries';
import { notifyError, notifySuccess } from '../../utilities/notify';
import { Accordion } from '../common/Accordion/Accordion';
import { Button } from '../common/Button/Button';
import FileField from '../common/FileField/FileField';
import { Select } from '../common/Select/Select';
import Spinner from '../common/Spinner/Spinner';
import TextArea from '../common/TextArea/TextArea';
import TextField from '../common/TextField/TextField';
import { initialData } from './data';
import { useMrssPageStyles } from './styles';
import { MrssItemType } from './types';

const Mrss = () => {
  const classes = useMrssPageStyles();
  const { siteDetails } = useContext(AppStateContext);

  const [, setFormData] = useState<{ [key: string]: string }>({
    articleUrl: '',
    videoUrl: '',
  });

  const [feedData, setFeedData] = useState<{ [key: string]: string }>({
    title: '',
    link: '',
    managingEditor: '',
    publishDate: '',
    lastBuildDate: '',
    logoUrl: '',
    desc: '',
  });
  const [mrssItemData, setMrssItemData] = useState<MrssItemType[]>([
    { ...initialData },
  ]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [siteFeed, setSiteFeed] = useState<{ [key: string]: string }[]>([]);
  const [feedCreationSuccess, setFeedCreationSuccess] =
    useState<boolean>(false);

  const { data, loading, refetch } = useQuery(GETSITEFEEDS, {
    variables: { site_uuid: siteDetails.uuid, first: 50 },
    fetchPolicy: 'network-only',
  });
  const { data: FeedByUuid, loading: feedLoading } = useQuery(
    GETFEEDINFOBYUUID,
    {
      variables: { uuid: selectedOption },
      fetchPolicy: 'network-only',
    }
  );
  const { data: ItemData } = useQuery(GETFEEDCONTENTSBYUUID, {
    variables: { feed_uuid: selectedOption, first: 10 },
    fetchPolicy: 'network-only',
  });
  const [createFeed, { loading: createLoading }] = useMutation(CREATEFEED);
  const [updateFeed, { loading: updateFeedLoading }] = useMutation(UPDATEFEED);
  const [addMrssITem, { loading: addItemLoading }] = useMutation(ADDMRSSITEM);
  const [deleteMrssITem, { loading: deleteItemLoading }] =
    useMutation(DELETEMRSSITEM);
  const [updateMrssITem, { loading: updateItemLoading }] =
    useMutation(UPDATEMRSSITEM);

  const getFeedOptions = () => {
    const feedOpt: { [key: string]: string } = {
      'Select Feed': '',
    };
    siteFeed?.forEach((feed) => {
      feedOpt[feed?.title as keyof { [key: string]: string }] = feed?.uuid;
    });

    return feedOpt;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFeedChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFeedData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleMrssItemChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number
  ) => {
    const { name, value } = e.target;
    setMrssItemData((prevState: MrssItemType[]) => {
      const items = prevState;
      const item = {
        ...prevState[idx],
        [name]: value,
      };
      items[idx] = item;
      return [...items];
    });
  };

  const handleOptionsChange = (
    e: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    setSelectedOption(e.target.value);
  };

  const handleFeedSubmit = async () => {
    if (createLoading || updateFeedLoading) return;
    try {
      const parsedPubDate =
        feedData.publishDate?.replace('T', ' ').split(':').length > 2
          ? feedData.publishDate?.replace('T', ' ')
          : feedData.publishDate?.replace('T', ' ') + ':00';
      const parsedLastBuildDate =
        feedData.lastBuildDate?.replace('T', ' ').split(':').length > 2
          ? feedData.lastBuildDate?.replace('T', ' ')
          : feedData.lastBuildDate?.replace('T', ' ') + ':00';

      const variables = {
        uuid: feedData?.uuid,
        site_uuid: siteDetails.uuid,
        title: feedData.title,
        link: feedData.link,
        pubDate: parsedPubDate,
        lastBuildDate: parsedLastBuildDate,
        managingEditor: feedData.managingEditor,
        description: feedData.desc,
        image: feedData.logoUrl,
      };

      if (feedData?.uuid && feedData?.uuid !== '') {
        await updateFeed({ variables });
      } else {
        await createFeed({ variables });
        setFeedData({
          title: '',
          link: '',
          managingEditor: '',
          publishDate: '',
          lastBuildDate: '',
          logoUrl: '',
          desc: '',
        });
      }
      notifySuccess('Submitted successfully.');
      setFeedCreationSuccess(true);
    } catch (error) {
      notifyError(
        'Something went wrong, Please make sure are fields are correctly filled'
      );
    }
  };

  const handleMrssItemSubmit = async () => {
    if (addItemLoading || updateItemLoading) return;
    try {
      Promise.all(
        mrssItemData.map(async (item) => {
          const parsedPubDate =
            item.publishDate?.replace('T', ' ').split(':').length > 2
              ? item.publishDate?.replace('T', ' ')
              : item.publishDate?.replace('T', ' ') + ':00';

          const variables = {
            uuid: item?.uuid,
            feed_uuid: FeedByUuid?.getFeedInfoByUuid?.uuid,
            title: item.title,
            pubDate: parsedPubDate,
            link: item.link,
            description: item.desc,
            category: item?.category,
            url: item?.videoUrl,
            title_media: item?.mediaTitle,
            description_media: item?.mediaDesc,
            thumbnail: item?.thumbnail,
            author: item?.author,
            copyright_url: item?.copyright,
          };

          if (item?.uuid && item?.uuid !== '') {
            return updateMrssITem({ variables });
          } else {
            return addMrssITem({ variables });
          }
        })
      ).then(() => {
        notifySuccess('Submitted successfully.');
        setFeedCreationSuccess(true);
      });
    } catch (error) {
      notifyError(
        'Something went wrong, Please make sure are fields are correctly filled'
      );
    }
  };

  const handleDeleteItem = async (index: number, uuid: string) => {
    if (confirm('Are you sure you want to delete')) {
      try {
        if (uuid) {
          const variables = {
            uuid,
          };
          await deleteMrssITem({ variables });
        }

        setMrssItemData((prevState: MrssItemType[]) => {
          const items = prevState;
          items.splice(index, 1);
          return [...items];
        });
        notifySuccess('Deleted successfully.');
      } catch (error) {
        notifyError('Something went wrong, try again later');
      }
    }
  };

  useEffect(() => {
    if (feedCreationSuccess) {
      refetch({ site_uuid: siteDetails.uuid, first: 10 });
      setFeedCreationSuccess(false);
    }
    setSiteFeed(data?.getSiteFeeds?.data);
  }, [loading, feedCreationSuccess]);

  useEffect(() => {
    setSiteFeed(data?.getSiteFeeds?.data);
  }, [data?.getSiteFeeds?.data]);

  useEffect(() => {
    setFeedData({
      uuid: FeedByUuid?.getFeedInfoByUuid?.uuid ?? '',
      title: FeedByUuid?.getFeedInfoByUuid?.title ?? '',
      link: FeedByUuid?.getFeedInfoByUuid?.link ?? '',
      managingEditor: FeedByUuid?.getFeedInfoByUuid?.managingEditor ?? '',
      publishDate: FeedByUuid?.getFeedInfoByUuid?.pubDate ?? '',
      lastBuildDate: FeedByUuid?.getFeedInfoByUuid?.lastBuildDate ?? '',
      logoUrl: FeedByUuid?.getFeedInfoByUuid?.image ?? '',
      desc: FeedByUuid?.getFeedInfoByUuid?.description ?? '',
    });
  }, [FeedByUuid?.getFeedInfoByUuid]);

  useEffect(() => {
    const data = ItemData?.getFeedContentsByUuid?.data?.map(
      (item: { [key: string]: any }) => {
        return {
          uuid: item?.uuid ?? '',
          title: item?.title ?? '',
          link: item?.link ?? '',
          publishDate: item?.pubDate ?? '',
          desc: item?.description ?? '',
          mediaTitle: item?.mrssMedia?.title ?? '',
          videoUrl: item?.mrssMedia?.url ?? '',
          category: item?.mrssMedia?.category ?? '',
          author: item?.mrssMedia?.author ?? '',
          copyright: item?.mrssMedia?.copyright_url ?? '',
          mediaDesc: item?.mrssMedia?.description ?? '',
          thumbnail: item?.mrssMedia?.thumbnail ?? '',
        };
      }
    );
    if (data && data.length > 0) {
      setMrssItemData(data);
    } else {
      setMrssItemData([{ ...initialData }]);
    }
  }, [ItemData?.getFeedContentsByUuid?.data]);

  return (
    <div>
      <div className={classes.heading}>
        <h3>MRSS</h3>
      </div>
      <div>
        <div>
          <div className={classes.container}>
            <div className={classes.feedSubmit}>
              <div className={classes.dropdown}>
                {siteFeed && siteFeed.length > 0 && (
                  <div className={classes.feedDropdown}>
                    <div>Feed:</div>
                    <Select
                      value={selectedOption}
                      options={getFeedOptions()}
                      onChange={handleOptionsChange}
                    />
                  </div>
                )}
              </div>
              <Button
                title="Submit"
                loading={createLoading || updateFeedLoading}
                onClick={handleFeedSubmit}
              />
            </div>
            <h4 className={classes.subTitle}>Feed</h4>
            <div>
              <div className={classes.dualTextFields}>
                <TextField
                  name="title"
                  label="Title"
                  type="text"
                  value={feedData.title}
                  onChange={handleFeedChange}
                />
                <TextField
                  name="link"
                  label="Link"
                  type="text"
                  value={feedData.link}
                  onChange={handleFeedChange}
                />
              </div>
              <div className={classes.textField}>
                <TextField
                  name="managingEditor"
                  label="Managing Editor"
                  type="text"
                  value={feedData.managingEditor}
                  onChange={handleFeedChange}
                />
              </div>
              <div className={classes.dualTextFields}>
                <TextField
                  type="datetime-local"
                  label="Publish Date"
                  value={feedData.publishDate}
                  name="publishDate"
                  onChange={handleFeedChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="datetime-local"
                  label="Last Build Date"
                  value={feedData.lastBuildDate}
                  name="lastBuildDate"
                  onChange={handleFeedChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.dualTextFields}>
                <FileField
                  name={'logoUrl'}
                  label={'Logo'}
                  handleChange={(url: string) => {
                    setFeedData((prevState) => {
                      return {
                        ...prevState,
                        logoUrl: url,
                      };
                    });
                  }}
                  className={classes.logoField}
                  url={feedData.logoUrl}
                />
                <div className={classes.w50}>
                  <TextArea
                    aria-label="minimum height"
                    minRows={6}
                    name={'desc'}
                    placeholder=""
                    style={{ width: '100%' }}
                    value={feedData.desc}
                    label={'Description'}
                    onChange={handleFeedChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {Boolean(selectedOption) && (
            <div className={classes.container}>
              <div className={classes.mrssItemHeaderSection}>
                <Button
                  title="Submit"
                  loading={addItemLoading}
                  onClick={handleMrssItemSubmit}
                />
              </div>
              <div>
                {[...Array((mrssItemData ?? []).length)].map((item, index) => {
                  return (
                    <div className={classes.accordionContainer} key={index}>
                      <Accordion
                        key={index}
                        title={`Item ${index + 1}`}
                        isDeletable={index !== 0}
                        handleRemove={() =>
                          handleDeleteItem(index, mrssItemData?.[index]?.uuid)
                        }
                      >
                        <>
                          <div>
                            <h4 className={classes.subTitle}>Item</h4>
                            <div>
                              <div className={classes.dualTextFields}>
                                <TextField
                                  name="title"
                                  label="Title"
                                  type="text"
                                  value={mrssItemData?.[index]?.title}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                                <TextField
                                  name="link"
                                  label="Link"
                                  type="text"
                                  value={mrssItemData?.[index]?.link}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                              </div>
                              <div className={classes.dualTextFields}>
                                <TextField
                                  type="datetime-local"
                                  label="Publish Date"
                                  value={mrssItemData?.[index]?.publishDate}
                                  name="publishDate"
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <TextArea
                                  aria-label="minimum height"
                                  minRows={6}
                                  name={'desc'}
                                  placeholder=""
                                  style={{ width: '100%' }}
                                  value={mrssItemData?.[index]?.desc}
                                  label={'Description'}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div>
                            <h4 className={classes.subTitle}>Media</h4>
                            <div>
                              <div className={classes.dualTextFields}>
                                <TextField
                                  name="mediaTitle"
                                  label="Title"
                                  type="text"
                                  value={mrssItemData?.[index]?.mediaTitle}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                                <TextField
                                  name="videoUrl"
                                  label="Video Url"
                                  type="text"
                                  value={mrssItemData?.[index]?.videoUrl}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                              </div>
                              <div className={classes.dualTextFields}>
                                <TextField
                                  name="category"
                                  label="Category"
                                  type="text"
                                  value={mrssItemData?.[index]?.category}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                                <TextField
                                  name="author"
                                  label="Author/Producer"
                                  type="text"
                                  value={mrssItemData?.[index]?.author}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                              </div>
                              <div>
                                <TextField
                                  name="copyright"
                                  label="Copyright Url"
                                  type="text"
                                  value={mrssItemData?.[index]?.copyright}
                                  onChange={(e) =>
                                    handleMrssItemChange(e, index)
                                  }
                                />
                              </div>
                              <div className={classes.dualTextFields}>
                                <FileField
                                  name={'thumbnail'}
                                  label={'Thumbnail'}
                                  handleChange={(url: string) => {
                                    setMrssItemData(
                                      (prevState: MrssItemType[]) => {
                                        const items = prevState;
                                        const item = {
                                          ...prevState[index],
                                          thumbnail: url,
                                        };
                                        items[index] = item;
                                        return [...items];
                                      }
                                    );
                                  }}
                                  url={mrssItemData?.[index]?.thumbnail}
                                />
                                <div className={classes.w50}>
                                  <TextArea
                                    aria-label="minimum height"
                                    minRows={6}
                                    name={'mediaDesc'}
                                    placeholder=""
                                    style={{ width: '100%' }}
                                    value={mrssItemData?.[index]?.mediaDesc}
                                    label={'Description'}
                                    onChange={(e) =>
                                      handleMrssItemChange(e, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </Accordion>
                    </div>
                  );
                })}

                <Button
                  variant="outlined"
                  onClick={() => {
                    setMrssItemData((prev) => {
                      return [...prev, initialData];
                    });
                  }}
                >
                  <AiOutlinePlus size={15} />
                  Add row
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Spinner
        display={
          loading ||
          updateFeedLoading ||
          updateItemLoading ||
          deleteItemLoading ||
          feedLoading
        }
      />
    </div>
  );
};

export default Mrss;
