import { useContext, useState } from 'react';
import { ClassNameMap } from '@mui/material';
import { Route, useLocation } from 'react-router-dom';
import Tab from '../components/common/Tab/Tab';
import TabParent from '../components/common/TabParent/TabParent';
import { TabsProps } from 'components/PortalDashboard/data';
import { hasPermission } from './permissions';
import { AppStateContext } from 'contexts/provider';

export const useDisplayRoutes = () => {
  return (routes: { route: string; component: JSX.Element }[]) => {
    return routes.map(({ route: path, component }, index) => (
      <Route key={index} path={path} element={component} />
    ));
  };
};

interface TabProps {
  key: string;
  children?: unknown;
}

export const getNavs = (
  tabs: TabProps[],
  nav?: string,
  active?: boolean
): { [key: string]: boolean } => {
  const parentTabs = tabs
    .filter(({ children }) => children)
    .map(({ key }) => key);

  const initialNavs: { [key: string]: boolean } = {};
  parentTabs.forEach((tab) => {
    if (nav) {
      initialNavs[nav] = active as boolean;
    } else {
      initialNavs[tab] = false;
    }
  });

  return initialNavs;
};

export const useDisplayTabs = () => {
  const location = useLocation();
  const [, setChildrenActive] = useState<boolean>(false);
  const { user } = useContext(AppStateContext);
  return (
    tabs: TabsProps[],
    classes: ClassNameMap<string>,
    navs: { [key: string]: boolean },
    setNavs: (navs: { [key: string]: boolean }) => void,
    navigate: (route: string, isChild?: boolean) => void
  ) => {
    return tabs.map(
      ({ icon, name, route, key, children, isParent, isAdmin }, index) => {
        if (isAdmin && !hasPermission(user.id, 'admin')) {
          return;
        }
        if (!children) {
          if (isParent) {
            return (
              <TabParent
                key={key}
                index={index}
                name={name}
                handleClick={() => {
                  setNavs(getNavs(tabs, key, !navs[key]));
                  setChildrenActive(true);
                  navigate(route as string);
                  setChildrenActive(false);
                }}
                icon={icon}
                navigate={() => {
                  navigate(route as string);
                  setChildrenActive(false);
                  setNavs(getNavs(tabs, key, navs[key]));
                }}
                tabs={[]}
                active={navs[key]}
                childActive={false}
                classes={classes}
              />
            );
          }
          return (
            <Tab
              key={key}
              name={name}
              identifier={key}
              navigate={() => {
                navigate(route as string);
                setChildrenActive(false);
                setNavs(getNavs(tabs, key, navs[key]));
              }}
              classes={classes}
            />
          );
        }

        const getChildrenKeys = () => {
          const childKeys: string[] = [];
          children.forEach((tab: unknown) => {
            if (typeof tab === 'object' && tab !== null && 'key' in tab) {
              const tabkey = tab.key;
              if (Array.isArray(tabkey)) {
                tabkey.forEach((value: unknown) => {
                  if (typeof value === 'string') {
                    childKeys.push(value);
                  }
                });
              } else if (typeof tabkey === 'string') {
                childKeys.push(tabkey);
              }
            }
          });
          return childKeys;
        };

        const locationSplitArray = location.pathname.split('/');
        const activeKey = [
          locationSplitArray[locationSplitArray.length - 2],
          locationSplitArray[locationSplitArray.length - 1],
        ].join('/');
        const childrenKeys = getChildrenKeys();

        return (
          <TabParent
            key={index}
            index={index}
            name={name}
            handleClick={() => {
              setNavs(getNavs(tabs, key, !navs[key]));
              setChildrenActive(true);
            }}
            icon={icon}
            navigate={navigate}
            tabs={children}
            active={navs[key]}
            childActive={childrenKeys.includes(activeKey)}
            classes={classes}
          />
        );
      }
    );
  };
};
