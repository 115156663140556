import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useSectionStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    fontSize: theme.typography.fontSize,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.design.colors.offWhite}`,
      marginBottom: theme.spacing(4),
    },
  },
  itemsParent: {},
  hasMulitple: {
    flex: 0.8,
    padding: theme.spacing(2, 3, 2),
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  rowsContainer: {},
  row: {
    width: '100%',
  },
  rowNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.design.colors.widgetGrey}`,
    height: '100%',
  },
  addMoreButton: {
    margin: theme.spacing(1, 0, 0),
    fontSize: theme.typography.fontSize + 2,
  },
  inputRow: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    '& $row': {
      width: '100%',
    },
  },
  delete: {
    display: 'flex',
    color: theme.design.colors.sunRed,
    cursor: 'pointer',
  },
  firstRow: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
  accordion: {
    marginBottom: theme.spacing(2),
    '&::before': {
      display: 'none',
    },
  },
  fileField: {
    width: '50%',
  },
}));
