import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { ClickAwayListener } from '@mui/base';
import { sections } from 'components/Nav/data';
import { AppStateContext } from 'contexts/provider';
import { useLogout } from 'components/Nav/Nav';
import clx from 'classnames';
import NavProps from 'types/NavType';
import { ArrowBack } from '@mui/icons-material';
import { Button } from 'components/common/Button/Button';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { hasPermission } from 'utilities/permissions';
import { trim } from 'lodash';

const DashboardNav: React.FC<NavProps> = ({ showLinks = true }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const logout = useLogout();
  const { user } = useContext(AppStateContext);
  const [open, setOpen] = React.useState(false);

  const displaySections = () =>
    sections
      .filter((section) => {
        if (section.name === 'Admin') {
          return true;
        }
        return hasPermission(user.id, section.name);
      })
      .map((section, index) => (
        <Link key={index} className="link" to={`${trim(import.meta.env.VITE_APP_URL, '/')}/${trim(section.route, '/')}`}>
          <span>{section.name}</span>
          <IoIosArrowRoundForward className="icon" size={18} />
        </Link>
      ));

  const links: string[] = [
    'Welcome',
    'Culture',
    'HR',
    'Office',
    'Finance',
    'FAQ',
  ];

  const displayLinks = () => {
    return links.map((link: string, index: number) => {
      return (
        <div
          key={index}
          className={clx(
            classes.navLink,
            location.pathname?.split('/')?.[1].includes(link.toLowerCase()) &&
              classes.navLinkActive
          )}
        >
          <a href={`/${link.toLowerCase()}`}>{link}</a>
        </div>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const redirect = (parent: string, link: string) => {
    let formattedString = link.toLowerCase();
    formattedString = `/${formattedString.replace(/ /g, '-')}`;
    navigate(`${formattedString}`);
  };

  const profileDetails = [
    user?.email,
    user.position ?? 'content.creator ',
    user.location ?? 'London, U.K.',
  ];

  return (
    <>
      <nav className={classes.landingNav}>
        <div className={classes.content}>
          {showLinks && (
            <div>
              <img
                onClick={() => {
                  navigate('/');
                }}
                src="https://jungle-portal.s3.us-west-1.amazonaws.com/icons/logo.svg"
                alt="Jungle Creations Logo"
                className={classes.gorillaIcon}
              />
            </div>
          )}
          {!showLinks && (
            <div className={classes.backIcon} onClick={() => navigate('/')}>
              <ArrowBack />
            </div>
          )}
          {showLinks && displayLinks()}
          {showLinks && (
            <div className={clx(classes.rightSection)}>
              {showLinks && (
                <div className={classes.profileParent} onClick={handleToggle}>
                  <div className={classes.profileCard}>
                    <img
                      src={parseRecursive(user.avatar)?.src}
                      className={classes.navImage}
                      alt="user name"
                    />
                    {open ? (
                      <ClickAwayListener onClickAway={handleClose}>
                        <div className={classes.profile}>
                          <img
                            src={parseRecursive(user.avatar)?.src}
                            alt="user name"
                            className={classes.navImage_profile}
                          />
                          <h3>{user.name}</h3>
                          <p className={classes.profile_moreDetails}>
                            {profileDetails.map((item, index) => (
                              <span
                                className={
                                  index === profileDetails.length - 1
                                    ? 'noBorder'
                                    : ''
                                }
                              >
                                {item}
                              </span>
                            ))}
                          </p>
                          <div className={clx(classes.ctaButton, 'button')}>
                            <Button
                              title={'Manage profile'}
                              className={classes.primary}
                              loading={false}
                              variant="outlined"
                              onClick={() => {
                                navigate('/admin/profile');
                              }}
                            />
                            {/* <Button
                              title={'Connect on slack'}
                              loading={false}
                            /> */}
                          </div>
                          {displaySections()}
                          <p onClick={logout} className="link">
                            Logout
                          </p>
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default DashboardNav;
