import { ClickAwayListener } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FaMoneyCheck } from 'react-icons/fa';
import { FiBriefcase, FiLayers, FiLogIn, FiUsers } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { AppStateContext } from '../../contexts/provider';
import NavProps from '../../types/NavType';
import NavContent from './NavContent/NavContent';
import useStyles from './styles';
import Cookie from 'universal-cookie';
import { sections, subLinks } from './data';
import clx from 'classnames';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import { Button } from 'components/common/Button/Button';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { hasPermission } from 'utilities/permissions';
import OngoingAnalysisButton from './NavContent/OngoingAnalysisButton';
import { useCommentAnalysis } from 'contexts/ca';

export const useLogout = () => {
  return async () => {
    const cookie = new Cookie();
    const allCookies: { [key: string]: any } = cookie.getAll();
    localStorage.clear();

    await new Promise(() => {
      const cookies = Object.entries(allCookies);
      cookies.map((cook) => {
        cookie.remove(cook[0]);
        return cook;
      });
      history.go(0);
    });
  };
};

const Nav: React.FC<NavProps> = ({ showLinks = false }) => {
  const [activeLink, setActiveLink] = useState<string>('');
  const { user } = useContext(AppStateContext);
  const { analyzingPostItems } = useCommentAnalysis();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const logout = useLogout();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const links: { [key: string]: any } = {
    Welcome: <FiLogIn className="icon" />,
    Culture: <FiLayers className="icon" />,
    HR: <FiUsers className="icon" />,
    Office: <FiBriefcase className="icon" />,
    Finance: <FaMoneyCheck className="icon" />,
  };

  const redirect = (parent: string, link: string) => {
    let formattedString = link.toLowerCase();
    formattedString = `/${formattedString.replace(/ /g, '-')}`;
    navigate(`${formattedString}`);
  };

  const displayLinks = () => {
    return Object.keys(links).map((link: string, index: number) => {
      return (
        <div
          key={index}
          onMouseEnter={() => {
            setActiveLink(link.toLowerCase());
          }}
          className={`${classes.nav__link} ${
            activeLink === link.toLowerCase() ? classes.nav__link_active : null
          }`}
        >
          {links[link]}
          <p>{link}</p>
        </div>
      );
    });
  };

  const displaySections = () =>
    sections
      .filter((section) => {
        if (section.name === 'Admin') {
          return true;
        }
        return hasPermission(user.id, section.name);
      })
      .map((section, index) => (
        <Link key={index} className="link" to={section.route}>
          <span>{section.name}</span>
          <IoIosArrowRoundForward className="icon" size={18} />
        </Link>
      ));

  const displayActivePage = () => {
    if (location.pathname.includes('cms')) return 'CMS';
    if (location.pathname.includes('admin')) return 'Admin';
    if (location.pathname.includes('cpas')) return 'CPAS';
    return;
  };

  const profileDetails = [
    user?.email,
    user.position ?? 'content.creator ',
    user.location ?? 'London, U.K.',
  ];

  return (
    <div className={`${classes.nav} ${!showLinks && classes.nav__fixed}`}>
      <div
        className={clx(
          classes.nav__content,
          location.pathname.includes('cpas') && classes.noMargin
        )}
        onMouseLeave={() => {
          setActiveLink('');
        }}
      >
        <div className={classes.nav__links}>
          {showLinks ? displayLinks() : <NavContent />}
        </div>
        {analyzingPostItems.length > 0 && <OngoingAnalysisButton />}

        <div className={classes.profileParent} onClick={handleToggle}>
          <p className={classes.navInfo}> {displayActivePage()}</p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img
              src={parseRecursive(user.avatar)?.src}
              className={classes.navImage}
              alt="user name"
            />
            {open ? (
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.profile}>
                  <img
                    src={parseRecursive(user.avatar)?.src}
                    alt="user name"
                    className={classes.navImage_profile}
                  />
                  <h3>{user.name}</h3>
                  <p className={classes.profile_moreDetails}>
                    {profileDetails.map((item, index) => (
                      <span
                        className={
                          index === profileDetails.length - 1 ? 'noBorder' : ''
                        }
                      >
                        {item}
                      </span>
                    ))}
                  </p>
                  <div className={clx(classes.ctaButton, 'button')}>
                    <Button
                      title={'Manage profile'}
                      className={classes.primary}
                      loading={false}
                      variant="outlined"
                      onClick={() => {
                        navigate('/admin/profile');
                      }}
                    />
                    {/* <Button
                              title={'Connect on slack'}
                              loading={false}
                            /> */}
                  </div>
                  {displaySections()}
                  <p onClick={logout} className="link">
                    Logout
                  </p>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>

        {activeLink !== '' && (
          <div className={classes.nav__sub_links}>
            {subLinks[activeLink].map((link, index) => {
              return (
                <div
                  key={index}
                  onClick={() => redirect(activeLink, link)}
                  className={classes.sub_link_hover}
                >
                  {link}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;
