import { gql } from '@apollo/client';

export const CORE_IMAGE_FIELDS = gql`
  fragment CoreImageFields on Image {
    title
    uuid
    url
    caption
    alternative_text
    metadata
    created_at
    updated_at
  }
`;

export const GET_IMAGES = gql`
  ${CORE_IMAGE_FIELDS}
  query GqlGetImages(
    $library_uuid: String!
    $page: Int
    $count: Int
    $search: String
  ) {
    getImagesByLibraryUuid(
      input: {
        library_uuid: $library_uuid
        page: $page
        count: $count
        search: $search
      }
    ) {
      images {
        ...CoreImageFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  ${CORE_IMAGE_FIELDS}
  mutation uploadImage(
    $library_uuid: String!
    $image: Upload!
    $folder: String
    $title: String
    $caption: String
    $alternative_text: String
    $metadata: JSON
  ) {
    uploadImage(
      input: {
        library_uuid: $library_uuid
        image: $image
        folder: $folder
        title: $title
        caption: $caption
        alternative_text: $alternative_text
        metadata: $metadata
      }
    ) {
      ...CoreImageFields
    }
  }
`;

export const UPDATE_IMAGE = gql`
  ${CORE_IMAGE_FIELDS}
  mutation UpdateImage(
    $uuid: String!
    $title: String
    $caption: String
    $alternative_text: String
    $metadata: JSON
  ) {
    updateImage(
      input: {
        uuid: $uuid
        title: $title
        caption: $caption
        alternative_text: $alternative_text
        metadata: $metadata
      }
    ) {
      ...CoreImageFields
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation deleteImage($uuid: String!) {
    deleteImage(uuid: $uuid) {
      uuid
    }
  }
`;

export const GET_UNSPLASH_IMAGES = gql`
  query GqlGetUnsplashImages($search: String) {
    getUnsplashImages(input: { search: $search, limit: 20 }) {
      images {
        url
      }
    }
  }
`;

export const GET_PEXELS_IMAGES = gql`
  query GqlGetPexelsImages($search: String, $page: Int) {
    getPexelsImages(input: { search: $search, page: $page, limit: 20 }) {
      images {
        url
      }
    }
  }
`;

export const CONNECT_INSTAGRAM_ACCOUNT = gql`
  mutation connectInstagramAccount($library_uuid: String!) {
    connectInstagramAccount(library_uuid: $library_uuid) {
      redirect_url
    }
  }
`;

export const INTEGRATE_INSTAGRAM_ACCOUNT = gql`
  mutation integrateInstagramAccount($code: String!, $library_uuid: String!) {
    integrateInstagramAccount(
      input: { code: $code, library_uuid: $library_uuid }
    )
  }
`;

export const DOWNLOAD_IMAGE = gql`
  mutation downloadImage($platform: String!, $url: String!) {
    downloadImage(input: { platform: $platform, url: $url })
  }
`;

export const GET_INSTAGRAM_IMAGES = gql`
  query GqlGetInstagramImages($library_uuid: String!) {
    getInstagramImages(library_uuid: $library_uuid)
  }
`;
