import { Icon } from '@iconify/react';

interface Props {
  engagementCount: number;
  engagementType: 'comments' | 'mentions';
}

export default function PostEngagement({
  engagementType,
  engagementCount,
}: Props) {
  const engagementText = {
    comments: 'Comments',
    mentions: 'Mentions',
  }[engagementType];

  const icon = {
    comments: 'uil:comments',
    mentions: 'fe:mention',
  }[engagementType];

  return (
    <div className="flex items-center space-x-2">
      <Icon color="#70707080" icon={icon} width={18} height={18} />
      <p className="text-[#344054] truncate text-sm">{engagementCount}</p>
      <p className="text-[#707070] truncate text-sm">{engagementText}</p>
    </div>
  );
}
