import { FC, useState } from 'react';
import { useMeasurementStyles } from './styles';
import { useStylesGuides } from '../../styles';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { MediaDisplay } from 'components/common/MediaDisplay';
import ReactQuill from 'react-quill';
import { Header } from '../Header';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';

export const CreateTopPicks: FC<any> = ({
  index: listindex,
  form,
  setFormData,
}) => {
  const classes = { ...useMeasurementStyles(), ...useStylesGuides() };
  const [embedAction, setEmbedAction] = useState('');

  const handleInputIncrement = () => {
    const new_guides_list = [...form.top_picks_content];
    new_guides_list[listindex].list = [
      ...new_guides_list[listindex].list,
      {
        pros: '',
        cons: '',
      },
    ];
    setFormData({
      ...form,
      top_picks_content: new_guides_list,
    });
  };

  const handleInputIncrementSpec = () => {
    const new_guides_list = [...form.top_picks_content];
    new_guides_list[listindex].spec_list = [
      ...new_guides_list[listindex].spec_list,
      {
        spec: '',
        quatity: '',
      },
    ];
    setFormData({
      ...form,
      top_picks_content: new_guides_list,
    });
  };

  const handleChange = (e: any, label: string, number: number) => {
    setFormData((form: any) => {
      const new_ing_list = form.top_picks_content;
      new_ing_list[listindex][label][number][e.name] = e.value;
      return {
        ...form,
        top_picks_content: new_ing_list,
      };
    });
  };

  const handleTextChange = ({
    value,
    name,
  }: {
    name: string;
    value: string;
  }) => {
    setFormData((form: any) => {
      const new_ing_list = form.top_picks_content;
      new_ing_list[listindex][name] = value;

      return {
        ...form,
        top_picks_content: new_ing_list,
      };
    });
  };

  const handleInputDecrement = (index: number, type: string) => {
    const new_guides_list = [...form.top_picks_content];
    new_guides_list[listindex]?.[type].splice(index, 1);

    setFormData({
      ...form,
      top_picks_content: new_guides_list,
    });
  };

  const displayDetails = (
    detailList: any,
    label: string,
    value: string,
    type: string
  ) => {
    return (
      <>
        {detailList.map(
          (detail: { amount: string; ingredient: string }, index: number) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className={classes.padding}>
                  <TextInput
                    name={label}
                    value={detailList?.[index]?.[label]}
                    onChange={(e) => handleChange(e, type, index)}
                    classNames={{ input: classes.embedTextInput }}
                  />
                </td>
                <td className={classes.padding}>
                  <TextInput
                    name={value}
                    value={detailList?.[index]?.[value]}
                    onChange={(e) => handleChange(e, type, index)}
                    classNames={{ input: classes.embedTextInput }}
                  />
                </td>
                <td className={classes.incrementIcon}>
                  {index !== 0 && (
                    <AiOutlineMinus
                      onClick={() => handleInputDecrement(index, type)}
                    />
                  )}
                </td>
              </tr>
            );
          }
        )}
      </>
    );
  };

  const handleRemoveList = () => {
    setFormData((oldFormData: { [key: string]: any }) => {
      oldFormData.top_picks_content.splice(listindex, 1);
      return {
        ...oldFormData,
      };
    });
  };

  const handleFormChange = (e: any) => {
    setFormData((form: any) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
  };

  const top_description = {
    name: 'Description',
    description: 'Provide details of the pick below',
    children: (
      <>
        <ReactQuill
          className={classes.reactQuill}
          placeholder="Add description here"
          onChange={(value) => {
            setFormData((form: any) => {
              const new_ing_list = form.top_picks_content;
              new_ing_list[listindex]['topPick_description'] = value;
              return {
                ...form,
                top_picks_content: new_ing_list,
              };
            });
          }}
          value={form.top_picks_content[listindex]?.topPick_description}
        />
        <p className={classes.characterlength}>
          {form.top_picks_content[listindex]?.topPick_description?.length ?? 0}{' '}
          characters
        </p>
      </>
    ),
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    const new_guides_list = [...form.top_picks_content];
    new_guides_list[listindex].topPick_image = JSON.stringify(url);
    setFormData({ ...form, top_picks_content: new_guides_list });

    handleClose();
  };

  return (
    <section className={classes.root}>
      <aside className={classes.aside}>
        <p>{listindex + 1}</p>
      </aside>
      <main className={classes.listHeading}>
        <div className={classes.listHeadingRow}>
          <div
            style={{
              height: ((image) => {
                if (image) {
                  return '100%';
                }
                return '120px';
              })(
                parseRecursive(form.top_picks_content[listindex].topPick_image)
                  ?.src
              ),
            }}
          >
            <MediaDisplay
              name={'topPick_image'}
              formData={form.top_picks_content[listindex]}
              setFormData={setFormData}
              setEmbedAction={setEmbedAction}
            />
            <EmbedImage
              open={embedAction === 'topPick_image'}
              handleClose={handleClose}
              handleEmbed={handleImageEmbed}
              name={embedAction}
              formData={form}
              setFormData={setFormData}
            />
          </div>
          <div>
            <TextInput
              label="TOP PICK LABEL"
              name="topPick_summary"
              classNames={{ input: classes.embedTextInput }}
              value={form.top_picks_content[listindex].topPick_summary}
              onChange={handleTextChange}
            />
            <TextInput
              label="TOP PICK TITLE"
              name="topPick_title"
              classNames={{ input: classes.embedTextInput }}
              value={form.top_picks_content[listindex].topPick_title}
              onChange={handleTextChange}
            />
            <TextInput
              label="PICK AFFILIATE LINK"
              name="topPick_link"
              classNames={{ input: classes.embedTextInput }}
              value={form.top_picks_content[listindex].topPick_link}
              onChange={handleTextChange}
            />
            <TextInput
              label="PICK AFFILIATE LINK TEXT"
              name="topPick_linkText"
              classNames={{ input: classes.embedTextInput }}
              value={form.top_picks_content[listindex].topPick_linkText}
              onChange={handleTextChange}
            />
          </div>
          {listindex !== 0 && (
            <div className={classes.removeList} onClick={handleRemoveList}>
              <AiOutlineMinus />
            </div>
          )}
        </div>
        <article className={classes.tableContainer}>
          <label className={classes.label}>Comparison</label>
          <table className={classes.ingredientTable}>
            <tbody>
              <tr>
                <th></th>
                <th>
                  <p className={classes.tableHeaderText}>PROS</p>
                </th>
                <th>
                  <p className={classes.tableHeaderText}>CONS</p>
                </th>
                <th className={classes.incrementIcon}>
                  <AiOutlinePlus onClick={handleInputIncrement} />
                </th>
              </tr>
              {displayDetails(
                form.top_picks_content[listindex].list,
                'pros',
                'cons',
                'list'
              )}
            </tbody>
          </table>
        </article>
        <article className={classes.topPick_description}>
          <Header
            title={top_description?.name}
            description={top_description?.description}
            key={''}
          >
            {top_description?.children}
          </Header>
        </article>
        <article className={classes.tableContainer}>
          <label className={classes.label}>Details</label>
          <table className={classes.ingredientTable}>
            <tbody>
              <tr>
                <th></th>
                <th>
                  <p className={classes.tableHeaderText}>SPEC</p>
                </th>
                <th>
                  <p className={classes.tableHeaderText}>QUANTITY</p>
                </th>
                <th className={classes.incrementIcon}>
                  <AiOutlinePlus onClick={handleInputIncrementSpec} />
                </th>
              </tr>
              {displayDetails(
                form.top_picks_content[listindex]?.spec_list,
                'spec',
                'quantity',
                'spec_list'
              )}
            </tbody>
          </table>
        </article>
      </main>
    </section>
  );
};
