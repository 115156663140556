import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import { UTMGeneratorFormProps, UtmData } from './types';
import useUTMStyles from './styles';
import { TextArea, TextInput } from '@wazobia-tech/wazcom/dist/io';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { CommonProps } from '@wazobia-tech/wazcom/dist/types';
import { MdOutlineContentCopy } from 'react-icons/md';

const UTMGenerator: React.FC<UTMGeneratorFormProps> = ({
  formData,
  setFormData,
}) => {
  const classes = useUTMStyles();
  const getPreSlugURL = useGetPreSlugURL();
  const [copied, setCopied] = useState(false);

  const onChange: CommonProps['onChange'] = (e) => {
    setFormData((form) => ({
      ...form,
      meta_utmGenerator: {
        ...form.meta_utmGenerator,
        [e.name]: e.value,
      },
    }));
  };

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      meta_utmGenerator: {
        ...form.meta_utmGenerator,
        websiteUrl: `${getPreSlugURL()}${formData.slug}`,
      },
    }));
  }, [formData.slug]);

  const generateUtmUrl = ({
    websiteUrl,
    campaignSource,
    campaignMedium,
    campaignName,
    campaignContent,
    campaignId,
  }: UtmData): string => {
    if (!websiteUrl) return '';
    const url = new URL(websiteUrl);

    if (campaignSource) {
      url.searchParams.append('utm_source', campaignSource);
    }

    if (campaignMedium) {
      url.searchParams.append('utm_medium', campaignMedium);
    }

    if (campaignName) {
      url.searchParams.append('utm_campaign', campaignName);
    }

    if (campaignContent) {
      url.searchParams.append('utm_content', campaignContent);
    }

    if (campaignId) {
      url.searchParams.append('utm_term', campaignId);
    }

    return url.toString();
  };

  return (
    <>
      <p className={classes.title}>UTM Generator</p>
      {[
        {
          label: 'Website URL',
          name: 'websiteUrl',
          disabled: true,
        },
        {
          label: 'Campaign source',
          name: 'campaignSource',
          onChange,
        },
        {
          label: 'Campaign medium',
          name: 'campaignMedium',
          onChange,
        },
        {
          label: 'Campaign name',
          name: 'campaignName',
          onChange,
        },
        {
          label: 'Campaign ID',
          name: 'campaignId',
          onChange,
        },

        {
          label: 'Campaign content',
          name: 'campaignContent',
          onChange,
        },
      ].map((field, key) => (
        <div className={classes.selectBox} key={key}>
          <p className={classes.label}>{field.label}</p>
          <TextInput
            type="text"
            value={
              formData.meta_utmGenerator?.[
                field.name as keyof typeof formData.meta_utmGenerator
              ]
            }
            name={field.name}
            classNames={{ input: classes.inputField }}
            disabled={field.disabled}
            onChange={field.onChange}
          />
        </div>
      ))}
      <div className={classes.copyTitle}>
        <p className={classes.title}>Share the generated URL</p>
        <MdOutlineContentCopy
          className={clx(classes.copyIcon)}
          onClick={() => {
            navigator.clipboard
              .writeText(generateUtmUrl(formData.meta_utmGenerator))
              .then(() => {
                setCopied((copied) => !copied);
              });
          }}
          color={copied ? 'green' : undefined}
        />
      </div>
      <TextArea
        value={generateUtmUrl(formData.meta_utmGenerator)}
        disabled
        classNames={{
          input: classes.inputField,
        }}
      />
    </>
  );
};

export default UTMGenerator;
