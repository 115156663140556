import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const usePostStyles = makeStyles<Theme, { isDialog: boolean }>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: theme.spacing(2),
      marginBottom: ({ isDialog }) =>
        isDialog ? theme.spacing(3) : theme.spacing(2),
      color: theme.design.colors.grey100,
    },
    postContent: {},
    postTitle: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(1),
    },
    postPublishedAt: {
      fontSize: theme.typography.fontSize,

      '& span': {
        marginLeft: theme.spacing(1),
      },
    },
    postCheckBox: {
      padding: 0,
      color: 'rgba(0,0,0,0.7)',

      '&.Mui-checked': {
        color: `${theme.design.colors.primary100} !important`,
      },
    },
  })
);
