import { FC, ReactNode } from 'react';
import { useStylesGuides } from '../../styles';
import { HeaderProps } from './types';

export const Header: FC<HeaderProps> = ({ children, title, description }) => {
  const classes = useStylesGuides();
  return (
    <div className={classes.guideTitle} style={{ marginTop: 16 }}>
      <p className={classes.guideTitleType}>{title}</p>
      <p
        className={classes.guideTitleType}
        style={{ fontWeight: 400, paddingTop: 0 }}
      >
        {description}
      </p>
      {children}
    </div>
  );
};
