import { gql } from '@apollo/client';

export const GET_EMPLOYEES = gql`
  query getCezanneForEmployees {
    getNewEmployees {
      name
      role
      avatar {
        type
        image
      }
    }
  }
`;
