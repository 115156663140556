import FullScreenLoader from 'components/FullScreenLoader/FullScreenLoader';
import useStyles from './styles';
import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import clx from 'classnames';
import { CreateContentLayoutProps } from './types';
import { BsArrowLeft } from 'react-icons/bs';
import { RightSideBar } from 'components/common/RightSideBar';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';

const CreateContentLayout: React.FC<CreateContentLayoutProps> = ({
  getContentConfig: {
    GET_CONTENT,
    variables: getContentVariables,
    client: getContentClient,
    dataKey,
  },
  onGoBack,
  isEdit,
  isPublished,
  children,
  showRightSidebar,
  updateStateData,
  type,
  sidebarControls,
  saving,
}) => {
  const classes = useStyles();
  const { state, pathname } = useLocation();

  const [getContent, { loading: contentLoading }] = useLazyQuery(GET_CONTENT, {
    variables: {
      ...getContentVariables,
    },
    client: getContentClient,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!pathname.includes('/new')) {
      if (state?.row) {
        updateStateData(state.row);
      } else {
        getContent().then((res) => {
          updateStateData(get(res.data, dataKey?.join('.')));
        });
      }
    }
  }, []);

  return (
    <div className={classes.content}>
      <FullScreenLoader display={contentLoading} />
      {!contentLoading && (
        <div className={classes.contentContainer}>
          <div
            className={classes.content__form_section}
            style={{ width: 'calc(70% - 185px)' }}
          >
            <div className={classes.headerContainer}>
              <BsArrowLeft
                onClick={() => {
                  onGoBack();
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
              <p className={classes.headerTitle}>
                {isEdit ? `Edit ${type}` : `Create ${type}`}
              </p>
              <div
                className={clx(classes.postStatus, {
                  [classes.postStatusPublished]: isPublished,
                })}
              >
                <p className={classes.postStatusText}>
                  {isPublished ? 'Published' : 'Draft'}
                </p>
              </div>
              {saving && <p>Saving...</p>}
            </div>
            {children}
          </div>
        </div>
      )}
      {showRightSidebar && (
        <RightSideBar controls={sidebarControls} pageType={type} />
      )}
    </div>
  );
};

export default CreateContentLayout;
