import { useState, useContext, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  unstable_usePrompt,
} from 'react-router-dom';
import useStyles from './styles';
import { WysiwygInput } from '@wazobia-tech/wazcom/dist/io';
import { AppStateContext } from 'contexts/provider';
import { EmbedVideo } from './components/EmbedVideo';
import { EmbedLink } from './components/EmbedLink';
import { EmbedImage } from './components/EmbedImage';
import { GqlImageCropper } from '@wazobia-tech/wazcom/dist/gql';
import {
  createBlogPage,
  notify,
  updateBlogPage,
  useGetPostInformation,
  useGetPreSlugURL,
} from '../../helpers';
import {
  decodeHtml,
  getEncodedConfig,
  getVideoUrl,
  parseRecursive,
  showRightSidebar,
  updateEditorImage,
  useAutoSave,
  validatePostInformation,
  addPrefixToObjectKeys,
  parseRecursiveHelper,
} from './helpers';
import { CTAs } from 'components/Recipes/CreateRecipe/components/CTAs';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CREATE_CATEGORY,
  GET_BLOG_CATEGORIES,
  GET_BLOG_POST,
  GET_BLOG_TAGS,
} from 'components/Blog/graphQL';
import { notifySuccess } from 'utilities/notify';
import { generateTimestamp } from './components/VersionPrompt/helpers';
import { CREATEREDIRECT } from 'components/CreateRedirect/graphQL';
import { errorHandler } from 'utilities/helpers';
import {
  getSeoData,
  getSocialData,
  getTwitterData,
} from 'components/Recipes/CreateRecipe/helper';
import {
  CategoryForm,
  CreateContentLayout,
  DetailsForm,
  POST_PAGE_TYPE,
  SchemaForm,
  SeoForm,
  SocialsForm,
  TagForm,
  UTMGeneratorForm,
} from 'layouts';
import createApolloClient, { blogClient } from 'utilities/apolloClient';
import { RelatedPosts } from '../RelatedPost';
import { PURGECACHE } from 'components/ClearCache/graphQL';
import { commonMediaLibraryProps } from 'components/common/MediaDisplay/commonMediaProps';
import {
  DOWNLOAD_IMAGE,
  GET_IMAGES,
  UPDATE_IMAGE,
  UPLOAD_IMAGE,
} from 'components/Media/graphql/queries';
import { addImagesSubdomain } from 'utilities/image';

const CreateContent = () => {
  const classes = useStyles();
  const [embed, setEmbed] = useState<{ type: string; value: any }>();
  const [embedAction, setEmbedAction] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const { user, siteDetails } = useContext(AppStateContext);
  const getPostInformation = useGetPostInformation();
  const getPreSlugURL = useGetPreSlugURL();
  const [submitting, setSubmitting] = useState({
    draft: false,
    publish: false,
  });
  const { uuid: post_uuid } = useParams();
  const [createRedirect] = useMutation(CREATEREDIRECT);
  const [purgeCache] = useMutation(PURGECACHE);

  const [editorImage, setEditorImage] = useState<any>(null);
  const [handleCropperEmbed, setHandleCropperEmbed] = useState<
    ((name: string, value: any) => void) | undefined
  >(undefined);
  const blog_uuid = siteDetails?.blog_uuid;

  useEffect(() => {
    setTimeout(() => {
      const images: HTMLImageElement[] = [].slice.call(
        document.querySelectorAll('#editor img')
      );
      images.forEach((image) => {
        image.addEventListener('click', () => {
          const srcSplits = image?.src?.split('?');
          let editorImage: any = { src: image.src };

          if (srcSplits.length > 1) {
            const queryString = '?' + srcSplits.slice(1).join('?');
            const urlParams = new URLSearchParams(queryString);
            const config = urlParams.get('config');

            if (config) {
              try {
                const decodedConfig = JSON.parse(atob(config));
                editorImage = { ...editorImage, ...decodedConfig };
              } catch (error) {
                //
              }
            }
          }
          setEditorImage(editorImage);
          const handleCropperEmbed = () => {
            return (name: string, value: any) => {
              updateEditorImage(image, value);
              setEditorImage(null);
            };
          };
          setHandleCropperEmbed(handleCropperEmbed);
        });
      });
    }, 1000);
  }, [embed]);

  // Removing old data for autosaving feature
  localStorage.removeItem('formData');

  const [formData, setFormData] = useAutoSave(
    {
      is_comments_enabled: true,
      is_in_sitemap: true,
      is_feature_article: false,
      is_autofilled_suggestion: false,
      same_as_socials: true,
      author_uuid: user.uuid,
      site: 'vt',
      is_dark_post: false,
      status: false,
      generated_uuid: post_uuid,
      meta_utmGenerator: {
        websiteUrl: '',
        campaignId: '',
        campaignSource: '',
        campaignMedium: '',
        campaignName: '',
        campaignContent: '',
      },
    },
    {
      blog_uuid,
    }
  );

  const initiateEmbed = (embedAction: string) => {
    setEmbedAction(embedAction);
  };

  const handleImageEmbed = (name: string, value: any) => {
    value['src'] =
      value['src'] +
      (value['src']?.includes('?') ? '&' : '?') +
      `config=${getEncodedConfig(value)}`;
    name === 'image'
      ? setEmbed({ type: 'image', value })
      : setFormData({ ...formData, [name]: JSON.stringify(value) });

    handleClose();
  };

  const handleVideoEmbed = (videoUrl: string) => {
    setEmbed({ type: 'video', value: getVideoUrl(videoUrl) });
    handleClose();
  };

  const handleLinkEmbed = (link: string) => {
    setEmbed({ type: 'link', value: link });
    handleClose();
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleChange = (data: { name: string; value: string }) => {
    setFormData({
      ...formData,
      [data?.name]: data?.value,
    });
  };

  const handleValidate = async (name: string, status: boolean) => {
    if (name === 'preview') return;
    const invalidFields = validatePostInformation(formData, status);

    if (invalidFields.length > 0)
      return notify(
        'error',
        `${invalidFields.join(', ')} ${
          invalidFields.length > 1 ? 'are' : 'is'
        } required`
      );
    formData['status'] = status;
    handleSubmit(generateTimestamp(), status);
  };

  const handleSubmit = async (version: string, status: boolean) => {
    const variables = getPostInformation(formData, blog_uuid, status);
    setSubmitting((submitting) => ({
      ...submitting,
      [status ? 'publish' : 'draft']: true,
    }));
    if (!formData?.created_at) {
      await createBlogPage(blog_uuid, {
        ...variables,
        version,
        uuid: post_uuid,
      })
        .then((res) => {
          notifySuccess('Post created successfully');
          setSubmitting((prev) => ({ ...prev, draft: false, publish: false }));
          setFormData((form) => ({
            ...form,
            status,
            post_uuid: res?.createBlogPost.uuid,
            published_at: res?.createBlogPost.published_at,
            created_at: res?.createBlogPost.created_at,
          }));
          navigate(`/cms/posts/${res?.createBlogPost.uuid}`, {
            replace: false,
          });
        })
        .catch((error) => {
          if (error instanceof ApolloError) {
            errorHandler(error as ApolloError);
          }
          setSubmitting((submitting) => ({
            ...submitting,
            [status ? 'publish' : 'draft']: false,
          }));
        });
    } else {
      try {
        const updatePost = await updateBlogPage({
          ...variables,
          uuid: formData?.post_uuid as string,
          version,
          identifier: formData?.post_uuid as string,
        });
        await purgeCache({
          variables: { url: getPreSlugURL() + variables.route, type:'articles' },
        }).catch(console.log);

        notifySuccess('Post updated successfully');
        setSubmitting((prev) => ({ ...prev, draft: false, publish: false }));
        setFormData((form) => ({
          ...form,
          status,
          published_at: updatePost?.updateBlogPost.published_at,
        }));
      } catch (error) {
        if (error instanceof ApolloError) {
          errorHandler(error as ApolloError);
        }
        setSubmitting((submitting) => ({
          ...submitting,
          [status ? 'publish' : 'draft']: false,
        }));
      }
    }
  };

  const updatePostData = (post_data: { [key: string]: any }) => {
    if (post_data?.uuid) {
      setFormData((oldFormData) => {
        const defaultConfig = {
          open_graph: {
            'og:title': '',
            'og:description': '',
            'og:image': '',
          },
          twitter_cards: {
            'twitter:title': '',
            'twitter:description': '',
            'twitter:image': '',
          },
          seo_cards: {
            'seo:title': '',
            'seo:description': '',
            'seo:image': '',
            'seo:url': '',
          },
        };

        const post_config = post_data?.postConfig?.social_preview_config
          ? JSON.parse(post_data?.postConfig?.social_preview_config)
          : defaultConfig;

        return {
          ...oldFormData,
          post_uuid: post_data?.uuid,
          title: decodeHtml(post_data.title),
          content: post_data?.content,
          featured_image: JSON.stringify(
            parseRecursive(post_data?.featured_image)
          ),
          excerpt: post_data?.excerpt,
          categories: post_data?.categories,
          category: post_data?.category,
          tags: post_data?.tags,
          slug: post_data?.slug,
          is_comments_enabled: post_data.postConfig.is_comments_enabled,
          is_in_sitemap: post_data.postConfig.is_in_sitemap,
          is_feature_article: post_data.postConfig.is_feature_article,
          ...getSocialData(post_config),
          ...getTwitterData(post_config),
          ...getSeoData(post_config),
          open_graph: post_config.open_graph,
          twitter_cards: post_config.twitter_cards,
          schema: post_data.postConfig.schema,
          visibility: post_data.visibility.toString(),
          author_uuid: post_data?.author.uuid,
          is_dark_post: !post_data.postConfig.is_in_sitemap,
          related_posts_config: JSON.parse(
            post_data.postConfig.related_posts_config
          ),
          auto_related_posts: post_data.postConfig?.related_posts_config
            ? false
            : true,
          published_at: post_data.published_at,
          status: Boolean(post_data.status),
          ignoreTitleChange: Boolean(post_data.status),
          created_at: post_data?.created_at,
          ...addPrefixToObjectKeys(
            parseRecursiveHelper(post_data.metadata),
            'meta_'
          ),
        };
      });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      window.history.replaceState({}, '');
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formData]);

  unstable_usePrompt({
    message: 'Are you sure you want to leave?',
    when: ({ currentLocation, nextLocation }) =>
      !currentLocation.pathname.includes('/new') &&
      currentLocation.pathname !== nextLocation.pathname,
  });

  const handleHtmlEmbed = (html: string) => {
    setEmbed({
      type: 'htmlembed',
      value: html,
    });
    handleClose();
  };

  return (
    <CreateContentLayout
      isEdit={formData?.created_at}
      isPublished={formData?.status}
      onGoBack={() => {
        navigate('/cms/posts');
      }}
      showRightSidebar={showRightSidebar(formData)}
      type={POST_PAGE_TYPE}
      updateStateData={updatePostData}
      getContentConfig={{
        client: blogClient,
        variables: {
          blog_uuid: siteDetails.blog_uuid,
          uuid: post_uuid,
        },
        GET_CONTENT: GET_BLOG_POST,
        dataKey: ['getBlogPostByUuid'],
      }}
      sidebarControls={{
        details: (
          <DetailsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
            type={POST_PAGE_TYPE}
            switchData={[
              {
                label: 'Dark Article',
                name: 'is_dark_post',
              },
              {
                label: 'Featured Article',
                name: 'is_feature_article',
              },
              {
                label: 'Remove from Snapchat RSS Feed',
                name: 'meta_remove_from_snap_rss',
              },
            ]}
          />
        ),
        categories: (
          <CategoryForm
            formData={formData}
            setFormData={setFormData}
            isArticle={true}
            createCategoryConfig={{
              client: blogClient,
              CREATE_CATEGORY: CREATE_CATEGORY,
              variables: {
                blog_uuid: siteDetails.blog_uuid,
              },
            }}
            getCategoriesConfig={{
              name: GET_BLOG_CATEGORIES,
              client: blogClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getBlogCategoriesByBlogUuid', 'data'],
              variables: {
                blog_uuid: siteDetails.blog_uuid,
                page: 1,
                first: 20,
              },
            }}
          />
        ),
        relatedPosts: (
          <RelatedPosts formData={formData} setFormData={setFormData} />
        ),
        schema: <SchemaForm formData={formData} setFormData={setFormData} />,
        seo: (
          <SeoForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        socials: (
          <SocialsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        tags: (
          <TagForm
            formData={formData}
            setFormData={setFormData}
            getTagsConfig={{
              name: GET_BLOG_TAGS,
              client: blogClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getTagsByBlogUuid', 'data'],
              variables: {
                blog_uuid: siteDetails.blog_uuid,
                page: 1,
                first: 20,
              },
            }}
            addOption={(tag: string) =>
              new Promise((resolve) =>
                resolve({
                  label: tag,
                  value: String(Math.random() * 1000),
                  status: true,
                })
              )
            }
          />
        ),
        utmGenerator: (
          <UTMGeneratorForm formData={formData} setFormData={setFormData} />
        ),
      }}
    >
      {/* {blocker.state === "blocked" ? (
        <div>
          <p>Are you sure you want to leave?</p>
          <button onClick={() => blocker.proceed()}>
            Proceed
          </button>
          <button onClick={() => blocker.reset()}>
            Cancel
          </button>
        </div>
      ) : null} */}
      <WysiwygInput
        value={{ title: formData?.title, content: formData?.content }}
        onChange={handleChange}
        embed={embed}
        initiateEmbed={initiateEmbed}
      />
      <EmbedImage
        open={
          embedAction === 'image' ||
          embedAction === 'featured_image' ||
          embedAction === 'social_image' ||
          embedAction === 'twitter_image' ||
          embedAction === 'seo_image'
        }
        handleClose={handleClose}
        handleEmbed={handleImageEmbed}
        name={embedAction}
        formData={formData}
        setFormData={setFormData}
      />
      <EmbedVideo
        open={embedAction === 'video'}
        handleClose={handleClose}
        handleEmbed={handleVideoEmbed}
      />
      <EmbedLink
        open={embedAction === 'link'}
        handleClose={handleClose}
        handleEmbed={handleLinkEmbed}
        handleVideoEmbed={handleVideoEmbed}
        handleHtmlEmbed={handleHtmlEmbed}
      />
      <CTAs
        handleValidate={handleValidate}
        submitting={submitting}
        isEditPost={Boolean(formData?.status)}
      />
      {editorImage && (
        <GqlImageCropper
          name="editorImage"
          open={true}
          value={editorImage}
          handleClose={() => {
            setEditorImage(null);
          }}
          onChange={({ name, value }) => {
            handleCropperEmbed?.(name, value);
          }}
          imageServiceDomain={addImagesSubdomain(siteDetails.url)}
          config={{
            ...commonMediaLibraryProps,
            client: createApolloClient(
              import.meta.env.VITE_DOLOS_API_URL as string
            ) as any,
            query: {
              name: GET_IMAGES,
              variables: {
                page: 1,
                count: 30,
                library_uuid: siteDetails?.library_uuid,
              },
            },
            upload: { name: UPLOAD_IMAGE, variables: {} },
            update: { name: UPDATE_IMAGE },
            download: {
              name: DOWNLOAD_IMAGE,
            },
          }}
        />
      )}
    </CreateContentLayout>
  );
};

export default CreateContent;
