import React from 'react';
import useStyles from './styles';
import { LeadershipListProps } from './types';

const LeadershipList: React.FC<LeadershipListProps> = ({ leaders }) => {
  const classes = useStyles();

  return (
    <div className={classes.leaderContainer}>
      {leaders.map((item, index) => {
        return (
          <div className={classes.leader} key={index}>
            <img className={classes.leaderImage} src={item.image} />
            <p>{item.name}</p>
            <p>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default LeadershipList;
