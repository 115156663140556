import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useSnapDetailsStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    width: '100%',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectField: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    padding: theme.spacing(0, 0.5),
    borderRadius: '3px',
    background: theme.design.colors.white,
  },
  select: {
    width: 100,
    border: 'none',
    '&.expand': {
      width: '100%',
    },
  },
  contentRoot: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  contentVideo: {
    width: '35%',
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.lidGrey}`,
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
    borderRadius: '8px',
  },
  contentDetails: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    minHeight: 314,
  },
  revenueCard: {
    width: '48.5%',
    marginBottom: theme.spacing(2),
  },
  botttomCard: {
    width: '31.5%',
  },
  chartConainer: {
    background: theme.design.colors.white,
    borderRadius: '4px',
    position: 'relative',
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
    padding: theme.spacing(2),
    minHeight: '30%',
  },
  right: {
    width: '70%',
  },
  chart: {
    width: `100%`,
    height: `260px`,
    marginTop: theme.spacing(2.5),
  },
  snapchat: {
    width: '80%',
    margin: theme.spacing(0, 0, 0, 3),
    borderRadius: '8px',
  },
  storyName: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1.5, 0),
  },
  img: {
    width: '100%',
    borderRadius: '8px',
  },
  backParent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
  },
  back: {
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightMedium,
  },
  stampDetails: {
    fontSize: theme.typography.fontSize - 2,
    margin: theme.spacing(1.5, 0),
    display: 'flex',
    gap: theme.spacing(3),
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.design.colors.grey700,
      marginLeft: theme.spacing(1),
    },
  },
  subHeading: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.design.colors.grey100,
  },
  filterText: {
    textWrap: 'noWrap',
    fontSize: theme.typography.fontSize,
  },
  loading: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));

export default useSnapDetailsStyles;
