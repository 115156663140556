import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSlugControlStyles = makeStyles((theme: Theme) => ({
  slugControl: {
    marginBottom: theme.spacing(2.5),
  },
  slugControlTextInput: {
    background: theme.design.colors.grey98,
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize,
    width: 'calc(100% - 24px)',
  },
  slugControlIcon: {
    marginRight: theme.spacing(0.5),
  },
  slugTextAndIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));
