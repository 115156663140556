import { Icon } from '@iconify/react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  btnText: string;
  disabled?: boolean;
}

export default function PopoverItem({ icon, btnText, onClick, disabled }: Props) {
  return (
    <div>
      <button onClick={onClick} className={`p-4 w-full ${disabled && 'cursor-not-allowed opacity-50'}`}>
        <div className="flex items-center">
          <div>
            <Icon icon={icon} color="#130F26" />
          </div>

          <div className="ml-2">
            <p className="text-[#242424] text-xs">{btnText}</p>
          </div>
        </div>
      </button>
    </div>
  );
}
