export const options = {
  Feed1: 'feed1',
  Feed2: 'Feed2',
};

export const initialData = {
  uuid: '',
  title: '',
  link: '',
  publishDate: '',
  desc: '',
  mediaTitle: '',
  videoUrl: '',
  category: '',
  author: '',
  copyright: '',
  mediaDesc: '',
  thumbnail: '',
};
