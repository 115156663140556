import { Button } from 'components/common/Button/Button';
import { FC } from 'react';
import { CTAsProps } from './types';
import { useStyles } from './styles';
import { IoEyeSharp } from 'react-icons/io5';

export const CTAs: FC<CTAsProps> = ({
  handleValidate,
  submitting,
  isEditPost,
}) => {
  const classes = useStyles();
  return (
    <section className={classes.actionButton}>
      {false && (
        <IoEyeSharp
          onClick={() => {
            handleValidate('preview', true);
          }}
          className={classes.iconShadow}
        />
      )}
      <Button
        onClick={() => {
          handleValidate('save', false);
        }}
        title={isEditPost ? 'Unpublish' : 'Save as Draft'}
        className={classes.primary}
        loading={submitting?.draft}
        variant="outlined"
      />
      <Button
        onClick={() => {
          handleValidate('save', true);
        }}
        title={isEditPost ? 'Update' : 'Publish'}
        loading={submitting?.publish}
      />
    </section>
  );
};
