import { gql } from '@apollo/client';

export const GETUSERS = gql`
  query fetchUsers {
    users(first: 200) {
      data {
        name
        uuid
      }
    }
  }
`;

export const GETGROUPS = gql`
  query getGroups {
    groups {
      id
      name
    }
  }
`;

export const GETPERMISSIONS = gql`
  query getPermissions {
    getPermissions
  }
`;

export const CREATEPERMISSION = gql`
  mutation createPermission(
    $action_uuid: String
    $user_uuid: String!
    $group_id: ID
    $site_uuid: String!
  ) {
    createPermission(
      input: {
        action_uuid: $action_uuid
        user_uuid: $user_uuid
        group_id: $group_id
        site_uuid: $site_uuid
      }
    ) {
      permission {
        action_uuid
        user_uuid
        site_uuid
      }
    }
  }
`;
