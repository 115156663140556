import { AppStateContext } from "./provider";
import React, { createContext, useState, useContext } from "react";

type FieldValidity = {
  [fieldName: string]: boolean;
};

export type VTSectionsValidity = {
  SEO: FieldValidity;
  shows: FieldValidity;
  lifestyle: FieldValidity;
  tvCarousel: FieldValidity;
  heroSection: FieldValidity;
  newsHeadline: FieldValidity;
  focusSection: FieldValidity;
  entertainment: FieldValidity;
  entertainmentCarousel: FieldValidity;
};

export type TwistedSectionsValidity = {
  SEO: FieldValidity;
  shows: FieldValidity;
  aboutUs: FieldValidity;
  lifestyle: FieldValidity;
  motivation: FieldValidity;
  heroCarousel: FieldValidity;
  latestRecipes: FieldValidity;
  featuredRecipes: FieldValidity;
};

export type TwistedSectionName =
  | 'heroCarousel'
  | 'latestRecipes'
  | 'featuredRecipes'
  | 'lifestyle'
  | 'motivation'
  | 'shows'
  | 'aboutUs'
  | 'SEO';

export type VtSectionName =
  | 'heroSection'
  | 'newsHeadline'
  | 'focusSection'
  | 'entertainment'
  | 'entertainmentCarousel'
  | 'lifestyle'
  | 'tvCarousel'
  | 'shows';

export type ValidationContextType = {
  pageName: "vt" | "twisted";
  isFormValid: (sectionName: keyof (VTSectionsValidity | TwistedSectionsValidity)) => boolean
  handleValidationChange: (sectionName: keyof (VTSectionsValidity | TwistedSectionsValidity), fieldName: string, isValid: boolean) => void
}
export const ValidationContext = createContext({} as ValidationContextType);

const ValidationProvider = ({ children }: { children: React.ReactNode }) => {
  const { site } = useContext(AppStateContext)
  const pageName = site === 'vt' ? 'vt' : 'twisted'

  const [fieldValidity, setFieldValidity] = useState<{
    vt: VTSectionsValidity;
    twisted: TwistedSectionsValidity;
  }>({
    vt: {
      heroSection: {},
      newsHeadline: {},
      focusSection: {},
      entertainment: {},
      entertainmentCarousel: {},
      lifestyle: {},
      tvCarousel: {},
      shows: {},
      SEO: {},
    },
    twisted: {
      heroCarousel: {},
      latestRecipes: {},
      featuredRecipes: {},
      lifestyle: {},
      motivation: {},
      shows: {},
      aboutUs: {},
      SEO: {},
    },
  });

  const handleValidationChange = (
    sectionName: keyof (VTSectionsValidity | TwistedSectionsValidity),
    fieldName: string,
    isValid: boolean
  ) => {
    setFieldValidity((prev) => ({
      ...prev,
      [pageName]: {
        ...prev[pageName],
        [sectionName]: {
          ...prev[pageName][sectionName],
          [fieldName]: isValid,
        },
      },
    }));
  };

  const isFormValid = (sectionName: keyof (VTSectionsValidity | TwistedSectionsValidity)) => {
    return Object.values(fieldValidity[pageName][sectionName] || {}).every(
      (fieldValue) => fieldValue === true
    );
  };

  return (
    <ValidationContext.Provider 
      value={{
        pageName,
        isFormValid,
        handleValidationChange
      }}
    >
      {children}
    </ValidationContext.Provider>
  )
}

export default ValidationProvider