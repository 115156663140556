import React, { useState, useContext, useCallback, FC, useEffect } from 'react';
import { DELETEANNOUNCEMENT, FETCHANNOUNCEMENTS } from './graphQL';
import { useMutation, ApolloConsumer } from '@apollo/client';
import useStyles from '../ViewRedirect/styles';
import { Modal, Box } from '@mui/material';
import { notifySuccess } from '../../utilities/notify';
import { Button } from 'components/common/Button/Button';
import CreateAnnouncement from 'components/Announcement/CreateAnnouncement/CreateAnnouncement';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';
import { useNavigate } from 'react-router-dom';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';
interface AnnouncementType {
  id: number;
  description: string;
  title: string;
}

const ViewAnnouncements = () => {
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const [post_uuid, setPostUuid] = useState<undefined | string>();
  const tableColumns = useGetTableColumns(
    setPostUuid,
    setOpenDeleteModal,
    setRefreshToken
  );
  const getTableRows = useGetTableRows();
  const [deleteAnnouncementMutation] = useMutation(DELETEANNOUNCEMENT);
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [displayedAnnouncements, setDisplayedAnnouncements] = useState<
    AnnouncementType[]
  >([]);
  const navigate = useNavigate();

  const url = window.location.pathname.split('/');
  const isEdit = url.includes('edit');
  const classes = useStyles();

  const updateAnnouncements = useCallback(
    (id: number) => {
      let idx = announcements.findIndex(
        (announcement) => announcement.id.toString() === id.toString()
      );
      const newAnnouncements = [...announcements];
      newAnnouncements.splice(idx, 1);
      setAnnouncements(newAnnouncements);
      idx = displayedAnnouncements.findIndex(
        (announcement) => announcement.id.toString() === id.toString()
      );
      const newDisplayedAnnouncements = [...displayedAnnouncements];
      newDisplayedAnnouncements.splice(idx, 1);
      setDisplayedAnnouncements(newDisplayedAnnouncements);
    },
    [announcements, displayedAnnouncements]
  );

  const deleteAnnouncement = async () => {
    if (!post_uuid) {
      return;
    }
    const variables = { id: post_uuid };
    setLoading(true);
    try {
      await deleteAnnouncementMutation({ variables });
      notifySuccess('Announcement Deleted Successfully');
      updateAnnouncements(Number(variables.id));
      setOpenDeleteModal(false);
      setRefreshToken(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setOpenCreateModal(true);
    }
  }, [isEdit]);

  const handleClose = () => {
    navigate(`/admin/announcements`);
    setOpenCreateModal(false);
  };
  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Announcements</p>
            <div className={classes.searchBox}>
              <Button
                title="Create Announcement"
                onClick={() => {
                  setOpenCreateModal(true);
                  setRefreshToken(false);
                }}
              />
            </div>
          </div>

          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: FETCHANNOUNCEMENTS,
                client,
                variables: {
                  page,
                  first: rows,
                  refreshToken,
                },
                dataKey: 'announcements',
              }}
              conditionFilters={tableConditionFilters}
              showFilters={false}
            />
          </div>
          <ShowAnnouncementDialog
            openCreateModal={openCreateModal}
            handleClose={handleClose}
            setOpenCreateModal={setOpenCreateModal}
            selectedId={isEdit ? url.slice(-2)[0] : null}
            setRefreshToken={setRefreshToken}
          />
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleCloseDelete}
            deletePostCategory={deleteAnnouncement}
            setOpenDeleteModal={setOpenDeleteModal}
            loading={loading}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default ViewAnnouncements;

type accouncementProps = {
  openCreateModal: boolean;
  handleClose: () => void;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedId: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ShowAnnouncementDialog: FC<accouncementProps> = ({
  openCreateModal,
  handleClose,
  setOpenCreateModal,
  selectedId,
  setRefreshToken,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Modal
      open={openCreateModal}
      onClose={handleClose}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <Box className={classes.box}>
        <CreateAnnouncement
          handleClose={() => {
            navigate(`/admin/announcements`);
            setOpenCreateModal(false);
          }}
          id={selectedId}
          setRefreshToken={setRefreshToken}
        />
      </Box>
    </Modal>
  );
};
