import { gql } from '@apollo/client';

export const GETSTORYDETAILS = gql`
  query getStory($story_id: String!, $start_date: String!, $end_date: String!) {
    getStory(
      input: {
        story_id: $story_id
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      story_id
      name
      snap_url
      day_by_day_metrics {
        day
        metrics {
          avg_time_viewed
          ecpm
          edition_subscribe_count
          revenue
          sold_impressions
          unique_dau
        }
      }
      metrics {
        avg_time_viewed
        ecpm
        edition_subscribe_count
        revenue
        sold_impressions
        unique_dau
      }
    }
  }
`;
