import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Drawer, IconButton } from '@mui/material';
import { useSidebarStyles } from './styles';
import { SidebarProps } from './types';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useDisplayTabs } from '../../utilities/page';
import clx from 'classnames';

const Sidebar: React.FC<SidebarProps> = ({ tabs }) => {
  const classes = useSidebarStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [navs, setNavs] = React.useState<{ [key: string]: boolean }>({
    content: false,
    forms: false,
  });
  const displayTabs = useDisplayTabs();
  const createPattern = /^\/cms\/posts\/create$/;
  const editPattern =
    /^\/cms\/posts\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/edit$/;

  const isCreatePostPage =
    createPattern.test(location.pathname) ||
    editPattern.test(location.pathname);
  const navigateRoute = (route: string): void => {
    navigate(route);
  };

  return (
    <div>
      <Drawer
        className={clx(classes.drawer, !open && classes.drawer_hidden)}
        variant="permanent"
        anchor="left"
        open={true}
        classes={{
          paper: clx(classes.drawerPaper, !open && classes.drawer_hidden),
        }}
      >
        {open ? (
          <>
            <img
              onClick={() => {
                navigate('/');
              }}
              src="https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-30T120658/rItmmQUi4sXvdkwsm62lTlNH25g32O84lvjW3F4w.png"
              alt="Jungle Creations Logo"
              className={classes.gorillaIcon}
            />
            <div
              onClick={() => {
                setOpen(false);
              }}
              className={`${classes.icon} ${!open && classes.icon_closed}`}
            >
              <ChevronLeft className="chevron" />
            </div>
          </>
        ) : (
          <>
            <img
              onClick={() => {
                navigate('/');
              }}
              src="https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-30T121134/UMfjoJmfvzygdZI3iVvoSxhvLS9UtXHZ1V82T8J1.png"
              alt="Jungle Creations Logo"
              className={clx(classes.gorillaIcon, classes.resizeIcon)}
            />
            <div
              onClick={() => {
                setOpen(true);
              }}
              className={`${classes.icon} ${!open && classes.icon_closed}`}
            >
              <ChevronRight className="chevron" />
            </div>
          </>
        )}

        {displayTabs(tabs, classes, navs, setNavs, navigateRoute)}
      </Drawer>
      <div
        className={clx(
          classes.content,
          !open && classes.content_extended,
          isCreatePostPage && classes.fullView
        )}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
