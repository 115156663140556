import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import useStyles, { Props } from './styles';

const Spinner: FC<Props> = ({ display, size, variant = 'small' }) => {
  const classes = useStyles({ display });

  const renderSpinnerVariant = (type: string) => {
    switch (type) {
      case 'small':
        return (
          <div className={classes.spinner}>
            <CircularProgress color="inherit" size="25px" />
          </div>
        );
      case 'large':
        return (
          <div className={classes.large__spinner}>
            <CircularProgress color="inherit" size={size ? size : '60px'} />
          </div>
        );
      default:
        return (
          <div className={classes.spinner}>
            <CircularProgress color="inherit" size="25px" />
          </div>
        );
    }
  };

  return <>{renderSpinnerVariant(variant)}</>;
};

export default Spinner;
