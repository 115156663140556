import { gql } from '@apollo/client';

export const VOTE = gql`
  mutation createVote($user_id: ID!, $option_id: ID!, $poll_id: ID!) {
    vote(user_id: $user_id, option_id: $option_id, poll_id: $poll_id) {
      poll {
        id
        title
        status
        vote
        votes_count
        author {
          name
        }
        options {
          id
          title
          votes_count
        }
      }
    }
  }
`;
