export const options = {
  elementType: ['line', 'area', 'bar'],
  primaryAxisType: ['linear', 'time', 'log', 'band'],
  secondaryAxisType: ['linear', 'time', 'log', 'band'],
  primaryAxisPosition: ['top', 'left', 'right', 'bottom'],
  secondaryAxisPosition: ['top', 'left', 'right', 'bottom'],
  secondaryAxisStack: [true, false],
  primaryAxisShow: [true, false],
  secondaryAxisShow: [true, false],
  interactionMode: ['primary', 'closest'],
  tooltipGroupingMode: ['single', 'primary', 'secondary', 'series'],
  tooltipAnchor: [
    'closest',
    'top',
    'bottom',
    'left',
    'right',
    'center',
    'gridTop',
    'gridBottom',
    'gridLeft',
    'gridRight',
    'gridCenter',
    'pointer',
  ],
  tooltipAlign: [
    'auto',
    'top',
    'bottom',
    'left',
    'right',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'center',
  ],
  snapCursor: [true, false],
} as const;
