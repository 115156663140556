import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useMeasurementStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    display: 'flex',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    pading: theme.spacing(1),
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    '& th': {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.fontSize - 1,
      textAlign: 'left',
      padding: theme.spacing(2.5, 0, 2.5, 0.5),
    },
  },
  aside: {
    width: '3%',
    borderRight: `1px solid ${theme.design.colors.lidGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listHeading: {
    flexGrow: 1,
    '& > div': {
      borderBottom: `1px solid ${theme.design.colors.lidGrey}`,
      padding: theme.spacing(1, 2, 0, 2),
    },
    position: 'relative',
  },
  listHeadingRow: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    // height: '100%',
    '& > div:first-child': {
      width: '50%',
    },
    '& > div:nth-child(2)': {
      width: '50%',
    },
    '& img': {
      height: '100% !important',
      objectPosition: 'center',
    },
  },
  topPick_description: {
    '& div': {
      border: 0,
      borderRadius: 0,
      borderTop: `1px solid ${theme.design.colors.lidGrey} !important`,
      borderBottom: `1px solid ${theme.design.colors.lidGrey} !important`,
      textAlign: 'left',
    },
  },
  tableContainer: {
    padding: theme.spacing(1.5),
  },
  ingredientTable: {
    '& table, & th, & td': {
      border: `1px solid ${theme.design.colors.lidGrey}`,
    },
    borderCollapse: 'collapse',
    width: '100%',
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    textAlign: 'left',
    display: 'block',
    paddingBottom: theme.spacing(0.5),
  },
  tableHeaderText: {
    paddingLeft: theme.spacing(1.5),
  },
  removeList: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '50%',
    padding: theme.spacing(0.2),
    'row-gap': theme.spacing(0, 0.4),
    cursor: 'pointer',
    margin: 'auto',
    position: 'absolute',
    top: 3,
    right: 3,
  },
  incrementIcon: {
    width: '50px',
    '& svg': {
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'center',
      background: theme.design.colors.white,
      border: `1px solid ${theme.design.colors.lidGrey}`,
      borderRadius: '50%',
      padding: theme.spacing(0.2),
      'row-gap': theme.spacing(0, 0.4),
      cursor: 'pointer',
      margin: 'auto',
    },
  },
}));
