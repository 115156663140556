import React, { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, TextField } from '@mui/material';
import clx from 'classnames';
import useTaggableStyles from './styles';

interface TagsProps {
  onChange: (
    event: React.ChangeEvent<string | unknown>,
    tags: string[]
  ) => void;
  onBlur?: ({
    target: { name, value },
  }: React.FocusEvent<HTMLInputElement>) => void;
  tags?: string[];
  options?: string[];
  invitees?: boolean;
  placeholder?: string;
  className?: string;
  externalChip?: boolean;
}
const Taggables: FC<TagsProps> = ({
  onChange,
  onBlur,
  tags,
  options,
  invitees,
  placeholder,
  externalChip,
  className,
}) => {
  const classes = useTaggableStyles();
  const determineOptions = () => {
    if (invitees) {
      return options;
    }

    if (tags) {
      return tags?.splice(tags.length);
    }

    return [];
  };

  return (
    <div className={clx(className)}>
      <Autocomplete
        multiple
        options={determineOptions() as string[]}
        value={tags ?? []}
        freeSolo={!invitees}
        onChange={onChange}
        renderTags={(value: string[], getTagProps) => (
          <div className={clx(externalChip && classes.renderChipOutside)}>
            {value.map((value: string, index: number) => (
              <div>
                <Chip
                  variant="outlined"
                  label={value}
                  {...getTagProps({ index })}
                />
              </div>
            ))}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name="tags"
            onBlur={onBlur}
            placeholder={placeholder ?? 'Add tags'}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default Taggables;
