import { FC } from 'react';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { useSlugControlStyles } from './styles';
import { AuthorProps } from './types';
import clx from 'classnames';
import { GqlComboBox } from '@wazobia-tech/wazcom/dist/gql';
import { GET_ALL_USERS } from 'components/Blog/graphQL';
import { blogClient } from 'utilities/apolloClient';

export const Author: FC<AuthorProps> = ({
  name = 'author',
  label = 'Author',
  className,
  formData,
  setFormData,
}) => {
  const classes = useSlugControlStyles();

  return (
    <div className={classes.slugControl}>
      {(() => {
        return (
          // @ts-ignore
          <GqlComboBox
            config={{
              name: GET_ALL_USERS,
              client: blogClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getAllUsers'],
            }}
            onChange={({ name, value }: any) => {
              setFormData((prevData) => ({
                ...prevData,
                [name]: value,
              }));
            }}
            name="author_uuid"
            value={formData?.author_uuid}
            variables={{}}
            placeholder="e.g Joanna Freedman"
            label="Author"
            // postFetchHook={postTagsFetchHook}
            addOption={() => {}}
            sx={{
              '& input': {
                minHeight: 0,
              },
            }}
          />
        );
      })()}
    </div>
  );
};
