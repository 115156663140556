import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import { useNavigate } from 'react-router-dom';
import { useTableStyles } from './styles';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Forms', condition: (posts: any) => posts },
  {
    name: 'Active',
    condition: (posts: any) => posts.filter((post: any) => post.status === '1'),
  },
  {
    name: 'In-Active',
    condition: (posts: any) => posts.filter((post: any) => post.status === '0'),
  },
];

export const useGetTableColumns = (
  handleDelete: (uuid: string) => void
): TableColumn[] => {
  const navigate = useNavigate();
  // const [
  //     {
  //         forms: { formsUiState, formsState },
  //     },
  //     dispatch,
  // ] = useContext<[StateProps, Dispatch<ActionProps>]>(AppStateContext);

  // const handleNavigate = (uuid: string) => {
  //     dispatch({ type: "SET_LOADING", payload: true });
  //     getFormByUUID(uuid)
  //         .then(({ data }) => {
  //             sessionStorage.setItem(
  //                 "form-questions",
  //                 JSON.stringify(data?.getFormByUuid?.questions),
  //             );
  //             navigate(`/admin/forms/${uuid}/responses`);
  //         })
  //         .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  // };

  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'View Responses',
          func: (uuid) => {
            navigate(`/cms/forms/${uuid}/responses`);
          },
        },
      ],
    },
    { name: 'Responses', key: 'responses', type: 'string' },
    { name: 'Author', key: 'author', type: 'string' },
    { name: 'Last Modified', key: 'updated_at', type: 'date' },
    { name: 'Status', key: 'status', type: 'string' },
  ];
};

export const useGetTableRows = (onTableRowEmpty?: (value: boolean) => void) => {
  const classes = useTableStyles();

  return (data: any) => {
    const parsedRows: { [key: string]: any }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: any } = {};
      parsedRow['title'] = (
        <p className={classes.blogTitleSlug}>
          {row['title']}{' '}
          {row?.status === '1' ? (
            <FaCheckCircle className={classes.postCheck} />
          ) : (
            ''
          )}
        </p>
      );
      parsedRow['responses'] = row?.responses_count;
      parsedRow['author'] = row.author;
      parsedRow['status'] = (
        <div className={classes.statusTagContainer}>
          <p className={classes.statusTag}>
            {Number(row.status) === 0 ? 'in-active' : 'active'}
          </p>
        </div>
      );

      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] =
        updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
      parsedRow['identifier'] = row.uuid;
      parsedRows.push(parsedRow);
    }
    if (parsedRows.length === 0) onTableRowEmpty?.(true);
    else onTableRowEmpty?.(false);
    return parsedRows;
  };
};
