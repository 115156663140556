import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import useStyles from './styles';
import {
  convertFields,
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';
import { CONTACTUSPAGES } from 'components/ViewPages/graphQL';
import { contactClient } from 'utilities/apolloClient';
import { useState } from 'react';
import { Modal } from 'components/Modal';

const ContactPage = () => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>();

  const getTableRows = useGetTableRows();

  const handleDialogOpen = (row: any) => {
    setModalData(row);
    setOpenModal(true);
  };

  const tableColumns = useGetTableColumns({ handleDialogOpen });

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className={classes.contactModal}>
          <h1 style={{ textDecoration: 'underline' }}>Contact us messages</h1>
          <div className={classes.message}>
            {modalData &&
              Object.entries(modalData?.response)?.map((item, index) => {
                return (
                  convertFields({ uuid: item[0] })?.key !== 'message' &&
                  convertFields({ uuid: item[0] })?.key !== 'reason' && (
                    <div key={index} className={classes.modalContent}>
                      <label className={classes.contactLabel}>
                        {convertFields({ uuid: item[0] })?.key}:
                      </label>
                      <p>{item[1]}</p>
                    </div>
                  )
                );
              })}
          </div>
          <div>
            {modalData &&
              Object.entries(modalData?.response).map((item, index) => {
                const key = convertFields({ uuid: item[0] })?.key;
                return (
                  (key === 'message' || key === 'reason') && (
                    <div key={index} className={classes.modalContent2}>
                      <label className={classes.contactLabel2}>{key}:</label>
                      <p>{item[1]}</p>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </Modal>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Contact Us</p>
      </div>
      <div className={classes.main}>
        <TableNew
          columns={tableColumns}
          getRows={getTableRows}
          query={{
            name: CONTACTUSPAGES,
            // @ts-ignore
            client: contactClient,
            variables: {
              form_uuid: 'e43e2b4b-bb7d-4574-b19d-149122b7ae0d',
              first: 10,
              page: 1,
            },
            dataKey: 'getResponsesByFormUuid',
          }}
          conditionFilters={tableConditionFilters}
          showFilters={false}
        />
      </div>
    </>
  );
};

export default ContactPage;
