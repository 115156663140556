import React, { useState, useEffect, useCallback } from 'react';
import Taggables from '../common/Taggables/Taggables';
import Spinner from '../common/Spinner/Spinner';
import { Button } from '../common/Button/Button';
import Loader from '../common/Loader/Loader';
import useStyles from '../Blog/components/CreateContent/styles';
import {
  GETUSERS,
  GETGROUPS,
  GETPERMISSIONS,
  CREATEPERMISSION,
} from './graphQL';
import { ACLType } from './types';
import { useMutation, useQuery } from '@apollo/client';
import createApolloClient from '../../utilities/apolloClient';
import { notifySuccess } from '../../utilities/notify';

const ACL_URL = import.meta.env.VITE_ACL_URL as string;
const ACL_CLIENT = createApolloClient(ACL_URL);
const site_uuid = import.meta.env.VITE_ACL_UUID as string;

const Acl = () => {
  const classes = useStyles();
  const { data: usersData, loading: usersLoading } = useQuery(GETUSERS, {
    fetchPolicy: 'network-only',
  });
  const { data: groupsData, loading: groupsLoading } = useQuery(GETGROUPS, {
    client: ACL_CLIENT,
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(
    GETPERMISSIONS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [createPermissionMutation, { loading: createPermissionLoading }] =
    useMutation(CREATEPERMISSION, { client: ACL_CLIENT });
  const [users, setUsers] = useState<ACLType[]>([]);
  const [groups, setGroups] = useState<ACLType[]>([]);
  const [permissions, setPermissions] = useState<ACLType[]>([]);
  const [errors, setErrors] = useState({ user: false, permissionGroup: false });
  const [formData, setFormData] = useState<{ [key: string]: number }>({
    user_id: -1,
    group_id: -1,
    permission_id: -1,
  });

  useEffect(() => {
    if (usersData) {
      const users: { uuid: string; name: string }[] = usersData.users.data;
      const formattedUsers = users.map(({ uuid, name }) => ({
        id: uuid,
        name,
      }));
      setUsers(formattedUsers);
    }

    if (groupsData) {
      const groups = groupsData.groups;
      setGroups(groups);
    }

    if (permissionsData) {
      const parsedPermissions = JSON.parse(permissionsData.getPermissions);
      const permissions: ACLType[] = [];
      Object.keys(parsedPermissions).forEach((key) => {
        permissions.push({ id: key, name: parsedPermissions[key] });
      });
      setPermissions(permissions);
    }
  }, [usersData, groupsData, permissionsData]);

  const handleTags = useCallback(
    (tags: string[], type: string) => {
      let values, key;
      switch (type) {
        case 'permission':
          values = permissions;
          key = 'permission_id';
          break;
        case 'group':
          values = groups;
          key = 'group_id';
          break;
        default:
          values = users;
          key = 'user_id';
      }
      if (!tags[0]) {
        return setFormData({ ...formData, [key]: -1 });
      }

      const ids = values
        .filter((value) => tags.includes(value.name))
        .map((value) => value.id);
      setFormData({ ...formData, [key]: ids[0] as number });
    },
    [users, groups, formData]
  );

  const displaySections = useCallback(() => {
    const data: { key: string; values: ACLType[] }[] = [
      { key: 'User', values: users },
      { key: 'Group', values: groups },
      { key: 'Permission', values: permissions },
    ];
    return data.map(({ key, values }, index) => {
      const selectedValue = formData?.[key.toLowerCase() + '_id'];
      return (
        <div key={index} className={classes.content__form_section}>
          <label className={classes.content__form_label}>{key}</label>
          <Taggables
            options={values.map((value) => value.name)}
            invitees={true}
            onChange={(e, tags) => {
              const formattedTags = [tags[tags.length - 1]];
              handleTags(formattedTags, key.toLowerCase());
            }}
            placeholder={`Select ${key}`}
            tags={
              selectedValue === -1
                ? []
                : values
                    .filter((value) => value.id === selectedValue)
                    .map((value) => value.name)
            }
          />
          {key === 'User' && errors.user && (
            <p className={classes.content__form_error}>User is required</p>
          )}
          {key !== 'User' && errors.permissionGroup && (
            <p className={classes.content__form_error}>
              A Permission or Group is required
            </p>
          )}
        </div>
      );
    });
  }, [users, groups, permissions, formData, errors]);

  const validate = useCallback(() => {
    const newErrors = { user: false, permissionGroup: false };
    let isValid = true;
    if (formData.user_id === -1) {
      newErrors.user = true;
      isValid = false;
    }

    if (formData.group_id === -1 && formData.permission_id === -1) {
      newErrors.permissionGroup = true;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  }, [formData, errors]);

  const createPermission = useCallback(
    async (e) => {
      e.preventDefault();
      if (!validate()) return;
      const {
        user_id: user_uuid,
        permission_id: action_uuid,
        group_id,
      } = formData;
      const variables: { [key: string]: string | number } = {
        user_uuid,
        site_uuid,
      };
      if (action_uuid && action_uuid !== -1) {
        variables.action_uuid = action_uuid;
      }
      if (group_id && group_id !== -1) {
        variables.group_id = group_id;
      }

      await createPermissionMutation({ variables });
      notifySuccess('Permission set successfully');
    },
    [formData, errors]
  );

  return (
    <React.Fragment>
      {!usersLoading && !groupsLoading && !permissionsLoading && (
        <div className={classes.content}>
          <p className={classes.content__title}>Set User Permission</p>
          <form onSubmit={createPermission} className={classes.content__form}>
            {displaySections()}
            <div
              className={classes.content__form_section}
              style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <div style={{ width: 'fit-content', position: 'relative' }}>
                <Button type="submit" title="Create" />
                <Loader display={createPermissionLoading} />
              </div>
            </div>
          </form>
        </div>
      )}
      <Spinner display={usersLoading || groupsLoading || permissionsLoading} />
    </React.Fragment>
  );
};

export default Acl;
