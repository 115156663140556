import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

interface DraggableCardProps {
  id: string;
  index: number;
  level: number;
  parentIndex?: number;
  moveCard: (
    dragIndex: number,
    hoverIndex: number,
    dragLevel: number,
    parentIndex?: number
  ) => void;
  children: React.ReactNode;
}

interface DragItem {
  id: string;
  index: number;
  level: number;
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  id,
  index,
  level,
  moveCard,
  children,
  parentIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: `CARD_${level}`,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      const dragLevel = item.level;

      if (dragIndex === hoverIndex && dragLevel === level) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex, dragLevel, parentIndex);
      item.index = hoverIndex;
      item.level = level;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: `CARD_${level}`,
    item: { id, index, level },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

export default DraggableCard;
