import React, { FC } from 'react';
import { useAccordionStyles } from './styles';
import { AccordionProps } from './types';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { kebabCase } from 'lodash';
import clx from 'classnames';
import { Button } from '../Button/Button';

export const Accordion: FC<AccordionProps> = ({
  title,
  className,
  square,
  expanded,
  children,
  handleClick,
  isDeletable,
  handleRemove,
}) => {
  const classes = useAccordionStyles();
  return (
    <MuiAccordion
      className={clx(classes.root, className)}
      square={square}
      expanded={expanded}
    >
      <MuiAccordionSummary
        expandIcon={<MdExpandMore className={classes.icon} />}
        aria-controls={kebabCase(title)}
        id={kebabCase(title)}
        className={classes.summary}
        onClick={handleClick}
      >
        <div className={classes.headSection}>
          <div>{title}</div>
          <div>
            {isDeletable && (
              <Button
                variant="outlined"
                className={classes.deleteButton}
                onClick={() => handleRemove?.()}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};
