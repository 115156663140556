import { FC, useState } from 'react';
import { CircularProgress } from '@mui/material';
import WidgetNav from '../common/WidgetNav/WidgetNav';
import useStyles from './styles';
import { ArticleListProps, AnnouncementContainerProps } from './types';

type WidgetDataFormatProps = {
  pages?: number;
  displayContent: (arg0: number, arg1: number) => void;
  dataLimit: number;
  loading: boolean;
  dataLength: number;
};

const WidgetDataFormat: FC<WidgetDataFormatProps> = ({
  pages,
  displayContent,
  loading,
  dataLimit,
  dataLength,
}) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
    if (currentPage !== pages) {
      setCurrentPage((page) => page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((page) => page - 1);
    }
  };

  const startIndex = currentPage * dataLimit - dataLimit;
  const endIndex = startIndex + dataLimit;

  return (
    <>
      {loading ? (
        <div className={classes.loader_container}>
          <CircularProgress className={classes.loader} size="24px" />
        </div>
      ) : (
        <div className={classes.scroll}>
          {displayContent(startIndex, endIndex)}
        </div>
      )}
      <WidgetNav
        onClickLeft={goToPreviousPage}
        onClickRight={goToNextPage}
        startingPoint={startIndex !== 0 && dataLength > 0}
        endingPoint={endIndex < dataLength && dataLength > 0}
      />
    </>
  );
};

export const AnnouncementContainer: FC<AnnouncementContainerProps> = ({
  date,
  title,
  description,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.list_container}>
        <div className={classes.list_details}>
          <div className={classes.titleHeader}>
            <p className={classes.annoucementTitle}>{title}</p>
            <p className={classes.datePosted}>{date}</p>
          </div>
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

export const ArticleList: FC<ArticleListProps> = ({ count, description }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.list_container}>
        <div className={classes.list_details}>
          <div className={classes.titleHeader}>
            <p className={classes.annoucementTitle}>{description}</p>
            {count && <p className={classes.datePosted}>{count}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetDataFormat;
