import { gql } from '@apollo/client';

export const FETCHSURVEYS = gql`
  query getSurveys($first: Int, $page: Int) {
    surveys(first: $first, page: $page) {
      data {
        id
        name
        url
        creator
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETESURVEY = gql`
  mutation deleteSurvey($id: ID!) {
    deleteSurvey(id: $id) {
      id
    }
  }
`;
