import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import moment from 'moment';
import { useNavigate } from 'react-router';

export const useGetTableColumns = ({
  handleDialogOpen,
}: {
  handleDialogOpen: (arg: any) => void;
}): TableColumn[] => {
  return [
    {
      name: 'Message',
      key: 'message',
      type: 'string',
      actions: [
        {
          name: 'View',
          func: (row: any) => {
            handleDialogOpen(row);
          },
        },
      ],
    },
    { name: 'Email', key: 'email', type: 'string' },
    { name: 'Company', key: 'company', type: 'string' },
    { name: 'Name', key: 'name', type: 'string' },
    { name: 'Date', key: 'updated_at', type: 'date' },
  ];
};

export const useGetTableRows = () => {
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];
    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['message'] = (
        <p style={{ width: '500px' }}>
          {decodeHtml(
            convertFields({ data: row?.response, filterField: 'message' })
              ?.message
          )}{' '}
        </p>
      );
      parsedRow['name'] = (
        <p>
          {decodeHtml(
            convertFields({ data: row?.response, filterField: 'name' })?.name
          )}{' '}
        </p>
      );
      parsedRow['email'] = (
        <p>
          {decodeHtml(
            convertFields({ data: row?.response, filterField: 'email' })?.email
          )}{' '}
        </p>
      );
      parsedRow['company'] = (
        <p>
          {decodeHtml(
            convertFields({ data: row?.response, filterField: 'company' })
              ?.company
          )}{' '}
        </p>
      );
      const updatedAt = moment(row?.updated_at).fromNow();
      parsedRow['updated_at'] = (
        <p style={{ width: '100px' }}>
          {updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase()}
        </p>
      );
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Pages', condition: (posts: any) => posts },
];

export const convertFields = ({
  data,
  uuid,
  filterField,
}: {
  data?: any[];
  uuid?: string;
  filterField?: 'name' | 'phone' | 'email' | 'reason' | 'company' | 'message';
}): { [key: string]: string } => {
  const keyUuid = [
    { name: '0fab4033-1128-4f0c-8670-947253e90904' },
    { phone: '8a63a312-8ebe-4c28-b6d4-b38956853be6' },
    { email: '56db02bd-6303-4b71-8b0b-62920848423a' },
    { reason: '78d135ec-388a-4cde-a0c0-1b5d44c49ac6' },
    { company: 'bd553c06-65a6-4fe6-a984-41581be3e5e7' },
    { message: 'd67e7cb8-c6b5-44be-bb9e-da006dfc9c52' },
  ];

  if (data && filterField) {
    const convertDataToArray = Object.entries(data);

    const filteredKey = keyUuid.filter((item) => {
      return Object.keys(item)[0] === filterField;
    })[0];
    const filteredData = convertDataToArray.filter((item) => {
      return item[0] === filteredKey[filterField];
    })[0];
    return { [filterField as string]: filteredData[1] };
  } else {
    const filteredKey = keyUuid.filter((item) => {
      return Object.values(item)[0] === uuid;
    })[0];
    return { key: Object.keys(filteredKey)[0] };
  }
};
