import { gql } from '@apollo/client';

export const FETCHFEATURES = gql`
  query getFeature($first: Int, $page: Int) {
    features(first: $first, page: $page) {
      data {
        id
        name
        user
        reason
        description
        status
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const UPDATEFEATURE = gql`
  mutation updateFeature($id: ID!, $status: Int!) {
    updateFeatureRequest(id: $id, status: $status) {
      message
    }
  }
`;
