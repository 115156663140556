import React from 'react';
import { get, size } from 'lodash';
import clx from 'classnames';
import FormBuilder from '../FormBuilder';
import { useSectionStyles } from './styles';
import { FormGroupProps } from './types';
import { Button } from '../../common/Button/Button';
import { AiOutlinePlus } from 'react-icons/ai';
import { Accordion } from '../../common/Accordion/Accordion';
import { handleAddNewCard, handleRemoveCard } from '../../../utilities/helpers';

const FormGroup: React.FC<FormGroupProps> = ({
  fields = [],
  name,
  sectionName,
  canHaveMultiple = false,
  form,
  setForm,
}) => {
  const classes = useSectionStyles();

  const showFormGroupHeading = () => {
    if (!fields) return false;
    if (fields.length > 1) return true;
    if (
      fields.length == 1 &&
      fields[0].type == 'layout' &&
      size(get(fields, '[0].properties.fields', [])) > 1
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      {showFormGroupHeading() && (
        <>
          <p>{name.toUpperCase()}</p>
          <br />
        </>
      )}

      {fields ? (
        canHaveMultiple ? (
          <div className={classes.rowsContainer}>
            {Array.isArray(form[sectionName][name]) &&
              form[sectionName][name].map((data: any, index: number) => (
                <Accordion
                  key={index}
                  title={`${sectionName.toUpperCase()} ${index + 1}`}
                  isDeletable={index != 0}
                  handleRemove={() => {
                    handleRemoveCard(setForm, sectionName, name, index);
                  }}
                  className={classes.accordion}
                >
                  <div className={classes.inputRow}>
                    <div className={classes.row}>
                      <FormBuilder
                        fields={fields}
                        canHaveMultiple={canHaveMultiple}
                        className={clx(
                          classes.itemsParent,
                          canHaveMultiple && classes.hasMulitple
                        )}
                        sectionName={sectionName}
                        groupName={name}
                        form={form}
                        setForm={setForm}
                        activeCardIndex={index}
                      />
                    </div>
                  </div>
                </Accordion>
              ))}
          </div>
        ) : (
          <FormBuilder
            fields={fields}
            canHaveMultiple={canHaveMultiple}
            className={classes.itemsParent}
            sectionName={sectionName}
            groupName={name}
            form={form}
            setForm={setForm}
          />
        )
      ) : null}

      {canHaveMultiple && (
        <Button
          variant="outlined"
          className={classes.addMoreButton}
          onClick={() => handleAddNewCard(setForm, sectionName, name, form)}
        >
          <AiOutlinePlus size={15} className={classes.addIcon} />
          Add row
        </Button>
      )}
    </div>
  );
};

export default FormGroup;
