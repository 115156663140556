import { GET_POSTS_BY_BLOG_UUID } from 'components/Blog/graphQL';
import { AppStateContext } from 'contexts/provider';
import { useContext } from 'react';
import { blogClient } from 'utilities/apolloClient';

export const queryApi = async (
  setBlogdata: React.Dispatch<
    React.SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >
) => {
  const {
    siteDetails: { blog_uuid },
  } = useContext(AppStateContext);

  const { data, error } = await blogClient.query({
    query: GET_POSTS_BY_BLOG_UUID,
    variables: {
      blog_uuid,
    },
    fetchPolicy: 'network-only',
  });

  if (data && !error) {
    const { data: tableData, paginatorInfo: pagination } =
      data?.getBlogPostsByBlogUuid;
    setBlogdata(tableData);
  }
};
