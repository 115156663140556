import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../../contexts/theme';

const useCategoryStyles = makeStyles((theme: BaseTheme) => ({
  root: {},
  main: {
    minHeight: '30vh',
    borderRadius: '10px',
  },
  title: {
    fontSize: '1.3rem',
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  tagsList: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  createBtn: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.design.font.workSans,
    color: theme.design.colors.white,
    background: theme.design.colors.grey100,
  },
  loader: {
    '&.MuiCircularProgress-colorPrimary': {
      color: theme.design.colors.white,
    },
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  actionButton: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },
  noBtn: {
    '&.MuiButton-root': {
      background: theme.design.colors.white,
      color: theme.design.colors.black,
      border: `1px solid ${theme.design.colors.black}`,
    },
  },
  subTitle: {
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize + 2,
    padding: theme.spacing(2, 0),
  },
  deleteText: {
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
  },
  box: {
    width: 767,
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.design.colors.white,
  },
  deleteModal: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

export default useCategoryStyles;
