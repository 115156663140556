import { useEffect, useState } from 'react';
import WidgetDataFormat, {
  AnnouncementContainer,
} from 'components/Announcement/WidgetDataFormat';
import { queryApi } from './helper';

export const LatestArticlesWidget = () => {
  const [blogData, setBlogdata] = useState<{ [key: string]: any }[]>([]);
  const [newBlogsData, setNewBlogsData] = useState<{ [key: string]: any }[]>(
    []
  );
  queryApi(setBlogdata);
  const dataLimit = 2;

  useEffect(() => {
    if (blogData) {
      const blogs: { [key: string]: any }[] = blogData;
      const newBlogs: { [key: string]: any }[] = [];
      blogs.forEach(({ title, excerpt, name }) => {
        newBlogs.push({ title, excerpt, name });
      });
      setNewBlogsData(newBlogs);
    }
  }, [blogData]);

  const displayContent = (startIndex: number, endIndex: number) => {
    return newBlogsData
      ?.slice(startIndex, endIndex)
      ?.map((data: any, index: number) => {
        return (
          <AnnouncementContainer
            key={index}
            title={data.title}
            description={data.excerpt}
          />
        );
      });
  };

  return (
    <>
      {/* <p
        style={{
          padding: '16px 0',
          width: '90%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ fontSize: '10px' }}>Title</span>
      </p> */}
      <WidgetDataFormat
        displayContent={displayContent}
        loading={newBlogsData.length > 1 ? false : true}
        dataLimit={dataLimit}
        dataLength={newBlogsData.length}
      />
    </>
  );
};
