import React from 'react';
import useStyles from './styles';
import SubSectionList from 'pages/PageContent/components/subSectionList';
import { cultureTabs } from 'pages/PageContent/data';

const CulturePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          Jungle Culture{' '}
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T103048/5I9ACEe4yjiBHPGImPGC9IJ1rfH5Qs1OKs5IEQTu.png'
            }
          />
        </h1>
      </div>
      <div className={classes.cultureCards}>
        {[
          {
            title: 'Diversity & Belonging',
            image:
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-16T112250/yqcAODt3x7CWw3WYLOhkxMKdCO2UabnLIBPQZvIa.jpg',
            description:
              'At Jungle Creations, we aim to ensure our workforce reflects the diverse society we live in.',
          },
        ].map((culture) => (
          <div className={classes.card}>
            <p className={classes.cardTitle}>{culture.title}</p>
            <img src={culture.image} className={classes.cardImage} />
            <p className={classes.cardDescription}>{culture.description}</p>
          </div>
        ))}
      </div>
      <div className={classes.content}>
        <SubSectionList cards={cultureTabs} />
      </div>
    </div>
  );
};

export default CulturePage;
