import React from 'react';
import { SectionProps } from './types';
import TextField from '../../common/TextField/TextField';
import Card from '../Card/Card';
import { useSectionStyles } from './styles';

const Section: React.FC<SectionProps> = ({
  max,
  items,
  index: sectionIndex,
  handleChange,
  handleDeleteCard,
}) => {
  const classes = useSectionStyles();
  return (
    <div className={classes.root}>
      <TextField
        name="max"
        label="Maximum"
        value={max}
        type="number"
        onChange={(e) => {
          handleChange?.(
            [String(sectionIndex as number), 'max'],
            e.target.value
          );
        }}
      />
      <div className={classes.itemsParent}>
        {items.map((item, index) => (
          <Card
            key={index}
            index={index}
            {...item}
            handleChange={(
              keys: string[],
              value: string | number | boolean
            ) => {
              keys.unshift(String(sectionIndex));
              handleChange?.(keys, value);
            }}
            handleDelete={(answerIndex) =>
              handleDeleteCard?.(sectionIndex as number, answerIndex)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Section;
