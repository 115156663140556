import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  main: {
    minHeight: '30vh',
    borderRadius: '10px',
  },
  contactModal: {
    background: '#fff',
    width: 700,
    borderRadius: 10,
    padding: 20,
  },
  message: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    paddingTop: '20px',
  },
  contactLabel: {
    textTransform: 'capitalize',
    color: 'gray',
    fontSize: '12px',
  },
  contactLabel2: {
    textTransform: 'capitalize',
    color: 'gray',
  },
  modalContent2: {
    width: '100%',
    padding: '10px 0',
  },
  modalContent: { width: '50%', padding: '10px 0' },

  title: {
    fontSize: '1.3rem',
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
