import React from 'react';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { Select } from '../common/Select/Select';
import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import {
  errorHandler,
  notifyError,
  notifySuccess,
} from '../../utilities/notify';

declare global {
  interface Window {
    FB: any;
  }
}
const actionsHandler = async (
  event: React.ChangeEvent<{ name?: string; value: string }>,
  id: number,
  site: string,
  fetchPages: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  facebookPageData: any,
  pagesLoading: boolean,
  deleteConnection: (id: number) => Promise<void>,
  pageError?: ApolloError
) => {
  const value = event.target.value;

  if (value === 'Renew') {
    await connectToFacebook(fetchPages, site, facebookPageData, pageError);
  } else if (value == 'Delete') {
    deleteConnection(id);
  }
};

export const connectToFacebook = async (
  fetchPages: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  site: string,
  facebookPageData: any,
  pageError?: ApolloError
) => {
  const data = await loginToFacebook();
  fetchPages({
    variables: {
      userShortToken: data.authResponse.accessToken,
      site,
      userId: data.authResponse.userID,
    },
  });
  if (pageError) {
    return errorHandler(pageError);
  }
  if (!facebookPageData || !facebookPageData.generateFacebookPageAccessToken) {
    notifyError('Something went wrong. Please, try again.');
  } else {
    notifySuccess(facebookPageData.generateFacebookPageAccessToken.message);
  }
};

const loginToFacebook = (): Promise<{ authResponse: any }> =>
  new Promise((resolve, reject) => {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          resolve(response);
        }
        reject(response);
      },
      { scope: 'email, pages_show_list' }
    );
  });

const getSiteName = (selectOptions: any, name: string) => {
  return Object.keys(selectOptions).find((key) => selectOptions[key] === name);
};

export const schema = (
  fetchPages: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  site: string,
  facebookPageData: any,
  pagesLoading: boolean,
  deleteConnection: (id: number) => Promise<void>,
  pageError?: ApolloError
): SchemaProps[] => {
  const selectOptions = {
    VT: 'vt',
    'The Wild': 'thewild',
    'Twisted Food': 'twistedfood',
    'Four Nine': 'fournine',
    'Craft Factory': 'craftfactory',
    'Jungle Creations': 'junglecreations',
  };
  return [
    {
      label: 'Page Name',
      func: ({ item }) => (
        <Link
          to={{
            pathname: `https://www.facebook.com/${get(item, 'platform_id')}/`,
          }}
          target="_blank"
          rel="noreferrer"
        >
          {getSiteName(selectOptions, get(item, 'name'))}
        </Link>
      ),
    },
    {
      label: 'Page ID',
      func: ({ item }) => get(item, 'platform_id'),
    },
    {
      label: 'Expiry Date',
      func: ({ item }) => get(item, 'expires_at'),
    },
    {
      label: 'Token',
      func: () => 'X'.repeat(12),
    },
    {
      label: 'Action',
      func: ({ item }) => (
        <div style={{ width: '110px' }}>
          <Select
            value="Actions"
            options={['Actions', 'Renew', 'Delete']}
            noStyle={true}
            onChange={(e) => {
              actionsHandler(
                e,
                get(item, 'id'),
                site,
                fetchPages,
                facebookPageData,
                pagesLoading,
                deleteConnection,
                pageError
              );
            }}
          />
        </div>
      ),
    },
  ];
};
