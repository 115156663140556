import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FETCHCONTACTDETAILS } from './graphQL';
import useStyles from '../ViewRedirect/styles';
import { SchemaProps } from '../common/Table/types';
import Table from '../common/Table/Table';
import { get } from 'lodash';
import { CircularProgress } from '@mui/material';

const ContactDetails = () => {
  const [paginator, setPaginator] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const {
    data,
    error,
    loading: pageLoading,
  } = useQuery(FETCHCONTACTDETAILS, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const [details, setDetails] = useState<{ [key: string]: string }[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      const details = data.contactDetails.data;
      setDetails(details);
      const paginatorInfo = data?.contactDetails?.paginatorInfo;
      setPaginator(paginatorInfo);
    }
  }, [data, error]);

  const schema: SchemaProps[] = [
    {
      label: 'Site',
      func: () => (
        <img
          src="https://jungle-portal.s3.us-west-1.amazonaws.com/icons/the-wild-icon.png"
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
        />
      ),
    },
    {
      label: 'Name',
      func: ({ item }) => get(item, 'name'),
    },
    {
      label: 'Email',
      func: ({ item }) => get(item, 'email'),
    },
    {
      label: 'Company',
      func: ({ item }) => get(item, 'company'),
    },
    {
      label: 'Number',
      func: ({ item }) => get(item, 'number'),
    },
    {
      label: 'Services',
      func: ({ item }) => get(item, 'service'),
    },
    {
      label: 'Date',
      func: ({ item }) => get(item, 'created_at'),
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Contact Details</p>
      </div>

      <div className={classes.main}>
        {pageLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <Table
            schema={schema}
            collection={details}
            pagination={true}
            paginatorInfo={paginator}
            rowsPerPage={rows}
            changePage={(e) => setPage(e + 1)}
            changeRow={(e) => setRows(e + 1)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ContactDetails;
