import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useNavContentStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 950,
  },
  select: {
    '& .MuiOutlinedInput-root': {
      width: 200,
      padding: `${theme.spacing(0, 0, 0, 0.5)} !important`,
    },
  },
  group: {
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.design.colors.grey100,
    backgroundColor: theme.design.colors.jpGrey400,
  },
  ongoingAnalysisBtn: {
    all: 'unset',
    color: '#344054',
    cursor: 'pointer',
    border: `1px solid ${theme.design.colors.greyPitch}`,
    padding: '10px 40px',
    borderRadius: '3px',
  },
  truncateText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
