import React, { useContext, useEffect } from 'react';
import useStyles from './styles';
import {
  GET_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from '../CreatePurchaseOrder/graphQL';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { blogClient } from 'utilities/apolloClient';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from '../CreatePurchaseOrder/data';
import { AppStateContext } from 'contexts/provider';
import FullScreenLoader from 'components/FullScreenLoader/FullScreenLoader';
import { UPDATE_SLT_PURCHASE_ORDER } from './graphQL';
import { notifySuccess } from 'utilities/notify';
import ProgressTracker from './POTracker';
import {
  poDetails,
  requestorDetails,
  statusNumbers,
  vendorDetails,
} from './data';
import { Button } from 'components/common/Button/Button';
import clx from 'classnames';
import pagePermissions from '../../../pagePermissions.json';

const ReviewPurchaseOrder = () => {
  const classes = useStyles();
  const { uuid } = useParams();
  const { state } = useLocation();
  const { user } = useContext(AppStateContext);
  const isAdmin = pagePermissions.admin.includes(user.id);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      requestorName: user.name,
      requestorJobTitle: user.position,
      requestor_id: user.id.toString(),
      requestorEmail: user.email,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const {
        date: purchase_date,
        vendorName: vendor_name,
        vendorContactDetails: vendor_contact,
        approved: slt_approved,
        isBudgetedFor: current_budget,
        requirement,
        items,
        price: total,
        requestor_id,
        status,
      } = values;
      const variables = {
        purchase_date,
        vendor_name,
        vendor_contact,
        slt_approved,
        current_budget,
        requirement,
        items,
        total: Number(total),
        requestor_id,
        status,
      };
      try {
        if (slt_approved) {
          const slt = await sltApprovePO({
            variables: {
              id: formik.values.id,
            },
          });
        }
        await updatePurchaseOrderMutation({
          variables: {
            ...variables,
            id: values.id,
          },
        });
        notifySuccess('PO Updated Successfully');
      } catch (error) {
        //
      }
    },
  });

  const [getContent, { loading: contentLoading }] = useLazyQuery(
    GET_PURCHASE_ORDER,
    {
      variables: {
        uuid,
      },
      client: blogClient,
      fetchPolicy: 'network-only',
    }
  );

  const [sltApprovePO, { loading: sltApproveLoading }] = useMutation(
    UPDATE_SLT_PURCHASE_ORDER
  );
  const [updatePurchaseOrderMutation, { loading: updateLoading }] = useMutation(
    UPDATE_PURCHASE_ORDER
  );

  const updateFormData = (data: Record<string, any>) => {
    formik.setValues({
      ...data,
      date: data.purchase_date,
      vendorName: data.vendor_name,
      vendorContactDetails: data.vendor_contact,
      approved: Boolean(data.slt_approved),
      acc_approved: Boolean(data.acc_approved),
      isBudgetedFor: Boolean(data.current_budget),
      requirement: data.requirement,
      items: data.items,
      price: data.total,
      requestor_id: data.requestor.id,
      requestorName: data.requestor.name,
      requestorJobTitle: data.requestor.position,
      requestorEmail: data.requestor.email,
      requestorDepartment: data.requestor.department,
      poAuthoriser: "Katie O'Reilly",
      poNumber: data.po_number,
      created_at: data.created_at,
      status: data.status,
      id: data.id,
    });
  };

  useEffect(() => {
    getContent().then((res: any) => {
      updateFormData(res.data.getPurchaseOrderByUuid);
    });
  }, [state]);

  if ([].includes(user.id)) {
    return (
      <div className={classes.noPermission}>
        <div className={classes.noPermissionContainer}>
          <h3 className={classes.message}>
            You do not have permission to view this page
          </h3>
          <Button
            onClick={() => navigate('/admin/purchase-orders')}
            title="Go Back"
          ></Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <FullScreenLoader display={contentLoading} />

      {!contentLoading && (
        <>
          <h2 className={classes.title}>PO Number #{formik.values.poNumber}</h2>
          <div
            className={clx(classes.layout, {
              [classes.guest]: !isAdmin,
            })}
          >
            <div>
              <div className={classes.headerBox}>
                <h3 className={classes.headerBoxTitle}>Requestor details</h3>
                <div className={classes.gridContainer}>
                  {requestorDetails.map((item) => (
                    <div key={item.key} className={classes.gridItem}>
                      <div className={classes.itemName}>{item.field}</div>
                      <div className={classes.itemValue}>
                        {
                          formik.values?.[
                            item.key as keyof typeof formik.values
                          ]
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.poDetails}>
                <h3 className={classes.poDetailsTitle}>PO Details</h3>
                <div className={classes.poDetailsData}>
                  {poDetails.map(({ key, field }) => (
                    <div key={key}>
                      <div className={classes.itemName}>{field}</div>
                      <p className={classes.itemValue}>
                        {formik.values?.[key as keyof typeof formik.values]}
                      </p>
                    </div>
                  ))}
                </div>
                <hr className={classes.hr} />
                <div className={classes.totalPrice}>
                  <div className={classes.totalPriceTitle}>Total price</div>
                  <div className={classes.totalPriceAmount}>
                    £ {formik.values.price}
                  </div>
                </div>
                <hr className={classes.hr} />
                <div className={classes.items}>
                  {formik.values.items.map((item, key) => (
                    <div key={key} className={classes.item}>
                      <div className={classes.itemTitle}>#Item {key + 1}</div>
                      <div className={classes.itemDetails}>{item}</div>
                    </div>
                  ))}
                </div>
                <hr className={classes.hr} />
                <div className={classes.businessRequirements}>
                  <div className={classes.businessRequirementsTitle}>
                    Business requirements
                  </div>
                  <div className={classes.businessRequirementsContent}>
                    <div className={classes.businessRequirementsText}>
                      {formik.values.requirement}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.vendorDetails}>
                <div className={classes.vendorDetailsTitle}>Vendor Details</div>
                <div className={classes.poDetailsData}>
                  {vendorDetails.map(({ key, field }) => (
                    <div key={key}>
                      <div className={classes.itemName}>{field}</div>
                      <p className={classes.itemValue}>
                        {formik.values?.[key as keyof typeof formik.values]}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {isAdmin && (
              <div>
                <ProgressTracker
                  formik={formik}
                  statusNumbers={statusNumbers}
                  loading={sltApproveLoading || updateLoading}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewPurchaseOrder;
