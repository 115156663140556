import React, { useState, useRef, useEffect } from 'react';
import useCustomPlayerStyles from './styles';
import { IoMdPause } from 'react-icons/io';

interface CustomVideoPlayerProps {
  videoSource: string;
  autoplay?: boolean;
  title?: string;
  className?: string;
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  videoSource,
  autoplay,
  title,
  className,
}) => {
  const classes = useCustomPlayerStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [showPlayButton, setShowPlayButton] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    if (video && autoplay) {
      video.play();
      setIsPlaying(true);
    }
  }, [autoplay]);

  const togglePlay = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
      setIsPlaying(!video.paused);
    }
  };

  return (
    <div className={classes.snapchat}>
      <video
        ref={videoRef}
        src={videoSource}
        loop
        onMouseEnter={() => setShowPlayButton(true)}
        onMouseLeave={() => setShowPlayButton(false)}
      />
      <div
        className={`play-button ${showPlayButton ? '' : 'hidden'}`}
        onClick={togglePlay}
        onMouseEnter={() => setShowPlayButton(true)}
      >
        {isPlaying ? (
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-23T103844/rxIEIIEyZEUp2BC5PpYM2y2pafkB72tETfT9Q97s.png'
            }
          />
        ) : (
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-23T100841/ZkpijjPGphdZIDcvvUjm9M2dpF7N3STK6qrfXBuU.png'
            }
          />
        )}
      </div>
      {/* <div className={clx(classes.snapInfo, className)}>
        <div className={classes.snapDetails}>
          <div className={classes.snapIcon}>
            <FaSnapchatGhost size={'20px'} />
          </div>
          <p className={classes.snapTitle}>{title}</p>
        </div>
      </div> */}
    </div>
  );
};

export default CustomVideoPlayer;
