import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const usePortalLandingStyles = makeStyles((theme: BaseTheme) => ({
  home: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.9fr 1fr',
    gap: theme.spacing(3),
    fontFamily: theme.design.font.universal,
    marginTop: theme.spacing(2),
    zIndex: -10,
    padding: theme.spacing(0, 8),

    '& .action_button': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      padding: theme.spacing(1.5),
      borderRadius: '10px',
      background: theme.design.colors.black,
      color: theme.design.colors.white,
      width: '254px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      fontSize: 14,

      '& .arrow_parent': {
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.design.colors.white,
        borderRadius: '3px',

        '& .icon': {
          color: theme.design.colors.black,
        },
      },
    },
  },
  right_section: {
    backgroundColor: theme.design.colors.white,
    padding: theme.spacing(2),
    borderRadius: '10px',
    border: `1px solid ${theme.design.colors.lidGrey}`,

    '& > div:first-child': {
      marginBottom: theme.spacing(2.5),
    },
  },
  leftSection: {
    backgroundColor: theme.design.colors.white,
    padding: theme.spacing(2),
    borderRadius: '10px',
    border: `1px solid ${theme.design.colors.lidGrey}`,

    '& .initial-widgets': {
      display: 'flex',

      '& .welcome-events': {
        display: 'flex',
        flexDirection: 'column',
        width: '320px',
        marginRight: theme.spacing(3),
      },

      '& .social': {
        marginTop: theme.spacing(2),
        width: '100%',
        height: '440px',
        zIndex: 0,
        marginBottom: theme.spacing(2),
      },
    },

    '& .final-widgets': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  left_section_right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(2.5),
  },
  widget: {
    width: '40%',
    marginTop: theme.spacing(2),
  },
  bottomSection: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  leftHeaderTitle: {
    fontSize: theme.typography.fontSize + 4,
    color: theme.design.colors.jpGrey300,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  gorillaIcon: {
    margin: theme.spacing(3, 0, 4, 3),
    width: '60%',
    cursor: 'pointer',
  },
  countdownParent: {
    fontFamily: theme.design.font.universal,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    '& p': {
      fontSize: theme.typography.fontSize - 2,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& span': {
      fontSize: theme.typography.fontSize - 4,
      textTransform: 'capitalize',
    },
  },
  social: {
    overflow: 'hidden',
  },
}));

export default usePortalLandingStyles;
