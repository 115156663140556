import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query fetchPlatformForArticles($site: String!) {
    getPlatformArticles(site: $site) {
      title
      description
      url
      site
      id
      apple_news_status
    }
  }
`;
