import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  profileHeader: {
    display: 'flex',
    fontSize: theme.typography.fontSize + 6,
    justifyContent: 'space-between',
    fontFamily: 'universal',
    fontWeight: theme.typography.fontWeightLight,
  },
  profileBody: {
    background: theme.design.colors.white,
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
  profileAvatar: {
    margin: theme.spacing(1),
    width: '142px',
    height: '142px',
    '& button': {
      display: 'none!important',
    },
    '& div, img': {
      borderRadius: '50%',
      height: '100% !important',
    },
    '& img': {
      padding: theme.spacing(0.5),
      boxSizing: 'border-box',
    },
    '& div div': {
      display: 'none!important',
    },
  },
  profileImage: {
    padding: theme.spacing(2, 0),
    width: '30%',
  },
  primary: {
    background: `transparent !important`,
    color: `${theme.design.colors.black} !important`,
    border: `1px solid ${theme.design.colors.black} !important`,
  },
  profileForm: {
    padding: theme.spacing(2),
  },
  embedTextInput: {
    background: theme.design.colors.grey98,
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(2),
    width: 'calc(100% - 24px)',
  },
  profileFirstInput: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(3,1fr)',
  },
  excerptText: {
    width: '60%',
    marginTop: theme.spacing(1),
  },
}));
