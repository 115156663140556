import { ApolloError, useMutation } from '@apollo/client';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { Button } from 'components/common/Button/Button';
import { forwardRef, useCallback, useState } from 'react';
import { useModal } from '../common/ModalManager';
import { Icon } from '@iconify/react';
import { CREATE_CAMPAIGN } from 'graphql/jcmat/createCampaign';
import { toast } from 'react-toastify';
import link from '../common/assets/link.svg';
import success from '../common/assets/success.svg';
import { isValidSocialUrl } from '../helper';
import { ADD_POST_TO_CAMPAIGN } from 'graphql/jcmat/addPostToCampaign';
import { useCommentAnalysis } from 'contexts/ca';
import SuccessfulCampaign from './SuccessfulCampaign';
import SearchPostPreviewCard from './SearchPostPreviewCard';

export default forwardRef<HTMLDivElement>((props, ref) => {
  const [data, setData] = useState({ name: '', description: '', postLink: '' });
  const [showNext, setShowNext] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [campaignUuid, setCampaignUuid] = useState('');
  const [error, setError] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [createCampaign] = useMutation(CREATE_CAMPAIGN);
  const [addPostToCampaign] = useMutation(ADD_POST_TO_CAMPAIGN);
  const { page, fetchCampaigns } = useCommentAnalysis();

  const { hideModal } = useModal();
  const disabled = true;

  const handleCreateCampaign = useCallback(async () => {
    setShowNext(true);

    try {
      const res = await createCampaign({
        variables: {
          name: data.name,
          description: data.description,
        },
      });
      setShowNext(true);
      setCampaignUuid(res.data.createCampaign.uuid);
    } catch (error) {
      if (error instanceof ApolloError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred.');
      }
    }
  }, [data, createCampaign]);

  const handleChange = useCallback(
    (event) => {
      setData({ ...data, [event.name]: event.value });
    },
    [data]
  );

  const handleAddPostToCampaign = useCallback(async () => {
    if (!data.postLink || !isValidSocialUrl(data.postLink)) {
      setError('Please enter a valid Facebook, Instagram, or LinkedIn URL.');
      return;
    }

    try {
      await addPostToCampaign({
        variables: {
          campaign_uuid: campaignUuid,
          post_url: data.postLink,
        },
      });
      setShowSuccess(true);
      fetchCampaigns(page + 1);
    } catch (error) {
      if (error instanceof ApolloError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred.');
      }
    }
  }, [data.postLink, addPostToCampaign]);

  return (
    <div ref={ref} className="space-y-8">
      {showSuccess ? (
        <SuccessfulCampaign hideModal={hideModal} />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <p className="text-lg">Create new campaign</p>

            <button onClick={hideModal}>
              <Icon icon="ic:round-close" width={20} color="black" />
            </button>
          </div>

          <p className="text-[#383838]">
            {showNext
              ? 'Add posts to your campaign'
              : 'Provide Campaign Details'}
          </p>

          {showNext ? (
            <>
              <div className="mr-5 space-y-5">
                <TextInput
                  width="100%"
                  height="25px"
                  name={'postLink'}
                  leftIcon={<img src={link} className="mr-2" />}
                  value={data.postLink}
                  onChange={handleChange}
                  placeholder="Enter post link to add"
                  borderRadius="8px"
                  className="text-[#6C747A] placeholder:text-[#6C747A]"
                />
              </div>
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </>
          ) : (
            <div className="mr-5 space-y-5">
              <TextInput
                width="100%"
                height="25px"
                name={'name'}
                value={data.name}
                onChange={handleChange}
                label="Campaign Name"
                placeholder="Enter a preferred title for your campaign"
                borderRadius="8px"
                className="text-[#6C747A] placeholder:text-[#6C747A]"
              />

              <TextInput
                width="100%"
                height="25px"
                name={'description'}
                value={data.description}
                onChange={handleChange}
                label="Campaign Description"
                placeholder="Enter a preferred description for your campaign"
                borderRadius="8px"
                className="text-[#6C747A] placeholder:text-[#6C747A]"
              />
            </div>
          )}

          <div>
            <Button
              onClick={
                data.postLink ? handleAddPostToCampaign : handleCreateCampaign
              }
              className={`!rounded-full w-full !h-[56px] ${
                !data.name || !data.description
                  ? '!bg-[#DBDFE6] !shadow-none'
                  : ''
              }`}
            >
              {showNext ? 'Add Post to Campaign' : 'Create Campaign'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
});
