import { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  btnText: string;
}

export default function TabButton({ onClick, active = false, btnText }: Props) {
  return (
    <button
      onClick={onClick}
      style={{ border: `2px solid ${active ? '#5DEB9473' : '#E5E5E5'}` }}
      className={`cursor - pointer rounded-full px-5 py-3 space-x-2 flex items-center border-2 ${
        active
          ? 'border-[#5DEB9473] bg-[#f6fff9] text-[#1E9D4F]'
          : 'border-[#E5E5E5] text-[#8B8B8B]'
      }`}
    >
      {btnText}
    </button>
  );
}
