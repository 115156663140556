// Helper method to transform the JSON config returned from the

// import { FournineSectionProps } from "./types";
import upperFirst from 'lodash/upperCase';
import lowerCase from 'lodash/lowerCase';
import { SectionProps } from '../components/VTPages/types';
import { notifyError } from 'utilities/notify';
import { ApolloError } from '@apollo/client';
import { useEffect, useRef } from 'react';

// API into the FournineSectionProps[] type
export const parseSectionsIntoProps = (sections: SectionProps) => {
  const keys = Object.keys(sections);

  const values = Object.values(sections);
  const parsedSections: Array<SectionProps> = [];
  keys.forEach((key, index) => {
    parsedSections.push({ name: key, ...values[index] });
  });
  return parsedSections;
};

// Helper method to transform the state props from the component
// into JSON that will be returned to the API
export const parseSectionsIntoJson = (
  sections: SectionProps[],
  form: { [key: string]: Record<string, any> }
) => {
  const content: { [key: string]: Record<string, any> } = {};
  sections.forEach(({ name, ...data }) => {
    data.enabled = form[name].enabled;
    Object.keys(data.sections).forEach((value) => {
      data.sections[value].data = form[name][value];
    });
    content[name] = data;
  });
  return JSON.stringify(content);
};

export const camelCaseToTitleCase = (string: string): string => {
  return upperFirst(lowerCase(string));
};

export const getFormDataFromConfig = (keys: SectionProps[]) => {
  let form: { [key: string]: any } = {};
  keys.forEach((key) => {
    const groups = Object.keys(key.sections);
    let formGroupData: { [key: string]: any } = {};

    groups.forEach((group) => {
      formGroupData = {
        ...formGroupData,
        [group]: key.sections[group].canHaveMultiple
          ? key.sections[group].data ?? []
          : key.sections[group].data ?? {},
      };
    });

    form = {
      ...form,
      [key.name]: {
        ...form[key.name],
        ...formGroupData,
        enabled: key.enabled,
      },
    };
  });
  return form;
};

export const handleAddNewCard = (
  setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
  sectionName: string,
  groupName: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _oldForm: { [key: string]: string }
) => {
  setForm((form: { [key: string]: any }) => {
    const newArray = [...form[sectionName][groupName]];
    newArray.push(
      (() => {
        const keys = Object.keys(form[sectionName][groupName][0] ?? {});
        const newForm: { [key: string]: string } = {};
        keys.map((key: string) => {
          newForm[key] = '';
        });
        return newForm;
      })()
    );
    return {
      ...form,
      [sectionName]: {
        ...form[sectionName],
        [groupName]: newArray,
      },
    };
  });
};

export const handleRemoveCard = (
  setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
  sectionName: string,
  groupName: string,
  index: number
) => {
  setForm((form: { [key: string]: any }) => {
    const newArray = [...form[sectionName][groupName]];
    newArray.splice(index, 1);
    return {
      ...form,
      [sectionName]: {
        ...form[sectionName],
        [groupName]: newArray,
      },
    };
  });
};

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
export const updateConfigWithFormData = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _mainSections: SectionProps[]
) => {
  //
};

export const validateImg = (file: File, fileSize?: number) => {
  const allowedTypes = ['jpeg', 'jpg', 'png', 'svg', 'webp'];
  const type = file.type.split('/')[1];
  const size = file.size;

  if (!allowedTypes.includes(type)) {
    notifyError('File type not supported');
    return false;
  }

  if (size > (fileSize ?? 7000000)) {
    notifyError('Image cannot be larger than 2MB. Please try again');
    return false;
  }

  return true;
};

export const errorHandler = (error: ApolloError, key?: string) => {
  if (error.graphQLErrors) {
    error.graphQLErrors.map(({ extensions, message }) => {
      if (extensions?.validation) {
        if (key && (extensions as any)?.validation?.[key][0])
          return notifyError((extensions as any)?.validation?.[key][0] ?? '');
        Object.entries(extensions.validation).map(([, messages]: any) =>
          messages.map((message: string) => notifyError(message))
        );
      } else if (extensions?.reason) {
        return notifyError((extensions as any)?.reason ?? '');
      } else {
        return notifyError(message);
      }
    });
  }
  return key;
};
export const getActiveSiteOrDefault = (): {
  site: string;
  siteDetails: {
    id: string;
    uuid: string;
    blog_uuid: string;
    url: string;
    library_uuid: string;
  };
  channelDetails: {
    businessProfileId: string;
    _id: string;
  };
  channel: string;
  status: boolean;
} => {
  const activeSite = localStorage.getItem('active_site');

  if (activeSite) {
    return {
      status: false,
      ...JSON.parse(activeSite),
    };
  }

  return {
    site: 'vt',
    siteDetails: {
      id: '4',
      uuid: '73871f84-154c-48e7-a496-25f17aacb298',
      blog_uuid: import.meta.env.VITE_BLOG_UUID,
      url: 'https://vt.co',
      library_uuid: '1d86966e-e893-4e90-8ce3-286eed76e5dc',
    },
    channelDetails: {
      businessProfileId: '5c9c44eb-019a-4e85-b675-6a40ce4f94a9',
      _id: '64a83786347aeff3bc3d1846',
    },
    channel: 'Twisted Explore',
    status: true,
  };
};

export const usePrevious = (value: string) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isEqual = (
  a: { [key: string]: any },
  b: { [key: string]: any }
): Boolean => {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b;
  if (a === null || a === undefined || b === null || b === undefined)
    return false;
  if (a.prototype !== b.prototype) return false;
  let keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every((k) => isEqual(a[k], b[k]));
};

export const compareObjects = (
  o: { [key: string]: any },
  p: { [key: string]: any }
): boolean => {
  var i,
    keysO = Object.keys(o).sort(),
    keysP = Object.keys(p).sort();

  if (keysO.length !== keysP.length) return false; // not the same number of keys
  if (keysO.join('') !== keysP.join('')) return false; // different keys

  for (i = 0; i < keysO.length; ++i) {
    if (Array.isArray(o[keysO[i]])) {
      if (!Array.isArray(p[keysO[i]])) return false;

      // Create copies of the arrays before sorting to avoid modifying the original arrays
      const oArraySorted = [...o[keysO[i]]].sort();
      const pArraySorted = [...p[keysO[i]]].sort();

      if (compareObjects(oArraySorted, pArraySorted) === false) return false;
      if (oArraySorted.join('') !== pArraySorted.join('')) return false;
    } else if (o[keysO[i]] instanceof Date) {
      if (!(p[keysO[i]] instanceof Date)) return false;
      if ('' + o[keysO[i]] !== '' + p[keysO[i]]) return false;
    } else if (typeof o[keysO[i]] === 'function') {
      if (typeof p[keysO[i]] !== 'function') return false;
    } else if (o[keysO[i]] instanceof Object) {
      if (!(p[keysO[i]] instanceof Object)) return false;
      if (o[keysO[i]] === o) {
        if (p[keysO[i]] !== p) return false;
      } else if (compareObjects(o[keysO[i]], p[keysO[i]]) === false)
        return false; // WARNING: does not deal with circular refs other than ^^
    } else if (o[keysO[i]] !== p[keysO[i]]) {
      // change !== to != for loose comparison
      return false; // not the same value
    }
  }
  return true;
};

export const waitForObjectProperty = (property: any) =>
  new Promise((resolve) => {
    const reCheckTimeOut = setTimeout(
      () => waitForObjectProperty(property),
      10
    );

    if (typeof property !== 'undefined') {
      clearTimeout(reCheckTimeOut);

      resolve(null);
    }
  });

export const addScriptTag = ({
  src = null,
  innerHTML = null,
  tag = 'head',
  async = false,
  id = null,
}: {
  src?: string | null;
  innerHTML?: string | null;
  tag?: string;
  async?: boolean;
  id?: string | null;
}): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');

    if (src) script.src = src;
    if (innerHTML) script.innerHTML = innerHTML;
    if (id) script.id = id;
    if (async) script.async = true;
    script.defer = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = reject;

    const targetElement = document.getElementsByTagName(tag)[0];
    if (targetElement) {
      targetElement.appendChild(script);
    } else {
      reject(new Error(`Target element "${tag}" not found`));
    }
  });
