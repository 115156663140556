import { gql } from '@apollo/client';

export const FETCHNEWSLETTERS = gql`
  query getNewsletters($first: Int, $page: Int) {
    newsletters(first: $first, page: $page) {
      data {
        id
        title
        url
        image
        date
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETENEWSLETTER = gql`
  mutation deleteNewsletter($id: ID!) {
    deleteNewsletter(id: $id) {
      id
    }
  }
`;
export const GETNEWSLETTERS = gql`
  query getNewsletter($id: ID!) {
    newsletter(id: $id) {
      title
      image
      url
    }
  }
`;
