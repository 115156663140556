import { gql } from '@apollo/client';

export const FETCHCONFIG = gql`
  query fetchConfig($route: String!, $site: String!) {
    getConfig(route: $route, site: $site)
  }
`;

export const UPDATECONFIG = gql`
  mutation UpdatePageConfigs(
    $route: String!
    $site: String!
    $content: String!
  ) {
    updatePageConfig(route: $route, site: $site, content: $content)
  }
`;
