import { ApolloError, useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { notifySuccess } from '../../utilities/notify';
import { Button } from '../common/Button/Button';
import TextField from '../common/TextField/TextField';
import { PURGECACHE } from './graphQL';
import { useClearCacheStyles } from './styles';
import classNames from 'classnames';
import { SelectInput, TextInput } from '@wazobia-tech/wazcom/dist/io';

const ClearCache: React.FC = () => {
  const classes = useClearCacheStyles();
  const [purge, { loading }] = useMutation(PURGECACHE);
  const [data, setData] = useState<{ [key: string]: string }>({
    label: 'https://vt.co',
    url: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>();

  /**
   * Checks if "recipes" is the first path segment in a URL
   * @param url The URL to check
   * @returns boolean indicating whether "recipes" is the first path segment
   */
  function isRecipesFirstPathSegment(url: string): boolean {
    try {
      // Parse the URL
      const parsedUrl = new URL(url);

      // Get the pathname and remove leading slash
      const path = parsedUrl.pathname.startsWith('/')
        ? parsedUrl.pathname.substring(1)
        : parsedUrl.pathname;

      // Split the path into segments
      const pathSegments = path.split('/');

      // Check if the first segment is "recipes"
      return pathSegments[0].toLowerCase() === 'recipes';
    } catch (error) {
      // If URL parsing fails, return false
      console.error('Invalid URL:', error);
      return false;
    }
  }

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      if (!data.label || !data.url) {
        setErrors({
          url: 'Incomplete URL, please select a site url and provide a slug',
        });
        return null;
      }
      await purge({
        variables: {
          url: data?.url,
          type: isRecipesFirstPathSegment(data?.url) ? 'recipes' : 'articles',
        },
      })
        .then(({ data }) => {
          notifySuccess(data.purgeCache.message);
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.map(({ message }) => toast.error(message));
        });
    },
    [data]
  );

  const handleChange = ({ target: { name, value } }: any) => {
    setData({ ...data, [name]: value });
  };

  const sites: {
    label: string;
    options: { label: string; value: string }[];
  }[] = [
    {
      label: 'URL',
      options: [
        { label: 'https://vt.co', value: 'https://vt.co' },
        {
          label: 'https://twistedfood.co.uk',
          value: 'https://twistedfood.co.uk',
        },
        { label: 'https://fournine.net', value: 'https://fournine.net' },
        {
          label: 'https://junglecreations.com',
          value: 'https://junglecreations.com',
        },
        { label: 'https://thewild.agency', value: 'https://thewild.agency' },
      ],
    },
  ];

  return (
    <div>
      <p className={classes.content__title}>Clear Cache</p>
      <form className={classes.cache_form} onSubmit={handleSubmit}>
        <div className={classes.content__form_section}>
          {/* {sites.map((site, index) => (
            <SelectInput
              name="label"
              label="Site URL"
              key={index}
              options={site?.options}
              value={data?.label}
              onChange={(data) =>
                handleChange({ target: { name: data.name, value: data.value } })
              }
              className={classes.content__formSelect}
              required
            />
          ))} */}
          <TextInput
            type="text"
            name="url"
            value={data?.url}
            label="Target Url"
            required
            onChange={(data) => handleChange({ target: { ...data } })}
            className={classes.content__formText}
          />
        </div>
        {errors?.url && (
          <p className={classes.content__form_error}>{errors.url}</p>
        )}
        <Button
          type="submit"
          title="Clear Cache"
          loading={loading}
          className={classes.buttonAlign}
        />
      </form>
    </div>
  );
};

export default ClearCache;
