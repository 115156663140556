import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  titles: {
    display: 'flex',
    overflowX: 'scroll',
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(3, 5),
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    'scrollbar-color': 'transparent transparent',
  },
  Scroller: {
    display: 'flex',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'thin',
    'scrollbar-color': 'transparent transparent',
    margin: theme.spacing(3, 0),
    // ' &::-webkit-scrollbar': {
    //   display: 'none',
    //   innerHeight: '20%',
    // },
  },
  title: {
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize * 2,
    background: theme.design.colors.pink200,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3, 4),
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginRight: theme.spacing(3),
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
  activeTitle: {
    background: theme.design.colors.pink100,
    color: theme.design.colors.white,
  },
}));
