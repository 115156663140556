import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    background: theme.design.colors.yellow,
    '& .loader': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: -9999,
      background: theme.design.colors.widgetGrey,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 0.5s ease-in',
    },

    '& .active': {
      opacity: 1,
      zIndex: 10,
    },

    '& .empty-message': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 1,
      zIndex: 10,
      background: theme.design.colors.widgetGrey,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  containerContent: {
    padding: theme.spacing(2),
    borderRadius: '16px',
    background: theme.design.colors.jpGrey400,
    marginTop: theme.spacing(1),
  },
  container__surveys: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    paddingTop: `${theme.spacing(4)}px`,

    '&::-webkit-scrollbar': {
      height: '0px',
    },

    '& .survey_parent': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      flexShrink: 0,

      '& .survey': {
        display: 'flex',
        width: '100%',
        flexShrink: 0,
        marginBottom: theme.spacing(2),

        '& p': {
          fontSize: 14,
          color: theme.design.colors.black,
        },
      },

      '& .link': {
        width: '16px',
        height: '16px',
        marginRight: theme.spacing(3),
      },

      '& .text': {
        width: 'calc(100% - 48px)',
        fontSize: 14,
        textDecoration: 'underline',
        textDecorationColor: theme.design.colors.black,
      },
    },
  },
  container__polls: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    paddingTop: `${theme.spacing(3)}px`,

    '&::-webkit-scrollbar': {
      height: '0px',
    },

    '& .poll': {
      display: 'flex',
      width: '100%',
      height: '240px',
      flexShrink: 0,
    },
  },
  container__nav: {
    position: 'absolute',
    bottom: '16px',
    right: '18px',
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize + 4,
    fontFamily: 'Feature',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closingPolls: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.grey200,
    fontFamily: 'Feature',
    '& span': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  pollTitle: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  pollAuthor: {
    fontSize: theme.typography.fontSize - 2,
    textTransform: 'uppercase',
    color: theme.design.colors.darkCharcoal,
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  countDown: {
    textAlign: 'center',
  },
  ansBtn: {
    width: '100%',
    borderRadius: '20px !important',
    display: 'flex !important',
    justifyContent: 'space-around !important',
    fontFamily: 'Universal !important',
  },
  btnContainer: {
    marginTop: theme.spacing(1.5),
    width: '210px',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  poll: {
    width: '100%',
    height: 'fit-content',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  box: {
    width: 600,
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  emptyPolls: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > span': {
      fontSize: 12,
    },
  },
}));

export default useStyles;
