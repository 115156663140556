import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    width: '100%',
    background: theme.design.colors.white,
    borderRadius: '4px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    boxShadow: `0px 2px 6px ${theme.design.colors.shadow100}`,
    minHeight: '295px',
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    width: '90%',
    margin: theme.spacing(0, 'auto'),
    fontFamily: theme.design.font.title,
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightMedium,
    '& span': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  divider: {
    border: `0.5px solid ${theme.design.colors.grey900}`,
    margin: theme.spacing(1.5, 0, 1, 0),
  },
  title_icon: {
    color: theme.design.colors.titleColor,
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },
  list_container: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: theme.spacing(1),
    width: '90%',
    margin: theme.spacing(0, 'auto'),
  },
  list_image: {
    marginTop: theme.spacing(0.5),
    position: 'relative',
    width: '30px',
    height: '30px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  list_details: {
    flex: 4,
    flexDirection: 'column',
    '& p': {
      marginTop: theme.spacing(0.6),
    },
  },
  list_icon: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listDescription: {
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.greyDark,
  },
  name: {
    fontSize: '18px',
    color: theme.design.colors.greyDark,
  },
  navigation: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& p': {
      textDecoration: 'underline',
      fontSize: '18px',
    },
  },
  loader: {
    margin: theme.spacing(6),
    color: theme.design.colors.greyDark,
  },
  loader_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scroll: {
    overflowY: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '3px',
      background: theme.design.colors.scrollBarBackground,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.design.colors.scrollBarThumbBackground,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.design.colors.scrollBarTrackBackground,
    },
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
  },
  description: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.grey200,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
  },
  annoucementTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.design.colors.grey100,
    textTransform: 'capitalize',
  },
  datePosted: {
    background: theme.design.colors.blue100,
    padding: theme.spacing(0.3, 1),
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.grey650,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  event: {
    fontSize: 10,
    padding: theme.spacing(1, 2),
  },
  headerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.design.font.universal,
  },
  oldContainer: {
    width: '100%',
    background: theme.design.colors.white,
    borderRadius: '10px',
    minHeight: '240px',
    border: `1px solid ${theme.design.colors.widgetBorder}`,
    position: 'relative',
    marginBottom: theme.spacing(3.5),
  },
  oldHeaderTitle: {
    fontSize: theme.typography?.fontSize + 8,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography?.fontWeightMedium,
  },
}));

export default useStyles;
