/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SectionProps } from './types';
import { useVTPageStyles } from './styles';
import { Button } from '../common/Button/Button';
import SideTabs from '../common/SideTabs/SideTabs';
import { useMutation, useQuery } from '@apollo/client';
import { notifySuccess } from '../../utilities/notify';
import { CircularProgress, Switch } from '@mui/material';
import { TwistedSectionsValidity, ValidationContext, VTSectionsValidity } from 'contexts/validation';
import FormGroup from '../FormBuilder/FormGroup/FormGroup';
import { FETCHCONFIG } from '../../utilities/graphql/queries';
import { UPDATECONFIG } from '../../utilities/graphql/mutations';
import { camelCaseToTitleCase, getFormDataFromConfig, parseSectionsIntoJson, parseSectionsIntoProps } from '../../utilities/helpers';

const VTPage: React.FC = () => {
  const classes = useVTPageStyles();
  const splitPath = location.pathname.split('/');
  const currentPath = splitPath[splitPath.length - 1];
  const previousPath = splitPath[splitPath.length - 2];
  const { isFormValid } = useContext(ValidationContext)
  const [activeTabId, setActiveTabId] = useState<number>(0);
  const [sections, setSections] = useState<SectionProps[]>([]);
  const [form, setForm] = useState<{ [key: string]: any }>({});
  const [, setInitialSections] = useState<SectionProps[]>([]);

  const getPageRoute = () => {
    switch (currentPath) {
      case 'home':
        return 'home';
      case 'team':
        return 'team';
      default:
        return currentPath;
    }
  };

  // data fetching
  const { data, loading } = useQuery(FETCHCONFIG, {
    variables: { route: getPageRoute(), site: 'vt' },
    fetchPolicy: 'network-only',
  });

  const url = `${import.meta.env.VITE_VT_REST_URL
    }/api/revalidate?path=/&secret=${import.meta.env.VITE_VT_SECRECT_KEY}`;

  const [updateConfigMutation, { loading: updateLoading }] =
    useMutation(UPDATECONFIG);

  useEffect(() => {
    if (data) {
      const route = getPageRoute();
      const { getConfig: pageContent } = data;
      const {
        config: {
          [route]: { content: sections },
        },
      } = JSON.parse(pageContent);
      const parsedSections = parseSectionsIntoProps(JSON.parse(sections));
      setForm(getFormDataFromConfig(parsedSections));
      setInitialSections(JSON.parse(JSON.stringify(parsedSections)));
      setSections(parsedSections);
    }
  }, [data]);

  const updateConfig = useCallback(async () => {
    if (updateLoading) return;
    const content = parseSectionsIntoJson(sections, form);
    try {
      const variables = {
        route: `${getPageRoute()}`,
        site: 'vt',
        content,
      };
      await updateConfigMutation({ variables });
      fetch(url)
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
      notifySuccess('Updated successfully.');
      const parsedSections = parseSectionsIntoProps(JSON.parse(content));
      setInitialSections(JSON.parse(JSON.stringify(parsedSections)));
    } catch (error) {
      //
    }
  }, [sections, form]);

  const displayTabContent = useCallback(() => {
    const fieldSections = Object.keys(sections?.[activeTabId]?.sections ?? {});

    return (
      <>
        <div className={classes.enabled}>
          <p>Enabled</p>
          <Switch
            inputProps={{ 'aria-label': 'demo' }}
            defaultChecked
            name={sections?.[activeTabId]?.name}
            className={classes.switch}
            onChange={() => {
              setForm((form: { [key: string]: any }) => {
                return {
                  ...form,
                  [sections?.[activeTabId]?.name]: {
                    ...form[sections?.[activeTabId]?.name],
                    enabled: !form[sections?.[activeTabId]?.name]?.enabled,
                  },
                };
              });
            }}
            checked={Boolean(form[sections?.[activeTabId]?.name]?.enabled)}
          />
        </div>
        {fieldSections.map((value: string, idx) => (
          <FormGroup
            key={idx}
            {...sections?.[activeTabId]?.sections[value]}
            name={value}
            sectionName={sections ? sections[activeTabId]?.name : ''}
            form={form}
            setForm={setForm}
          />
        ))}
      </>
    );
  }, [sections, activeTabId, form]);

  const displayVtSections = () => {
    return sections.map(({ name }, index) => {
      const formattedName = camelCaseToTitleCase(name);

      return (
        <SideTabs
          key={index}
          title={formattedName}
          isActive={activeTabId === index}
          handleClick={() => {
            setActiveTabId(index);
          }}
        />
      );
    });
  };

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>
          {splitPath.length > 3 ? `${previousPath} ` : `${currentPath}`}
        </p>
        <div className={classes.publishBtn}>
          <Button
            title="Publish"
            disabled={!sections || !isFormValid(sections[activeTabId]?.name as keyof (VTSectionsValidity | TwistedSectionsValidity))}
            loading={updateLoading}
            onClick={updateConfig}
          />
        </div>
      </div>
      <div className={classes.root}>
        {loading && Object.keys(form).length < 1 ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <div className={classes.sectionsContainer}>
            <div className={classes.sectionsTab}>{displayVtSections()}</div>
            <form className={classes.form}>{displayTabContent()}</form>
          </div>
        )}
      </div>
    </>
  );
};

export default VTPage;
