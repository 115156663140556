export interface ValidationProps {
  status: boolean;
  errorText?: string | undefined;
}
export interface ValidationArgs {
  name: string;
  value: string;
  password?: string;
}

export const isRequired = ({
  name,
  value,
}: ValidationArgs): ValidationProps => ({
  status: !!value,
  errorText: `The ${name} field is required`,
});
