import { FC, useContext, useEffect, useState } from 'react';
import { CreateRecipeProps } from './types';
import { unstable_usePrompt, useNavigate, useParams } from 'react-router-dom';
import useStyles from 'components/Blog/components/CreateContent/styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { useStylesRecipe } from './styles';
import clx from 'classnames';
import ReactQuill from 'react-quill';
import { Header } from './components/Header';
import {
  SelectInput,
  TextInput,
  ToggleInput,
} from '@wazobia-tech/wazcom/dist/io';
import { CTAs } from './components/CTAs';
import { AppStateContext } from 'contexts/provider';
import {
  getCookingTimeDetails,
  getOptions,
  getRecipeInformation,
  useRecipeAutosave,
  validateRecipeInformation,
  getSeoData,
  getSocialData,
  getTwitterData,
  showRightSidebar,
  serveOptions,
} from './helper';
import { CreateMeasurement } from './components/CreateMeasurement';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CREATE_RECIPE_CATEGORY,
  GET_RECIPE_BY_UUID,
  GET_RECIPE_CATEGORIES,
  GET_RECIPE_TAGS,
} from 'components/Blog/graphQL';
import { blogClient, recipeClient } from 'utilities/apolloClient';
import { createRecipe, notify, updateRecipe } from '../helpers';
import { notifySuccess } from 'utilities/notify';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import {
  CategoryForm,
  CreateContentLayout,
  DetailsForm,
  RECIPE_PAGE_TYPE,
  SchemaForm,
  SeoForm,
  SocialsForm,
  TagForm,
  UTMGeneratorForm,
} from 'layouts';
import { RelatedPosts } from 'components/Blog/components/RelatedPost';
import { errorHandler } from 'utilities/helpers';
import { PURGECACHE } from 'components/ClearCache/graphQL';
import { useGetPreSlugURL } from 'components/Blog/helpers';

export const CreateRecipe: FC<CreateRecipeProps> = ({}) => {
  const navigate = useNavigate();
  const classes = { ...useStyles(), ...useStylesRecipe() };
  const { siteDetails, user } = useContext(AppStateContext);
  const [purgeCache] = useMutation(PURGECACHE);
  const { uuid: recipeUuid } = useParams();
  const getPreSlugURL = useGetPreSlugURL();
  const [submitting, setSubmitting] = useState({
    draft: false,
    publish: false,
  });

  // Removing old data for autosaving feature
  localStorage.removeItem('recipeFormData');

  const [embedAction, setEmbedAction] = useState<string>('');
  const [formData, setFormData] = useRecipeAutosave(
    {
      is_comments_enabled: true,
      is_in_sitemap: true,
      is_feature_article: false,
      is_autofilled_suggestion: false,
      same_as_socials: true,
      site: 'twisted',
      title: '',
      ingredients_list: [
        {
          list_heading: '',
          list: [{ ingredient: '', amount: '' }],
        },
      ],
      measurement: 'Gram (g)',
      author_uuid: user.uuid,
      status: false,
      saving: false,
      meta_utmGenerator: {
        websiteUrl: '',
        campaignId: '',
        campaignSource: '',
        campaignMedium: '',
        campaignName: '',
        campaignContent: '',
      },
    },
    {
      site_uuid: siteDetails.uuid,
    }
  );

  const handleAddCreateList = () => {
    const ingredients_list = [
      ...formData.ingredients_list,
      {
        list_heading: '',
        list: [
          {
            ingredient: '',
            amount: '',
          },
        ],
      },
    ];
    setFormData({
      ...formData,
      ingredients_list,
    });
  };

  const handleValidate = async (name: string, status: boolean) => {
    const invalidFields = validateRecipeInformation(formData, status);

    if (invalidFields.length > 0) {
      return notify(
        'error',
        `${invalidFields.join(', ')} ${
          invalidFields.length > 1 ? 'are' : 'is'
        } required`
      );
    }
    formData['status'] = status;
    handleSubmit(status);
  };

  const handleSubmit = async (status: boolean) => {
    try {
      const variables = getRecipeInformation(formData, siteDetails.uuid);
        variables.permalink = variables.permalink?.trim() || "";
        setSubmitting((prev) => ({
        ...prev,
        [status ? "publish" : "draft"]: true,
      }));
  
      if (!formData?.created_at) {
        const res = await createRecipe(variables);
        notifySuccess("Recipe created successfully");
          setFormData((prev) => ({
          ...prev,
          status,
          recipe_uuid: res.createRecipe.uuid,
          created_at: res.createRecipe.created_at,
        }));
  
        navigate(`/cms/recipes/${res.createRecipe.uuid}`);
      } else {
        await updateRecipe({ ...variables, uuid: formData.recipe_uuid });
  
        await purgeCache({
          variables: {
            url: getPreSlugURL() + variables.slug,
            type: "recipes",
          },
        }).catch(console.log);
  
        notifySuccess("Recipe updated successfully");
  
        setFormData((prev) => ({
          ...prev,
          status,
        }));
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        errorHandler(error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setSubmitting((prev) => ({
        ...prev,
        draft: false,
        publish: false,
      }));
    }
  };
  

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let maxWords = 0;
    if (e.target.name === 'title') {
      maxWords = 75;
    }
    const words = e.target.value.split(/\s+/);
    if (words.length <= maxWords) {
      setFormData((prev) => ({ ...prev, title: e.target.value }));
    }
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    setFormData({ ...formData, [name]: JSON.stringify(url) });

    handleClose();
  };

  const handleFormChange = (e: any) => {
    setFormData((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
  };

  const headerData = [
    {
      name: 'Title',
      description: '',
      required: true,
      children: (
        <>
          <textarea
            name="title"
            placeholder="Add title"
            onChange={handleTitleChange}
            className={clx(classes.excerptText, classes.otherExcerptText)}
            value={decodeHtml(formData.title)}
          ></textarea>
          <p className={classes.characterlength}>
            {decodeHtml(formData.title).length}/75 characters
          </p>
        </>
      ),
    },
    {
      name: 'Short description',
      required: true,
      description:
        'A short description about the recipe that is directly under the heading.',
      children: (
        <>
          <ReactQuill
            className={classes.reactQuill}
            placeholder="Add description here"
            onChange={(value) => {
              handleFormChange({ target: { name: 'excerpt', value } });
            }}
            value={formData.excerpt}
          />
          <p className={classes.characterlength}>
            {formData.excerpt?.length ?? 0}/175 characters
          </p>
        </>
      ),
    },
    {
      name: 'Full Description',
      required: true,
      description:
        'A detailed description of your recipe that is in the content',
      children: (
        <>
          <ReactQuill
            className={classes.reactQuill}
            placeholder="Add description here"
            onChange={(value) => {
              handleFormChange({ target: { name: 'description', value } });
            }}
            value={formData.description}
          />
          <p className={classes.characterlength}>
            {formData.description?.length ?? 0}/175 characters
          </p>
        </>
      ),
    },
    {
      name: 'Video Link',
      description: 'URL to the recipe video',
      children: (
        <div className={clx(classes.padding, classes.background)}>
          <TextInput
            label="URL"
            name="video_link"
            placeholder="Please link here"
            value={formData.video_link}
            onChange={({ name, value }) => {
              handleFormChange({ target: { name, value } });
            }}
            classNames={{ input: classes.embedTextInput }}
          />
        </div>
      ),
    },
    {
      name: 'Time',
      description: 'The time it takes to prepare the meal',
      children: (
        <>
          <div className={clx(classes.time, classes.background)}>
            <TextInput
              label="TIME"
              name="serving_time"
              placeholder="E.g 30"
              value={formData.serving_time}
              onChange={({ name, value }) => {
                handleFormChange({ target: { name, value } });
              }}
              classNames={{ input: classes.embedTextInput }}
            />
            <SelectInput
              options={getOptions(['', 'Minute(s)', 'Hour(s)'])}
              value={formData.serving_time_unit}
              name="serving_time_unit"
              className={classes.primaryCategory}
              label="Unit"
              onChange={({ name, value }) => {
                handleFormChange({ target: { name, value } });
              }}
            />
          </div>
        </>
      ),
    },
    {
      name: 'Serving size',
      required: true,
      description: 'The amount of people the recipes serves',
      children: (
        <div
          className={clx(classes.padding, classes.background, classes.serves)}
        >
          <ToggleInput
            className={classes.exactNumberToggle}
            classNames={{ toggleInput: classes.exactNumberToggleInput }}
            value={formData['use_exact_number']}
            onChange={({
              value: checked,
            }: {
              name: string;
              value: boolean;
            }) => {
              setFormData({ ...formData, use_exact_number: checked });
            }}
            label="Specify an exact number"
          />
          {formData?.use_exact_number ? (
            <TextInput
              label="HOW MANY PEOPLE?"
              name="serves"
              placeholder="0"
              value={formData.serves}
              onChange={({ name, value }) => {
                handleFormChange({ target: { name, value } });
              }}
              classNames={{ input: classes.embedTextInput }}
            />
          ) : (
            <SelectInput
              options={getOptions(serveOptions)}
              value={formData.serves}
              name="serves"
              className={classes.exactNumberInput}
              label="How many people"
              onChange={({ name, value }) => {
                handleFormChange({ target: { name, value } });
              }}
            />
          )}
        </div>
      ),
    },
    {
      name: 'Ingredient',
      description: 'Add the ingredients for the recipe',
      children: (
        <div
          className={clx(
            classes.padding,
            classes.bottomPadding,
            classes.background
          )}
        >
          <br />
          {formData?.ingredients_list?.map((list: any, index: number) => {
            return (
              <CreateMeasurement
                index={index}
                unit="g"
                list={list}
                form={formData}
                setFormData={setFormData}
                key={index}
              />
            );
          })}
          <div className={classes.addBtn} onClick={handleAddCreateList}>
            <AiOutlinePlus />
            <p>Add list</p>
          </div>
        </div>
      ),
    },
    {
      name: 'Method',
      description: 'The method of the recipe to follow',
      children: (
        <ReactQuill
          className={classes.reactQuill}
          placeholder="Add description here"
          value={formData.method}
          onChange={(value) => {
            handleFormChange({ target: { name: 'method', value } });
          }}
        />
      ),
    },
  ];

  const updateRecipeData = (recipe_data: { [key: string]: any }) => {
    if (recipe_data) {
      const { time: serving_time, measurement: serving_time_unit } =
        getCookingTimeDetails(recipe_data.cooking_time);
      const recipe_config =
        JSON.parse(recipe_data.config.social_preview_config) ?? {};
      setFormData((oldFormData) => ({
        ...oldFormData,
        recipe_uuid: recipe_data?.uuid,
        title: recipe_data.title,
        description: recipe_data.description,
        serving_time_unit,
        serving_time,
        serves: recipe_data.serves,
        measurement: recipe_data.measurement,
        method: recipe_data.method,
        author_uuid: recipe_data.author_uuid,
        excerpt: recipe_data.excerpt,
        status: Boolean(recipe_data.status),
        featured_image: JSON.stringify(parseRecursive(recipe_data.cover_image)),
        slug: recipe_data.slug,
        permalink: recipe_data.permalink,
        video_link: recipe_data.video_link,
        ingredients_list: JSON.parse(recipe_data.ingredient_lists),
        published_at: recipe_data.published_at,
        is_dark_post: !recipe_data.config.is_in_sitemap,
        is_feature_article: recipe_data.config.is_feature_recipe,
        ...getSocialData(recipe_config),
        ...getTwitterData(recipe_config),
        ...getSeoData(recipe_config),
        open_graph: recipe_config.open_graph,
        twitter_cards: recipe_config.twitter_cards,
        categories: recipe_data.categories,
        tags: recipe_data?.tags
          ? recipe_data.tags.map((tag: any) => tag.name)
          : [],
        use_exact_number: !serveOptions.includes(recipe_data.serves ?? ''),
        created_at: recipe_data.created_at,
        ignoreTitleChange: Boolean(recipe_data.status),
      }));
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      window.history.replaceState({}, '');
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formData]);

  unstable_usePrompt({
    message: 'Are you sure you want to leave?',
    when: ({ currentLocation, nextLocation }) =>
      !currentLocation.pathname.includes('/new') &&
      currentLocation.pathname !== nextLocation.pathname,
  });

  return (
    <CreateContentLayout
      saving={formData.saving}
      isEdit={formData?.created_at}
      isPublished={formData?.status}
      onGoBack={() => {
        navigate('/cms/recipes');
      }}
      showRightSidebar={showRightSidebar(formData)}
      type={RECIPE_PAGE_TYPE}
      updateStateData={updateRecipeData}
      getContentConfig={{
        client: blogClient,
        variables: {
          uuid: recipeUuid,
        },
        GET_CONTENT: GET_RECIPE_BY_UUID,
        dataKey: ['getRecipeByUuid'],
      }}
      sidebarControls={{
        details: (
          <DetailsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
            type={RECIPE_PAGE_TYPE}
            switchData={[
              {
                label: 'Dark Recipe',
                name: 'is_dark_post',
              },
              {
                label: 'Featured Recipe',
                name: 'is_feature_article',
              },
            ]}
          />
        ),
        categories: (
          <CategoryForm
            formData={formData}
            setFormData={setFormData}
            createCategoryConfig={{
              client: recipeClient,
              CREATE_CATEGORY: CREATE_RECIPE_CATEGORY,
              variables: {
                site_uuid: siteDetails.uuid,
              },
            }}
            getCategoriesConfig={{
              name: GET_RECIPE_CATEGORIES,
              client: recipeClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getCategoriesBySiteUuid', 'data'],
              variables: {
                site_uuid: siteDetails.uuid,
                page: 1,
                perPage: 20,
              },
            }}
          />
        ),
        relatedPosts: (
          <RelatedPosts formData={formData} setFormData={setFormData} />
        ),
        schema: <SchemaForm formData={formData} setFormData={setFormData} />,
        seo: (
          <SeoForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        socials: (
          <SocialsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        tags: (
          <TagForm
            formData={formData}
            setFormData={setFormData}
            getTagsConfig={{
              name: GET_RECIPE_TAGS,
              client: recipeClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getTagsBySiteUuid', 'data'],
              variables: {
                site_uuid: siteDetails.uuid,
                page: 1,
                perPage: 20,
              },
            }}
            addOption={(tag: string) =>
              new Promise((resolve) =>
                resolve({
                  label: tag,
                  value: String(Math.random() * 1000),
                  status: true,
                })
              )
            }
          />
        ),
        utmGenerator: (
          <UTMGeneratorForm formData={formData} setFormData={setFormData} />
        ),
      }}
    >
      {headerData?.map(
        ({ name, description, children, required }, index: number) => (
          <Header
            title={name}
            description={description}
            key={index}
            required={required}
          >
            {children}
          </Header>
        )
      )}
      <EmbedImage
        open={
          embedAction === 'image' ||
          embedAction === 'featured_image' ||
          embedAction === 'social_image' ||
          embedAction === 'seo_image' ||
          embedAction === 'twitter_image'
        }
        handleClose={handleClose}
        handleEmbed={handleImageEmbed}
        name={embedAction}
        formData={formData}
        setFormData={setFormData}
      />
      <CTAs
        handleValidate={handleValidate}
        isEditPost={Boolean(formData?.status)}
        submitting={submitting}
      />
    </CreateContentLayout>
  );
};
