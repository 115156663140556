import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
  query Campaigns($pageSize: Float, $page: Float) {
    campaigns(pageSize: $pageSize, page: $page) {
      data {
        id
        uuid
        name
        description
        postsCount
        commentsCount
        sentiment {
          positive
          negative
          neutral
        }
        createdAt
      }
      pagination {
        totalPages
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_CAMPAIGNS_COUNT = gql`
  query Campaigns($pageSize: Float, $page: Float) {
    campaigns(pageSize: $pageSize, page: $page) {
      data {
        name
        createdAt
        postsCount
      }
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query Campaign($uuid: String!) {
    campaign(uuid: $uuid) {
      id
      uuid
      name
      description
      postsCount
      comments {
        author
        text
        createdAt
        id
      }
      commentsCount
      sentiment {
        negative
        neutral
        positive
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
