import { ApolloConsumer, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/auth';
import { notifySuccess } from '../../utilities/notify';
import { Button } from '../common/Button/Button';
import useStyles from '../ViewRedirect/styles';
import { DELETEPAGE, FETCHPAGES } from './graphQL';
import { Modal } from 'components/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';

const ViewContent = () => {
  const [getId, setGetId] = useState<undefined | string>('');
  const [refreshToken, setRefreshToken] = useState(false);
  const [proceed, setIsProceed] = useState(false);
  const [deletePageMutation] = useMutation(DELETEPAGE);
  const [pages, setPages] = useState<{ [key: string]: string }[]>([]);
  const { toggleSpinner } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const getTableRows = useGetTableRows();
  const tableColumns = useGetTableColumns(
    setGetId,
    setOpenDeleteModal,
    setRefreshToken
  );

  useEffect(() => {
    if (proceed === true) {
      deleteArticle();
    }
  }, [proceed]);

  const deleteArticle = async () => {
    if (!getId) {
      return;
    }
    const variables = { id: Number(getId) };
    toggleSpinner(true);
    try {
      await deletePageMutation({ variables });
      notifySuccess('Page Deleted Successfully');
      updatePages(variables?.id);
      setOpenDeleteModal(false);
      setRefreshToken(true);
    } catch (error) {
      //
    } finally {
      toggleSpinner(false);
    }
  };

  const updatePages = useCallback(
    (id: number) => {
      const idx = pages.findIndex(
        (article) => article.id.toString() === id.toString()
      );
      const newpages = [...pages];
      newpages.splice(idx, 1);
      setPages(newpages);
    },
    [pages]
  );

  const handleClickAway = () => {
    setOpenDeleteModal(false);
  };

  const handleSubmitDate = () => {
    setIsProceed(true);
    setOpenDeleteModal(false);
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <Modal isOpen={openDeleteModal} onClose={handleCloseDelete}>
            <div className={classes.modalContent}>
              <div className={classes.modalHeader}>
                <h3>Delete?</h3>
                <AiOutlineClose
                  size={'20px'}
                  onClick={handleClickAway}
                  className={classes.iconPointer}
                />
              </div>
              <div className={classes.subTitle}>
                Are you sure you want to delete this item? It will be deleted
                permanently from your account
              </div>
              <div className={classes.filterBox}>
                <button onClick={handleSubmitDate} className="secondary">
                  Yes
                </button>
                <button onClick={handleClickAway}>No</button>
              </div>
            </div>
          </Modal>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Pages</p>
            <Button
              title="Create Page"
              onClick={() => {
                navigate('/admin/pages/create');
              }}
            />
          </div>
          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: FETCHPAGES,
                client,
                variables: {
                  refreshToken,
                },
                dataKey: 'contents',
              }}
              conditionFilters={tableConditionFilters}
              showFilters={false}
            />
          </div>
        </>
      )}
    </ApolloConsumer>
  );
};

export default ViewContent;
