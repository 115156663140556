import { FC, useContext } from 'react';
import useCpasStyles from 'pages/Cpas/styles';
import { GETCHANNELSTORIES } from '../../../../pages/Cpas/graphQl/graphQl';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import { cpasClient } from 'utilities/apolloClient';
import { AppStateContext } from 'contexts/provider';
import FilterSelect from '../FilterSelect/FilterSelect';
import useSelectChange from '../../../../pages/Cpas/hooks/hooks';
import { useGetTableColumns, useGetTableRows } from 'pages/Cpas/helpers';
import { CpasTableProps } from './types';
import { PiExport } from 'react-icons/pi';
import { CSVLink } from 'react-csv';
import clx from 'classnames';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

const CpasTable: FC<CpasTableProps> = ({ setStoryId, setOpenDetailsModal }) => {
  const classes = useCpasStyles();
  const { channelDetails, all, brand, channel } = useContext(AppStateContext);
  const {
    value,
    selectedStartDate,
    selectedEndDate,
    handleSelectChange,
    setShowCustomRange,
    setSelectedStartDate,
    setSelectedEndDate,
    showCustomRange,
  } = useSelectChange();
  const tableColumns = useGetTableColumns(setStoryId, setOpenDetailsModal);
  const getTableRows = useGetTableRows();
  const formattedBrand = brand.includes('(Brand)')
    ? brand.replace('(Brand)', '')
    : brand;

  const {
    data: creativeData,
    error: storyError,
    loading: loadingStory,
  } = useQuery(GETCHANNELSTORIES, {
    variables: {
      businessProfileId: channelDetails?.businessProfileId,
      export: true,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      brand: formattedBrand,
    },
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  const getChannelData = (creativeData: any) => {
    return creativeData?.getChannelStories?.data?.map(
      (
        data: { metrics: { [key: string]: string }; name: string },
        index: number
      ) => {
        return {
          story_title: data?.name,
          paid_impressions: data?.metrics?.sold_impressions,
          gross_revenue: data?.metrics?.revenue,
          ecpm: data?.metrics?.ecpm,
          unique_viewers: data?.metrics?.unique_dau,
          unique_subscribers: data?.metrics?.edition_subscribe_count,
        };
      }
    );
  };

  return (
    <div className={classes.tableParent}>
      <div className={clx(classes.exportFilter, 'creative')}>
        <p className={classes.subHeading}>Creative Data</p>
        {channel !== 'All Channels' && (
          <CSVLink
            className={clx(classes.exportBtn, !creativeData && 'loading')}
            data={getChannelData(creativeData ?? []) ?? []}
            filename={`${channel}-Stories-${selectedStartDate}-${selectedEndDate}.csv`}
          >
            <span>
              <PiExport />
            </span>
            {!loadingStory ? (
              <span>Export</span>
            ) : (
              <CircularProgress size="20px" />
            )}
          </CSVLink>
        )}
      </div>
      <div className={classes.tableContainer}>
        <TableNew
          columns={tableColumns}
          getRows={getTableRows}
          query={{
            name: GETCHANNELSTORIES,
            client: cpasClient,
            variables: {
              businessProfileId: channelDetails?.businessProfileId,
              page: 1,
              first: 10,
              start_date: selectedStartDate,
              end_date: selectedEndDate,
              all_channels: all,
              brand: formattedBrand,
            },
            dataKey: 'getChannelStories',
          }}
          showFilters={false}
          emptyState={{
            message: 'You currently do not have any story created yet',
          }}
        />
      </div>

      <div className={classes.filterSelect}>
        <FilterSelect
          value={value}
          showCustomRange={showCustomRange}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          handleSelectChange={handleSelectChange}
          setShowCustomRange={setShowCustomRange}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedEndDate={setSelectedEndDate}
          hasMarginBottom={true}
        />
      </div>
    </div>
  );
};

export default CpasTable;
