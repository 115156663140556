export const parseDate = (date: Date) => {
  const year = date.getFullYear();
  let month = String(date.getMonth() + 1);
  month = month.length === 1 ? '0' + month : month;
  let day = String(date.getDate());
  day = day.length === 1 ? '0' + day : day;

  return `${year}-${month}-${day}`;
};

export const parseFullDate = (date: Date) => {
  const year = date.getFullYear();
  let month = String(date.getMonth() + 1);
  month = month.length === 1 ? '0' + month : month;
  let day = String(date.getDate());
  day = day.length === 1 ? '0' + day : day;
  let hour = String(date.getHours());
  hour = hour.length === 1 ? '0' + hour : hour;

  let minutes = String(date.getMinutes());
  minutes = minutes.length === 1 ? '0' + minutes : minutes;

  let seconds = String(date.getSeconds());
  seconds = seconds.length === 1 ? '0' + seconds : seconds;

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
};

export const getTimeFrom = (dt: number): string => {
  const date = Date.now();
  const difference = date - dt;

  if (difference < 60000) {
    return `${Math.round(difference / 1000)}s`;
  }

  if (difference < 3600000) {
    return `${Math.round(difference / 60000)}m`;
  }

  if (difference < 86400000) {
    return `${Math.round(difference / 3600000)}h`;
  }

  if (difference < 2592000000) {
    return `${Math.round(difference / 86400000)}d`;
  }

  if (difference < 31104000000) {
    return `${Math.round(difference / 2592000000)}mo`;
  }

  return `${Math.round(difference / 31104000000)}y`;
};

export const convertToReadable = (dateString: string): string => {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  const timeDifference = currentDate.getTime() - inputDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else {
    return `${years} years ago`;
  }
};

export const isDateStringLessThanCurrent = (
  dateString: string = '',
  deadline: boolean = false
) => {
  const inputDate = dateString ? new Date(dateString) : new Date();
  const comparisonDate = deadline ? new Date('2024-01-01') : new Date();
  return inputDate < comparisonDate;
};
