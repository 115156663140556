import React, { useState, useEffect, useCallback, FC } from 'react';
import useStyles from './styles';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_TASK, GETUSERS, GET_TASK, UPDATE_TASK } from './graphQL';
import Taggables from '../../common/Taggables/Taggables';
import CustomTextField from '../../common/TextField/TextField';
import { CircularProgress, TextField } from '@mui/material';
import { Button } from '../../common/Button/Button';
import { formHandleText, formHandleFieldBlur } from '../../../utilities/form';
import Loader from '../../common/Loader/Loader';
import { parseFullDate } from '../../../utilities/date';
import { notifyError, notifySuccess } from '../../../utilities/notify';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { Close } from '@mui/icons-material';

interface Taskprops {
  handleClose: () => void;
  id: string | null;
}

const CreateTask: FC<Taskprops> = ({ handleClose, id }) => {
  const { data, error } = useQuery(GETUSERS, {
    fetchPolicy: 'network-only',
  });
  const {
    data: taskData,
    error: taskError,
    loading: loadingTask,
  } = useQuery(GET_TASK, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const [createTask, { loading }] = useMutation(CREATE_TASK);
  const [updateTask, { loading: taskLoading }] = useMutation(UPDATE_TASK);
  const [date, setDate] = useState(parseFullDate(new Date()));
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const [buttonInvalid, setButtonInvalid] = useState(true);
  const classes = useStyles();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<{ [key: string]: string }[]>([]);

  useEffect(() => {
    if (data) {
      const userData = data.users.data;
      setUserData(userData);
    }

    if (error) {
      notifyError(error.graphQLErrors[0].message);
    }
  }, [data, error]);

  useEffect(() => {
    taskData && setFormData(taskData.task);
  }, [taskData, taskError]);

  const handleFieldBlur = useCallback(
    (event) => {
      formHandleFieldBlur(event, formData, formErrors, setFormErrors);
    },
    [formErrors, formData]
  );

  const parseAndSubmitData = () => {
    if (Object.keys(formData).length < 6) {
      notifyError('Fields Are Required');
      return;
    }

    const dueDate = format(new Date(date), 'yyyy-mm-dd hh:mm:ss');
    const newFormData = { ...formData, deadline: dueDate };
    const variables: { [key: string]: boolean | string | string[] | Date } = {
      ...newFormData,
    };
    if (id) {
      return handleSubmit({ ...variables, id });
    }
    handleSubmit(variables);
  };

  const handleSubmit = (variables: {
    [key: string]: boolean | string | string[] | Date;
  }) => {
    if (id) {
      updateTask({ variables })
        .then(() => {
          notifySuccess('Task Updated Successfully');
          navigate('/');
        })
        .catch(() => {
          notifyError('An Error Occurred');
        });
    } else {
      createTask({ variables })
        .then(() => {
          notifySuccess('Task Created Successfully');
          navigate('/');
        })
        .catch(() => {
          notifyError('An Error Occurred');
        });
    }
  };

  const handleTags = useCallback(
    (tags: string[], type: string) => {
      if (tags.length > 1) {
        return;
      }

      const id = userData
        .filter((user) => tags.includes(user.name))
        .map((user) => user.id);
      if (id.length === 0) {
        const newFormData = { ...formData };
        delete newFormData.assignee_name;
        delete newFormData.assignee_id;
        setFormData(newFormData);
        return;
      }

      if (type === 'assignee') {
        setFormData({ ...formData, assignee_id: id[0], assignee_name: tags });
      } else {
        setFormData({ ...formData, reporter_id: id[0], reporter_name: tags });
      }
    },
    [formData, userData]
  );

  useEffect(() => {
    if (
      !formData?.name ||
      !formData?.assignee_name ||
      !formData?.tag ||
      !formData.description
    ) {
      setButtonInvalid(true);
    } else {
      setButtonInvalid(false);
    }
  }, [formData]);

  return (
    <div className={classes.event}>
      <div className={classes.event_title_parent}>
        <p className={classes.event__title}>
          {id ? 'Edit Task' : 'Create Task'}
        </p>
        <Close onClick={handleClose} className={classes.closeIcon} />
      </div>
      {id && loadingTask ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" size="30px" />
        </div>
      ) : (
        <form className={classes.event__form}>
          <div className={classes.taskParent}>
            <div className={classes.formParent}>
              <div className={classes.event__form_section}>
                <label className={classes.label}>Title</label>
                <CustomTextField
                  name="name"
                  onChange={(e: React.ChangeEvent<any>) => {
                    formHandleText(e, setFormData, formData);
                  }}
                  onBlur={handleFieldBlur}
                  type="text"
                  value={formData?.name ?? ''}
                  className={classes.createTextfield}
                />
                {formErrors?.name && (
                  <p className={classes.event__form_error}>
                    {formErrors?.name}
                  </p>
                )}
              </div>
              <div className={classes.event__form_section}>
                <label className={classes.label}>Task Description</label>
                <CustomTextField
                  onChange={(e: React.ChangeEvent<any>) => {
                    formHandleText(e, setFormData, formData);
                  }}
                  onBlur={handleFieldBlur}
                  type="text"
                  value={formData?.description ?? ''}
                  name="description"
                  multiline={true}
                  rows={10}
                  className={classes.createTextfield}
                />
                {formErrors?.description && (
                  <p className={classes.event__form_error}>
                    {formErrors?.description}
                  </p>
                )}
              </div>
              <div
                className={`${classes.event__form_section} ${classes.reporter}`}
              >
                <label className={classes.label}>Reporter</label>
                <Taggables
                  options={userData.map(
                    (user: { [key: string]: string }) => user.name
                  )}
                  invitees={true}
                  onBlur={handleFieldBlur}
                  onChange={(e, tags) => {
                    tags, 'reporter';
                  }}
                  tags={formData?.reporter_name ?? []}
                />
              </div>
            </div>
            <div style={{ width: '48%' }}>
              <div className={classes.event__form_section}>
                <label className={classes.label}>Assignee</label>
                <Taggables
                  options={userData.map(
                    (user: { [key: string]: string }) => user.name
                  )}
                  invitees={true}
                  onBlur={handleFieldBlur}
                  onChange={(e, tags) => {
                    handleTags(tags, 'assignee');
                  }}
                  tags={formData?.assignee_name ?? []}
                  className={classes.taggable}
                />
              </div>
              <div className={classes.event__form_section}>
                <label className={classes.label}>Date Due</label>
                <TextField
                  id="datetime-local"
                  label="To"
                  type="datetime-local"
                  defaultValue={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  className={classes.event__date_time_picker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.event__form_actions}>
            <div style={{ position: 'relative' }}>
              <Button
                style={{
                  cursor: buttonInvalid ? 'not-allowed' : 'pointer',
                  background: buttonInvalid ? 'grey' : '',
                }}
                className={classes.btn}
                onClick={!buttonInvalid ? parseAndSubmitData : undefined}
                title={id ? 'Edit Task' : 'Create Task'}
              />
              <Loader display={loading || taskLoading} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateTask;
