import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface StyleProps {
  background?: string;
  borderRadius?: string;
  padding?: string;
  height?: string;
  noStyle?: boolean;
  filter?: boolean;
  append?: boolean;
  social?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  select__parent: {
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    zIndex: 5,
  },
  select: {
    '&:focus': {
      outline: 'none',
    },
    zIndex: 5,
    width: ({ filter }) => (filter ? 'fit-content' : '100%'),
    height: '100%',
    border: ({ filter }) => (filter ? 'none' : '1px solid #F3F3F3'),
    padding: ({ filter, social }) => {
      if (filter) {
        return '0 30px 0 0';
      }
      if (social) {
        return '8px 10px';
      }
      return '10px 30px 10px 10px';
    },
    color: '#33333',
    backgroundColor: ({ filter, social }) =>
      filter || social ? 'transparent' : '#Ffffff',
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: ({ filter }) => (filter ? '95% 35%' : '95% 60%'),
    fontSize: '12px',
    borderRadius: ({ append }) => (append ? '0px 5px 5px 0' : '3px'),
    fontWeight: ({ filter }) => (filter ? 'bold' : 'normal'),
  },
  icon: {
    position: 'absolute',
    zIndex: 1,
    top: ({ noStyle }) =>
      noStyle ? 'calc(( 100% - 25px )/2)' : 'calc((100% - 24px)/2)',
    right: ({ filter }) => (filter ? '-30px' : '10px'),
    fontSize: '1.7rem',
  },
}));

export default useStyles;
