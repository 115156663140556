import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useTwistedfoodHomeStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    position: 'relative',
    minHeight: 'calc(45vh)',
  },
  publishBtn: {
    position: 'relative',
    '& button': {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.design.font.universal,
    },
  },
  add: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: 24,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: -9999,
    transition: 'all 0.5s ease-in',
    background: theme.design.colors.fullScreenBackground,
  },
  overlayActive: {
    opacity: 1,
    zIndex: 5,
  },
  form: {
    border: `solid 1px #E6E6E6`,
    borderLeft: 'none',
    background: theme.design.colors.white,
    maxHeight: '65vh',
    width: '90%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(3),
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  title: {
    fontSize: '16px',
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  loading: {
    position: 'relative',
    top: 'calc((30vh - 40px)/2)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
  },
  enabled: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: theme.typography.fontSize,
    fontFamily: theme.design.font.universal,
  },
  accordion: {
    boxShadow: 'none !important',
    border: '1px solid #ccc',
    borderRadius: '4px !important',
    overflow: 'hidden',

    marginBottom: theme.spacing(2),
    '& > .MuiAccordionSummary-root': {
      backgroundColor: `${theme.design.colors.widgetGrey} !important`,

      '& > div > p': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  categoryContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  categoryDropdown: {
    width: '111px',
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'white',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'black',
      opacity: 1,
    },
  },
  sectionsContainer: {
    display: 'flex',
    width: '100%',
  },
  sectionsTab: {
    width: '20%',
    fontFamily: theme.design.font.universal,
  },
}));
