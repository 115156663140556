import { gql } from '@apollo/client';

export const GET_SOCIAL_INTEGRATION = gql`
  query connectionByPlatform($site: String!, $platform: String!) {
    connectionByPlatform(site: $site, platform: $platform) {
      id
      name
      token
      expires_at
      platform_id
    }
  }
`;

export const GET_FACEBOOK_PAGE_ACCESS_TOKEN = gql`
  mutation generateFacebookPageAccessToken(
    $userShortToken: String!
    $site: String!
    $userId: String!
  ) {
    generateFacebookPageAccessToken(
      userShortToken: $userShortToken
      site: $site
      userId: $userId
    ) {
      message
      status
    }
  }
`;

export const DELETE_CONNECTION = gql`
  mutation deleteConnection($id: ID!) {
    deleteConnection(id: $id) {
      id
      name
      platform
      platform_id
      token
    }
  }
`;
