import { useCommentAnalysis } from 'contexts/ca';

interface TablePaginationProps {
  refetch?: (variables: any) => Promise<any>;
  campaignUuid?: string;
  take?: number;
  postPage?: number;
  setPostPage?: (page: number) => void;
}

export default function TablePagination({
  refetch,
  campaignUuid,
  take,
  postPage,
  setPostPage,
}: TablePaginationProps) {
  const { page, total, hasNextPage, hasPreviousPage, fetchCampaigns } =
    useCommentAnalysis();

  const handleFetchIncremental = async () => {
    if (
      refetch &&
      take &&
      campaignUuid &&
      postPage !== undefined &&
      setPostPage
    ) {
      const nextPostPage = postPage + 1;
      setPostPage(nextPostPage);
      await refetch({ take, skip: (nextPostPage - 1) * take, campaignUuid });
    } else {
      fetchCampaigns(page + 1);
    }
  };

  const handleFetchDecremental = async () => {
    if (
      refetch &&
      take &&
      campaignUuid &&
      postPage !== undefined &&
      setPostPage
    ) {
      if (postPage <= 1) return;
      const prevPostPage = postPage - 1;
      setPostPage(prevPostPage);
      await refetch({ take, skip: (prevPostPage - 1) * take, campaignUuid });
    } else {
      if (page <= 1) return;
      fetchCampaigns(page - 1);
    }
  };

  return (
    <div className="p-4 flex justify-between items-center">
      <div>
        <p className="truncate text-[#344054]">
          Page {refetch ? `${postPage} of 2` : `${page} of ${total}`}
        </p>
      </div>

      <div className="flex space-x-4 items-center">
        <div>
          <button
            style={{ border: '2px solid #EAECF0' }}
            onClick={handleFetchDecremental}
            className={`px-4 py-3 text-xs text-[#344054] rounded-[8px] border-2 ${
              refetch
                ? 'cursor-pointer'
                : !hasPreviousPage
                ? 'cursor-not-allowed opacity-45'
                : 'cursor-pointer'
            }`}
            disabled={refetch ? false : !hasPreviousPage}
          >
            Previous
          </button>
        </div>

        <div>
          <button
            style={{ border: '2px solid #EAECF0' }}
            onClick={handleFetchIncremental}
            disabled={refetch ? false : !hasNextPage}
            className={`px-4 py-3 text-xs text-[#344054] rounded-[8px] border-2 ${
              refetch
                ? 'cursor-pointer'
                : !hasNextPage
                ? 'cursor-not-allowed opacity-45'
                : 'cursor-pointer'
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
