import { gql } from '@apollo/client';

export const UPDATE_SLT_PURCHASE_ORDER = gql`
  mutation UpdateSltPurchaseOrder($id: ID!) {
    updateSltPurchaseOrder(id: $id) {
      id
    }
  }
`;

export const UPDATE_ACC_PURCHASE_ORDER = gql`
  mutation UpdateAccPurchaseOrder($id: ID!, $acc_approved: Boolean!) {
    updateAccPurchaseOrder(id: $id, acc_approved: $acc_approved) {
      id
    }
  }
`;
