import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useSectionStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  itemsParent: {
    marginTop: theme.spacing(4),
  },
}));
