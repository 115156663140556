import { gql } from '@apollo/client';

export const ADD_POST_TO_CAMPAIGN = gql`
  mutation AddPostToCampaign($campaign_uuid: String!, $post_url: String!) {
    addPostToCampaign(campaignUuid: $campaign_uuid, postUrl: $post_url) {
      name
      description
    }
  }
`;
