import React, { FC, useCallback, useEffect, useState } from 'react';
import useSnapDetailsStyles from './styles';
import { ParameterCard } from 'components/ParameterCard/ParameterCard';
import clx from 'classnames';
import { LineChart } from 'components/LineChart/LineChart';
import { ArrowBack } from '@mui/icons-material';
import { SnapDetailsProps } from './types';
import { useQuery } from '@apollo/client';
import { GETSTORYDETAILS } from './graphQl';
import { CircularProgress } from '@mui/material';
import { cpasClient } from 'utilities/apolloClient';
import { convertEcpmAndRevenueData } from 'pages/Cpas/helpers';
import FilterSelect from 'pages/Cpas/components/FilterSelect/FilterSelect';
import useSelectChange from 'pages/Cpas/hooks/hooks';
import CustomVideoPlayer from 'components/CustomVideoPlayer/CustomVideoPlayer';

const SnapDetails: FC<SnapDetailsProps> = ({ handleBackClick, story_id }) => {
  const classes = useSnapDetailsStyles();

  const [storyDetails, setstoryDetails] = useState<any>();
  const [chartDetails, setChartDetails] = useState<any[]>();
  const {
    value,
    selectedStartDate,
    selectedEndDate,
    showCustomRange,
    handleSelectChange,
    setShowCustomRange,
    setSelectedStartDate,
    setSelectedEndDate,
  } = useSelectChange();
  const {
    data: storyData,
    error: storyError,
    loading: loadingStory,
  } = useQuery(GETSTORYDETAILS, {
    variables: {
      story_id,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
    },
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  useEffect(() => {
    if (storyData) {
      setstoryDetails(storyData.getStory);
      setChartDetails(
        convertEcpmAndRevenueData(storyData.getStory.day_by_day_metrics)
      );
    }
  }, [storyData, storyError]);

  const renamedDataArray = useCallback(() => {
    if (!storyDetails?.metrics) {
      return [];
    }

    return Object.entries(storyDetails.metrics)
      .map(([key, value]) => {
        if (key === '__typename') {
          return null;
        }

        let formattedKey = key;
        let formattedValue = value as string;

        if (!isNaN(parseFloat(formattedValue))) {
          const numberValue = Number(formattedValue);
          if (Number.isInteger(numberValue)) {
            formattedValue = numberValue.toLocaleString();
          } else {
            formattedValue = numberValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
        }

        if (key === 'avg_time_viewed') {
          formattedKey = 'Average Watch Time';
          formattedValue = `${formattedValue}(secs)`;
        } else if (key === 'revenue') {
          formattedKey = 'Gross Revenue';
          formattedValue = `$${formattedValue}`;
        } else if (key === 'ecpm') {
          formattedKey = 'ECPM';
          formattedValue = `$${formattedValue}`;
        } else if (key === 'edition_subscribe_count') {
          formattedKey = 'Total Unique Subscribers';
        } else if (key === 'sold_impressions') {
          formattedKey = 'Paid Impressions';
        } else if (key === 'unique_dau') {
          formattedKey = 'Unique Viewers';
        }

        return {
          title: formattedKey,
          value: formattedValue,
        };
      })
      .filter(Boolean)
      .sort((a, b) => {
        if (a === null || b === null) {
          return 0; // Handle null values
        }
        const order = [
          'Average Watch Time',
          'Gross Revenue',
          'ECPM',
          'Total Unique Subscribers',
          'Paid Impressions',
          'Unique Viewers',
        ];
        return order.indexOf(a.title) - order.indexOf(b.title);
      });
  }, [storyDetails]);

  const displayParameterCard = () =>
    renamedDataArray().length > 0 &&
    renamedDataArray().map((revenue, index) => (
      <div key={index} className={clx(classes.revenueCard)}>
        <ParameterCard
          title={revenue?.title ?? ''}
          value={String(revenue?.value) ?? ''}
          index={index}
        />
      </div>
    ));
  return (
    <>
      <div className={classes.root}>
        {loadingStory ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <>
            <div className={classes.heading}>
              <div className={classes.backParent} onClick={handleBackClick}>
                <ArrowBack />
                <p className={classes.back}>Back</p>
              </div>
              <FilterSelect
                value={value}
                showCustomRange={showCustomRange}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                handleSelectChange={handleSelectChange}
                setShowCustomRange={setShowCustomRange}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
              />
            </div>
            <div className={classes.contentRoot}>
              <div className={classes.contentVideo}>
                <div className={classes.snapchat}>
                  <p className={classes.storyName}>{storyDetails?.name}</p>
                  <div className={classes.img}>
                    <CustomVideoPlayer
                      title={storyDetails?.name}
                      videoSource={storyDetails?.snap_url}
                    />
                  </div>
                  <div className={classes.stampDetails}>
                    <p>
                      Date Created:<span>Today</span>
                    </p>
                    <p>
                      Time Created:<span>9:45 am</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.right}>
                <div className={classes.contentDetails}>
                  {displayParameterCard()}
                </div>
                <div className={classes.chartConainer}>
                  <p className={classes.subHeading}>Creative Chart</p>
                  {chartDetails && (
                    <LineChart
                      className={classes.chart}
                      data={
                        storyData.getStory.day_by_day_metrics
                          ? convertEcpmAndRevenueData(
                              storyData.getStory.day_by_day_metrics
                            )
                          : []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SnapDetails;
