import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FETCHEVENTS } from './graphQL';
import useStyles from './styles';
import { parseDate } from '../../utilities/date';

const Events = () => {
  const [fetchEventsQuery, { data, loading, error }] = useLazyQuery(
    FETCHEVENTS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [date] = useState<Date>(new Date());
  const [eventIndex, setEventIndex] = useState<Number | string>();
  const [events, setEvents] = useState<{ [key: string]: string }[]>([]);
  const classes = useStyles();
  const colors = [
    '#782E54',
    '#5D5FEF',
    '#EB5757',
    '#F2994A',
    '#219653',
    '#9B51E0',
  ];

  useEffect(() => {
    const variables = { date: parseDate(date) };
    fetchEventsQuery({ variables });
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (data) {
      const events = data.events;
      const filteredEvents = events.filter(
        (event: { name: string }) => event?.name
      );
      setEvents(filteredEvents);
    }
  }, [data, error]);

  const getDate = (date: string) => {
    return new Date(date);
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const displayEvents = () => {
    return events.slice(0, 6).map((event, index) => {
      return (
        <div key={index} className={classes.event}>
          <div
            className={classes.eventBox}
            onClick={() => {
              setEventIndex((prev) => (prev === index ? '' : index));
            }}
          >
            <div className={classes.eventHeader}>
              <img
                src="https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-bulk-note.svg"
                alt="AnnoucementIcon"
              />
              <div>
                <p className={classes.upcoming}>Upcoming</p>
                <p className={classes.evenTitle}>{event.name}</p>
              </div>
            </div>
            <p className={classes.eventDate}>
              <span className={classes.dot} />
              <span>{getDate(event?.start_time).getDate()}th</span>
              <span className={classes.eventDateMonths}>
                {monthNames[getDate(event?.start_time).getMonth()].slice(0, 3)}
              </span>
            </p>
          </div>
          {eventIndex === index && (
            <p className={classes.eventDescription}>{event?.description}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className={classes.eventsParent}>
      <p className={classes.eventTitle}>
        Events{' '}
        {events.length > 0 && (
          <span>{events.slice(0, 6).length} Events coming up</span>
        )}
      </p>
      <div className={classes.eventContainer}>{displayEvents()}</div>
    </div>
  );
};

export default Events;
