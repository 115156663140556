import { useState, useContext } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { DELETE_GUIDE_BY_UUID, GET_GUIDE_BY_SITE_UUID } from '../Blog/graphQL';
import { useMutation, ApolloConsumer } from '@apollo/client';
import { Button } from '../common/Button/Button';
import useStyles from '../ViewRedirect/styles';
import { notifySuccess } from '../../utilities/notify';
import { SEND_NOTIFICATION } from '../ArticlePlatforms/graphql';
import { AppStateContext } from '../../contexts/provider';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';
import { blogClient } from 'utilities/apolloClient';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';

const Guides = () => {
  const [, setPaginator] = useState<{ [key: string]: any }>({});
  const [, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [post_uuid, setPostUuid] = useState<undefined | string>();
  const [refreshToken, setRefreshToken] = useState(false);
  const {
    site,
    siteDetails: { blog_uuid, uuid },
    user,
  } = useContext(AppStateContext);

  const [deleteRecipe] = useMutation(DELETE_GUIDE_BY_UUID, {
    client: blogClient,
  });
  const { toggleSpinner } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [sendNotification] = useMutation(SEND_NOTIFICATION);
  const tableColumns = useGetTableColumns(setPostUuid, setOpenDeleteModal);
  const getTableRows = useGetTableRows();

  const handleDeleteArticle = async () => {
    if (!post_uuid) return;

    const variables = { uuid: post_uuid };
    toggleSpinner(true);
    try {
      handleClose();
      await deleteRecipe({ variables });
      setRefreshToken(true);
      notifySuccess('Guides Deleted Successfully');
    } finally {
      setRefreshToken(false);
      handleClose();
      toggleSpinner(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <header className={classes.titleWidget}>
            <p className={classes.title}>Guides</p>
            <div className={classes.rightSide}>
              <Button
                title="Create Guides"
                onClick={() => {
                  navigate('/cms/guides/create');
                }}
                className={classes.createBtn}
              />
            </div>
          </header>
          <TableNew
            columns={tableColumns}
            getRows={getTableRows}
            query={{
              name: GET_GUIDE_BY_SITE_UUID,
              client,
              variables: {
                site_uuid: uuid,
                refreshToken,
                include_drafts: true,
              },
              dataKey: 'getGuidesBySiteUuid',
            }}
            conditionFilters={tableConditionFilters}
            showFilters={false}
          />
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            deletePostCategory={handleDeleteArticle}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default Guides;
