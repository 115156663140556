import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  RequestHandler,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'universal-cookie';

const createApolloClient = (uri: string) => {
  // app state
  const cookies = new Cookies();

  // apollo client
  const cache = new InMemoryCache({});
  const httpLink: ApolloLink | RequestHandler = createUploadLink({ uri });

  const authLink = setContext((_, { headers }) => {
    const token = cookies.get('token') as string;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      const { message } = graphQLErrors[0];
      if (message === 'Unauthenticated.') {
        cookies.remove('token');
        cookies.remove('user');
        window.location.href = window.location.origin;
      }
    }
  });

  const link = authLink.concat(httpLink);

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: logoutLink.concat(link),
    cache,
  });

  return client;
};

export default createApolloClient;

export const cpasClient = createApolloClient(
  import.meta.env.VITE_CPAS_GRAPH_QL_URL as string
);

export const blogClient = createApolloClient(
  import.meta.env.VITE_GRAPH_QL_URL as string
);

export const contactClient = createApolloClient(
  import.meta.env.VITE_BLOG_GRAPHQL_URL as string
);

export const recipeClient = createApolloClient(
  import.meta.env.VITE_RECIPE_GRAPH_QL_URL as string
);
