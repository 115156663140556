import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import usePortalLandingStyles from 'components/LandingDashboard/styles';

interface CountdownProps {
  expirationDate: string;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Countdown: React.FC<CountdownProps> = ({ expirationDate }) => {
  const classes = usePortalLandingStyles();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateTimeLeft(): TimeLeft {
    const now: Moment = moment();
    const expires: Moment = moment(expirationDate, 'YYYY-MM-DD HH:mm:ss'); // Parse with the provided format
    const diff: number = expires.diff(now);

    const duration = moment.duration(diff);

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  function formatTime(time: number): string {
    const modifiedTime = Number(String(time).replace(/-/g, ''));

    return modifiedTime < 10 ? `0${modifiedTime}` : `${modifiedTime}`;
  }

  return (
    <div className={classes.countdownParent}>
      <div>
        <p>{formatTime(timeLeft.days)}</p>
        <span>days</span>
      </div>
      <div>:</div>
      <div>
        <p>{formatTime(timeLeft.hours)}</p>
        <span>hrs</span>
      </div>
      <div>:</div>
      <div>
        <p>{formatTime(timeLeft.minutes)}</p>
        <span>mins</span>
      </div>
      <div>:</div>
      <div>
        <p>{formatTime(timeLeft.seconds)}</p>
        <span>secs</span>
      </div>
    </div>
  );
};

export default Countdown;
