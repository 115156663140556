import React from 'react';
import useStyles, { StyleProps } from './styles';
import clx from 'classnames';
interface SelectProps extends StyleProps {
  title?: string;
  name?: string;
  value?: string;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value: string }>
  ) => void;
  width?: string;
  options: { label: string; value: any }[] | { [key: string]: any };
  edit?: boolean;
  className?: string;
}

export const Select: React.FC<SelectProps> = ({
  name,
  onChange,
  value,
  options,
  noStyle,
  append,
  social,
  filter,
  className,
}) => {
  const classes = useStyles({ noStyle, filter, append, social });
  const id = 'select-' + (Math.random() * 1000).toFixed(2);

  return (
    <div className={clx(classes.select__parent)}>
      <select
        id={id}
        name={name ?? 'select'}
        className={clx(classes.select, className)}
        value={value}
        onChange={onChange}
      >
        {options instanceof Array
          ? options.map(
              (option: { label: string; value: any }, index: number) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label ?? option.label}
                  </option>
                );
              }
            )
          : Object.keys(options).map((key) => (
              <option key={key} value={options[key]}>
                {key}
              </option>
            ))}
      </select>
    </div>
  );
};
