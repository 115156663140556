import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, Route, useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../components/common/Spinner/Spinner';
import Nav from '../../components/Nav/Nav';
import AuthContext from '../../contexts/auth';
import useStyles from './styles';
import clx from 'classnames';
import DashboardNav from 'components/Nav/DashboardNav';
import { RouteType } from './types';

const Dashboard = () => {
  const [spinner, setSpinner] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<{ [key: string]: string | number }>(
    {}
  );
  const [showLinks, setShowLinks] = useState<boolean>(
    !window.location.pathname.slice(1).startsWith('admin') &&
      !window.location.pathname.slice(1).startsWith('cms')
  );
  const [showFullScreen, setShowFullScreen] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSpinner = (spinnerVal: boolean) => {
    setSpinner(spinnerVal);
  };

  const landingFilter =
    !location.pathname.includes('admin') &&
    !location.pathname.includes('cms') &&
    !location.pathname.includes('cpas') &&
    !location.pathname.includes('ca');

  useEffect(() => {
    if (landingFilter) {
      return setShowFullScreen(true);
    }
    return setShowFullScreen(false);
  }, [location.pathname, showFullScreen]);

  useEffect(() => {
    setShowLinks(
      !location.pathname.startsWith('/admin') &&
        !location.pathname.startsWith('/cms')
    );
  }, [location.pathname]);

  useEffect(() => {
    const RedirectToPortal = () => {
      if (location.pathname === '/') {
        return navigate('/');
      }
    };
    RedirectToPortal();
  }, [location.pathname, navigate]);

  const showLink = useCallback(() => {
    if (landingFilter) return true;
    return false;
  }, [showLinks, landingFilter]);

  const classes = useStyles();
  const navLinks = ['/', '/hr', '/welcome', '/office', '/finance', '/culture'];

  const renderRoutes = (routes: RouteType[]) => {
    return routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={route.element}
        index={route.index}
      >
        {route.routes && renderRoutes(route.routes)}
      </Route>
    ));
  };

  return (
    <div className={clx(classes.dashboard)}>
      {!landingFilter ? (
        <Nav showLinks={showLink()} />
      ) : (
        <DashboardNav showLinks={landingFilter} />
      )}
      <div
        className={clx(
          classes.dashboard__content,
          !showLinks && classes.dashboard__content_fill
        )}
      >
        <AuthContext.Provider
          value={{
            toggleSpinner,
            redirect,
            editRedirect: (redirect: any) => {
              setRedirect(redirect);
            },
          }}
        >
          <Outlet />
        </AuthContext.Provider>
      </div>
      <Spinner display={spinner} />
    </div>
  );
};

export default Dashboard;
