import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { formatDirectories } from 'components/DirectoryWidget/Old_DirectoryWidget';
import { GET_EMPLOYEES } from './graphQL';
import useDirectoryStyles from 'components/DirectoryWidget/styles';
import { useState } from 'react';

export default function AdminEmployee() {
  const classes = useDirectoryStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(GET_EMPLOYEES);
  return (
    <>
      {loading ? (
        <div className={classes.loader_container}>
          <CircularProgress className={classes.loader} size="24px" />
        </div>
      ) : (
        <div id="scroll-directories" className={classes.scroll}>
          {formatDirectories(
            data,
            classes,
            'getNewEmployees',
            5,
            currentPage,
            setCurrentPage
          )}
        </div>
      )}
    </>
  );
}
