import { useState, useContext } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { DELETEARTICLE, GET_POSTS_BY_BLOG_UUID } from './graphQL';
import { useMutation, ApolloConsumer } from '@apollo/client';
import { notifySuccess } from '../../utilities/notify';
import { Button } from '../common/Button/Button';
import useStyles from '../ViewRedirect/styles';
import { AppStateContext } from '../../contexts/provider';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';
import { DialogDelete } from './components/DeleteDialog/Dialog';
import { v4 as uuidv4 } from 'uuid';

const Blog = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [post_uuid, setPostUuid] = useState<undefined | string>();
  const [refreshToken, setRefreshToken] = useState(false);
  const {
    site,
    siteDetails: { blog_uuid },
  } = useContext(AppStateContext);

  const [deleteArticleMutation] = useMutation(DELETEARTICLE);
  const { toggleSpinner } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const tableColumns = useGetTableColumns(setPostUuid, setOpenDeleteModal);
  const getTableRows = useGetTableRows();

  const handleDeleteArticle = async () => {
    if (!post_uuid) return;

    const variables = { uuid: post_uuid };
    toggleSpinner(true);
    try {
      handleClose();
      await deleteArticleMutation({ variables });
      setRefreshToken(true);
      notifySuccess('Article Deleted Successfully');
    } finally {
      setRefreshToken(false);
      handleClose();
      toggleSpinner(false);
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Posts</p>
            <Button
              title="Create Post"
              onClick={() => {
                navigate(`/cms/posts/${uuidv4()}/new`);
              }}
              className={classes.createBtn}
            />
          </div>

          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: GET_POSTS_BY_BLOG_UUID,
                client,
                variables: { blog_uuid, refreshToken },
                dataKey: 'getBlogPostsByBlogUuid',
              }}
              conditionFilters={tableConditionFilters}
              showFilters={true}
            />
          </div>
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            deletePostCategory={handleDeleteArticle}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default Blog;
