import React from 'react';
import clx from 'classnames';
import { FormBuilderType } from './data';
import useStyles from './styles';
import { TextField } from '@mui/material';
import CustomTextField from '../../common/TextField/TextField';
import Radio from '../../common/Radio/Radio';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import TextArea from 'components/common/TextArea/TextArea';
import { MdDeleteForever } from 'react-icons/md';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { GqlComboBox } from '@wazobia-tech/wazcom/dist/gql';
import { GET_ALL_USERS } from 'components/Blog/graphQL';
import { blogClient } from 'utilities/apolloClient';

interface FormSectionProps {
  formik: any;
  section: FormBuilderType;
  addItems: () => void;
  updateItems: (e: any, index: number) => void;
  deleteItems: (index: number) => void;
  updateRadioButton: (key: string) => void;
}

const FormSection: React.FC<FormSectionProps> = ({
  formik,
  section,
  addItems,
  updateItems,
  deleteItems,
  updateRadioButton,
}) => {
  const classes = useStyles();
  const values: { [key: string]: string | number | string[] | boolean } = {
    ...formik.values,
  };
  const errors: { [key: string]: string | string[] | undefined } = {
    ...formik.errors,
  };

  const displaySection = () => {
    return section.map((formElement, index) => {
      const { key, type, label, required } = formElement;
      let element;
      if (type === 'date') {
        element = (
          <TextInput
            id="date"
            type="date"
            defaultValue={values[key] as string}
            name="date"
            onChange={(data) => {
              formik.handleChange({
                target: {
                  ...data,
                },
              });
            }}
            className={classes.input}
            classNames={{
              inputField: classes.block,
            }}
          />
        );
      } else if (type === 'radio') {
        element = (
          <div>
            <Radio
              handleClick={() => {
                updateRadioButton(key);
              }}
              checked={Boolean(values[key])}
              text="Yes"
            />
            <Radio
              handleClick={() => {
                updateRadioButton(key);
              }}
              checked={!Boolean(values[key])}
              text="No"
            />
          </div>
        );
      } else if (type === 'text') {
        element = (
          <TextInput
            name={key}
            onChange={(data) => {
              formik.handleChange({
                target: {
                  ...data,
                },
              });
            }}
            type={key === 'price' ? 'number' : 'text'}
            value={
              key === 'price'
                ? (values[key] as string) == '0'
                  ? ''
                  : (values[key] as string)
                : (values[key] as string)
            }
            className={classes.input}
            disabled={formElement.disabled}
          />
        );
      } else if (type === 'textarea') {
        element = (
          <TextArea
            name={key}
            onChange={formik.handleChange}
            value={
              key === 'price'
                ? (values[key] as string) == '0'
                  ? ''
                  : (values[key] as string)
                : (values[key] as string)
            }
          />
        );
      } else if (type === 'combobox') {
        element = (
          // @ts-ignore
          <GqlComboBox
            config={formElement.config}
            onChange={(data: any) => {
              formik.handleChange({
                target: {
                  ...data,
                },
              });
            }}
            name={key}
            value={values[key]}
            variables={{}}
            placeholder="e.g Joanna Freedman"
            addOption={() => {}}
            sx={{
              '& input': {
                minHeight: 0,
              },
            }}
            className={clx(classes.input, {
              [classes.disabled]: formElement.disabled,
            })}
          />
        );
      } else {
        element = (values[key] as string[]).map((item, index) => {
          return (
            <div key={index} className={classes.formSection}>
              <div className={classes.formParent}>
                <label
                  className={clx(classes.formLabel, classes.itemLabel)}
                  style={{ width: 75 }}
                >
                  #Item {index + 1}
                </label>
                <TextInput
                  name="name"
                  onChange={(data) => {
                    updateItems(
                      {
                        target: {
                          ...data,
                        },
                      },
                      index
                    );
                  }}
                  type="text"
                  value={item}
                  className={classes.input}
                />
                {formik.values.items.length > 1 && (
                  <MdDeleteForever
                    onClick={() => {
                      deleteItems(index);
                    }}
                    size={20}
                    className={classes.formRemoveItems}
                  />
                )}
              </div>
              {errors.items?.[index] && (
                <p className={classes.formError}>{errors.items?.[index]}</p>
              )}
            </div>
          );
        });
      }

      return (
        <div key={index} className={classes.formSection}>
          <label className={classes.formLabel}>
            {label}
            {required && (
              <span style={{ color: '#990000', fontSize: '20px' }}>*</span>
            )}
          </label>
          {element}
          {key !== 'items' && errors[key] && (
            <p className={classes.formError}>{errors[key]}</p>
          )}

          {key === 'items' && (
            <div onClick={addItems} className={classes.formAddItems}>
              <AiOutlinePlusCircle className="icon" />
              <p>Add item</p>
            </div>
          )}
        </div>
      );
    });
  };

  return <React.Fragment>{displaySection()}</React.Fragment>;
};

export default FormSection;
