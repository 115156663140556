import { gql } from '@apollo/client';

export const CORE_FORM_FIELDS = gql`
  fragment CoreFormFields on Form {
    uuid
    title
    updated_at
    status
    site_uuid
    responses_count
    setting {
      uuid
    }
    author
    created_by
  }
`;

export const GET_FORMS = gql`
  ${CORE_FORM_FIELDS}
  query GqlGetFormsBySiteUUID(
    $site_uuid: ID!
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getFormsBySiteUuid(
      input: {
        site_uuid: $site_uuid
        first: $first
        page: $page
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        ...CoreFormFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;
