import { blogClient } from 'utilities/apolloClient';
import { UPDATE_USER } from './grahql';

export const profileFormData = [
  { label: 'FULL NAME', name: 'fullname', disabled: true, type: 'text' },
  { label: 'EMAIL', name: 'email', disabled: true, type: 'text' },
  { label: 'LOCATION', name: 'location', disabled: false, type: 'text' },
  { label: 'DEPARTMENT', name: 'department', disabled: false, type: 'select' },
  { label: 'JOB TITLE', name: 'position', disabled: false, type: 'text' },
  {
    label: 'INSTAGRAM HANDLE',
    name: 'social_handle',
    disabled: false,
    type: 'text',
  },
];

export const gqlUpdateUser = async (variables: any) =>
  blogClient.mutate({
    mutation: UPDATE_USER,
    variables: {
      input: {
        ...variables,
      },
    },
  });
