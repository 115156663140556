import { gql } from '@apollo/client';

export const FETCHANNOUNCEMENTS = gql`
  query getAnnouncements($first: Int, $page: Int) {
    announcements(first: $first, page: $page) {
      data {
        id
        title
        description
        updated_at
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETEANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
      id
    }
  }
`;

export const FETCHANNOUNCEMENT = gql`
  query getAnnouncement($id: ID!) {
    announcement(id: $id) {
      description
      recipients
      all
    }
  }
`;
