import React from 'react';
import useStyles from './styles';
import { FaFaceSmile } from 'react-icons/fa6';
import { MdWavingHand } from 'react-icons/md';
import LeadershipList from 'pages/PageContent/components/leadershipList';
import SubSectionList from 'pages/PageContent/components/subSectionList';
import { financeTabs, officeTabs } from 'pages/Content/data';

const FinancePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T103048/5I9ACEe4yjiBHPGImPGC9IJ1rfH5Qs1OKs5IEQTu.png'
            }
          />
          Finance
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T102719/106iJUHwWoT6L88zkzTfv1mbA3nYdfmAdWLftqbP.png'
            }
          />
        </h1>
      </div>
      <div className={classes.leaderContainer}>
        <LeadershipList
          leaders={[
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T094932/ya08ubsHEftI6a8aUOgKZTbtSQ3DE8FeSkGCcNX9.png',
              title: 'Financial Controller',
              name: 'Dalvar Gill',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T093330/5qKJSGUjkTWrZe5LL1cDmuhHszAsYv3qf5PlpI5p.png',
              title: 'Senior FP&A Analyst',
              name: 'Aimee Ritchie',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T150629/IM5QN4jxCJ0QoXscnvlpNNfThLzP07sSaPOo6tHQ.png',
              title: 'Senior Management Accountant',
              name: 'Anusha Amin',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T094007/psLXvCpEHjzuBnmmueLwzZvMuVxOuciZ2rI9vYQ2.png',
              title: 'Finance Business Partner',
              name: 'Ben Davis',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T094942/bknO8HFD5Cmi5nBhWZqtCDDhEoVIZb5PqpSj2Hqx.png',
              title: 'Finance Assistant',
              name: 'Davlat Davronov',
            },
          ]}
        />
      </div>
      <div className={classes.content}>
        <SubSectionList cards={financeTabs} />
      </div>
    </div>
  );
};

export default FinancePage;
