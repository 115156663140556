import { gql } from '@apollo/client';

export const CORE_CATEGORY_FIELDS = gql`
  fragment CoreCategoryFields on GuideCategory {
    uuid
    name
    description
    slug
  }
`;

export const CREATE_GUIDE_CATEGORY = gql`
  ${CORE_CATEGORY_FIELDS}
  mutation GqlCreateCategory(
    $slug: String!
    $name: String!
    $site_uuid: ID!
    $description: String
  ) {
    createGuideCategory(
      slug: $slug
      name: $name
      site_uuid: $site_uuid
      description: $description
    ) {
      ...CoreCategoryFields
    }
  }
`;

export const UPDATE_GUIDE_CATEGORY = gql`
  ${CORE_CATEGORY_FIELDS}
  mutation GqlUpdateCategory(
    $uuid: ID!
    $name: String!
    $slug: String
    $description: String
  ) {
    updateGuideCategory(
      uuid: $uuid
      name: $name
      slug: $slug
      description: $description
    ) {
      ...CoreCategoryFields
    }
  }
`;

export const GET_GUIDE_CATEGORIES = gql`
  query GetGuideCategory($site_uuid: ID) {
    getGuidecategoriesBySiteUuid(site_uuid: $site_uuid) {
      data {
        id
        uuid
        name
        slug
        uuid
        description
        created_at
        updated_at
      }
    }
  }
`;
