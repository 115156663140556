import {
  TableColumn,
  TableConditionFilter,
} from '@wazobia-tech/wazcom/dist/core';
import { decodeHtml } from 'components/Blog/components/CreateContent/helpers';
import PollType from '../../types/PollType';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenViewModal: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenCreateModal: React.Dispatch<
    React.SetStateAction<
      | {
          open: boolean;
          data: PollType | undefined;
        }
      | undefined
    >
  >
): TableColumn[] => {
  return [
    {
      name: 'Questions',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            console.log(row);
            setRefreshToken(false);
            setOpenCreateModal({ open: true, data: row });
          },
        },
        {
          name: 'View',
          func: (row: any) => {
            setOpenViewModal(true);
          },
        },
        {
          name: 'Delete',
          func: (row: { id: string }) => {
            setPostId(row.id);
            setRefreshToken(false);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Author', key: 'author', type: 'string' },
    { name: 'Status', key: 'status', type: 'string' },
    { name: 'Response', key: 'votes_count', type: 'date' },
  ];
};

export const useGetTableRows = () => {
  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];
    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow['title'] = (
        <p style={{ width: '400px' }}>{decodeHtml(row['title'])} </p>
      );
      parsedRow['status'] = <p>{decodeHtml(row['status'])} </p>;
      parsedRow.author = <p>{decodeHtml(row?.author.name)} </p>;
      parsedRow['votes_count'] = <p>{decodeHtml(row['votes_count'])} </p>;
      parsedRow['identifier'] = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Polls', condition: (posts: any) => posts },
];
