import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  nav: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 1px 25px #0000000D',
    zIndex: 50,
    background: theme.design.colors.white,
    transition: 'all ease-in 0.2s',
  },
  nav__fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
  },
  nav__content: {
    position: 'relative',
    width: '90%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 240,
    padding: theme.spacing(0, 3),
  },
  postButton: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  showSubLinks: {
    height: 'auto !important',
  },
  navSubLinks: {
    fontFamily: theme.design.font.universal,
    width: '100%',
    maxWidth: '100%',
    gap: theme.spacing(5),
    background: theme.design.colors.greyDark,
    zIndex: 100,
    transition: 'all 32.5s ease-in',
    color: theme.design.colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',

    '& div': {
      fontSize: theme.typography.fontSize + 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '16px',
      textAlign: 'center',
      padding: theme.spacing(2, 0),
    },
  },
  nav__links: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 50,
    marginRight: 'auto',
  },
  nav__link: {
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '182px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    background: 'transparent',
    color: theme.design.colors.greyDark,
    transition: 'all 0.2s ease-in',

    '& .icon': {
      margin: '0 0 8px 0',
      width: '20px',
      height: '20px',
    },

    '& p': {
      textAlign: 'center',
      fontSize: '14px',
      color: 'inherit',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 8),
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    background: 'transparent',
    transition: 'all 0.2s ease-in',
    fontFamily: theme.design.font.universal,
    fontWeight: 500,
    '& a': {
      color: theme.design.colors.greyDark,
      textDecoration: 'none',
    },
  },
  navLinkActive: {
    paddingBottom: theme.spacing(1),
    borderBottom: `4px solid ${theme.design.colors.green100}`,
  },
  nav__link_active: {
    background: theme.design.colors.greyDark,
    color: theme.design.colors.white,
  },
  links: {
    display: 'flex',
    gap: theme.spacing(3.5),
    '& p': {
      fontSize: theme.typography.fontSize + 4,
      color: theme.design.colors.grey50,
    },
  },
  landingNav: {
    width: '100%',
    background: theme.design.colors.white,
    padding: theme.spacing(2, 0),
  },
  navImage: {
    position: 'relative',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
  },
  navImage_profile: {
    position: 'relative',
    width: '52px',
    height: '52px',
    borderRadius: '50%',
    margin: 'auto',
  },
  nav__sub_links: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    maxWidth: '100%',
    background: theme.design.colors.greyDark,
    zIndex: 100,
    transition: 'all 32.5s ease-in',
    color: theme.design.colors.white,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& div': {
      width: '182px',
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '16px',
      textAlign: 'center',
      height: '40px',
    },
  },
  profile_moreDetails: {
    fontSize: theme.typography.fontSize - 2,
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      padding: theme.spacing(0, 0.4),
      borderRight: `0.5px solid ${theme.design.colors.lidGrey}`,
      fontFamily: 'Feature',
    },
    '& .noBorder': {
      borderRight: 'none',
    },
  },

  primary: {
    background: `transparent !important`,
    color: `${theme.design.colors.black} !important`,
    border: `1px solid ${theme.design.colors.black} !important`,
  },
  ctaButton: {
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(2, 2.5)} !important`,
    borderBottom: `1px solid ${theme.design.colors.greyPitch}`,
    justifyContent: 'center',
    '& button': {
      fontSize: `${theme.typography.fontSize - 2}px !important`,
      paddingTop: `${theme.spacing(0.7)} !important`,
      whiteSpace: 'nowrap',
      boxSpacing: 'border-box !important',
    },
  },
  profile: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 60,
    width: 300,
    padding: theme.spacing(1, 0),
    zIndex: 99,
    right: 23,
    backgroundColor: theme.design.colors.white,
    borderRadius: '3px',
    border: `1px solid ${theme.design.colors.greyPitch}`,
    boxSizing: 'border-box',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    '& h3': {
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: 'Feature',
    },
    '& .link': {
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1, 2.5),
      textDecoration: 'none',
      color: theme.design.colors.grey100,

      display: 'flex',
      justifyContent: 'space-between',
      '& > span': {
        display: 'block',
      },
      '& > .icon': {
        transform: 'matrix(0.87, -0.5, 0.5, 0.87, 0, 0)',
      },
      '&:hover': {
        backgroundColor: theme.design.colors.grey500,
        cursor: 'pointer',
      },
    },
  },
  sub_link_hover: {
    '&:hover': {
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  profileCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileParent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: '3px',
    padding: theme.spacing(0.5, 2),
    border: `1px solid ${theme.design.colors.greyPitch}`,
    fontFamily: theme.design.font.universal,
    cursor: 'pointer',
  },
  toggleWidth: {
    width: '80%',
  },
  navInfo: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightMedium,
  },
  noMargin: {
    marginLeft: 0,
  },
  backIcon: {
    height: 41,
    width: 41,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    cursor: 'pointer',
  },
  rightSection: {
    display: 'flex',
    gap: theme.spacing(1),
    position: 'relative',
  },
  searchBar: {
    borderRadius: 10,
    border: 'none',
    transition: 'width 0.1s ease-in-out',
    padding: theme.spacing(0.5, 1, 1, 5),
    width: 45,
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  close: {
    display: 'none',
  },
  expanded: {
    width: '100%',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    '&:focus': {
      border: `1px solid ${theme.design.colors.lidGrey}`,
    },
  },
  searchIcon: {
    position: 'absolute',
    top: 11,
    left: 5,
  },
  gorillaIcon: {
    height: 27,
    width: 88,
    cursor: 'pointer',
  },
}));

export default useStyles;
