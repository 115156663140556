import { FC, ReactNode } from 'react';
import { useStylesRecipe } from '../../styles';
import { HeaderProps } from './types';

export const Header: FC<HeaderProps> = ({
  children,
  title,
  description,
  required,
}) => {
  const classes = useStylesRecipe();
  return (
    <div className={classes.recipeTitle} style={{ marginTop: 16 }}>
      <p className={classes.recipeTitleType}>
        {title}{' '}
        {required && (
          <span style={{ color: '#990000', fontSize: '14px' }}>*</span>
        )}
      </p>
      <p
        className={classes.recipeTitleType}
        style={{ fontWeight: 400, paddingTop: 0 }}
      >
        {description}
      </p>
      {children}
    </div>
  );
};
