import { gql } from '@apollo/client';

export const GET_POSTS_BY_CAMPAIGN_UUID = gql`
  query Posts($campaignUuid: String!, $take: Int!, $skip: Int!) {
    postsByCampaign(campaign_uuid: $campaignUuid, take: $take, skip: $skip) {
      posts {
        id
        url
        pageId
        text
        imageUrl
        commentsCount
        sentiment {
          negative
          neutral
          positive
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;
