export const generateTimestamp = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}/${parseElementString(month)}/${parseElementString(day)} ${
    hours > 12 ? hours - 12 : hours
  }:${parseElementString(minutes)}${hours > 12 ? 'pm' : 'am'}`;
};

const parseElementString = (element: number) => {
  const parsedElement = String(element);
  return parsedElement.length === 1 ? '0' + parsedElement : parsedElement;
};
