import makeStyles from '@mui/styles/makeStyles';
import { BaseTheme } from 'contexts/theme';

export const useRelatedPostsStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  title: {
    fontSize: theme.typography.fontSize + 2,
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  relatedHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  relatedToggle: {
    position: 'relative',
    top: -2,
    left: 12,
  },
  relatedToggleInput: {
    marginTop: theme.spacing(0.5),
    '&input:checked ~ .slider': {
      background: theme.design.colors.black,
    },
  },
  relatedDescription: {
    width: '70%',
    margin: theme.spacing(1, 0, 2, 0),
  },
  relatedAutoMessage: {
    margin: theme.spacing(1, 0, 2, 0),
    padding: theme.spacing(2, 5),
    borderRadius: 4,
    border: `1px solid ${theme.design.colors.jpGrey300}`,
  },
  relatedAddAction: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    cursor: 'pointer',

    '& p': {
      fontSize: theme.typography.fontSize + 2,
    },
  },
  relatedAddActionInactive: {
    opacity: 0.4,
  },
  relatedPlusParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    background: theme.design.colors.grey900,
    borderRadius: '50%',
  },
}));
