import { gql } from '@apollo/client';

export const FETCHPOSTS = gql`
  query getSocialPost($id: String, $update: Boolean) {
    socialPosts(id: $id, update: $update) {
      posts {
        id
        full_picture
        created_time
        from {
          name
        }
        message
        type
        permalink_url
        attachments
      }
      update
    }
  }
`;
