import React, { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LATESTPOLLS, MYSURVEYS } from './graphQL';
import { useQuery } from '@apollo/client';
import { CircularProgress, Typography, Modal, Box } from '@mui/material';
import Poll from '../Poll/Poll';
import { notifyError } from '../../utilities/notify';
import useStyles from './styles';
import PollProps from '../../types/PollType';

import Countdown from 'utilities/Countdown';
import { Button } from 'components/common/Button/Button';
import moment from 'moment';
import { RiBardFill } from 'react-icons/ri';
import { MdOutlineBarChart } from 'react-icons/md';
import { TiChartBar } from 'react-icons/ti';
import { ClassNameMap } from '@mui/styles';
import { IconType } from 'react-icons';
import { IoIosClose } from 'react-icons/io';
import { isDateStringLessThanCurrent } from 'utilities/date';
import { PiFolderOpen } from 'react-icons/pi';

type surveyPollsprops = {
  openViewModal: boolean;
  setOpenViewModal: any;
};

type widgetButtonProps = {
  handleOpenModal?: () => void;
  title: string;
  icon: any;
  color?: string;
};

export const WidgetButton: FC<widgetButtonProps> = ({
  handleOpenModal,
  title,
  icon,
  color,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.btnContainer}>
      <Button
        className={classes.ansBtn}
        onClick={handleOpenModal ?? undefined}
        style={{ background: color, color: color === '#fff' ? '#333' : '#fff' }}
      >
        {title}
      </Button>
    </div>
  );
};

const SurveyPolls: FC<surveyPollsprops> = ({
  openViewModal,
  setOpenViewModal,
}) => {
  const [poll, setPoll] = useState<boolean>(true);
  const [scrollIndex, setScrollIndex] = useState<number>(0);
  const [polls, setPolls] = useState<PollProps[]>([]);
  const [surveys, setSurveys] = useState<{ [key: string]: string }[]>([]);
  const [numColumns, setNumColumns] = useState<number>(
    poll ? polls.length : Math.ceil(surveys.length % 4)
  );
  const {
    data: pollData,
    loading: pollLoading,
    error: pollError,
  } = useQuery(LATESTPOLLS, { fetchPolicy: 'network-only' });
  const {
    data: surveyData,
    loading: surveyLoading,
    error: surveyError,
  } = useQuery(MYSURVEYS, { fetchPolicy: 'network-only' });
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (pollData) {
      const polls = pollData.latestPolls.data;
      setPolls(polls);
      const numColumns = poll ? polls.length : Math.ceil(surveys.length % 4);
      setNumColumns(numColumns);
    }

    if (pollError) {
      notifyError('An Error Occured');
    }
  }, [pollData, pollError, poll, surveys.length]);

  useEffect(() => {
    if (surveyData) {
      const surveys = surveyData.mySurveys;
      setSurveys(surveys);
    }

    if (surveyError) {
      notifyError('An Error Occured');
    }
  }, [surveyData, surveyError]);

  const displayPolls = () => {
    if (polls.length === 0) {
      return (
        <div className="empty-message">
          <Typography variant="body2">No polls to display</Typography>
        </div>
      );
    }
    return polls.map((poll, index) => {
      return (
        <div key={index} className="poll">
          <Poll
            {...poll}
            background="#F7F7F9"
            padding="10px"
            update={updatePolls}
          />
        </div>
      );
    });
  };

  const updatePolls = useCallback(
    (id, poll) => {
      const index = Number(
        polls?.findIndex((poll) => poll.id.toString() === id.toString())
      );
      if (polls) {
        const newPolls = [...polls];
        newPolls[index] = poll;
        setPolls(newPolls);
      }
    },
    // eslint-disable-next-line
    [polls]
  );
  const handleOpenModal = () => {
    setOpenViewModal(true);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <img
              src={
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-22T154909/RZSN1jWAMUEqASJQCLlM9hsOoZEpeKCgu26MbLlJ.png'
              }
            />
            <p className={classes.title}>Polls</p>
          </div>
          {!isDateStringLessThanCurrent(polls[0]?.expires_at) && (
            <p className={classes.closingPolls}>
              Closes by:{' '}
              <span>
                {moment(polls[0]?.expires_at).format('dddd Do MMM YY')}
              </span>
            </p>
          )}
        </div>
        {!isDateStringLessThanCurrent(polls[0]?.expires_at) ? (
          <div className={classes.containerContent}>
            <p className={classes.pollTitle}>{polls[0]?.title}</p>
            <p className={classes.pollAuthor}>
              author: {polls[0]?.author.name}
            </p>
            {polls[0]?.expires_at && (
              <div className={classes.countDown}>
                <Countdown expirationDate={polls[0]?.expires_at} />
              </div>
            )}
            <WidgetButton
              title={`${polls[0]?.vote ? 'View Result' : 'Answer Poll'}`}
              icon={<RiBardFill color={'#F6FF4A'} />}
              handleOpenModal={handleOpenModal}
            />
          </div>
        ) : (
          <div className={classes.emptyPolls}>
            <PiFolderOpen size={25} />
            <span>No polls</span>
          </div>
        )}
        <div
          className={`loader ${pollLoading || surveyLoading ? 'active' : ''}`}
        >
          <CircularProgress color="inherit" size="25px" />
        </div>
      </div>
      <Modal
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
        onBackdropClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.box}>
          {polls[0] && (
            <div className={classes.poll}>
              <Poll update={updatePolls} {...polls[0]} is_public={false}></Poll>
            </div>
          )}
          <IoIosClose
            size={25}
            style={{
              position: 'absolute',
              top: '10%',
              right: '5%',
              cursor: 'pointer',
            }}
            onClick={() => setOpenViewModal(false)}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SurveyPolls;
