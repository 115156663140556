import { gql } from '@apollo/client';

export const GETARTICLES = gql`
  query getPage($first: Int, $page: Int, $site: String) {
    pages(first: $first, page: $page, site: $site) {
      data {
        id
        title
        slug
        tags {
          name
        }
        status
        site
        category
        metadata {
          description
        }
        updated_at
        user {
          name
        }
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETEARTICLE = gql`
  mutation deletePage($uuid: ID!) {
    deleteBlogPost(uuid: $uuid) {
      id
    }
  }
`;

export const CORE_POST_FIELDS = gql`
  fragment CorePostFields on BlogPost {
    uuid
    title
    slug
    status
    categories {
      name
      uuid
    }
    author {
      name
    }
    tags {
      uuid
      name
    }
    published_at
    updated_at
  }
`;

export const CORE_POST_CONFIG_FIELDS = gql`
  fragment CorePostConfigFields on BlogPostConfig {
    permalink
    is_comments_enabled
    is_in_sitemap
    is_feature_article
    schema
    social_preview_config
    has_related_posts
    related_posts_config
  }
`;

export const EXTENDED_POST_FIELDS = gql`
  ${CORE_POST_CONFIG_FIELDS}
  fragment ExtendedPostFields on BlogPost {
    content
    excerpt
    title
    featured_image
    visibility
    uuid
    category {
      name
      uuid
    }
    author {
      name
      uuid
      avatar
    }
    tags {
      uuid
      name
    }
    published_at
    updated_at
    created_at
    metadata
    categories {
      name
      uuid
      slug
      parent {
        name
        uuid
        slug
      }
      subCategories {
        name
        uuid
        slug
      }
    }
    postConfig {
      ...CorePostConfigFields
    }
  }
`;

export const GETPOSTS = gql`
  query posts($page: Int, $first: Int!) {
    posts(page: $page, first: $first) {
      data {
        title
        postAuthor {
          name
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const CORE_TAG_FIELDS = gql`
  fragment CoreTagFields on BlogTag {
    name
    uuid
  }
`;

export const GET_BLOG_POSTS = gql`
  ${EXTENDED_POST_FIELDS}
  query GqlGetBlogPosts(
    $blog_uuid: ID
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
  ) {
    getBlogPostsByBlogUuid(
      input: {
        blog_uuid: $blog_uuid
        first: $first
        page: $page
        search: $search
        sort: $sort
      }
    ) {
      data {
        ...ExtendedPostFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const GETCATEGORIES = gql`
  query categoriesBySiteID($site_id: ID, $page: Int) {
    categoriesBySiteID(site_id: $site_id, page: $page) {
      data {
        description
        name
        uuid
        subCategory {
          description
          id
          name
        }
        posts {
          title
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const GETCATEGORY = gql`
  query getCategoryByUuid($uuid: ID) {
    getCategoryByUuid(uuid: $uuid) {
      name
      description
      subCategory {
        name
        id
      }
    }
  }
`;
export const CORE_CATEGORY_FIELDS = gql`
  fragment CoreCategoryFields on BlogCategory {
    uuid
    name
    description
    post_count
    slug
  }
`;

export const DELETE_CATEGORY = gql`
  mutation GqlDeleteCategory($uuid: ID!) {
    deleteBlogCategory(uuid: $uuid) {
      uuid
      name
      description
      slug
    }
  }
`;

export const GET_BLOG_CATEGORIES = gql`
  ${CORE_CATEGORY_FIELDS}
  query GqlGetBlogCategories(
    $blog_uuid: ID!
    $page: Int
    $first: Int
    $search: String
    $sort: [SortInput]
    $all: Boolean
  ) {
    getBlogCategoriesByBlogUuid(
      input: {
        blog_uuid: $blog_uuid
        page: $page
        first: $first
        search: $search
        sort: $sort
        all: $all
      }
    ) {
      data {
        ...CoreCategoryFields
        subCategories {
          name
          uuid
          slug
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  ${CORE_CATEGORY_FIELDS}
  mutation GqlCreateCategory(
    $slug: String!
    $name: String!
    $blog_uuid: ID!
    $description: String
    $subCategories: [BlogSubCategoryInput]
  ) {
    createBlogCategory(
      input: {
        slug: $slug
        name: $name
        blog_uuid: $blog_uuid
        description: $description
        subCategories: $subCategories
      }
    ) {
      ...CoreCategoryFields
    }
  }
`;

export const CREATE_TAG = gql`
  mutation GqlCreateTag($name: String!) {
    createBlogTag(name: $name) {
      uuid
      name
    }
  }
`;

export const GET_BLOG_TAGS = gql`
  ${CORE_TAG_FIELDS}
  query GqlGetTags(
    $blog_uuid: ID
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getTagsByBlogUuid(
      input: {
        blog_uuid: $blog_uuid
        page: $page
        first: $first
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        ...CoreTagFields
      }
    }
  }
`;

export const GET_POST = gql`
  ${CORE_POST_FIELDS}
  ${EXTENDED_POST_FIELDS}
  query GqlGetPostByUuid($uuid: ID!) {
    postByUuid(uuid: $uuid) {
      ...CorePostFields
      ...ExtendedPostFields
    }
  }
`;

export const GET_SEARCHED_POSTS = gql`
  query GqlGetPosts($blog_uuid: ID, $search: String) {
    getBlogPostsByBlogUuid(
      input: { blog_uuid: $blog_uuid, search: $search, first: 3, page: 1 }
    ) {
      data {
        uuid
        title
        published_at
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation GqlCreatePost(
    $slug: String
    $title: String
    $uuid: ID
    $blog_uuid: ID
    $status: Boolean
    $content: String
    $author_uuid: ID
    $category_uuids: [ID]
    $tags: [String]
    $featured_image: JSON
    $published_at: DateTime
    $excerpt: String
    $visibility: Boolean
    $version: String
    $config: PostConfigInput
    $metadata: JSON
  ) {
    createBlogPost(
      input: {
        slug: $slug
        title: $title
        uuid: $uuid
        status: $status
        content: $content
        blog_uuid: $blog_uuid
        author_uuid: $author_uuid
        category_uuids: $category_uuids
        tags: $tags
        featured_image: $featured_image
        published_at: $published_at
        excerpt: $excerpt
        visibility: $visibility
        version: $version
        config: $config
        metadata: $metadata
      }
    ) {
      ...CorePostFields
      ...ExtendedPostFields
    }
  }
  ${CORE_POST_FIELDS}
  ${EXTENDED_POST_FIELDS}
`;

export const UPDATE_POST = gql`
  mutation GqlUpdatePost(
    $uuid: ID!
    $slug: String
    $title: String
    $status: Boolean
    $content: String
    $category_uuids: [ID]
    $tags: [String]
    $featured_image: JSON
    $published_at: String
    $excerpt: String
    $visibility: Boolean
    $version: String
    $config: PostConfigInput
    $author_uuid: ID
    $metadata: JSON
  ) {
    updateBlogPost(
      input: {
        uuid: $uuid
        slug: $slug
        title: $title
        status: $status
        content: $content
        category_uuids: $category_uuids
        tags: $tags
        featured_image: $featured_image
        published_at: $published_at
        excerpt: $excerpt
        visibility: $visibility
        version: $version
        config: $config
        author_uuid: $author_uuid
        metadata: $metadata
      }
    ) {
      ...CorePostFields
      ...ExtendedPostFields
    }
  }
  ${CORE_POST_FIELDS}
  ${EXTENDED_POST_FIELDS}
`;
export const GET_POSTS_BY_BLOG_UUID = gql`
  ${CORE_POST_FIELDS}
  ${EXTENDED_POST_FIELDS}
  query GqlGetBlogPostByUUID(
    $blog_uuid: ID
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getBlogPostsByBlogUuid(
      input: {
        blog_uuid: $blog_uuid
        first: $first
        page: $page
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        ...CorePostFields
        ...ExtendedPostFields
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
        hasMorePages
      }
    }
  }
`;

export const GET_BLOG_POST = gql`
  ${CORE_POST_FIELDS}
  ${EXTENDED_POST_FIELDS}
  query GqlGetPostByUuid($uuid: ID!, $blog_uuid: ID!) {
    getBlogPostByUuid(uuid: $uuid, blog_uuid: $blog_uuid) {
      ...CorePostFields
      ...ExtendedPostFields
    }
  }
`;

export const GET_RELATED_POSTS = gql`
  query GqlGetRelatedPosts($uuids: [ID]) {
    getBlogPostsByUuids(uuids: $uuids) {
      uuid
      title
      published_at
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GqlGetAllUsers {
    getAllUsers {
      uuid
      id
      name
      role
      department
    }
  }
`;
export const GET_RECIPES_BY_SITE_UUID = gql`
  query GqlGetRecipesBySiteUuid(
    $site_uuid: ID
    $perPage: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
    $include_drafts: Boolean
  ) {
    getRecipesBySiteUuid(
      input: {
        site_uuid: $site_uuid
        perPage: $perPage
        page: $page
        search: $search
        sort: $sort
        filter: $filter
        include_drafts: $include_drafts
      }
    ) {
      data {
        id
        uuid
        title
        description
        excerpt
        slug
        permalink
        status
        ingredient_lists
        method
        cover_image
        serves
        cooking_time
        cooking_time_extra
        author_uuid
        author {
          name
          uuid
        }
        published_at
        video_link
        site_uuid
        measurement
        created_at
        updated_at
        tags {
          uuid
          name
          site_uuid
        }
        categories {
          uuid
          name
          slug
          site_uuid
          description
        }
        category {
          uuid
          name
          slug
          site_uuid
          description
        }
        config {
          is_in_sitemap
          is_feature_recipe
          schema
          social_preview_config
          has_related_recipes
          related_recipes_config
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;
export const GET_GUIDE_BY_SITE_UUID = gql`
  query GqlGetGuidesBySiteUuid(
    $site_uuid: ID
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
    $include_drafts: Boolean
  ) {
    getGuidesBySiteUuid(
      input: {
        site_uuid: $site_uuid
        page: $page
        search: $search
        sort: $sort
        filter: $filter
        include_drafts: $include_drafts
      }
    ) {
      data {
        id
        uuid
        slug
        content
        excerpt
        status
        featured_image
        title
        visibility
        top_picks_content
        subtopics
        published_at
        created_at
        updated_at
        config {
          is_comments_enabled
          is_in_sitemap
          is_feature_guide
          schema
          social_preview_config
          has_related_guides
          related_guides_config
        }
        categories {
          id
          uuid
          name
          slug
          description
          created_at
          updated_at
        }
        author {
          name
          uuid
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const GET_RECIPE_BY_UUID = gql`
  query GqlGetRecipeByUuid($uuid: ID!) {
    getRecipeByUuid(uuid: $uuid) {
      id
      uuid
      title
      description
      excerpt
      slug
      permalink
      status
      ingredient_lists
      method
      cover_image
      serves
      cooking_time
      cooking_time_extra
      author_uuid
      author {
        name
        uuid
      }
      published_at
      video_link
      site_uuid
      measurement
      created_at
      updated_at
      tags {
        uuid
        name
        site_uuid
      }
      categories {
        uuid
        name
        slug
        site_uuid
        description
      }
      category {
        uuid
        name
        slug
        site_uuid
        description
      }
      config {
        is_in_sitemap
        is_feature_recipe
        schema
        social_preview_config
        has_related_recipes
        related_recipes_config
      }
    }
  }
`;

//still needed to be changed
export const GET_GUIDE_BY_UUID = gql`
  query GqlGetGuideByUuid($uuid: ID, $site_uuid: ID) {
    getGuideByUuid(input: { uuid: $uuid, site_uuid: $site_uuid }) {
      id
      uuid
      slug
      content
      excerpt
      status
      featured_image
      title
      visibility
      top_picks_content
      subtopics
      published_at
      created_at
      updated_at
      config {
        is_comments_enabled
        is_in_sitemap
        is_feature_guide
        schema
        social_preview_config
        has_related_guides
        related_guides_config
      }
      categories {
        id
        uuid
        name
        slug
        description
        created_at
        updated_at
      }
      author {
        name
        uuid
      }
    }
  }
`;

export const DELETE_RECIPE_BY_UUID = gql`
  mutation GqlDeleteRecipe($uuid: ID!) {
    deleteRecipe(uuid: $uuid) {
      title
      description
      uuid
      slug
      updated_at
    }
  }
`;

export const DELETE_GUIDE_BY_UUID = gql`
  mutation GqlDeleteRecipe($uuid: ID!) {
    deleteRecipe(uuid: $uuid) {
      title
      description
      uuid
      slug
      updated_at
    }
  }
`;

export const CREATE_RECIPE = gql`
  mutation GqlCreateRecipe(
    $title: String!
    $description: String
    $excerpt: String
    $slug: String!
    $permalink: String!
    $status: Boolean!
    $ingredient_lists: String
    $method: String
    $cover_image: String
    $serves: String
    $cooking_time: String
    $cooking_time_extra: String
    $author_uuid: ID
    $tags: [String]
    $category_uuids: [ID]
    $published_at: String
    $video_link: String
    $site_uuid: ID!
    $config: ConfigInput
    $measurement: String
  ) {
    createRecipe(
      createRecipeInput: {
        title: $title
        description: $description
        excerpt: $excerpt
        slug: $slug
        permalink: $permalink
        status: $status
        ingredient_lists: $ingredient_lists
        method: $method
        cover_image: $cover_image
        serves: $serves
        cooking_time: $cooking_time
        cooking_time_extra: $cooking_time_extra
        author_uuid: $author_uuid
        tags: $tags
        category_uuids: $category_uuids
        published_at: $published_at
        video_link: $video_link
        site_uuid: $site_uuid
        config: $config
        measurement: $measurement
      }
    ) {
      title
      description
      uuid
      slug
      permalink
      updated_at
      created_at
    }
  }
`;
export const CREATE_GUIDE = gql`
  mutation GqlCreateGuides(
    $title: String!
    $content: String
    $excerpt: String
    $slug: String!
    $status: Boolean!
    $top_picks_content: JSON
    $featured_image: JSON
    $author_uuid: ID
    $tags: [String]
    $category_uuids: [ID]
    $published_at: DateTime
    $subtopics: JSON
    $site_uuid: ID!
    $config: GuideConfigInput
  ) {
    createGuide(
      input: {
        title: $title
        content: $content
        excerpt: $excerpt
        slug: $slug
        status: $status
        top_picks_content: $top_picks_content
        featured_image: $featured_image
        author_uuid: $author_uuid
        tags: $tags
        category_uuids: $category_uuids
        published_at: $published_at
        subtopics: $subtopics
        site_uuid: $site_uuid
        config: $config
      }
    ) {
      title
      content
      uuid
      slug
      updated_at
    }
  }
`;

export const UPDATE_RECIPE = gql`
  mutation GqlUpdateRecipe(
    $uuid: ID!
    $title: String
    $description: String
    $excerpt: String
    $slug: String
    $permalink: String
    $status: Boolean
    $ingredient_lists: String
    $method: String
    $cover_image: String
    $serves: String
    $cooking_time: String
    $cooking_time_extra: String
    $author_uuid: ID
    $tags: [String]
    $category_uuids: [ID]
    $published_at: String
    $video_link: String
    $config: ConfigInput
    $measurement: String
    $category: ID
  ) {
    updateRecipe(
      updateRecipeInput: {
        uuid: $uuid
        title: $title
        description: $description
        excerpt: $excerpt
        slug: $slug
        permalink: $permalink
        status: $status
        ingredient_lists: $ingredient_lists
        method: $method
        cover_image: $cover_image
        serves: $serves
        cooking_time: $cooking_time
        cooking_time_extra: $cooking_time_extra
        author_uuid: $author_uuid
        tags: $tags
        category_uuids: $category_uuids
        category: $category
        published_at: $published_at
        video_link: $video_link
        config: $config
        measurement: $measurement
      }
    ) {
      title
      description
      uuid
      slug
      updated_at
    }
  }
`;
export const UPDATE_GUIDE = gql`
  mutation GqlUpdateGuide(
    $uuid: ID!
    $title: String!
    $content: String
    $excerpt: String
    $slug: String!
    $status: Boolean!
    $top_picks_content: JSON
    $featured_image: JSON
    $author_uuid: ID
    $tags: [String]
    $category_uuids: [ID]
    $published_at: DateTime
    $subtopics: JSON
    $site_uuid: ID
    $config: GuideConfigInput
  ) {
    updateGuide(
      input: {
        uuid: $uuid
        title: $title
        content: $content
        excerpt: $excerpt
        slug: $slug
        status: $status
        top_picks_content: $top_picks_content
        featured_image: $featured_image
        author_uuid: $author_uuid
        tags: $tags
        category_uuids: $category_uuids
        published_at: $published_at
        subtopics: $subtopics
        site_uuid: $site_uuid
        config: $config
      }
    ) {
      title
      uuid
      slug
      updated_at
    }
  }
`;

export const GET_RECIPE_CATEGORIES = gql`
  query GetRecipeCategory(
    $site_uuid: ID
    $perPage: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
    $all: Boolean
  ) {
    getCategoriesBySiteUuid(
      input: {
        site_uuid: $site_uuid
        perPage: $perPage
        page: $page
        search: $search
        sort: $sort
        filter: $filter
        all: $all
      }
    ) {
      data {
        id
        uuid
        name
        slug
        site_uuid
        description
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
      }
    }
  }
`;

export const CREATE_RECIPE_CATEGORY = gql`
  mutation GqlCreateRecipeCategory(
    $slug: String!
    $name: String!
    $site_uuid: ID!
    $description: String
  ) {
    createCategory(
      createCategoryInput: {
        slug: $slug
        name: $name
        site_uuid: $site_uuid
        description: $description
      }
    ) {
      uuid
      name
      slug
      description
      created_at
      updated_at
    }
  }
`;

export const CREATE_RECIPE_TAG = gql`
  mutation GqlCreateRecipeCategory($name: String!, $site_uuid: ID!) {
    createTag(createTagInput: { name: $name, site_uuid: $site_uuid }) {
      uuid
      name
      site_uuid
      created_at
      updated_at
    }
  }
`;

export const CREATE_GUIDE_TAG = gql`
  mutation GqlCreateGuideCategory($name: String!, $site_uuid: ID!) {
    createGuideTag(name: $name, site_uuid: $site_uuid) {
      uuid
      name
      created_at
      updated_at
    }
  }
`;

export const UPDATE_RECIPE_CATEGORY = gql`
  mutation GqlUpdateRecipeCategory(
    $uuid: ID!
    $name: String
    $description: String
    $slug: String
  ) {
    updateCategory(
      updateCategoryInput: {
        name: $name
        description: $description
        slug: $slug
        uuid: $uuid
      }
    ) {
      uuid
      name
      slug
      description
    }
  }
`;

export const DELETE_RECIPE_CATEGORY = gql`
  mutation GqlDeleteRecipeCategory($uuid: ID!) {
    deleteCategory(uuid: $uuid) {
      name
    }
  }
`;

export const GET_RECIPE_TAGS = gql`
  query GqlGetTagsBySiteUuid(
    $site_uuid: ID
    $perPage: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
    $all: Boolean
  ) {
    getTagsBySiteUuid(
      input: {
        site_uuid: $site_uuid
        perPage: $perPage
        page: $page
        search: $search
        sort: $sort
        filter: $filter
        all: $all
      }
    ) {
      data {
        id
        uuid
        name
        site_uuid
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
      }
    }
  }
`;

export const GET_GUIDE_TAGS = gql`
  query GqlGetTagsBySiteUuid($site_uuid: ID) {
    getGuideTagsBySiteUuid(site_uuid: $site_uuid) {
      id
      uuid
      name
      created_at
      updated_at
    }
  }
`;
