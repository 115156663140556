import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useCheckboxStyles = makeStyles((theme: BaseTheme) => ({
  root: {},
  label: {
    fontSize: theme.typography.fontSize + 8,
    color: theme.design.colors.grey100,
  },
  checkbox: {
    color: theme.design.colors.grey100,
  },
}));
