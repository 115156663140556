import { getPostConfig } from 'components/Blog/helpers';
import { useEffect, useRef, useState } from 'react';
import { generateTimestamp } from 'components/Blog/components/CreateContent/components/VersionPrompt/helpers';
import { parseRecursive } from 'components/Blog/components/CreateContent/helpers';
import { createGuide, updateGuide } from '../helpers';

export const getOptions = (form: any) => {
  if (!form) return [];

  const parsedOption: { label: string; value: string }[] = [];

  form.forEach((name: string) => {
    parsedOption.push({ label: name, value: name });
  });

  return parsedOption;
};

export const validateGuidesInformation = (form: any, status: boolean) => {
  const fields = ['title', 'content', 'slug', 'excerpt'];

  const invalidFields: string[] = [];

  fields.forEach((field) => {
    if (field === 'categories') {
      status &&
        (!form?.categories || form?.categories?.length === 0) &&
        invalidFields.push(field);
    } else if (!form[field]) invalidFields.push(field);
  });

  return invalidFields.map(
    (field) => field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()
  );
};

export const getGuideInformation = (form: any, siteUuid: string) => {
  const { categories } = form;
  const category_uuids: string[] = [];

  if (categories) {
    categories.forEach(
      ({
        uuid,
        subCategories,
      }: {
        name: string;
        uuid: string;
        subCategories?: { name: string; uuid: string }[];
      }) => {
        const category_uuid =
          subCategories && subCategories.length > 0
            ? subCategories[0].uuid
            : uuid;
        category_uuids.push(category_uuid);
      }
    );
  }

  const config = getPostConfig(form);
  let related_guides_config = config.related_posts_config;
  let is_feature_guide = config.is_feature_article;
  delete config.related_posts_config;
  delete config.is_feature_article;

  return {
    title: form?.title,
    content: form?.content,
    excerpt: form?.excerpt,
    slug: form?.slug,
    top_picks_content: JSON.stringify(form.top_picks_content),
    subtopics: JSON.stringify(form.subtopics),
    status: form?.status,
    category_uuids,
    featured_image: JSON.stringify(parseRecursive(form.featured_image)),
    author_uuid: form?.author_uuid,
    tags: form?.tags
      ? form.tags
          .map((item: any) => {
            if (typeof item == 'string') {
              return item;
            } else if (item && item?.name) {
              return item.name;
            }
            return '';
          })
          .filter(Boolean)
      : [],
    config: {
      ...config,
      related_guides_config,
      is_feature_guide,
    },
    site_uuid: siteUuid,
    published_at: form.published_at,
  };
};

export const useGuidesAutosave = (
  initialData: any,
  other: { [key: string]: any },
  saveInterval = 30000
): [
  {
    [key: string]: any;
  },
  React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >
] => {
  const localData = localStorage.getItem('guideFormData');
  const storedData = localData ? JSON.parse(localData) : initialData;
  const { site_uuid } = other;

  const [formData, setFormData] = useState<{ [key: string]: any }>(storedData);

  const lastSavedDataRef = useRef(storedData);

  const handleSubmit = async (version: string, status: boolean) => {
    const variables = getGuideInformation(formData, site_uuid);

    if (!formData?.guide_uuid) {
      await createGuide(variables)
        .then((res) => {
          window.history.replaceState(
            {
              row: res?.createGuide,
            },
            document.title
          );
        })
        .catch((error) => {});
    } else {
      await updateGuide({ ...variables, uuid: formData.guide_uuid })
        .then((res) => {
          window.history.replaceState(
            {
              row: res?.updateGuide,
            },
            document.title
          );
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        JSON.stringify(lastSavedDataRef.current) !== JSON.stringify(formData)
      ) {
        const invalidFields = validateGuidesInformation(formData, true);
        if (invalidFields.length == 0) {
          await handleSubmit(generateTimestamp(), formData['status']);
        }

        localStorage.setItem('guideFormData', JSON.stringify(formData));
        lastSavedDataRef.current = formData;
      }
    }, saveInterval);

    return () => clearInterval(interval);
  }, [formData, saveInterval]);

  return [formData, setFormData];
};

export const getSeoData = (config: { [key: string]: any }) => {
  const properties = ['image', 'description', 'title', 'url'];

  return properties.reduce((seoData, property) => {
    let value =
      config?.seo_cards?.[`seo:${property}`] ??
      config.seo_cards?.[`seo_${property}`] ??
      config.open_graph?.[`og:${property}`];

    if (property === 'image') {
      value = JSON.stringify(parseRecursive(value));
    }

    return { ...seoData, [`seo_${property}`]: value };
  }, {});
};

export const getSocialData = (config: { [key: string]: any }) => {
  return {
    social_title: config?.title,
    social_description: config?.description,
    social_image: JSON.stringify(parseRecursive(config.open_graph['og:image'])),
  };
};

export const getTwitterData = (config: { [key: string]: any }) => {
  return {
    twitter_image: JSON.stringify(
      parseRecursive(config.twitter_cards?.['twitter:image'])
    ),
    twitter_description: config.twitter_cards?.['twitter:description'],
    twitter_title: config.twitter_cards?.['twitter:title'],
  };
};

export const parseContentRecursive = (data: string = '') => {
  if (typeof data !== 'string' || data === '') return data;

  try {
    const parsedData = JSON.parse(data);
    if (typeof parsedData === 'string') {
      return parseRecursive(parsedData);
    }
    return parsedData;
  } catch (error) {
    return {};
  }
};
