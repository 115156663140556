import useStyles from './styles';
import SubSectionList from 'pages/PageContent/components/subSectionList';
import { faqTabs } from 'pages/Content/data';

const FAQ = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T103048/5I9ACEe4yjiBHPGImPGC9IJ1rfH5Qs1OKs5IEQTu.png'
            }
          />
          FAQ
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T102719/106iJUHwWoT6L88zkzTfv1mbA3nYdfmAdWLftqbP.png'
            }
          />
        </h1>
      </div>
      <div className={classes.content}>
        <SubSectionList cards={faqTabs} />
      </div>
    </div>
  );
};

export default FAQ;
