import React from 'react';
import _ from 'lodash';
import FormElement from './FormElement/FormElement';
import { FormBuilderProps, FormFieldsProps } from './types';

const FormBuilder: React.FC<FormBuilderProps> = ({
  sectionName,
  groupName,
  form,
  setForm,
  canHaveMultiple,
  activeCardIndex,
  ...props
}) => {
  const getValue = (field: FormFieldsProps) => {
    if (field.type == 'layout') return null;
    if (_.get(form, `[${sectionName}][${groupName}]`, null)) {
      if (canHaveMultiple) {
        if (
          form &&
          form[sectionName] &&
          form[sectionName][groupName].length > 0
        ) {
          return form[sectionName][groupName][activeCardIndex as number][
            field.properties.name
          ];
        }
      }
      return form[sectionName][groupName][field.properties.name];
    }

    return null;
  };

  const onChange = (e: any) => {
    setForm((prevState: any) => ({
      ...prevState,
      [sectionName]: {
        ...prevState[sectionName],
        [groupName]: canHaveMultiple
          ? (() => {
            // find key of the current active card from the prop instead of hardcoding index 0
            const newArray = [...prevState[sectionName][groupName]];
            newArray[activeCardIndex as number] = {
              ...newArray[activeCardIndex as number],
              [e.target.name]: e.target.value,
            };
            return newArray;
          })()
          : {
            ...prevState[sectionName][groupName],
            [e.target.name]: e.target.value,
          },
      },
    }));
  };

  const displayFields = (fields: FormFieldsProps[]) => {
    return fields.map((field, index) => {
      const properties = {
        ...field,
        properties: {
          ...field.properties,
          value: getValue(field),
          onChange,
        },
      };
      return (
        <FormElement
          {...properties}
          key={index}
          sectionName={sectionName}
          groupName={groupName}
          form={form}
          setForm={setForm}
          canHaveMultiple={canHaveMultiple as boolean}
          activeCardIndex={activeCardIndex as number}
        />
      );
    });
  };

  return <div className={props.className}>{displayFields(props.fields)}</div>;
};

export default FormBuilder;
