import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useCardStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.design.colors.offWhite}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  deleteBtn: {
    cursor: 'pointer',
    textDecoration: 'underline',
    position: 'absolute',
    bottom: 48,
    right: 16,
  },
  inputParent: {
    width: '48%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  fileField: {
    width: '100%',
  },
}));
