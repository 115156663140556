import { ApolloError, useMutation } from '@apollo/client';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { Button } from 'components/common/Button/Button';
import { useCallback, useState } from 'react';
import { useModal } from '../../common/ModalManager';
import { Icon } from '@iconify/react';
import { ADD_POST_TO_CAMPAIGN } from 'graphql/jcmat/addPostToCampaign';
import { toast } from 'react-toastify';
import success from '../../common/assets/success.svg';
import { isValidSocialUrl } from '../../helper';

/**
 * Handles adding a post to a Campaign
 * - Expects campaign uuid as params
 *
 * 1. Ensure the url is a valid url: ensure has valid url for FB posts
 * 2. Validate url can be accessed with comments - Skip for now
 * 3. Save post if valid
 */
const AddPostToCampaign = ({ campaignUuid }: { campaignUuid: string }) => {
  const [url, setUrl] = useState();
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const { hideModal } = useModal();
  const [addPostToCampaign] = useMutation(ADD_POST_TO_CAMPAIGN);

  const handleSave = useCallback(async () => {
    if (!url || !isValidSocialUrl(url)) {
      setError('Please enter a valid Facebook, Instagram, or LinkedIn URL.');
      return;
    }

    try {
      await addPostToCampaign({
        variables: {
          campaign_uuid: campaignUuid,
          post_url: url,
        },
      });
      setShowSuccess(true);
    } catch (error) {
      if (error instanceof ApolloError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred.');
      }
    }
  }, [url, addPostToCampaign]);

  const handleChange = useCallback(
    (event) => {
      setUrl(event.value);
    },
    [url]
  );

  return (
    <div className="space-y-8">
      {showSuccess ? (
        <div className="flex flex-col items-center justify-center my-4">
          <img src={success} />
          <h1 className="text-xl font-medium my-4">Posts Added Successfully</h1>
          <p className="text-lg font-light text-center mb-12 text-[#979797]">
            Your post has been added successfully!.Start analyzing their
            performance now!
          </p>
          <Button
            onClick={hideModal}
            className="!rounded-full w-full !h-[56px]"
          >
            Done
          </Button>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <p className="text-lg">Add Post</p>

            <button onClick={hideModal}>
              <Icon icon="ic:round-close" width={20} color="black" />
            </button>
          </div>

          <p className="text-[#383838]">Add a post to a campaign</p>

          <div className="mr-5 space-y-5">
            <TextInput
              width="100%"
              height="25px"
              name={'name'}
              value={url}
              onChange={handleChange}
              label="Post Url"
              placeholder="Enter the url of the post"
              borderRadius="8px"
              className="text-[#6C747A] placeholder:text-[#6C747A]"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>

          <div>
            <Button
              onClick={handleSave}
              className="!rounded-full w-full !h-[56px]"
            >
              Add Post to Campaign
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPostToCampaign;
