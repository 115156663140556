import { gql } from '@apollo/client';

export const FETCHCONTACTDETAILS = gql`
  query ContactDetails($first: Int, $page: Int) {
    contactDetails(first: $first, page: $page) {
      data {
        name
        company
        email
        number
        site
        created_at
        service
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;
