export const getNextBirthday = (date: number | string | Date) => {
  // Current Date
  const currentDate = new Date();

  // Set the users birthday to this year (originally from thier birth year)
  const birthday = new Date(date);
  birthday.setFullYear(currentDate.getFullYear());

  // If the birthday has already occured this year.  Then thier next birthday is next year.
  if (birthday.valueOf() - currentDate.valueOf() < 0) {
    birthday.setFullYear(currentDate.getFullYear() + 1);
  }

  // Return the users next birthday as a date.
  return birthday;
};

export const getUpcomingBirthdays = (
  bdays: { [key: string]: number | string | Date }[]
) => {
  return bdays
    ?.slice(0)
    .sort(
      (
        a: { [key: string]: number | string | Date },
        b: { [key: string]: number | string | Date }
      ) => getNextBirthday(a.dob).valueOf() - getNextBirthday(b.dob).valueOf()
    );
};
