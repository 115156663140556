import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clx from 'classnames';
import { CONTENT } from './graphQL';
import { useLazyQuery } from '@apollo/client';
import useStyles from './styles';
import Spinner from '../../components/common/Spinner/Spinner';
import { notifyError } from '../../utilities/notify';
import {
  welcomeTabs,
  cultureTabs,
  hrTabs,
  officeTabs,
  financeTabs,
} from './data';

type Page = {
  title: string;
  content: string;
};

const Content = () => {
  const [page, setPage] = useState<Page | null>(null);
  const [fetchPage, { data, loading, error }] = useLazyQuery(CONTENT, {
    fetchPolicy: 'network-only',
  });
  const classes = useStyles();
  const location = useLocation();
  const slug = window.location.pathname.split('/').slice(-1)[0];
  const landingFilter =
    !location.pathname.includes('admin') && !location.pathname.includes('cms');

  useEffect(() => {
    fetchPage({ variables: { slug } });
    // eslint-disable-next-line
  }, [slug, location]);

  useEffect(() => {
    if (data && data.content) {
      setPage(data.content);
    }

    if (data && !data.content) {
      notifyError('Page Does Not Exist');
      setPage(null);
    }

    if (error) {
      notifyError('An Error Occured');
    }
  }, [data, error]);

  const determineTabs = () => {
    if (welcomeTabs.some((item) => location.pathname.includes(item.route)))
      return welcomeTabs;
    else if (cultureTabs.some((item) => location.pathname.includes(item.route)))
      return cultureTabs;
    else if (hrTabs.some((item) => location.pathname.includes(item.route)))
      return hrTabs;
    else if (officeTabs.some((item) => location.pathname.includes(item.route)))
      return officeTabs;
    else if (financeTabs.some((item) => location.pathname.includes(item.route)))
      return financeTabs;
    else return;
  };

  const displayTabs = () =>
    determineTabs()?.map((tab, index) => (
      <Link
        key={index}
        to={tab.route}
        className={clx(
          classes.link,
          location.pathname.includes(tab.route) && classes.linkActive
        )}
      >
        {tab.name}
      </Link>
    ));

  return (
    <div>
      {page && (
        <div
          className={clx(classes.tabs, landingFilter && classes.landingTabs)}
        >
          {displayTabs()}
        </div>
      )}
      {page && (
        <div
          className={clx(
            classes.content,
            {
              [classes.content__loading]: loading,
            },
            landingFilter && classes.neutral
          )}
        >
          <p className={classes.content__title}>{page?.title}</p>
          <div
            className={classes.content__details}
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      )}
      <div
        className={clx(classes.content__loader, {
          [classes.show__contentLoader]: loading,
        })}
      >
        <Spinner display={true} variant="large" />
      </div>
    </div>
  );
};

export default Content;
