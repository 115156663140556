import { gql } from '@apollo/client';

export const FETCHTASKS = gql`
  query getTasks($first: Int, $page: Int) {
    tasks(first: $first, page: $page) {
      data {
        id
        name
        assignee_id
        status
        deadline
        description
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;

export const DELETETASK = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`;
