import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import WidgetNav from '../common/WidgetNav/WidgetNav';
import { GET_BIRTHDAYS } from './graphQL';
import { getUpcomingBirthdays } from './helpers';
import useStyles from './styles';
import clx from 'classnames';

type BirthdayWidgetProps = {
  className?: string;
};

const DATA_LIMIT = 1;

const BirthdayWidget: FC<BirthdayWidgetProps> = ({ className }) => {
  const classes = useStyles();
  const { data, loading } = useQuery(GET_BIRTHDAYS);
  const [pages, setPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [birthday, setBirthday] = useState<{ [key: string]: any }[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setPages(Math.ceil(data?.getBirthdays?.length / DATA_LIMIT));
    }
  }, [loading, data]);

  const goToNextPage = useCallback(() => {
    if (currentPage !== pages) {
      setCurrentPage((page) => page + 1);
    }
  }, [currentPage, pages, setCurrentPage]);

  useEffect(() => {
    if (data) {
      const birthdays: { [key: string]: any }[] = getUpcomingBirthdays(
        data?.getBirthdays
      );
      const newBirthdays: { [key: string]: any }[] = [];

      birthdays.forEach(({ dob, avatar, name }) => {
        const date = new Date(dob).toLocaleDateString('en-Us', {
          month: 'short',
          day: 'numeric',
        });
        newBirthdays.push({ dob: date, avatar, name });
      });

      setBirthday(newBirthdays);
    }
  }, [data]);

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((page) => page - 1);
    }
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * DATA_LIMIT - DATA_LIMIT;
    const endIndex = startIndex + DATA_LIMIT;
    return birthday?.slice(startIndex, endIndex);
  };

  const todayDate = new Date().toLocaleDateString('en-Us', {
    month: 'short',
    day: 'numeric',
  });

  const todayEntries = getPaginatedData().filter(
    (data: any) => data.dob === todayDate
  );

  const showWidgetNav =
    todayEntries.length > 1 ||
    (todayEntries.length === 1 &&
      todayEntries.length < getPaginatedData().length);

  return (
    <div className={clx(classes.container, className)}>
      <div className={classes.titleParent}>
        <div className={classes.title}>
          <img
            src="https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-22T155023/4GCdiimyCvbXErYQ08sRe5X1W0tOigW16BZZANUL.png"
            alt="employeeIcon"
          />
          <p className={classes.headerTitle}>Birthdays</p>
        </div>
      </div>
      {loading ? (
        <div className={classes.loader_container}>
          <CircularProgress className={classes.loader} size="24px" />
        </div>
      ) : (
        <div className={classes.scroll}>
          <div className={classes.dob}>
            {getPaginatedData()?.length > 0
              ? getPaginatedData()[0].dob === todayDate
                ? 'Today'
                : getPaginatedData()[0].dob
              : ''}
          </div>
          {getPaginatedData()?.map((data: any, index: number) => (
            <div key={index} className={classes.listContainer}>
              <div className={classes.birthdayDetails}>
                <div className={classes.list_image}>
                  <img
                    className={classes.avatar_image}
                    src={
                      data?.avatar?.image
                        ? `data:image/${data?.avatar?.type.toLowerCase()};base64, ${
                            data?.avatar?.image
                          }`
                        : 'https://jungle-portal.s3-us-west-1.amazonaws.com/Neutral2.png'
                    }
                    alt="Jungle Creations Logo"
                    width="100%"
                  />
                </div>
                <p className={classes.name}>
                  {data.name.length > 23
                    ? data.name.slice(0, 20) + '...'
                    : data.name}
                  <span>{data.role ? data.role : ''}</span>
                </p>
              </div>
              {/* {todayDate === data.dob && ( */}
              {/* <WidgetButton
                title="Send a Message"
                icon={<FaFaceSmile color={'#00FAE8'} size={20} />}
                // handleOpenModal={handleOpenModal}
              /> */}
              {/* )} */}
            </div>
          ))}
        </div>
      )}
      {/* {todayEntries.length > 1 && ( */}
      <WidgetNav
        onClickLeft={goToPreviousPage}
        onClickRight={goToNextPage}
        className={classes.navWidget}
        color={'#F6FF4A'}
        startingPoint={currentPage === 1}
        endingPoint={birthday?.length === currentPage}
      />
      {/* )} */}
    </div>
  );
};

export default BirthdayWidget;
