import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  body: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  sections: {
    width: '95%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),
    columnGap: theme.spacing(2),
  },
  section: {
    background: '#FFDCD2 0% 0% no-repeat padding-box',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3),
    opacity: 1,
  },
  sectionName: {
    font: 'normal normal bold 24px/36px universal',
    letterSpacing: 0,
    color: '#010E05',
    opacity: 1,
    textTransform: 'capitalize',
  },
  sectionButton: {
    font: 'normal normal normal 12px/18px universal',
    letterSpacing: 0,
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '& > span': {
      display: 'block',
    },
  },
  sectionLink: {
    textDecoration: 'none',
  },
}));

export default useStyles;
