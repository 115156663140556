import React, { useState, FC } from 'react';
import useStyles from './styles';
import { Formik } from 'formik';
import { CreateRedirect as CreateSchema } from '../../schemas/redirect';
import { CREATEREDIRECT } from './graphQL';
import { ApolloError, useMutation } from '@apollo/client';
import { notifySuccess, notifyError } from '../../utilities/notify';
import SiteField from '../common/SiteField/SiteField';
import { Button } from '../common/Button/Button';
import { Select } from '../common/Select/Select';
import Loader from '../common/Loader/Loader';
import { OutlinedInput } from '@mui/material';
import { useNavigate } from 'react-router';
import { Close } from '@mui/icons-material';
import { RedirectProps } from './types';

const CreateRedirect: FC<RedirectProps> = ({ handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [createRedirect] = useMutation(CREATEREDIRECT);
  const [loading, setLoading] = useState(false);
  const types: string[] = ['Permanent', 'Temporary'];

  const handleSubmit = async (variables: {
    [key: string]: string | boolean;
  }) => {
    setLoading(true);
    await createRedirect({ variables })
      .then(() => {
        notifySuccess('Redirect Created');
        navigate('/cms/redirects');
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.map(({ extensions, message }) => {
          if ('input.source' in extensions?.validation) {
            return notifyError(extensions?.validation['input.source'][0]);
          } else {
            return notifyError(message);
          }
        });
      })
      .finally(() => setLoading(false));
  };

  const handleFormikSubmit = (values: { [key: string]: string | boolean }) => {
    const newValues = { ...values };
    if (newValues.type === 'Permanent') {
      delete newValues?.time;
      delete newValues?.time_number;
    } else {
      if (newValues.time_number === '') {
        notifyError('time is required!');
        return;
      }
      newValues.time = String(newValues.time_number) + String(newValues.time);
      delete newValues.time_number;
    }
    newValues.type = newValues.type === 'Permanent' ? true : false;
    handleSubmit(newValues);
  };

  const timeOptions = {
    Minutes: 'm',
    Hour: 'h',
    Day: 'd',
    Month: 'n',
    Year: 'y',
  };

  return (
    <div className={classes.content}>
      <Formik
        initialValues={{
          source: '',
          target: '',
          type: 'Permanent',
          time: 'm',
          site: 'vt',
          time_number: '',
        }}
        onSubmit={handleFormikSubmit}
        validateOnChange={false}
        validationSchema={CreateSchema}
      >
        {({ handleChange, handleSubmit, errors, values }) => (
          <>
            {
              <form className={classes.content__form} onSubmit={handleSubmit}>
                <div className={classes.eventTitleParent}>
                  <p className={classes.event__title}>Create URL Redirect</p>
                  <Close className={classes.closeIcon} onClick={handleClose} />
                </div>
                <div className={classes.content__form_section}>
                  <label className={classes.content__form_label}>
                    Source URL&nbsp;
                    <span className={classes.content__form_label_required}>
                      &#42;
                    </span>
                  </label>
                  <SiteField
                    edit={false}
                    selectValue={values.site}
                    onSelect={handleChange('site')}
                    onChange={handleChange('source')}
                    name="source"
                    value={values.source}
                  />
                  {errors.source && (
                    <p className={classes.content__form_error}>
                      {errors.source}
                    </p>
                  )}
                </div>
                <div className={classes.content__form_section}>
                  <label className={classes.content__form_label}>
                    Target URL
                    <span className={classes.content__form_sublabel}>
                      If this field is empty, the target URL will be set as the
                      home page
                    </span>
                  </label>
                  <SiteField
                    edit={false}
                    selectValue={values.site}
                    onSelect={handleChange('site')}
                    onChange={handleChange('target')}
                    name="target"
                    value={values.target}
                  />
                  {errors.target && (
                    <p className={classes.content__form_error}>
                      {errors.target}
                    </p>
                  )}
                </div>
                <div className={classes.select_section}>
                  <div className={classes.content__form_section_select}>
                    <label className={classes.content__form_label}>
                      Type&nbsp;
                      <span className={classes.content__form_label_required}>
                        &#42;
                      </span>
                    </label>
                    <div className={classes.selectField}>
                      <Select
                        options={types}
                        onChange={handleChange('type')}
                        value={values.type}
                      />
                    </div>
                  </div>
                  {values.type === 'Temporary' ? (
                    <div className={classes.content__form_section_select}>
                      <label className={classes.content__form_label}>
                        Time
                      </label>
                      <div className={classes.input_parent}>
                        <OutlinedInput
                          name=""
                          onChange={handleChange('time_number')}
                          type="number"
                          className={classes.input}
                        />
                        <div className="select">
                          <Select
                            name="time"
                            options={timeOptions}
                            onChange={handleChange}
                            append={true}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={classes.content__form_actions}>
                  <div className={classes.btn}>
                    <Button type="submit" title="Create Redirect" />
                    <Loader display={loading} />
                  </div>
                </div>
              </form>
            }
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateRedirect;
