import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import Popover from '@mui/material/Popover';
import imagePlaceholder from '../../../assets/imgs/Solid_gray.png';
import TailwindWrapper from 'components/common/Tailwind/TailwindWrapper';

export default function OngoingAnalysisButton() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    // @ts-ignore
    <TailwindWrapper>
      <div className="mr-[20px]">
        <button
          aria-describedby="simple-popover"
          style={{ border: '1px solid #dededf' }}
          className="text-[#344054] bg-[#f9fafb] rounded-[4px] px-[40px] py-[10px]"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div className="flex items-center justify-between gap-[10px]">
            <div>
              <p className="text-[12px] text-[#434343] m-0 whitespace-nowrap">
                Ongoing Analysis
              </p>

              <div className="mt-[4px] h-[3px] rounded-full w-full bg-[#EAECF0] overflow-hidden">
                <div className="h-full w-[50%] bg-[#333333]" />
              </div>
            </div>

            <div>
              <Icon
                width={20}
                height={20}
                color="#333333"
                icon="ic:sharp-keyboard-arrow-down"
                className={`w-[20px] h-[20px] transition-transform duration-300 ${
                  open ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </div>
          </div>
        </button>
      </div>

      <Popover
        open={open}
        id={popoverId}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e3e3e3',
            borderRadius: '3px',
            padding: '10px',
            width: '20rem',
          },
        }}
      >
        <div>
          <p className="text-sm">Ongoing Analysis</p>
        </div>

        <div className="space-y-[20px] mt-3">
          {[1, 2, 3, 4].map((_num, index) => (
            <OngoingAnalysisCard key={index} index={index + 1} />
          ))}
        </div>
      </Popover>
    </TailwindWrapper>
  );
}

interface OngoingAnalysisCardProps {
  index: number;
}

function OngoingAnalysisCard({ index }: OngoingAnalysisCardProps) {
  return (
    <div className="pb-[10px]" style={{ borderBottom: '2px solid #EAECF0' }}>
      <div className="flex items-start justify-between gap-[10px]">
        <div className="flex items-start gap-[10px]">
          <p className="text-[12px]">{index}</p>

          <div>
            <img
              width={40}
              height={40}
              alt="post image"
              src={imagePlaceholder}
              className="rounded-[4px]"
            />
          </div>
        </div>

        <div className="w-full">
          <div>
            <p className="text-[12px] truncate">Content curating app</p>

            <div className="grid grid-cols-[1fr_auto] gap-[10px] items-center mt-[5px]">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                <p className="text-[12px] font-light truncate">
                  Description of diuhd idchi sdiuhiiu disdshiiudc isdh dihi diu
                </p>
              </div>

              <div className="flex items-center">
                <p className="text-[12px] mr-[5px] text-[#333333] whitespace-nowrap">
                  Step 1 <span className="text-[#D7D7D7]">of 2</span>
                </p>
                <div>
                  <Icon icon="gg:check-o" color="#D7D7D7" width={18} />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[6px] h-[3px] rounded-full w-full bg-[#EAECF0] overflow-hidden">
            <div className="h-full w-[50%] bg-[#333333]"></div>
          </div>

          <div className="mt-[5px] text-right">
            <p className="text-[11px]">Time remaining: 20mins</p>
          </div>
        </div>
      </div>
    </div>
  );
}
