import * as React from 'react';
import Radio from '@mui/material/Radio';
import useStyles from './styles';
import clx from 'classnames';

interface RadioButtonProps {
  checked: boolean;
  text: string;
  handleClick: () => void;
  className?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  text,
  handleClick,
  className,
}) => {
  const classes = useStyles();
  return (
    <div className={clx(classes.root, className)}>
      <Radio
        onClick={() => {
          handleClick();
        }}
        checked={checked}
      />
      <p>{text}</p>
    </div>
  );
};

export default RadioButton;
