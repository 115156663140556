export const poDetails = [
  { field: 'Timestamp', key: 'created_at' },
  { field: 'Purchase date', key: 'date' },
  { field: 'SLT approval (1st approval)', key: 'acc_approved' },
  { field: 'PO authoriser', key: 'poAuthoriser' },
  { field: 'Invoice value', key: 'price' },
  { field: 'CFO/COO approval (2nd approval)', key: 'approved' },
];

export const vendorDetails = [
  { field: 'Name', key: 'vendorName' },
  { field: 'Contact', key: 'vendorContactDetails' },
];

export const statusNumbers = {
  received: {
    value: 0,
    text: 'Received',
    summary: 'PO received.',
  },
  processing: {
    value: 1,
    text: 'Processing',
    summary: 'PO is being processed.',
  },
  approved: {
    value: 2,
    text: 'Approved',
    summary: 'PO approved',
  },
  cancelled: {
    value: 3,
    text: 'Cancelled',
    summary: 'PO cancelled.',
  },
};

export const requestorDetails = [
  { field: 'Name', key: 'requestorName' },
  { field: 'Job title', key: 'requestorJobTitle' },
  { field: 'Department', key: 'requestorDepartment' },
  { field: 'Email', key: 'requestorEmail' },
];
