import Container from './components/layout/Container';
import CampaignStatCard from './components/campaign/CampaignStatCard';
import CampaignOverviewTable from './components/campaign/CampaignOverviewTable';
import { useQuery } from '@apollo/client';
import { caClient } from './client';
import { ModalProvider } from './components/common/ModalManager';
import { GET_CAMPAIGNS_COUNT } from 'graphql/jcmat/campaigns';
import { useState } from 'react';

export type CampaignModalStep = 1 | 2 | 3 | 4;

export function CommentAnalysis() {
  const { data, loading } = useQuery(GET_CAMPAIGNS_COUNT, {
    fetchPolicy: 'network-only',
    client: caClient,
  });
  const [filter, setFilter] = useState<string>('all');

  const now = new Date();
  const lastMonth = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  const lastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );

  const filteredCampaigns =
    data?.campaigns?.data?.filter((campaign: any) => {
      const createdAt = new Date(campaign.createdAt);

      if (filter === 'lastMonth') return createdAt >= lastMonth;
      if (filter === 'lastWeek') return createdAt >= lastWeek;

      return true;
    }) || [];

  const totalPages = filteredCampaigns.length;

  const totalPosts = data?.campaigns.data?.reduce((sum: number, page: any) => {
    return sum + (page?.postsCount || 0);
  }, 0);

  return (
    <Container>
      <ModalProvider>
        <div className="space-y-8">
          <div className="flex lg:justify-between lg:items-center flex-col lg:flex-row gap-8 max-w-full">
            <CampaignStatCard
              stock="decline"
              assetColor="blue"
              total={loading ? 0 : totalPages}
              setFilter={setFilter}
            />

            <CampaignStatCard
              stock="incline"
              assetColor="green"
              total={loading ? 0 : totalPosts}
              setFilter={setFilter}
            />
          </div>

          <div className="max-w-full">
            <CampaignOverviewTable />
          </div>
        </div>
      </ModalProvider>
    </Container>
  );
}
