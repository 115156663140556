import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    boxSizing: 'border-box',
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.widgetBorder}`,
    padding: theme.spacing(2),
    // marginBottom: theme.spacing(3),
    position: 'relative',
    marginTop: theme.spacing(2.5),
    backgroundColor: theme.design.colors.purple,
  },
  divider: {
    border: `0.5px solid ${theme.design.colors.grey900}`,
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    fontFamily: theme.design.font.title,
    color: theme.design.colors.titleColor,
    fontSize: '15px',
  },
  title_icon: {
    color: theme.design.colors.titleColor,
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      fontSize: '18px',
      color: theme.design.colors.greyDark,
      paddingLeft: theme.spacing(1),
    },
    '& img': {},
  },
  listContainer: {
    width: '100%',
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.design.colors.whisper}`,
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    '& p': {
      color: theme.design.colors.titleColor,
    },
  },
  btn: {
    '&.MuiButton-root': {
      padding: theme.spacing(0.5, 1.5),
      marginTop: theme.spacing(2),
      fontSize: theme.typography.fontSize - 2,
    },
  },
  list_row: {
    paddingLeft: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& p': {
      marginTop: theme.spacing(0.5),
      fontSize: '14px',
      color: theme.design.colors.titleColor,
    },
  },
  list_image: {
    width: 83,
    height: 83,
    borderRadius: '50%',
    overflow: 'hidden',
    border: `2px solid ${theme.design.colors.skyBlue}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar_image: {
    height: '100%',
  },
  titleParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  birthdayDetails: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  name: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: `${theme.design.colors.black}!important`,
    alignSelf: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    '& span': {
      display: 'block',
      fontSize: theme.typography.fontSize - 2,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  navWidget: {
    top: 18,
  },
  dob: {
    fontSize: theme.typography.fontSize - 1,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5, 2),
    background: theme.design.colors.blue300,
    borderRadius: 12,
    textAlign: 'center',
    width: 'max-content',
    margin: 'auto',
    marginTop: '-5px',
    color: theme.design.colors.white,
  },
  navigation: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& p': {
      textDecoration: 'underline',
      fontSize: '18px',
    },
  },
  scroll: {
    height: '80%',
    marginTop: theme.spacing(1),
    overflowY: 'hidden',
    background: theme.design.colors.white,
    borderRadius: 16,
    border: `6px solid ${theme.design.colors.blue300}`,
  },
  loader: {
    margin: theme.spacing(6),
    color: theme.design.colors.greyDark,
  },
  loader_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '86%',
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 4,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography?.fontWeightMedium,
    fontFamily: 'Feature',
  },
}));

export default useStyles;
