import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useFournineRevolutionStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    background: theme.design.colors.white,
    padding: theme.spacing(4),
  },
  publishBtn: {
    position: 'fixed',
    top: '14vh',
    right: '4vw',

    '& button': {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: theme.typography.fontWeightMedium,
      fontFamily: theme.design.font.universal,
    },
  },
  add: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: 24,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: -9999,
    transition: 'all 0.5s ease-in',
    background: theme.design.colors.fullScreenBackground,
  },
  overlayActive: {
    opacity: 1,
    zIndex: 5,
  },
}));
