import { gql } from '@apollo/client';

export const EDITREDIRECT = gql`
  mutation updateRedirect(
    $id: ID!
    $source: String!
    $target: String!
    $type: Boolean!
    $time: String
  ) {
    updateRedirect(
      id: $id
      source: $source
      target: $target
      type: $type
      time: $time
    ) {
      source
      target
      type
      expires_at
    }
  }
`;

export const GETREDIRECT = gql`
  query getRedirect($id: ID!) {
    redirect(id: $id) {
      id
      source
      site
      target
      type
      expires_at
    }
  }
`;
