export const weather: { [key: string]: string | boolean }[] = [
  {
    name: 'Partly cloudy',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/part-sun.svg',
    day: true,
  },
  {
    name: 'Sunny',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/sunny.svg',
    day: true,
  },
  {
    name: 'Clear',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/moon_moonlight_night_icon.svg',
    day: false,
  },
  {
    name: 'Cloudy',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/cloud_clouds_cloudy_icon.svg',
    day: true,
  },
  {
    name: 'Cloudy',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/cloud_clouds_cloudy_icon.svg',
    day: false,
  },
  {
    name: 'Heavy rain at times',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/clouds_cloudy_rain_sunny.svg',
    day: true,
  },
  {
    name: 'Heavy rain at times',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/clouds_moon_night_rain_icon.svg',
    day: false,
  },
  {
    name: 'Heavy snow',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/clouds_snow_winter_icon.svg',
    day: true,
  },
  {
    name: 'Heavy snow',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/moon_night_snow_icon.svg',
    day: false,
  },
  {
    name: 'Light rain shower',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/rain_shower_storm_icon.svg',
    day: true,
  },
  {
    name: 'Light rain shower',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/clouds_moon_night_rain_icon.svg',
    day: false,
  },
  {
    name: 'Patchy light rain with thunder',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/day_clouds_rain_storm_thunder_icon.svg',
    day: true,
  },
  {
    name: 'Patchy light rain with thunder',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/night_rain_storm_icon.svg',
    day: false,
  },
  {
    name: 'Patchy rain possible',
    url: 'https://jungle-portal.s3.us-west-1.amazonaws.com/weather/clouds_cloudy_rain_sunny.svg',
    day: true,
  },
  {
    name: 'Patchy rain possible',
    url: 'https://jungle-portal.s3-us-west-1.amazonaws.com/weather/clouds_moon_night_rain_icon.svg',
    day: false,
  },
];
