import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($description: String, $name: String!) {
    createCampaign(description: $description, name: $name) {
      id
      name
      uuid
      updatedAt
      description
      deletedAt
      createdAt
    }
  }
`;
