import React from 'react';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import { FiMinusCircle } from 'react-icons/fi';
import { FcCheckmark } from 'react-icons/fc';
import { TiTimes } from 'react-icons/ti';
import { ClassNameMap } from '@mui/material';

export const schema = (
  classes: ClassNameMap<string>,
  publish: (url: string) => void
): SchemaProps[] => {
  return [
    {
      label: 'Id',
      func: ({ item }) => get(item, 'id'),
    },
    {
      label: 'Title',
      func: ({ item }) => get(item, 'title'),
    },
    {
      label: 'Published',
      func: ({ item }) => {
        const status = get(item, 'instant_article_status').status;
        return (
          <div style={{ textAlign: 'center' }}>
            {!status ? (
              <FiMinusCircle color="grey" style={{ fontSize: '18px' }} />
            ) : status === 'Published' ? (
              <FcCheckmark style={{ fontSize: '18px' }} />
            ) : (
              <TiTimes color="red" style={{ fontSize: 18 }} />
            )}
          </div>
        );
      },
    },
    {
      label: 'Actions',
      func: ({ item }) => (
        <p
          className={classes.action}
          onClick={() => {
            publish(get(item, 'url'));
          }}
        >
          Publish
        </p>
      ),
    },
  ];
};
