import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useCategoryStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  categorySubsParent: {},
  categorySubHeading: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.grey44,
    marginBottom: theme.spacing(1.5),
  },
  categorySubs: {
    display: 'flex',
    alignItems: 'center',
  },
  categorySub: {
    display: 'flex',
    gap: theme.spacing(1),
    borderRight: `1px solid ${theme.design.colors.primary700}`,
    margin: theme.spacing(0, 0, 0, 0.5),
    padding: theme.spacing(0, 1, 0, 0.5),

    '&:first-of-type': {
      margin: 0,
      padding: theme.spacing(0, 1, 0, 0),
    },

    '&:last-of-type': {
      borderRightColor: 'transparent',
    },

    '& p': {
      fontSize: theme.typography.fontSize,
      color: theme.design.colors.grey100,
    },
  },
  categoryCheckBox: {
    padding: 0,
    color: 'rgba(0,0,0,0.7)',

    '&.Mui-checked': {
      color: `${theme.design.colors.primary100} !important`,
    },
  },
  category: {
    marginBottom: theme.spacing(2),
  },
  categoryIndicator: {
    position: 'relative',
    top: -3,
    left: 3,
    width: '41px',
    height: '30px',
    border: `1px solid ${theme.design.colors.primary700}`,
    borderRight: 'transparent',
    borderTop: 'transparent',
  },
  categoryFormSubs: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  categorySubTag: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    height: 23,
    fontSize: theme.typography.fontSize,
  },
  primaryCategory: {
    paddingTop: theme.spacing(1),
  },
  primaryCategoryContainer: {
    borderTop: `1px solid ${theme.design.colors.primary700}`,
    marginTop: theme.spacing(3),
  },
}));
