import { gql } from '@apollo/client';

export const REQUESTFEATURE = gql`
  mutation createFeature(
    $name: String!
    $reason: String!
    $description: String!
  ) {
    requestFeature(name: $name, reason: $reason, description: $description) {
      message
    }
  }
`;
