import React, { useState, useEffect, useCallback } from 'react';
import { FETCHSOCIALINTEGRATIONS, UPDATESOCIALPOSTS } from './graphQL';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import useStyles from '../ViewRedirect/styles';
import { CircularProgress } from '@mui/material';
import Table from '../common/Table/Table';
import { SchemaProps } from '../common/Table/types';
import { get } from 'lodash';
import SocialIntegrationType from '../../types/SocialIntegrationType';
import { determineSiteImage } from '../../utilities/image';
import { FcCheckmark } from 'react-icons/fc';
import { TiTimes } from 'react-icons/ti';
import { Button } from '../common/Button/Button';
import { notifyError, notifySuccess } from '../../utilities/notify';

const ViewSocialIntegrations = () => {
  const { data, error, loading } = useQuery(FETCHSOCIALINTEGRATIONS, {
    fetchPolicy: 'network-only',
  });
  const [updateSocialPostsMutation] = useMutation(UPDATESOCIALPOSTS);
  const [updating, setUpdating] = useState(false);
  const [integrations, setIntegrations] = useState<SocialIntegrationType[]>([]);
  const [displayedIntegrations, setDisplayedIntegrations] = useState<
    SocialIntegrationType[]
  >([]);
  const classes = useStyles();
  const instagramAccessUrl = import.meta.env.VITE.REST_URL + 'instagram/access';

  useEffect(() => {
    if (data) {
      const integrations: SocialIntegrationType[] = data.connections.data;
      setIntegrations(integrations);
      setDisplayedIntegrations(integrations);
    }
  }, [data, error]);

  const updateSocialPosts = useCallback(async () => {
    setUpdating(true);
    updateSocialPostsMutation()
      .then((response) => {
        notifySuccess('Posts Updated Successfully');
        const integrations = response.data.updateSocialPosts;
        setIntegrations(integrations);
        setDisplayedIntegrations(integrations);
      })
      .catch((error: ApolloError) => {
        if (error.networkError) {
          notifyError('Failed to connect to API');
          return;
        }
        error.graphQLErrors.map((gqlErrors) => {
          notifyError(gqlErrors?.message);
          return gqlErrors;
        });
      })
      .finally(() => {
        setUpdating(false);
      });
  }, [integrations, displayedIntegrations]);

  const schema: SchemaProps[] = [
    {
      label: 'Platform',
      func: ({ item }) =>
        get(item, 'platform').charAt(0).toUpperCase() +
        get(item, 'platform').slice(1).toLowerCase(),
    },
    {
      label: 'Site',
      func: ({ item }) => (
        <div>
          <img
            src={determineSiteImage({ name: get(item, 'name') })}
            width="40px"
            height="40px"
            alt={get(item, 'name')}
          />
        </div>
      ),
    },
    {
      label: 'Status',
      func: ({ item }) => {
        const status = get(item, 'status');
        if (!status) {
          return 'Never been updated';
        }
        return status.status === 200 ? (
          <FcCheckmark style={{ fontSize: '18px' }} />
        ) : (
          <TiTimes style={{ fontSize: '18px', color: 'red' }} />
        );
      },
    },
    {
      label: 'Date',
      func: ({ item }) => {
        const status = get(item, 'status');
        if (!status) {
          return 'Never been updated';
        }
        const updated_at = status.created_at.split(' ');
        let time = updated_at[1];
        const timeArray = time.split(':');
        const timeSuffix = timeArray[0] >= 12 ? 'PM' : 'AM';
        timeArray[0] = timeArray[0] > 12 ? timeArray[0] - 12 : timeArray[0];
        time = timeArray.slice(0, 2).join(':');
        return `Last Updated ${updated_at[0].replace(
          /-/g,
          '/'
        )} at ${time} ${timeSuffix}`;
      },
    },
  ];

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Social Integrations</p>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative', marginRight: '16px' }}>
            <a
              className={classes.anchor}
              href={instagramAccessUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button title="Add Instagram" />
            </a>
          </div>
          <div style={{ position: 'relative' }}>
            <Button title="Update Posts" onClick={updateSocialPosts} />
            <div
              className={`${classes.loader} ${
                updating && classes.loader_active
              }`}
            >
              <CircularProgress size={18} color="inherit" />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.main}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <>
            <Table
              schema={schema}
              collection={displayedIntegrations}
              pagination={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ViewSocialIntegrations;
