import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  polls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  polls__content: {},
  polls__text: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '0 0 1.25rem 0',
    color: 'rgba(0,0,0,0.8)',
  },
  polls__table: {
    width: '100%',
  },
  polls__actions: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.5%',
  },
  poll: {
    width: '100%',
    height: 'fit-content',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  loading: {
    height: '30vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.design.colors.black,
    background: theme.design.colors.white,
  },
  title_parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  box: {
    width: 600,
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  actions: {
    display: 'none',
    gap: theme.spacing(0.5),
  },
  actionActive: {
    display: 'flex',
    height: 'auto',
    transition: 'height 1s ease-out',
  },
  titleParent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  edit: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.greyDark,
    cursor: 'pointer',
    '&:hover': {
      color: theme.design.colors.black,
    },
  },
  editBorder: {
    borderLeft: `1px solid ${theme.design.colors.deepGrey}`,
    paddingLeft: theme.spacing(0.5),
  },
  itemTitle: {
    fontWeight: theme.typography?.fontWeightMedium,
    color: theme.design.colors.grey100,
  },
}));

export default useStyles;
