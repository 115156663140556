import { makeStyles } from '@mui/styles';
import theme from '../../../contexts/theme';
import { Theme } from '@mui/material';

export interface StyleProps {
  isActive: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  container: {
    padding: theme.spacing(2, 2, 2, 2.5),
    background: ({ isActive }) => (isActive ? '#FFFFFF' : '#FBFBFB'),
    fontSize: theme.typography.fontSize,
    fontWeight: ({ isActive }) => (isActive ? 600 : 400),
    boxShadow: ({ isActive }) =>
      isActive ? '0px 3px 20px rgba(0, 0, 0, 0.1)' : '',
    lineHeight: '22px',
    color: '#1A1B1F',
    width: '100%',
    borderRight: ({ isActive }) =>
      isActive ? 'none' : `solid 1px ${theme.design.colors.borderGrey}`,
    cursor: 'pointer',
    marginBottom: '4px',
  },
}));

export default useStyles;
