export const removeSpecialCharacters = (slug: string = '') => {
  const specialsCharacters = /["~!@#$%^&*()_+{}[\]\\|,.//?;':"]/g as RegExp;
  const parsedSlug = slug
    .trim()
    .replace(specialsCharacters, '')
    .replace(' ', '-')
    .replace(/[^\w\s-]/g, '');

  return parsedSlug?.startsWith('/') ? parsedSlug : '/' + parsedSlug;
};
