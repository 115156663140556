import React from 'react';
import useStyles from './styles';
import { SideTabsTypes } from './types';

const SideTabs: React.FC<SideTabsTypes> = ({
  title,
  isActive,
  handleClick,
}) => {
  const classes = useStyles({ isActive });
  return (
    <div className={classes.root}>
      <div onClick={() => handleClick?.()} className={classes.container}>
        {title}
      </div>
    </div>
  );
};

export default SideTabs;
