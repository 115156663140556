import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    background: theme.design.colors.cyan,
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.widgetBorder}`,
    padding: theme.spacing(2),
    position: 'relative',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: theme.design.font.title,
    color: theme.design.colors.titleColor,
    fontSize: '16px',
  },
  title_icon: {
    color: theme.design.colors.titleColor,
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(1),
  },
  info: {
    marginTop: theme.spacing(2),
    borderRadius: 10,
    padding: theme.spacing(2, 0),
    background: theme.design.colors.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  navWidget: {
    top: 18,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 2,
  },
  image: {
    marginTop: theme.spacing(0.5),
    width: 73,
    height: 73,
    borderRadius: '50%',
    overflow: 'hidden',
    alignSelf: 'center',
    border: `2px solid ${theme.design.colors.black}`,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '29px',
    color: theme.design.colors.darkCharcoal,
    alignSelf: 'center',
    textAlign: 'center',
  },
  job: {
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.greyDark,
    alignSelf: 'center',
    textAlign: 'center',
  },
  infoDetails: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    maxWidth: '90%',
  },
  loader: {
    margin: theme.spacing(6),
    color: theme.design.colors.greyDark,
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 4,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: 'Feature',
    color: theme.design.colors.grey100,
  },
  avatarImage: {
    height: '100%',
  },
}));

export default useStyles;
