import { Button as MuiButton, CircularProgress } from '@mui/material';
import React from 'react';
import { useButtonStyles } from './styles';
import { ButtonProps } from './types';
import clx from 'classnames';

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  title,
  startIcon,
  loading,
  disabled,
  variant,
  ...props
}) => {
  const classes = useButtonStyles();

  return (
    <MuiButton
      disabled={loading ? loading : disabled}
      className={clx(classes.root, className)}
      startIcon={
        loading ? (
          <CircularProgress
            className={classes.icon}
            color={'primary'}
            size="16px"
          />
        ) : (
          startIcon
        )
      }
      variant={variant ?? 'contained'}
      {...props}
    >
      {children ?? title}
    </MuiButton>
  );
};
