import { Button } from 'components/common/Button/Button';
import success from '../common/assets/success.svg';

interface Props {
  hideModal: () => void;
}

const SuccessfulCampaign = ({ hideModal }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center my-4">
      <img src={success} />
      <h1 className="text-xl font-medium my-4">
        Campaign Created Successfully
      </h1>
      <p className="text-lg font-light text-center mb-12 text-[#979797]">
        Your campaign has been created successfully, and posts have been added.
        Start analyzing their performance now!
      </p>
      <Button onClick={hideModal} className="!rounded-full w-full !h-[56px]">
        Start Comment Analysis
      </Button>
    </div>
  );
};

export default SuccessfulCampaign;
