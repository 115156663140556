import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    display: 'flex',
  },
  link: {
    color: theme.design.colors.grey1000,
    textDecoration: 'none',
    fontSize: theme.typography.fontSize,
    fontFamily: theme.design.font.universe,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    '&.active': {
      color: theme.design.colors.black,
    },
    '& svg': {
      padding: theme.spacing(0.5),
    },
  },
}));
