import React from 'react';
import { Checkbox, InputLabel } from '@mui/material';
import { useCheckboxStyles } from './styles';
import { CheckboxProps } from './types';

const CheckBox: React.FC<CheckboxProps> = ({
  checked,
  label,
  handleChange,
  loading = false,
  classNames,
  name,
}) => {
  const classes = useCheckboxStyles();
  return (
    <>
      {label && (
        <InputLabel shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <Checkbox
        disabled={loading}
        checked={checked}
        onChange={handleChange}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
        classes={classNames}
        name={name}
      />
    </>
  );
};

export default CheckBox;
