import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useEmbedVideoStyles = makeStyles((theme: Theme) => ({
  embedTextInput: {
    background: theme.design.colors.grey98,
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize,
    width: 'calc(100% - 24px)',
  },
  embedDialog: {
    position: 'relative',
    background: theme.design.colors.white,
    padding: theme.spacing(3),
    borderRadius: 4,
    boxShadow: `0px 3px 6px ${theme.design.colors.black99}`,
    margin: '0 auto',
    width: '46%',
    maxWidth: 680,
    zIndex: 10,
  },
  embedDialogHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  embedDialogTitle: {
    color: theme.design.colors.black200,
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
  },
  embedDialogIcon: {
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize + 4,
    cursor: 'pointer',
  },
  embedDialogButtons: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },
  embedDialogButtonPrimary: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize + 2,
    padding: theme.spacing(1, 2),
    textTransform: 'unset',
    fontFamily: theme.design.font.openSans,
    width: 'unset',
    height: 'unset',
    background: theme.design.colors.black,
  },
  embedDialogButtonSecondary: {
    fontWeight: theme.typography.fontWeightMedium,
    background: theme.design.colors.waz_white,
    border: `1px solid ${theme.design.colors.primary600}`,
    fontSize: theme.typography.fontSize + 2,
    padding: theme.spacing(1, 2),
    fontFamily: theme.design.font.openSans,
    width: 'unset',
    height: 'unset',
    color: theme.design.colors.grey100,

    '&:hover': {
      background: theme.design.colors.waz_white,
    },

    '&:focus': {
      background: theme.design.colors.waz_white,
    },

    '&:hover:focus': {
      background: theme.design.colors.waz_white,
    },
  },
}));
