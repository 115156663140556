import { Icon } from '@iconify/react';
import { Link, useParams } from 'react-router-dom';

type SentimentReportCardProps = {
  positive: number;
  negative: number;
  neutral: number;
};

export default ({ positive, negative, neutral }: SentimentReportCardProps) => {
  const { campaignId } = useParams();

  return (
    <div
      style={{ border: '2px solid #EAECF0' }}
      className="w-full shadow-md rounded-[12px]"
    >
      <div
        style={{ borderBottom: '2px solid #EAECF0' }}
        className="p-5 flex justify-between lg:items-center gap-2 flex-col lg:flex-row"
      >
        <p className="text-lg font-[500]">Sentiment Report</p>

        {/* @ts-ignore */}
        <Link to={`sentiment-report`}>
          <p className="text-[#257CFF] text-sm">View detailed report</p>
        </Link>
      </div>

      <div className="space-y-4 pt-2 pb-5">
        <div className="flex px-5 items-start">
          <div>
            <div className="w-24" />
          </div>

          <div className="w-full">
            <div className="w-full text-[#707074] grid grid-cols-5">
              <p>0</p>
              <p>20</p>
              <p>40</p>
              <p>60</p>

              <div className="max-w-full flex justify-between items-start">
                <p>80</p>

                <p>100</p>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <ReactionBar percentage={positive} type="positive" />
          <ReactionBar percentage={neutral} type="neutral" />
          <ReactionBar percentage={negative} type="bad" />
        </div>
      </div>
    </div>
  );
};

interface ReactionBarProps {
  percentage: number;
  type: 'positive' | 'neutral' | 'bad';
}

function ReactionBar({ type, percentage }: ReactionBarProps) {
  const reacttionText = {
    bad: 'Bad',
    positive: 'Positive',
    neutral: 'Neutral',
  }[type];

  const color = {
    bad: '#FF2915',
    positive: '#14B90B',
    neutral: '#DEA30D',
  }[type];

  const icon = {
    positive: 'ph:smiley-bold',
    bad: 'gravity-ui:face-sad',
    neutral: 'gravity-ui:face-neutral',
  }[type];

  return (
    <div className="px-5">
      <div className="flex items-center">
        <div>
          <div className="w-24">
            <div className="flex max-w-full pr-4 justify-end space-x-1 items-center">
              <Icon color={color} icon={icon} width={18} height={18} />

              <p className="text-[#707073]">{reacttionText}</p>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center space-x-2">
          <div
            style={{
              width: '100%',
              maxWidth: `${percentage}%`,
              backgroundColor: color,
            }}
            className="h-12 rounded-r-full"
          />

          <p style={{ color }}>{percentage}%</p>
        </div>
      </div>
    </div>
  );
}
