import { TextInput } from '@wazobia-tech/wazcom/dist/io/TextInput';
import DraggableCard from 'components/common/DraggableCard';
import { AiOutlineMinus } from 'react-icons/ai';
import { useMeasurementStyles } from '../CreateMeasurement/styles';
import { useStylesRecipe } from '../../styles';
import { FC, useCallback } from 'react';
import update from 'immutability-helper';

export const MeasurementSubList: FC<{
  form: any;
  listindex: any;
  setFormData: any;
  handleChange: (e: any, listindex: number, number: number) => void;
  handleInputDecrement: (index: number) => void;
}> = ({ form, listindex, setFormData, handleChange, handleInputDecrement }) => {
  const classes = { ...useMeasurementStyles(), ...useStylesRecipe() };
  const current_ing_list = Boolean(form.ingredients_list[listindex].list)
    ? form.ingredients_list[listindex].list
    : [];

  const moveCard = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      listIndex: number,
      parentIndex?: number
    ) => {
      const currentList = form.ingredients_list[parentIndex ?? 0].list;
      const newCurrentList = update(currentList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, currentList[dragIndex]],
        ],
      });

      setFormData({
        ...form,
        ingredients_list: form.ingredients_list.map((item: any, idx: number) =>
          idx === parentIndex ? { ...item, list: newCurrentList } : item
        ),
      });
    },
    [form]
  );

  return (
    <>
      {current_ing_list.map(
        (detail: { amount: string; ingredient: string }, index: number) => {
          return (
            <DraggableCard
              id={`child${index}`}
              index={index}
              level={1}
              moveCard={moveCard}
              parentIndex={listindex}
            >
              <tr key={index} className={classes.displayGrid}>
                <td className={classes.incrementIcon}>{index + 1}</td>
                <td className={classes.padding}>
                  <TextInput
                    label=""
                    name="amount"
                    placeholder="400g"
                    value={current_ing_list[index].amount}
                    onChange={(e) => handleChange(e, listindex, index)}
                    classNames={{ input: classes.embedTextInput }}
                  />
                </td>
                <td className={classes.padding}>
                  <TextInput
                    label=""
                    name="ingredient"
                    placeholder="Chicken"
                    value={current_ing_list[index].ingredient}
                    onChange={(e) => handleChange(e, listindex, index)}
                    classNames={{ input: classes.embedTextInput }}
                  />
                </td>
                <td className={classes.incrementIcon}>
                  {index !== 0 && (
                    <AiOutlineMinus
                      onClick={() => handleInputDecrement(index)}
                    />
                  )}
                </td>
              </tr>
            </DraggableCard>
          );
        }
      )}
    </>
  );
};
