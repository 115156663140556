import { createContext } from 'react';

interface AuthContextType {
  toggleSpinner: (spinner: boolean) => void;
  redirect: { [key: string]: string | number };
  editRedirect: (redirect: { [key: string]: string }) => void;
}

const toggleSpinner = () => {
  //
};
const editRedirect = (redirect: { [key: string]: string }) => redirect;

const AuthContext = createContext<AuthContextType>({
  toggleSpinner,
  redirect: {},
  editRedirect,
});

export default AuthContext;
