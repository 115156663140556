import { Login } from '@mui/icons-material';
import moment from 'moment';

export const vidData = [
  {
    name: 'VTC vid: Chloe Dillon 18 UGC/N/L',
    src: 'https://jungle-cpas.s3.us-west-1.amazonaws.com/8e4a32ac-6dfb-4665-81ea-62e0311df160.mp4',
  },
];

export const chartData = [
  {
    label: 'Series 1',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 62,
      },
      {
        primary: '2023-07-13 ',
        secondary: 75,
      },
      {
        primary: '2023-07-14 ',
        secondary: 97,
      },
      {
        primary: '2023-07-15 ',
        secondary: 47,
      },
      {
        primary: '2023-07-16 ',
        secondary: 1,
      },
      {
        primary: '2023-07-17 ',
        secondary: 14,
      },
      {
        primary: '2023-07-18 ',
        secondary: 6,
      },
      {
        primary: '2023-07-19 ',
        secondary: 13,
      },
      {
        primary: '2023-07-20 ',
        secondary: 8,
      },
      {
        primary: '2023-07-21 ',
        secondary: 58,
      },
    ],
  },
  {
    label: 'Series 2',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 34,
      },
      {
        primary: '2023-07-13 ',
        secondary: 4,
      },
      {
        primary: '2023-07-14 ',
        secondary: 21,
      },
      {
        primary: '2023-07-15 ',
        secondary: 65,
      },
      {
        primary: '2023-07-16 ',
        secondary: 99,
      },
      {
        primary: '2023-07-17 ',
        secondary: 52,
      },
      {
        primary: '2023-07-18 ',
        secondary: 67,
      },
      {
        primary: '2023-07-19 ',
        secondary: 93,
      },
      {
        primary: '2023-07-20 ',
        secondary: 79,
      },
      {
        primary: '2023-07-21 ',
        secondary: 28,
      },
    ],
  },
  {
    label: 'Series 3',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 67,
      },
      {
        primary: '2023-07-13 ',
        secondary: 35,
      },
      {
        primary: '2023-07-14 ',
        secondary: 59,
      },
      {
        primary: '2023-07-15 ',
        secondary: 31,
      },
      {
        primary: '2023-07-16 ',
        secondary: 31,
      },
      {
        primary: '2023-07-17 ',
        secondary: 54,
      },
      {
        primary: '2023-07-18 ',
        secondary: 74,
      },
      {
        primary: '2023-07-19 ',
        secondary: 61,
      },
      {
        primary: '2023-07-20 ',
        secondary: 21,
      },
      {
        primary: '2023-07-21 ',
        secondary: 96,
      },
    ],
  },
  {
    label: 'Series 4',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 88,
      },
      {
        primary: '2023-07-13 ',
        secondary: 61,
      },
      {
        primary: '2023-07-14 ',
        secondary: 67,
      },
      {
        primary: '2023-07-15 ',
        secondary: 37,
      },
      {
        primary: '2023-07-16 ',
        secondary: 57,
      },
      {
        primary: '2023-07-17 ',
        secondary: 1,
      },
      {
        primary: '2023-07-18 ',
        secondary: 39,
      },
      {
        primary: '2023-07-19 ',
        secondary: 87,
      },
      {
        primary: '2023-07-20 ',
        secondary: 31,
      },
      {
        primary: '2023-07-21 ',
        secondary: 57,
      },
    ],
  },
  {
    label: 'Series 5',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 33,
      },
      {
        primary: '2023-07-13 ',
        secondary: 54,
      },
      {
        primary: '2023-07-14 ',
        secondary: 15,
      },
      {
        primary: '2023-07-15 ',
        secondary: 36,
      },
      {
        primary: '2023-07-16 ',
        secondary: 82,
      },
      {
        primary: '2023-07-17 ',
        secondary: 96,
      },
      {
        primary: '2023-07-18 ',
        secondary: 73,
      },
      {
        primary: '2023-07-19 ',
        secondary: 12,
      },
      {
        primary: '2023-07-20 ',
        secondary: 64,
      },
      {
        primary: '2023-07-21 ',
        secondary: 87,
      },
    ],
  },
  {
    label: 'Series 6',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 15,
      },
      {
        primary: '2023-07-13 ',
        secondary: 84,
      },
      {
        primary: '2023-07-14 ',
        secondary: 96,
      },
      {
        primary: '2023-07-15 ',
        secondary: 72,
      },
      {
        primary: '2023-07-16 ',
        secondary: 83,
      },
      {
        primary: '2023-07-17 ',
        secondary: 92,
      },
      {
        primary: '2023-07-18 ',
        secondary: 41,
      },
      {
        primary: '2023-07-19 ',
        secondary: 4,
      },
      {
        primary: '2023-07-20 ',
        secondary: 96,
      },
      {
        primary: '2023-07-21 ',
        secondary: 99,
      },
    ],
  },
  {
    label: 'Series 7',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 74,
      },
      {
        primary: '2023-07-13 ',
        secondary: 33,
      },
      {
        primary: '2023-07-14 ',
        secondary: 95,
      },
      {
        primary: '2023-07-15 ',
        secondary: 21,
      },
      {
        primary: '2023-07-16 ',
        secondary: 100,
      },
      {
        primary: '2023-07-17 ',
        secondary: 29,
      },
      {
        primary: '2023-07-18 ',
        secondary: 23,
      },
      {
        primary: '2023-07-19 ',
        secondary: 9,
      },
      {
        primary: '2023-07-20 ',
        secondary: 4,
      },
      {
        primary: '2023-07-21 ',
        secondary: 59,
      },
    ],
  },
  {
    label: 'Series 8',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 71,
      },
      {
        primary: '2023-07-13 ',
        secondary: 53,
      },
      {
        primary: '2023-07-14 ',
        secondary: 87,
      },
      {
        primary: '2023-07-15 ',
        secondary: 40,
      },
      {
        primary: '2023-07-16 ',
        secondary: 65,
      },
      {
        primary: '2023-07-17 ',
        secondary: 47,
      },
      {
        primary: '2023-07-18 ',
        secondary: 56,
      },
      {
        primary: '2023-07-19 ',
        secondary: 52,
      },
      {
        primary: '2023-07-20 ',
        secondary: 43,
      },
      {
        primary: '2023-07-21 ',
        secondary: 79,
      },
    ],
  },
  {
    label: 'Series 9',
    data: [
      {
        primary: '2023-07-12 ',
        secondary: 27,
      },
      {
        primary: '2023-07-13 ',
        secondary: 20,
      },
      {
        primary: '2023-07-14 ',
        secondary: 89,
      },
      {
        primary: '2023-07-15 ',
        secondary: 68,
      },
      {
        primary: '2023-07-16 ',
        secondary: 2,
      },
      {
        primary: '2023-07-17 ',
        secondary: 44,
      },
      {
        primary: '2023-07-18 ',
        secondary: 86,
      },
      {
        primary: '2023-07-19 ',
        secondary: 70,
      },
      {
        primary: '2023-07-20 ',
        secondary: 79,
      },
      {
        primary: '2023-07-21 ',
        secondary: 0,
      },
    ],
  },
  {
    label: 'Series 10',
    data: [
      {
        primary: '2023-07-12',
        secondary: 55,
      },
      {
        primary: '2023-07-13 ',
        secondary: 38,
      },
      {
        primary: '2023-07-14 ',
        secondary: 89,
      },
      {
        primary: '2023-07-15 ',
        secondary: 13,
      },
      {
        primary: '2023-07-16 ',
        secondary: 2,
      },
      {
        primary: '2023-07-17 ',
        secondary: 30,
      },
      {
        primary: '2023-07-18',
        secondary: 95,
      },
      {
        primary: '2023-07-19 ',
        secondary: 97,
      },
      {
        primary: '2023-07-20 ',
        secondary: 72,
      },
      {
        primary: '2023-07-21 ',
        secondary: 1,
      },
    ],
  },
];
interface OptionsObject {
  [key: string]: string;
}

export const getOptions = (
  customData: string | undefined,
  isCustom: boolean
) => {
  const currentMonthIndex: number = moment().month();
  const monthNames: string[] = moment.months();

  // Months from January to last month (exclude current month)
  const monthsUpToPreviousMonth: string[] = monthNames.slice(
    0,
    currentMonthIndex
  );

  // isCustom && customData && monthsUpToPreviousMonth.push(customData);

  // Convert to an object with the same value and label
  const optionsObject: OptionsObject = monthsUpToPreviousMonth.reduce(
    (acc, month) => {
      acc[month] = month;
      return acc;
    },
    {} as OptionsObject
  );

  return { ...optionsObject };
};
