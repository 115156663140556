import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

const notifySuccess = (message: string) => {
  toast.success(message);
};

const notifyError = (message: string) => {
  toast.error(message);
};

export const errorHandler = (error: ApolloError, key?: string) => {
  if (error.graphQLErrors) {
    error.graphQLErrors.map(({ message }) => {
      toast.error(message);
    });
  }
  return key;
};

export { notifySuccess, notifyError };
