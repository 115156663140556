import { gql } from '@apollo/client';

export const GET_ANNOUNCEMENTS = gql`
  query fetchAnnouncement {
    getAnnouncements {
      data {
        description
        title
        id
        updated_at
        video
        image
      }
    }
  }
`;
