import React from 'react';
import { makeStyles } from '@mui/styles';
import clx from 'classnames';
import {
  HiOutlineArrowSmallLeft,
  HiOutlineArrowSmallRight,
} from 'react-icons/hi2';

type WidgetNavType = {
  onClickLeft: () => void;
  onClickRight: () => void;
  className?: string;
  color?: string;
  startingPoint: boolean;
  endingPoint: boolean;
};

const WidgetNav: React.FC<WidgetNavType> = ({
  onClickLeft,
  onClickRight,
  color,
  className,
  startingPoint,
  endingPoint,
}) => {
  const classes = useStyles();

  return (
    <div className={clx(classes.option, className)}>
      {!color ? (
        <img
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-arrow-down.png_bnlKoY'
          }
          className={classes.navigation}
          style={{
            marginRight: '10px',
            opacity: startingPoint ? '100%' : '20%',
            cursor: startingPoint ? 'pointer' : 'not-allowed',
          }}
          onClick={startingPoint ? onClickLeft : undefined}
        />
      ) : (
        !startingPoint && (
          <HiOutlineArrowSmallLeft
            className={classes.navigation}
            style={{
              marginRight: endingPoint ? '30px' : '10px',
              background: color,
            }}
            onClick={onClickLeft}
            size={20}
            color="#676E69"
          />
        )
      )}
      {!color ? (
        <img
          color="#676E69"
          className={classes.navigation}
          style={{
            opacity: endingPoint ? '100%' : '20%',
            cursor: endingPoint ? 'pointer' : 'not-allowed',
          }}
          onClick={endingPoint ? onClickRight : undefined}
          src={
            'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-arrow-down.png_PKG75v'
          }
        />
      ) : (
        !endingPoint && (
          <HiOutlineArrowSmallRight
            color="#676E69"
            className={classes.navigation}
            style={{ background: color }}
            onClick={onClickRight}
            size={20}
          />
        )
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  option: {
    position: 'absolute',
    bottom: 5,
    right: 15,
    '& p': {
      textDecoration: 'underline',
      fontSize: '18px',
    },
  },
  navigation: {
    fontSize: 40,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default WidgetNav;
