import React, { useState, useContext } from 'react';
import { AppStateContext } from '../../contexts/provider';
import { VOTE } from './graphQL';
import { useMutation } from '@apollo/client';
import { notifySuccess } from '../../utilities/notify';
import useStyles, { StyleProps } from './styles';
import PollProps from '../../types/PollType';
import { CircularProgress } from '@mui/material';

interface PollComponentProps extends PollProps, StyleProps {}

const Poll: React.FC<PollComponentProps> = ({
  id: poll_id,
  title,
  status,
  vote: votedOptionId,
  votes_count: polls_vote_count,
  options,
  author,
  update,
  background,
  padding,
  is_public,
}) => {
  const [voteMutation, { loading }] = useMutation(VOTE);
  const { user } = useContext(AppStateContext);
  const [votingOption, setVotingOption] = useState<number>(23.5);
  const user_id = user.id;

  const vote = async (option_id: number) => {
    if (loading || votedOptionId || status === 'Completed') {
      return;
    }
    const variables = { user_id, option_id, poll_id };
    setVotingOption(option_id);
    try {
      const response = await voteMutation({ variables });
      const {
        vote: { poll },
      } = response?.data;
      notifySuccess('Vote Recorded Successfully');
      if (update) {
        update(poll_id, poll);
      }
    } finally {
      setVotingOption(23.5);
    }
  };

  const classes = useStyles({ background, padding });
  return (
    <div className={classes.poll}>
      <p className={classes.poll__title}>{title}</p>
      {background && (
        <p className={classes.poll__author}>Author: {author.name}</p>
      )}
      <div className={classes.poll__options}>
        {options.map(({ id, title, votes_count }) => {
          const percent =
            votes_count === 0 && polls_vote_count === 0
              ? '0%'
              : String(((votes_count / polls_vote_count) * 100).toFixed(1)) +
                '%';
          return (
            <div
              key={id}
              onClick={() => {
                vote(id);
              }}
              className={classes.poll__option}
            >
              <p>{title}</p>
              <div
                className={classes.poll__option_vote}
                style={{
                  width:
                    (status === 'Completed' || votedOptionId) && is_public
                      ? percent
                      : '0%',
                }}
              ></div>
              <div
                className={classes.poll__option_percent}
                style={{
                  opacity: status === 'Completed' || votedOptionId ? 100 : 0,
                }}
              >
                {false && <p>{percent}</p>}
                {votedOptionId === id && (
                  <img
                    src="https://jungle-static.s3-us-west-1.amazonaws.com/jungleportal/checked.svg"
                    alt="vote"
                    style={{
                      filter:
                        'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)',
                    }}
                  />
                )}
              </div>
              <div
                className={`poll-option-loader ${
                  votingOption === id ? 'poll-option-loader-active' : ''
                }`}
              >
                <CircularProgress size="12px" color="inherit" />
              </div>
            </div>
          );
        })}
      </div>
      {!background && (
        <p className={classes.poll__author}>Author: {author.name}</p>
      )}
    </div>
  );
};

export default Poll;
