import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useEmbedLinkStyles } from './styles';
import { EmbedLinkProps } from './types';
import { Button } from '@wazobia-tech/wazcom/dist/core';
import { SelectInput, TextInput } from '@wazobia-tech/wazcom/dist/io';
import { FaTimes } from 'react-icons/fa';
import { Dialog } from '../Dialog';
import Embed from './Embed';
import {
  addDimensionToFacebookIframe,
  addDimensionToInstagramIframe,
  addDimensionToRedditIframe,
  addDimensionToTikTokIframe,
} from './helper';

export const EmbedLink: FC<EmbedLinkProps> = ({
  open,
  handleClose,
  handleEmbed,
  handleHtmlEmbed,
}) => {
  const classes = useEmbedLinkStyles();
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [label, setLabel] = useState('');
  const [checkBox, setCheckBox] = useState(false);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) return;
    setText('');
    setUrl('');
  }, [open]);

  const handleChange = ({ name, value }: { name: string; value: string }) => {
    if (name === 'text') return setText(value);
    setUrl(value);
  };

  const triggerEmbed = () => {
    const textLink = text + '||>>' + url;
    switch (label) {
      case 'text':
        handleEmbed(textLink, label);
        break;
      case 'tiktok':
      case 'twitter':
        handleHtmlEmbed(addDimensionToTikTokIframe(ref.current?.innerHTML));
        break;
      case 'facebook':
        handleHtmlEmbed(addDimensionToFacebookIframe(ref.current?.innerHTML));
        break;
      case 'instagram':
      case 'reddit':
        handleHtmlEmbed(addDimensionToRedditIframe(ref.current?.innerHTML));
        break;
      default:
        break;
    }
    setLabel('');
  };

  const inputData: {
    label: string;
    options: { label: string; value: string }[];
  }[] = [
    {
      label: 'SOCIAL MEDIA PLATFORM',
      options: [
        { label: 'Text', value: 'text' },
        { label: 'Facebook', value: 'facebook' },
        { label: 'Tiktok', value: 'tiktok' },
        { label: 'Instagram', value: 'instagram' },
        { label: 'Twitter', value: 'twitter' },
        { label: 'Reddit', value: 'reddit' },
      ],
    },
  ];

  const handleValueChange = useCallback((val: any) => {
    setLabel(val.value);
  }, []);

  const handleCheckBox = useCallback((val: any) => {
    setCheckBox((val) => !val);
  }, []);

  return (
    <Dialog open={open} handleClose={handleClose}>
      <div className={classes.embedDialog}>
        <div className={classes.embedDialogHeading}>
          <p className={classes.embedDialogTitle}>Embed Link</p>
          <FaTimes className={classes.embedDialogIcon} onClick={handleClose} />
        </div>
        <div
          style={{
            maxHeight: '80vh',
            overflow: 'scroll',
            minHeight: '30vh',
          }}
        >
          <div
            style={{
              margin: '0 auto 20px',
              width: 'fit-content',
            }}
            id="postembed"
            ref={ref}
          >
            <Embed
              label={label}
              removeCaption={checkBox}
              setText={setText}
              value={url}
            />
          </div>
          {inputData.map((data, index) => (
            <SelectInput
              key={index}
              label={data?.label}
              options={data?.options}
              value={label}
              onChange={handleValueChange}
              className={classes.selectInput}
            />
          ))}
          {label === 'text' && (
            <TextInput
              label="TEXT"
              name="text"
              value={text}
              onChange={handleChange}
              classNames={{ input: classes.embedTextInput }}
            />
          )}
          <TextInput
            label="URL"
            name="url"
            value={url}
            onChange={handleChange}
            classNames={{ input: classes.embedTextInput }}
          />
          {['facebook', 'instagram'].includes(label) && (
            <div className={classes.removeCaption}>
              <input
                type="checkbox"
                onClick={handleCheckBox}
                checked={checkBox}
              />
              <span>Remove Caption</span>
            </div>
          )}
          <div className={classes.embedDialogButtons}>
            <Button
              className={classes.embedDialogButtonPrimary}
              onClick={triggerEmbed}
            >
              Embed
            </Button>
            <Button
              className={classes.embedDialogButtonSecondary}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
