import { gql } from '@apollo/client';
import { CORE_CATEGORY_FIELDS } from '../../../Blog/graphQL';

export const CREATE_CATEGORY = gql`
  ${CORE_CATEGORY_FIELDS}
  mutation GqlCreateCategory(
    $slug: String!
    $name: String!
    $blog_uuid: ID!
    $description: String
    $subCategories: [BlogSubCategoryInput]
  ) {
    createBlogCategory(
      input: {
        slug: $slug
        name: $name
        blog_uuid: $blog_uuid
        description: $description
        subCategories: $subCategories
      }
    ) {
      ...CoreCategoryFields
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  ${CORE_CATEGORY_FIELDS}
  mutation GqlUpdateCategory(
    $uuid: ID!
    $name: String!
    $slug: String
    $description: String
    $subCategories: [BlogSubCategoryInput]
  ) {
    updateBlogCategory(
      input: {
        uuid: $uuid
        name: $name
        slug: $slug
        description: $description
        subCategories: $subCategories
      }
    ) {
      ...CoreCategoryFields
    }
  }
`;
