import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    background: theme.design.colors.grey950,
    padding: theme.spacing(2, 8, 2, 4),
    minHeight: 'calc(100vh - 75px)',
    position: 'relative',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '18% 80%',
    gap: '2%',
    justifyContent: 'center',
    '&.single': {
      display: 'block',
    },
  },
  imageTop: {
    position: 'fixed',
    top: 195,
    right: 10,
  },
  imageBottom: {
    position: 'fixed',
    bottom: 0,
    left: -30,
  },
  asideContainer: {
    background: theme.design.colors.white,
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  aside: {
    zIndex: 2,
  },
  activeTab: {
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    height: '70px',
    width: '100%',
    background: theme.design.colors.white,
    padding: theme.spacing(0, 6),
    boxSizing: 'border-box',
    fontSize: theme.typography.fontSize * 4,
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontFamily: 'Feature',
    fontWeight: theme.typography.fontWeightBold,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 6, 6, 6),
    background: theme.design.colors.white,
    borderRadius: 10,
    '& p': {
      lineHeight: '25px',
      textAlign: 'justify',
    },
    '& ul': {
      fontFamily: theme.design.font.universal,
    },
    '& li': {
      marginBottom: '20px',
      lineHeight: '25px',
      textAlign: 'justify',
    },
  },
  content__loading: {
    height: '100%',
    overflow: 'hidden',
  },
  content__title: {
    fontSize: '18px',
    lineHeight: '54px',
    margin: '24px 0 12px 0',
    fontFamily: theme.design.font.universal,
    fontWeight: theme.typography.fontWeightMedium,
  },
  content__info: {
    display: 'flex',
    marginBottom: '1.875rem',
    fontSize: '1.125rem',
  },
  content__details: {
    lineHeight: '1.05rem',

    '& iframe': {
      width: '100%',
    },
  },
  content__loader: {
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.design.colors.widgetGrey,
    transition: 'opacity 0.3s ease-in',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabs: {
    display: 'flex',
    gap: theme.spacing(2.5),
    margin: theme.spacing(3, 0),
  },
  link: {
    textDecoration: 'none',
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.grey800,
    paddingBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  links: {
    textDecoration: 'none',
    fontSize: theme.typography.fontSize + 2,
    color: theme.design.colors.grey800,
  },
  linkActive: {
    color: theme.design.colors.grey100,
    borderBottom: `5px solid ${theme.design.colors.black}`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  landingTabs: {
    borderBottom: `1px solid ${theme.design.colors.lidGrey}`,
    paddingLeft: '9%',
  },
  classification: {
    border: `1px solid ${theme.design.colors.white}`,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 3.5),
    '& p': {
      padding: theme.spacing(1, 0),
    },
  },
  content__accordionTitle: {
    fontSize: '18px',
    lineHeight: '54px',
    margin: '24px 0 12px 0',
    fontFamily: theme.design.font.universal,
    fontWeight: theme.typography.fontWeightMedium,
    border: `1px solid ${theme.design.colors.lidGrey}`,
    padding: theme.spacing(0, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  content__accordionAnswer: {
    border: `1px solid ${theme.design.colors.lidGrey}`,
    margin: theme.spacing(-1.6, 0),
    display: 'grid',
    'grid-template-rows': '0fr',
    transition: 'grid-template-rows 0.5s ease-out',
    padding: theme.spacing(0, 2.5),
    '&.open': {
      'grid-template-rows': '1fr',
    },
    '& p': {
      overflow: 'hidden',
    },
  },
  accordion: {
    width: '60%',
    margin: '10px auto 0',

    '&:first-child': {
      margin: '30px auto 0',
    },

    '& h3': {
      fontSize: '13px !important',
    },

    '& .accordion__header': {
      padding: theme.spacing(1, 2),
    },

    '& p': {
      fontSize: '13px !important',
    },
  },
  arrowRotationUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.5s ease-in-out',
  },
  arrowRotationDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.5s ease-in-out',
  },
}));

export default useStyles;
