import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: `1px solid ${theme.design.colors.black}`,
    background: theme.design.colors.black,
    transition: 'opacity 0.5s ease-in',
    opacity: 0,
    zIndex: -9,
  },
  color: {
    color: theme.design.colors.white,
  },
  loaderParent: {
    color: theme.design.colors.black,
  },
  active: {
    opacity: 100,
    zIndex: 10,
  },
}));

export default useStyles;
