import React, { useContext, useEffect } from 'react';
import { AppStateContext } from '../../../contexts/provider';
import { useLocation } from 'react-router-dom';
import { Select } from '../../common/Select/Select';
import { useNavContentStyles } from './styles';
import { useQuery } from '@apollo/client';
import { FETCHSITE, FETCH_SITES } from '../../../utilities/graphql/queries';
import { useFetchChannelsData } from 'pages/Cpas/helpers';
import { Autocomplete, TextField } from '@mui/material';

const NavContent = () => {
  const { changeSite, site, changeChannel, brand } =
    useContext(AppStateContext);

  const { data } = useQuery(FETCH_SITES, {
    fetchPolicy: 'network-only',
  });

  const { channelsData, isLoading, error } = useFetchChannelsData();
  const classes = useNavContentStyles();

  const location = useLocation();
  const selectOptions = {
    VT: 'vt',
    'The Wild': 'thewild',
    'Twisted Food': 'twistedfood',
    'Four Nine': 'fournine',
    'Craft Factory': 'craftfactory',
    'Jungle Creations': 'junglecreations',
  };

  const channels = channelsData.map((item) => ({
    label: item.mutablePublisherName,
    value: item.mutablePublisherName,
    businessProfileID: item.businessProfileId,
  }));

  const handleSiteChange = (
    e: React.ChangeEvent<{ name?: string; value: string }>
  ) => {
    changeSite(e.target.value, data?.getBlogSites);
  };

  const handleChannelChange = (
    event: any,
    newValue: { [key: string]: string }
  ) => {
    changeChannel(newValue.value, channelsData);
  };

  useEffect(() => {
    switch (site) {
      case 'vt':
        localStorage.setItem(
          'active_site_app_id',
          import.meta.env.VITE_VT_FACEBOOK_APP_ID as string
        );
        break;
      case 'twistedfood':
        localStorage.setItem(
          'active_site_app_id',
          import.meta.env.VITE_TWISTED_FACEBOOK_APP_ID as string
        );
        break;
    }
  }, [site]);

  const cms = location.pathname.includes('cms');
  const cpas = location.pathname.includes('cpas');
  const showSelectField =
    location.pathname.includes('cms') || location.pathname.includes('cpas');

  const sortedChannels = channels.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const groupingChannels = sortedChannels.map((channel: any) => ({
    ...channel,
    group: 'Channels',
  }));

  const options = [
    {
      label: 'All Channels',
      value: 'All Channels',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Craft Factory(Brand)',
      value: 'Craft Factory(Brand)',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Four Nine',
      value: 'Four Nine',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Futur3ology',
      value: 'Futur3ology',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Level Fitness',
      value: 'Level Fitness',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Lovimals',
      value: 'Lovimals',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'Twisted(Brand)',
      value: 'Twisted(Brand)',
      businessProfileId: '',
    },
    {
      group: 'Brands',
      label: 'VT',
      value: 'VT',
      businessProfileId: '',
    },
    ...groupingChannels,
  ];

  return (
    <div className={classes.root}>
      <div className={classes.select}>
        {showSelectField &&
          (!cpas ? (
            <Select
              value={site}
              options={selectOptions}
              onChange={handleSiteChange}
            />
          ) : (
            <Autocomplete
              className={classes.select}
              options={options}
              defaultValue={{ label: 'Twisted Explore' }}
              onChange={handleChannelChange}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
              renderGroup={(params) => (
                <li key={params.key}>
                  <div className={classes.group}>{params.group}</div>
                  <div>{params.children}</div>
                </li>
              )}
            />
          ))}
      </div>
    </div>
  );
};

export default NavContent;
