import { gql } from '@apollo/client';

export const FETCHSOCIALINTEGRATIONS = gql`
  query getConnection {
    connections {
      data {
        platform
        name
        status {
          status
          created_at
        }
      }
    }
  }
`;

export const UPDATESOCIALPOSTS = gql`
  mutation updateSocialPost {
    updateSocialPosts {
      platform
      name
      status {
        status
        created_at
      }
    }
  }
`;
