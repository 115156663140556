import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    borderRadius: '10px',
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    color: theme.design.colors.black,
    backgroundColor: theme.design.colors.white,
    margin: theme.spacing(1.5, 0, 2, 0),
    padding: theme.spacing(2),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  temperature: {
    fontSize: theme.typography.fontSize + 4,
    color: theme.design.colors.grey50,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.design.colors.jpGrey300,
    },
  },
  date: {
    color: theme.design.colors.darkCharcoal,
    fontSize: theme.typography.fontSize - 2,
    margin: theme.spacing(1, 0),
  },
  firstname: {
    fontSize: theme.typography.fontSize * 3,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
    '& p, & span': {
      fontSize: theme.typography.fontSize * 3,
      fontFamily: theme.design.font.feature,
    },
  },
  lastname: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Universal',
  },
  image_section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      height: '100%',
    },
  },
}));

export default useStyles;
