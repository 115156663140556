import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useTaggableStyles = makeStyles<Theme>((theme: Theme) => ({
  renderChipOutside: {
    position: 'absolute',
    top: 60,
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));

export default useTaggableStyles;
