import { FC, useState, useEffect } from 'react';
import { EmbedVideoProps } from './types';
import { useEmbedVideoStyles } from './styles';
import { FaTimes } from 'react-icons/fa';
import { Button } from '@wazobia-tech/wazcom/dist/core';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { Dialog } from '../Dialog';

export const EmbedVideo: FC<EmbedVideoProps> = ({
  open,
  handleClose,
  handleEmbed,
}) => {
  const classes = useEmbedVideoStyles();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (open) return;
    setUrl('');
  }, [open]);

  const handleChange = ({ value: url }: { name: string; value: string }) => {
    setUrl(url);
  };

  const triggerEmbed = () => {
    if (url.trim() === '') return;

    handleEmbed(url);
    setUrl('');
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <div className={classes.embedDialog}>
        <div className={classes.embedDialogHeading}>
          <p className={classes.embedDialogTitle}>Embed Video</p>
          <FaTimes className={classes.embedDialogIcon} onClick={handleClose} />
        </div>
        <TextInput
          label="Video URL"
          value={url}
          onChange={handleChange}
          classNames={{ input: classes.embedTextInput }}
        />
        <div className={classes.embedDialogButtons}>
          <Button
            className={classes.embedDialogButtonPrimary}
            onClick={triggerEmbed}
          >
            Embed
          </Button>
          <Button
            className={classes.embedDialogButtonSecondary}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
