import { Icon } from '@iconify/react';
import { Divider } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '../common/ModalManager';
import PopoverItem from '../common/PopoverItem';
import SentimentResult from '../common/SentimentResult';
import { CampaignCardProps } from './types';
import DeleteCampaign from './DeleteCampaign';
import AddPostToCampaignForm from './forms/AddPostToCampaignForm';
import { useCommentAnalysis } from 'contexts/ca';

export default function CampaignCard({ campaign }: CampaignCardProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { showModal } = useModal();
  const { downloadCommentsHandler } = useCommentAnalysis();

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-[repeat(13,_minmax(0,_1fr))] bg-viol\et-300 p-4 gap-4"
    >
      <div className="col-span-2 max-w-full h-full flex items-center">
        <p className="text-[#101828] truncate !font-light">{campaign?.name}</p>
      </div>

      <div className="col-span-2 max-w-full h-full flex items-center">
        <div className="flex items-center space-x-3">
          <Icon width={20} color="#D8D8D8" icon="skill-icons:instagram" />

          {/* <Icon width={20} color="#D8D8D8" icon="skill-icons:linkedin" /> */}

          <Icon width={20} color="#D8D8D8" icon="logos:facebook" />
        </div>
      </div>

      <div className="col-span-2 max-w-full h-full flex items-center">
        <p className="text-[#344054] truncate !font-light">
          {campaign?.postsCount}
        </p>
      </div>

      <div className="col-span-2 max-w-full h-full flex items-center">
        <p className="text-[#344054] truncate !font-light">
          {campaign.commentsCount}
        </p>
      </div>

      <div className="col-span-2 max-w-full h-full flex items-center">
        <SentimentResult
          result={{
            sad: campaign.sentiment.negative,
            neutral: campaign.sentiment.neutral,
            happy: campaign.sentiment.positive,
          }}
        />
      </div>

      <div className="col-span-2 max-w-full h-full flex items-center">
        <div className="space-y-2">
          <p className="text-[#344054] truncate !font-light">
            {campaign.createdAt}
          </p>
        </div>
      </div>

      <div className="col-span-1 max-w-full h-full flex justify-end items-center">
        <button
          aria-describedby="simple-popover"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Icon color="#98A2B3" icon="ph:dots-three-outline-vertical-fill" />
        </button>

        <Popover
          open={open}
          id={popoverId}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="w-[13.5rem]">
            <PopoverItem
              btnText="Add new post"
              icon="tabler:plus"
              onClick={() =>
                showModal(
                  <AddPostToCampaignForm campaignUuid={campaign.uuid} />
                )
              }
            />

            <Divider style={{ borderColor: '#dfe3e9' }} />

            {/* @ts-ignore */}
            <Link to={`/ca/campaign/${campaign.uuid}`}>
              <PopoverItem btnText="View campaign details" icon="carbon:view" />
            </Link>

            <Divider style={{ borderColor: '#dfe3e9' }} />

            {/* @ts-ignore */}
            <Link to={`/ca/campaign/${campaign.uuid}/sentiment-report`}>
              <PopoverItem
                btnText="View sentiment report"
                icon="solar:chart-square-outline"
              />
            </Link>

            <Divider style={{ borderColor: '#dfe3e9' }} />

            <PopoverItem
              btnText="Download sentiment report"
              icon="octicon:download-16"
              onClick={() => downloadCommentsHandler(campaign.uuid)}
              disabled={!campaign.commentsCount}
            />

            <Divider style={{ borderColor: '#dfe3e9' }} />

            <PopoverItem
              btnText="Delete campaign"
              icon="solar:trash-bin-2-outline"
              onClick={() =>
                showModal(<DeleteCampaign campaignUuid={campaign.uuid} />)
              }
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}
