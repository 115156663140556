import React, { useState } from 'react';
import { useFormStyles } from './styles';
import { TableConditionFilter, TableNew } from '@wazobia-tech/wazcom/dist/core';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { GET_RESPONSES_BY_FORM_UUID } from './graphql';
import { ApolloConsumer } from '@apollo/client';
import { capitalizeFirstCharacter } from './helpers';
import { Tag } from '../TitleTag/Tag';

const ViewResponses = () => {
  const classes = useFormStyles();
  const { uuid } = useParams<{ uuid: string }>();
  type RowType = Record<string, JSX.Element | string | number>;

  const [questions] = useState<Record<'question', string>[]>(() => {
    if (!uuid) return [];

    const cachedQuestion = sessionStorage.getItem('form-questions');
    if (!cachedQuestion) return [];

    const questions = JSON.parse(cachedQuestion);
    return questions ?? [];
  });

  const selectFirst100CharFromString = (word = '') => {
    if (word.length <= 100) return word;
    return word.slice(0, 100).concat('...');
  };
  const useGetTableColumns = () => {
    return questions?.map((question, index) => ({
      name:
        index === 0
          ? capitalizeFirstCharacter(question['question'])
          : question.question,
      key: question.question,
      type: typeof question.question as 'string' | 'number' | 'date',
      actions:
        index === 0
          ? [
              {
                name: 'View Response',
                func: (uuid: string) => {},
              },
            ]
          : undefined,
    }));
  };

  const useGetTableRows = () => {
    return (data: any[]) => {
      const parsedRows: RowType[] = [];

      for (const row of data) {
        const parsedRow: RowType = {};
        questions?.forEach((question, index) => {
          if (index === 0) {
            parsedRow[question.question] = (
              <p
                className={classes.tableTitle}
                style={{ textTransform: 'capitalize' }}
              >
                {selectFirst100CharFromString(row.response[question.question])}
              </p>
            );
          } else {
            parsedRow[question.question] = selectFirst100CharFromString(
              row.response[question.question]
            );
          }
        });
        const updatedAt = moment(row?.updated_at).fromNow();
        parsedRow['updated_at'] =
          updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
        parsedRow['identifier'] = row.uuid;
        parsedRows.push(parsedRow);
      }
      return parsedRows;
    };
  };

  const tableConditionFilters: TableConditionFilter[] = [
    { name: 'All Forms', condition: (posts: any) => posts },
  ];

  const tableRows = useGetTableRows();
  const tableColumns = useGetTableColumns();
  return (
    <ApolloConsumer>
      {(client: any) => (
        <div>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Responses</p>
          </div>
          <div className={classes.main}>
            <TableNew
              columns={tableColumns ?? []}
              getRows={tableRows}
              query={{
                name: GET_RESPONSES_BY_FORM_UUID,
                client,
                variables: {
                  form_uuid: uuid,
                  refreshToken: location.pathname,
                  first: 5,
                },
                dataKey: 'getResponsesByFormUuid',
              }}
              conditionFilters={tableConditionFilters}
            />
          </div>
        </div>
      )}
    </ApolloConsumer>
  );
};

export default ViewResponses;
