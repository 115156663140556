import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useTagListStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
  },
  tagSuggestions: {
    marginBottom: theme.spacing(3),
  },
  tagSuggestion: {
    padding: theme.spacing(0.5, 1),
    height: 23,
    fontSize: theme.typography.fontSize,
  },
  tagHeading: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.charcoal,
    marginBottom: theme.spacing(1),
  },
  tagContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  tagSelections: {},
}));
