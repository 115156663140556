import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_CAMPAIGNS } from 'graphql/jcmat/campaigns';
import { DOWNLOAD_COMMENTS } from 'graphql/jcmat/downloadComments';
import { caClient } from 'pages/Ca/client';
import { downloadCSV } from 'pages/Ca/components/helper';
import React, { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';

interface CommentAnalysisContextType {
  data?: any;
  loading: boolean;
  error?: any;
  fetchCampaigns: (page?: number) => void;
  page: number;
  setPage: (page: number) => void;
  total: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  downloadCommentsHandler: (campaignUuid: string) => void;
  isCommentsLoading: boolean;
  addPostToAnalysis: (postData: any) => void,
  analyzingPostItems: any[]
}

export const CommentAnalysisContext =
  createContext<CommentAnalysisContextType | null>(null);

const CommentAnalysisProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const take = 10;
  const [page, setPage] = useState(1);
  const [analyzingPostItems, setAnalyzingPostItems] = useState<any[]>([]);

  // Standard query (fetches immediately)
  const { data, loading, error, refetch } = useQuery(GET_CAMPAIGNS, {
    variables: {
      pageSize: take,
      page,
    },
    client: caClient,
  });

  // Function to fetch campaigns from anywhere
  const fetchCampaigns = async (pageNumber = 1) => {
    setPage(pageNumber);
    await refetch({
      pageSize: take,
      page,
    });
  };

  const total = data?.campaigns?.pagination.totalPages;
  const hasPreviousPage = data?.campaigns?.pagination.hasPreviousPage;
  const hasNextPage = data?.campaigns?.pagination.hasNextPage;

  const [downloadComments, { loading: isCommentsLoading }] = useLazyQuery(
    DOWNLOAD_COMMENTS,
    {
      client: caClient,
      onCompleted: (data) => {
        toast.success('Comments downloaded successfully!');

        if (data?.commentsByCampaignUuidAsCsv) {
          downloadCSV(data.commentsByCampaignUuidAsCsv);
        }
      },
      onError: (error) => {
        toast.error(`Error downloading comments: ${error.message}`);
      },
    }
  );

  const downloadCommentsHandler = (campaignUuid: string) => {
    downloadComments({ variables: { campaignUuid } });
  };

  const addPostToAnalysis = (postData: any) => {
    setAnalyzingPostItems((prevItems) => {
      if (!prevItems.includes(postData)) {
        return [...prevItems, postData];
      }
      return prevItems;
    });
  };

  return (
    <CommentAnalysisContext.Provider
      value={{
        data,
        loading,
        error,
        page,
        setPage,
        fetchCampaigns,
        total,
        hasPreviousPage,
        hasNextPage,
        downloadCommentsHandler,
        isCommentsLoading,
        addPostToAnalysis,
        analyzingPostItems
      }}
    >
      {children}
    </CommentAnalysisContext.Provider>
  );
};

export const useCommentAnalysis = () => {
  const context = useContext(CommentAnalysisContext);
  if (!context) {
    throw new Error(
      'useCommentAnalysis must be used within a CommentAnalysisProvider'
    );
  }
  return context;
};

export default CommentAnalysisProvider;
