import CampaignCard from './CampaignCard';
import TablePagination from '../common/TablePagination';
import { formatDistanceToNow } from 'date-fns';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { Button } from 'components/common/Button/Button';
import { Icon } from '@iconify/react';
import { useModal } from '../common/ModalManager';
import NewCampaignForm from './NewCampaignForm';
import { useCommentAnalysis } from 'contexts/ca';
import { useState } from 'react';
import { Campaign } from './types';

export default function CampaignOverviewTable() {
  const { showModal } = useModal();
  const { data } = useCommentAnalysis();
  const campaigns: Campaign[] = data?.campaigns?.data;
  const [searchQuery, setSearchQuery] = useState('');

  const filteredCampaigns = campaigns?.filter((campaign: Campaign) =>
    campaign?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <div className="overflow-x-scroll">
      <div className="min-w-[70rem]">
        <div style={{ border: '2px solid #EAECF0' }} className="rounded-[12px]">
          <div className="p-5 flex justify-between items-center">
            <div className="space-y-2">
              <p className="text-[#101828] font-[550] text-xl">
                Campaign Overview
              </p>

              <p className="text-[#8E8E93]">
                Get a detailed view of your campaign's performance
              </p>
            </div>

            <div className="flex h-[60px] w-full max-w-[30rem] items-center space-x-10">
              <TextInput
                height="100%"
                borderRadius="999px"
                placeholder="Search"
                className="w-full text-[#6C747A] placeholder:text-[#6C747A]"
                leftIcon={
                  <div style={{ marginLeft: 2, marginRight: 5 }}>
                    <Icon width={20} color="#D8D8D8" icon="iconamoon:search" />
                  </div>
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.value)}
              />

              <div>
                <Button
                  onClick={() => showModal(<NewCampaignForm />)}
                  className="!rounded-full whitespace-nowrap"
                  startIcon={
                    <Icon width={16} color="white" icon="line-md:plus" />
                  }
                >
                  Create new campaign
                </Button>
              </div>
            </div>
          </div>

          <TableTitle />

          {filteredCampaigns?.length > 0 ? (
            filteredCampaigns?.map((campaign: Campaign) => {
              return (
                <CampaignCard
                  key={campaign.uuid}
                  campaign={{
                    ...campaign,
                    createdAt: formatDistanceToNow(
                      new Date(campaign.createdAt),
                      {
                        addSuffix: true,
                      }
                    ),
                  }}
                />
              );
            })
          ) : (
            <p className="flex justify-center italic text-lg text-gray-500 my-12">
              No available camapaign
            </p>
          )}

          {filteredCampaigns?.length > 0 && <TablePagination />}
        </div>
      </div>
    </div>
  );
}

function TableTitle() {
  return (
    <div
      style={{ borderBottom: '2px solid #EAECF0' }}
      className="grid grid-cols-[repeat(13,_minmax(0,_1fr))] p-4 bg-[#EAECF0] gap-4"
    >
      <div className="col-span-2">
        <p>Campaign Title</p>
      </div>

      <div className="col-span-2">
        <p>Platform(s) Used</p>
      </div>

      <div className="col-span-2">
        <p>Number of Posts</p>
      </div>

      <div className="col-span-2">
        <p>Comments</p>
      </div>

      <div className="col-span-2">
        <p>Sentiment Analysis</p>
      </div>

      <div className="col-span-2">
        <p>Date Created</p>
      </div>

      <div className="col-span-1" />
    </div>
  );
}
