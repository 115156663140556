import axios from 'axios';
import { notifyError, notifySuccess } from '../../../utilities/notify';

export const uploadImage = async (
  file: File,
  setLoading: (loading: boolean) => void
) => {
  const url = import.meta.env.VITE_REST_URL + 'api/image/upload';
  setLoading(true);
  try {
    const headers = {};
    const data = new FormData();
    data.append('site', 'fournine');
    data.append('image', file);
    const {
      data: { location },
    } = await axios.post(url, data, headers);
    notifySuccess('Image uploaded successfully');
    return { success: true, url: location };
  } catch (error: any) {
    if (error.response) {
      const {
        data: { message },
      } = error.response;
      notifyError(message);
      return { success: false, url: null };
    }
    notifyError('Failed to connect to API');
    return { success: false, url: null };
  } finally {
    setLoading(false);
  }
};

export const getFileType = (url: string) => {
  const uExtension = url.split('.').pop();
  const extension = uExtension ? uExtension.toLowerCase() : '';

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  if (imageExtensions.includes(extension)) {
    return 'image';
  }

  return 'video';
};
