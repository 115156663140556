import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
  },
  content__title: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'bold',
    marginBottom: '2.5rem',
  },
  content__form: {
    background: '#fff',
    padding: '2.5rem',
  },
  content__form_section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2.5rem',
    width: '100%',
  },
  content__form_section_select: {
    // padding:"2.5rem",
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginRight: '3.813rem',
    // justifyContent:"space-between",
  },
  select_section: {
    display: 'flex',
    flexDirection: 'row',
  },
  content__form_label: {
    fontSize: '1.125rem',
    marginBottom: '1.25rem',
  },
  content__form_error: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  content__form_actions: {
    margin: '4.25rem 0 0 0',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  input_parent: {
    position: 'relative',
    width: '100%',

    '& .select': {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50%',
      zIndex: 10,
    },
  },
  input: {
    width: '100%',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
}));

export default useStyles;
