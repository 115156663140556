import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { FiUserPlus } from 'react-icons/fi';
import WidgetNav from '../common/WidgetNav/WidgetNav';
import { useQuery } from '@apollo/client';
import { GET_EMPLOYEES } from './graphQL';
import { CircularProgress } from '@mui/material';
import { WidgetButton } from 'components/SurveyPolls/SurveyPolls';
import { FaFaceSmile } from 'react-icons/fa6';

const EmployeeWidget = () => {
  const classes = useStyles();
  const { data, loading } = useQuery(GET_EMPLOYEES);

  const dataLimit = 1;
  const [pages, setPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data) {
      setPages(Math.ceil(data?.getNewEmployees?.length / dataLimit));
    }
  }, [data]);

  const goToNextPage = () => {
    if (currentPage !== pages) {
      setCurrentPage((page) => page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((page) => page - 1);
    }
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data?.getNewEmployees.slice(startIndex, endIndex);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <img
          src=" https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-22T155643/eUhmbPggzbJI15ItHcYbt77j0ZNcnDLEsu0XE6ve.png"
          alt="employeeIcon"
        />
        <p className={classes.headerTitle}>New Employees</p>
      </div>
      <div className={classes.info}>
        {loading ? (
          <CircularProgress className={classes.loader} size="24px" />
        ) : (
          getPaginatedData()?.map((data: any, index: number) => (
            <>
              <div key={index} className={classes.infoDetails}>
                <div className={classes.image}>
                  <img
                    className={classes.avatarImage}
                    src={
                      data?.avatar?.image
                        ? `data:image/${data?.avatar?.type.toLowerCase()};base64, ${
                            data?.avatar?.image
                          }`
                        : 'https://jungle-portal.s3-us-west-1.amazonaws.com/Neutral2.png'
                    }
                    alt="Jungle Creations Logo"
                  />
                </div>
                <div className={classes.details}>
                  <p className={classes.name}>{data.name}</p>
                  <p className={classes.job}>{data.role} </p>
                </div>
              </div>
              {/* {todayDate === data.dob && ( */}
              {/* <WidgetButton
                title="Send a Message"
                icon={<FaFaceSmile color={'#86FC65'} size={20} />}
                color={'#FF656C'}
                // handleOpenModal={handleOpenModal}
              /> */}
            </>
            // )}
          ))
        )}
      </div>
      <WidgetNav
        onClickLeft={goToPreviousPage}
        onClickRight={goToNextPage}
        className={classes.navWidget}
        color="#F4EFED"
        startingPoint={currentPage === 1}
        endingPoint={data?.getNewEmployees?.length === currentPage}
      />
    </div>
  );
};

export default EmployeeWidget;
