import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    boxSizing: 'border-box',
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    background: theme.design.colors.pink100,

    '& .loader': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: -9999,
      background: theme.design.colors.white,
      borderRadius: '0 0 10px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 0.5s ease-in',
    },

    '& .active': {
      opacity: 1,
      zIndex: 10,
    },
  },
  containerHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.design.colors.titleColor,
    marginBottom: theme.spacing(2),

    '& .icon': {
      marginRight: theme.spacing(1),
    },
  },
  updateheader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  containerUpdates: {
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    position: 'relative',
    display: 'flex',
    width: '100%',

    '&::-webkit-scrollbar': {
      height: 0,
    },
  },
  container__newsletter: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '100%',
    height: '379px',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 16,
      position: 'relative',
    },
  },
  video: {
    borderRadius: 16,
    background: theme.design.colors.black,
  },
  jungleText: {
    top: '70px',
    left: '305px',
    position: 'absolute',
  },
  jungleSpan: {
    fontFamily: 'Universal',
    fontWeight: 700,
    fontSize: theme.typography.fontSize + 4,
  },
  readBtn: {
    width: '90%',
  },
  updateTitle: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '40px',
    left: '40px',
    alignItems: 'flex-end',
    '& h1': {
      color: '#F4EFED',
      fontSize: theme.typography.fontSize * 6,
      fontFamily: 'Universal',
    },
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,.2.5)',
    borderRadius: 16,
  },
  btncontainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    textDecoration: 'none',
    bottom: 25,
  },
  date: {
    fontSize: theme.typography.fontSize - 3,
    color: theme.design.colors.grey200,
    fontWeight: theme.typography?.fontWeightMedium,
  },
  container__nav: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 4,
    color: theme.design.colors.white,
    fontWeight: theme.typography?.fontWeightMedium,
    fontFamily: 'Feature',
  },
  videoAction: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  navWidget: {
    position: 'relative',
    right: 'unset',
    bottom: 'unset',
  },
}));

export default useStyles;
