import { gql } from '@apollo/client';

export const FETCHTACODETAILS = gql`
  query christmasDraws($first: Int, $page: Int) {
    christmasDraws(first: $first, page: $page) {
      data {
        name
        city
        email
        dob
        site
        created_at
      }
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        perPage
      }
    }
  }
`;
