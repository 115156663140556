import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  social: {
    backgroundColor: theme.design.colors.green100,
    padding: theme.spacing(2),
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    // marginTop: theme.spacing(2),
    position: 'relative',

    '& .slick-slider': {
      display: 'grid !important',
    },

    '& .slick-track': {
      height: '100%',

      '& > div > div': {
        height: '410px',
      },
    },

    '& .slick-dots': {
      top: '-25px',
      height: 10,
      right: '-30%',
    },

    '& .loader': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: -9999,
      background: theme.design.colors.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 0.5s ease-in',
    },

    '& .active': {
      opacity: 1,
      zIndex: 10,
    },
  },
  social__heading: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.design.colors.titleColor,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.design.font.universal,

    '& div:nth-child(1)': {
      '& .text': {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize + 2,
        color: theme.design.colors.grey100,
      },

      '& .icon': {
        margin: `${theme.spacing(0.25)}px ${theme.spacing(2)}px 0 0`,
        width: '20px',
        height: '20px',
      },

      '& .three-dots': {
        width: '24px',
        height: '24px',
        color: theme.design.colors.grey,
        cursor: 'pointer',
      },
    },

    '& .filters': {
      position: 'absolute',
      top: '80%',
      right: '5%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '5px',
      boxShadow: '0px 6px 11px rgba(68, 68, 68, 0.15)',
      padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px`,
      width: '173px',
      boxSizing: 'border-box',
      opacity: 0,
      zIndex: -1,
      background: theme.design.colors.white,
      transition: 'opacity 0.5s ease-in',

      '& .filter-parent': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),

        '& div': {
          display: 'flex',
          justifyContent: 'space-between',
        },

        '& .filter': {
          fontSize: 12,
          fontWeight: 'normal',
          marginBottom: theme.spacing(1),
          cursor: 'pointer',
        },
      },
    },

    '& .filter-parent:nth-child(1)': {
      borderBottom: `0.5px solid ${theme.design.colors.lightestGrey}`,
    },

    '& .filters-active': {
      zIndex: 5,
      opacity: 1,
    },
  },
  postContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  social__posts: {
    display: 'flex',
    scrollBehavior: 'smooth',
    overflowX: 'scroll',

    '&::-webkit-scrollbar': {
      width: '3px',
      background: theme.design.colors.scrollBarBackground,
      display: 'none',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.design.colors.scrollBarThumbBackground,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.design.colors.scrollBarTrackBackground,
    },
  },
  social__loader: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  social__post: {
    display: 'flex',
    flexDirection: 'column',

    '& div:nth-child(1)': {
      display: 'flex',
      justifyContent: 'space-between',

      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        width: '45%',
      },
    },
  },
  subHeading: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.darkCharcoal,
    padding: theme.spacing(1, 0, 1.5, 0),
    fontWeight: theme.typography.fontWeightRegular,
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 4,
    color: theme.design.colors.grey100,
    fontFamily: 'Feature',
    fontWeight: theme.typography.fontWeightMedium,
  },
  blockquote: {
    maxWidth: '720px',
    minWidth: '288px',
  },
  snapchat: {
    width: '100%',
    marginTop: theme.spacing(2),
    height: 384,
    borderRadius: '8px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
  },
  img: {
    height: '100%',
    width: '100%',
    borderRadius: '8px',
  },
}));

export default useStyles;
