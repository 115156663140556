import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import { useGetPreSlugURL } from 'components/Blog/helpers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useAutoFill = (
  formData: { [key: string]: any },
  setFormData: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >,
  tabName: string
) => {
  const { uuid } = useParams();
  const getPreSlugUrl = useGetPreSlugURL();
  const [ignoreDetailsChangeState, setIgnoreDetailsChange] = useState({
    title:
      formData[`${tabName}_title`] &&
      decodeHtml(formData.title) !== formData[`${tabName}_title`],
    description:
      formData[`${tabName}_description`] &&
      decodeHtml(formData.excerpt) !== formData[`${tabName}_description`],
    image: false,
    url:
      formData[`${tabName}_url`] &&
      getPreSlugUrl() + formData.permalink !== formData[`${tabName}_url`],
  });

  const shouldAutoFillImage = () => {
    if (
      (tabName === 'social' || tabName === 'twitter') &&
      getPreSlugUrl().includes('articles')
    )
      return false;
    if (uuid) {
      if (
        (formData[`${tabName}_image`] === 'null' ||
          !formData[`${tabName}_image`]) &&
        !formData.featured_image
      ) {
        return true;
      } else if (
        (formData[`${tabName}_image`] === 'null' ||
          !formData[`${tabName}_image`]) &&
        formData.featured_image
      ) {
        return true;
      } else if (
        JSON.stringify(parseRecursive(formData[`${tabName}_image`])) !==
        JSON.stringify(parseRecursive(formData.featured_image))
      ) {
        return false;
      } else {
        return !ignoreDetailsChangeState.image;
      }
    } else {
      if (
        (formData[`${tabName}_image`] === 'null' ||
          !formData[`${tabName}_image`]) &&
        !formData.featured_image
      ) {
        return true;
      } else if (
        (formData[`${tabName}_image`] === 'null' ||
          !formData[`${tabName}_image`]) &&
        formData.featured_image
      ) {
        return true;
      } else if (
        JSON.stringify(parseRecursive(formData[`${tabName}_image`])) !==
        JSON.stringify(parseRecursive(formData.featured_image))
      ) {
        return false;
      } else {
        return !ignoreDetailsChangeState.image;
      }
    }
  };

  useEffect(() => {
    const isAutoFilled =
      decodeHtml(formData.title)?.trim() ===
        formData[`${tabName}_title`]?.trim() &&
      decodeHtml(formData.excerpt || formData.content)?.trim() ===
        formData[`${tabName}_description`]?.trim();
    setFormData((oldFormData) => ({
      ...oldFormData,
      is_autofilled_suggestion: isAutoFilled,
    }));

    if (formData?.is_autofilled_suggestion === true) {
      const new_data = {
        [`${tabName}_title`]:
          ignoreDetailsChangeState.title || !ignoreDetailsChangeState.title
            ? decodeHtml(formData.title)
            : formData[`${tabName}_title`],
        [`${tabName}_description`]:
          ignoreDetailsChangeState.description ||
          !ignoreDetailsChangeState.description
            ? decodeHtml(formData.excerpt || formData.content)
            : formData[`${tabName}_description`],
        [`${tabName}_image`]: formData.featured_image,
        [`${tabName}_url`]: getPreSlugUrl() + formData.slug,
      };

      setFormData((oldFormData) => ({ ...oldFormData, ...new_data }));
    } else {
      setFormData((oldFormData) => ({
        ...oldFormData,
        [`${tabName}_image`]: formData[`${tabName}_image`],
        [`${tabName}_url`]: getPreSlugUrl() + formData.slug,
      }));
    }
  }, [formData.is_autofilled_suggestion]);

  useEffect(() => {
    if (
      decodeHtml(formData.title)?.trim() !==
        formData[`${tabName}_title`]?.trim() ||
      decodeHtml(formData.excerpt || formData.content)?.trim() !==
        formData[`${tabName}_description`]?.trim()
    ) {
      setFormData((oldFormData) => ({
        ...oldFormData,
        is_autofilled_suggestion: false,
      }));
    }
  }, [formData[`${tabName}_title`], formData[`${tabName}_description`]]);

  const handleAutoFilledFields = (field: string, value?: string) => {
    if (value) {
      setIgnoreDetailsChange((oldDetails) => ({
        ...oldDetails,
        [field]: true,
      }));
    }
  };

  return { handleAutoFilledFields };
};
