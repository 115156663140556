import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import useStyles from './styles';
import { FileFieldProps } from './types';
import { validateFile } from '../../../utilities/file';
import { getFileType, uploadImage } from './utilities';
import clx from 'classnames';
import { Button } from '../Button/Button';
import { HiPencil } from 'react-icons/hi';
import { MdDeleteForever } from 'react-icons/md';

const FileField: React.FC<FileFieldProps> = ({
  name,
  label,
  url,
  handleChange,
  className,
  isSmallFileField,
  setEmbedAction,
  embedImage,
  setImageUrl,
  imageUrl,
  handleEmbed,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const handleSelect = async (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (true) {
      const { success, url: imageUrl } = await uploadImage(file, setLoading);
      setImageUrl(imageUrl);
      success && handleChange?.(imageUrl, name);
    }
  };

  const handleRemoveImage = () => {
    handleChange?.('', '');
  };
  return (
    <>
      <div className={clx(classes.root, className)}>
        {label && <p className={classes.label}>{label}</p>}
        <div
          className={clx(
            classes.input_parent,
            isSmallFileField && classes.fileFieidMd
          )}
          style={{ height: imageUrl ? '100%' : '100%' }}
        >
          {url ? (
            <div>
              {getFileType(url) == 'image' ? (
                <img
                  src={url}
                  alt="uploaded feature image"
                  className={classes.uploaded_img}
                />
              ) : (
                <video src={url} className={classes.uploaded_img} controls />
              )}

              <div className={classes.icons_section}>
                <div className={classes.icon_bg} onClick={handleRemoveImage}>
                  <MdDeleteForever color="white" />
                </div>
                <div>
                  <div
                    className={classes.icon_bg}
                    onClick={() => {
                      setEmbedAction(name);
                    }}
                  >
                    <HiPencil color="white" />
                  </div>
                </div>
              </div>
              {loading && (
                <CircularProgress
                  color="secondary"
                  className={classes.loader}
                  size={20}
                />
              )}
            </div>
          ) : !embedImage || imageUrl ? (
            <>
              {!imageUrl && !url ? (
                !loading ? (
                  <Button
                    className={clx(
                      classes.upload_btn,
                      isSmallFileField && classes.uploadBtnMd
                    )}
                  >
                    <input
                      type="file"
                      accept="image/*,video/*"
                      onChange={handleSelect}
                      className={classes.input_field}
                    />
                    Uploaded Image
                  </Button>
                ) : (
                  <CircularProgress
                    color="secondary"
                    className={classes.loader}
                    size={20}
                  />
                )
              ) : (
                <div>
                  {!loading ? (
                    <Button
                      className={clx(
                        classes.upload_btn,
                        isSmallFileField && classes.uploadBtnMd
                      )}
                    >
                      <input
                        type="file"
                        accept="image/*,video/*"
                        onChange={handleSelect}
                        className={classes.input_field}
                      />
                      Change Image
                    </Button>
                  ) : (
                    <CircularProgress
                      color="secondary"
                      className={classes.loader}
                      size={20}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {!loading ? (
                <Button
                  className={clx(
                    classes.upload_btn,
                    isSmallFileField && classes.uploadBtnMd
                  )}
                  onClick={() => {
                    setEmbedAction(name);
                  }}
                >
                  Upload Image
                </Button>
              ) : (
                <CircularProgress
                  color="secondary"
                  className={classes.loader}
                  size={20}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileField;
