import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { FETCHPOSTS } from './graphQL';
import useStyles from './styles';
import { PostType } from '../../types/PostType';
import { notifyError } from '../../utilities/notify';
import { videoData } from './data';
import CustomVideoPlayer from 'components/CustomVideoPlayer/CustomVideoPlayer';
import Slider from 'react-slick';

const sites = [
  'All Sites',
  'Twisted',
  'VT',
  'Four Nine',
  'Lovimals',
  'Craft Factory',
];

const OldSocialMediaWidget = () => {
  const [fetchSocialPosts, { data, error, loading }] = useLazyQuery(
    FETCHPOSTS,
    { fetchPolicy: 'network-only' }
  );
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [filters, setFilters] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>('all sites');
  const platforms = ['All Platforms', 'Facebook', 'Instagram', 'Tiktok'];
  const [posts, setPosts] = useState<PostType[]>([]);
  const [displayedPosts, setDisplayedPosts] = useState<PostType[]>([]);

  const getSocialPosts = useCallback(
    (reload: null | boolean = null) => {
      if (loading) {
        return;
      }
      const variables: { [key: string]: string } = {};
      if (posts.length > 0 && !reload) {
        variables.id = posts[posts.length - 1].id;
      }
      fetchSocialPosts({ variables });
    },
    [posts, fetchSocialPosts, loading]
  );

  const filterPosts = useCallback(
    (type: string, value: string, top = true, latestPosts = null) => {
      const allPosts: PostType[] = latestPosts ?? posts;
      let newDisplayedPosts;

      if (type === 'site') {
        if (value === 'all sites') {
          newDisplayedPosts = allPosts;
        } else {
          newDisplayedPosts = allPosts.filter(
            (post) => post.from && post.from.name.toLowerCase() === value
          );
        }
      } else {
        if (value === 'all platforms') {
          newDisplayedPosts = allPosts;
        } else {
          const type = value === 'instagram' ? 'Instagram' : null;
          newDisplayedPosts = allPosts.filter((post) => post.type === type);
        }
      }

      setDisplayedPosts(newDisplayedPosts);
      setActiveFilter(value);
      setFilters(false);
      if (top) {
        socialScrollToTop();
      }
    },
    [posts]
  );

  useEffect(() => {
    getSocialPosts();
  }, []);

  useEffect(() => {
    if (data) {
      setUpdateLoading(false);
      const newPosts: PostType[] = data.socialPosts.posts;
      const { update } = data.socialPosts;
      if (update && newPosts.length >= 100) {
        setPosts([]);
        getSocialPosts(true);
        return;
      }
      const updatedPosts = update
        ? newPosts.concat(posts)
        : posts.concat(newPosts);
      setPosts(updatedPosts);
      const type =
        activeFilter.includes('platform') ||
        activeFilter === 'facebook' ||
        activeFilter === 'instagram' ||
        activeFilter === 'tiktok'
          ? 'platform'
          : 'site';
      filterPosts(type, activeFilter, false, updatedPosts);
    }

    if (error) {
      notifyError('An Error Occured');
      setUpdateLoading(false);
    }
  }, [data, error, activeFilter]);

  const socialScrollToTop = () => {
    const socialPosts = document.getElementById('social-posts');
    if (socialPosts) {
      socialPosts.scrollTop = 0;
    }
  };

  const renderSlides = videoData.map((vid, index) => (
    <CustomVideoPlayer
      key={index}
      title={vid.name}
      videoSource={vid.src}
      autoplay={false}
    />
  ));

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={classes.social}>
      {data && !loading && (
        <div className={classes.social__heading}>
          <div className={classes.titleContainer}>
            <p className={classes.headerTitle}>The Add</p>
          </div>
        </div>
      )}
      <Slider {...settings}>{renderSlides}</Slider>
      <div
        className={`loader ${loading && posts.length === 0 ? 'active' : null}`}
      >
        <CircularProgress color="inherit" size="25px" />
      </div>
    </div>
  );
};

export default OldSocialMediaWidget;
