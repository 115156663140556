import clx from 'classnames';
import { useAutoFill } from 'layouts/createContent/helpers';
import { SocialsFormProps } from './types';
import useSocialStyles from './styles';
import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import { MediaDisplay } from 'components/common/MediaDisplay';
import Preview from 'components/Blog/components/Preview/Preview';
import TextField from 'components/common/TextField/TextField';
import { formHandleText } from 'utilities/form';
import TextArea from 'components/common/TextArea/TextArea';
import { Switch } from '@mui/material';
import { useState } from 'react';

const SocialsForm: React.FC<SocialsFormProps> = ({
  formData,
  setFormData,
  setEmbedAction,
}) => {
  const classes = useSocialStyles();
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});

  const { handleAutoFilledFields } = useAutoFill(
    formData,
    setFormData,
    'social'
  );
  const { handleAutoFilledFields: handleTwitterAutoFilledFields } = useAutoFill(
    formData,
    setFormData,
    'twitter'
  );

  return (
    <>
      <p className={clx(classes.title, classes.schemaTitle)}>Social Preview</p>
      <p className={classes.subText}>
        Used by social networks like Facebook to display text and an image when
        this page is shared.
      </p>
      <div className={classes.selectBox}>
        <p className={clx(classes.title)}>FACEBOOK PREVIEW</p>
        <MediaDisplay
          name={'social_image'}
          formData={formData}
          setFormData={setFormData}
          setEmbedAction={setEmbedAction}
        />
      </div>
      <Preview
        title={decodeHtml(formData?.social_title)}
        description={decodeHtml(formData?.social_description)}
        link={formData?.permalink}
      />
      <div className={classes.selectBox}>
        <p className={classes.label}>Title</p>
        <TextField
          onChange={(e: React.ChangeEvent<any>) => {
            handleAutoFilledFields('title', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          type="text"
          value={decodeHtml(formData?.social_title)}
          name={'social_title'}
          className={classes.textField}
        />
      </div>
      <div className={classes.selectBox}>
        <p className={classes.label}>Description</p>
        <TextArea
          onChange={(e) => {
            handleAutoFilledFields('description', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          value={decodeHtml(formData?.social_description ?? '')}
          name="social_description"
          className={classes.excerptText}
        />
      </div>
      <div className={classes.twitterSubHeading}>
        <p className={classes.same}>Same as Facebook Preview</p>
        <Switch
          name={'same_as_socials'}
          className={classes.switch}
          onChange={(e) => {
            setChecked({ ...checked, same_as_socials: e.target.checked });
            setFormData({
              ...formData,
              same_as_socials: e.target.checked,
            });
          }}
          checked={checked?.same_as_socials}
          value={checked?.same_as_socials}
        />
      </div>
      <p className={clx(classes.title, classes.schemaTitle)}>TWITTER PREVIEW</p>
      <div className={classes.selectBox}>
        <MediaDisplay
          name={'twitter_image'}
          formData={formData}
          setFormData={setFormData}
          setEmbedAction={setEmbedAction}
        />
      </div>
      <Preview
        title={decodeHtml(formData?.twitter_title)}
        description={decodeHtml(formData?.twitter_description)}
        link={formData?.permalink}
      />
      <div className={classes.selectBox}>
        <p className={classes.label}>Title</p>
        <TextField
          onChange={(e: React.ChangeEvent<any>) => {
            handleTwitterAutoFilledFields('title', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          type="text"
          value={decodeHtml(formData?.twitter_title)}
          name={'twitter_title'}
          className={classes.textField}
        />
      </div>
      <div className={classes.selectBox}>
        <p className={classes.label}>Description</p>
        <TextArea
          onChange={(e) => {
            handleTwitterAutoFilledFields('description', e.target.value);
            formHandleText(e, setFormData, formData);
          }}
          value={decodeHtml(formData?.twitter_description)}
          name="twitter_description"
          className={classes.excerptText}
        />
      </div>
    </>
  );
};

export default SocialsForm;
