import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/auth';
import { Button } from '../common/Button/Button';
import useStyles from './styles';
import { get } from 'lodash';
import { CircularProgress, Modal, Box } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { GET_REDIRECTS, DELETE_REDIRECT } from './graphql';
import { selectFilter, searchFilter } from '../../utilities/filter';
import exportToCsv from '../../utilities/export';
import { notifySuccess } from '../../utilities/notify';
import TextField from '../common/TextField/TextField';
import { Select } from '../common/Select/Select';
import { BsDownload } from 'react-icons/bs';
import { SchemaProps } from '../common/Table/types';
import Table from '../common/Table/Table';
import clx from 'classnames';
import CreateRedirect from 'components/CreateRedirect/CreateRedirect';

const ViewRedirect = () => {
  const classes = useStyles();
  const [paginator, setPaginator] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(7);
  const [redirectText, setRedirectText] = useState<string>('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showActions, setShowActions] = useState<number | string | null>(null);
  const [redirectFilter, setRedirectFilter] = useState<string>('all');
  const [tableArr, setTableArr] = useState<{ [key: string]: any }[]>([]);
  const [displayedTableArr, setDisplayedTableArr] = useState<
    { [key: string]: any }[]
  >([]);
  const { data, loading, error } = useQuery(GET_REDIRECTS, {
    variables: {
      page,
      first: rows,
    },
    fetchPolicy: 'network-only',
  });
  const [deleteRedirectMutation] = useMutation(DELETE_REDIRECT);
  const navigate = useNavigate();
  const urlFilters = {
    'All URL Redirects': 'all',
    'VT Redirects': 'vt',
    'Twisted Redirects': 'twistedfood',
    'Fournine Redirects': 'fournine',
  };
  const userFilters = ['All Users'];
  const { toggleSpinner, editRedirect } = useContext(AuthContext);
  type RedirectOject = { [key: string]: any };
  const sites: { [key: string]: string } = {
    vt: 'https://vt.co/',
    twistedfood: 'https://twistedfood.co.uk/',
    fournine: 'https://fournine.net/',
    craftfactory: 'https://craftfactory.com/',
    thewild: 'https://thewild.agency/',
  };

  useEffect(() => {
    if (data) {
      const redirectsData: { [key: string]: any }[] = data?.redirects?.data;
      const redirects: { [key: string]: any }[] = [];
      redirectsData.forEach(
        ({ id, source, target, site, type, user: { name } }) => {
          redirects.push({ id, source, target, site, type, user: name });
        }
      );
      const paginatorInfo = data?.redirects?.paginatorInfo;
      setPaginator(paginatorInfo);
      setTableArr(redirects);
      setDisplayedTableArr(redirects);
    }
  }, [data, error]);

  const actionsHandler = (
    event: React.ChangeEvent<{ name?: string; value: string }>,
    id: number
  ) => {
    const value = event.target.value;
    if (value === 'Delete') {
      deleteRedirect(id);
    }

    if (value === 'Edit') {
      const editData = tableArr.find((y) => y.id === id) as RedirectOject;
      editRedirect(editData);
      navigate(`/admin/redirects/edit/${id}`);
    }
  };

  const filterRedirects = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      const newDisplayedTableArr = selectFilter(
        tableArr,
        'site',
        event.target.value
      );
      setDisplayedTableArr(newDisplayedTableArr);
      setRedirectFilter(event.target.value);
    },
    [tableArr]
  );

  const updateRedirects = useCallback(
    (id: number) => {
      let idx = tableArr.findIndex(
        (redirect) => redirect.id.toString() === id.toString()
      );
      const newTableArr = [...tableArr];
      newTableArr.splice(idx, 1);
      setTableArr(newTableArr);
      idx = displayedTableArr.findIndex(
        (redirect) => redirect.id.toString() === id.toString()
      );
      const newDisplayedTableArr = [...displayedTableArr];
      newDisplayedTableArr.splice(idx, 1);
      setDisplayedTableArr(newDisplayedTableArr);
    },
    [tableArr, displayedTableArr]
  );

  const deleteRedirect = async (id: number) => {
    const proceed = window.confirm('are you sure you want to delete ?');
    if (!proceed) {
      return;
    }
    const variables = { id };
    toggleSpinner(true);
    try {
      await deleteRedirectMutation({ variables });
      notifySuccess('Redirect Deleted Successfully');
      updateRedirects(variables.id);
    } catch (error) {
      //
    } finally {
      toggleSpinner(false);
    }
  };

  const download = () => {
    const tableArr = [...displayedTableArr];
    tableArr.forEach((redirect) => {
      delete redirect.__typname;
      delete redirect.id;
    });
    let name = redirectFilter;
    name =
      redirectText.length > 0
        ? `${name}_${redirectText}_redirects`
        : `${name}_redirects`;
    exportToCsv(name, displayedTableArr);
  };

  const schema: SchemaProps[] = [
    {
      label: 'Original Url',
      func: ({ item, uniqueId }) => {
        const site = sites[get(item, 'site')];
        const source = get(item, 'source');
        const url = site + source;
        return (
          <div
            className={classes.titleParent}
            onMouseEnter={() => setShowActions(uniqueId)}
          >
            <p className={classes.itemTitle}>{url}</p>
            <div
              className={clx(
                classes.actions,
                showActions === uniqueId && classes.actionActive
              )}
            >
              <p
                className={classes.edit}
                onClick={() => {
                  const id = get(item, 'id');
                  setOpenCreateModal(true);
                }}
              >
                Edit
              </p>
              <p className={clx(classes.edit, classes.editBorder)}>Delete</p>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Redirecting To',
      func: ({ item }) => {
        const site = sites[get(item, 'site')];
        const target = get(item, 'target');
        return site + target;
      },
    },
    {
      label: 'Type',
      func: ({ item }) => get(item, 'type'),
    },
    {
      label: 'User',
      func: ({ item }) => get(item, 'user'),
    },
  ];

  return (
    <>
      <div className={classes.titleWidget}>
        <p className={classes.title}>Redirects</p>
        <div className={classes.searchBox}>
          <Button
            title="Create Redirect"
            onClick={() => setOpenCreateModal(true)}
          />
        </div>
      </div>

      <div className={classes.main}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size="30px" />
          </div>
        ) : (
          <>
            <div className={classes.mainTop}>
              <div className={classes.mainTopLeft}>
                <div className={classes.filter}>
                  <p>Display: </p>
                  <div>
                    <Select
                      noStyle={true}
                      options={urlFilters}
                      filter={true}
                      onChange={filterRedirects}
                    />
                  </div>
                </div>
                <div className={classes.filter}>
                  <p>Created by: </p>
                  <div>
                    <Select
                      noStyle={true}
                      options={userFilters}
                      filter={true}
                    />
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  download();
                }}
                className={classes.mainTopRight}
              >
                <BsDownload />
                <p
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                  }}
                >
                  Export
                </p>
              </div>
            </div>
            <Table
              schema={schema}
              collection={displayedTableArr}
              pagination={true}
              paginatorInfo={paginator}
              rowsPerPage={rows}
              changePage={(e) => setPage(e + 1)}
              changeRow={(e) => setRows(e + 1)}
            />
          </>
        )}
      </div>
      <Modal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onBackdropClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.box}>
          <CreateRedirect handleClose={() => setOpenCreateModal(false)} />
        </Box>
      </Modal>
    </>
  );
};

export default ViewRedirect;
