import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  input_parent: {
    position: 'relative',
    width: '100%',
  },
  select: {
    height: '100%',
    fontSize: theme.typography.fontSize,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '30%',
    zIndex: 10,
  },
  input: {
    width: '100%',
    paddingLeft: '30%',
    fontSize: '12px',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
}));

export default useStyles;
