import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  eventsParent: {
    backgroundColor: theme.design.colors.mintGreen,
    padding: theme.spacing(2),
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
  },

  event: {
    marginBottom: theme.spacing(1),
    borderRadius: 16,
    background: theme.design.colors.white,
    padding: theme.spacing(1, 2.5),
    cursor: 'pointer',
    '& P': {
      fontFamily: 'Universal !important',
    },
  },
  eventDateMonths: {
    fontSize: theme.typography.fontSize - 2,
    fontWeight: theme.typography.fontWeightRegular,
  },
  eventTitle: {
    fontSize: theme.typography?.fontSize + 4,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    fontFamily: 'Feature',
    justifyContent: 'space-between',
    '& span': {
      fontSize: theme.typography.fontSize - 1,
      fontFamily: 'sans-serif',
    },
  },
  eventHeader: {
    display: 'flex',
    gap: '3px',
    width: '80%',
    '& img': {
      height: 18,
    },
  },
  eventDate: {
    background: theme.design.colors.mintGreen,
    padding: theme.spacing(1),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    fontSize: theme.typography.fontSize + 2,
  },
  dot: {
    height: 6,
    width: 6,
    borderRadius: '50%',
    background: theme.design.colors.pink100,
  },
  eventBox: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventDescription: {
    margin: '10px 0',
    paddingTop: '10px',
    borderTop: `1px solid ${theme.design.colors.lidGrey}`,
    fontSize: theme.typography.fontSize - 1,
    fontweight: theme.typography.fontWeightMedium,
  },
  eventContainer: {
    marginTop: theme.spacing(1.5),
  },
  upcoming: {
    fontSize: theme.typography.fontSize - 4,
    color: theme.design.colors.greyDark,
    textTransform: 'uppercase',
  },
  evenTitle: {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.design.colors.grey100,
    marginTop: theme.spacing(0.5),
  },
}));

export default useStyles;
