import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import axios from 'axios';
import { AppStateContext } from '../../contexts/provider';
import { weather } from './weather';

const WelcomeWidget = () => {
  const classes = useStyles();
  const [icon, setIcon] = useState('');
  const [temp, setTemp] = useState('');
  const { user } = useContext(AppStateContext);
  const res = user.name.split(' ');
  const options: any = { weekday: 'long', hour: 'numeric', minute: 'numeric' };
  const dateTime = new Date().toLocaleDateString('en-GB', options);

  const getWeather = async () => {
    await axios
      .get(
        `https://api.weatherapi.com/v1/current.json?key=b9c79bc32d4d4ff688c100701212405&q=auto:ip`
      )
      .then(({ data }) => {
        const time = data.current.condition.icon.includes('day');
        const icon = weather.filter(
          (weather) =>
            weather.name === data.current.condition.text && weather.day === time
        );
        if (icon.length !== 0) {
          const weatherUrl = icon[0].url as string;
          setIcon(weatherUrl);
        } else {
          setIcon(data.current.condition.icon);
        }

        setTemp(data.current.temp_c);
      })
      .catch(() => {
        //
      });
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.info}>
          <p className={classes.temperature}>
            {temp}
            <span>&#8451;</span>
          </p>
          <p className={classes.date}>{dateTime.toUpperCase()}</p>
        </div>
        <div className={classes.image_section}>
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/02/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/vuesax-bulk-sun.png_P8HESM'
            }
            alt="weather icon"
          />
        </div>
      </div>
      <div className={classes.firstname}>
        <p>{res[0]}</p>
        <span className={classes.lastname}>{res[1]}</span>
      </div>
    </div>
  );
};

export default WelcomeWidget;
