import { gql } from '@apollo/client';

export const FETCHNEWSLETTERS = gql`
  query getNewsletter {
    newsletters {
      data {
        id
        title
        url
        image
        date
      }
    }
  }
`;
