import Blog from '../../components/Blog/Blog';
import CreateContent from '../../components/Blog/components/CreateContent/CreateContent';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import VtTacoBells from '../../components/VtTacoBells/VtTacoBells';
import FournineRevolution from '../../components/FournineRevolution/FournineRevolution';
import InstantArticles from '../../components/InstantArticles/InstantArticles';
import Facebook from '../../components/Facebook/Facebook';
import PushNotifications from '../../components/PushNotifications/PushNotifications';
import AppleNews from '../../components/AppleNews/AppleNews';
import { RiTeamLine, RiTodoLine, RiFacebookCircleLine } from 'react-icons/ri';
import { GiPhotoCamera, GiPlatform, GiTacos } from 'react-icons/gi';
import { IoMdContact } from 'react-icons/io';
import { BsBell } from 'react-icons/bs';
import { AiOutlineApple, AiOutlineHome } from 'react-icons/ai';
import TwistedfoodPage from '../../components/TwistedfoodPages/Pages/Pages';
import VTPage from '../../components/VTPages/Pages';
import Mrss from '../../components/Mrss/Mrss';
import MediaLibrary from './../../components/Media/MedialLibrary';
import { MdCached, MdOutlinePermMedia } from 'react-icons/md';

export const tabs: { [key: string]: any }[] = [
  {
    name: 'Posts',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-receipt-edit.svg',
    key: 'post',
    children: [
      {
        name: 'All Posts ',
        route: '/cms/posts',
        key: '/cms/posts',
      },
      {
        name: 'Categories ',
        route: '/cms/category',
        key: '/cms/category',
      },
    ],
  },
  {
    name: 'MRSS',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-document-text.svg',
    key: 'mrss',
    children: [
      {
        name: 'Create/Update Mrss',
        icon: <GiPlatform className="icon" />,
        route: '/cms/create-mrss',
        key: 'cms/create-mrss',
      },
    ],
  },
  {
    name: 'Pages',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-book.svg',
    key: 'pages',
    children: [
      {
        name: 'Fournine Revolution',
        icon: <GiPlatform className="icon" />,
        route: '/cms/fournine-revolution',
        key: 'cms/fournine-revolution',
        site: 'fournine',
      },
      {
        name: 'Home',
        icon: <AiOutlineHome className="icon" />,
        route: '/cms/twisted/home',
        key: 'cms/home',
        site: 'twistedfood',
      },
      {
        name: 'Best of Guides',
        icon: <RiTodoLine className="icon" />,
        key: 'guides',
        site: 'twistedfood',
        children: [
          {
            name: 'Overview',
            route: '/cms/twisted/guides/overview',
            key: 'recipes/overview',
            site: 'twistedfood',
          },
        ],
      },
      {
        name: 'Recipes',
        icon: <RiTodoLine className="icon" />,
        key: 'recipes',
        site: 'twistedfood',
        children: [
          {
            name: 'Overview',
            route: '/cms/twisted/recipes/overview',
            key: 'recipes/overview',
            site: 'twistedfood',
          },
          {
            name: 'Sub-category',
            route: '/cms/twisted/recipes/breakfast',
            key: [
              'recipes/breakfast',
              'recipes/sandwiches',
              'recipes/chicken',
              'recipes/vegetarian',
              'recipes/twisted-green',
              'recipes/spicy',
              'recipes/pasta',
              'recipes/dessert',
            ],
            site: 'twistedfood',
          },
        ],
      },
      {
        name: 'Lifestyle',
        icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-book.svg',
        key: 'lifestyle',
        site: 'twistedfood',
        children: [
          {
            name: 'Overview',
            route: '/cms/twisted/lifestyle/overview',
            key: 'lifestyle/overview',
            site: 'twistedfood',
          },
          {
            name: 'Sub-category',
            route: '/cms/twisted/lifestyle/trending',
            key: [
              'lifestyle/trending',
              'lifestyle/features',
              'lifestyle/opinion',
              'lifestyle/interview',
              'lifestyle/news',
            ],
            site: 'twistedfood',
          },
        ],
      },
      {
        name: 'Shows',
        icon: <GiPhotoCamera className="icon" />,
        key: 'shows',
        site: 'twistedfood',
        children: [
          {
            name: 'Overview',
            route: '/cms/twisted/shows/overview',
            key: 'shows/overview',
            site: 'twistedfood',
          },
          {
            name: 'Sub-category',
            route: '/cms/twisted/shows/bake-in',
            key: [
              'shows/bake-in',
              'shows/shelf-life',
              'shows/hangin-with',
              'shows/food-palace',
            ],
            site: 'twistedfood',
          },
        ],
      },
    ],
  },
  {
    name: 'Pages',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/book.svg',
    key: 'vtpages',
    children: [
      {
        name: 'Home',
        icon: <AiOutlineHome className="icon" />,
        route: '/cms/vt/home',
        key: 'cms/vt/home',
        site: 'vt',
      },
      {
        name: 'Meet the teams',
        icon: <RiTeamLine className="icon" />,
        key: 'cms/vt/team',
        site: 'vt',
        route: '/cms/vt/team',
      },
      {
        name: 'Shows Page',
        icon: <GiPhotoCamera className="icon" />,
        route: '/cms/vt/shows',
        key: 'cms/vt/shows',
        site: 'vt',
      },
      {
        name: 'Entertainment',
        icon: <GiPhotoCamera className="icon" />,
        route: '/cms/vt/entertainment',
        key: 'cms/vt/entertainment',
        site: 'vt',
      },
      {
        name: 'News',
        icon: <GiPhotoCamera className="icon" />,
        route: '/cms/vt/news',
        key: 'cms/vt/news',
        site: 'vt',
      },
      {
        name: 'Lifestyle',
        icon: <GiPhotoCamera className="icon" />,
        route: '/cms/vt/lifestyle',
        key: 'cms/vt/lifestyle',
        site: 'vt',
      },
    ],
  },
  {
    name: 'Social Integrations',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(3).svg',
    key: 'integrations',
    children: [
      {
        name: 'Instant Articles',
        icon: <RiFacebookCircleLine className="icon" />,
        route: '/cms/instant-articles',
        key: 'cms/instant-articles',
      },
      {
        name: 'Push Notifications',
        icon: <BsBell className="icon" />,
        route: '/cms/push-notifications',
        key: 'cms/push-notifications',
      },
      {
        name: 'Apple News',
        icon: <AiOutlineApple className="icon" />,
        route: '/cms/apple-news',
        key: 'cms/apple-news',
      },
      {
        name: 'Facebook',
        icon: <RiFacebookCircleLine className="icon" />,
        route: '/cms/facebook',
        key: 'cms/facebook',
      },
    ],
  },
  {
    name: 'Form Results',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-message-question.svg',
    key: 'forms',
    children: [
      {
        name: 'TheWild Contact',
        icon: <IoMdContact className="icon" />,
        route: '/cms/contact-details',
        key: 'cms/contact-details',
        site: 'thewild',
      },
      {
        name: 'VT Taco Bell',
        icon: <GiTacos className="icon" />,
        route: '/cms/taco-bell',
        key: 'cms/taco-bell',
        site: 'vt',
      },
    ],
  },
  {
    name: 'Forms',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-message-question.svg',
    key: 'hive-forms',
    route: '/cms/forms',
    isParent: true,
    site: 'junglecreations',
  },
  {
    name: 'Media Library',
    icon: <MdOutlinePermMedia />,
    key: 'media',
    children: [
      {
        name: 'All Medias',
        route: '/cms/media-library',
        key: '/cms/media-library',
      },
    ],
  },
  {
    name: 'Redirects',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/vuesax-twotone-document-text.svg',
    key: 'redirects',
    children: [
      {
        name: 'View redirects',
        route: '/cms/redirects',
        key: '/cms/redirects',
      },
    ],
  },
  {
    name: 'Clear Cache',
    icon: <MdCached size={20} />,
    route: '/cms/clear-cache',
    key: 'cache',
    isParent: true,
  },
];

export const routes: {
  route: string;
  component: JSX.Element;
  site?: string;
}[] = [
  {
    route: '/cms/posts',
    component: <Blog />,
  },
  {
    route: '/cms/posts/create',
    component: <CreateContent />,
  },
  // {
  //   route: '/cms/posts/:id/edit',
  //   component: <CreateContent />,
  // },
  {
    route: '/cms/instant-articles',
    component: <InstantArticles />,
  },
  {
    route: '/cms/facebook',
    component: <Facebook />,
  },
  {
    route: '/cms/push-notifications',
    component: <PushNotifications />,
  },
  {
    route: '/cms/apple-news',
    component: <AppleNews />,
  },
  {
    route: '/cms/create-mrss',
    component: <Mrss />,
  },
  {
    route: '/cms/fournine-revolution',
    component: <FournineRevolution />,
    site: 'fournine',
  },
  {
    route: '/cms/contact-details',
    component: <ContactDetails />,
    site: 'thewild',
  },
  {
    route: '/cms/taco-bell',
    component: <VtTacoBells />,
    site: 'vt',
  },
  // tiwsted routes
  {
    route: '/cms/twisted/home',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/best-of-guides/overview',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/overview',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/breakfast',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/sandwiches',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/chicken',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/vegetarian',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/twisted-green',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/spicy',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/pasta',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/recipes/dessert',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/overview',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/trending',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/features',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/opinion',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/interview',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/lifestyle/news',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/shows/overview',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/shows/bake-in',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/shows/shelf-life',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/shows/hangin-with',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  {
    route: '/cms/twisted/shows/food-palace',
    component: <TwistedfoodPage />,
    site: 'twistedfood',
  },
  // Vt page route
  {
    route: '/cms/vt/home',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/vt/shows',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/vt/team',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/vt/entertainment',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/vt/lifestyle',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/vt/news',
    component: <VTPage />,
    site: 'vt',
  },
  {
    route: '/cms/media-library',
    component: <MediaLibrary />,
  },
];
