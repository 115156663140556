import { useStyles } from './styles';
import clx from 'classnames';
import { useEffect, useRef, useState } from 'react';

export const SectionCard = ({
  titles,
}: {
  titles: { name: string; route: string }[];
}) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [, setScrollPosition] = useState(0);

  const handleScroll = () => {
    if (containerRef.current) {
      setScrollPosition(containerRef.current.scrollLeft as any);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={containerRef} className={classes.Scroller}>
      {titles.map((title, index: number) => (
        <a
          className={clx(
            classes.title,
            location.pathname.includes(title?.route) && classes.activeTitle
          )}
          key={index}
          href={title?.route}
        >
          {title?.name}
        </a>
      ))}
    </div>
  );
};
