import { FC, ReactNode } from 'react';
import { useAdminDashboardStyles } from './styles';
import { WidgetWrapperProps } from './types';

export const WidgetWrapper: FC<WidgetWrapperProps> = ({
  children,
  title,
  subtitle,
}) => {
  const classes = useAdminDashboardStyles();
  return (
    <div className={classes.right_section}>
      <div className={classes.weatherTitleHeader}>
        <p className={classes.title}>{title}</p>
        {subtitle}
      </div>
      <hr className={classes.divider} />
      {children}
    </div>
  );
};
