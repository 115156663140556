import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useClearCacheStyles = makeStyles((theme: BaseTheme) => ({
  cache_form: {
    backgroundColor: theme.design.colors.white,
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(1),
  },
  content: {
    width: '100%',
    minHeight: '100vh',
  },
  content__title: {
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  content__form: {
    background: '#fff',
    padding: '2.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  content__form_section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(2),
  },
  content__formSelect: {
    flex: 0.3,
  },
  content__formText: {
    width: "calc(100% - 24px)",
    // flex: 1,
  },
  content__form_label: {
    marginBottom: theme.spacing(1),
  },
  content__form_error: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  content__form_actions: {
    margin: '4.25rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(394px + 2.5rem)',
  },
  table_section: {
    height: '666px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    marginTop: '40px',
    padding: '20px',
  },
  buttonAlign: {
    marginTop: `${theme.spacing(2.5)} !important`,
  },
}));
