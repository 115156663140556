import { notifyError } from '../utilities/notify';

export const validateFile = (file: File) => {
  const allowedTypes = ['jpeg', 'jpg', 'png', 'webp'];
  const type = file.type.split('/')[1].toLowerCase();

  if (!allowedTypes.includes(type)) {
    notifyError('Invalid Image Format');
    return false;
  }

  if (file.size > 2000000) {
    notifyError('Image is larger than 2MB');
    return false;
  }

  return true;
};
