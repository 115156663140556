import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  survey: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 4.5rem 0',
  },
  survey__title: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'bold',
    marginBottom: '2.5rem',
  },
  survey__form: {
    padding: '2.5rem',
    background: '#fff',
    borderRadius: '10px',
  },
  survey__form_section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2.5rem',
    width: 'calc(50% - 1.25rem)',
  },
  survey__form_label: {
    fontSize: '1.125rem',
    marginBottom: '1.25rem',
  },
  survey__form_error: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  survey__check_parent: {
    margin: '7px 0 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  survey__option: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 1.25rem 0',
  },
  survey__option_circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '2px solid #666666',
    margin: '0 0.75rem 0 0',
  },
  survey__option_remove: {
    display: 'flex',
    cursor: 'pointer',

    '& p': {
      margin: '0 0 0 0.65rem',
    },
  },
  survey__option_add: {
    borderBottom: '1px solid #000',
    padding: '0 0 1px 0',
    margin: '4px 0 0 32px',
    width: 'fit-content',
    cursor: 'pointer',
  },
  survey__duration_parent: {
    width: '275px',
    position: 'relative',

    '& .select': {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50%',
      zIndex: 10,
    },
  },
  survey__duration_input: {
    width: '100%',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  survey__submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '4.25rem 0 0 0',
  },
}));

export default useStyles;
