import { Modal, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import Loader from 'components/common/Loader/Loader';
import { Button } from '../../../common/Button/Button';
import { FC } from 'react';
import useCategoryStyles from './styles';
import { DialogDeleteProps } from './type';

export const DialogCancel: FC<DialogDeleteProps> = ({
  openDeleteModal,
  handleClose,
  onCancel,
  setOpenDeleteModal,
  loading,
}) => {
  const classes = useCategoryStyles();
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleClose}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <Box className={classes.box}>
        <div className={classes.deleteModal}>
          <div className={classes.header}>
            <p className={classes.deleteText}>Cancel purchase order</p>
            <Close className={classes.closeIcon} onClick={handleClose} />
          </div>
          <div className={classes.subTitle}>
            Are you sure you want to cancel this purchase order request
          </div>
          <div className={classes.actionButton}>
            <div style={{ position: 'relative' }}>
              <Button title="Yes, cancel request" onClick={onCancel} />
              <Loader display={loading ?? false} className={classes.loader} />
            </div>
            <Button
              title="No, continue editing"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className={classes.noBtn}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
