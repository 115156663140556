import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useCalendarStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    '&.MuiDateCalendar-root': {
      minWidth: '240px !important',
      maxHeight: '280px',
    },
    '& .MuiPickersArrowSwitcher-root': {
      flexDirection: 'row-reverse',
    },
    '& .MuiPickersArrowSwitcher-spacer': {
      width: 0,
    },
    '& .MuiPickersDay-root': {
      fontSize: theme.typography?.fontSize - 2,

      '&.Mui-selected': {
        background: theme.design.colors.black,
      },
    },
    '& .MuiDayCalendar-header': {
      height: '15px',
      fontSize: theme.typography?.fontSize - 2,
    },
  },
}));
