import { GET_ALL_USERS } from 'components/Blog/graphQL';
import { parseDate } from '../../../utilities/date';
import * as Yup from 'yup';
import { blogClient } from 'utilities/apolloClient';

export type FormBuilderType = {
  type: string;
  label?: string;
  key: string;
  required?: boolean;
  config?: any;
  disabled?: boolean;
}[];

export const initialSection: FormBuilderType = [
  {
    type: 'combobox',
    label: 'Requestor name',
    key: 'requestor_id',
    required: true,
    config: {
      name: GET_ALL_USERS,
      client: blogClient,
      labelKey: 'name',
      valueKey: 'id',
      key: ['getAllUsers'],
    },
  },
  {
    type: 'combobox',
    label: 'Requestor job title',
    key: 'requestor_id',
    disabled: true,
    config: {
      name: GET_ALL_USERS,
      client: blogClient,
      labelKey: 'role',
      valueKey: 'id',
      key: ['getAllUsers'],
    },
  },
  {
    type: 'combobox',
    label: 'Requestor department',
    key: 'requestor_id',
    disabled: true,
    config: {
      name: GET_ALL_USERS,
      client: blogClient,
      labelKey: 'department',
      valueKey: 'id',
      key: ['getAllUsers'],
    },
  },
  {
    type: 'date',
    label: 'Purchase date',
    key: 'date',
    required: true,
  },
  {
    type: 'radio',
    label: 'Is this in the current budget ?',
    key: 'isBudgetedFor',
    required: true,
  },
  {
    type: 'text',
    label: 'Vendor Name',
    key: 'vendorName',
    required: true,
  },
  {
    type: 'text',
    label: 'Vendor Contact Details',
    key: 'vendorContactDetails',
    required: true,
  },
];

export const finalSection: FormBuilderType = [
  {
    type: 'items',
    key: 'items',
  },
  {
    type: 'text',
    label: 'Total cost of order',
    key: 'price',
  },
  {
    type: 'textarea',
    label: 'What is the business requirement',
    key: 'requirement',
  },
];

export const initialValues = {
  date: parseDate(new Date()),
  isBudgetedFor: true,
  approved: false,
  vendorName: '',
  vendorContactDetails: '',
  requirement: '',
  items: [''],
  price: 0,
  status: 'requested',
  acc_approved: false,
  poAuthoriser: "Katie O'reily",
  poNumber: '',
  created_at: '',
  requestorDepartment: '',
  id: null,
};

export const validationSchema = Yup.object().shape({
  vendorName: Yup.string().required(' Vendor name is required'),
  vendorContactDetails: Yup.string().required(
    'Vendor Contact Details is required'
  ),
  requirement: Yup.string().required('Requirement is required'),
  price: Yup.number().min(300, 'Price must be greater than 300'),
  items: Yup.array().of(Yup.string().required('Item is required')),
  requestorName: Yup.string().required(' Requestor name is required'),
});
