import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '../common/TextField/TextField';
import { FETCHPAGE, UPDATEPAGE } from './graphQL';
import AuthContext from '../../contexts/auth';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import useStyles from '../Blog/components/CreateContent/styles';
import { Button } from '../common/Button/Button';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import { notifyError, notifySuccess } from '../../utilities/notify';
import { ValidationProps, isRequired } from '../../utilities/validation';
import { plugins, toolbar, uploadImage } from '../../utilities/tiny';
import clx from 'classnames';
import { Editor } from '@tinymce/tinymce-react';
import { WysiwygInput } from '@wazobia-tech/wazcom/dist/io';

const EditPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { toggleSpinner } = useContext(AuthContext);
  const params = useParams();
  const { data } = useQuery(FETCHPAGE, {
    variables: { slug: params.id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [editPageMutation, { loading }] = useMutation(UPDATEPAGE);
  const [fullLoading, setFullLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: any }>({});
  const [embed, setEmbed] = useState<{ type: string; value: any }>();
  const [embedAction, setEmbedAction] = useState<string>('');

  useEffect(() => {
    toggleSpinner(true);

    return () => {
      toggleSpinner(false);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const { id, title, slug: url, content } = data?.content;
      setFormData({ ...formData, id, title, url, content });
      toggleSpinner(false);
    }
  }, [data]);

  const handleFieldBlur = useCallback(
    ({ target: { name, value } }: React.FocusEvent<HTMLInputElement>) => {
      const validation: ValidationProps =
        name === 'tags'
          ? {
              status: Boolean(formData?.tags),
              errorText: 'the tags field is required',
            }
          : isRequired({ name, value });

      if (validation.status) {
        const newErrors = { ...formErrors };
        delete newErrors[name];
        setFormErrors(newErrors);
      } else {
        setFormErrors({
          ...formErrors,
          [name]: validation.errorText,
        });
      }
    },
    [formErrors, formData]
  );

  const handleText = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      let formattedValue = value;
      if (name === 'url') {
        formattedValue = value.toLowerCase().replace(/ /g, '-');
      }
      setFormData({ ...formData, [name]: formattedValue });
    },
    [formData]
  );

  const handleChange = (data: { name: string; value: string }) => {
    setFormData((form) => ({
      ...form,
      [data?.name]: data?.value,
    }));
  };

  const updatePage = async () => {
    console.log('formdata', formData);
    if (Object.keys(formData).length < 3) {
      notifyError('All fields are required');
      return;
    }

    const variables: { [key: string]: string } = {
      ...formData,
      slug: formData.url,
    };
    delete variables.url;

    await editPageMutation({ variables })
      .then(() => {
        notifySuccess('Page updated successfully');
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.map(({ extensions, message }) => {
          if ('title' in extensions?.validation) {
            return notifyError(extensions?.validation.title[0]);
          } else {
            return notifyError(message);
          }
        });
      });
  };

  return (
    <div className={classes.content}>
      <FullScreenLoader display={fullLoading} />
      <p className={classes.content__title}>Edit Page</p>
      <form className={classes.content__form}>
        <div className={classes.content__input}>
          <div
            className={clx(classes.content__form_section, classes.fullWidth)}
          >
            <label className={classes.content__form_label}>Title:</label>
            <TextField
              onChange={handleText}
              onBlur={handleFieldBlur}
              type="text"
              value={formData?.title}
              name="title"
            />
            {formErrors?.title && (
              <p className={classes.content__form_error}>{formErrors?.title}</p>
            )}
          </div>
          <div
            className={clx(classes.content__form_section, classes.fullWidth)}
          >
            <label className={classes.content__form_label}>URL:</label>
            <TextField
              onChange={handleText}
              onBlur={handleFieldBlur}
              type="text"
              value={formData?.url}
              name="url"
            />
            {formErrors?.url && (
              <p className={classes.content__form_error}>{formErrors?.url}</p>
            )}
          </div>
        </div>
        <div className={clx(classes.content__form_section, classes.fullWidth)}>
          <label className={classes.content__form_label}>Content:</label>
          {/* @ts-ignore*/}
          <WysiwygInput
            value={{ title: formData?.title, content: formData?.content }}
            onChange={handleChange}
            embed={embed}
            initiateEmbed={(embedAction: string) => {
              setEmbedAction(embedAction);
            }}
            hasTitle={false}
          />
          {formErrors?.content && (
            <p className={classes.content__form_error}>{formErrors?.content}</p>
          )}
        </div>
        <div className={classes.content__form_actions} style={{ margin: 0 }}>
          <Button
            disabled={loading}
            onClick={() => {
              updatePage();
            }}
            title="Update"
          />
        </div>
      </form>
    </div>
  );
};

export default EditPage;
