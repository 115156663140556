import React, { useCallback, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { useFormik } from 'formik';
import { Button } from '../../common/Button/Button';
import Loader from '../../common/Loader/Loader';
import {
  initialSection,
  finalSection,
  initialValues,
  validationSchema,
} from './data';
import FormSection from './FormSection';
import {
  CREATEPURCHASEORDER,
  GET_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from './graphQL';
import { useLazyQuery, useMutation } from '@apollo/client';
import { notifySuccess } from 'utilities/notify';
import { AppStateContext } from 'contexts/provider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { blogClient } from 'utilities/apolloClient';
import { DialogCancel } from './CancelDialog/Dialog';

const PurchaseOrder = () => {
  const classes = useStyles();
  const [createPurchaseOrderMutation, { loading: createLoading }] =
    useMutation(CREATEPURCHASEORDER);
  const [updatePurchaseOrderMutation, { loading: updateLoading }] = useMutation(
    UPDATE_PURCHASE_ORDER
  );
  const { user } = useContext(AppStateContext);
  const { uuid } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [getContent, { loading: contentLoading }] = useLazyQuery(
    GET_PURCHASE_ORDER,
    {
      variables: {
        uuid,
      },
      client: blogClient,
      fetchPolicy: 'network-only',
    }
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      requestorName: user.name,
      requestorJobTitle: user.role,
      requestor_id: user.id.toString(),
      id: null,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const {
        date: purchase_date,
        vendorName: vendor_name,
        vendorContactDetails: vendor_contact,
        approved: slt_approved,
        isBudgetedFor: current_budget,
        requirement,
        items,
        price: total,
        requestor_id,
        status,
      } = values;
      const variables = {
        purchase_date,
        vendor_name,
        vendor_contact,
        slt_approved,
        current_budget,
        requirement,
        items,
        total: Number(total),
        requestor_id,
        status,
      };
      try {
        if (uuid) {
          await updatePurchaseOrderMutation({
            variables: {
              ...variables,
              id: values.id,
            },
          });
          notifySuccess('Request Updated Successfully');
        } else {
          await createPurchaseOrderMutation({ variables });
          notifySuccess('Request Made Successfully');
        }
        formik.resetForm();
      } catch (error) {
        //
      }
    },
  });

  const addItems = () => {
    const values = { ...formik.values };
    const newItems = [...values.items];
    newItems.push('');
    formik.setValues({ ...values, items: newItems });
  };

  const updateItems = useCallback(
    (e, index) => {
      const values = { ...formik.values };
      const newItems = [...values.items];
      newItems[index] = e.target.value;
      formik.setValues({ ...values, items: newItems });
    },
    [formik]
  );

  const deleteItems = useCallback(
    (index) => {
      const { items } = formik.values;
      const newItems = items.filter((_, itemIndex) => index !== itemIndex);
      formik.setValues({ ...formik.values, items: newItems });
    },
    [formik]
  );

  const updateRadioButton = useCallback(
    (key) => {
      const values: { [key: string]: string | number | string[] | boolean } = {
        ...formik.values,
      };
      formik.setValues({ ...formik.values, [key]: !(values[key] as boolean) });
    },
    [formik]
  );

  const updateFormData = (data: Record<string, any>) => {
    formik.setValues({
      ...data,
      date: data.purchase_date,
      vendorName: data.vendor_name,
      vendorContactDetails: data.vendor_contact,
      approved: Boolean(data.slt_approved),
      isBudgetedFor: Boolean(data.current_budget),
      requirement: data.requirement,
      items: data.items,
      price: data.total,
      requestor_id: data.requestor.id,
      requestorName: data.requestor.name,
      requestorJobTitle: data.requestor.position,
      status: data.status,
      id: data.id,
    });
  };

  useEffect(() => {
    if (location.pathname.includes('/edit')) {
      if (state?.row) {
        updateFormData(state.row);
      } else {
        getContent().then((res: any) => {
          updateFormData(res.data.getPurchaseOrderByUuid);
        });
      }
    }
  }, [state, user]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>Purchase Order Request</h3>
        <p className={classes.subtitle}>
          Please only fill this out for purchases over £300
          <br />
          <br />
          We may contact you to go over details of the purchase and we will then
          let you know whether is has been approved or declined. If it is
          approved we will supply you with a purchase order number (PO#) that
          you should provide to the vendor for them to apply to their invoice.
        </p>
      </div>

      <div className={classes.orderMetaInfo}>
        {
          <FormSection
            formik={formik}
            section={initialSection}
            addItems={addItems}
            updateItems={updateItems}
            deleteItems={deleteItems}
            updateRadioButton={updateRadioButton}
          />
        }
      </div>
      <div className={classes.orderItems}>
        {
          <FormSection
            formik={formik}
            section={finalSection}
            addItems={addItems}
            updateItems={updateItems}
            deleteItems={deleteItems}
            updateRadioButton={updateRadioButton}
          />
        }
      </div>
      <div className={classes.orderParent}>
        <form className={classes.form}>
          <div className="section"></div>
          <div className="section"></div>
        </form>

        <div className={classes.actions}>
          <Button
            title="Cancel"
            variant="outlined"
            onClick={() => setOpenDeleteModal(true)}
            className={classes.outlinedButton}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            title={uuid ? 'Update request' : 'Submit request'}
            type="submit"
            loading={createLoading || updateLoading}
          />
        </div>
        <DialogCancel
          openDeleteModal={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          onCancel={() => {
            navigate('/admin/purchase-orders');
          }}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      </div>
    </div>
  );
};

export default PurchaseOrder;
