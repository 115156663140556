import { gql } from '@apollo/client';

export const CREATEPOLL = gql`
  mutation createPoll(
    $title: String!
    $options: [String!]!
    $time: String!
    $is_public: Boolean
  ) {
    createPoll(
      title: $title
      options: $options
      time: $time
      is_public: $is_public
    ) {
      message
      errorId
      poll {
        title
        is_public
      }
    }
  }
`;

export const UPDATE_POLL = gql`
  mutation updatePoll(
    $id: ID!
    $title: String!
    $options: [String!]!
    $time: String!
    $is_public: Boolean
  ) {
    updatePoll(
      id: $id
      title: $title
      options: $options
      time: $time
      is_public: $is_public
    ) {
      message
      errorId
      poll {
        title
        is_public
      }
    }
  }
`;
