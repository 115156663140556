import { useState, FC, useEffect } from 'react';
import { ToggleInput } from '@wazobia-tech/wazcom/dist/io';
import { AiOutlinePlus } from 'react-icons/ai';
import clx from 'classnames';
import { useRelatedPostsStyles } from './styles';
import { SelectPosts } from '../SelectPosts';
import { Post } from '../Post';
import { blogClient } from 'utilities/apolloClient';
import { GET_RELATED_POSTS } from 'components/Blog/graphQL';
import { errorHandler } from 'utilities/notify';
import { ApolloError } from '@apollo/client';
import { CircularProgress } from '@mui/material';

type RelatedPostsprops = {
  formData: any;
  setFormData: any;
};

export const RelatedPosts: FC<RelatedPostsprops> = ({
  formData,
  setFormData,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useRelatedPostsStyles();
  const { related_posts_config } = formData;

  const isRecipe = location.pathname.includes('recipes');
  const isGuide = location.pathname.includes('guides');

  const getRelatedPosts = async (uuids: [String]) => {
    try {
      const { data } = await blogClient.query({
        query: GET_RELATED_POSTS,
        variables: {
          uuids,
        },
      });
      return data?.getBlogPostsByUuids ?? [];
    } catch (error) {
      errorHandler(error as ApolloError);
    }
  };

  const isInvalidRelatedPosts =
    related_posts_config &&
    related_posts_config?.length > 0 &&
    !related_posts_config[0]?.title;

  useEffect(() => {
    handleRelatedPostsCheck();
  }, []);

  const handleRelatedPostsCheck = async () => {
    if (isInvalidRelatedPosts) {
      const new_related_posts_config = await getRelatedPosts(
        related_posts_config
      );
      setFormData((oldFormData: any) => ({
        ...oldFormData,
        related_posts_config: new_related_posts_config,
      }));
    }
  };

  const renderRelatedPosts = () =>
    formData?.related_posts_config?.map((post: any, index: number) => (
      <Post
        key={index}
        {...post}
        isDialog={false}
        formData={formData}
        setFormData={setFormData}
      />
    ));

  const isRelatedPostsAutomated =
    formData?.auto_related_posts === undefined
      ? true
      : formData?.auto_related_posts;

  if (isInvalidRelatedPosts)
    return <CircularProgress size={14} color="success" />;

  return (
    <div className={classes.root}>
      <p className={classes.title}>
        {isRecipe
          ? 'Related Recipe'
          : isGuide
          ? 'Related Guide'
          : 'Related Posts'}
      </p>
      <div className={classes.relatedHeading}>
        <p>
          {isRecipe
            ? 'Automatically add recipes under same category'
            : isGuide
            ? 'Automatically add guides under same category'
            : 'Automatically add posts under same category'}
        </p>
        <ToggleInput
          className={classes.relatedToggle}
          classNames={{ toggleInput: classes.relatedToggleInput }}
          value={isRelatedPostsAutomated}
          onChange={({ value: checked }: { name: string; value: boolean }) => {
            setFormData({ ...formData, auto_related_posts: checked });
          }}
        />
      </div>
      <div>
        {isRelatedPostsAutomated ? (
          <div className={classes.relatedAutoMessage}>
            {isRecipe
              ? 'Three recipes with same category as this will be added under this recipe when you publish'
              : isGuide
              ? 'Three guides with same category as this will be added under this guide when you publish'
              : ' Three posts with same category as this will be added under this post when you publish'}
          </div>
        ) : (
          <p className={classes.relatedDescription}>
            {isRecipe
              ? 'Choose three related recipes to show at the end of this recipe'
              : isGuide
              ? 'Choose three related guides to show at the end of this guide'
              : 'Choose three related posts to show at the end of this post'}
          </p>
        )}
        {!isRelatedPostsAutomated &&
          formData?.related_posts_config?.length > 0 && (
            <div>{renderRelatedPosts()}</div>
          )}
        <div
          className={clx(
            classes.relatedAddAction,
            isRelatedPostsAutomated && classes.relatedAddActionInactive
          )}
          onClick={() => {
            !isRelatedPostsAutomated && setOpen(true);
          }}
        >
          <p className={classes.relatedPlusParent}>
            <AiOutlinePlus />
          </p>
          <p>
            {isRecipe ? 'Add recipes' : isGuide ? 'Add guides' : 'Add posts'}
          </p>
        </div>
      </div>
      <SelectPosts
        open={open}
        handleClose={() => setOpen(false)}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};
