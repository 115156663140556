import { FC, useState, useEffect, useRef, useContext } from 'react';
import { useSelectPostsStyles } from './styles';
import { FaTimes } from 'react-icons/fa';
import { Button, Dialog } from '@wazobia-tech/wazcom/dist/core';
import { TextInput } from '@wazobia-tech/wazcom/dist/io';
import { SelectPostsProps } from './types';
import { CircularProgress } from '@mui/material';
import { Post } from '../Post';
import { BsSearch } from 'react-icons/bs';
import { debounce } from 'lodash';
import { blogClient } from 'utilities/apolloClient';
import { useQuery } from '@apollo/client';
import { GET_BLOG_POSTS, GET_SEARCHED_POSTS } from 'components/Blog/graphQL';
import { AppStateContext } from 'contexts/provider';

export const SelectPosts: FC<SelectPostsProps> = ({
  open,
  handleClose,
  formData,
  setFormData,
}) => {
  const classes = useSelectPostsStyles();
  const [searchedPosts, setSearchedPosts] = useState<[] | undefined>();
  const [suggestedPosts, setSuggestedPosts] = useState([]);
  const [initialPostsConfig, setInitialPostsConfig] = useState(null);
  const {
    site,
    siteDetails: { blog_uuid },
  } = useContext(AppStateContext);

  const updateSearchValue = useRef(
    debounce((search: string) => fetchSearchedPosts(search), 500)
  );
  const getSearchedData = async (search: string) => {
    try {
      const response = await blogClient.query({
        query: GET_SEARCHED_POSTS,
        variables: { blog_uuid, search },
        fetchPolicy: 'network-only',
      });
      setSearchedPosts(response.data.getBlogPostsByBlogUuid.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const { data, loading } = useQuery(GET_BLOG_POSTS, {
    fetchPolicy: 'network-only',
    client: blogClient,
    variables: {
      blog_uuid,
    },
  });

  useEffect(() => {
    setSuggestedPosts(data?.getBlogPostsByBlogUuid?.data);
  }, [data]);

  useEffect(() => {
    if (open) {
      setInitialPostsConfig(formData?.related_posts_config ?? []);
      return;
    }
  }, [open]);

  const fetchSearchedPosts = async (search: string) => {
    if (search.trim() === '') return setSearchedPosts(undefined);
    getSearchedData(search);
  };

  const handleSearchChange = ({
    value: search,
  }: {
    name: string;
    value: string;
  }) => {
    updateSearchValue.current(search);
  };

  const handleDialogClose = () => {
    setFormData({ ...formData, related_posts_config: initialPostsConfig });
    handleClose();
  };

  const renderSearchedPosts = () =>
    searchedPosts?.map((post: any, index) => (
      <Post
        key={index}
        {...post}
        setFormData={setFormData}
        formData={formData}
      />
    ));

  const renderSuggestedPosts = () =>
    suggestedPosts?.map((post: any, index) => (
      <Post
        key={index}
        {...post}
        setFormData={setFormData}
        formData={formData}
      />
    ));

  return (
    <Dialog open={open} handleClose={handleDialogClose}>
      <div className={classes.root}>
        {suggestedPosts ? (
          <>
            <div className={classes.selectHeading}>
              <p className={classes.selectHeadingTitle}>Choose related posts</p>
              <FaTimes
                className={classes.selectHeadingIcon}
                onClick={handleDialogClose}
              />
            </div>
            <TextInput
              leftIcon={<BsSearch className={classes.selectTextInputIcon} />}
              placeholder="Search by title"
              className={classes.selectTextInput}
              onChange={handleSearchChange}
            />
            <div className={classes.selectContainer}>
              {searchedPosts && (
                <div className={classes.selectSearchResults}>
                  <p className={classes.selectTitle}>
                    {searchedPosts.length === 0 && 'NO '} SEARCH RESULTS
                  </p>
                  {renderSearchedPosts()}
                </div>
              )}
              <div className={classes.selectSuggestions}>
                <p className={classes.selectTitle}>SUGGESTIONS</p>
                {renderSuggestedPosts()}
              </div>
            </div>
            <div className={classes.selectButtons}>
              <Button
                onClick={handleClose}
                className={classes.selectButtonPrimary}
              >
                Apply
              </Button>
              <Button
                className={classes.selectButtonSecondary}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <CircularProgress size={14} color="success" />
        )}
      </div>
    </Dialog>
  );
};
