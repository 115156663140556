import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useStyles from 'components/Blog/components/CreateContent/styles';
import { AiOutlinePlus } from 'react-icons/ai';
import { useStylesGuides } from './styles';
import clx from 'classnames';
import ReactQuill from 'react-quill';
import { Header } from './components/Header';
import { CTAs } from './components/CTAs';
import { AppStateContext } from 'contexts/provider';
import {
  getGuideInformation,
  validateGuidesInformation,
  getSeoData,
  getSocialData,
  getTwitterData,
  useGuidesAutosave,
  parseContentRecursive,
} from './helper';
import { CreateTopPicks } from './components/CreateTopPicks';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { GET_GUIDE_BY_UUID, GET_GUIDE_TAGS } from 'components/Blog/graphQL';
import createApolloClient, { blogClient } from 'utilities/apolloClient';
import { createGuide, notify, updateGuide } from '../helpers';
import { notifyError, notifySuccess } from 'utilities/notify';
import { EmbedImage } from 'components/Blog/components/CreateContent/components/EmbedImage';
import {
  decodeHtml,
  parseRecursive,
} from 'components/Blog/components/CreateContent/helpers';
import { FAQ } from './components/FAQ';
import {
  CategoryForm,
  CreateContentLayout,
  DetailsForm,
  GUIDE_PAGE_TYPE,
  SchemaForm,
  SeoForm,
  SocialsForm,
  TagForm,
  UTMGeneratorForm,
} from 'layouts';
import { RelatedPosts } from 'components/Blog/components/RelatedPost';
import {
  CREATE_GUIDE_CATEGORY,
  GET_GUIDE_CATEGORIES,
} from 'components/GuidesCategory/Category/components/CreateCategory/graphQL';

export const CreateGuide = () => {
  const navigate = useNavigate();
  const classes = { ...useStyles(), ...useStylesGuides() };
  const { siteDetails, user } = useContext(AppStateContext);
  const blog_uuid = siteDetails?.blog_uuid;
  const { uuid: guideUuid } = useParams();
  const { state } = useLocation();
  const [submitting, setSubmitting] = useState({
    draft: false,
    publish: false,
  });
  let guide_data = state?.row;

  const [getGuide, { loading: guideLoading }] = useLazyQuery(
    GET_GUIDE_BY_UUID,
    {
      variables: {
        uuid: guideUuid,
        site_uuid: siteDetails.uuid,
      },
      fetchPolicy: 'network-only',
    }
  );

  // Removing old data for autosaving feature
  localStorage.removeItem('guideFormData');

  const [embedAction, setEmbedAction] = useState<string>('');
  const [formData, setFormData] = useGuidesAutosave(
    {
      is_comments_enabled: true,
      is_in_sitemap: true,
      is_feature_article: false,
      same_as_socials: true,
      site: 'twisted',
      title: '',
      subtopics: [{}],
      top_picks_content: [
        {
          topPick_image: '',
          topPick_summary: '',
          topPick_title: '',
          topPick_link: '',
          topPick_link_text: '',
          list: [{ pros: '', cons: '' }],
          spec_list: [{ spec: '', quantity: '' }],
        },
      ],
      author_uuid: user.uuid,
      ignoreTitleChange: Boolean(guideUuid),
      meta_utmGenerator: {
        websiteUrl: '',
        campaignId: '',
        campaignSource: '',
        campaignMedium: '',
        campaignName: '',
        campaignContent: '',
      },
    },
    {
      site_uuid: siteDetails.uuid,
    }
  );

  console.log('form', formData.subtopics);

  const handleAddCreateList = () => {
    const top_picks_content = [
      ...formData.top_picks_content,
      {
        topPick_summary: '',
        topPick_title: '',
        topPick_link: '',
        topPick_link_text: '',
        list: [
          {
            pros: '',
            cons: '',
          },
        ],
        spec_list: [
          {
            spec: '',
            quantity: '',
          },
        ],
      },
    ];
    setFormData({
      ...formData,
      top_picks_content,
    });
  };

  const handleValidate = async (name: string, status: boolean) => {
    const invalidFields = validateGuidesInformation(formData, true);
    if (invalidFields.length > 0)
      return notify(
        'error',
        `${invalidFields.join(', ')} ${
          invalidFields.length > 1 ? 'are' : 'is'
        } required`
      );

    formData['status'] = status;
    handleSubmit(status);
  };

  const handleSubmit = async (status: boolean) => {
    const variables = getGuideInformation(formData, siteDetails.uuid);
    setSubmitting((submitting) => ({
      ...submitting,
      [status ? 'publish' : 'draft']: true,
    }));
    if (!formData?.guide_uuid) {
      await createGuide(variables)
        .then((res) => {
          notifySuccess('Guides created successfully');
          setSubmitting((prev) => ({ ...prev, draft: false, publish: false }));
          setFormData((form) => ({ ...form, status }));
          window.history.replaceState({}, document.title);
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.map(({ message }: { message: any }) =>
            notifyError(message)
          );
          setSubmitting((submitting) => ({
            ...submitting,
            [status ? 'publish' : 'draft']: false,
          }));
        });
    } else {
      await updateGuide({ ...variables, uuid: formData.guide_uuid })
        .then((res) => {
          notifySuccess('Guides updated successfully');
          setSubmitting((prev) => ({ ...prev, draft: false, publish: false }));
          setFormData((form) => ({ ...form, status }));
          window.history.replaceState({}, document.title);
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.map(({ message }: { message: any }) => {
            notifyError(message);
          });
          setSubmitting((submitting) => ({
            ...submitting,
            [status ? 'publish' : 'draft']: false,
          }));
        });
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let maxWords = 0;
    if (e.target.name === 'title') {
      maxWords = 75;
    }
    const words = e.target.value.split(/\s+/);
    if (words.length <= maxWords) {
      setFormData((prev) => ({ ...prev, title: e.target.value }));
    }
  };

  const handleClose = () => {
    setEmbedAction('');
  };

  const showRightSidebar = () => {
    if (location.pathname.includes('edit')) {
      return Boolean(formData?.guide_uuid);
    }
    return true;
  };

  const handleImageEmbed = (name: string, url: { [key: string]: string }) => {
    setFormData({ ...formData, [name]: JSON.stringify(url) });

    handleClose();
  };

  const handleFormChange = (e: any) => {
    setFormData((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
  };

  const headerData = [
    {
      name: 'Title',
      description: '',
      children: (
        <>
          <textarea
            name="title"
            placeholder="Add title"
            onChange={handleTitleChange}
            className={clx(classes.excerptText, classes.otherExcerptText)}
            value={decodeHtml(formData.title)}
          ></textarea>
          <p className={classes.characterlength}>
            {decodeHtml(formData.title).length}/75 characters
          </p>
        </>
      ),
    },
    {
      name: 'Description',
      description:
        'Guides description that will appear directly under the heading.',
      children: (
        <>
          <ReactQuill
            className={classes.reactQuill}
            placeholder="Add description here"
            onChange={(value) => {
              handleFormChange({ target: { name: 'content', value } });
            }}
            value={formData.content}
          />
          <p className={classes.characterlength}>
            {formData.content?.length ?? 0} characters
          </p>
        </>
      ),
    },
    {
      name: 'Summary',
      description: 'Briefly provide a summary of your top picks',
      children: (
        <>
          <ReactQuill
            className={classes.reactQuill}
            placeholder="Add description here"
            onChange={(value) => {
              handleFormChange({ target: { name: 'excerpt', value } });
            }}
            value={formData.excerpt}
          />
          <p className={classes.characterlength}>
            {formData.excerpt?.length ?? 0} characters
          </p>
        </>
      ),
    },
    {
      name: 'Guides',
      description: 'List of Guides',
      children: (
        <div
          className={clx(
            classes.padding,
            classes.bottomPadding,
            classes.background
          )}
        >
          <label className={classes.createMeasurementStyles}>
            Add Picks Below
          </label>
          {formData.top_picks_content.map((list: any, index: number) => {
            return (
              <CreateTopPicks
                index={index}
                unit="g"
                list={list}
                form={formData}
                setFormData={setFormData}
                key={index}
              />
            );
          })}
          <div className={classes.addBtn} onClick={handleAddCreateList}>
            <AiOutlinePlus />
            <p>Add new top pick</p>
          </div>
        </div>
      ),
    },
    {
      name: 'Things to Consider',
      description:
        'These are set of things to Look out for when trying to buy this product',
      children: showRightSidebar() && (
        <FAQ faq={formData.subtopics[0]} setFAQ={setFormData} />
      ),
    },
  ];

  const setGuideData = (guide_data: { [key: string]: any }) => {
    if (guide_data) {
      const guide_config =
        JSON.parse(guide_data.config.social_preview_config) ?? {};

      setFormData((oldFormData) => ({
        ...oldFormData,
        guide_uuid: guide_data?.uuid,
        title: guide_data.title,
        content: guide_data.content,
        author_uuid: guide_data.author.uuid,
        excerpt: guide_data.excerpt,
        status: guide_data.status,
        featured_image: JSON.stringify(
          parseRecursive(guide_data.featured_image)
        ),
        slug: guide_data.slug,
        top_picks_content: parseContentRecursive(guide_data.top_picks_content),
        published_at: guide_data.published_at,
        is_dark_post: !guide_data.config.is_in_sitemap,
        is_feature_article: guide_data.config.is_feature_recipe,
        ...getSocialData(guide_config),
        ...getTwitterData(guide_config),
        ...getSeoData(guide_config),
        open_graph: guide_config.open_graph,
        twitter_cards: guide_config.twitter_cards,
        categories: guide_data.categories,
        subtopics: parseContentRecursive(guide_data.subtopics),
        tags: guide_data?.tags
          ? guide_data.tags.map((tag: any) => tag.name)
          : [],
      }));
    }
  };

  return (
    <CreateContentLayout
      isEdit={formData?.guide_uuid}
      isPublished={formData?.status}
      onGoBack={() => {
        navigate('/cms/guides');
      }}
      showRightSidebar={showRightSidebar()}
      type="guides"
      updateStateData={setGuideData}
      getContentConfig={{
        client: blogClient,
        variables: {
          uuid: guideUuid,
          site_uuid: siteDetails.uuid,
        },
        GET_CONTENT: GET_GUIDE_BY_UUID,
        dataKey: ['getGuideByUuid'],
      }}
      sidebarControls={{
        details: (
          <DetailsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
            type={GUIDE_PAGE_TYPE}
            switchData={[
              {
                label: 'Dark Guide',
                name: 'is_dark_post',
              },
              {
                label: 'Featured Guide',
                name: 'is_feature_article',
              },
            ]}
          />
        ),
        categories: (
          <CategoryForm
            formData={formData}
            setFormData={setFormData}
            createCategoryConfig={{
              client: blogClient,
              CREATE_CATEGORY: CREATE_GUIDE_CATEGORY,
              variables: {
                site_uuid: siteDetails.uuid,
              },
            }}
            getCategoriesConfig={{
              name: GET_GUIDE_CATEGORIES,
              client: blogClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getGuidecategoriesBySiteUuid', 'data'],
              variables: {
                site_uuid: siteDetails.uuid,
                all: true,
              },
            }}
          />
        ),
        relatedPosts: (
          <RelatedPosts formData={formData} setFormData={setFormData} />
        ),
        schema: <SchemaForm formData={formData} setFormData={setFormData} />,
        seo: (
          <SeoForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        socials: (
          <SocialsForm
            formData={formData}
            setEmbedAction={setEmbedAction}
            setFormData={setFormData}
          />
        ),
        tags: (
          <TagForm
            formData={formData}
            setFormData={setFormData}
            getTagsConfig={{
              name: GET_GUIDE_TAGS,
              client: blogClient,
              labelKey: 'name',
              valueKey: 'uuid',
              key: ['getGuideTagsBySiteUuid'],
              variables: {
                site_uuid: siteDetails.uuid,
                all: true,
              },
            }}
            addOption={(tag: string) =>
              new Promise((resolve) =>
                resolve({
                  label: tag,
                  value: String(Math.random() * 1000),
                  status: true,
                })
              )
            }
          />
        ),
        utmGenerator: (
          <UTMGeneratorForm formData={formData} setFormData={setFormData} />
        ),
      }}
    >
      {headerData?.map(({ name, description, children }, index: number) => (
        <Header title={name} description={description} key={index}>
          {children}
        </Header>
      ))}
      <EmbedImage
        open={
          embedAction === 'image' ||
          embedAction === 'featured_image' ||
          embedAction === 'social_image' ||
          embedAction === 'seo_image' ||
          embedAction === 'twitter_image'
        }
        handleClose={handleClose}
        handleEmbed={handleImageEmbed}
        name={embedAction}
        formData={formData}
        setFormData={setFormData}
      />
      <CTAs
        handleValidate={handleValidate}
        isEditPost={Boolean(formData?.status)}
        submitting={submitting}
      />
    </CreateContentLayout>
  );
};
