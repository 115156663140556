import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    background: '#F4EFED 0% 0% no-repeat padding-box',
    minHeight: 'calc(100vh - 70px)',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    font: 'normal normal 700 48px/72px universal',
    letterSpacing: 0,
    color: '#000000',
    opacity: 1,
    marginTop: theme.spacing(2.5),
  },
  content: {
    marginTop: theme.spacing(5),
  },
  cultureCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    width: '40vw',
    gap: theme.spacing(2),
    margin: '0 auto',
  },
  card: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '24px',
    opacity: 1,
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
  },
  cardImage: {
    width: '100%',
  },
  cardTitle: {
    font: 'normal normal bold 24px/36px universal',
    letterSpacing: 0,
    color: '#010E05',
    opacity: 1,
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    font: 'normal normal normal 14px/21px universal',
    letterSpacing: 0,
    color: '#343E37',
    textTransform: 'capitalize',
    opacity: 1,
    marginTop: theme.spacing(1.5),
  },
}));

export default useStyles;
