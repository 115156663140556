import { gql } from '@apollo/client';

export const GET_PURCHASE_ORDERS = gql`
  query GqlGetPurchaseOrders(
    $first: Int
    $page: Int
    $search: String
    $sort: [SortInput]
    $filter: [FilterInput]
  ) {
    getMyPurchaseOrders(
      input: {
        first: $first
        page: $page
        search: $search
        sort: $sort
        filter: $filter
      }
    ) {
      data {
        id
        uuid
        vendor_name
        vendor_contact
        slt_approved
        acc_approved
        approved_at
        current_budget
        requirement
        total
        items
        purchase_date
        created_at
        updated_at
        po_number
        status
        requestor {
          name
          department
          role
          id
          position
          email
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        perPage
        hasMorePages
      }
    }
  }
`;
