import { useContext, useState, useEffect } from 'react';
import { Select } from 'components/common/Select/Select';
import { getOptions } from './data';
import { cpasClient } from 'utilities/apolloClient';
import useCpasStyles from './styles';
import { Modal, Box, CircularProgress } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { convertEcpmAndRevenueData, formatDate } from './helpers';
import { AppStateContext } from 'contexts/provider';
import { LineChart } from 'components/LineChart/LineChart';
import { useQuery } from '@apollo/client';
import SnapDetails from 'components/SnapDetails/SnapDetails';
import clx from 'classnames';
import DataParameter from './components/DataParameters/DataParameter';
import useSelectChange from './hooks/hooks';
import { GETCHANNELMETRICS, GETTOPSTORYSNAPS } from './graphQl/graphQl';
import CustomVideoPlayer from 'components/CustomVideoPlayer/CustomVideoPlayer';
import CpasTable from './components/CpasTable/CpasTable';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Cpas = () => {
  const classes = useCpasStyles();
  const { channelDetails, all, brand } = useContext(AppStateContext);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
  const [storyId, setStoryId] = useState<string>('');
  const [topSnaps, setTopSnaps] = useState([]);
  const formattedBrand = brand.includes('(Brand)')
    ? brand.replace('(Brand)', '')
    : brand;

  const {
    value,
    showCustomRange,
    selectedStartDate,
    selectedEndDate,
    handleSelectChange,
    setShowCustomRange,
    setSelectedStartDate,
    setSelectedEndDate,
  } = useSelectChange();

  const {
    data: channelMetrics,
    error: channelMetricsError,
    loading: loadingMetrics,
  } = useQuery(GETCHANNELMETRICS, {
    variables: {
      businessProfileId: channelDetails?.businessProfileId ?? '',
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      all_channels: all,
      brand: formattedBrand,
    },
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  const {
    data: topStorySnaps,
    error,
    loading,
  } = useQuery(GETTOPSTORYSNAPS, {
    variables: {
      businessProfileId: channelDetails?.businessProfileId,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      all_channels: all,
      brand: formattedBrand,
    },
    fetchPolicy: 'network-only',
    client: cpasClient,
  });

  useEffect(() => {
    if (topStorySnaps) {
      const snaps = topStorySnaps.getTopStorySnaps;
      setTopSnaps(snaps);
    }
  }, [topStorySnaps, error]);

  const handleApplyClick = () => {
    setShowCustomRange(false);
  };

  const handleChange = (index: number) => {
    setCurrentIndex(index);
  };

  const handleDateChange = (selectedDates: DateRange) => {
    setSelectedStartDate(selectedDates.startDate ?? '');
    setSelectedEndDate(selectedDates.endDate ?? '');
  };

  const renderSlides =
    topSnaps &&
    topSnaps.map((vid: { [key: string]: string }, index) => (
      <CustomVideoPlayer
        title={vid?.name}
        videoSource={vid.snap_url}
        key={index}
        className={classes.snapIcon}
      />
    ));

  const handleClose = () => setOpenDetailsModal(false);
  const handleClickAway = () => setShowCustomRange(false);

  const [dateState, setDateState] = useState([
    { startDate: new Date(), endDate: null, key: 'selection' },
  ]);
  const handleSubmitDate = () => {
    setSelectedStartDate(formatDate(dateState?.[0]?.startDate ?? ''));
    setSelectedEndDate(formatDate(dateState?.[0]?.endDate ?? ''));
    setShowCustomRange(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.cpasParent}>
        <h1 className={classes.heading}>Overview</h1>
        <DataParameter channelDetails={channelDetails} />
        <div className={classes.storiesRoot}>
          <div className={classes.topStoriesParent}>
            <p className={classes.subHeading}>Top Stories</p>
            {/* @ts-ignore*/}
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              selectedItem={currentIndex}
              onChange={handleChange}
              className="carousel-container"
              showStatus={false}
              showArrows={false}
              interval={5000}
            >
              {renderSlides}
            </Carousel>
          </div>
          <div className={classes.creativeParent}>
            <div className={classes.chartHeading}>
              <p className={classes.subHeading}>Creative story chart</p>
              <div className={classes.selectField}>
                <p className={classes.filterText}>Filter By:</p>
                <Select
                  options={getOptions('', false)}
                  onChange={handleSelectChange}
                  value={value}
                  className={classes.select}
                />
              </div>
            </div>
            {showCustomRange && (
              <>
                <div className={clx(classes.filterContainer, 'data')}>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item: any) => setDateState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateState}
                  />
                  <div className={classes.filterBox}>
                    <button onClick={handleClickAway}>Cancel</button>
                    <button onClick={handleSubmitDate} className="secondary">
                      Apply
                    </button>
                  </div>
                </div>
                <div
                  className={classes.overlay}
                  onClick={() => setShowCustomRange(false)}
                />
              </>
            )}
            {loadingMetrics ? (
              <div className={classes.loader_container}>
                <CircularProgress
                  className={classes.loader}
                  size="24px"
                  color="inherit"
                />
              </div>
            ) : (
              channelMetrics?.getAggregatedChannelStoryMetrics && (
                <LineChart
                  className={classes.chart}
                  data={
                    channelMetrics?.getAggregatedChannelStoryMetrics
                      ? convertEcpmAndRevenueData(
                          channelMetrics?.getAggregatedChannelStoryMetrics
                        )
                      : []
                  }
                />
              )
            )}
          </div>
        </div>
        <CpasTable
          setStoryId={setStoryId}
          setOpenDetailsModal={setOpenDetailsModal}
        />
      </div>
      <Modal
        open={openDetailsModal}
        onClose={handleClose}
        onBackdropClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.box}>
          <SnapDetails
            handleBackClick={() => setOpenDetailsModal(false)}
            story_id={storyId}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Cpas;
