import React from 'react';
import clx from 'classnames';
import { useLayoutStyles } from './styles';
import { LayoutProps } from './types';
import FormElement from '../FormElement/FormElement';
import { FormFieldsProps } from '../types';
import _ from 'lodash';

const FormLayout: React.FC<LayoutProps> = ({
  gap = '32px',
  className,
  type = 'horizontal',
  fields = [],
  form,
  sectionName,
  groupName,
  canHaveMultiple,
  setForm,
  activeCardIndex,
  ...layoutProps
}) => {
  const classes = useLayoutStyles({ width: '55%' });

  const onChange = (e: any) => {
    setForm((form: any) => ({
      ...form,
      [sectionName]: {
        ...form[sectionName],
        [groupName]: canHaveMultiple
          ? (() => {
              // find key of the current active card from the prop instead of hardcoding index 0
              const newArray = [...form[sectionName][groupName]];
              newArray[activeCardIndex];

              newArray[activeCardIndex] = {
                ...newArray[activeCardIndex],
                [e.target.name]: e.target.value,
              };
              return newArray;
            })()
          : {
              ...form[sectionName][groupName],
              [e.target.name]: e.target.value,
            },
      },
    }));
  };

  const getValue = (field: FormFieldsProps) => {
    if (field.type == 'layout') return null;
    if (_.get(form, `[${sectionName}][${groupName}]`, null)) {
      if (canHaveMultiple) {
        if (
          form &&
          form[sectionName] &&
          form[sectionName][groupName].length > 0
        ) {
          return form[sectionName][groupName][activeCardIndex][
            field.properties.name
          ];
        }
      }
      return form[sectionName][groupName][field.properties.name];
    }
    return null;
  };

  const displayControls = () => {
    return fields.map((field, index) => {
      const properties = {
        ...field,
        properties: {
          ...field.properties,
          className: clx(field.properties?.className, classes.layoutElement),
          value: getValue(field),
          onChange,
        },
      };
      return (
        <FormElement
          {...layoutProps}
          {...properties}
          key={index}
          setForm={setForm}
          form={form}
          sectionName={sectionName}
          groupName={groupName}
          canHaveMultiple={canHaveMultiple}
          activeCardIndex={activeCardIndex}
        />
      );
    });
  };
  return (
    <div
      className={clx(
        classes.root,
        className,
        type === 'vertical' && classes.verticalVariant
      )}
      style={{ gap }}
    >
      {displayControls()}
    </div>
  );
};

export default FormLayout;
