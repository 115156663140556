export const welcomeTabs = [
  {
    name: 'Your First Day',
    route: 'your-first-day',
  },
  {
    name: 'Email and Slack',
    route: 'emails-and-slack',
  },
  {
    name: 'Email Signature',
    route: 'email-signature',
  },
];
export const cultureTabs = [
  {
    name: 'Values',
    route: 'values',
  },
  {
    name: 'People experience',
    route: 'all-hands',
  },
  {
    name: 'ERGs',
    route: 'ergs-clubs-committees',
  },
];
export const hrTabs = [
  {
    name: 'Sick Days',
    route: 'sick-days',
  },
  {
    name: 'Working Day',
    route: 'working-day',
  },
  {
    name: 'Performance Reviews',
    route: 'performance-reviews',
  },
  {
    name: 'Career bandings',
    route: 'career-bandings',
  },
  {
    name: 'Wellbeing',
    route: 'wellbeing',
  },
  {
    name: 'Hiring',
    route: 'hiring',
  },
  {
    name: 'Hybrid working',
    route: 'hybrid-working',
  },
];
export const officeTabs = [
  {
    name: 'The Office',
    route: 'the-office',
  },
  {
    name: 'First Aid & Fire Safety',
    route: 'first-aid-&-fire-safety',
  },
  {
    name: 'Phones & Printers',
    route: 'phones-&-printers',
  },
  {
    name: 'Meeting Rooms',
    route: 'meeting-rooms',
  },
  {
    name: 'Keeping It Green',
    route: 'keeping-it-green',
  },
  {
    name: 'Health Precautions',
    route: 'health-precautions',
  },
];
export const financeTabs = [
  {
    name: 'Purchase orders',
    route: 'purchase-orders',
  },
  {
    name: 'Accounts payable',
    route: 'accounts-payable',
  },
  {
    name: 'Pleo',
    route: 'pleo',
  },
  {
    name: 'Dext',
    route: 'dext',
  },
  {
    name: 'Billing',
    route: 'billing',
  },
  {
    name: 'Company information',
    route: 'company-information',
  },
];
export const faqTabs = [
  {
    name: 'HR',
    route: 'hr',
  },
  {
    name: 'Payroll',
    route: 'payroll',
  },
  {
    name: 'Office',
    route: 'office',
  },
  {
    name: 'D&B',
    route: 'd&b',
  },
  {
    name: 'Tech',
    route: 'tech',
  },
  {
    name: 'Finance',
    route: 'finance',
  },
];
