import { Tag } from '@wazobia-tech/wazcom/dist/core';
import {
  Status,
  useGetStatusClass,
} from 'components/PurchaseOrders/ViewPurchaseOrders/helpers';
import CheckBox from 'components/common/Checkbox/Checkbox';
import Loader from 'components/common/Loader/Loader';
import React from 'react';
import useStyles from './styles';

interface ProgressTrackerProps {
  formik: any;
  statusNumbers: Record<
    string,
    {
      value: number;
      text: string;
      summary: string;
    }
  >;
  loading: boolean;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  formik,
  statusNumbers,
  loading,
}) => {
  const getStatusClass = useGetStatusClass();
  const classes = useStyles();

  return (
    <div className={classes.progress}>
      <div className={classes.progressTitle}>Purchase Order status</div>
      <div className={classes.progressTracker}>
        {Object.keys(statusNumbers).map((status, index) => {
          const enumStatus = Status[status as keyof typeof Status];
          const currentStatusValue =
            statusNumbers[status as keyof typeof statusNumbers].value;
          const formikStatusValue =
            statusNumbers[formik.values.status as keyof typeof statusNumbers]
              ?.value;

          const isCurrentOrPriorStatus =
            currentStatusValue <= formikStatusValue;

          return (
            <div key={index}>
              <div className={classes.row}>
                {isCurrentOrPriorStatus ? (
                  <div
                    className={classes.step}
                    style={{
                      backgroundColor: isCurrentOrPriorStatus
                        ? 'green'
                        : '#ccc',
                    }}
                  >
                    {index + 1}
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <CheckBox
                      name={status}
                      checked={(() => {
                        if (status == 'approved') {
                          return formik.values.approved;
                        }
                        return false;
                      })()}
                      handleChange={(e) => {
                        formik.handleChange({
                          target: e?.target,
                        });
                        formik.handleChange({
                          target: {
                            name: 'status',
                            value: status,
                          },
                        });
                        formik.handleSubmit();
                      }}
                      classNames={{ root: classes.noPadding }}
                    />
                    <Loader display={loading} />
                  </div>
                )}
                <div className={classes.progressDetails}>
                  <div className={classes.summary}>
                    {
                      statusNumbers[status as keyof typeof statusNumbers]
                        .summary
                    }
                  </div>
                  <Tag
                    name={
                      enumStatus
                        ? enumStatus.charAt(0).toUpperCase() +
                          enumStatus.slice(1).toLowerCase()
                        : ''
                    }
                    classname={getStatusClass(enumStatus)}
                  />
                </div>
              </div>
              {index !== Object.values(statusNumbers).length - 1 && (
                <div className={classes.line} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressTracker;
