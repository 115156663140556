import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    fontFamily: 'Universal',
  },
  header: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '4px',
    border: '1px solid var(--Foundation-jungle-Grey-jungle-grey-600, #DED9D8)',
    background: 'var(--Foundation-Dark-D80, #FFF)',
  },
  title: {
    fontSize: 20,
    lineHeight: '1.75rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },
  orderMetaInfo: {
    marginTop: theme.spacing(2.5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
  },
  information: {
    width: '100%',
    height: '100%',
    background: '#fff',
    borderRadius: '10px',
    padding: `${theme.spacing(5)}px ${theme.spacing(23)}px`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
  },
  informationIcon: {
    marginRight: theme.spacing(5),
    width: '10rem',
  },
  informationContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: -6,

    '& .text': {
      lineHeight: 1.6,
      fontSize: '1rem',
      marginBottom: theme.spacing(3),
    },
  },
  orderParent: {
    background: '#fff',
    padding: '2.5rem',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',

    '& .section': {
      display: 'flex',
      flexDirection: 'column',
      width: '48%',
    },
  },
  formSection: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
    borderRadius: '4px',
    border: '1px solid var(--Foundation-jungle-Grey-jungle-grey-600, #DED9D8)',
    background: '#FFF',
    width: '100%',
    boxSizing: 'border-box',
  },
  orderItems: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '15px',
    width: '100%',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  formLabel: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  formError: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  formActions: {
    margin: '4.25rem 0 0 0',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  formAddItems: {
    display: 'flex',
    alignItems: 'center',
    background: theme.design.colors.faintBlack,
    borderRadius: 5,
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    width: 'fit-content',
    color: theme.design.colors.black,
    cursor: 'pointer',

    '& .icon': {
      marginRight: theme.spacing(1),
    },
  },
  formRemoveItems: {
    cursor: 'pointer',
    color: 'red',
  },
  formDatePicker: {
    width: '100%',
  },
  inputParent: {
    position: 'relative',
    width: '100%',

    '& .select': {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '60%',
      zIndex: 10,
    },
  },
  input: {
    width: '100%',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
    '& div': {
      boxSizing: 'border-box',
    },
  },
  formParent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
  },
  itemLabel: {
    color: 'var(--Foundation-jungle-Grey-jungle-grey-900, #666464)',
    fontFamily: 'Georgia',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  block: {
    display: 'block',
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#eee',
    borderRadius: '4px',
    '& input': {
      color: '#888',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
  outlinedButton: {
    border: '1px solid black !important',
    background: 'white !important',
    color: 'black !important',
  },
}));

export default useStyles;
