import React from 'react';
import useStyles from './styles';
import { FaFaceSmile } from 'react-icons/fa6';
import { welcomeTabs } from '../../data';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

const WelcomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.heading}>
          Welcome{' '}
          <FaFaceSmile
            color={'#86FC65'}
            size={25}
            style={{ background: 'black', borderRadius: '50%' }}
          />{' '}
          to Jungle
        </h1>
        <h2 className={classes.subheading}>
          We are delighted to have you here! Meet the SLT
        </h2>
      </div>
      <div className={classes.body}>
        <div className={classes.leaderContainer}>
          {[
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-04T190052/HBBu2GoRfHSfQaczC6yyHQqyZb27JO0qziP5tfxX.png',
              title: 'CEO',
              name: 'Melisa Chapman',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-04T190039/S9SL6WDcmGJOqZ4kppiVX6NmXvRz0zVZLjaki0B5.png',
              title: 'COFO',
              name: 'Katie O’ Reilly',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-04T190312/W3hgaKIuvi8TijN0lF5fgIutc4Fdef46Z4EeRXvT.png',
              title: 'MD of Media',
              name: 'Siobhan McDade',
            },
          ].map((item, index) => {
            return (
              <div className={classes.leader} key={index}>
                <img className={classes.leaderImage} src={item.image} />
                <p>{item.name}</p>
                <p>{item.title}</p>
              </div>
            );
          })}
        </div>
        <div className={classes.sections}>
          <h4>
            We are Jungle Creations, an award winning social media agency and
            publisher
          </h4>
          <p>
            Since our creation in 2014, we have built a community of over 147
            million followers on and beyond social. In 2019 we launched The
            Wild, our publisher-powered social agency, that helps brands unlock
            social through data and insights, so they can build communities and
            break into culture.
          </p>
          <p>
            Here&rsquo;s where you can read more&nbsp;
            <Link to="https://www.junglecreations.com" target="_blank">
              about us
            </Link>
            &#8230;&nbsp;
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
