import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    boxSizing: 'border-box',
    borderRadius: '24px',
    border: `1px solid ${theme.design.colors.lidGrey}`,
    background: theme.design.colors.palePink,

    '& .loader': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      zIndex: -9999,
      background: theme.design.colors.white,
      borderRadius: '0 0 10px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 0.5s ease-in',
    },

    '& .active': {
      opacity: 1,
      zIndex: 10,
    },
  },
  containerHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.design.colors.titleColor,
    marginBottom: theme.spacing(2),

    '& .icon': {
      marginRight: theme.spacing(1),
    },
  },
  newsletterheader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  containerNewsletters: {
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    position: 'relative',
    display: 'flex',
    width: '100%',

    '&::-webkit-scrollbar': {
      height: 0,
    },
  },
  container__newsletter: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: '100%',

    '& img': {
      width: '100%',
      // height: 276,
      objectFit: 'cover',
      borderRadius: 16,
    },
  },
  readBtn: {
    width: '90%',
  },
  btncontainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    textDecoration: 'none',
    bottom: 25,
  },
  date: {
    fontSize: theme.typography.fontSize - 3,
    color: theme.design.colors.grey200,
    fontWeight: theme.typography?.fontWeightMedium,
  },
  container__nav: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  headerTitle: {
    fontSize: theme.typography?.fontSize + 4,
    color: theme.design.colors.grey100,
    fontWeight: theme.typography?.fontWeightMedium,
    fontFamily: 'Feature',
  },
  empty: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    '& > span': {
      fontSize: 12,
    },
  },
}));

export default useStyles;
