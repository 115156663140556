import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useMediaLibraryStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    padding: theme.spacing(2),
    background: theme.design.colors.white,
  },
  pageTitle: {
    fontSize: theme.typography.fontSize + 6,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.design.colors.black200,
  },
  desc: {
    fontSize: theme.typography.fontSize,
    color: theme.design.colors.black200,
    margin: theme.spacing(1, 0),
  },

  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalBox: {
    position: 'fixed',
    zIndex: '1300',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaButton: {
    padding: theme.spacing(0, 3),
    height: 35,
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
}));
