import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../contexts/theme';

export const useMrssPageStyles = makeStyles((theme: BaseTheme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  container: {
    background: theme.design.colors.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    margin: theme.spacing(2, 0),
  },
  dualTextFields: {
    display: 'flex',
    gap: '20px',
    marginBottom: theme.spacing(2.5),
    justifyContent: 'space-between',
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdown: {
    minWidth: '225px',
    marginBottom: theme.spacing(2),
  },
  w50: {
    width: '50%',
  },
  accordionContainer: {
    marginBottom: theme.spacing(2),
  },
  mrssItemHeaderSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
  },
  feedSubmit: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemSubmit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  feedDropdown: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  textField: {
    marginBottom: theme.spacing(2.5),
  },
  logoField: {
    width: '50%',
  },
}));
