import { FC, useContext } from 'react';
import { AppStateContext } from 'contexts/provider';
import Category from './Category/Category';
import {
  CREATE_RECIPE_CATEGORY,
  DELETE_RECIPE_CATEGORY,
  GET_RECIPE_CATEGORIES,
  UPDATE_RECIPE_CATEGORY,
} from 'components/Blog/graphQL';
import {
  CREATE_GUIDE_CATEGORY,
  UPDATE_GUIDE_CATEGORY,
  GET_GUIDE_CATEGORIES,
} from 'components/GuidesCategory/Category/components/CreateCategory/graphQL';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helpers';

export const GuidesCategory = () => {
  const {
    siteDetails: { uuid: site_uuid },
  } = useContext(AppStateContext);
  const path = '/cms/guides-category';
  const dataKey = 'getCategoriesBySiteUuid';
  return (
    <Category
      site_uuid={site_uuid}
      path={path}
      name="category"
      datakey={dataKey}
      DELETE_CATEGORY={DELETE_RECIPE_CATEGORY}
      GET_GUIDE_CATEGORIES={GET_GUIDE_CATEGORIES}
      UPDATE_GUIDE_CATEGORY={UPDATE_GUIDE_CATEGORY}
      CREATE_GUIDE_CATEGORY={CREATE_GUIDE_CATEGORY}
      tableConditionFilters={tableConditionFilters}
      useGetTableColumns={useGetTableColumns}
      useGetTableRows={useGetTableRows}
    />
  );
};
