import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useTextFieldStyles = makeStyles((theme: BaseTheme) => ({
  root: {
    marginBottom: theme.spacing(2.5),
    width: '100%',
  },
  textField: {
    fontSize: theme.typography.fontSize,
    background: theme.design.colors.grey150,
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.black,
    marginBottom: theme.spacing(1),
  },
}));
