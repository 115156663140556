import { gql } from '@apollo/client';

export const GET_DIRECTORY = gql`
  query getCezanneDirectory {
    getCezanneDirectory {
      name
      role
      email
      number
      avatar {
        type
        image
      }
    }
  }
`;

export const SEARCH_DIRECTORY = gql`
  query getCezanne($search: String!) {
    searchCezanneDirectory(search: $search) {
      name
      role
      email
      number
      avatar {
        type
        image
      }
    }
  }
`;
