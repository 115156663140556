import React from 'react';
import useStyles from './styles';
import { FaFaceSmile } from 'react-icons/fa6';
import { MdWavingHand } from 'react-icons/md';
import LeadershipList from 'pages/PageContent/components/leadershipList';
import SubSectionList from 'pages/PageContent/components/subSectionList';
import { officeTabs } from 'pages/Content/data';

const OfficePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.title}>
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T103048/5I9ACEe4yjiBHPGImPGC9IJ1rfH5Qs1OKs5IEQTu.png'
            }
          />
          The Office
          <img
            src={
              'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/eee6c05f-eaa2-4e68-9e31-c14b3a3f96e3/2024-01-10T102719/106iJUHwWoT6L88zkzTfv1mbA3nYdfmAdWLftqbP.png'
            }
          />
        </h1>
      </div>
      <div className={classes.leaderContainer}>
        <LeadershipList
          leaders={[
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-16T114333/ectH2ocgqUDuTtSmlePnLwx3P66JIbUgeiYGyzKs.png',
              title: 'COFO',
              name: 'Katie O’ Reilly',
            },
            {
              image:
                'https://portal-spa.s3.us-west-1.amazonaws.com/wp-content/uploads/2024/01/73871f84-154c-48e7-a496-25f17aacb298/2024-01-10T101044/Qr2egQgsJSz2JuSW48cnRscap5U241q8moS01OBn.png',
              title: 'Operations and Events Coordinator',
              name: 'Sen Kahveci',
            },
          ]}
        />
      </div>
      <div className={classes.content}>
        <SubSectionList cards={officeTabs} />
      </div>
    </div>
  );
};

export default OfficePage;
