import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Icon } from '@iconify/react';
import FormLayout from '../Layout/Layout';
import { useQuery } from '@apollo/client';
import { FormFieldsProps } from '../types';
import { upperFirst, lowerCase } from 'lodash';
import { AppStateContext } from 'contexts/provider';
import TextArea from '../../common/TextArea/TextArea';
import CheckBox from '../../common/Checkbox/Checkbox';
import { useSectionStyles } from '../FormGroup/styles';
import TextField from '../../common/TextField/TextField';
import FileField from '../../common/FileField/FileField';
import {
  TwistedSectionName,
  ValidationContext,
  VtSectionName,
} from 'contexts/validation';
import {
  GET_BLOG_POST_BY_UUID_AND_SLUG,
  GET_RECIPE_BY_SITEUUID_AND_SLUG,
} from 'utilities/graphql/queries';

const FormElement: React.FC<FormFieldsProps> = ({
  type,
  form,
  setForm,
  groupName,
  properties,
  sectionName,
  canHaveMultiple,
  activeCardIndex,
}) => {
  let input;
  const classes = useSectionStyles();
  const [slug, setSlug] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { siteDetails } = useContext(AppStateContext);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { name, value = '', defaultValue = '', onChange } = properties;
  const { handleValidationChange, pageName } = useContext(ValidationContext);
  const hasUrlInLabel = upperFirst(lowerCase(name))
    .toLowerCase()
    .split(' ')
    .includes('url');
  const hasLinkInLabel = upperFirst(lowerCase(name))
    .toLowerCase()
    .split(' ')
    .includes('link');

  const isBlog = (val: TwistedSectionName | VtSectionName): boolean => {
    if (pageName === 'twisted') {
      return ['lifestyle'].includes(val as TwistedSectionName);
    } else if (pageName === 'vt') {
      return true;
    }
    return false;
  };

  const extractCategoryAndSlugFromUrl = (url: string): string => {
    try {
      const parsedUrl = new URL(url);
      if (!parsedUrl.pathname) {
        return '';
      }
  
      if (pageName === 'twisted') {
        const pathParts = parsedUrl.pathname.split('/').filter(Boolean);
        if (pathParts[0] === 'articles') {
          return `/${pathParts.slice(-2).join('/')}`;
        }
        return `/${pathParts[pathParts.length - 1] || ''}`;
      }
      return parsedUrl.pathname;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  const useCustomQuery = (
    value: string,
    sectionName: TwistedSectionName | VtSectionName,
    siteDetails: any,
    slug: string
  ) => {
    let isArticleUrl = false;
    console.error('Invalid URL provided:', isArticleUrl);

    try {
      const parsedUrl = new URL(value);
      const pathParts = parsedUrl.pathname.split('/');
      isArticleUrl = pathParts[1] === 'articles';
    } catch (error) {
      console.error('Invalid URL provided:', value);
    }

    return useQuery(
      isBlog(sectionName) || isArticleUrl
        ? GET_BLOG_POST_BY_UUID_AND_SLUG
        : GET_RECIPE_BY_SITEUUID_AND_SLUG,
      {
        variables: isBlog(sectionName)
          ? {
              blogUuid: siteDetails.blog_uuid,
              slug,
            }
          : {
              site_uuid: siteDetails.uuid,
              slug,
            },
        skip: !slug || (sectionName === 'shows' && Boolean(slug)),
      }
    );
  };

  const query = useCustomQuery(value, sectionName, siteDetails, slug);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false); // Skip the first run
      return;
    }

    let debounceTimer: NodeJS.Timeout;

    if (pageName === 'twisted') {
      if (
        type === 'text' &&
        value &&
        groupName !== 'heading' &&
        groupName !== 'button' &&
        (hasUrlInLabel || hasLinkInLabel) &&
        !(sectionName === 'shows' && groupName === 'showsSlider')
      ) {
        // Debounce function to wait half a sec after the user stops typing
        debounceTimer = setTimeout(() => {
          const extractedSlug = extractCategoryAndSlugFromUrl(value);

          setSlug(extractedSlug);
        }, 500);
      }
    }

    if (pageName === 'vt') {
      if (
        type === 'text' &&
        value &&
        (hasUrlInLabel || hasLinkInLabel) &&
        !(sectionName === 'tvCarousel' && groupName === 'tvCarousel') &&
        !(sectionName === 'shows' && groupName === 'shows')
      ) {
        // Debounce function to wait half a sec after the user stops typing
        debounceTimer = setTimeout(() => {
          const extractedSlug = extractCategoryAndSlugFromUrl(value);
          setSlug(extractedSlug);
        }, 500);
      }
    }
    return () => clearTimeout(debounceTimer);
  }, [value]);

  useEffect(() => {
    // Early return if conditions are not met
    if (pageName !== 'twisted' || sectionName !== 'shows' || !slug) return;

    setIsLoading(true);

    const fetchEpisodeData = async () => {
      try {
        const response = await axios.get(
          `https://wp.twistedfood.co.uk/wp-json/wp/v2/episodes?slug=${
            slug.split('/')[2]
          }`
        );

        const isTrue = response.data.length > 0;
        setIsValid(isTrue);
        handleValidationChange(
          sectionName,
          `${name}-${activeCardIndex}`,
          isTrue
        );
      } catch (error) {
        setIsValid(false);
        handleValidationChange(
          sectionName,
          `${name}-${activeCardIndex}`,
          false
        );
      } finally {
        setIsLoading(false);
      }
    };

    void fetchEpisodeData();
  }, [slug]);

  // Check the validity once the query has returned data
  useEffect(() => {

  let isArticleUrl = false;
    console.error('Invalid URL provided:', isArticleUrl);

    try {
      const parsedUrl = new URL(value);
      const pathParts = parsedUrl.pathname.split('/');
      isArticleUrl = pathParts[1] === 'articles';
    } catch (error) {
      console.error('Invalid URL provided:', value);
    }

    if (query.data) {
      const data = isBlog(sectionName as TwistedSectionName | VtSectionName) || isArticleUrl
        ? query.data.getBlogPostByBlogUuidAndSlug
        : query.data.getRecipeBySiteUuidAndSlug;

      if (data && data.title && (data.status === 1 || data.status === true)) {
        setIsValid(true);
        handleValidationChange(sectionName, `${name}-${activeCardIndex}`, true);
      } else {
        setIsValid(false);
        handleValidationChange(
          sectionName,
          `${name}-${activeCardIndex}`,
          false
        );
      }
    }
  }, [query.data]);

  useEffect(() => {
    if (query.error) {
      setIsValid(false);
      handleValidationChange(sectionName, `${name}-${activeCardIndex}`, false);
    }
  }, [query.error]);

  switch (type) {
    case 'text':
      input = (
        <>
          <TextField
            name={name}
            label={upperFirst(lowerCase(name))}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
          />
          <div style={{ marginTop: 5 }}>
            {pageName === 'twisted' &&
              (hasUrlInLabel || hasLinkInLabel) &&
              groupName !== 'heading' &&
              groupName !== 'button' &&
              !(sectionName === 'shows' && groupName === 'showsSlider') &&
              (query.loading || isLoading ? (
                <Icon icon="eos-icons:loading" width="18" height="18" />
              ) : isValid ? (
                <Icon
                  icon="teenyicons:tick-outline"
                  width="18"
                  height="18"
                  color="green"
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    color: '#ff3719',
                  }}
                >
                  <Icon
                    icon="proicons:cancel"
                    width="18"
                    height="18"
                    color="#ff3719"
                  />
                  <span>Invalid Link</span>
                </div>
              ))}

            {pageName === 'vt' &&
              (hasUrlInLabel || hasLinkInLabel) &&
              !(sectionName === 'tvCarousel' && groupName === 'tvCarousel') &&
              !(sectionName === 'shows' && groupName === 'shows') &&
              (query.loading || isLoading ? (
                <Icon icon="eos-icons:loading" width="18" height="18" />
              ) : isValid ? (
                <Icon
                  icon="teenyicons:tick-outline"
                  width="18"
                  height="18"
                  color="green"
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    color: '#ff3719',
                  }}
                >
                  <Icon
                    icon="proicons:cancel"
                    width="18"
                    height="18"
                    color="#ff3719"
                  />
                  <span>Invalid Link</span>
                </div>
              ))}
          </div>
        </>
      );
      break;
    case 'file': {
      const getNewArray = (url: string) => {
        // find key of the current active card from the prop instead of hardcoding index 0
        const newArray = [...form[sectionName][groupName]];
        newArray[activeCardIndex] = {
          ...newArray[activeCardIndex],
          [name]: url,
        };
        return newArray;
      };
      input = (
        <FileField
          name={name}
          label={upperFirst(lowerCase(name))}
          handleChange={(url: string) => {
            // handleChange?.(keys, url);
            setForm((form: any) => {
              return {
                ...form,
                [sectionName]: {
                  ...form[sectionName],
                  [groupName]: canHaveMultiple
                    ? getNewArray(url)
                    : {
                        ...form[sectionName][groupName],
                        [name]: url,
                      },
                },
              };
            });
          }}
          url={value}
          className={classes.fileField}
        />
      );
      break;
    }
    case 'checkbox':
      input = <CheckBox label={name} checked={Boolean(value)} />;
      break;
    case 'textarea':
      input = (
        <TextArea
          aria-label="minimum height"
          minRows={6}
          name={name}
          placeholder=""
          style={{ width: '100%' }}
          value={value}
          label={upperFirst(lowerCase(name))}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      );
      break;
    case 'layout':
      input = (
        <FormLayout
          {...properties}
          form={form}
          sectionName={sectionName}
          groupName={groupName}
          setForm={setForm}
          canHaveMultiple={canHaveMultiple}
          activeCardIndex={activeCardIndex}
        />
      );
      break;
    default:
      input = <div key={0} />;
  }
  return input;
};

export default FormElement;
