import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatTimezoneDateTime = (dateTime: string) => {
  const date = new Date(dateTime);
  const timestamp =
    date.getTime() + (new Date().getTimezoneOffset() ?? 0) * -1 * 60 * 1000;
  const newDate = new Date(timestamp);
  const year = date.getFullYear();

  return `${year}-${parseNumber(date.getMonth() + 1)}-${parseNumber(
    date.getDate()
  )} ${parseNumber(newDate.getHours())}:${parseNumber(
    newDate.getMinutes()
  )}:${parseNumber(newDate.getSeconds())}`;
};

const parseNumber = (number: number) =>
  String(number).length === 1 ? '0' + String(number) : number;

export const dateFromString = (dateTime: string) => dayjs(dateTime);

export const parseOrderTimestamp = (timestamp: string) => {
  const [date, time] = timestamp.split(' ');
  let parsedTime = time.slice(0, -2);
  const [hours, minutes] = parsedTime.split(':');

  parsedTime =
    String(Number(hours) > 12 ? Number(hours) - 12 : hours) +
    ':' +
    minutes +
    ' ' +
    (Number(hours) > 11 ? 'pm' : 'am');

  return date.replace(/-/g, '/') + ', ' + parsedTime;
};

export function convertUTCDateToLocalDate(arg: Date | string) {
  const date = new Date(arg);
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
}
