import { useQuery } from '@apollo/client';
import { GET_ANNOUNCEMENTS } from './graphQL';
import { useEffect, useState } from 'react';
import WidgetDataFormat, { AnnouncementContainer } from './WidgetDataFormat';

export const AnnouncementWidget = () => {
  const { data, loading } = useQuery(GET_ANNOUNCEMENTS, {
    fetchPolicy: 'network-only',
  });
  const [pages, setPages] = useState<number>(1);
  const [announcements, setAnnouncement] = useState<{ [key: string]: any }[]>(
    []
  );

  const dataLimit = 3;

  useEffect(() => {
    if (data) {
      const announcements: { [key: string]: any }[] =
        data?.getAnnouncements.data;
      const newAnnouncements: { [key: string]: any }[] = [];
      announcements.forEach(({ updated_at, description, title }) => {
        newAnnouncements.push({ updated_at, description, title });
      });
      setAnnouncement(newAnnouncements);
      setPages(Math.ceil(data?.getAnnouncements?.length / dataLimit));
    }
  }, [data]);

  const displayContent = (startIndex: number, endIndex: number) => {
    return announcements
      ?.slice(startIndex, endIndex)
      ?.map((data: any, index: number) => {
        return (
          <AnnouncementContainer
            key={index}
            date={data.updated_at}
            title={data.title}
            description={data.description}
          />
        );
      });
  };

  return (
    <WidgetDataFormat
      pages={pages}
      displayContent={displayContent}
      loading={loading}
      dataLimit={dataLimit}
      dataLength={announcements.length}
    />
  );
};
