import clx from 'classnames';
import TextField from 'components/common/TextField/TextField';
import { formHandleText } from 'utilities/form';
import { SchemaFormProps } from './types';
import useSchemaStyles from './styles';
import { schemaFields } from './data';

const SchemaForm: React.FC<SchemaFormProps> = ({ formData, setFormData }) => {
  const classes = useSchemaStyles();

  return (
    <>
      <p className={clx(classes.title, classes.schemaTitle)}>Schema</p>
      <p className={classes.subText}>
        Make your posts eligible for rich results in search engines.
      </p>
      {schemaFields.map((field) => (
        <div key={field.name} className={classes.selectBox}>
          <p className={classes.label}>{field.label}</p>
          <TextField
            onChange={(e: React.ChangeEvent<any>) => {
              formHandleText(e, setFormData, formData);
            }}
            type="text"
            value={formData?.[field.name] ?? ''}
            name={field.name}
            className={classes.textField}
          />
        </div>
      ))}
    </>
  );
};

export default SchemaForm;
