import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../contexts/theme';

export const useStylesGuides = makeStyles((theme: BaseTheme) => ({
  iconShadow: {
    background: theme.design.colors.grey250,
    borderRadius: '50%',
    padding: theme.spacing(0.8),
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    alignItems: 'center',
  },
  backIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
  },
  excerptText: {
    height: 84,
    marginTop: theme.spacing(1),
  },
  guideTitle: {
    border: `1px solid ${theme.design.colors.lidGrey}`,
    borderRadius: '4px',
    color: theme.design.colors.grey100,
    fontFamily: theme.design.font.universal,
  },
  guideTitleType: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.design.font.universal,
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1.8),
    background: theme.design.colors.white,
  },
  otherExcerptText: {
    padding: theme.spacing(1.8),
    border: 'none',
    borderRadius: 0,
    height: '80px !important',
    outline: 'none',
    width: '100%',
    fontSize: theme.typography.fontSize * 2.5,
    color: `#010E05 !important`,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.design.font.universal,
    margin: 0,
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme.design.colors.lidGrey}`,
    borderBottom: `1px solid ${theme.design.colors.lidGrey}`,
  },
  characterlength: {
    textAlign: 'right',
    fontFamily: theme.design.font.universal,
    fontSize: theme.typography.fontSize - 2,
    padding: theme.spacing(0.5, 1.5),
  },
  reactQuill: {
    background: theme.design.colors.white,
    fontWeight: theme.typography.fontWeightRegular,
    '& .ql-container': {
      border: 'none',
    },
    '& .ql-editor': {
      paddingLeft: theme.spacing(2),
      minHeight: '20vh',
    },
  },
  time: {
    padding: '8px 16px 0 16px',
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    '& > div': {
      width: '85%',
    },
    '& > div + div': {
      width: '15%',
    },
  },
  primaryCategory: {
    width: '15%',
    '& .css-uc447t-control': {
      height: 38,
    },
  },
  embedTextInput: {
    background: theme.design.colors.grey98,
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(2),
    width: 'calc(100% - 24px)',
  },
  padding: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2),
  },
  createMeasurementStyles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
    display: 'block',
    margin: theme.spacing(1, 0),
  },
  addBtn: {
    display: 'flex',
    marginLeft: 'auto',
    width: 'fit-content',
    fontSize: theme.typography.fontSize - 1,
    fontWeight: theme.typography.fontWeightMedium,
    gap: theme.spacing(0.4),
    paddingTop: theme.spacing(1),
    alignItems: 'center',
    cursor: 'pointer',
  },
  background: {
    background: theme.design.colors.white,
    borderTop: `1px solid ${theme.design.colors.lidGrey}`,
  },
}));
