import React, { FC } from 'react';
import { Modal } from '@wazobia-tech/wazcom/dist/core';
import { DialogProps } from './types';
import { useDialogStyles } from './styles';

export const Dialog: FC<DialogProps> = ({ open, handleClose, children }) => {
  const classes = useDialogStyles();

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className={classes.root}>{children}</div>
    </Modal>
  );
};
