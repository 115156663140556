import axios, { AxiosResponse } from 'axios';
import { validateFile as validateImage } from './file';
import { notifyError } from './notify';

export const plugins: string[] = [
  'advlist autolink lists link image code',
  'charmap print preview anchor help',
  'searchreplace visualblocks code',
  'insertdatetime media table paste wordcount',
];

export const toolbar: string[] = [
  'undo redo | formatselect | link image | bold italic | \
  alignleft aligncenter alignright | \
  bullist numlist outdent indent | help',
];

export const uploadImage = (
  callback: (location: string, config: { [key: string]: string }) => void,
  setLoading: (loading: boolean) => void,
  site: string
) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.onchange = async function (event: Event) {
    const file: File | undefined = (event.target as HTMLInputElement)
      ?.files?.[0];
    if (!file || !validateImage(file)) {
      return;
    }
    const url = `${import.meta.env.VITE_REST_URL as string}api/image/upload`;
    setLoading(true);
    try {
      const headers = {};
      const data = new FormData();
      data.append('site', site);
      data.append('image', file);
      const response: AxiosResponse<{ location: string }> = await axios.post(
        url,
        data,
        headers
      );
      const { location } = response.data;
      callback(location, { title: file.name, width: '70%', height: '42%' });
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const responseError = error as {
          response: { data: { message: string } };
        };
        const { message } = responseError.response.data;
        notifyError(message);
        return;
      }
      notifyError('Failed to connect to API');
    } finally {
      setLoading(false);
    }
  };

  input.click();
};
