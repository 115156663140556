import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useEmbedImageStyles = makeStyles((theme: Theme) => ({
  root: {},
  wysiwygMediaWrapper: {
    background: 'white',
    width: '80%',
    height: '80%',
    zIndex: 2,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  embedTextInput: {
    background: theme.design.colors.grey98,
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize,
    marginBottom: theme.spacing(2),
    width: 'calc(100% - 24px)',
  },
  primary: {
    background: `${theme.design.colors.white} !important`,
    color: `${theme.design.colors.black} !important`,
  },
  linksHead: {
    display: 'flex',
    gap: theme.spacing(2),
    decoration: 'none',
    listStyleType: 'none',
    padding: 0,
    fontSize: theme.typography.fontSize + 2,
    '& li': {
      cursor: 'pointer',
    },
  },
  link: {
    borderBottom: `4px solid ${theme.design.colors.black}`,
    paddingBottom: theme.spacing(1),
  },
  uploadText: {
    fontSize: theme.typography.fontSize - 2,
    paddingBottom: theme.spacing(1),
  },
  headerText: {
    fontSize: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  wrapper: {
    height: '80vh',
    width: '80vw',
    backgroundColor: theme.design.colors.waz_white,
    zIndex: 15,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
