import React, { ReactNode } from 'react';
import { AiOutlineBell, AiOutlineKey } from 'react-icons/ai';
import { FiClipboard, FiBarChart2 } from 'react-icons/fi';
import Content from '../../pages/Content/Content';
import Acl from '../Acl/Acl';
import CreateAnnouncement from '../Announcement/CreateAnnouncement/CreateAnnouncement';
import ClearCache from '../ClearCache/ClearCache';
import CreateNewsletter from '../CreateNewsletter/CreateNewsletter';
import CreatePage from '../CreatePage/CreatePage';
import CreatePoll from '../CreatePoll/CreatePoll';
import CreateRedirect from '../CreateRedirect/CreateRedirect';
import CreateSurvey from '../CreateSurvey/CreateSurvey';
import EditPage from '../EditPage/EditPage';
import EditRedirect from '../EditRedirect/EditRedirect';
import Features from '../Features/Features';
import { CreatePurchaseOrder } from '../PurchaseOrders';
import RequestFeature from '../RequestFeature/RequestFeature';
import ViewAnnouncements from '../ViewAnnouncements/ViewAnnouncements';
import ViewNewsletters from '../ViewNewsletters/ViewNewsletters';
import ViewPolls from '../ViewPolls/ViewPolls';
import ViewRedirect from '../ViewRedirect/ViewRedirect';
import ViewSocialIntegrations from '../ViewSocialIntegrations/ViewSocialIntegrations';
import ViewTasks from '../ViewTasks/ViewTasks';
import EditSurvey from '../../components/EditSurvey/EditSurvey';
import ViewPages from '../../components/ViewPages/ViewPages';
import ViewSurveys from '../../components/ViewSurveys/ViewSurveys';
import EditNewsletter from '../../components/EditNewsletter/EditNewsletter';
import { AdminDashboard } from './Dashboard/AdminDashboard';
import { BiBookContent } from 'react-icons/bi';
import { MdCached } from 'react-icons/md';
import { GrContactInfo } from 'react-icons/gr';

export interface TabsProps {
  name: string;
  icon?: string | ReactNode | JSX.Element;
  route?: string;
  key: string;
  isParent?: boolean;
  isAdmin?: boolean;
  children?: {
    name: string;
    route: string;
    key: string;
    icon?: string | ReactNode | JSX.Element;
  }[];
}

export const tabs: TabsProps[] = [
  {
    name: 'Overview',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/element-4.svg',
    route: '/admin',
    key: 'portal',
    isParent: true,
    isAdmin: true,
  },
  {
    name: 'Resources',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/task.svg',
    key: 'resources',
    isAdmin: true,
    children: [
      {
        name: 'Welcome',
        route: '/welcome',
        key: 'welcome',
      },
      {
        name: 'Culture',
        route: '/culture',
        key: 'culture',
      },
      {
        name: 'HR',
        route: '/hr',
        key: 'hr',
      },
      {
        name: 'Office',
        route: '/office',
        key: 'office',
      },
      {
        name: 'Finance',
        route: '/finance',
        key: 'finance',
      },
    ],
  },
  {
    name: 'Annoucements',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/volume-high.svg',
    route: '/admin/announcements',
    key: 'annoucements',
    isParent: true,
    isAdmin: true,
  },
  {
    name: 'Newsletters',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/note.svg',
    route: '/admin/newsletters',
    key: 'newsletters',
    isParent: true,
    isAdmin: true,
  },
  {
    name: 'Productivity',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(2).svg',
    route: '/portal/admin/Productivity',
    key: 'Productivity',
    isParent: true,
    isAdmin: true,
    children: [
      {
        name: 'Tasks',
        icon: <AiOutlineBell className="icon" />,
        route: '/admin/productivity/task',
        key: 'task',
      },
      {
        name: 'Polls',
        icon: <FiClipboard className="icon" />,
        route: '/admin/productivity/polls',
        key: 'polls',
      },
    ],
  },
  {
    name: 'Pages',
    icon: <BiBookContent size={20} />,
    route: '/admin/pages',
    key: 'pages',
    isParent: true,
    isAdmin: true,
  },
  {
    name: 'Purchase orders',
    icon: 'https://jungle-portal.s3.us-west-1.amazonaws.com/icons/share+(2).svg',
    route: '/portal/admin/purchase-orders',
    key: 'purchaseorders',
    isParent: true,
    children: [
      {
        name: 'Orders',
        icon: <AiOutlineBell className="icon" />,
        route: '/admin/purchase-orders',
        key: 'task',
      },
      {
        name: 'Request form',
        icon: <AiOutlineBell className="icon" />,
        route: '/admin/purchase-orders/create',
        key: 'task',
      },
      {
        name: 'Settings',
        icon: <FiClipboard className="icon" />,
        route: '/admin/purchase-orders/settings',
        key: 'polls',
      },
    ],
  },
  {
    name: 'Contact us',
    icon: <GrContactInfo style={{ fill: 'white' }} />,
    route: '/admin/contact-us',
    key: 'contantUs',
    isParent: true,
  },
];

export const routes: { route: string; component: JSX.Element }[] = [
  {
    route: '/portal',
    component: <AdminDashboard />,
  },
  {
    route: '/portal/welcome/your-first-day',
    component: <Content />,
  },
  {
    route: '/portal/welcome/emails-and-slack',
    component: <Content />,
  },
  {
    route: '/portal/welcome/email-signature',
    component: <Content />,
  },
  {
    route: '/portal/welcome/email-signature',
    component: <Content />,
  },
  {
    route: '/portal/welcome/guess-who',
    component: <Content />,
  },
  {
    route: '/portal/culture/mission-statements',
    component: <Content />,
  },
  {
    route: '/portal/culture/values',
    component: <Content />,
  },
  {
    route: '/portal/culture/all-hands',
    component: <Content />,
  },
  {
    route: '/portal/culture/diversity-forum',
    component: <Content />,
  },
  {
    route: '/portal/culture/ergs-clubs-committees',
    component: <Content />,
  },
  {
    route: '/portal/hr/sick-days',
    component: <Content />,
  },
  {
    route: '/portal/hr/working-day',
    component: <Content />,
  },
  {
    route: '/portal/hr/performance-reviews',
    component: <Content />,
  },
  {
    route: '/portal/hr/career-bandings',
    component: <Content />,
  },
  {
    route: '/portal/hr/wellbeing',
    component: <Content />,
  },
  {
    route: '/portal/hr/hiring',
    component: <Content />,
  },
  {
    route: '/portal/hr/hybrid-working',
    component: <Content />,
  },
  {
    route: '/portal/hr/payroll',
    component: <Content />,
  },
  {
    route: '/portal/office/the-office',
    component: <Content />,
  },
  {
    route: '/portal/office/reporting-problems',
    component: <Content />,
  },
  {
    route: '/portal/office/first-aid-&-fire-safety',
    component: <Content />,
  },
  {
    route: '/portal/office/phones-&-printers',
    component: <Content />,
  },
  {
    route: '/portal/office/meeting-rooms',
    component: <Content />,
  },
  {
    route: '/portal/office/keeping-it-green',
    component: <Content />,
  },
  {
    route: '/portal/office/health-precautions',
    component: <Content />,
  },
  {
    route: '/portal/office/coronavirus-policy',
    component: <Content />,
  },
  {
    route: '/portal/finance/purchase-orders',
    component: <Content />,
  },
  {
    route: '/portal/finance/accounts-payable',
    component: <Content />,
  },
  {
    route: '/portal/finance/pleo',
    component: <Content />,
  },
  {
    route: '/portal/finance/dext',
    component: <Content />,
  },
  {
    route: '/portal/finance/billing',
    component: <Content />,
  },
  {
    route: '/portal/finance/company-information',
    component: <Content />,
  },
  // admin routes
  {
    route: '/admin/announcements',
    component: <ViewAnnouncements />,
  },
  {
    route: 'admin/tasks',
    component: <ViewTasks />,
  },
  {
    route: '/admin/redirects',
    component: <ViewRedirect />,
  },
  {
    route: '/admin/redirects/edit/:id',
    component: <EditRedirect />,
  },
  {
    route: '/admin/newsletters',
    component: <ViewNewsletters />,
  },
  {
    route: '/admin/newsletters/create',
    component: <CreateNewsletter />,
  },
  {
    route: '/cms/newsletters/edit/:id',
    component: <EditNewsletter />,
  },
  {
    route: '/admin/pages',
    component: <ViewPages />,
  },
  {
    route: '/admin/pages/create',
    component: <CreatePage />,
  },
  {
    route: '/admin/pages/edit',
    component: <ViewPages />,
  },
  {
    route: '/admin/polls',
    component: <ViewPolls />,
  },
  {
    route: '/admin/surveys',
    component: <ViewSurveys />,
  },
  {
    route: '/admin/surveys/create',
    component: <CreateSurvey />,
  },
  {
    route: '/admin/surveys/edit/:id',
    component: <EditSurvey />,
  },
  {
    route: '/portal/admin/clear-cache',
    component: <ClearCache />,
  },
  {
    route: '/portal/admin/features',
    component: <Features />,
  },
  {
    route: '/portal/admin/features/request',
    component: <RequestFeature />,
  },
  {
    route: '/portal/admin/social/integrations',
    component: <ViewSocialIntegrations />,
  },
  {
    route: '/portal/admin/purchase/order',
    component: <CreatePurchaseOrder />,
  },
  {
    route: '/portal/admin/acl',
    component: <Acl />,
  },
];
