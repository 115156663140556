import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface Props {
  display: boolean;
  color?: string;
  variant?: string;
  size?: string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  spinner: {
    position: 'fixed',
    right: '3vw',
    bottom: '2vw',
    color: ({ color }) => color ?? '#000',
    opacity: ({ display }) => (display ? 100 : 0),
    zIndex: ({ display }) => (display ? 10 : -9999),
    transition: 'opacity 0.5s ease-in',
  },
  large__spinner: {
    position: 'relative',
    top: '-15%',
  },
}));

export default useStyles;
