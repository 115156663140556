import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

const useStyles = makeStyles((theme: BaseTheme) => ({
  content: {
    width: '100%',
  },
  content__title: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'bold',
    marginBottom: '2.5rem',
  },
  event__title: {
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: theme.design.colors.grey100,
  },
  content__form: {
    background: '#fff',
    padding: theme.spacing(3),
  },
  content__form_section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2.5rem',
    width: '100%',
  },
  content__form_section_select: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginRight: '3.813rem',
  },
  select_section: {
    display: 'flex',
    flexDirection: 'row',
  },
  content__form_label: {
    fontSize: '1.125rem',
    marginBottom: '1.25rem',
  },
  content__form_sublabel: {
    fontSize: '0.8rem',
    display: 'block',
    margin: '6px 0 0 2px',
    color: 'rgba(0,0,0,0.5)',
  },
  content__form_label_required: {
    color: '#EA3C53',
  },
  content__form_error: {
    marginTop: '16px',
    fontSize: '14px',
    color: '#EA3C53',
  },
  content__form_actions: {
    margin: '4.25rem 0 0 0',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  input_parent: {
    position: 'relative',
    width: '100%',

    '& .select': {
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '60%',
      zIndex: 10,
    },
  },
  input: {
    width: '100%',
    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },
  },
  eventTitleParent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  selectField: {
    position: 'relative',
    height: '56px',
    width: '215px',
  },
  btn: {
    position: 'relative',
    width: '100%',
  },
}));

export default useStyles;
