import { gql } from '@apollo/client';

export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement(
    $all: Boolean
    $description: String!
    $recipients: [ID]
    $title: String!
  ) {
    createAnnouncement(
      all: $all
      description: $description
      recipients: $recipients
      title: $title
    ) {
      id
      title
      description
      created_at
    }
  }
`;

export const UPDATE_ANNOUCEMENT = gql`
  mutation updateAnnoucement(
    $all: Boolean
    $description: String!
    $recipients: [ID]
    $title: String!
    $id: ID!
    $image: JSON
    $video: String
  ) {
    updateAnnouncement(
      all: $all
      description: $description
      recipients: $recipients
      title: $title
      id: $id
      image: $image
      video: $video
    ) {
      id
      title
      description
      created_at
      image
    }
  }
`;

export const FETCHANNOUNCEMENT = gql`
  query getAnnouncement($id: ID!) {
    announcement(id: $id) {
      description
      recipients
      title
      all
      image
      video
    }
  }
`;

export const GETUSERS = gql`
  query fetchUser {
    users {
      data {
        id
        name
      }
    }
  }
`;
