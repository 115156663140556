import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSelectPostsStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    background: theme.design.colors.white,
    padding: theme.spacing(3),
    borderRadius: 4,
    boxShadow: `0px 3px 6px ${theme.design.colors.black99}`,
    margin: '0 auto',
    width: '41%',
    maxWidth: 680,
    zIndex: 10,
    maxHeight: '80vh',
  },
  selectHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '14px',
  },
  selectHeadingTitle: {
    fontSize: theme.typography.fontSize + 4,
    color: theme.design.colors.black200,
    fontWeight: theme.typography.fontWeightBold,
  },
  selectHeadingIcon: {
    color: theme.design.colors.grey100,
    fontSize: theme.typography.fontSize + 4,
    cursor: 'pointer',
  },
  selectTextInput: {
    marginBottom: theme.spacing(2),
    width: 'calc(100% - 24px)',
    boxSizing: 'border-box',
  },
  selectTextInputIcon: {
    marginRight: theme.spacing(1),
  },
  selectTitle: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.charcoal,
    marginBottom: theme.spacing(1),
  },
  selectContainer: {
    height: '65vh',
    overflow: 'scroll',
    marginBottom: theme.spacing(2),
  },
  selectSearchResults: {
    marginBottom: theme.spacing(3),
  },
  selectSuggestions: {},
  selectButtons: {
    display: 'flex',
    gap: theme.spacing(1.5),

    '& button': {
      width: 'calc((100% - 12px)/2)',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.fontSize + 2,
    },
  },
  selectButtonSecondary: {
    background: theme.design.colors.white,
    border: `1px solid ${theme.design.colors.black}`,
    color: theme.design.colors.black,

    '&:hover': {
      backgroundColor: theme.design.colors.white,
      color: theme.design.colors.black,
    },

    '&:focus': {
      backgroundColor: theme.design.colors.white,
      color: theme.design.colors.black,
    },

    '&:hover:focus': {
      backgroundColor: theme.design.colors.white,
      color: theme.design.colors.black,
    },
  },
  selectButtonPrimary: {
    backgroundColor: theme.design.colors.black,
    color: theme.design.colors.white,
  },
}));
