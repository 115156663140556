import { gql } from '@apollo/client';

export const UPDATECONFIG = gql`
  mutation UpdatePageConfig(
    $route: String!
    $site: String!
    $content: String!
  ) {
    updatePageConfig(route: $route, site: $site, content: $content)
  }
`;

export const CREATEFEED = gql`
  mutation CreateFeed(
    $site_uuid: ID!
    $title: String!
    $link: String!
    $pubDate: DateTime!
    $lastBuildDate: DateTime!
    $managingEditor: String!
    $description: String!
    $image: String!
  ) {
    createFeed(
      input: {
        site_uuid: $site_uuid
        title: $title
        link: $link
        pubDate: $pubDate
        lastBuildDate: $lastBuildDate
        managingEditor: $managingEditor
        description: $description
        image: $image
      }
    ) {
      link
      site_id
      image
      title
      uuid
      description
      lastBuildDate
      managingEditor
      pubDate
    }
  }
`;

export const ADDMRSSITEM = gql`
  mutation AddMrssItem(
    $feed_uuid: ID!
    $title: String!
    $pubDate: DateTime!
    $link: String!
    $description: String!
    $category: String!
    $url: String!
    $title_media: String!
    $description_media: String!
    $thumbnail: String!
    $author: String!
    $copyright_url: String!
  ) {
    addMrssItem(
      mrssItemInput: {
        feed_uuid: $feed_uuid
        title: $title
        pubDate: $pubDate
        link: $link
        description: $description
      }
      mrssMediaInput: {
        category: $category
        url: $url
        title_media: $title_media
        description_media: $description_media
        thumbnail: $thumbnail
        author: $author
        copyright_url: $copyright_url
      }
    ) {
      description
      link
      pubDate
      title
      uuid
    }
  }
`;

export const UPDATEFEED = gql`
  mutation UpdateFeed(
    $uuid: ID!
    $site_uuid: ID!
    $title: String!
    $link: String!
    $pubDate: DateTime!
    $lastBuildDate: DateTime!
    $managingEditor: String!
    $description: String!
    $image: String!
  ) {
    updateFeed(
      uuid: $uuid
      input: {
        site_uuid: $site_uuid
        title: $title
        link: $link
        pubDate: $pubDate
        lastBuildDate: $lastBuildDate
        managingEditor: $managingEditor
        description: $description
        image: $image
      }
    ) {
      link
      site_id
      image
      title
      uuid
      description
      lastBuildDate
      managingEditor
      pubDate
    }
  }
`;

export const UPDATEMRSSITEM = gql`
  mutation UpdateMrssItem(
    $uuid: ID!
    $feed_uuid: ID!
    $title: String!
    $pubDate: DateTime!
    $link: String!
    $description: String!
    $category: String!
    $url: String!
    $title_media: String!
    $description_media: String!
    $thumbnail: String!
    $author: String!
    $copyright_url: String!
  ) {
    updateMrssItem(
      uuid: $uuid
      mrssItemInput: {
        feed_uuid: $feed_uuid
        title: $title
        pubDate: $pubDate
        link: $link
        description: $description
      }
      mrssMediaInput: {
        category: $category
        url: $url
        title_media: $title_media
        description_media: $description_media
        thumbnail: $thumbnail
        author: $author
        copyright_url: $copyright_url
      }
    ) {
      description
      link
      pubDate
      title
      uuid
    }
  }
`;

export const DELETEMRSSITEM = gql`
  mutation DeleteMrssItem($uuid: ID!) {
    deleteMrssItem(uuid: $uuid) {
      description
      link
      pubDate
      title
      uuid
    }
  }
`;
