import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import useStyles from './styles';
import clx from 'classnames';
import { LoaderProps } from './types';

const Loader: FC<LoaderProps> = ({ className, display }) => {
  const classes = useStyles();
  return (
    <div className={clx(classes.loader, display && classes.active)}>
      <div className={classes.loaderParent}>
        <CircularProgress className={className} size="18px" />
      </div>
    </div>
  );
};

export default Loader;
