import React, { useState, useContext, useCallback } from 'react';
import AuthContext from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { FETCHNEWSLETTERS, DELETENEWSLETTER } from './graphQL';
import { useMutation, ApolloConsumer } from '@apollo/client';
import useStyles from '../ViewRedirect/styles';
import { Button } from '../common/Button/Button';
import NewsletterType from '../../types/NewsletterType';
import { notifyError, notifySuccess } from '../../utilities/notify';
import { TableNew } from '@wazobia-tech/wazcom/dist/core';
import {
  tableConditionFilters,
  useGetTableColumns,
  useGetTableRows,
} from './helper';
import { DialogDelete } from 'components/Blog/components/DeleteDialog/Dialog';

const ViewNewsletters = () => {
  const [page, setPage] = useState<number>(1);
  const [first, setRows] = useState<number>(7);
  const { toggleSpinner } = useContext(AuthContext);
  const [deleteNewsletterMutation] = useMutation(DELETENEWSLETTER);
  const [newsletters, setNewsletters] = useState<NewsletterType[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [post_uuid, setPostUuid] = useState<undefined | string>();
  const [refreshToken, setRefreshToken] = useState(false);
  const [displayedNewsletters, setDisplayedNewsletters] = useState<
    NewsletterType[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const classes = useStyles();
  const navigate = useNavigate();
  const tableColumns = useGetTableColumns(
    setPostUuid,
    setOpenDeleteModal,
    setRefreshToken
  );
  const getTableRows = useGetTableRows();

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const updateNewsletters = useCallback(
    (id: number) => {
      let idx = newsletters.findIndex(
        (newsletter) => newsletter.id.toString() === id.toString()
      );
      const newNewsletters = [...newsletters];
      newNewsletters.splice(idx, 1);
      setNewsletters(newNewsletters);
      idx = displayedNewsletters.findIndex(
        (newsletter) => newsletter.id.toString() === id.toString()
      );
      const newDisplayedNewsletters = [...displayedNewsletters];
      newDisplayedNewsletters.splice(idx, 1);
      setDisplayedNewsletters(newDisplayedNewsletters);
    },
    [newsletters, displayedNewsletters]
  );

  const deleteNewsletter = async () => {
    if (!post_uuid) {
      return;
    }
    const variables = { id: post_uuid };
    toggleSpinner(true);
    try {
      await deleteNewsletterMutation({ variables });
      notifySuccess('Newsletter Deleted Successfully');
      updateNewsletters(Number(variables.id));
      setRefreshToken(true);
    } catch (error) {
      notifyError('An Error Occured');
    } finally {
      toggleSpinner(false);
      setOpenDeleteModal(false);
    }
  };

  const searchNewsletters = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      setSearchText(event.target.value);
      const filterednewsletters = newsletters.filter((newsletter) =>
        newsletter.title.toLowerCase().includes(event.target.value)
      );
      setDisplayedNewsletters(filterednewsletters);
    },
    // eslint-disable-next-line
    [displayedNewsletters, newsletters]
  );

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <div className={classes.titleWidget}>
            <p className={classes.title}>Newsletters</p>
            <div className={classes.searchBox}>
              <Button
                title="Create Newsletter"
                onClick={() => {
                  navigate('/admin/newsletters/create');
                }}
              />
            </div>
          </div>

          <div className={classes.main}>
            <TableNew
              columns={tableColumns}
              getRows={getTableRows}
              query={{
                name: FETCHNEWSLETTERS,
                client,
                variables: {
                  page,
                  first,
                  refreshToken,
                },
                dataKey: 'newsletters',
              }}
              conditionFilters={tableConditionFilters}
              showFilters={false}
            />
          </div>
          <DialogDelete
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            deletePostCategory={deleteNewsletter}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default ViewNewsletters;
