import { makeStyles } from '@mui/styles';
import { BaseTheme } from 'contexts/theme';

export const useStyles = makeStyles((theme: BaseTheme) => ({
  loading: {
    position: 'relative',
    top: 'calc((30vh - 40px)/2)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.design.colors.black,
    color: theme.design.colors.white,
    width: '100%',
    height: '100%',
    zIndex: -10,
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
    borderRadius: 5,
  },
  loader_active: {
    zIndex: 10,
    opacity: 1,
  },
  tags: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  tag: {
    padding: theme.spacing(0.5),
    background: theme.design.colors.widgetGrey,
    borderRadius: '5px',
  },
  main: {
    minHeight: '30vh',
    borderRadius: '10px',
  },
  filterBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    '& button': {
      padding: theme.spacing(1.5, 2.5),
      background: 'transparent',
      border: `1px solid ${theme.design.colors.black}`,
      borderRadius: '4px',
      cursor: 'pointer',
    },
    '& .secondary': {
      background: theme.design.colors.black,
      color: theme.design.colors.white,
    },
  },
  iconPointer: {
    cursor: 'pointer',
  },
  subTitle: {
    paddingTop: theme.spacing(2),
  },
  modalContent: {
    background: theme.design.colors.white,
    width: '700px',
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: '4px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    fontWeight: 'bold',
  },
  mainTopRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  mainTopLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  event_title_parent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  event__title: {
    fontSize: theme.typography.fontSize + 4,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    color: theme.design.colors.grey100,
  },
  checkMarkIcon: {
    fontSize: theme.typography.fontSize + 4,
  },
  timesIcon: {
    color: theme.design.colors.red,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5.3rem',

    '& p': {
      margin: '0 1.25rem 0 0',
      color: 'rgba(0,0,0,0.8)',
      width: '-webkit-fill-available',
    },
  },
  search: {
    width: '280px',
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '24px',
  },
  searchBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  box: {
    width: 767,
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  searchIcon: {
    position: 'absolute',
    bottom: '8px',
    left: '35%',
    width: 20,
  },
  title: {
    fontSize: '1.3rem',
    lineHeight: '1.75rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18,
  },
  anchor: {
    textDecoration: 'none',
  },
  rightSide: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  createBtn: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.design.font.workSans,
    color: theme.design.colors.white,
    background: theme.design.colors.grey100,
    '& .secondary': {
      color: theme.design.colors.grey100,
      background: theme.design.colors.white,
    },
  },
  createParent: {
    background: theme.design.colors.white,
    width: '100%',
    height: '100%',
  },
  actions: {
    display: 'none',
    gap: theme.spacing(0.5),
  },
  actionActive: {
    display: 'flex',
    height: 'auto',
    transition: 'height 1s ease-out',
  },
  titleParent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  edit: {
    fontSize: theme.typography.fontSize - 2,
    color: theme.design.colors.greyDark,
    cursor: 'pointer',
    '&:hover': {
      color: theme.design.colors.black,
    },
  },
  editBorder: {
    borderLeft: `1px solid ${theme.design.colors.deepGrey}`,
    paddingLeft: theme.spacing(0.5),
  },
  itemTitle: {
    fontWeight: theme.typography?.fontWeightMedium,
    color: theme.design.colors.grey100,
  },
}));

export default useStyles;
