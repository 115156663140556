import { makeStyles } from '@mui/styles';
import { BaseTheme } from '../../../../contexts/theme';

const useUTMStyles = makeStyles((theme: BaseTheme) => ({
  title: {
    fontSize: theme.typography.fontSize + 2,
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  selectBox: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.fontSize - 2,
    paddingBottom: theme.spacing(1.5),
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputField: {
    boxSizing: 'border-box',
  },
  copyTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyIcon: {
    cursor: 'pointer',
  },
  copied: {
    border: '1px solid green',
  },
}));

export default useUTMStyles;
