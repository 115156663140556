import {
  TableColumn,
  TableConditionFilter,
  Tag,
} from '@wazobia-tech/wazcom/dist/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import moment from 'moment';
import useBlogStyles from './styles';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { notifyStatus } from 'simple-notify/dist/types';
import {
  CorePostFields,
  GqlCreateBlogPageVariables,
  GqlCreatePostVariables,
  GqlUpdateBlogPageVariables,
  GqlUpdatePostVariables,
} from './components/CreateContent/type';
import { blogClient } from 'utilities/apolloClient';
import { CREATE_POST, UPDATE_POST } from './graphQL';
import useStyles from './styles';
import { AppStateContext } from 'contexts/provider';
import { useContext } from 'react';
import { decodeHtml } from './components/CreateContent/helpers';
import { formatTimezoneDateTime } from 'utilities/time';

export const useGetTableColumns = (
  setPostId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
): TableColumn[] => {
  const navigate = useNavigate();
  const classes = useStyles();
  return [
    {
      name: 'Title',
      key: 'title',
      type: 'string',
      actions: [
        {
          name: 'Edit',
          func: (row: any) => {
            navigate(`/cms/posts/${row.uuid}`);
          },
        },
        {
          name: 'Delete',
          func: (row: { uuid: string }) => {
            setPostId(row?.uuid);
            setOpenDeleteModal(true);
          },
        },
      ],
    },
    { name: 'Author', key: 'author.name', type: 'string' },
    { name: 'Categories', key: 'categories', type: 'string' },
    { name: 'Tags', key: 'tags', type: 'string' },
    { name: 'Date', key: 'updated_at', type: 'date' },
  ];
};

export const getPostURL = (current_site_url: string) => {
  const portal_host = window.location.host;
  if (portal_host == 'portal.junglecreations.com') {
    return current_site_url.includes('twistedfood')
      ? current_site_url + '/articles'
      : current_site_url;
  } else {
    let convertedUrl = current_site_url
      ? current_site_url.replace('https://', 'https://dev.')
      : '';
    return current_site_url.includes('twistedfood')
      ? convertedUrl + '/articles'
      : convertedUrl;
  }
};

export const useGetTableRows = () => {
  const classes = useBlogStyles();
  const getPreSlugURL = useGetPreSlugURL();

  return (data: any) => {
    const parsedRows: { [key: string]: JSX.Element | string | number }[] = [];

    for (const row of data) {
      const parsedRow: { [key: string]: JSX.Element | string | number } = {};
      parsedRow.title = (
        <a
          href={`${getPreSlugURL()}` + row?.slug}
          target="_blank"
          className={classes.blogTitleSlug}
          rel="noreferrer"
        >
          {decodeHtml(row.title)}{' '}
          {row?.status ? <FaCheckCircle className={classes.postCheck} /> : ''}
        </a>
      );
      parsedRow['author.name'] = row?.author?.name as string;
      parsedRow.categories =
        row?.categories && row?.categories?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.categories
              ?.slice(0, 3)
              ?.map(({ name }: any, index: number) => (
                <Tag name={name as string} key={index} />
              ))}
            {row?.categories?.length > 3 && (
              <span className={classes.postTagDifference}>
                {row?.categories?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      parsedRow.tags =
        row?.tags?.length > 0 ? (
          <div className={classes.postTags}>
            {row?.tags?.slice(0, 3)?.map(({ name }: any, index: number) => (
              <Tag name={name} key={index} />
            ))}
            {row?.tags?.length > 3 && (
              <span className={classes.postTagDifference}>
                +{row?.tags?.length - 3}
              </span>
            )}
          </div>
        ) : (
          'None'
        );
      try {
        const date = row?.status ? row?.published_at : row?.updated_at;
        const updatedAt = moment(formatTimezoneDateTime(date)).fromNow();
        parsedRow.updated_at = (
          <div>
            <p>{row?.status ? 'Published' : 'Updated'}</p>
            <p>
              {row?.status
                ? moment(formatTimezoneDateTime(date)).format(
                    'DD/MM/YYYY [at] hh:mm a'
                  )
                : updatedAt.charAt(0).toUpperCase() +
                  updatedAt.slice(1).toLowerCase()}
              {}
            </p>
          </div>
        );
      } catch (e) {
        const updatedAt = moment(row?.updated_at).fromNow();
        parsedRow.updated_at =
          updatedAt.charAt(0).toUpperCase() + updatedAt.slice(1).toLowerCase();
      }
      parsedRow.identifier = row;
      parsedRows.push(parsedRow);
    }

    return parsedRows;
  };
};

export const tableConditionFilters: TableConditionFilter[] = [
  { name: 'All Posts', condition: (posts: any) => posts },
  {
    name: 'Published',
    condition: (posts: any) => posts.filter((post: any) => post.status),
    filter: [
      {
        column: 'status',
        operator: '=',
        value: '1',
      },
    ],
  },
  {
    name: 'Drafted',
    condition: (posts: any) => posts.filter((post: any) => !post.status),
    filter: [
      {
        column: 'status',
        operator: '=',
        value: '0',
      },
    ],
  },
];

export const validatePostInformation = (form: any) => {
  const fields = ['title', 'content', 'slug', 'categories'];
  const invalidFields: string[] = [];

  fields.forEach((field) => {
    if (field === 'categories') {
      (!form?.categories || form?.categories?.length === 0) &&
        invalidFields.push(field);
    } else if (!form[field]) invalidFields.push(field);
  });

  return invalidFields.map(
    (field) => field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()
  );
};

export const notify = (status: notifyStatus, title: string, text?: string) => {
  return new Notify({
    status,
    title,
    text,
    effect: 'slide',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 3500,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'top right',
  });
};

export const useGetPostInformation = () => {
  return (form: any, blogUuid: string, status: boolean) => {
    const { categories } = form;
    const category_uuids: string[] = [];

    (categories ?? []).forEach(
      ({
        uuid,
        subCategories,
      }: {
        name: string;
        uuid: string;
        subCategories?: { name: string; uuid: string }[];
      }) => {
        const category_uuid =
          subCategories && subCategories.length > 0
            ? subCategories[0].uuid
            : uuid;
        category_uuids.push(category_uuid);
      }
    );

    const config = getPostConfig(form);
    const metadata: { [key: string]: any } = extractMetaValues(form);

    return {
      title: form?.title,
      content: form?.content,
      status: form?.status,
      category_uuids,
      author_uuid: form?.author_uuid,
      tags: form?.tags
        ? form.tags
            .map((item: any) => {
              if (typeof item == 'string') {
                return item;
              } else if (item && item?.name) {
                return item.name;
              }
              return '';
            })
            .filter(Boolean)
        : [],
      featured_image: form.featured_image,
      excerpt: form?.excerpt,
      route: form?.slug,
      visibilty: Boolean(form?.visibilty),
      blog_uuid: blogUuid,
      config,
      metadata: JSON.stringify(metadata),
      ...(form?.published_at
        ? { published_at: form?.published_at.split('T').join(' ') }
        : {}),
    };
  };
};

export const extractMetaValues = (form: { [key: string]: any }) => {
  const metaValues: { [key: string]: any } = {};

  for (const key in form) {
    if (form.hasOwnProperty(key) && key.startsWith('meta_')) {
      metaValues[`${key.split(`meta_`)[1]}`] = form[key];
    }
  }

  return metaValues;
};

export const getPostConfig = (form: any) => {
  const social_preview_config: { [key: string]: any } = {
    open_graph: {
      ...form.open_graph,
    },
    twitter_cards: {
      ...form.twitter_cards,
    },
    seo_cards: {
      ...form?.seo_cards,
    },
  };
  const socialFields = [
    'social_title',
    'social_description',
    'social_image',
    'twitter_image',
    'twitter_title',
    'twitter_description',
    'seo_image',
    'seo_title',
    'seo_description',
    'seo_url',
  ];

  const processSocialField = (
    field: string,
    targetObject: Record<string, any>,
    prefix: string,
    sPrefix: string
  ) => {
    if (form[field]) {
      if (field.startsWith(sPrefix)) {
        targetObject[`${prefix}${field.split(`${sPrefix}`)[1]}`] = form[field];
      }

      if (
        field.startsWith(sPrefix) &&
        (field.includes('title') || field.includes('description'))
      ) {
        targetObject[`${prefix}${field.split(`${sPrefix}`)[1]}`] = decodeHtml(
          form[field]
        );
        return;
      }
    }
  };

  socialFields.forEach((field) => {
    processSocialField(
      field,
      social_preview_config.open_graph,
      'og:',
      'social_'
    );
    processSocialField(
      field,
      social_preview_config.twitter_cards,
      'twitter:',
      'twitter_'
    );
    processSocialField(field, social_preview_config.seo_cards, 'seo:', 'seo_');
  });

  social_preview_config['title'] = decodeHtml(form['social_title']);
  social_preview_config['description'] = decodeHtml(form['social_description']);

  const schema: { [key: string]: string } = {};
  const schemaKeys = Object.keys(form).filter((key: string) =>
    key.startsWith('schema_')
  );

  schemaKeys.forEach((key) => {
    if (form[key]) {
      const newKey = key.split('schema_')[1];
      schema[newKey] = form[key];
    }
  });

  return {
    is_comments_enabled: form?.is_comments_enabled,
    is_in_sitemap: !form?.is_dark_post,
    is_feature_article: form?.is_feature_article,
    related_posts_config: form?.auto_related_posts
      ? null
      : form?.related_posts_config && form?.related_posts_config?.length > 0
      ? typeof form?.related_posts_config[0] === 'string'
        ? undefined
        : JSON.stringify(
            form?.related_posts_config?.map(
              ({ uuid }: { uuid: string }) => uuid
            )
          )
      : null,
    social_preview_config: JSON.stringify(social_preview_config),
    schema: form.schema,
  };
};

export const createBlogPage = async (
  blogUuid: string,
  variables: GqlCreateBlogPageVariables
): Promise<{ createBlogPost: CorePostFields } | undefined> => {
  const blog_uuid = blogUuid;
  if (blog_uuid) {
    const postInput = (({
      route,
      title,
      status,
      category_uuids,
      content,
      tags,
      description,
      featured_image,
      author_uuid,
      published_at,
      config,
      excerpt,
      version,
      visibility,
      metadata,
      uuid,
    }) => ({
      slug: route,
      title,
      status,
      category_uuids,
      content,
      description,
      blog_uuid,
      tags,
      featured_image,
      author_uuid,
      published_at,
      config,
      excerpt,
      version,
      visibility,
      metadata,
      uuid,
    }))(variables);

    const { data }: { data?: { createBlogPost: CorePostFields } } =
      await gqlCreatePost({
        ...postInput,
      });

    if (data) {
      return data;
    }
  }
};

export const updateBlogPage = async (variables: GqlUpdateBlogPageVariables) => {
  const postInput = (({
    uuid,
    route,
    title,
    status,
    category_uuids,
    content,
    tags,
    description,
    featured_image,
    published_at,
    config,
    excerpt,
    version,
    visibility,
    author_uuid,
    metadata,
  }) => ({
    uuid,
    slug: route,
    title,
    status,
    category_uuids,
    content,
    description,
    tags,
    featured_image,
    published_at,
    config,
    excerpt,
    version,
    visibility,
    author_uuid,
    metadata,
  }))(variables);

  const { data }: { data?: { updateBlogPost: CorePostFields } } =
    await gqlUpdatePost({
      ...postInput,
    });

  if (data) {
    return data;
  }
};

export const gqlCreatePost = async (variables: GqlCreatePostVariables) =>
  blogClient.mutate({
    mutation: CREATE_POST,
    variables,
  });

export const gqlUpdatePost = async (variables: GqlUpdatePostVariables) =>
  blogClient.mutate({
    mutation: UPDATE_POST,
    variables,
  });

export const useGetPreSlugURL = () => {
  const {
    siteDetails: { url: current_site_url },
  } = useContext(AppStateContext);
  const location = useLocation();

  return (isPreview: boolean = false, type: string = 'articles') => {
    const portal_host = window.location.host;

    const isRecipe = location.pathname.includes('recipes');
    const isGuide = location.pathname.includes('guides');

    if (portal_host == 'portal.junglecreations.com') {
      const siteUrl = isPreview
        ? `${current_site_url}/preview`
        : current_site_url;
      return current_site_url.includes('twistedfood')
        ? isRecipe
          ? `${siteUrl}/recipes`
          : isGuide
          ? `${siteUrl}/guides`
          : `${siteUrl}/articles`
        : siteUrl;
    } else {
      let convertedUrl = current_site_url
        ? current_site_url.replace('https://', 'https://dev.')
        : '';
      const siteUrl = isPreview ? `${convertedUrl}/preview` : convertedUrl;

      return current_site_url.includes('twistedfood')
        ? isRecipe
          ? `${siteUrl}/recipes`
          : isGuide
          ? `${siteUrl}/guides`
          : `${siteUrl}/articles`
        : siteUrl;
    }
  };
};
