import { gql } from '@apollo/client';

export const CONTENT = gql`
  query getContent($slug: String!) {
    content(slug: $slug) {
      title
      content
    }
  }
`;
